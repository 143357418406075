import React, { useState } from 'react';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import StyledBigTitle from '../../../../components/parameters/StyledTitle';
import InputRadio from '../../../../components/parameters/InputRadio';
import StyledLabel from '../../../../components/parameters/StyledLabel';
import Upload from '../../../../_assets/icon/Upload';
import {
  BounceLoader,
  Loader,
} from '../../../../components/parameters/LoadAnimation';
import StyledUpload from '../../../../components/parameters/StyledUpload';
import InputFile from '../../../../components/parameters/InputFile';
import CircleImg from '../../../../components/parameters/CircleImg';
import StyledImage from '../../../../components/parameters/StyledImage';
import { RightArrow } from '@styled-icons/boxicons-solid/RightArrow';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';
import messageTemplate from './templates/Message';
import loaderTemplate from './templates/Loader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WrapperDiv = styled.div`
  display: flex;
  align-items: ${({ alignItem }) => alignItem || 'flex-start'};
  margin: ${({ mr }) => (mr ? mr : '1rem 0')};
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const SubtitleLight = styled.h3`
  font-size: 1.1.rem;
  margin-right: 2rem;
  margin: ${({ margin }) => margin};
`;

const WrapperMessages = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 1.4rem 0;
`;

const ContentMessages = styled.div`
  align-self: ${({ user }) => (user ? 'flex-end' : 'flex-start')};
`;

const StyledMessage = styled.div`
  position: relative;
  width: max-content;
  font-weight: 300;
  line-height: 1.2;
  color: ${({ isUser }) => (isUser ? '#111' : '#FFF')};
  background-color: ${({ isUser }) => (isUser ? '#eee' : '#F44A4A')};
  padding: 0.8rem;
  margin: 0.4rem;
  border-radius: ${({ borderRadius }) => borderRadius};
  border-top-right-radius: ${({ isUser, borderCornerTop }) =>
    isUser && borderCornerTop ? '2px' : ''};
  border-top-left-radius: ${({ isUser, borderCornerTop }) =>
    !isUser && borderCornerTop ? '2px' : ''};
  border-bottom-right-radius: ${({ isUser, borderCornerBottom }) =>
    isUser && borderCornerBottom ? '2px' : ''};
  border-bottom-left-radius: ${({ isUser, borderCornerBottom }) =>
    !isUser && borderCornerBottom ? '2px' : ''};
  box-shadow: ${({ boxShadow }) => boxShadow};
  &::after {
    display: ${({ oldVersion }) => (oldVersion ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    right: ${({ isUser }) => (isUser ? '0' : 'unset')};
    left: ${({ isUser }) => (isUser ? 'unset' : '0')};
    margin-right: ${({ isUser }) => (isUser ? '8px' : 'unset')};
    margin-left: ${({ isUser }) => (!isUser ? '8px' : 'unset')};
    border-right-color: ${({ isUser }) => (isUser ? '#eee' : 'transparant')};
    border-left-color: ${({ isUser }) => (!isUser ? '#F44A4A' : 'transparent')};
  }
`;

const WrapperColumn = styled.div`
  display: flex;
  margin: ${({ margin }) => margin || '1rem 2rem'};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const WrapperColumnUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  align-items: center;
`;

const WrapperAnimation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const InputUpload = styled.input`
  display: inline-block;
  position: absolute;
  z-index: 1000000;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: 150px;
  text-align: center;
  margin-top: 1rem;
  transition: 0.3s;
  cursor: pointer;
  transition: opacity 0.3s;

  > span {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.white};
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 12px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Message = ({ w, wWebchat, state, dispatch, handleChangeAvatar }) => {
  const [hideFirstContent, setHideFirstContent] = useState(true);
  const [hideSecondContent, setHideSecondContent] = useState(true);
  const { Handle } = Slider;

  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} %`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };
  return (
    <Wrapper>
      <StyledBigTitle>
        {wWebchat.templateChoice}
        <span onClick={() => setHideFirstContent(!hideFirstContent)}>
          {hideFirstContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideFirstContent && (
        <>
          <WrapperDiv>
            <SubtitleLight>{wWebchat.shapeBubble}</SubtitleLight>
          </WrapperDiv>
          <WrapperDiv>
            <WrapperMessages>
              <ContentMessages>
                <StyledMessage borderCornerTop borderRadius={'25px'}>
                  <p>{wWebchat.messageBot}</p>
                </StyledMessage>
              </ContentMessages>
              <ContentMessages user>
                <StyledMessage isUser borderCornerTop borderRadius={'25px'}>
                  {wWebchat.messageUser}
                </StyledMessage>
              </ContentMessages>
              <InputRadio
                type="radio"
                value="template2"
                checked={
                  JSON.stringify(state.templateMessage) ===
                  JSON.stringify(messageTemplate('template2'))
                }
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplateMessage',
                    payload: messageTemplate(e.target.value),
                  });
                }}
              />
            </WrapperMessages>
            <WrapperMessages>
              <ContentMessages>
                <StyledMessage borderRadius={'20px'}>
                  <p>{wWebchat.messageBot}</p>
                </StyledMessage>
              </ContentMessages>
              <ContentMessages user>
                <StyledMessage isUser borderRadius={'20px'}>
                  {wWebchat.messageUser}
                </StyledMessage>
              </ContentMessages>
              <InputRadio
                type="radio"
                value="template3"
                checked={
                  JSON.stringify(state.templateMessage) ===
                  JSON.stringify(messageTemplate('template3'))
                }
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplateMessage',
                    payload: messageTemplate(e.target.value),
                  });
                }}
              />
            </WrapperMessages>
            <WrapperMessages>
              <ContentMessages>
                <StyledMessage
                  borderRadius={'25px'}
                  boxShadow={'rgba(0, 0, 0, 0.35) 0px 3px 15px'}
                >
                  <p>{wWebchat.messageBot}</p>
                </StyledMessage>
              </ContentMessages>
              <ContentMessages user>
                <StyledMessage
                  isUser
                  borderRadius={'25px'}
                  boxShadow={'rgba(0, 0, 0, 0.35) 0px 3px 15px'}
                >
                  {wWebchat.messageUser}
                </StyledMessage>
              </ContentMessages>
              <InputRadio
                type="radio"
                value="template4"
                checked={
                  JSON.stringify(state.templateMessage) ===
                  JSON.stringify(messageTemplate('template4'))
                }
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplateMessage',
                    payload: messageTemplate(e.target.value),
                  });
                }}
              />
            </WrapperMessages>
            <WrapperMessages>
              <ContentMessages>
                <StyledMessage
                  borderRadius={'15px'}
                  boxShadow={'0px 2px 2px 0px rgba(0,0,0,0.5)'}
                >
                  <p>{wWebchat.messageBot}</p>
                </StyledMessage>
              </ContentMessages>
              <ContentMessages user>
                <StyledMessage
                  isUser
                  borderRadius={'15px'}
                  boxShadow={'0px 2px 2px 0px rgba(0,0,0,0.5)'}
                >
                  {wWebchat.messageUser}
                </StyledMessage>
              </ContentMessages>
              <InputRadio
                type="radio"
                value="template5"
                checked={
                  JSON.stringify(state.templateMessage) ===
                  JSON.stringify(messageTemplate('template5'))
                }
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplateMessage',
                    payload: messageTemplate(e.target.value),
                  });
                }}
              />
            </WrapperMessages>
            <WrapperMessages>
              <ContentMessages>
                <StyledMessage borderCornerBottom borderRadius={'25px'}>
                  <p>{wWebchat.messageBot}</p>
                </StyledMessage>
              </ContentMessages>
              <ContentMessages user>
                <StyledMessage isUser borderCornerBottom borderRadius={'25px'}>
                  {wWebchat.messageUser}
                </StyledMessage>
              </ContentMessages>
              <InputRadio
                type="radio"
                value="template6"
                checked={
                  JSON.stringify(state.templateMessage) ===
                  JSON.stringify(messageTemplate('template6'))
                }
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplateMessage',
                    payload: messageTemplate(e.target.value),
                  });
                }}
              />
            </WrapperMessages>
            <WrapperMessages>
              <ContentMessages>
                <StyledMessage borderRadius={'5px'} oldVersion>
                  <p>{wWebchat.messageBot}</p>
                </StyledMessage>
              </ContentMessages>
              <ContentMessages user>
                <StyledMessage isUser borderRadius={'5px'} oldVersion>
                  {wWebchat.messageUser}
                </StyledMessage>
              </ContentMessages>
              <InputRadio
                type="radio"
                value="template7"
                checked={
                  JSON.stringify(state.templateMessage) ===
                  JSON.stringify(messageTemplate('template7'))
                }
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplateMessage',
                    payload: messageTemplate(e.target.value),
                  });
                }}
              />
            </WrapperMessages>
          </WrapperDiv>
        </>
      )}
      <StyledBigTitle>
        {wWebchat.templateConfig}
        <span onClick={() => setHideSecondContent(!hideSecondContent)}>
          {hideSecondContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideSecondContent && (
        <>
          <WrapperDiv mr={'0 2rem'}>
            <SubtitleLight>{wWebchat.animationChoice}</SubtitleLight>
          </WrapperDiv>
          <WrapperDiv
            mr={'1rem 4rem'}
            alignItem={'flex-end'}
            justifyContent={'space-evenly'}
          >
            <WrapperAnimation>
              <BounceLoader />
              <InputRadio
                mt
                type="radio"
                value="template1"
                checked={state.templateAnimation.v2}
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplateAnimation',
                    payload: loaderTemplate(e.target.value),
                  });
                }}
              />
            </WrapperAnimation>
            <WrapperAnimation>
              <Loader />
              <InputRadio
                mt
                type="radio"
                value="template2"
                checked={!state.templateAnimation.v2}
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplateAnimation',
                    payload: loaderTemplate(e.target.value),
                  });
                }}
              />
            </WrapperAnimation>
          </WrapperDiv>
          <WrapperDiv mr={'0 2rem'}>
            <SubtitleLight>{wWebchat.iconBubble}</SubtitleLight>
          </WrapperDiv>
          <WrapperDiv mr={'1rem 4rem'} justifyContent={'flex-start'}>
            <StyledLabel noMargin flex radio>
              <InputRadio
                type="checkbox"
                value={state.iconMessageUser}
                checked={!state.iconMessageUser}
                onChange={() => {
                  dispatch({
                    type: 'setIconMessageUser',
                    payload: !state.iconMessageUser,
                  });
                }}
              />
              {wWebchat.iconUser}
            </StyledLabel>
            <StyledLabel noMargin flex radio>
              <InputRadio
                type="checkbox"
                value={state.iconMessageBot}
                checked={!state.iconMessageBot}
                onChange={() => {
                  dispatch({
                    type: 'setIconMessageBot',
                    payload: !state.iconMessageBot,
                  });
                }}
              />
              {wWebchat.iconBot}
            </StyledLabel>
          </WrapperDiv>
          {state.userIcon ? (
            <WrapperColumnUpload alignItem={'flex-start'}>
              <WrapperDiv mr={'0 0 1rem 0'}>{wWebchat.avatarUser}</WrapperDiv>
              <CircleImg width={'80'} height={'80'}>
                <StyledImage width={'80'} src={state.userIcon} alt="" />
              </CircleImg>
              <ButtonWrapper>
                <span>{wWebchat.changeIcon}</span>
                <InputUpload
                  type="file"
                  onChange={(e) => {
                    handleChangeAvatar(e, 'setUserIcon');
                  }}
                />
              </ButtonWrapper>
            </WrapperColumnUpload>
          ) : (
            <WrapperColumnUpload>
              <WrapperDiv mr={'0'}>{wWebchat.changeIconSend}</WrapperDiv>
              <WrapperDiv mr={'0 0 2rem 0'}>
                <StyledUpload>
                  <Upload />
                  <div>
                    <p>{w.download}</p>
                  </div>
                  <InputFile
                    type="file"
                    onChange={(e) => {
                      handleChangeAvatar(e, 'setUserIcon');
                    }}
                  />
                </StyledUpload>
              </WrapperDiv>
            </WrapperColumnUpload>
          )}
          {state.botIcon ? (
            <WrapperColumnUpload alignItem={'flex-start'}>
              <WrapperDiv mr={'2rem 0 1rem 0'}>{wWebchat.avatarBot}</WrapperDiv>
              <CircleImg width={'80'} height={'80'}>
                <StyledImage width={'80'} src={state.botIcon} alt="" />
              </CircleImg>
              <ButtonWrapper>
                <span>{wWebchat.changeIcon}</span>
                <InputUpload
                  type="file"
                  onChange={(e) => {
                    handleChangeAvatar(e, 'setBotIcon');
                  }}
                />
              </ButtonWrapper>
            </WrapperColumnUpload>
          ) : (
            <WrapperColumnUpload>
              <WrapperDiv mr={'0'}>{wWebchat.changeBot}</WrapperDiv>
              <WrapperDiv mr={'0 0 2rem 0'}>
                <StyledUpload>
                  <Upload />
                  <div>
                    <p>{w.download}</p>
                  </div>
                  <InputFile
                    type="file"
                    onChange={(e) => {
                      handleChangeAvatar(e, 'setBotIcon');
                    }}
                  />
                </StyledUpload>
              </WrapperDiv>
            </WrapperColumnUpload>
          )}
          <WrapperColumn>
            <SubtitleLight>{wWebchat.iconPosition}</SubtitleLight>
            <div>
              <StyledLabel radio flex widht={'20'} noMargin>
                <InputRadio
                  type="radio"
                  value="flex-start"
                  checked={state.iconPosition === 'flex-start'}
                  onChange={(e) => {
                    dispatch({
                      type: 'setIconPosition',
                      payload: e.target.value,
                    });
                  }}
                />
                {wWebchat.top}
              </StyledLabel>
              <StyledLabel radio flex widht={'20'} noMargin>
                <InputRadio
                  type="radio"
                  value="center"
                  checked={state.iconPosition === 'center'}
                  onChange={(e) => {
                    dispatch({
                      type: 'setIconPosition',
                      payload: e.target.value,
                    });
                  }}
                />
                {wWebchat.middle}
              </StyledLabel>
              <StyledLabel radio flex widht={'20'} noMargin>
                <InputRadio
                  type="radio"
                  value="flex-end"
                  checked={state.iconPosition === 'flex-end'}
                  onChange={(e) => {
                    dispatch({
                      type: 'setIconPosition',
                      payload: e.target.value,
                    });
                  }}
                />
                {wWebchat.bottom}
              </StyledLabel>
            </div>
          </WrapperColumn>
          <WrapperColumn></WrapperColumn>
          <WrapperDiv mr={'0 2rem'}>
            <SubtitleLight>{wWebchat.colorBubbleBot}</SubtitleLight>
          </WrapperDiv>
          <WrapperColumn justifyContent={'space-around'} margin={'1rem 0'}>
            <div>
              <SubtitleLight margin={'1rem 0'}>
                {wWebchat.colorBubble}
              </SubtitleLight>
              <ChromePicker
                color={state.colorBubbleBot}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setColorBubbleBot', payload: val.hex });
                }}
              />
            </div>
            <div>
              <SubtitleLight margin={'1rem 0'}>
                {wWebchat.colorFont}
              </SubtitleLight>
              <ChromePicker
                color={state.colorBubbleFontBot}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setColorBubbleFontBot', payload: val.hex });
                }}
              />
            </div>
          </WrapperColumn>
          <WrapperDiv mr={'0 2rem'}>
            <SubtitleLight>{wWebchat.colorBubbleUser}</SubtitleLight>
          </WrapperDiv>
          <WrapperColumn justifyContent={'space-around'} margin={'1rem 0'}>
            <div>
              <SubtitleLight margin={'1rem 0'}>
                {wWebchat.colorBubble}
              </SubtitleLight>
              <ChromePicker
                color={state.colorBubbleUser}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setColorBubbleUser', payload: val.hex });
                }}
              />
            </div>
            <div>
              <SubtitleLight margin={'1rem 0'}>
                {wWebchat.colorFont}
              </SubtitleLight>
              <ChromePicker
                color={state.colorBubbleFontUser}
                onChangeComplete={(val) => {
                  dispatch({
                    type: 'setColorBubbleFontUser',
                    payload: val.hex,
                  });
                }}
              />
            </div>
          </WrapperColumn>
          <WrapperColumn justifyContent={'space-around'}>
            <div>
              <SubtitleLight>{wWebchat.fontSize}</SubtitleLight>
              <Slider
                trackStyle={{ backgroundColor: 'red', height: '10px' }}
                railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                handleStyle={{
                  width: '20px',
                  height: '20px',
                  border: '1px solid lightgray',
                  boxShadow:
                    'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                }}
                min={0.6}
                max={1.2}
                step={0.1}
                value={state.fontSizeMessage?.slice(0, -2)}
                onChange={(value) => {
                  dispatch({
                    type: 'setFontSizeMessage',
                    payload: `${value}em`,
                  });
                }}
                handle={handle}
              />
            </div>
          </WrapperColumn>
          {state.templateAnimation.v2 && (
            <>
              <WrapperDiv>
                <SubtitleLight>{wWebchat.colorAnimationBot}</SubtitleLight>
              </WrapperDiv>
              <WrapperDiv mr={'1rem 3rem'}>
                <ChromePicker
                  color={state.colorAnimation}
                  onChangeComplete={(val) => {
                    dispatch({ type: 'setColorAnimation', payload: val.hex });
                  }}
                />
              </WrapperDiv>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Message;
