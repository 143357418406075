import React from 'react';
import styled from 'styled-components';
import { Icon } from '@ask-mona/ui';
import { withMain } from '../../hoc/main';

const Header = ({ title, subtitle, addons, titleaddon }) => {
  return (
    <Container id="base-header">
      <div className="flex center items-center">
        <Title className="has-text-primary-ter">{title}</Title>
        <IconContainer>
          {subtitle && <Icon icon="fas fa-caret-right" />}
        </IconContainer>

        {subtitle && (
          <SubTitle className="has-text-primary">{subtitle}</SubTitle>
        )}
        {titleaddon && titleaddon}
      </div>
      <AddonsContainer>{addons}</AddonsContainer>
    </Container>
  );
};

export default withMain(Header);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 1.2rem;
  @media (max-width: 1400px) {
    padding: 5px;
  }
`;

const Title = styled.span`
  color: ${(p) => p.theme.colors.primary};
  margin-left: 20px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${(p) => p.theme.colors.primary};
`;

const SubTitle = styled.span`
  color: ${(p) => p.theme.colors.primary};
`;

const AddonsContainer = styled.div`
  display: flex;
  height: 100%;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;
