export default {
  desactivate: ['Désactiver le chatbot', 'Disable the chatbot'],
  title: [
    "Paramétrer un message d'information",
    'Set up an information message',
  ],
  subtitle: [
    'Ce message sera envoyé automatiquement aux utilisateurs tant que le chatbot est désactivé.',
    'This message will be sent automatically to users as long as the chatbot is disabled.',
  ],
  placeholder: ["Saisir ici votre message d'information", 'Enter your information message here'],
};
