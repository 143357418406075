import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { Icon } from '@ask-mona/ui';
import Radio from '@material-ui/core/Radio';

export default ({
  m: { w },
  api: { event },
  botID,
  focus,
  subfocus,
  lang,
  element,
  dispatch,
  index,
  fix,
  advanced,
  random,
  arrayOfElements,
  newIndex,
  logicString,
}) => {
  const [eventLoading, setEventLoading] = useState(true);
  const [tagLoading, setTagLoading] = useState(true);
  const [eventOptions, setEventOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [eventIdList, setEventIdList] = useState([]);
  const [tagIdList, setTagIdList] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [eventOrTagSwitch, setEventOrTagSwitch] = useState('');

  const [updatedIdList, setUpdatedIdList] = useState(false);

  useEffect(() => {
    logicString.includes('LOGIC_PROG_TAGS')
      ? setEventOrTagSwitch(true)
      : setEventOrTagSwitch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logicString]);

  useEffect(() => {
    let idString = '';
    let logicStringCopy = (' ' + logicString).slice(1);
    let idArray = [];
    if (logicString.includes('LOGIC_PROG_TAGS')) {
      let reg = new RegExp('LOGIC_PROG_TAGS');
      // eslint-disable-next-line
      idString = logicStringCopy.replace(reg, '').replace(/[\[\]\(\)]+/g, '');
      if (idString !== 'event_id') {
        let regSpace = new RegExp(' ', 'g');
        idString = idString.replace(regSpace, '');
        idArray = idString.split(',');
        idArray = idArray.map((el) => {
          return parseInt(el);
        });
        idArray = idArray.filter((el) => (isNaN(el) ? false : true));
        setTagIdList(idArray);
      }
    } else if (logicString.includes('LOGIC_PROG_EVENT')) {
      let reg = new RegExp('LOGIC_PROG_EVENT');
      // eslint-disable-next-line
      idString = logicStringCopy.replace(reg, '').replace(/[\[\]\(\)]+/g, '');
      if (idString !== 'event_id') {
        let regSpace = new RegExp(' ', 'g');
        idString = idString.replace(regSpace, '');
        idArray = idString.split(',');
        idArray = idArray.map((el) => {
          return parseInt(el);
        });
        idArray = idArray.filter((el) => (isNaN(el) ? false : true));
        setEventIdList(idArray);
      }
    }
    setUpdatedIdList(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventOrTagSwitch, logicString]);

  useEffect(() => {
    if (!eventOrTagSwitch) {
      let savedEvents = [];
      eventOptions.map((event) => {
        if (eventIdList.includes(event.value)) {
          savedEvents = [...savedEvents, event.label];
        }
        return event;
      });
      setSelectedEvents(savedEvents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventOptions, eventIdList]);

  useEffect(() => {
    if (eventOrTagSwitch) {
      let savedTags = [];
      tagOptions.map((tag) => {
        if (tagIdList.includes(tag.value)) {
          savedTags = [...savedTags, tag.label];
        }
        return tag;
      });
      setSelectedTags(savedTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagOptions, tagIdList]);

  useEffect(() => {
    const fetchEvents = async () => {
      const res = await event._getSimpleListEvents({
        bot_id: botID,
        limit: 1000,
      });
      if (res) {
        setEventLoading(false);
        if (res.success) {
          setEventOptions(
            res.getEvents.events.map((event) => {
              return {
                value: event.id,
                label:
                  event.translations &&
                  event.translations[0] &&
                  event.translations[0].short_title
                    ? event.translations[0].short_title
                    : event.translations &&
                      event.translations[0] &&
                      event.translations[0].long_title &&
                      event.translations[0].long_title,
              };
            })
          );
        }
      }
    };
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchTags = async () => {
      const res = await event._getTags({ bot_id: botID });
      if (res) {
        setTagLoading(false);
        if (res.success) {
          setTagOptions(
            res.getTags.tags.map((tag) => {
              return { value: tag.id, label: tag.slug };
            })
          );
        }
      }
    };
    fetchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updatedIdList) {
      let newArray = [];
      if (random) {
        newArray = [...arrayOfElements];
        newArray[newIndex] = [
          eventOrTagSwitch
            ? `LOGIC_PROG_TAGS(${tagIdList})`
            : `LOGIC_PROG_EVENT(${eventIdList})`,
        ];
      }
      dispatch({
        type: 'onElemChange',
        payload: {
          index,
          value: random
            ? newArray
            : eventOrTagSwitch
            ? `LOGIC_PROG_TAGS(${tagIdList})`
            : `LOGIC_PROG_EVENT(${eventIdList})`,
          func: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventOrTagSwitch]);

  return (
    <Container random={random} className="m-xs border-primary rad-15px">
      <SwitchContainer>
        <RadioContainer>
          <Radio
            checked={eventOrTagSwitch === false}
            onChange={(e) => {
              // eslint-disable-next-line
              setEventOrTagSwitch(e.target.value == 'true' ? true : false);
            }}
            value={false}
            name="event"
          />
          <RadioLabel>{w.scenario.perEvent}</RadioLabel>
        </RadioContainer>
        <RadioContainer>
          <Radio
            checked={eventOrTagSwitch === true}
            onChange={(e) => {
              // eslint-disable-next-line
              setEventOrTagSwitch(e.target.value == 'true' ? true : false);
            }}
            value={true}
            name="tag"
          />
          <RadioLabel>{w.scenario.perTag}</RadioLabel>
        </RadioContainer>
      </SwitchContainer>
      {eventOrTagSwitch ? (
        <Title>{w.scenario.chooseOneOrManyTags}</Title>
      ) : (
        <Title>{w.scenario.chooseOneOrManyEvents}</Title>
      )}

      <SelectContainer>
        <Select
          defaultValue={eventOrTagSwitch ? tagOptions[0] : eventOptions[0]}
          options={
            eventOrTagSwitch === true
              ? tagOptions
              : eventOrTagSwitch === false && eventOptions
          }
          isDisabled={
            eventOrTagSwitch === true
              ? tagLoading
              : eventOrTagSwitch === false && eventLoading
          }
          className="w-100p"
          onChange={(option) => {
            if (eventOrTagSwitch === true) {
              let newArray = [];
              if (random) {
                newArray = [...arrayOfElements];
                newArray[newIndex] = [
                  `LOGIC_PROG_TAGS(${[...tagIdList, option.value]})`,
                ];
              }
              !selectedTags.includes(option.label) &&
                setSelectedTags([...selectedTags, option.label]);
              !tagIdList.includes(option.value) &&
                dispatch({
                  type: 'onElemChange',
                  payload: {
                    index,
                    value: random
                      ? newArray
                      : `LOGIC_PROG_TAGS(${[...tagIdList, option.value]})`,
                    func: null,
                  },
                });
              !tagIdList.includes(option.value) &&
                setTagIdList([...tagIdList, option.value]);
            } else if (eventOrTagSwitch === false) {
              let newArray = [];
              if (random) {
                newArray = [...arrayOfElements];
                newArray[newIndex] = [
                  `LOGIC_PROG_EVENT(${[...eventIdList, option.value]})`,
                ];
              }
              !selectedEvents.includes(option.label) &&
                setSelectedEvents([...selectedEvents, option.label]);
              !eventIdList.includes(option.value) &&
                dispatch({
                  type: 'onElemChange',
                  payload: {
                    index,
                    value: random
                      ? newArray
                      : `LOGIC_PROG_EVENT(${[...eventIdList, option.value]})`,
                    func: null,
                  },
                });
              !eventIdList.includes(option.value) &&
                setEventIdList([...eventIdList, option.value]);
            }
          }}
        />
      </SelectContainer>
      <Content>
        {eventOrTagSwitch
          ? tagLoading && <div>{w.scenario.loadingTags}</div>
          : eventLoading && <div>{w.scenario.loadingEvents}</div>}
        {!eventOrTagSwitch &&
          selectedEvents &&
          selectedEvents.map((event) => (
            <SelectedElement>
              <EventElement>- {event}</EventElement>
              <IconElement
                onClick={() => {
                  let newArray = [];
                  if (random) {
                    newArray = [...arrayOfElements];
                    newArray[newIndex] = [
                      `LOGIC_PROG_EVENT(${[
                        eventIdList.filter(
                          (id) =>
                            id !==
                            parseInt(
                              eventOptions.filter((el) => el.label === event)[0]
                                .value
                            )
                        ),
                      ]})`,
                    ];
                  }
                  dispatch({
                    type: 'onElemChange',
                    payload: {
                      index,
                      value: random
                        ? newArray
                        : `LOGIC_PROG_EVENT(${[
                            eventIdList.filter(
                              (id) =>
                                id !==
                                parseInt(
                                  eventOptions.filter(
                                    (el) => el.label === event
                                  )[0].value
                                )
                            ),
                          ]})`,
                      func: null,
                    },
                  });
                  setSelectedEvents(
                    selectedEvents.filter((el) => el !== event)
                  );
                  setEventIdList(
                    eventIdList.filter(
                      (id) =>
                        id !==
                        parseInt(
                          eventOptions.filter((el) => el.label === event)[0]
                            .value
                        )
                    )
                  );
                }}
              >
                <Icon icon="fas fa-times" />
              </IconElement>
            </SelectedElement>
          ))}
        {eventOrTagSwitch &&
          selectedTags &&
          selectedTags.map((tag) => (
            <SelectedElement>
              <EventElement>- {tag}</EventElement>
              <IconElement
                onClick={() => {
                  let newArray = [];
                  if (random) {
                    newArray = [...arrayOfElements];
                    newArray[newIndex] = [
                      `LOGIC_PROG_TAGS(${[
                        tagIdList.filter(
                          (id) =>
                            id !==
                            parseInt(
                              tagOptions.filter((el) => el.label === tag)[0]
                                .value
                            )
                        ),
                      ]})`,
                    ];
                  }
                  dispatch({
                    type: 'onElemChange',
                    payload: {
                      index,
                      value: random
                        ? newArray
                        : `LOGIC_PROG_TAGS(${[
                            tagIdList.filter(
                              (id) =>
                                id !==
                                parseInt(
                                  tagOptions.filter((el) => el.label === tag)[0]
                                    .value
                                )
                            ),
                          ]})`,
                      func: null,
                    },
                  });
                  setSelectedTags(selectedTags.filter((el) => el !== tag));
                  setTagIdList(
                    tagIdList.filter(
                      (id) =>
                        id !==
                        parseInt(
                          tagOptions.filter((el) => el.label === tag)[0].value
                        )
                    )
                  );
                }}
              >
                <Icon icon="fas fa-times" />
              </IconElement>
            </SelectedElement>
          ))}
      </Content>
      {random && (
        <RemoveElementButton
          onClick={() => {
            let newArray = [...arrayOfElements];
            newArray = newArray.filter((el) => el[0] !== logicString);
            dispatch({
              type: 'onElemChange',
              payload: {
                index,
                value: newArray,
                func: null,
              },
            });
          }}
        >
          <Icon icon="fas fa-times" />
        </RemoveElementButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: ${(p) => (p.random ? '350px' : '50%')};
  position: relative;
  height: 350px;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 0px;
`;

const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
`;

const RadioLabel = styled.div`
  margin-left: 3px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(p) => p.theme.colors.primary};
  font-size: 0.9rem;
`;

const Title = styled.div`
  height: 50px;
  padding: 0 20px 0;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.primary};
`;

const SelectContainer = styled.div`
  height: 50px;
  padding: 0 20px 0;
  margin-bottom: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  padding: 0 20px 0;
  height: 170px;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(p) => p.theme.colors.primary};
  overflow: scroll;
`;

const SelectedElement = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  padding-top: 10px;
  font-size: 14px;
`;

const EventElement = styled.div``;

const IconElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.secondary};
  cursor: pointer;
`;

const RemoveElementButton = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  color: gray;
  background-color: #ececec;
  border-radius: 20px;
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
`;
