import { Component } from 'react';
import ReactDOM from 'react-dom';

class Portal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.style.flex = 'auto';
  }

  componentDidMount() {
    const { selector } = this.props;
    this.targetEl = document.getElementById(selector);
    this.el && this.targetEl && this.targetEl.appendChild(this.el);
  }

  componentWillUnmount() {
    this.el && this.targetEl && this.targetEl.removeChild(this.el);
  }

  render() {
    const { component } = this.props;
    return ReactDOM.createPortal(component, this.el);
  }
}

export default Portal;
