import React from 'react';
import { _mergePlaces } from '../../../_tools/other';
import { Switch, Button, Icon } from '@ask-mona/ui';
import { _sendFeedback } from '../../../_tools/ui';

export default ({
  dispatch,
  placeData: { getBotHasPlaces },
  botData: { getBot },
  ...other
}) => {
  let items = _mergePlaces(getBot.places);
  const {
    api,
    m: { w, bot },
  } = other;
  return (
    <React.Fragment>
      {items.map((o) => {
        let checked = o.itinerary;
        if (typeof checked !== 'boolean') {
          let pl = getBotHasPlaces.find((p) => p.place_id === o.pID);
          checked = pl ? pl.itinerary : false;
        }
        return (
          <div
            key={o.id}
            className="flex row items-center border-b-primary-lighter has-text-primary"
          >
            <div className="border-r-primary-lighter p-sm flex grow-3 basis-0">
              {o.name}
            </div>
            <div className="border-r-primary-lighter p-sm flex grow-3 basis-0">
              {o.address}
            </div>
            <div className="border-r-primary-lighter p-sm flex grow-1 basis-0 center">
              <Switch
                color="secondary"
                defaultChecked={checked}
                onChange={async () => {
                  let res = null;
                  _sendFeedback('warning', 'loader');
                  if (o.sub)
                    res = await api.place._updateSubPlace({
                      id: o.pID,
                      itinerary: !checked,
                    });
                  else
                    res = await api.place._updateBotHasPlace({
                      bot_id: bot.id,
                      place_id: o.pID,
                      itinerary: !checked,
                    });
                  if (res.success) _sendFeedback('success', w.success.saved);
                  else _sendFeedback('danger', w.error.generic);
                }}
              />
            </div>
            <div className="p-sm flex grow-1 basis-0 center">
              <Button
                className="shrink is-borderless is-outlined"
                onClick={() => dispatch({ type: 'setEdit', payload: o })}
              >
                <Icon className="has-text-primary" icon="fas fa-edit" />
              </Button>
              <Button
                className="shrink is-borderless is-outlined"
                color="danger"
                onClick={async () => {
                  let res = null;
                  _sendFeedback('warning', 'loader');
                  if (o.sub)
                    res = await api.place._deleteSubPlace(
                      { id: o.pID },
                      { refetchQueries: ['getBot', 'getBotHasPlaces'] }
                    );
                  else
                    res = await api.place._deleteBotHasPlace(
                      { bot_id: bot.id, place_id: o.pID },
                      { refetchQueries: ['getBot', 'getBotHasPlaces'] }
                    );
                  if (res.success) _sendFeedback('success', w.success.saved);
                  else _sendFeedback('danger', w.error.generic);
                }}
              >
                <Icon icon="fas fa-trash" />
              </Button>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};
