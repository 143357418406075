import React, { Component } from 'react';
import PlaceSelector from '../../../components/place/PlaceSelector';
import PlaceCreator from '../../../components/place/PlaceCreator';
import Modal from '../../../components/Modal';
import { Field, Select } from '@ask-mona/ui';
import { _getKeyValue } from '../../../_tools/other';

class Place extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  onClose = () => {
    this.setState({ isOpen: false, default: null });
  };

  onCreate = (item) => {
    this.setState({ isOpen: true, default: item.value });
  };

  onSelect = (place) => {
    const { fct } = this.props;
    fct.setPlace(place);
  };

  onSelectSub = (subplace) => {
    const { fct } = this.props;
    fct.setSubPlace(subplace);
  };

  onAdd = async (place) => {
    const { fct } = this.props;
    this.setState({ isOpen: false });
    fct.setPlace(place);
  };

  render() {
    const {
      m: { w },
      event,
      errors,
    } = this.props;
    const place = _getKeyValue(event, 'place') || { name: '', address: '' };
    const sub_place_id = _getKeyValue(event, 'sub_place_id');

    return (
      <>
        <Field
          title={w.place.name}
          className="m-b-md"
          information={place.address}
          error={errors.place ? w.error.missingPlace : ''}
        >
          <PlaceSelector
            defaultValue={place.name}
            create={`${w.place.createPlace}: `}
            onSelect={this.onSelect}
            onCreate={this.onCreate}
          />
        </Field>
        {place.sub_places && place.sub_places.length > 0 && (
          <Field title={w.place.nameSub} className="m-b-md">
            <Select
              defaultValue={
                sub_place_id
                  ? place.sub_places.find((el) => el.id === sub_place_id).name
                  : ''
              }
              items={place.sub_places.map((el, i) => ({
                id: el.id,
                value: el.name,
              }))}
              onSelect={this.onSelectSub}
              className="w-100p"
            />
          </Field>
        )}
        <Modal isOpen={this.state.isOpen} close={this.onClose} selector="#root">
          <PlaceCreator
            onSuccess={this.onAdd}
            onCancel={this.onClose}
            default={this.state.default}
          />
        </Modal>
      </>
    );
  }
}

export default Place;
