import React, { useState } from 'react';
import { Button, Icon } from '@ask-mona/ui';

export default (props) => {
  const {
    m: { w },
    advanced,
    dispatch,
  } = props;
  const [hover, setHover] = useState(false);
  return (
    <div
      key="logicMenu"
      className={`w-100p p-xs flex center space-nowrap overflow-auto hideScroll ${
        hover ? 'has-background-danger-light' : ''
      }`}
    >
      {[
        w.scenario.prog,
        w.scenario.schedule,
        w.scenario.pricing,
        w.scenario.direction,
      ].map((m) => (
        <div
          key={m}
          className={`p-sm is-size-7 has-background-white border-grey-lighter inline-block rad-15px overflow-auto m-xs space-initial text-align-center`}
        >
          {m}
        </div>
      ))}
      {advanced && (
        <Button
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className="is-outlined is-borderless shrink"
          color="danger"
          onClick={() => dispatch({ type: 'removeLogicMenu', payload: {} })}
        >
          <Icon icon="fas fa-trash" />
        </Button>
      )}
    </div>
  );
};
