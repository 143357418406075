import React, { useState } from 'react';
import { Button, Icon } from '@ask-mona/ui';
import { _rotateArray } from '../../_tools/other';
import { fullDays } from '../../translations/';
import { getLanguageId } from "../../_tools/translation";

export default ({ g: { w }, ...other }) => {
  const { block } = other;
  const languageId = getLanguageId(localStorage.getItem("languageCode"));

  let str = '';
  block.days.slice(0, 3).forEach((d, i) => {
    str = `${str}${i === 0 ? '' : ', '}${fullDays[d][languageId - 1]}`;
  });
  if (block.days.length > 3) str = `${str}...`;

  const [fixed, setFixed] = useState(false);

  return (
    <div>
      <div
        className={`pointer flex space-between ${
          str ? 'border-b-primary-ter' : ''
        }`}
        onClick={() => setFixed(true)}
      >
        {str}
        {str && <Icon icon="fas fa-caret-down" />}
      </div>
      {!str && (
        <Button
          className="is-outlined is-borderless"
          onClick={() => setFixed(true)}
        >
          <span className="is-underlined is-size-7">{w.schedule.addDays}</span>
        </Button>
      )}
      {fixed && (
        <div className="border-primary-ter animation-fadeInFast absolute has-background-white p-xs arrow-primary-ter rad-5">
          <DaysSelector {...other} />
        </div>
      )}
    </div>
  );
};

const DaysSelector = ({ onChange, onValidate, block }) => {
  const [selected, setSelected] = useState(block.days);
  const languageId = getLanguageId(localStorage.getItem("languageCode"));

  const sdays = fullDays.map((d, i) => ({ id: i, name: d[languageId - 1] }));
  _rotateArray(sdays, 1);

  return (
    <div className="flex wrap">
      {sdays.map((d) => {
        let isActive =
          selected.findIndex((id) => id === d.id) !== -1 ? true : false;
        return (
          <div
            key={d.name}
            className={`
                ${isActive ? 'has-background-primary-light' : ''}
                pointer p-xs flex center grow-1 basis-0
                border-primary-ter
              `}
            onClick={() => {
              setSelected(
                isActive
                  ? selected.filter((id) => id !== d.id).sort()
                  : selected.concat(d.id).sort()
              );
            }}
          >
            {d.name}
          </div>
        );
      })}
      <Button
        color="success"
        className="shrink is-outlined m-l-xs"
        onClick={() => {
          onChange && onChange({ ...block, days: selected });
        }}
      >
        <Icon icon="fas fa-check" />
      </Button>
    </div>
  );
};
