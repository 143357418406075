import React, { Component } from 'react';
import Case from '../../../components/singleton/Case';
import { withApollo } from 'react-apollo';
import defaultImg from '../../../_assets/default.png';
import { Icon } from '@ask-mona/ui';

class PhoneCase extends Component {
  state = {
    lang: this.props.data.lang,
  };

  render() {
    const {
      data: {
        contentPush: { save },
        errorContent: { empty },
      },
      array,
      status,
    } = this.props;
    return (
      <div
        className={
          !status || save
            ? 'lex center items-center border-l-white-ter disabled'
            : 'lex center items-center border-l-white-ter'
        }
        style={!status || save ? { opacity: '.5' } : { opacity: '1' }}
      >
        <Case>
          <div className="flex row">
            <div className="w-90p m-xs">
              {array.length === 0 && !empty && (
                <div className="has-background-primary-lighter rad-15px p-md flex row center is-size-7 has-text-primary-ter">
                  Prévisualiser votre contenu ici
                </div>
              )}
              {array.length === 0 && empty && (
                <div className="flex row center has-background-danger-light rad-15px p-md is-size-7 has-text-danger">
                  Rédiger le contenu de votre push
                </div>
              )}
              {array.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    {item.name === 'Texte' && item.content && (
                      <div className="has-background-primary-lighter rad-15px u-p-7 flex row center is-size-7 has-text-primary-ter">
                        <div>
                          {item.content.text === ''
                            ? 'Ajouter du texte'
                            : item.content.text}
                        </div>
                      </div>
                    )}
                    {item.name === 'Image' && item.content && (
                      <div className="frame">
                        <img
                          className="h-20 w-60p center"
                          src={
                            item.content.attachment.payload.url === ''
                              ? defaultImg
                              : item.content.attachment.payload.url
                          }
                          alt="img"
                        />
                      </div>
                    )}
                    {item.name === 'Vidéo' && item.content && (
                      <div>
                        <video
                          className="frame w-90p"
                          controls
                          src={item.content.attachment.payload.url}
                        >
                          <source
                            src={item.content.attachment.payload.url}
                            type={`video/webm`}
                          />
                        </video>
                      </div>
                    )}
                    {item.name === 'Carousel' && item.content && (
                      <div>
                        <div className="flex row h-100 overflow-auto flex-wra">
                          {item.content.attachment.payload.elements.map(
                            (item, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <div className="flex-auto w-50p h-90 m-xs has-background-primary-lighter p-xs overflow-auto rad-10px">
                                    <div className="m-b-3">
                                      <img
                                        className="w-100p"
                                        src={
                                          item.image_url !== ''
                                            ? item.image_url
                                            : defaultImg
                                        }
                                        alt="img"
                                      />
                                    </div>
                                    <div className="flex column">
                                      <div
                                        className="is-size-7 w-100p break-word has-text-primary-ter"
                                        style={{ fontSize: '0.7rem' }}
                                      >
                                        {item.title}
                                      </div>
                                      <div
                                        className="is-size-7 w-100p break-word has-text-primary-ter"
                                        style={{ fontSize: '0.6rem' }}
                                      >
                                        {item.subtitle}
                                      </div>
                                      <div
                                        className="is-size-7 w-100p break-word has-text-primary-ter"
                                        style={{ fontSize: '0.6rem' }}
                                      >
                                        {item.default_action !== undefined
                                          ? item.default_action.url
                                          : null}
                                      </div>
                                    </div>
                                    {item.buttons &&
                                      item.buttons.length > 0 &&
                                      item.buttons.map((btn, k) => {
                                        return (
                                          <a href={btn.url} key={k}>
                                            <div
                                              className="has-background-primary-qua rad-10px u-p-7 flex row center is-size-6 has-text-primary-ter m-b-xs"
                                              style={{ fontSize: '0.6rem' }}
                                            >
                                              {btn.title}
                                            </div>
                                          </a>
                                        );
                                      })}
                                  </div>
                                </React.Fragment>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="flex column flex-end">
              <Icon style={{ color: '#7a7ab8' }} icon="fas fa-robot" />
            </div>
          </div>
        </Case>
      </div>
    );
  }
}

export default withApollo(PhoneCase);
