import { _random } from '../../../_tools/other';
import Bot from '../../../_api/bot';
// REDUCER AND STATE
export const _reducer = (state, action) => {
  switch (action.type) {
    case 'setHasChange':
      return { ...state, hasChange: action.payload };
    case 'getInfo':
      return { ...state, getInfo: action.payload };
    case 'setItems':
      return {
        ...state,
        items: _sortItems(action.payload, state.items),
        hasChange: !action.first ? true : false,
      };
    case 'edit':
      return { ...state, edit: action.payload };
    case 'delt':
      return {
        ...state,
        items: _sortItems(
          state.items.filter((o) => o.id !== action.payload.id)
        ),
        hasChange: true,
      };
    case 'copy':
      let newItems = [...state.items];
      let copyIndex = null;
      let copyItem = state.items.find((o, i) => {
        if (o.id === action.payload.id) {
          copyIndex = i;
          return true;
        }
        return false;
      });
      copyItem = { ...copyItem, id: _random() };
      newItems.splice(copyIndex, 0, copyItem);
      return { ...state, items: newItems, hasChange: true };
    default:
      return state;
  }
};

export const initialState = (props) => {
  const {
    m: { bot, client },
  } = props;
  let apiBot = new Bot(client, bot.id);
  return {
    apiBot: apiBot,
    hasChange: false,
    items: null,
    edit: null,
  };
};

// UTILITIES FUNCTIONS
const _convertData = (data) => {
  const { name, description, recipient, ...other } = data;
  let newData = { ...other };

  if (typeof name === 'string')
    newData = { ...newData, name: { fr: name, en: '' } };
  else newData = { ...newData, name: name || { fr: '', en: '' } };

  if (typeof description === 'string')
    newData = { ...newData, description: { fr: description, en: '' } };
  else newData = { ...newData, description: description || { fr: '', en: '' } };

  if (Array.isArray(recipient))
    newData = { ...newData, recipient: { fr: recipient, en: [] } };
  else newData = { ...newData, recipient: recipient || { fr: [], en: [] } };

  return newData;
};

export const _jsonToItems = (obj) => {
  let ret = [];

  try {
    const loop = (obj, key = 'start') => {
      let stack = {};
      let data = {};
      for (let k in obj) {
        if (
          typeof obj[k] === 'object' &&
          !Array.isArray(obj[k]) &&
          k !== 'description' &&
          k !== 'name' &&
          k !== 'recipient' &&
          k !== 'information'
        )
          stack = { ...stack, [k]: obj[k] };
        else data = { ...data, [k]: obj[k] };
      }
      if (Object.keys(data).length) {
        let type = -1;
        if (key.match(/^categorie/)) type = 0;
        else if (key.match(/^sub_categorie/)) type = 1;
        else if (key.match(/^tarif/)) type = 2;

        let newData = _convertData(data);

        ret.push({ id: _random(), type, ...newData });
      }
      for (let k in stack) loop(stack[k], k);
    };
    loop(obj);
  } catch (err) {
    console.log(err);
  }

  return ret;
};

export const _itemsToJson = (items) => {
  let stack = {};
  let part = false;
  let subPart = false;
  let counter = {
    categorie: 0,
    sub_categorie: 0,
    tarif: 0,
  };
  _sortItems(items);
  for (let i = 0, len = items.length; i < len; i++) {
    let { id, type, ...curr } = items[i];
    switch (type) {
      case 0:
        part = true;
        subPart = false;
        counter['sub_categorie'] = 0;
        counter['tarif'] = 0;
        delete curr['recipient'];
        stack = {
          ...stack,
          [`categorie_${counter['categorie']}`]: { ...curr, has_sub: false },
        };
        counter['categorie']++;
        break;
      case 1:
        if (part) {
          subPart = true;
          let cat = stack[`categorie_${counter['categorie'] - 1}`];
          delete curr['recipient'];
          cat = {
            ...cat,
            has_sub: true,
            [`sub_categorie_${counter['sub_categorie']}`]: curr,
          };
          stack[`categorie_${counter['categorie'] - 1}`] = cat;
          counter['sub_categorie']++;
        }
        break;
      case 2:
        if (subPart) {
          let sub =
            stack[`categorie_${counter['categorie'] - 1}`][
              `sub_categorie_${counter['sub_categorie'] - 1}`
            ];
          sub = { ...sub, [`tarif_${counter['tarif']}`]: curr };
          stack[`categorie_${counter['categorie'] - 1}`][
            `sub_categorie_${counter['sub_categorie'] - 1}`
          ] = sub;
          counter['tarif']++;
        } else if (part) {
          let cat = stack[`categorie_${counter['categorie'] - 1}`];
          cat = { ...cat, [`tarif_${counter['tarif']}`]: curr };
          stack[`categorie_${counter['categorie'] - 1}`] = cat;
          counter['tarif']++;
        } else {
          stack = { ...stack, [`tarif_${counter['tarif']}`]: curr };
          counter['tarif']++;
        }
        break;
      default:
        break;
    }
  }
  stack = JSON.stringify(stack);
  return stack;
};

const _sortItems = (items = [], last) => {
  let sorted = [...items];
  let error = false;
  let sortedFindIndex = (y) =>
    sorted.findIndex((c, offset) => c.type === 0 && offset > y);
  for (let i = 0, len = sorted.length; i < len; i++) {
    let curr = sorted[i];
    let type = curr.type;
    if (type === 0) {
      let pos = i + 1;
      let y = pos;
      for (; y < len; y++) {
        let next = sorted[y];
        let ntype = next.type;
        if (ntype === 0) {
          y--;
          break;
        } else if (ntype === 2) {
          continue;
        } else if (ntype === 1) {
          sorted.splice(y, 1);
          sorted.splice(pos, 0, next);
          y = sortedFindIndex(y);
          y = y !== -1 ? y - 1 : sorted.length;
          break;
        }
      }
      i = y;
    } else if (type === 1) {
      error = true;
      break;
    }
  }
  return error ? last : sorted;
};
