import React from 'react';
import { withGlobals } from '../../../hoc/globals';
import DateRange from '../../../components/DateRange';
import Filter from '../../../components/base/Filter';

const Dates = ({ g: { w }, onChange }) => (
  <Filter
    icon="fas fa-calendar-alt"
    label={w.events.dates}
    padding="-"
    overflow="-"
  >
    <div className="p-md flex row">
      <DateRange onChange={onChange} small showClearDates />
    </div>
  </Filter>
);

export default withGlobals(Dates);
