import React, { useContext, useState } from 'react';
import { _uploadImg, _uploadVideo } from '../../../../_api/upload';
import { _sendFeedback } from "../../../../_tools/ui";
import { QAContext } from '../../context/QAContext';
import Text from './Text';
import { Image, Video } from '@ask-mona/ui';

interface Props {
  content: {
    type: string;
    content: string;
  };
  id: number;
  answerId: number;
  isEditable: boolean;
  w: any;
}

const Content = (props: Props) => {
  const {
    content: { type, content },
  } = props;
  const { state, dispatch } = useContext(QAContext);
  const [loading, setLoading] = useState<boolean>(false);
  const contentParsed = JSON.parse(content);

  return (
    <>
      {((type) => {
        switch (type) {
          case 'image':
          case 'gif':
            return (
              <div>
                <Image
                  src={
                    contentParsed.value !== 'placeholder.png'
                      ? contentParsed.value
                      : ''
                  }
                  placeholder={'Saisir le lien'}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_TOPIC_ANSWER',
                      payload: {
                        value: e.target.value,
                        type: 'image',
                        remove: false,
                        buttons: null,
                        id: props.id,
                        answerId: props.answerId,
                      },
                    })
                  }
                  onUpload={async (e) => {
                    setLoading(true);
                    await _uploadImg(
                      state.botId,
                      e,
                      (res) => {
                        setLoading(false);
                        dispatch({
                          type: 'UPDATE_TOPIC_ANSWER',
                          payload: {
                            value: res.path,
                            type: 'image',
                            remove: false,
                            buttons: null,
                            id: props.id,
                            answerId: props.answerId,
                          },
                        });
                         _sendFeedback("success", props.w.saved);
                      },
                      (err) => {
                        setLoading(false);
                      }
                    );
                  }}
                />
              </div>
            );
          case 'video':
            return (
              <Video
                src={
                  contentParsed.value !== 'placeholder.webm'
                    ? contentParsed.value
                    : ''
                }
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_TOPIC_ANSWER',
                    payload: {
                      value: e.target.value,
                      type: 'image',
                      remove: false,
                      buttons: null,
                      id: props.id,
                      answerId: props.answerId,
                    },
                  })
                }
                onUpload={async (e) => {
                  setLoading(true);
                  await _uploadVideo(
                    state.botId,
                    e,
                    (res) => {
                      setLoading(false);
                      dispatch({
                        type: 'UPDATE_TOPIC_ANSWER',
                        payload: {
                          value: res.path,
                          type: 'video',
                          remove: false,
                          buttons: null,
                          id: props.id,
                          answerId: props.answerId,
                        },
                      });
                    },
                    (err) => {
                      setLoading(false);
                    }
                  );
                }}
              />
            );
          case 'text':
            if (contentParsed.type === 'image') {
              return (
                <div>
                  <Image
                    src={
                      contentParsed.value !== 'placeholder.png'
                        ? contentParsed.value
                        : ''
                    }
                    placeholder={'Saisir le lien'}
                    onChange={(e) =>
                      dispatch({
                        type: 'UPDATE_TOPIC_ANSWER',
                        payload: {
                          value: e.target.value,
                          type: 'image',
                          remove: false,
                          buttons: null,
                          id: props.id,
                          answerId: props.answerId,
                        },
                      })
                    }
                    onUpload={async (e) => {
                      setLoading(true);
                      await _uploadImg(
                        state.botId,
                        e,
                        (res) => {
                          setLoading(false);
                          dispatch({
                            type: 'UPDATE_TOPIC_ANSWER',
                            payload: {
                              value: res.path,
                              type: 'image',
                              remove: false,
                              buttons: null,
                              id: props.id,
                              answerId: props.answerId,
                            },
                          });
                          _sendFeedback("success", props.w.saved);
                        },
                        (err) => {
                          setLoading(false);
                        }
                      );
                    }}
                  />
                </div>
              );
            } else if (contentParsed.type === 'video') {
              return (
                <Video
                  src={
                    contentParsed.value !== 'placeholder.webm'
                      ? contentParsed.value
                      : ''
                  }
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_TOPIC_ANSWER',
                      payload: {
                        value: e.target.value,
                        type: 'image',
                        remove: false,
                        buttons: null,
                        id: props.id,
                        answerId: props.answerId,
                      },
                    })
                  }
                  onUpload={async (e) => {
                    setLoading(true);
                    await _uploadVideo(
                      state.botId,
                      e,
                      (res) => {
                        setLoading(false);
                        dispatch({
                          type: 'UPDATE_TOPIC_ANSWER',
                          payload: {
                            value: res.path,
                            type: 'video',
                            remove: false,
                            buttons: null,
                            id: props.id,
                            answerId: props.answerId,
                          },
                        });
                      },
                      (err) => {
                        setLoading(false);
                      }
                    );
                  }}
                />
              );
            } else {
              return (
                <Text
                  isEditable={props.isEditable}
                  value={contentParsed.value}
                  type="text"
                  id={props.id}
                  answerId={props.answerId}
                />
              );
            }
          case 'button':
            return (
              <Text
                isEditable={props.isEditable}
                value={
                  contentParsed.attachment?.payload?.text ||
                  contentParsed.value.attachment.payload.text
                }
                buttons={
                  contentParsed.attachment?.payload?.buttons ||
                  contentParsed.value.attachment.payload.buttons
                }
                type="button"
                id={props.id}
                answerId={props.answerId}
              />
            );
          default:
            return (
              <Text
                isEditable={props.isEditable}
                value={contentParsed.value}
                type="text"
                id={props.id}
                answerId={props.answerId}
              />
            );
        }
      })(type)}
    </>
  );
  // }
};

export default Content;
