import React, { Component } from 'react';
import { Checkbox, Icon } from '@ask-mona/ui';
import { withMain } from '../../../hoc/main';

class ListHeader extends Component {
  render() {
    const {
      m: { w },
      allPush,
      check,
      fct,
      funct,
    } = this.props;
    return (
      <div className="flex row has-background-primary is-size-6 has-text-white sticky top">
        <div className="m-b-md p-xs flex items-start">
          {check.checkListID && check.checkListID.length === allPush.length && (
            <Checkbox
              onClick={() => funct.checkAllPush()}
              defaultChecked={false}
            />
          )}
          {check.checkListID && check.checkListID.length !== allPush.length && (
            <Checkbox
              onClick={() => funct.checkAllPush()}
              defaultChecked={false}
            />
          )}
        </div>
        <div className="p-xs flex grow-2 basis-0 space-pre">
          {' '}
          {w.pushs.titlePush}
        </div>
        <div
          className="p-xs flex grow-2 basis-0 pointer"
          onClick={() => fct.sortByDate()}
        >
          {' '}
          {w.generic.date}
          <Icon
            icon="fas fa-chevron-down"
            style={{ fontSize: '.6rem', marginTop: '-1%' }}
          />
        </div>
        <div className="p-xs flex grow-2 basis-0"> {w.generic.status}</div>
        <div className="p-xs flex grow-1 basis-0 end center">
          {' '}
          {w.generic.action}
        </div>
      </div>
    );
  }
}

export default withMain(ListHeader);
