import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Answers as AnswersType } from '../../../types/qa/topics';
import Answer from '../answer/Answer';
import { Api } from '../../../api/graphql/hooks/useTopics';
import Modal from '../../../components/Modal';
import History from '../components/history/History';
import { QAContext } from '../context/QAContext';

interface Props {
  answers: AnswersType;
  api: Api;
  w: any;
}

const Answers = (props: Props) => {
  const [displayHistory, setDisplayHistory] = useState<boolean>(false);
  const { state } = useContext(QAContext);

  return (
    <div>
      {!!props.answers.filter((el) => !el.enabled).length && (
        <StyledContent onClick={() => setDisplayHistory(!displayHistory)}>
          <i className="fas fas-folder"></i>
          {state.wording.getHistory}
        </StyledContent>
      )}
      <Modal
        isOpen={displayHistory}
        close={() => setDisplayHistory(!displayHistory)}
        selector="#root"
      >
        <History
          answers={props.answers.filter((el) => !el.enabled)}
          close={() => setDisplayHistory(!displayHistory)}
          api={props.api}
          w={props.w}
        />
      </Modal>

      {props.answers
        .filter((el) => el.enabled)
        .map((answer, i) => (
          <React.Fragment key={answer.id}>
            <Answer answer={answer} index={i} api={props.api} w={props.w} />
          </React.Fragment>
        ))}
    </div>
  );
};

const StyledContent = styled.div`
  text-align: center;
  cursor: pointer;
  color: #5054be;
  padding: 0.25rem;
  border-radius: 5px;
  margin-bottom: 0.25rem;

  &:hover {
    background: #9a9dda;
  }
`;

export default Answers;
