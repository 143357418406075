export default {
  adminFeatures: [
    'Administration des fonctionnalités',
    'Administration of the features',
  ],
  administration: ["Administration", "Administration"],
  servers: ["Serveurs", "Servers"],
  webchatFeat: ['Administration du webchat', 'Webchat administration'],
  chatBotIdentity: ['Identité du chatbot', 'Identity of the chatbot'],
  analyticsFeat: ["Fonctionnalités page d'accueil", 'Home page features'],
  save: ['Enregistrer', 'Save'],
  identity: ['Identification', 'Identification'],
  nameBot: ['Nom du chatbot', 'Name of the chatbot'],
  nameOrga: ["Nom de l'organisation", 'Name of the organization'],
  description: ['Description', 'Description'],
  modeExpression: ["Mode d'expression", 'Mode of expression'],
  lang: ['Langues', 'Languages'],
  language: ['Langue', 'Language'],
  inPanel: ['Dans le back-office', 'In the back-office'],
  editLanguages: ['Editer les langues', 'Edit languages'],
  inBot: ['Dans le chatbot', 'In the chatbot'],
  cancel: ['Annuler', 'Cancel'],
  validate: ['Valider', 'Validate'],
  familiarChoice: ['Tutoiement / vouvoiement', 'Familiar'],
  familiar: ['Tutoiement', 'Familiar'],
  formal: ['Vouvoiement', 'Formal'],
  chatbotVisual: ['Visuels du chatbot', 'Visuals of the chatbot'],
  logoBot: ['Logo du chatbot', 'Chatbot logo'],
  defaultVisual: ['Visuel par défaut', 'Default visual'],
  download: ['Télécharger', 'Download'],
  downloadSize: ['1024(w) X 128(h)', '1024(w) X 128(h)'],
  downloadFormat: [
    'formats acceptés : png, jpg, gif ; taille maximale : 5 MB',
    'only png, jpg, gif with max size of 5 MB',
  ],
  featuresSelect: [
    'Sélectionner les fonctionnalités à activer :',
    'Select the features to be activated:',
  ],
  feature: ['Fonctionnalité', 'Features'],
  backofficeActive: [
    'Activer dans le back-office',
    'Activate in the back office',
  ],
  visibleFor: ['Visible pour', 'Visible to'],
  chatbotActive: ['Activer dans le chatbot', 'Activate in the chatbot'],
  serverInline: [
    'Souhaitez-vous mettre en ligne un serveur pour ce chatbot ?',
    'Would you like to put a server online for this chatbot?',
  ],
  serverInfo: [
    '(le serveur doit être activé avant de configurer le Webchat ou Messenger)',
    '(the server must be activated before configuring the Webchat or Messenger)',
  ],
  yes: ['Oui', 'Yes'],
  no: ['Non', 'No'],
  stateServer: ["statut actuel du serveur:", 'Current server status:'],
  online: ['En ligne', 'On line'],
  inStaging: ['En attente', 'Pending'],
  offline: ['Hors ligne', 'Offline'],
  chatbotOnline: ['Ce chatbot est en ligne ?', 'Is this chatbot online?'],
  appearanceChatbot: ['Apparence du webchat', 'Webchat appearance'],
  chatbotClosed: ['Webchat fermé', 'Webchat closed'],
  iconWidget: ['Icône de la bulle', 'Bubble Icon'],
  iconHover: ['Icône au survol (optionnel)', 'Hover icon (optional)'],
  chatbotOpen: ['Webchat ouvert', 'Open Webchat'],
  header: ['Header', 'Header'],
  headerText: ["Texte d'en-tête", 'Header text'],
  background: ['Couleur du fond', 'Background color'],
  font: ['Police', 'Font'],
  body: ['Body', 'Body'],
  window: ['Fenêtre', 'Window'],
  border: ['Arrondi', 'Rounding'],
  fontColor: ['Couleur police', 'Font color'],
  widgetColor: ['Couleur des bulles', 'Color of the bubbles'],
  image: ['Image (optionnel)', 'Image (optional)'],
  textInput: ['Cadre du champ texte', 'Text field frame'],
  inputActive: [
    'Souhaitez-vous ouvrir le champ texte ?',
    'Would you like to open the text field?',
  ],
  imageActive: [
    "Souhaitez-vous activer la possibilité d'envoyer des images ?",
    'Would you like to enable the ability to send images?',
  ],
  webchatCode: ['Code du webchat', 'Webchat code'],
  webchatPreview: ['Aperçu du webchat ouvert', 'Open webchat overview'],
  writeMsg: ['Ecrire un message...', 'Write a message...'],
  randomText: [
    'Les visites de groupe ne sont pas autorisées pour le moment.',
    'Group visits are not permitted at this time.',
  ],
  webchatClosed: ['Aperçu du webchat fermé', 'Closed webchat overview'],
  messengerBotOnline: [
    'Ce chatbot est en ligne sur Facebook Messenger ?',
    'This chatbot is online on Facebook Messenger?',
  ],
  pageId: ['Page ID', 'Page ID'],
  token: ['Token', 'Token'],
  persona: ['Personas', 'Personas'],
  globalPersonaName: [
    'Nom du persona global (chatbot)',
    'Name of the global persona (chatbot)',
  ],
  globalPersonaImage: [
    'Image du persona global (chatbot)',
    'Image of the global persona (chatbot)',
  ],
  personaTeamName: ['Nom du persona équipe', 'Name of the persona team'],
  personaTeamImage: ['Image du persona équipe', 'Image of the team persona'],
  askMona: ['Ask Mona', 'Ask Mona'],
  askMonaClient: ['Ask mona + Client', 'Ask mona + Client'],
  catchphrase: ['Catchphrase', 'Catchphrase'],
  personaTeamPlaceholder: [
    'Renseigner le nom du persona équipe',
    'Enter the name of the team persona',
  ],
  personaGlobalPlaceholder: [
    'Renseigner le nom du persona global',
    'Enter the name of the global persona',
  ],
  platform: ['Platforme', 'Platform'],

  changeTheIcon: ["changer l'icone", "change the icon"],
  messenger: ["messenger", "messenger"],
  webchat: ["webchat", "webchat"],

  download_error: ["Une erreur est survenue lors du téléchargement.", "An error occurred while downloading"],
  downloadAFont: ["Télécharger une police", "Download a font"],
  acceptedFormat: ["Formats acceptés: .ttf, .otf, .woff, .woff2", "Accpeted formats: .ttf, .otf, .woff, .woff2"],
  chooseAnExistingFont: ["Sélectionnez une police existante", "Choose an existing font"],
  referral: ["Referral", "Referral"],

  preview: ["Aperçu", "Preview"],
  saved: ["Enregistré !", "Saved !"],

  messenger_online_on_facebook: ["Ce chatbot est en ligne sur Facebook Messenger", "This chatbot is online on Facebook Messenger"],
  messenger_offline_on_facebook: ["Ce chatbot n'est pas en ligne sur Facebook Messenger", "This chatbot is offline on Facebook Messenger"],
  messenger_enter_token: ["Renseigner le token", "Enter the token"],
};
