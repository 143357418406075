import { useContext } from 'react';
import { _arrayMove, _slugify } from '../../_tools/other';

import ScenariosContext from '../../containers/scenarios/context/scenariosContext';

export const _reducer = (state, action) => {
  const scenariosContext = useContext(ScenariosContext);

  const wording = scenariosContext.functionalitiesSubsbar;

  const subsbarfunc = scenariosContext.functionalitiesSubsbarFunc;

  const lang = scenariosContext.functionalitiesSubsbarLang;

  const focus = scenariosContext.functionalitiesSubsbarFocus;

  let el = '';
  if (subsbarfunc && subsbarfunc[lang] && !subsbarfunc[lang][focus]) {
    if (wording && wording[focus] && wording[focus][lang]) {
      el = wording[focus][lang];
    } else {
      el = '';
    }
  } else if (subsbarfunc && subsbarfunc[lang] && subsbarfunc[lang][focus]) {
    el = subsbarfunc[lang][focus];
  }

  const formatNewFocus = (focus, element, func, lang, value) => {
    if (func)
      return {
        ...focus,
        content: {
          ...focus.content,
          func: {
            ...focus.content.func,
            [lang]: {
              ...focus.content.func[lang],
              [func]: element
                ? !Array.isArray(element)
                  ? [element, value]
                  : [...element, value]
                : [value],
            },
          },
        },
      };
    else
      return {
        ...focus,
        content: {
          ...focus.content,
          [lang]: [...focus.content[lang], value],
        },
      };
  };
  switch (action.type) {
    case 'copyElement':
      return {
        ...state,
        cpyArray: state.cpyArray
          .filter((el) => el !== action.payload)
          .concat(action.payload),
      };
    case 'removeCpy':
      return {
        ...state,
        cpyArray: state.cpyArray.filter((el) => el !== action.payload),
      };
    case 'toggleCpyFolder':
      return { ...state, cpyFolder: action.payload };
    case 'setActivated':
      return { ...state, activated: action.payload };
    case 'setError':
      return { ...state, error: action.payload };
    case 'setQR':
      return { ...state, quick_replies: action.payload };
    case 'setPayloads':
      return { ...state, payloads: action.payload };
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setModules': {
      if (action.payload.some((m) => m.key === state.focus.key)) {
        return { ...state, modules: action.payload };
      } else {
        const modulesUpdated = state.modules;
        modulesUpdated[state.modules.length - 1] = state.focus;
        return { ...state, modules: modulesUpdated };
      }
    }
    case 'setSubModules':
      return { ...state, subModules: action.payload };
    case 'setIds':
      return { ...state, allIds: action.payload };
    case 'setDefaultLang':
      return { ...state, defaultLang: action.payload === "fr" ? "default": action.payload };
    case 'deleteModule':
      return {
        ...state,
        modules: action.payload,
      };
    case 'setFocus':
      let newFocus = { ...action.payload };
      if (!newFocus.content[state.lang] || !newFocus.content[state.lang].length)
        newFocus = {
          ...newFocus,
          content: {
            ...newFocus.content,
            [state.lang]: (newFocus.content[state.defaultLang] || newFocus.content['default'] || []).filter(
              (m) => typeof m !== 'string'
            ),
          },
          key: `p:${action.payload.id}`,
        };
      if (newFocus.content.func && !newFocus.content.func[state.lang])
        newFocus = {
          ...newFocus,
          content: {
            ...newFocus.content,
            func: {
              ...newFocus.content.func,
              [state.lang]: newFocus.content.func[state.defaultLang] || newFocus.content.func['default'],
            },
          },
        };
      return { ...state, focus: newFocus, subfocus: null, quick_replies: null };
    case 'setSubFocus':
      let newSubfocus = { ...action.payload };
      if (
        !newSubfocus.content[state.lang] ||
        !newSubfocus.content[state.lang].length
      )
        newSubfocus = {
          ...newSubfocus,
          content: {
            ...newSubfocus.content,
            [state.lang]: newSubfocus.content[state.defaultLang] || newSubfocus.content['default'] || [],
          },
        };
      return { ...state, subfocus: newSubfocus, quick_replies: null };
    case 'updateModule':
      const newMod = action.payload;
      return {
        ...state,
        modules: state.modules.map((m) => (m.id === newMod.id ? newMod : m)),
      };
    case 'setApi':
      return { ...state, Bot: action.payload };
    case 'onMoveElement':
    case 'addElement':
    case 'popElement':
    case 'onFuncChange':
    case 'onElemChange':
    case 'onQRChange':
    case 'setLang':
    case 'removeLogicMenu':
    case 'removeLogicAnalytic':
      const { name, value, index, type, lang, func } = action.payload;
      let hasMoved = state.hasMoved;
      let quickReplies = {};
      if (state.quick_replies)
        quickReplies = { quick_replies: state.quick_replies };
      const isClose = 'trigger' in state.focus;
      if (!state.subfocus) {
        let newFocus = { ...state.focus };
        //Remove "logic" on new lang to avoid internal servor error.""
        if (
          !newFocus.content[lang || state.lang] ||
          !newFocus.content[lang || state.lang].length
        )
          newFocus = {
            ...newFocus,
            content: {
              ...newFocus.content,
              [lang || state.lang]: (newFocus.content[state.defaultLang] || newFocus.content['default'] || []).filter(
                (m) => typeof m !== 'string'
              ),
            },
          };
        if (newFocus.content.func && !newFocus.content.func[lang || state.lang])
          newFocus = {
            ...newFocus,
            content: {
              ...newFocus.content,
              func: {
                ...newFocus.content.func,
                [lang || state.lang]: newFocus.content.func[state.defaultLang] || newFocus.content.func['default'],
              },
            },
          };
        if (action.type === 'addElement') {
          switch (name) {
            case 'copy':
              newFocus = formatNewFocus(newFocus, el, func, state.lang, value);
              break;
            case 'text':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                textTemplate(value, quickReplies)
              );
              break;
            case 'image':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                imageTemplate(value, quickReplies)
              );
              break;
            case 'mediaImg':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                mediaTemplate('image', value, null, quickReplies)
              );
              break;
            case 'mediaVid':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                mediaTemplate('video', value, null, quickReplies)
              );
              break;
            case 'video':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                videoTemplate(value, quickReplies)
              );
              break;
            case 'audio':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                audioTemplate(value, quickReplies)
              );
              break;
            case 'iframe':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                iframeTemplate(
                  value,
                  value?.attachment?.payload?.template_type === 'iframe',
                  quickReplies
                )
              );
              break;
            case 'generic':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                genericTemplate(
                  [genTemplate({}, {}, [btnTemplate()])],
                  quickReplies
                )
              );
              break;
            case 'button':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                buttonTemplate(value, quickReplies)
              );
              break;
            case 'list':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                listTemplate(
                  [lstTemplate({}, {}, []), lstTemplate({}, {}, [])],
                  [],
                  quickReplies
                )
              );
              break;
            case 'analytics':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                `LOGIC_ANALYTICS(${value})`
              );
              break;
            case 'menu':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                'LOGIC_MENU'
              );
              break;
            case 'random':
              newFocus = formatNewFocus(newFocus, el, func, state.lang, [[]]);
              break;
            case 'events':
              newFocus = formatNewFocus(
                newFocus,
                el,
                func,
                state.lang,
                'LOGIC_PROG_EVENT(event_id)'
              );
              break;
            default:
              break;
          }
        } else if (action.type === 'removeLogicMenu')
          newFocus = {
            ...newFocus,
            content: {
              ...newFocus.content,
              [state.lang]: newFocus.content[state.lang].filter(
                (e) => e !== 'LOGIC_MENU'
              ),
            },
          };
        else if (action.type === 'removeLogicAnalytic')
          newFocus = {
            ...newFocus,
            content: {
              ...newFocus.content,
              [state.lang]: newFocus.content[state.lang].filter(
                (e) => !(typeof e === 'string' && e.match(/LOGIC_ANALYTICS/))
              ),
            },
          };
        else if (action.type === 'onElemChange')
          func
            ? (newFocus = {
                ...newFocus,
                content: {
                  ...newFocus.content,
                  func: {
                    ...newFocus.content.func,
                    [state.lang]: {
                      ...newFocus.content.func[state.lang],
                      [func]: newFocus.content.func[state.lang][func].map(
                        (el, i) => (i === index ? value : el)
                      ),
                    },
                  },
                },
              })
            : (newFocus = {
                ...newFocus,
                content: {
                  ...newFocus.content,
                  [state.lang]: newFocus.content[state.lang].map((el, i) =>
                    i === index ? value : el
                  ),
                },
              });
        else if (action.type === 'onQRChange')
          newFocus = {
            ...newFocus,
            content: {
              ...newFocus.content,
              [state.lang]: newFocus.content[state.lang].map((el, i) =>
                i === index ? { ...el, quick_replies: value } : el
              ),
            },
          };
        else if (action.type === 'popElement') {
          action.func
            ? (newFocus = {
                ...newFocus,
                content: {
                  ...newFocus.content,
                  func: {
                    ...newFocus.content.func,
                    [state.lang]: {
                      ...newFocus.content.func[state.lang],
                      [action.func]: newFocus.content.func[state.lang][
                        action.func
                      ].filter((e, i) => i !== action.payload),
                    },
                  },
                },
              })
            : (newFocus = {
                ...newFocus,
                content: {
                  ...newFocus.content,
                  [state.lang]: newFocus.content[state.lang]
                    .filter((e, i) => i !== action.payload)
                    .map((e, i) => {
                      if (
                        !newFocus.content[state.lang][i + 1] &&
                        state.quick_replies &&
                        typeof e !== 'string'
                      )
                        return { ...e, quick_replies: state.quick_replies };
                      return e;
                    }),
                },
              });
        } else if (action.type === 'onFuncChange')
          newFocus = {
            ...newFocus,
            content: {
              ...newFocus.content,
              func: {
                ...newFocus.content.func,
                [state.lang]: {
                  ...newFocus.content.func[state.lang],
                  [name]: action.arraypayload ? [value] : value,
                },
              },
            },
          };
        else if (action.type === 'onMoveElement') {
          let newDefault = [...newFocus.content[state.lang]];
          let newFunc = func
            ? [...newFocus.content.func[state.lang][func]]
            : null;
          hasMoved = !hasMoved;
          _arrayMove(
            func ? newFunc : newDefault,
            index,
            type === 'up' ? index - 1 : index + 1
          );
          func
            ? (newFocus = {
                ...newFocus,
                content: {
                  ...newFocus.content,
                  func: {
                    ...newFocus.content.func,
                    [state.lang]: {
                      ...newFocus.content.func[state.lang],
                      [func]: newFunc,
                    },
                  },
                },
              })
            : (newFocus = {
                ...newFocus,
                content: { ...newFocus.content, [state.lang]: newDefault },
              });
        }
        if (action.type === 'setLang')
          return { ...state, focus: newFocus, lang: lang };
        return { ...state, focus: newFocus, hasMoved: hasMoved };
      } else {
        let newSubfocus = { ...state.subfocus };
        if (
          !newSubfocus.content[lang || state.lang] ||
          !newSubfocus.content[lang || state.lang].length
        )
          newSubfocus = {
            ...newSubfocus,
            content: {
              ...newSubfocus.content,
              [lang || state.lang]: newSubfocus.content[state.defaultLang] || newSubfocus.content['default'] || [],
            },
          };
        if (action.type === 'addElement') {
          switch (name) {
            case 'iframe':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(
                    iframeTemplate(
                      value,
                      value?.attachment?.payload?.template_type === 'iframe',
                      quickReplies
                    )
                  ),
                },
              };
              break;
            case 'copy':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(value),
                },
              };
              break;
            case 'text':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(
                    textTemplate(value, quickReplies)
                  ),
                },
              };
              break;
            case 'mediaImg':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(
                    mediaTemplate('image', value, null, quickReplies)
                  ),
                },
              };
              break;
            case 'mediaVid':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(
                    mediaTemplate('video', value, null, quickReplies)
                  ),
                },
              };
              break;
            case 'image':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(
                    imageTemplate(value, quickReplies)
                  ),
                },
              };
              break;
            case 'video':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(
                    videoTemplate(value, quickReplies)
                  ),
                },
              };
              break;
            case 'audio':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(
                    audioTemplate(value, quickReplies)
                  ),
                },
              };
              break;
            case 'generic':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(
                    genericTemplate(
                      [genTemplate({}, {}, [btnTemplate()])],
                      quickReplies
                    )
                  ),
                },
              };
              break;
            case 'button':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(
                    buttonTemplate(value, quickReplies)
                  ),
                },
              };
              break;
            case 'list':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(
                    listTemplate(
                      [lstTemplate({}, {}, []), lstTemplate({}, {}, [])],
                      [],
                      quickReplies
                    )
                  ),
                },
              };
              break;
            case 'analytics':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(
                    `LOGIC_ANALYTICS(${value})`
                  ),
                },
              };
              break;
            case 'menu':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]:
                    newSubfocus.content[state.lang].concat('LOGIC_MENU'),
                },
              };
              break;
            case 'random':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat([[]]),
                },
              };
              break;
            case 'events':
              newSubfocus = {
                ...newSubfocus,
                content: {
                  ...newSubfocus.content,
                  [state.lang]: newSubfocus.content[state.lang].concat(
                    'LOGIC_PROG_EVENT(event_id)'
                  ),
                },
              };
              break;
            default:
              break;
          }
        } else if (action.type === 'removeLogicMenu')
          newSubfocus = {
            ...newSubfocus,
            content: {
              ...newSubfocus.content,
              [state.lang]: newSubfocus.content[state.lang].filter(
                (e) => e !== 'LOGIC_MENU'
              ),
            },
          };
        else if (action.type === 'removeLogicAnalytic')
          newSubfocus = {
            ...newSubfocus,
            content: {
              ...newSubfocus.content,
              [state.lang]: newSubfocus.content[state.lang].filter(
                (e) => !(typeof e === 'string' && e.match(/LOGIC_ANALYTICS/))
              ),
            },
          };
        else if (action.type === 'onElemChange')
          newSubfocus = {
            ...newSubfocus,
            content: {
              ...newSubfocus.content,
              [state.lang]: newSubfocus.content[state.lang].map((el, i) =>
                i === index ? value : el
              ),
            },
          };
        else if (action.type === 'onQRChange')
          newSubfocus = {
            ...newSubfocus,
            content: {
              ...newSubfocus.content,
              [state.lang]: newSubfocus.content[state.lang].map((el, i) =>
                i === index ? { ...el, quick_replies: value } : el
              ),
            },
          };
        else if (action.type === 'popElement') {
          newSubfocus = {
            ...newSubfocus,
            content: {
              ...newSubfocus.content,
              [state.lang]: newSubfocus.content[state.lang]
                .filter((e, i) => i !== action.payload)
                .map((e, i) => {
                  if (
                    !newSubfocus.content[state.lang][i + 1] &&
                    state.quick_replies &&
                    typeof e !== 'string'
                  )
                    return { ...e, quick_replies: state.quick_replies };
                  return e;
                }),
            },
          };
        } else if (action.type === 'onFuncChange')
          newSubfocus = {
            ...newSubfocus,
            content: {
              ...newSubfocus.content,
              func: {
                ...newSubfocus.content.func,
                [state.lang]: {
                  ...newSubfocus.content.func[state.lang],
                  [name]: action.arraypayload ? [value] : value,
                },
              },
            },
          };
        else if (action.type === 'onMoveElement') {
          hasMoved = !hasMoved;
          let newDefault = [...newSubfocus.content[state.lang]];
          _arrayMove(newDefault, index, type === 'up' ? index - 1 : index + 1);
          newSubfocus = {
            ...newSubfocus,
            content: {
              ...newSubfocus.content,
              [state.lang]: newDefault.map((e, i) => {
                if (
                  !newDefault[i + 1] &&
                  state.quick_replies &&
                  typeof e !== 'string'
                )
                  return { ...e, quick_replies: state.quick_replies };
                return e;
              }),
            },
          };
        }
        let newState = { ...state, subfocus: newSubfocus, hasMoved: hasMoved };
        if (action.type === 'setLang') newState = { ...newState, lang: lang };
        if (isClose) {
          const { key, ...quickReplies } = newSubfocus;
          newState = {
            ...newState,
            focus: {
              ...newState.focus,
              trigger: { ...newState.focus.trigger, [key]: quickReplies },
            },
          };
        }
        return newState;
      }
    default:
      return state;
  }
};

export const btns = [
  { id: 0, type: 'text', name: 'text', icon: 'fas fa-font' },
  { id: 1, type: 'image', name: 'image', icon: 'fas fa-image' },
  { id: 2, type: 'video', name: 'video', icon: 'fab fa-youtube' },
  { id: 3, type: 'image', name: 'gif', icon: 'fas fa-file' },
  { id: 4, type: 'generic', name: 'generic', icon: 'fas fa-window-restore' },
  { id: 6, type: 'menu', name: 'menu', icon: 'fas fa-ellipsis-h' },
  { id: 7, type: 'audio', name: 'audio', icon: 'fas fa-volume-up' },
  { id: 8, type: 'mediaImg', name: 'mediaImg', icon: 'fas fa-image' },
  { id: 9, type: 'mediaVid', name: 'mediaVid', icon: 'fas fa-youtube' },
  { id: 10, type: 'random', name: 'random', icon: '' },
  { id: 11, type: 'events', name: 'events', icon: '' },
  { id: 12, type: 'iframe', name: 'iframe', icon: '' },
];

export const initialState = {
  lang: 'default',
  defaultLang: 'default',
  loading: true,
  modules: [],
  focus: null,
  btns: btns,
  cpyArray: [],
  cpyFolder: false,
  hasMoved: false,
};

export const _extractIds = (modules) => {
  let ids = [];
  modules.forEach((m) => {
    ids.push(m.id);
    if ('trigger' in m) {
      Object.values(m.trigger).forEach((v) => ids.push(v.id));
    }
  });
  return ids;
};

//FACEBOOK TEMPLATE
export const textTemplate = (str = '', quickReplies = {}) => ({
  text: str,
  ...quickReplies,
});

export const mediaTemplate = (
  type,
  attachmentID = null,
  url = null,
  quickReplies = {},
  btn,
  alt = ''
) => {
  let template = {
    attachment: {
      type: 'template',
      payload: {
        template_type: 'media',
        elements: [
          {
            media_type: type,
            attachment_id: attachmentID,
            url: url,
            alt,
          },
        ],
      },
    },
    ...quickReplies,
  };

  if (!url) delete template['attachment']['payload']['elements'][0]['url'];
  else delete template['attachment']['payload']['elements'][0]['attachment_id'];

  if (btn)
    template['attachment']['payload']['elements'][0] = {
      ...template['attachment']['payload']['elements'][0],
      buttons: [btn],
    };

  return template;
};

export const imageTemplate = (url = '', quickReplies = {}, alt = '') => {
  let template = {
    attachment: {
      type: 'image',
      payload: {
        url: url,
        alt,
      },
    },
    ...quickReplies,
  };
  return template;
};

export const imageIDTemplate = (id = '', quickReplies = {}, alt = '') => {
  let template = {
    attachment: {
      type: 'image',
      payload: {
        attachment_id: id,
        alt,
      },
    },
    ...quickReplies,
  };

  return template;
};

export const basicListTemplate = () => {
  return listTemplate([lstTemplate({}, {}, []), lstTemplate({}, {}, [])], []);
};

export const videoTemplate = (url = '', quickReplies = {}) => ({
  attachment: {
    type: 'video',
    payload: {
      url: url,
    },
  },
  ...quickReplies,
});

export const videoIDTemplate = (id = '', quickReplies = {}) => {
  let template = {
    attachment: {
      type: 'video',
      payload: {
        attachment_id: id,
      },
    },
    ...quickReplies,
  };

  return template;
};

export const audioTemplate = (url = '', quickReplies = []) => ({
  attachment: {
    type: 'audio',
    payload: {
      url: url,
    },
  },
  ...quickReplies,
});

export const iframeTemplate = (
  url = '',
  toWebview = false,
  quickReplies = {}
) => {
  if (toWebview) {
    return {
      attachment: {
        type: 'template',
        payload: {
          template_type: 'iframe',
          src: url,
        },
      },
      ...quickReplies,
    };
  } else {
    return {
      attachment: {
        type: 'iframe',
        payload: {
          url: url,
        },
      },
      ...quickReplies,
    };
  }
};

export const genericTemplate = (elements = [], quickReplies = {}) => ({
  attachment: {
    type: 'template',
    payload: {
      template_type: 'generic',
      image_aspect_ratio: 'square',
      elements: elements,
    },
  },
  ...quickReplies,
});

export const buttonTemplate = (text = '', btns = [], quickReplies = {}) => ({
  attachment: {
    type: 'template',
    payload: {
      template_type: 'button',
      text: text,
      buttons: btns,
    },
  },
  ...quickReplies,
});

export const listTemplate = (elements = [], btns = [], quickReplies = {}) => {
  let obj = {
    attachment: {
      type: 'template',
      payload: {
        template_type: 'list',
        top_element_style: 'compact',
        elements: elements,
        buttons: btns,
      },
    },
    ...quickReplies,
  };

  if (!obj.attachment.payload.buttons.length)
    delete obj['attachment']['payload']['buttons'];

  return obj;
};

export const lstTemplate = (opt = {}, default_action = {}, btns = []) => {
  let obj = {
    title: '',
    image_url: '',
    subtitle: '',
    ...opt,
    default_action: {
      type: 'web_url',
      url: '',
      webview_height_ratio: 'FULL',
      ...default_action,
    },
    buttons: btns,
  };

  if (!obj.buttons.length) delete obj['buttons'];

  if (!obj.subtitle) delete obj['subtitle'];

  if (!default_action.url) delete obj['default_action'];

  return obj;
};

export const genTemplate = (opt = {}, default_action = {}, btns = [], alt) => {
  let obj = {
    title: '',
    image_url: '',
    alt,
    subtitle: '',
    ...opt,
    default_action: {
      type: 'web_url',
      url: '',
      webview_height_ratio: 'FULL',
      ...default_action,
    },
    buttons: btns,
  };

  if (!obj.subtitle) delete obj['subtitle'];
  if (!default_action.url) delete obj['default_action'];

  return obj;
};

export const btnTemplate = (opt = {}) => {
  let obj = {
    type: 'web_url',
    url: '',
    title: '',
    payload: '',
    ...opt,
  };

  if (obj.type === 'web_url') delete obj['payload'];
  else delete obj['url'];

  return obj;
};

export const QRTemplate = (opt = {}) => ({
  title: '',
  payload: '',
  ...opt,
  content_type: 'text',
});

//JSON TEMPLATE
export const moduleTemplate = (
  opt = {},
  type = 1,
  allIds = [],
  daliAnswer = []
) => {
  const { id, name, content, ...quickReplies } = opt;

  let aId = _slugify(id);
  if (allIds && allIds.length) aId = _findDup(aId, allIds);
  let module = {
    id: aId,
    key: `p:${aId}`,
    name: name,
    custom: true,
    disable: true,
    ...quickReplies,
    content: content || {
      default: [],
      func: {
        default: {},
      },
    },
  };

  if (type === 0) {
    const { key, ...quickReplies } = moduleTemplate(
      {
        id: 'leave',
        disable: false,
        name: 'Module de sortie',
        jump: aId,
        undeletable: true,
      },
      1,
      allIds
    );
    module = {
      ...module,
      trigger: {
        [key]: quickReplies,
      },
    };
  } else if (type === 2) {
    let trigger = {};
    let answers = [];
    const results = moduleTemplate(
      {
        id: 'results',
        disable: false,
        name: 'Résultat',
        jump: aId,
        tag: 'Corps du jeu',
        donotshow: true,
        undeletable: true,
        content: {
          default: [
            'LOGIC_DALI_COUNT', // "trigger_daliCount({{ $1 }})",
          ],
          func: { default: {} },
        },
      },
      1,
      allIds
    );
    const beforeResults = moduleTemplate(
      {
        id: 'beforeresults',
        disable: false,
        name: 'Pré-résultat',
        jump: aId,
        tag: 'Corps du jeu',
        undeletable: true,
        content: {
          default: [
            'LOGIC_DALI_COUNT', // "trigger_daliCount({{ $1 }})",
            {
              text: 'Texte avant le résultat',
              quick_replies: [
                {
                  title: 'Lien vers le résultat',
                  payload: results.id,
                  content_type: 'text',
                },
              ],
            },
          ],
          func: { default: {} },
        },
      },
      1,
      allIds
    );
    const firstQuestion = moduleTemplate(
      {
        id: 'question1',
        disable: false,
        name: 'Question 1',
        tag: 'Corps du jeu',
        jump: aId,
        undeletable: true,
      },
      1,
      allIds
    );

    trigger = { ...trigger, [beforeResults.key]: beforeResults };
    trigger = { ...trigger, [results.key]: results };
    trigger = { ...trigger, [firstQuestion.key]: firstQuestion };

    daliAnswer.forEach((str) => {
      let { key, ...quickReplies } = moduleTemplate(
        {
          id: str,
          answer: true,
          disable: false,
          name: `Résultat: ${str}`,
          jump: aId,
          tag: 'Résultat',
        },
        1,
        allIds
      );
      trigger = { ...trigger, [key]: quickReplies };
      answers.push(quickReplies.id);
    });

    module = {
      ...module,
      content: {
        default: [`LOGIC_DALI_COUNT_START(${firstQuestion.id})`],
        func: {
          default: {
            daliType: 'match',
            answers: answers,
          },
        },
      },
      dali: true,
      trigger: trigger,
    };
  } else if (type === 3) {
    let trigger = {};
    let answers = [];

    daliAnswer.forEach((str) => {
      let { key, ...quickReplies } = moduleTemplate(
        {
          id: str.replace(/\(.*\)/, ''),
          answer: true,
          disable: false,
          name: `Résultat: ${str.replace(/\(.*\)/, '')}`,
          jump: aId,
        },
        1,
        allIds
      );
      trigger = { ...trigger, [key]: quickReplies };
      answers.push(str.toLowerCase());
    });

    const results = moduleTemplate(
      {
        id: 'results',
        disable: false,
        name: 'Résultat',
        jump: aId,
        tag: 'Corps du jeu',
        donotshow: true,
        undeletable: true,
        content: {
          default: [
            'LOGIC_DALI_COUNT', // "trigger_daliCount({{ $1 }})",
            'LOGIC_DALI_COUNT_RESULT', // "trigger_daliCount(results)"
          ],
          func: {
            default: {
              daliType: 'quiz',
              answers: answers,
            },
          },
        },
      },
      1,
      allIds
    );
    const beforeResults = moduleTemplate(
      {
        id: 'beforeresults',
        disable: false,
        name: 'Pré-résultat',
        jump: aId,
        tag: 'Corps du jeu',
        undeletable: true,
        content: {
          default: [
            'LOGIC_DALI_COUNT', // "trigger_daliCount({{ $1 }})",
            {
              text: 'Texte avant le résultat',
              quick_replies: [
                {
                  title: 'Lien vers le résultat',
                  payload: results.id,
                  content_type: 'text',
                },
              ],
            },
          ],
          func: { default: {} },
        },
      },
      1,
      allIds
    );
    const firstQuestion = moduleTemplate(
      {
        id: 'question1',
        disable: false,
        name: 'Question 1',
        tag: 'Corps du jeu',
        jump: aId,
        undeletable: true,
      },
      1,
      allIds
    );

    trigger = { ...trigger, [beforeResults.key]: beforeResults };
    trigger = { ...trigger, [results.key]: results };
    trigger = { ...trigger, [firstQuestion.key]: firstQuestion };

    module = {
      ...module,
      content: {
        default: [`LOGIC_DALI_COUNT_START(${firstQuestion.id})`],
        func: {
          default: {
            daliType: 'quiz',
            answers: answers,
          },
        },
      },
      dali: true,
      trigger: trigger,
    };
  }

  return module;
};

export const startLangTemplate = (bot_lang) => ({
  'p:start_lang': {
    key: 'p:start_lang',
    name: 'Introduction - Choix de la langue',
    id: 'start_lang',
    content: {
      default: ['trigger_language({{ $1}})'],
      en: ['trigger_language({{ $1}})'],
      it: ['trigger_language({{ $1}})'],
      func: {
        default: {
          begin:
            'Je suis là pour vous aider à préparer votre visite et pour répondre à vos questions. ',
          result: 'Très bien, je vous parlerai français !',
        },
        en: {
          result: "Alright, I'll talk to you in english !",
          begin:
            'I’m here to help you prepare your visit and to answer your questions.',
        },
        it: {
          result: 'Ok, parlerò in italiano adesso.',
          begin:
            'Sono qui per aiutarti a preparare la tua visita e per rispondere alle tue domande.',
        },
      },
    },
    jump: 'FIRST',
  },
});

export const endLangTemplate = () => ({
  'p:end_lang': {
    key: 'p:end_lang',
    name: 'Introduction - Fin',
    id: 'END_LANG',
    content: {
      default: [],
      en: [],
      it: [],
      func: {
        default: {
          begin: '',
          result: 'Très bien, je vous parlerai français !',
        },
        en: {
          result: "Alright, I'll talk to you in english !",
          begin: '',
        },
        it: {
          begin: '',
          result: 'Ok, parlerò in italiano adesso.',
        },
      },
    },
    jump: 'FIRST',
  },
});

export const _findDup = (key, array = []) => {
  let slug = key.replace(/_\d+$/, '');
  let reg = new RegExp(`^${slug}$`, 'ig');
  let dup = 0;

  array.forEach((k, i) => {
    if (k && k.replace(/_\d+$/, '').match(reg)) dup++;
  });

  return `${slug}${dup ? `_${dup + 1}` : ''}`;
};

export const _generatePath = (focus = null, subfocus = null) => {
  if (subfocus)
    return ['module', 'trigger', focus.key, 'trigger', subfocus.key];
  if (focus) return ['module', 'trigger', focus.key];
  else return ['module', 'content'];
};
