import React from 'react';
import { _isYoutube, _isDailymotion } from '../tools/isOrHas';

const _urlToEmbedVid = (url) => {
  const vidId = url.match(/[\w-]*$/);
  if (_isYoutube(url)) return `https://www.youtube.com/embed/${vidId[0]}`;
  if (_isDailymotion(url))
    return `https://www.dailymotion.com/embed/video/${vidId[0]}`;
  return url;
};

const EmbedVideo = ({ url }) => {
  const embedUrl = _urlToEmbedVid(url);
  return <iframe className="iframe" src={embedUrl} title={embedUrl}></iframe>;
};

export default ({ url = '', ...props }) => {
  if (_isYoutube(url) || _isDailymotion(url)) return <EmbedVideo url={url} />;
  return (
    <video className="video has-text-danger" controls>
      <source src={url} type={`video/${url?.slice(-3)}`} />
      Your browser does not support the video tag.
    </video>
  );
};
