import React, { Component } from 'react';
import { Field, Select } from '@ask-mona/ui';
import { Query } from 'react-apollo';
import { Button } from '@ask-mona/ui';
import { _getKeyValue, _slugify } from '../_tools/other';
import { _sendFeedback } from '../_tools/ui';
import { languages } from '../translations/index';

const LANGUAGES_IDS = {
  fr: 1,
  en: 2,
};

class Mark extends Component {
  state = {
    search: '',
  };

  search = (e) => {
    this.setState({ search: e.target.value });
  };

  onSelect = (item) => {
    const { name, onSelect } = this.props;
    this.setState({ search: '' });
    onSelect && onSelect(item, name);
  };

  onCreate = async (item) => {
    this.setState({ search: '' });
    if (item.value) {
      const {
        m: { w, lang },
        api: { ttt },
        name,
        mutation,
      } = this.props;
      const mut = ttt._getMutation(mutation.create);
      const set = ttt._getMutation(mutation.set);
      const variables = mutation.variables || {};
      if (mut) {
        _sendFeedback('warning', 'loader');
        let res = await ttt._mutate(mut, {
          slug: _slugify(item.value),
          name: item.value,
          ...variables,
        });
        if (res.success) {
          _sendFeedback('success', w.success.created);
          if (set) {
            const id = _getKeyValue(res, 'id');
            const idPath = name.replace('s', '_id');
            _sendFeedback('warning', 'loader');
            res = await ttt._mutate(set, {
              [idPath]: id,
              language_id: languages[lang],
              name: item.value,
              firstname: item.value,
            });
            if (res.success) {
              _sendFeedback('success', w.success.saved);
              this.onSelect({
                ...item,
                id: id,
                data: {
                  id: id,
                  slug: _slugify(item.value),
                  translations: [
                    { language_id: languages[lang], name: item.value },
                  ],
                },
              });
            } else _sendFeedback('danger', w.error.general);
          }
        } else _sendFeedback('danger', w.error.general);
      }
    }
  };

  render() {
    const {
      m: { w },
      api: { ttt },
      query,
      mutation,
      name,
      variables,
      title,
      selected,
    } = this.props;
    const que = ttt._getQuery(query);
    const mut = ttt._getMutation(mutation.create);
    return (
      <React.Fragment>
        <Query
          query={que}
          variables={{
            name: this.state.search,
            firstname: this.state.search,
            ...variables,
          }}
        >
          {({ loading, error, data }) => {
            if (error) return null;
            let list = [];
            let items = _getKeyValue(data, name) || [];
            const languageCode = localStorage.getItem("languageCode");
            const languageId = LANGUAGES_IDS[languageCode];

            items.forEach((i) => {
              let label = '';
              if (i.translations?.length) {
                const translation = i.translations.find(t => t.language_id === languageId) || i.translations[0];
                if (translation.firstname || translation.lastname)
                  label = `${translation.firstname} ${translation.lastname}`;
                else label = label || translation.short_name || translation.name || i.slug;

              } else label = i.name;
              list.push({ id: i.id, value: label || "", data: i });
            });
            return (
              <Field title={title} className="m-b-md">
                <Select
                  placeholder={w.generic.search}
                  items={list}
                  className="w-100p"
                  loading={loading}
                  onSelect={this.onSelect}
                  onChange={this.search}
                  create={mutation && mut ? w.generic.create : ''}
                  onCreate={this.onCreate}
                  sort="true"
                />
              </Field>
            );
          }}
        </Query>
        {selected && (
          <div className="m-b-md flex wrap">
            {selected.map((s) => (
              <Button
                key={s.id}
                className="crosshair m-xs"
                onClick={() => this.onSelect(s)}
                color="secondary"
              >
                {s.label}
              </Button>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

//Format items to fit required field in Mark component
export const _formatMark = (items) => {
  const list = [];
  if (Array.isArray(items)) {
    items.forEach((i) => {
      let label = '';
      if (i.translations) {
        i.translations.forEach((t) => {
          if (t.firstname || t.lastname) label = `${t.firstname} ${t.lastname}`;
          else label = label || t.short_name || t.name || i.slug;
        });
      } else label = i.name;
      list.push({ id: i.id, label });
    });
  }
  return list;
};

export default Mark;
