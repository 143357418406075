import React from 'react';
import URLImage from './URLImage';
import Buttons from './Buttons';
import Informations from './Information';
import { withGlobals } from '../hoc/withGlobals';

const Items = ({ buttons, ...other }) => {
  return (
    <div className="flex column space-between items">
      <Informations {...other} />
      <Buttons buttons={buttons} />
    </div>
  );
};

const Generic = ({
  context: { skin },
  image_url,
  default_action,
  ...props
}) => {
  return (
    <div className="webchat_card overflow-hidden rad-5px flex column m-xs">
      <URLImage
        src={
          image_url ||
          'http://askmona.fr/wp-content/uploads/2017/02/cropped-AskMona_Logo_vf.jpg'
        }
        {...default_action}
      />
      <Items {...props} {...default_action} />
    </div>
  );
};

const Generator = ({ context, elements }) =>
  elements.map((element, i) => (
    <Generic key={i} context={context} {...element} />
  ));

export default withGlobals(Generator);
