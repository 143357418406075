import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import Modal from './Modal';
import { Button } from '@ask-mona/ui';
import { withGlobals } from '../hoc/globals';

const noop = () => {};

const NavPrompt = ({ g: { w }, cancel, confirm, ...props }) => (
  <NavigationPrompt {...props}>
    {({ isActive, onCancel, onConfirm }) => {
      if (isActive) {
        return (
          <Modal isOpen={true} close={onCancel} selector="#root">
            <div className="w-4">
              {/* <div className="flex flex-end has-background-primary-lighter p-md">
                <Button onClick={onCancel} className="is-outlined is-borderless shrink is-size-7">
                  <Icon icon="fas fa-times" />
                </Button>
              </div> */}
              <div className="has-background-white flex column p-md">
                <p className="is-size-6 m-b-md">{w.generic.prompt}</p>
                <div className="flex row space-between wrap">
                  <Button
                    className="m-xs is-uppercase"
                    color="danger"
                    onClick={() => {
                      (cancel || noop)();
                      onConfirm();
                    }}
                  >
                    {w.generic.no}
                  </Button>
                  <Button
                    className="m-xs is-uppercase"
                    onClick={() => {
                      (confirm || noop)();
                      onConfirm();
                    }}
                  >
                    {w.generic.yes}
                  </Button>
                </div>
                <div>
                  <Button
                    className="m-t-md is-uppercase is-size-7"
                    onClick={onCancel}
                    style={{
                      background: 'transparent',
                      color: 'black',
                      border: 'none',
                    }}
                  >
                    {w.generic.cancel}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        );
      }
    }}
  </NavigationPrompt>
);

export default withGlobals(NavPrompt);
