import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import { timestampToDate, statut } from '../../../_tools/other';
import moment from 'moment';
import { Checkbox, Button, Icon } from '@ask-mona/ui';
import { withMain } from '../../../hoc/main';

class ListPushs extends Component {
  render() {
    const {
      m: { w },
      allPush,
      funct,
      check,
    } = this.props;
    if (!allPush) return <Loader />;
    if (allPush.length === 0)
      return <div className="flex row center">{w.pushs.noPush}</div>;
    return (
      <React.Fragment>
        {allPush.map((push, i) => {
          let finalDate = null;
          let finalStatut = null;
          if (push.scheduled) {
            const date = timestampToDate(push.scheduled);
            moment.locale('fr');
            const d2 = moment(date).format('LL');
            const h = moment(date).format('LT');

            finalStatut = statut(push.scheduled);
            finalDate = 'Le ' + d2 + ' à ' + h;
          }
          return (
            <div
              className="flex row items-center is-size-6bis border-b-primary-lighter"
              key={i}
            >
              <div className="m-b-md p-xs flex items-start">
                {check.checkListID.includes(push.id) && (
                  <Checkbox
                    onClick={() => funct.checkOnePush(push.id)}
                    defaultChecked
                  />
                )}
                {!check.checkListID.includes(push.id) && (
                  <Checkbox onClick={() => funct.checkOnePush(push.id)} />
                )}
              </div>
              <div className="p-xs flex grow-2 basis-0 break-word">
                {push.title}
              </div>
              <div className="p-xs flex grow-2 basis-0">{finalDate}</div>
              <div className="p-xs flex grow-2 basis-0">
                {finalStatut && finalDate && <p>{w.generic.progress}</p>}
                {!finalStatut && finalDate && <p>{w.generic.sent}</p>}
                {!finalDate && !finalStatut && <p>{w.generic.draft}</p>}
              </div>
              <div className="p-xs flex grow-1 basis-0 end center">
                {finalStatut && finalDate && (
                  <div className="flex row">
                    <Button
                      className="is-borderless is-outlined shrink"
                      onClick={(e) => funct.editPush(push.id)}
                    >
                      {' '}
                      <Icon icon="fas fa-pencil-alt" />{' '}
                    </Button>
                    <Button
                      className="is-borderless is-outlined shrink"
                      onClick={(e) => funct.deleteContentPush(push.id)}
                    >
                      {' '}
                      <Icon icon="fas fa-trash" />{' '}
                    </Button>
                  </div>
                )}
                {!finalStatut && finalDate && (
                  <div className="flex row">
                    <Button
                      className="is-borderless is-outlined shrink"
                      onClick={(e) => funct.viewPush(push.id)}
                    >
                      {' '}
                      <Icon icon="fas fa-eye" />{' '}
                    </Button>
                    <Button
                      className="is-borderless is-outlined shrink"
                      onClick={(e) => funct.deleteContentPush(push.id)}
                    >
                      {' '}
                      <Icon icon="fas fa-trash" />{' '}
                    </Button>
                  </div>
                )}
                {!finalDate && !finalStatut && (
                  <div className="flex row">
                    <Button
                      className="is-borderless is-outlined shrink"
                      onClick={(e) => funct.editPush(push.id)}
                    >
                      {' '}
                      <Icon icon="fas fa-pencil-alt" />{' '}
                    </Button>
                    <Button
                      className="is-borderless is-outlined shrink"
                      onClick={(e) => funct.deleteContentPush(push.id)}
                    >
                      {' '}
                      <Icon icon="fas fa-trash" />{' '}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default withMain(ListPushs);
