import React from 'react';
import DateRange from '../../../components/DateRange';
import Loader from '../../../components/Loader';

export const RenderRange = ({
  action,
  w,
  fct,
  fctInterval,
  interval,
  fctLoader,
  fctTimer,
}) => {
  const blocRange = (interval, onChange) => (
    <div className="flex row center">
      <div className={!interval ? 'overlay1' : 'overlay1'}>
        <DateRange onChange={onChange} small showClearDates />
      </div>
    </div>
  );

  const onChange = (startDate) => {
    if (startDate.startDate && startDate.endDate) {
      fctInterval(true);
      fctLoader(true);
      // fctTimer(false)
    } else {
      fctInterval(false);
      fctLoader(true);
      // fctTimer(false)
    }
    fct.selectOption('interval', action, startDate);
  };

  if (action === 'Functionality') return blocRange(interval, onChange);
  else if (action === 'NLP match rate') return blocRange(interval, onChange);
  else if (action === 'Event Action') return blocRange(interval, onChange);
  else
    return (
      <div className="text-align-center is-size-7">
        {w.homepage.unavailable}
      </div>
    );
};

export const RenderSort = ({
  action,
  input,
  w,
  fct,
  fctInterval,
  interval,
  fctInput,
  fctLoader,
  fctTimer,
}) => {
  const onChange = (input, interval, loader, time) => {
    fctInterval(interval);
    fctInput(input);
    fctLoader(loader);
    // fctTimer(time)
  };

  return (
    <div className="flex row space-around flex-wrap is-size-8">
      <div
        className={
          !interval && input === 'Ce mois-ci'
            ? 'm-t-md m-b-md rad-15px border-primary-lighter has-background-primary-ter pointer translateY transition'
            : 'm-t-md m-b-md rad-15px border-primary-lighter has-background-primary-lighter pointer translateY transition'
        }
        onClick={(e) => {
          fct.selectOption('Ce mois-ci', action);
          onChange('Ce mois-ci', false, true, false);
        }}
      >
        <p className="m-xs">{w.homepage.thisMonth}</p>
      </div>
      <div
        className={
          !interval && input === 'Cette semaine'
            ? 'm-t-md m-b-md rad-15px border-primary-lighter has-background-primary-ter pointer translateY transition'
            : 'm-t-md m-b-md rad-15px border-primary-lighter has-background-primary-lighter pointer translateY transition'
        }
        onClick={(e) => {
          fct.selectOption('Cette semaine', action);
          onChange('Cette semaine', false, true, false);
        }}
      >
        <p className="m-xs">{w.homepage.thisWeek}</p>
      </div>
      <div
        className={
          !interval && input === 'Depuis le début'
            ? 'm-t-md m-b-md rad-15px border-primary-lighter has-background-primary-ter pointer translateY transition'
            : 'm-t-md m-b-md rad-15px border-primary-lighter has-background-primary-lighter pointer translateY transition'
        }
        onClick={(e) => {
          fct.selectOption('Depuis le début', action);
          onChange('Depuis le début', false, true, false);
        }}
      >
        <p className="m-xs">{w.homepage.default}</p>
      </div>
    </div>
  );
};

export const RenderEvents = ({ analytics, action, w, time }) => {
  if (analytics.ga_analytics[action] === null) return <Loader />;
  else if (analytics.ga_analytics[action] === undefined)
    return (
      <div className="text-align-center is-size-7">
        {w.homepage.unavailable}
      </div>
    );
  return (
    analytics.ga_analytics[action] &&
    analytics.ga_analytics[action].data.map((event, i) => {
      if (i < 10) {
        const res = event.name;
        return (
          <div
            key={i}
            className="flex row flex-start is-size-8 m-b-md p-r-md p-l-md border-bottom"
          >
            <p className="mr-xs">{i + 1 + '.'} </p>
            <p>&nbsp;</p>
            <p>{res}</p>
          </div>
        );
      }
      return null;
    })
  );
};
