import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';
import StyledBigTitle from '../../../../components/parameters/StyledTitle';
import StyledLabel from '../../../../components/parameters/StyledLabel';
import StyledInput from '../../../../components/parameters/StyledInput';
import Webchat from '../../../../components/parameters/Webchat';
import InputRadio from '../../../../components/parameters/InputRadio';
import CircleImg from '../../../../components/parameters/CircleImg';
import StyledImage from '../../../../components/parameters/StyledImage';
import StyledUpload from '../../../../components/parameters/StyledUpload';
import InputFile from '../../../../components/parameters/InputFile';
import Upload from '../../../../_assets/icon/Upload';
import Toggle from '../../../../components/parameters/Toggle';
import { RightArrow } from '@styled-icons/boxicons-solid/RightArrow';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';

const Header = ({ wWebchat, state, dispatch, w, handleImage, m }) => {
  const [hideFirstContent, setHideFirstContent] = useState(true);
  const [hideSecondContent, setHideSecondContent] = useState(true);
  const [sizePerso, setSizePerso] = useState(true);
  const [toggleMobile, setToggleMobile] = useState(false);
  const { Handle } = Slider;

  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} %`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  return (
    <Wrapper>
      <StyledBigTitle>
        {wWebchat.contentHeader}
        <span onClick={() => setHideSecondContent(!hideSecondContent)}>
          {hideSecondContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideSecondContent && (
        <>
          <WrapperDiv mr={'0 2rem'}>
            <Toggle
              toggle={toggleMobile}
              handleClick={() => {
                setToggleMobile(!toggleMobile);
                dispatch({ type: 'setToggleMobile', payload: !toggleMobile });
              }}
              content={
                state.toggleMobile
                  ? wWebchat.header_disabled_on_mobile
                  : wWebchat.header_enabled_on_mobile
              }
            />
          </WrapperDiv>
          <WrapperDiv mr={'2rem'} space={'space-between'}>
            <StyledLabel width={'40'}>
              {wWebchat.title}
              <StyledInput
                type="text"
                maxLength="37"
                value={state.headerWebchat}
                onChange={(e) => {
                  dispatch({
                    type: 'setHeaderWebchat',
                    payload: e.target.value,
                  });
                }}
              />
            </StyledLabel>
            <StyledLabel width={'40'}>
              {wWebchat.subtitle}
              <StyledInput
                type="text"
                maxLength="37"
                value={state.subtitle}
                onChange={(e) => {
                  dispatch({ type: 'setSubtitle', payload: e.target.value });
                }}
              />
            </StyledLabel>
          </WrapperDiv>

          <WrapperDiv mr={'2rem'} space={'space-between'}>
            <StyledLabel width={'40'}>
              {wWebchat.titleSize}
              <Slider
                trackStyle={{ backgroundColor: 'red', height: '10px' }}
                railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                handleStyle={{
                  width: '20px',
                  height: '20px',
                  border: '1px solid lightgray',
                  boxShadow:
                    'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                }}
                min={14}
                max={24}
                defaultValue={state.sizeTitle}
                value={state.sizeTitle}
                onChange={(value) => {
                  dispatch({ type: 'setSizeTitle', payload: value });
                }}
                handle={handle}
              />
            </StyledLabel>
            <StyledLabel width={'40'}>
              {wWebchat.subtitleSize}
              <Slider
                trackStyle={{ backgroundColor: 'red', height: '10px' }}
                railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                handleStyle={{
                  width: '20px',
                  height: '20px',
                  border: '1px solid lightgray',
                  boxShadow:
                    'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                }}
                min={13}
                max={20}
                defaultValue={state.sizeSubtitle}
                value={state.sizeSubtitle}
                onChange={(value) => {
                  dispatch({ type: 'setSizeSubtitle', payload: value });
                }}
                handle={handle}
              />
            </StyledLabel>
          </WrapperDiv>

          <SubititleLight center>{wWebchat.iconToClose}</SubititleLight>
          <WrapperDiv mr={'2rem'} space={'space-between'}>
            {state.customIcon ? (
              <>
                <StyledDivWrapper alignItem={'flex-start'}>
                  <CircleImg width={'80'} height={'80'}>
                    <StyledImage width={'80'} src={state.customIcon} alt="" />
                  </CircleImg>
                  <ButtonWrapper>
                    <span>{wWebchat.changeIcon}</span>
                    <InputUpload
                      type="file"
                      onChange={(e) => {
                        handleImage(e, 'setCustomIcon');
                      }}
                    />
                  </ButtonWrapper>
                </StyledDivWrapper>
                <StyledLabel width={'40'}>
                  {wWebchat.crossSize}
                  <Slider
                    trackStyle={{ backgroundColor: 'red', height: '10px' }}
                    railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                    handleStyle={{
                      width: '20px',
                      height: '20px',
                      border: '1px solid lightgray',
                      boxShadow:
                        'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                    }}
                    min={16}
                    max={28}
                    defaultValue={state.sizeHeaderIcon}
                    value={state.sizeHeaderIcon}
                    onChange={(value) => {
                      dispatch({ type: 'setSizeHeaderIcon', payload: value });
                    }}
                    handle={handle}
                  />
                </StyledLabel>
              </>
            ) : (
              <>
                <WrapperColumn>
                  <StyledUpload noMargin width={'56'}>
                    <Upload />
                    <div>
                      <p>{w.download}</p>
                      <p>{w.downloadSize})</p>
                    </div>
                    <InputFile
                      type="file"
                      onChange={(e) => {
                        handleImage(e, 'setCustomIcon');
                      }}
                    />
                  </StyledUpload>
                  <StyledText width={'55%'}>{w.downloadFormat}</StyledText>
                </WrapperColumn>
              </>
            )}
          </WrapperDiv>

          <SubititleLight center>{wWebchat.avatar}:</SubititleLight>
          <WrapperDiv mr={'0'} space={'space-between'}>
            {state.avatarHeader || state.widgetIcon ? (
              <WrapperColumnUpload alignItem={'flex-start'}>
                <CircleImg width={'80'} height={'80'}>
                  <StyledImage
                    width={'80'}
                    src={state.avatarHeader || state.widgetIcon}
                    alt=""
                  />
                </CircleImg>
                <ButtonWrapper>
                  <span>{wWebchat.changeIcon}</span>
                  <InputUpload
                    type="file"
                    onChange={(e) => {
                      handleImage(e, 'setAvatarHeader');
                    }}
                  />
                </ButtonWrapper>
              </WrapperColumnUpload>
            ) : (
              <WrapperColumnUpload>
                <WrapperDiv mr={'0'}>{wWebchat.changeIconSend}</WrapperDiv>
                <WrapperDiv mr={'0 0 2rem 0'}>
                  <StyledUpload>
                    <Upload />
                    <div>
                      <p>{w.download}</p>
                    </div>
                    <InputFile
                      type="file"
                      onChange={(e) => {
                        handleImage(e, 'setAvatarHeader');
                      }}
                    />
                  </StyledUpload>
                </WrapperDiv>
              </WrapperColumnUpload>
            )}
          </WrapperDiv>

          <WrapperDiv
            mr={'2rem'}
            space={'space-between'}
            alignItem={'flex-start'}
          >
            <StyledDivWrapper width={'50%'}>
              <SubititleLight center>{wWebchat.colorHeader}</SubititleLight>
              <ChromePicker
                color={state.colorHeader}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setColorHeader', payload: val.hex });
                }}
              />
            </StyledDivWrapper>
            <StyledDivWrapper width={'40%'}>
              <SubititleLight center>{wWebchat.colorFont}</SubititleLight>
              <ChromePicker
                color={state.colorText}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setColorText', payload: val.hex });
                }}
              />
            </StyledDivWrapper>
          </WrapperDiv>

          <WrapperDiv mr={'2rem'} space={'space-between'}>
            <StyledLabel width={'40'}>
              {wWebchat.sizeIcon}
              <Slider
                trackStyle={{ backgroundColor: 'red', height: '10px' }}
                railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                handleStyle={{
                  width: '20px',
                  height: '20px',
                  border: '1px solid lightgray',
                  boxShadow:
                    'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                }}
                min={25}
                max={50}
                defaultValue={state.sizeIcon}
                value={state.sizeIcon}
                onChange={(value) => {
                  dispatch({ type: 'setV2Header', payload: true });
                  dispatch({ type: 'setDisplayLogo', payload: true });
                  dispatch({ type: 'setSizeIcon', payload: value });
                }}
                handle={handle}
              />
            </StyledLabel>
            <StyledLabel width={'40'}>
              {wWebchat.distance}
              <Slider
                trackStyle={{ backgroundColor: 'red', height: '10px' }}
                railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                handleStyle={{
                  width: '20px',
                  height: '20px',
                  border: '1px solid lightgray',
                  boxShadow:
                    'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                }}
                min={0}
                max={6}
                defaultValue={state.distanceIconText}
                value={state.distanceIconText}
                onChange={(value) => {
                  dispatch({ type: 'setDistanceIconText', payload: value });
                }}
                handle={handle}
              />
            </StyledLabel>
          </WrapperDiv>
        </>
      )}
      <StyledBigTitle>
        {wWebchat.formatHeader}
        <span onClick={() => setHideFirstContent(!hideFirstContent)}>
          {hideFirstContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideFirstContent && (
        <>
          <WrapperDiv>
            <SubititleLight mr={'2rem'}>{wWebchat.hasHeader}</SubititleLight>
            <StyledLabel radio flex noMargin mr>
              <InputRadio
                type="radio"
                name="hasHeader"
                value={state.hasHeader}
                checked={!state.hasHeader}
                onChange={() => {
                  dispatch({ type: 'setHasHeader', payload: !state.hasHeader });
                  dispatch({ type: 'setHeaderCurved', payload: false });
                }}
              />
              {wWebchat.yes}
            </StyledLabel>
            <StyledLabel radio flex noMargin>
              <InputRadio
                type="radio"
                name="hasHeader"
                value={state.hasHeader}
                checked={state.hasHeader}
                onChange={() => {
                  dispatch({ type: 'setHasHeader', payload: !state.hasHeader });
                  dispatch({ type: 'setHeaderCurved', payload: false });
                }}
              />
              {wWebchat.no}
            </StyledLabel>
          </WrapperDiv>

          <WrapperDiv mr={'2rem'}>
            <SubititleLight>{wWebchat.shape}</SubititleLight>
          </WrapperDiv>

          <WrapperDiv space={'space-around'} mr={'0'}>
            <div>
              <Webchat />
              <StyledLabel radio flex mt={'1rem'} justifyContent={'center'}>
                <InputRadio
                  type="radio"
                  value={state.headerCurved}
                  checked={!state.headerCurved}
                  name="headerCurved"
                  onChange={() => {
                    dispatch({
                      type: 'setHeaderCurved',
                      payload: !state.headerCurved,
                    });
                  }}
                />
                {wWebchat.straight}
              </StyledLabel>
            </div>
            <div>
              <Webchat curved />
              <StyledLabel radio flex mt={'1rem'} justifyContent={'center'}>
                <InputRadio
                  type="radio"
                  value={state.headerCurved}
                  checked={state.headerCurved}
                  name="headerCurved"
                  onChange={() => {
                    dispatch({
                      type: 'setHeaderCurved',
                      payload: !state.headerCurved,
                    });
                  }}
                />
                {wWebchat.curved}
              </StyledLabel>
            </div>
          </WrapperDiv>

          <WrapperDiv mr={'2rem'}>
            <SubititleLight>{wWebchat.sizeChoice}</SubititleLight>
          </WrapperDiv>

          <WrapperDiv space={'space-around'} mr={'0'}>
            <div>
              <Webchat />
              <StyledLabel radio flex mt={'1rem'} justifyContent={'center'}>
                <InputRadio
                  type="radio"
                  name="size"
                  value="45px"
                  checked={state.headerSize === '45px' && !sizePerso}
                  onChange={(e) => {
                    setSizePerso(false);
                    dispatch({ type: 'setV2Header', payload: true });
                    dispatch({
                      type: 'setHeaderSize',
                      payload: e.target.value,
                    });
                  }}
                />
                {wWebchat.large}
              </StyledLabel>
            </div>
            <div>
              <Webchat height={'40px'} />
              <StyledLabel radio flex mt={'1rem'} justifyContent={'center'}>
                <InputRadio
                  type="radio"
                  name="size"
                  value="20px"
                  checked={state.headerSize === '20px' && !sizePerso}
                  onChange={(e) => {
                    setSizePerso(false);
                    dispatch({ type: 'setV2Header', payload: true });
                    dispatch({
                      type: 'setHeaderSize',
                      payload: e.target.value,
                    });
                  }}
                />
                {wWebchat.slim}
              </StyledLabel>
            </div>
          </WrapperDiv>
          <WrapperDiv noWrap mr={'2rem'}>
            <StyledLabel radio flex noMargin width={'50'}>
              <InputRadio
                type="radio"
                name="size"
                checked={sizePerso}
                onChange={() => {
                  setSizePerso(true);
                }}
              />
              {wWebchat.customize}
            </StyledLabel>
            <StyledWidth>
              <Slider
                trackStyle={{ backgroundColor: 'red', height: '10px' }}
                railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                handleStyle={{
                  width: '20px',
                  height: '20px',
                  border: '1px solid lightgray',
                  boxShadow:
                    'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                }}
                min={20}
                max={65}
                disabled={!sizePerso}
                defaultValue={state.headerSize?.slice(0, -2)}
                value={state.headerSize?.slice(0, -2)}
                onChange={(value) => {
                  dispatch({ type: 'setV2Header', payload: true });
                  dispatch({ type: 'setHeaderSize', payload: `${value}px` });
                }}
                handle={handle}
              />
            </StyledWidth>
          </WrapperDiv>

          <WrapperDiv mr={'2rem'}>
            <SubititleLight>{wWebchat.choiceEl}</SubititleLight>
          </WrapperDiv>

          <WrapperDiv space={'space-around'}>
            <div>
              <SubititleLight center>{wWebchat.icon}</SubititleLight>
              <Webchat iconWidget={state.iconWidget} />
              <WrapperMultipleLabel>
                <StyledLabel radio flex noMargin margin={'.8rem 0'}>
                  <InputRadio
                    name="iconPosition"
                    type="checkbox"
                    value={state.displayLogo}
                    checked={!state.displayLogo}
                    onChange={() => {
                      dispatch({
                        type: 'setDisplayLogo',
                        payload: !state.displayLogo,
                      });
                      dispatch({ type: 'setV2Header', payload: true });
                    }}
                  />
                  {wWebchat.noIcon}
                </StyledLabel>
              </WrapperMultipleLabel>
            </div>
            <div>
              <SubititleLight center>{wWebchat.title}</SubititleLight>
              <Webchat title={'Nom du bot'} />
              <WrapperMultipleLabel>
                <StyledLabel radio flex noMargin margin={'.8rem 0'}>
                  <InputRadio
                    name="titlePosition"
                    type="checkbox"
                    value="none"
                    checked={!state.displayTitle}
                    onChange={(e) => {
                      dispatch({
                        type: 'setDisplayTitle',
                        payload: !state.displayTitle,
                      });
                    }}
                  />
                  {wWebchat.noTitle}
                </StyledLabel>
              </WrapperMultipleLabel>
            </div>
            <div>
              <SubititleLight center>{wWebchat.subtitle}</SubititleLight>
              <Webchat
                iconWidget={state.iconWidget}
                title={'Nom du bot'}
                subtitle={'sous-titre'}
              />
              <WrapperMultipleLabel>
                <StyledLabel radio flex noMargin margin={'.8rem 0'}>
                  <InputRadio
                    type="checkbox"
                    value="noSubtitle"
                    checked={!state.displaySubtitle}
                    onChange={(e) => {
                      dispatch({
                        type: 'setDisplaySubtitle',
                        payload: !state.displaySubtitle,
                      });
                    }}
                  />
                  {wWebchat.noSubtitle}
                </StyledLabel>
              </WrapperMultipleLabel>
            </div>
          </WrapperDiv>

          <WrapperDiv>
            <SubititleLight mr={'2rem'}>
              {wWebchat.positionHeader}
            </SubititleLight>
            <StyledLabel radio flex noMargin margin={'0 1rem 0 0'}>
              <InputRadio
                type="radio"
                value="start"
                checked={state.positionHeader === 'start'}
                onChange={(e) => {
                  dispatch({
                    type: 'setPositionHeader',
                    payload: e.target.value,
                  });
                }}
              />
              {wWebchat.left}
            </StyledLabel>
            <StyledLabel radio flex noMargin>
              <InputRadio
                type="radio"
                value="center"
                checked={state.positionHeader === 'center'}
                onChange={(e) => {
                  dispatch({
                    type: 'setPositionHeader',
                    payload: e.target.value,
                  });
                }}
              />
              {wWebchat.middle}
            </StyledLabel>
          </WrapperDiv>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledText = styled.p`
  font-weight: 300;
  font-size: 0.8em;
  margin-top: ${({ mt }) => mt};
  width: ${({ width }) => width};
`;

const WrapperDiv = styled.div`
  display: flex;
  align-items: ${({ alignItem }) => alignItem || 'center'};
  margin: ${({ mr }) => (mr ? mr : '2rem 0')};
  flex-wrap: ${({ noWrap }) => (noWrap ? 'nowrap' : 'wrap')};
  justify-content: ${({ space }) => space};
`;

const StyledDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: ${({ mr }) => mr};
  width: ${({ width }) => width};
`;

const SubititleLight = styled.h3`
  font-size: 1.1.rem;
  margin-right: ${({ mr }) => mr};
  display: ${({ center }) => center && 'flex'};
  justify-content: ${({ center }) => center && 'center'};
  margin-bottom: ${({ center }) => center && '1rem'};
`;

const WrapperMultipleLabel = styled.div`
  display: block;
  margin: 0 2rem;
`;

const StyledWidth = styled.div`
  width: 50%;
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: 150px;
  text-align: center;
  margin-top: 1rem;
  transition: 0.3s;
  cursor: pointer;
  transition: opacity 0.3s;

  > span {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.white};
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 12px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const InputUpload = styled.input`
  display: inline-block;
  position: absolute;
  z-index: 1000000;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const WrapperColumnUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  align-items: center;
`;

export default Header;
