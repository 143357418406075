import React, { useState } from 'react';
import styled from 'styled-components';
import Categorie from './Categorie';
import Modal from '../../../components/Modal';
import { _sendFeedback } from '../../../_tools/ui';
import { Field, Input, Button } from '@ask-mona/ui';

export default (props) => {
  const { focus } = props;
  return (
    <ViewContainer
      className="w-83p inline-block h-100p align-top overflow-scroll"
      style={{ height: 'inherit' }}
    >
      <div>{focus && _toContents(props)}</div>
      <AddCategory {...props} />
    </ViewContainer>
  );
};

const AddCategory = (props) => {
  const {
    m: { w, bot },
    api: { intent },
    addCat,
    setAddCat,
  } = props;
  const [nameFR, setNameFR] = useState('');
  const [nameEN, setNameEN] = useState('');
  return (
    <Modal
      {...props}
      isOpen={addCat}
      close={() => setAddCat(false)}
      selector="#root"
    >
      <div className="w-4 has-background-white">
        <div className="has-background-primary p-md has-text-white">
          {w.qa.addCat}
        </div>
        <div className="p-md">
          <Field title={w.qa.catNameFR} className="m-b-md">
            <Input onChange={(e) => setNameFR(e.target.value)} />
          </Field>
          <Field title={w.qa.catNameEN}>
            <Input onChange={(e) => setNameEN(e.target.value)} />
          </Field>
          <div className="flex row space-between p-t-md">
            <Button color="danger" onClick={async () => setAddCat(false)}>
              {w.generic.cancel}
            </Button>
            <Button
              onClick={async () => {
                if (nameFR && nameEN) {
                  _sendFeedback('warning', 'loader');
                  let res = await intent._createCategory(
                    {
                      bot_id: bot.id,
                      fr: nameFR,
                      en: nameEN,
                    },
                    { refetchQueries: ['getCategoriesAndIntents'] }
                  );
                  if (res.success) {
                    _sendFeedback('success', w.success.created);
                    setAddCat(false);
                  } else _sendFeedback('danger', w.error.general);
                }
              }}
            >
              {w.generic.validate}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const _toContents = (props) => {
  const {
    m: {
      user: { organization },
      bot,
      w
    },
    api: { intent },
    search,
    lang,
    focus,
    cats,
  } = props;
  let items = [];

  const leoBot = bot.features.some((el) => el.slug === 'leo');

  if (focus.id === 'allActivated' || focus.id === 'allDesactivated')
    items = _getAllIntents(cats, lang, organization.id, search, bot, intent);
  else if (search)
    items = _getAllIntents(cats, lang, organization.id, search, bot, intent, leoBot);
  else items = [focus];
  return (
    <div>
      {search && 
      <>
        <TableHeader className="flex row has-background-primary has-text-white">
          <div className="p-xs flex center grow-3 basis-0">{w.qa.theme}</div>
          <div className="p-xs flex center grow-4 basis-0">{w.qa.answer}</div>
          <div className="p-xs flex center grow-2 basis-0">{w.qa.options}</div>
          <div className="p-xs flex center grow-1 basis-0">{w.qa.state}</div>
        </TableHeader>
        </>
      }
      {items.map((cat) => (
        <Categorie key={cat.id} {...props} cat={cat} />
      ))}
    </div>
  );
};

const _getSearchItem = (
  cats = [],
  lang = 'fr',
  search = '',
  orgId = -1,
  leoBot = false
) => {
  let items = Object.assign(cats, []);
  let reg = new RegExp(`${search}`, 'ig');
  items = items
    .filter((c) => {
      if (orgId === 1) return true;
      else return c.id !== '1545240456' || c.id !== '12331565';
    })
    .map((cat) => {
      let filteredIntents = cat.intents.filter((int) => {
        if (leoBot) {
          return int.name.match(reg);
        } else {
          if (!int[lang]) return false;
          return int[lang].name.match(reg);
        }
      });
      return { ...cat, intents: filteredIntents };
    });
  items = items.filter((cat) => cat.intents.length > 0);
  return items;
};

const _getAllIntents = (
  cats = [],
  lang = 'fr',
  orgId = -1,
  search = '',
  bot,
  intent,
  leoBot = false
) => {
  let items = Object.assign(cats, []);
  items = items
    .filter((c) => {
      if (orgId === 1) return true;
      else return c.id !== '1545240456' || c.id !== '12331565';
    })
    .map((cat) => {
      let filteredIntents = cat.intents.filter(async (int) => {
        if (int.programmatic && cat.fr.toLowerCase() !== 'programmatique') {
          let programmaticCategory = cats.find(
            (cat) => cat.fr.toLowerCase() === 'programmatique'
          );
          await intent._changeCategory(
            {
              bot_id: bot.id,
              intent_id: int.id,
              category_id: programmaticCategory.id,
            },
            { refetchQueries: ['getCategoriesAndIntents'] }
          );
        }
      });
      return { ...cat, intents: filteredIntents };
    });
  items = items.filter((cat) => cat.intents.length > 0);
  if (search) items = _getSearchItem(items, lang, search, orgId, leoBot);
  return items;
};

const TableHeader = styled.div`
  height: 40px;
  align-items: center;
`;

const ViewContainer = styled.div`
  min-width: 80%;

  @media (max-width: 1400px) {
    min-width: 70%;
  }
  @media (max-width: 1200px) {
    min-width: 60%;
  }
  @media (max-width: 1000px) {
    min-width: 50%;
  }
`;
