import React from 'react';
import { Button, Icon } from '@ask-mona/ui';
import MUIButton from '@material-ui/core/Button';

import { _random } from '../../../_tools/other';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#2D2F7B' },
    secondary: { main: '#E66E6E' },
  },
});

export default ({ m: { w }, dispatch, onSave }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="m-l-md flex row grow-1 space-between is-size-7">
        <div className="flex row">
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={() =>
              dispatch({ type: 'edit', payload: { id: _random(), type: 0 } })
            }
            className="m-r-xs"
          >
            <Icon icon="fas fa-plus" /> {w.pricing.addCategorie}
          </Button>
          <Button
            color="secondary-bis"
            variant="contained"
            size="small"
            onClick={() =>
              dispatch({ type: 'edit', payload: { id: _random(), type: 1 } })
            }
            className="m-r-xs has-text-primary"
          >
            <Icon icon="fas fa-plus" /> {w.pricing.addSubCategorie}
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            size="small"
            onClick={() =>
              dispatch({ type: 'edit', payload: { id: _random(), type: 2 } })
            }
            className="m-r-xs is-outlined"
          >
            <Icon icon="fas fa-plus" />
            {w.pricing.addTarif}
          </Button>
        </div>
        <div className="flex row">
          <MUIButton onClick={onSave} variant="contained" color="primary">
            {w.generic.save}
          </MUIButton>
          <MUIButton
            className="shrink is-outlined m-l-xs"
            color="primary"
            onClick={() => dispatch({ type: 'getInfo', payload: true })}
          >
            <Icon
              className="has-text-primary"
              icon="fas fa-exclamation-circle"
            />
          </MUIButton>
        </div>
      </div>
    </MuiThemeProvider>
  );
};
