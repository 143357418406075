import React from 'react';
import Heading from './Heading';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { Button, Icon } from '@ask-mona/ui';

export default ({ dispatch, items, ...other }) => {
  return (
    <React.Fragment>
      <Heading {...other} />
      <Container
        lockAxis="y"
        onSortEnd={({ oldIndex, newIndex }) => {
          let newItems = arrayMove(items, oldIndex, newIndex);
          dispatch({ type: 'setHasChange', payload: true });
          dispatch({ type: 'setItems', payload: newItems });
        }}
        items={items}
        dispatch={dispatch}
      />
    </React.Fragment>
  );
};

const Container = SortableContainer(({ items, dispatch }) => {
  return (
    <div
      className="h-fill overflow-auto relative"
      style={{ paddingBottom: '53px' }}
    >
      {items.map((el, index) => (
        <Parts key={el.id} index={index} el={el} dispatch={dispatch} />
      ))}
    </div>
  );
});

const Parts = SortableElement(({ el, dispatch }) => {
  const { name, type, recipient, price, description } = el;
  return (
    <div
      className={`flex row border-b-primary-lighter has-background-${
        type === 0 ? 'primary-light' : type === 1 ? 'primary-lighter' : 'white'
      }`}
      style={{ maxHeight: '50px' }}
    >
      <div className="has-text-primary p-sm flex items-center grow-2 basis-0">
        {name ? name.fr : ''}
      </div>
      <div className="has-text-primary p-sm flex overflow-auto grow-2 basis-0">
        <span
          className="text-ellipsis"
          data-text={recipient && recipient.fr ? recipient.fr.join(', ') : ''}
        >
          {recipient && recipient.fr ? recipient.fr.join(', ') : ''}
        </span>
      </div>
      <div className="has-text-primary p-sm flex overflow-auto grow-2 basis-0">
        <span className="text-ellipsis" data-text={description.fr || ''}>
          {description.fr}
        </span>
      </div>
      <div className="has-text-primary p-sm flex items-center grow-1 basis-0 center">{`${
        price ? price + '€' : ''
      }`}</div>
      <div className="p-sm flex items-center grow-2 basis-0 center">
        <Button
          className="shrink is-outlined is-borderless"
          onClick={() => dispatch({ type: 'copy', payload: el })}
        >
          <Icon icon="fas fa-copy" className="cursor-none has-text-primary" />
        </Button>
        <Button
          className="shrink is-outlined is-borderless"
          onClick={() => dispatch({ type: 'edit', payload: el })}
        >
          <Icon icon="fas fa-edit" className="has-text-primary cursor-none" />
        </Button>
        <Button
          className="shrink is-outlined is-borderless"
          onClick={() => dispatch({ type: 'delt', payload: el })}
          color="danger"
        >
          <Icon icon="fas fa-trash" className="cursor-none" />
        </Button>
      </div>
    </div>
  );
});
