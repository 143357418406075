import { languages } from '../translations/';

const maxLength = {
  name: '',
  short_title: '20',
  long_title: '90',
  anecdote: '650',
  date: '650',
  description: '650',
  more_infos: '650',
  practical_infos: '650',
  price: '650',
  url: '650',
  url_ticketing: '650',
};

const LANGUAGES_IDS = {
  fr: 1,
  en: 2,
};

export function getLanguageId(languageCode) {
  return LANGUAGES_IDS[languageCode];
}

export const _defaultTranslation = (lang) => {
  let obj = {};
  for (let key in maxLength) obj = { ...obj, [key]: '' };
  obj = { ...obj, language_id: languages[lang], language: { code: lang } };
  return obj;
};

export const _formatTranslation = (translations = [], lang) => {
  let ret = {};
  let tr = translations || [];
  if (!tr.length) tr.push(_defaultTranslation(lang));
  tr.forEach((t) => {
    const {
      language_id,
      language: { code },
      event_id,
      ...other
    } = t;
    let obj = { language_id };
    for (let key in other) {
      obj = {
        ...obj,
        language_id: languages[lang],
        [key]: {
          name: key,
          value: other[key] || '',
          maxLength: maxLength[key],
          'data-lang': code,
        },
      };
    }
    ret = { ...ret, [code]: obj };
  });
  return ret;
};

export const _updateTranslations = (translations, target) => {
  const lang = target.getAttribute('data-lang');
  const { name, value } = target;
  let newTranslations = [];
  if (!translations)
    newTranslations.push({ ..._defaultTranslation(lang), [name]: value });
  else if (translations.findIndex((t) => t.language.code === lang) === -1) {
    newTranslations = translations.concat({
      ..._defaultTranslation(lang),
      [name]: value,
    });
  } else
    translations.forEach((t) => {
      if (t.language.code === lang)
        newTranslations.push({ ...t, [name]: value });
      else newTranslations.push(t);
    });
  return newTranslations;
};
