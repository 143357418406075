import React, { useState, useReducer, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Textarea, Icon, Button as Btn } from '@ask-mona/ui';
import { textTemplate, btnTemplate } from '../Reducer';
import { _getKeyValue } from '../../../_tools/other';
import { Button as GenericBtn } from './Generic';

import ScenariosContext from '../../../containers/scenarios/context/scenariosContext';

const _reducer = (state, action) => {
  switch (action.type) {
    case 'onChange':
    case 'onChangeBtn':
    case 'onPopBtn':
      const { name, value, index } = action.payload;
      if (action.type === 'onChange')
        return {
          ...state,
          attachment: {
            ...state.attachment,
            payload: { ...state.attachment.payload, [name]: value },
          },
        };
      else if (action.type === 'onChangeBtn') {
        let newBtn = { ...value };
        if (newBtn.type === 'web_url') delete newBtn['payload'];
        else delete newBtn['url'];
        return {
          ...state,
          attachment: {
            ...state.attachment,
            payload: {
              ...state.attachment.payload,
              buttons: state.attachment.payload.buttons.map((b, i) =>
                i === index ? newBtn : b
              ),
            },
          },
        };
      } else if (action.type === 'onPopBtn')
        return {
          ...state,
          attachment: {
            ...state.attachment,
            payload: {
              ...state.attachment.payload,
              buttons: state.attachment.payload.buttons.filter(
                (b, i) => i !== index
              ),
            },
          },
        };
      return { ...state };
    case 'onAddBtn':
      return {
        ...state,
        attachment: {
          ...state.attachment,
          payload: {
            ...state.attachment.payload,
            buttons: state.attachment.payload.buttons.concat(btnTemplate()),
          },
        },
      };
    case 'setEl':
      return { ...action.payload };
    default:
      return state;
  }
};

export default (props) => {
  const {
    m: { w },
    focus,
    lang,
    subfocus,
    element,
    index,
    fix,
    advanced,
    random,
    arrayOfElements,
    newIndex,
    setAddLink,
  } = props;
  const [foc, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const [mouseHover, setMouseHover] = useState(false);
  const [state, dispatch] = useReducer(_reducer, element);
  const [text, setText] = useState(_getKeyValue(element, 'text'));
  const qr = _getKeyValue(element, 'quick_replies');
  const buttons = _getKeyValue(state, 'buttons');

  const scenariosContext = useContext(ScenariosContext);

  useEffect(() => {
    dispatch({ type: 'setEl', payload: element });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus.key, subfocus ? subfocus.key : '-', lang]);

  useEffect(() => {
    if (!foc) {
      setText(element['attachment']['payload']['text']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element['attachment']['payload']['text']]);

  useEffect(() => {
    let newArray = [];
    if (random) {
      newArray = [...arrayOfElements];
      newArray[newIndex] = [{ ...element, ...state }];
    }
    if (!fix) {
      props.dispatch &&
        props.dispatch({
          type: 'onElemChange',
          payload: {
            index,
            value: random ? newArray : { ...element, ...state },
            func: advanced
              ? null
              : scenariosContext.functionalitiesSubsbarFocus === 'content'
              ? null
              : scenariosContext.functionalitiesSubsbarFocus,
          },
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Container
      className="m-xs border-grey-lighter rad-15px has-background-white"
      style={{ width: '225px' }}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
    >
      {(mouseHover || hover) && !fix && focus.key !== 'p:ask_language' && (
        <div className="flex row space-between animation-slideDown overflow-hidden">
          <Btn
            color="danger is-outlined is-borderless is-size-7"
            onClick={() => {
              let newArray = [];
              if (random) {
                newArray = [...arrayOfElements];
                newArray[newIndex] = [
                  textTemplate(text, qr ? { quick_replies: qr } : {}),
                ];
              }
              props.dispatch({
                type: 'onElemChange',
                payload: {
                  index,
                  value: random
                    ? newArray
                    : textTemplate(text, qr ? { quick_replies: qr } : {}),
                  func: advanced
                    ? null
                    : scenariosContext.functionalitiesSubsbarFocus === 'content'
                    ? null
                    : scenariosContext.functionalitiesSubsbarFocus,
                },
              });
              if (props.qa) {
                setAddLink();
                props.dispatch({
                  type: 'chgElement',
                  payload: {
                    index: props.elIndex,
                    value: text,
                  },
                  func: advanced
                    ? null
                    : scenariosContext.functionalitiesSubsbarFocus === 'content'
                    ? null
                    : scenariosContext.functionalitiesSubsbarFocus,
                });
              }
            }}
          >
            <Icon icon="fas fa-undo-alt" />
          </Btn>
          <Btn
            color="success is-size-7 is-outlined is-borderless"
            onClick={() => {
              if (hover) setHover(false);
              else setHover(true);
            }}
          >
            {hover ? <Icon icon="fas fa-check" /> : <Icon icon="fas fa-pen" />}
          </Btn>
        </div>
      )}
      <div
        className={`w-100p has-text-white has-background-${
          foc ? 'primary-bis' : 'primary-ter'
        } flex center`}
        style={{ borderRadius: '15px 15px 0px 0px' }}
      >
        <Textarea
          disabled={fix ? true : false}
          name="text"
          resize="auto"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={text || ''}
          onChange={(e) => {
            setText(e.target.value);
            dispatch({ type: 'onChange', payload: e.target });
          }}
          style={{ width: '90%' }}
          className="is-transparent"
        />
      </div>
      {buttons.map((b, bIndex) => (
        <GenericBtn
          key={bIndex}
          {...props}
          bIndex={bIndex}
          el={b}
          hover={hover}
          dispatch={dispatch}
          pDispatch={props.dispatch}
        />
      ))}
      {hover && buttons.length < 3 && (
        <div className="flex center items-center">
          <Btn
            color="success"
            className="is-size-7 expand"
            onClick={() => dispatch({ type: 'onAddBtn' })}
          >
            {w.generic.addBtn}
          </Btn>
        </div>
      )}
      {random && (
        <RemoveElementButton
          onMouseOver={mouseHover || hover}
          onClick={() => {
            let newArray = [...arrayOfElements];
            newArray = newArray.filter((el) => el[0] !== element);
            props.dispatch &&
              props.dispatch({
                type: 'onElemChange',
                payload: {
                  index,
                  value: newArray,
                  func: null,
                },
              });
          }}
        >
          <Icon icon="fas fa-times" />
        </RemoveElementButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const RemoveElementButton = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  color: gray;
  background-color: #ececec;
  border-radius: 20px;
  position: absolute;
  top: -5px;
  right: ${(p) => (p.onMouseOver ? '-17px' : '-5px')};
  cursor: pointer;
`;
