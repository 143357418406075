import React from 'react';
import { withGlobals } from '../hoc/withGlobals';

const Button = ({ context: { skin }, title, className, ...props }) => (
  <div
    className={`webchat_button has-text-info flex-1 ${className || ''}`}
    {...props}
  >
    {title}
  </div>
);

export default withGlobals(Button);
