import React, { Component } from 'react';
import styled from 'styled-components';
import ShowModalAlert from './alertMessages/ShowModalAlert';
import { withMain } from '../hoc/main';
import { MessageError } from '@styled-icons/boxicons-regular/MessageError';

class AlertMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    const { modal } = this.state;
    if (modal) this.setState({ modal: false });
    else this.setState({ modal: true });
  }

  render() {
    const { modal } = this.state;
    const {
      m: { w, bot },
    } = this.props;
    return (
      <React.Fragment>
        <div className="flex center pointer" onClick={this.toggleModal}>
          {this.props.homepage ? (
            <StyledButton>
              {this.props.alertMessage > 0
                ? this.props.alertMessage + w.homepage.messagesActif
                : w.homepage.messageActif}
              <MessageError size={20} />
            </StyledButton>
          ) : (
            <Container>
              <i className="has-text-primary fas fa-exclamation alert__icon p-xs"></i>
              <div className="flex center column is-size-7 m-l-xs">
                <Text className="flex center">
                  {w.alertMessages.alertMessages}
                </Text>
              </div>
            </Container>
          )}
        </div>
        {modal && (
          <ShowModalAlert
            show={modal}
            close={this.toggleModal}
            bot_id={bot.id}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withMain(AlertMessages);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span`
  color: ${(p) => p.theme.colors.primary};
`;

const StyledButton = styled.div`
  background-color: #e06d6d;
  color: #fff;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0.8rem;
  transition: opacity 0.5s;
  > svg {
    margin-left: 0.2rem;
  }
  &:hover {
    opacity: 0.7;
  }
`;
