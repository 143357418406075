import React, { useState } from 'react';
import Round from './Round';
import Oh from './Oh';
import Edit from './Edit';

const SelectAlert = (props) => {
  const { item, index, fct, select, periods, error, bool, state, w } = props;

  const [display, setDisplay] = useState(false);

  const edit = () => {
    display ? setDisplay(false) : setDisplay(true);
  };

  return (
    <div
      className="rad-5px has-background-primary-lighter m-b-xs transition is-size-7"
      style={!display ? { height: '50px' } : { height: '350px' }}
    >
      <div
        className="flex row space-between"
        style={!display ? { height: '100%' } : { height: '13%' }}
      >
        <div className="w-1 flex column center row m-xs">
          <Round item={item} select={select} index={index} />
        </div>
        <div className="w-5 flex column center">
          <div className="has-background-white h-1 p-xs border-solid text-ellipsis--alone">
            {item.content.fr[0]}
          </div>
        </div>
        <div className="w-4 flex column center m-xs">
          <Oh date={item.date} hours={item.hours} w={w} />
        </div>
        <div className="w-2 flex row space-around">
          <div className="flex column center">
            <div onClick={() => edit()}>
              {!display ? (
                <i className="fas fa-pencil-alt fa-sm"></i>
              ) : (
                <i className="fas fa-angle-down fa-lg"></i>
              )}
            </div>
          </div>
          <div className="flex column center">
            <div onClick={() => fct.deleteAlert(item.id)}>
              <i className="fas fa-trash-alt fa-sm"></i>
            </div>
          </div>
        </div>
      </div>
      <Edit
        item={item}
        index={index}
        display={display}
        fct={fct}
        periods={periods}
        error={error}
        bool={bool}
        state={state}
      />
    </div>
  );
}

export default SelectAlert;
