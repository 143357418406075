export const _showAllPushs = async (botID) => {
  const url = `${process.env.REACT_APP_HTTP_LINK}/rest/push/show/${botID}`;
  const req = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    return await { url, req };
  } catch (err) {
    console.log(err);
  }
};

export const _showPush = async (botID, pushID) => {
  const url = `${process.env.REACT_APP_HTTP_LINK}/rest/push/show/${botID}/${pushID}`;
  const req = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    return await { url, req };
  } catch (err) {
    console.log(err);
  }
};

export const _deletePush = async (botID, pushID) => {
  const url = `${process.env.REACT_APP_HTTP_LINK}/rest/push/delete/${botID}/${pushID}`;
  const req = {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    return await { url, req };
  } catch (err) {
    console.log(err);
  }
};

export const _broadcastPush = async (botID, pushID, json) => {
  const url = `${process.env.REACT_APP_HTTP_LINK}/rest/push/broadcast/${botID}/${pushID}`;
  const req = {
    method: 'POST',
    mode: 'cors',
    body: json,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    return await { url, req };
  } catch (err) {
    console.log(err);
  }
};

export const _createPush = async (botID, json) => {
  const url = `${process.env.REACT_APP_HTTP_LINK}/rest/push/create/${botID}`;
  const req = {
    method: 'POST',
    mode: 'cors',
    body: json,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    return await { url, req };
  } catch (err) {
    console.log(err);
  }
};

export const _updatePush = async (botID, pushID, json) => {
  const url = `${process.env.REACT_APP_HTTP_LINK}/rest/push/update/${botID}/${pushID}`;
  const req = {
    method: 'PUT',
    mode: 'cors',
    body: json,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    return await { url, req };
  } catch (err) {
    console.log(err);
  }
};

export const _suggestTags = async (botID) => {
  const url = `${process.env.REACT_APP_HTTP_LINK}/rest/push/tags/suggest/${botID}`;
  const req = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    return await { url, req };
  } catch (err) {
    console.log(err);
  }
};

export const _associateTagPreset = async (botID, name, data) => {
  const url = `${process.env.REACT_APP_HTTP_LINK}/rest/push/tag/associate/${botID}/preset?${name}=${data}`;
  const req = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    return await { url, req };
  } catch (err) {
    console.log(err);
  }
};

export const _associateTagPresetSuggest = async (botID, tag) => {
  const url = `${process.env.REACT_APP_HTTP_LINK}/rest/push/tag/associate/${botID}/${tag}`;
  const req = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    return await { url, req };
  } catch (err) {
    console.log(err);
  }
};

export const _deleteTag = async (botID, tag) => {
  console.log(tag);
  const url = `${process.env.REACT_APP_HTTP_LINK}/rest/push/tag/delete/${botID}/${tag}`;
  const req = {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    return await { url, req };
  } catch (err) {
    console.log(err);
  }
};

export const _getTag = async (botID, tagID) => {
  console.log(tagID);
  const url = `${process.env.REACT_APP_HTTP_LINK}/rest/push/tag/show/${botID}/${tagID}`;
  const req = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    return await { url, req };
  } catch (err) {
    console.log(err);
  }
};
