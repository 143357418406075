import React, { Component } from 'react';
import { _random } from '../../_tools/other';
import { Button, Icon } from '@ask-mona/ui';
import useHover from '../../hooks/useHover';
import Days from './Days';
import Hours from './Hours';

class Blocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
    };
  }

  setAdd = () => this.setState((state) => ({ add: !state.add }));

  popBlock = (block) => {
    const { onChange, blocks } = this.props;
    const newBlocks = blocks.filter((b) => {
      return b.index !== block.index;
    });
    onChange && onChange(newBlocks);
  };

  setBlock = (nBlock) => {
    const { onChange, blocks } = this.props;
    const newBlocks = blocks.map((b) => {
      return b.index === nBlock.index ? nBlock : b;
    });
    onChange && onChange(newBlocks);
  };

  addBlock = (nBlock) => {
    const { onChange, blocks } = this.props;
    onChange && onChange(blocks.concat(nBlock));
    this.setState({ add: false });
  };

  render() {
    const { add } = this.state;
    const { onChange, blocks, ...other } = this.props;
    const {
      g: { w },
      daysAllowed,
    } = other;
    return (
      <div className="p-md flex column basis-0 grow-3 space-pre">
        {blocks.map((b) => (
          <Block
            key={_random()}
            block={b}
            {...other}
            onChange={this.setBlock}
            onClick={() => this.popBlock(b)}
          />
        ))}
        {!add && daysAllowed && blocks.length > 0 && (
          <Button className="is-outlined is-borderless" onClick={this.setAdd}>
            <Icon icon="fas fa-plus is-size-7" />
            {w.generic.add}
          </Button>
        )}
        {(add || blocks.length === 0) && (
          <Block
            block={{ days: [], hours: [] }}
            {...other}
            onChange={this.addBlock}
            onClick={this.setAdd}
          />
        )}
      </div>
    );
  }
}

const Block = ({ daysAllowed, onClick, ...other }) => {
  const element = (hovered) => (
    <div>
      <Button
        color="danger"
        className="p-l-md is-size-7 h-100 shrink is-borderless"
        onClick={onClick}
      >
        <Icon icon="fas fa-times" />
      </Button>
    </div>
  );

  const [hoverable, hovered] = useHover(element);

  return (
    <div
      className={`${
        hovered ? 'border-b-danger has-background-danger-light' : ''
      } flex row`}
    >
      <div className="flex column basis-0 grow-1 space-pre">
        {daysAllowed && <Days {...other} />}
      </div>
      <div className="flex column basis-0 grow-1 space-pre">
        <Hours {...other} />
      </div>
      {other.oph && hoverable}
    </div>
  );
};

export default Blocks;
