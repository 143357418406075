const Loader = (template) => {
  switch (template) {
    case 'template1':
      return {
        v2: true,
      }
    case 'template2':
      return {
        v2: false,
      }
    default:
      break;
  }
};

export default Loader;
