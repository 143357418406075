import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { _isImg, _isVid } from '../../../../_tools/is';
import ContainerScroll from '../../../../components/parameters/ContainerScroll';
import StyledBigTitle from '../../../../components/parameters/StyledTitle';

const PreviewHomePage = ({ state, m }) => {
  return (
    <ContainerScroll width={'40'} border>
      <StyledBigTitle textAlign={'center'}>{m.w.parameters.preview}</StyledBigTitle>
      <WebchatContainer
        curved={state.headerCurved}
        colorHeader={state.colorHeader}
        borderRadius={state.borderRadius}
        headerSize={state.headerSize}
        boxShadow={state.boxShadowWebchat}
      >
        <GlobalStyle
          fontFamilly={state.fontSelected}
          formatFont={state.formatFont}
          fontUrl={state.urlFont}
        />
        <HomepageWrapper background={state.homeContentBg}>
          <WrapperContent>
            <WrapperMedia isSvg={/\.(svg)$/i.test(state.homeImage)}>
              {state.homeImage &&
                /\.(jpe?g|png|gif|svg)$/i.test(state.homeImage) && (
                  <img src={state.homeImage} />
                )}
              {state.homeImage && _isVid(state.homeImage) && (
                <video controls src={state.homeImage}>
                  <source src={state.homeImage} type={`video/webm`} />
                </video>
              )}
            </WrapperMedia>
            <StyledTitle
              fontSize={state.homeTitleFontSize}
              color={state.homeTitleColor}
            >
              {state.homeTitle['fr']}
            </StyledTitle>

            <StyledContent
              color={state.homeContentColor}
              fontSize={state.homeContentFontSize}
              dangerouslySetInnerHTML={{ __html: state.homeContent[m.bot.default_language] }}
            />
          </WrapperContent>
          <WrapperQr>
            {state.homeButtons.map((el) => (
              <StyledQr
                background={state.homeButtonsBackground}
                color={state.homeButtonsColor}
                template={state.qrTemplate}
                borderRadius={state.homeButtonsBorder}
                fontSize={state.fontSizeQr}
                fontFamilly={state.fontSelected}
                height={state.homeButtonsHeight}
                width={state.homeButtonsWidth}
                borderColor={state.homeButtonsColor}
              >
                {el.title[m.bot.default_language]}
              </StyledQr>
            ))}
          </WrapperQr>
        </HomepageWrapper>
      </WebchatContainer>
    </ContainerScroll>
  );
};

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: ${({ fontFamilly }) => fontFamilly};
    font-style: normal;
    font-weight: 400;
    src: ${({ fontUrl, formatFont }) =>
      `url('${fontUrl}') format('${formatFont}')`};
    font-display: swap;
  };
`;

const WebchatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 320px;
  height: 568px;
  position: relative;
  border: 1px solid lightgray;
  box-shadow: ${({ boxShadow }) =>
    boxShadow ||
    '0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)'};
  background: ${({ curved, colorHeader }) =>
    curved
      ? colorHeader
      : 'linear-gradient(180deg, rgba(254,254,254,1) 0%, rgba(253,253,253,1) 20%, rgba(251,250,250,1) 50%, rgba(250,249,249,1) 75%, rgba(248,247,247,1) 100%)'};
  border-radius: ${({ borderRadius }) => borderRadius}px;
`;

const HomepageWrapper = styled.div`
  height: 100vh;
  background-color: ${({ background }) => background || '#FFF'};
  display: flex;
  flex-direction: column;
  font-family: 'avenir';
`;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTitle = styled.h1`
  margin: 0.8rem 0;
  font-size: ${({ fontSize }) => fontSize || '1em'};
  color: ${({ color }) => color};
  padding: 0 1.6rem;
`;

const StyledContent = styled.p`
  margin: 0;
  color: ${({ color }) => color};
  margin-top: 0.8rem;
  font-size: ${({ fontSize }) => `${fontSize}px` || '1.8em'};
  padding: 0 1.6rem;
`;

const StyledQr = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ fontFamilly }) => fontFamilly};
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  border: ${({ template, borderColor }) =>
    template.border || `1px solid ${borderColor}`};
  padding: 0.4rem 1rem;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  box-shadow: ${({ template }) =>
    template.border && 'rgba(120, 120, 120, 0.8) 0px 2px 2px 0px'};
  transition-duration: 0.3s;
  cursor: pointer;
  outline: none;
  margin: 0.6rem;
  text-align: center;
  width: ${({ width }) => `${width}px` || '135px'};
  min-height: 30px;
  height: ${({ height }) => `${height}px`};
  font-size: ${({ fontSize }) => `${fontSize}em` || '.9em'};
`;

const WrapperMedia = styled.div`
  display: flex;
  justify-content: center;
  max-height: 150px;

  > video {
    width: 100%;
    object-fit: cover;
  }
  > img {
    object-fit: ${({ isSvg }) => (isSvg ? 'contain' : 'cover')};
  }
`;

const WrapperQr = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-top: 0.4rem;
`;

export default PreviewHomePage;
