import React, { Component } from 'react';
import { withGlobals } from '../hoc/withGlobals';
import { _formatQuickReplies } from '../tools/format';
import Control from './Control';

class QuickReplies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWider: true,
      support: true,
    };
    this.scrollbar = React.createRef();
  }

  componentDidMount() {
    this.updateControls(this.props.context.width);
    //Check browser support scrollBy or create one
    try {
      this.scrollbar.current.scrollBy =
        this.scrollbar.current.scrollBy ||
        function (x, y) {
          this.scrollLeft = this.scrollLeft + x;
          this.scrollTop = this.scrollTop + y;
        };
      this.scrollbar.current.scrollBy(1, 0);
    } catch (err) {
      this.setState({ support: false });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateControls(nextProps.context.width);
  }

  updateControls = (width) => {
    if (width !== this.state.width) {
      let isWider = true;
      if (this.scrollbar.current.scrollWidth >= width) isWider = true;
      this.setState({
        isWider: isWider,
      });
    }
  };

  addOffset = () => {
    this.scrollbar.current.scrollBy(-150, 0);
  };

  subOffset = () => {
    this.scrollbar.current.scrollBy(150, 0);
  };

  render() {
    const {
      context: {
        conversation: { quickReplies },
      },
    } = this.props;
    const { isWider, support } = this.state;
    const components = _formatQuickReplies(quickReplies);
    return (
      <div className="relative overflow-hidden">
        <div
          className="overflow-inherit white-space-nowrap p-l-lg iecrapter"
          ref={this.scrollbar}
        >
          {components}
          {quickReplies.length > 1 && isWider && support && (
            <React.Fragment>
              <Control
                onClick={this.addOffset}
                left
                icon="fas fa-chevron-left"
              />
              <Control onClick={this.subOffset} icon="fas fa-chevron-right" />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default withGlobals(QuickReplies);
