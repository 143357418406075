export default {
  //begin: ["A chaque nouvelle session"],
  noSelection: [
    "L'utilisateur n'a aucune sélection",
    'The user has no selection',
  ],
  addToSelection: [
    "L'utilisateur ajoute un événement à la sélection",
    'The user adds an event to the selection',
  ],
  removeFromSelection: [
    "L'utilisateur retire un événement de la sélection",
    'The user removes an event from the selection',
  ],
  alreadyInSelection: [
    "L'événement est déjà dans la sélection",
    'The event is already in the selection',
  ],
  userNewSearch: [
    "L'utilisateur souhaite faire une nouvelle recherche",
    'The user wants to do a new search',
  ],
  shareSelection: ['Partager une sélection', 'Share a selection'],
  showSelection: [
    "L'utilisateur veut voir sa sélection",
    'The user wants to see his selection',
  ],
  endSelection: [
    'Après avoir montré la sélection',
    'After showing the selection',
  ],
  result: ['Message de résultat (par défault)', 'Result message (default)'],
  analytics: ["Déclancher l'analytique", 'Triggering analytics'],
  directLink: ['Créer un lien direct', 'Create a direct link'],
  copied: ['Éléments copiés', 'Copied items'],
  answer: ['Bonne réponse', 'Correct answer'],
  answers: ['Réponse :', 'Answer:'],
  wtf: [
    'Attention, les intervalles de réponse ne sont pas correctes',
    'Please note that the answer ranges are not correct',
  ],
  step: ['Si le résultat est compris entre', 'If the result is between'],
  increment: ['Résultat(s) correspondant(s):', 'Corresponding result(s):'],
  addDaliAnswer: ['Nom de la réponse', 'Name of the answer'],
  addFolder: ['Ajouter un dossier', 'Add a folder'],
  addNewAnswer: ['Ajouter une réponse', 'Add an answer'],
  addToMenu: ['Ajouter au menu principal', 'Add to main menu'],
  addToBeta: ['Ajouter en test', 'Add in test'],
  X: ['[X]', '[X]'],
  addDelay: ['Ajouter un délai', 'Add a delay'],
  addModule: ['Ajouter un module', 'Add a module'],
  addQR: ['Ajouter un bouton', 'Add a button'],
  addScenario: ['Ajouter un scénario', 'Add a scenario'],
  addResult: ['Ajouter un résultat', 'Add a result'],
  also: [
    "suite de l'annonce d'un tarif",
    'Following the announcement of a tariff',
  ],
  ask_tarif: ['Question sur le tarif', 'Question about the rate'],
  beginWC: ['Itinéraire webchat', 'Webchat itinerary'],
  botDesactivated: ['Le bot est désactivé', 'The bot is disabled'],
  botPos: ["Le bot envoie l'itinéraire", 'The bot sends the route'],
  btnTitle: ['Titre du bouton', 'Button title'],
  chooseFolder: ['Choisir un dossier', 'Choose a folder'],
  chooseOneOrManyEvents: [
    'Choisissez un ou plusieurs évènements',
    'Choose one or more events',
  ],
  chooseOneOrManyTags: [
    'Choisissez un ou plusieurs tags',
    'Choose one or more tags',
  ],
  colFolder: ['Dossier', 'Folder'],
  colModule: ['Modules', 'Modules'],
  colScenario: ['Scénario', 'Scenario'],
  contentType: ['Contenu envoyé', 'Content sent'],
  defaultFree: ['Résultat tarif gratuit', 'Result free of charge'],
  defaultPriceResult: ['Résultat tarif par défaut', 'Default rate result'],
  deleteModule: ['Supprimer ce module', 'Delete this module'],
  deleteScenario: ['Supprimer ce scénario', 'Delete this scenario'],
  direction: ['Itinéraire', 'Itinerary'],
  disabled: ['Hors Ligne', 'Off line'],
  enabled: ['En Ligne', 'Online'],
  error: ['Erreur', 'Error'],
  errorRes: [
    "En cas d'absence de réponse ou de résultat",
    'In case of no response or result',
  ],
  event: ["{Nom de l'événement}", '{Name of event}'],
  eventInfo: ["Type d'événement\nDates", 'Type of event'],
  eventName: ["Nom de l'événement", 'Name of the event'],
  findEvent: [
    "J'ai trouvé X événements pour vous:",
    'I have found X events for you:',
  ],
  folder: ['Dossier du module', 'Module folder'],
  folderName: ['Nom du dossier', 'Name of the folder'],
  free: ['Gratuit', 'Free'],
  free_w: ['Gratuité', 'Free of charge'],
  full_price: ['Annonce du plein tarif', 'Full price announcement'],
  func: ['Message par défaut :', 'Default message:'],
  grantee: ['[BÉNÉFICIAIRES]', '[RECIPIENTS]'],
  Xevent: ['[X événement(s)]', '[X event(s)]'],
  ifGrantee: [
    'Si des bénéficiaires sont renseignés',
    'If any beneficiaries are listed',
  ],
  imageDescription: ['Description de l\'image', 'Image description'],
  intro: ['Introduction', 'Introduction'],
  imtroLang: ['Introduction - Début', 'Introduction - Start'],
  lang: [
    'Au changement de langue (par défault)',
    'At language change (default)',
  ],
  linkToBlock: ['Diriger vers un autre module', 'Direct to another module'],
  linkToModule: ['Lien vers un module', 'Link to a module'],
  begin: ['Message par défaut', 'Default message'],
  linkToWebURL: ['Lien vers un site web', 'Link to a website'],
  loadingEvents: [
    'Chargement de la liste des évènements...',
    'Loading the list of events...',
  ],
  loadingTags: [
    'Chargement de la liste des tags...',
    'Loading the list of tags...',
  ],
  matchScenario: ['Scénario de jeu avec match', 'Game scenario with match'],
  modifyFolder: [
    'Modifier les informations sur le dossier',
    'Modify the information on the folder',
  ],
  modifyModule: [
    'Modifier les informations sur le module',
    'Modify the information about the module',
  ],
  modifyScenario: [
    'Modifier les informations sur le scénario',
    'Modify scenario information',
  ],
  modname: ['Nom du module', 'Name of the module'],
  module: ['Module', 'Module'],
  moduleContent: ['Contenu du module', 'Content of the module'],
  moreInfo: ["Plus d'infos", 'More info'],
  name: ['Nom du scénario', 'Name of the scenario'],
  noContent: ["Attention, il n'y a aucun contenu ici.", 'Attention'],
  noFolder: ['Aucun dossier', 'No records'],
  nonClassifiedModules: ['Modules non classés', 'Unclassified modules'],
  of: ['De', 'from'],
  opening: [
    "Annonce des horaires d'ouverture",
    'Announcement of the opening hours',
  ],
  perEvent: ['Par évènement', 'By event'],
  perTag: ['Par tag', 'By tag'],
  practInfo: ['Infos pratiques', 'Practical information'],
  prevision: ['Prévision', 'Forecast'],
  price: ['Annonce du prix', 'Announcement of the price'],
  pricing: ['Tarif', 'Rate'],
  privateScenario: ['Scénario fermé', 'Closed scenario'],
  prog: ['Programmation', 'Programming'],
  publicScenario: ['Scénario ouvert', 'Open scenario'],
  question_ticket: ['Question sur le ticket', 'Question about the ticket'],
  quizzScenario: ['Scénario de jeu avec quiz', 'Game scenario with quiz'],
  redirectionURL: ['URL de redirection', 'Redirection URL'],
  resultName: ['Nom du résultat', 'Name of the result'],
  schedule: ['Horaire', 'Schedule'],
  select_ticket: ["A la sélection d'un ticket", 'When selecting a ticket'],
  sendOpening: ['Le bot envoie les horaires', 'The bot sends the schedules'],
  sendPricing: ['Envoi du plein tarif', 'Sending the full rate'],
  share: ['Partager', 'Share'],
  shareReceive: ['Ce que verra le destinataire', 'What the recipient will see'],
  shareSend: ["Ce que verra l'expéditeur", 'What the sender will see'],
  scenarioId: ['ID du scénario', 'Scenario ID'],
  subtitle: ['Sous-titre', 'Subtitle'],
  tarif: ["Annonce d'un tarif", 'Communication of a rate'],
  ti: ["minutes d'attente pour", 'Waiting time for'],
  title: ['Titre', 'Title'],
  type: ['Type de scénario', 'Type of scenario'],
  userMsg: ["Message de l'utilisateur", 'Message from the user'],
  userPos: ["L'utilisateur envoie sa position", 'The user sends his position'],
  valuable: ['Validité', 'Validity'],
  waiting_time: ["Temps d'attente", 'Waiting time'],
  removeDelay: ['Retirer le délai', 'Remove the delay'],
  addResults: ['Ajouter des résultats', 'Add results'],
  addAnalyticsToScenario: [
    'Ajouter des trackers analytics à ce scénario',
    'Add analytics trackers to this scenario',
  ],
  addAnalyticsToModule: [
    'Ajouter des trackers analytics à ce module',
    'Add analytics trackers to this module',
  ],

  coming_soon: ["Bientôt disponible", "Coming soon"],

  continuation_in_language_section: [
    'La suite dans la section "Introduction - Choix de la langue"',
    'The continuation in the section "Introduction - Language selection"'
  ],
};
