import React from 'react';
import styled from 'styled-components';
import Reply from '../components/Reply';
import Text from '../components/Text';
import QuickReply from '../components/QuickReply';
import TextLoader from '../components/TextLoader';
import Attachment from '../components/Attachment';
import Buttons from '../components/Buttons';
import { _findType } from './isOrHas';

//import {
//  listTemplate
//} from "../_tests/.templates";

const StyledSeparator = styled.div`
  text-align: center;
  position: relative;
  line-height: 0.5;
  span {
    display: inline-block;
    position: relative;
    color: #a4a4a4;
  }
  span:before,
  span:after {
    content: '';
    background-color: lightgrey;
    position: absolute;
    height: 1px;
    margin-top: 2px;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    top: 0;
    width: 100%;
  }
  span:before {
    right: 100%;
    margin-right: 15px;
  }
  span:after {
    left: 100%;
    margin-left: 15px;
  }
`;

const diffMinutes = (dt2, dt1) => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

export const _formatToComponents = (history, w) => {
  const components = history.map(({ is_user, type, message, time }, i) => {
    const prevDate = i !== 0 ? history[i - 1].time : null;
    const diff =
      prevDate !== null && diffMinutes(new Date(time), new Date(prevDate));
    let separator = false;
    if (diff >= 30 && prevDate !== null) {
      separator = true;
    }
    const content = _formatContent(is_user, message);
    if (message.text !== undefined || message.attachment || message.mid)
      return (
        <>
          {separator && (
            <StyledSeparator>
              <span>
                {w.webchat.history_new_session}{' '}
                {new Date(time).toLocaleDateString().substr(0, 10)} {w.webchat.at}{' '}
                {new Date(time).toTimeString().substr(0, 5)}
              </span>
            </StyledSeparator>
          )}
          <Reply key={i} to={is_user}>
            {type === 'loader' && <TextLoader />}
            {content}
          </Reply>
        </>
      );
  });
  return <React.Fragment>{components}</React.Fragment>;
};

//content = content.concat(<Attachment key={key} {...listTemplate["attachment"]} />)

const _formatContent = (is_user, msg) => {
  let content = [];
  for (let key in msg) {
    switch (key) {
      case 'text':
        content = content.concat(
          <Text key={key} to={is_user} text={msg[key]} />
        );
        break;
      case 'attachment':
        content = content.concat(<Attachment key={key} {...msg[key]} />);
        break;
      case 'attachments':
        msg[key].forEach((el) => {
          content = content.concat(<Attachment key={key} {...el} />);
        });
        break;
      default:
        break;
    }
  }
  return content;
};

export const _formatQuickReplies = (quickReplies) => {
  if (!quickReplies) return [];
  const components = quickReplies.map(({ content_type, ...props }, i) => {
    if (content_type === 'text') return <QuickReply key={i} {...props} />;
    if (content_type === 'location') {
      return (
        <QuickReply key={i} title="Envoyer la position" payload="location" />
      );
    }
    return null;
  });
  return <React.Fragment>{components}</React.Fragment>;
};

export const _formatToHistory = (conv, msg, from) => {
  const newConv = Object.assign({}, conv);
  switch (from) {
    case 'user':
      newConv.history = newConv.history.concat({
        is_user: true,
        ..._formatMsg(msg),
      });
      newConv.quickReplies = [];
      newConv.mailTo = false;
      break;
    case 'loader':
      newConv.history = newConv.history.concat({
        is_user: false,
        type: 'loader',
        message: {},
      });
      break;
    case 'bot':
      newConv.history = newConv.history
        .filter((el) => el.type !== 'loader')
        .concat({ is_user: false, ...msg });
      newConv.quickReplies = msg.message.quick_replies || [];
      newConv.mailTo = msg.message.mail || false;
      break;
    default:
      break;
  }
  return newConv;
};

const _formatMsg = (msg) => {
  const text = msg.message.text;
  const type = _findType(text);
  if (type.code === type.isIMAGE)
    return {
      ...msg,
      message: { attachment: { type: 'image', payload: { url: text } } },
    };
  else if (type.code === type.isVIDEO)
    return {
      ...msg,
      message: { attachment: { type: 'video', payload: { url: text } } },
    };
  else return msg;
};

export const _formatMenu = (menu) => {
  const { call_to_actions = [] } = menu;
  const buttons = _extractNestedButtons(call_to_actions);
  return (
    <React.Fragment>
      <Buttons
        buttons={buttons}
        className="flex flex-1 flex-direction-column"
      />
    </React.Fragment>
  );
};

const _extractNestedButtons = (arr) => {
  let buttons = [];
  arr.forEach((btn) => {
    if (btn.type === 'nested')
      buttons = buttons.concat(_extractNestedButtons(btn.call_to_actions));
    else buttons = buttons.concat(btn);
  });
  return buttons;
};

export const _formatConversation = (conv = []) => {
  let quickReplies = [];
  const history = conv.map(({ is_user, content, time, ...other }) => {
    quickReplies = content.quick_replies || [];
    if (is_user)
      return _formatMsg({
        is_user,
        message: content.message || {
          text:
            content.postback.payload !== undefined
              ? typeof content.postback?.title === 'object'
                ? content.postback?.title[
                    Object.keys(content.postback.title)[0]
                  ]
                : content.postback.title
              : '',
        },
        time,
      });
    return {
      is_user,
      message: typeof content !== 'string' ? content : { text: content },
      time,
    };
  });
  return {
    history: history || [],
    quickReplies: quickReplies || [],
  };
};
