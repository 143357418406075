import gql from 'graphql-tag';
import { _mutate, _query } from './client';

class TTT {
  constructor(client) {
    this.client = client;
    this.queries = {
      GET_TYPES,
      GET_THEMES,
      GET_TAGS,
      GET_PUBLICS,
      GET_ARTWORK_TAGS,
      GET_AUTHORS,
    };
    this.mutations = {
      CREATE_THEME,
      CREATE_TAG,
      CREATE_PUBLIC,
      CREATE_AUTHOR,
      CREATE_ARTWORK_TAG,
      SET_THEME_TRANSLATION,
      SET_TAG_TRANSLATION,
      SET_PUBLIC_TRANSLATION,
      SET_AUTHOR_TRANSLATION,
    };
  }

  _mutate = async (mutation, variables, opt) => {
    return await _mutate(this.client, mutation, variables, opt);
  };

  _getQuery = (name) => {
    return this.queries[name];
  };

  _getMutation = (name) => {
    return this.mutations[name];
  };

  _getTypes = async () => {
    return await _query(this.client, GET_TYPES);
  };
}

export default TTT;

const GET_TAGS = gql`
  query getTags($language: String, $name: String, $offset: Int, $limit: Int) {
    getTags(language: $language, name: $name, offset: $offset, limit: $limit) {
      tags {
        id
        slug
        translations {
          language_id
          name
        }
      }
    }
  }
`;

const GET_TYPES = gql`
  query getTypes($name: String, $language: String, $limit: Int, $offset: Int) {
    getTypes(name: $name, language: $language, limit: $limit, offset: $offset) {
      types {
        id
        slug
        translations {
          language_id
          name
          short_name
        }
      }
    }
  }
`;

const GET_THEMES = gql`
  query getThemes(
    $language: String
    $name: String
    $bot_id: Int
    $types: [Int]
    $offset: Int
    $limit: Int
  ) {
    getThemes(
      language: $language
      name: $name
      bot_id: $bot_id
      types: $types
      offset: $offset
      limit: $limit
    ) {
      themes {
        id
        slug
        translations {
          id
          theme_id
          language_id
          name
        }
      }
    }
  }
`;

const GET_PUBLICS = gql`
  query getPublics(
    $limit: Int
    $offset: Int
    $name: String
    $language: String
  ) {
    getPublics(
      limit: $limit
      offset: $offset
      name: $name
      language: $language
    ) {
      publics {
        id
        slug
        translations {
          id
          public_id
          language_id
          name
        }
      }
    }
  }
`;

const CREATE_TAG = gql`
  mutation($slug: String!) {
    createTag(slug: $slug) {
      id
      slug
    }
  }
`;

const CREATE_THEME = gql`
  mutation($slug: String!) {
    createTheme(slug: $slug) {
      id
      slug
    }
  }
`;

const CREATE_PUBLIC = gql`
  mutation($slug: String!) {
    createPublic(slug: $slug) {
      id
      slug
    }
  }
`;

const SET_PUBLIC_TRANSLATION = gql`
  mutation($public_id: Int!, $language_id: Int!, $name: String) {
    setPublicTranslation(
      public_id: $public_id
      language_id: $language_id
      name: $name
    ) {
      id
    }
  }
`;

const SET_THEME_TRANSLATION = gql`
  mutation($theme_id: Int!, $language_id: Int!, $name: String) {
    setThemeTranslation(
      theme_id: $theme_id
      language_id: $language_id
      name: $name
    ) {
      id
    }
  }
`;

const SET_TAG_TRANSLATION = gql`
  mutation($tag_id: Int!, $language_id: Int!, $name: String) {
    setTagTranslation(tag_id: $tag_id, language_id: $language_id, name: $name) {
      id
    }
  }
`;

const GET_ARTWORK_TAGS = gql`
  query getArtworkTags($mediation_id: Int!) {
    getArtworkTags(mediation_id: $mediation_id) {
      id
      name
    }
  }
`;

const CREATE_ARTWORK_TAG = gql`
  mutation($mediation_id: Int!, $name: String!) {
    createArtworkTag(mediation_id: $mediation_id, name: $name) {
      id
      name
    }
  }
`;

const GET_AUTHORS = gql`
  query($firstname: String, $bot_id: Int) {
    getAuthors(firstname: $firstname, bot_id: $bot_id) {
      authors {
        id
        translations {
          language {
            id
            code
            name
          }
          firstname
          lastname
        }
        slug
      }
    }
  }
`;

const CREATE_AUTHOR = gql`
  mutation($slug: String!) {
    createAuthor(slug: $slug) {
      id
    }
  }
`;

const SET_AUTHOR_TRANSLATION = gql`
  mutation($author_id: Int!, $language_id: Int!, $firstname: String) {
    setAuthorTranslation(
      author_id: $author_id
      language_id: $language_id
      firstname: $firstname
    ) {
      id
    }
  }
`;
