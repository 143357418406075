import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Icon } from '@ask-mona/ui';
import ArrowRightSharpIcon from '@material-ui/icons/ArrowRightSharp';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';

export const Sub = ({ sub, active, onClick, noarrow }) => (
  <Tab onClick={onClick}>
    {sub.icon && (
      <TabIcon title={sub.title}>
        <Icon
          icon={sub.icon}
          className={active ? 'has-text-secondary' : 'has-text-primary'}
        />
      </TabIcon>
    )}
    <TabTitle active={active}>{sub.title}</TabTitle>
    {!noarrow && (
      <ArrowIcon>
        {active ? (
          <ArrowDropDownSharpIcon style={{ fontSize: 30 }} />
        ) : (
          <ArrowRightSharpIcon style={{ fontSize: 30 }} />
        )}
      </ArrowIcon>
    )}
  </Tab>
);

class Sidebar extends Component {
  state = {
    hover: false,
  };

  handleEvent = (e) => {
    switch (e.type) {
      case 'mouseenter':
        this.setState({ hover: true });
        break;
      case 'mouseleave':
        this.setState({ hover: false });
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    const height = this.divElement.clientHeight;
    this.props.setHeight && this.props.setHeight(height);
  }

  render() {
    const {
      menu,
      addons,
      c: { subID },
      history,
    } = this.props;
    return (
      <Container
        onMouseEnter={this.handleEvent}
        onMouseLeave={this.handleEvent}
        ref={(divElement) => {
          this.divElement = divElement;
        }}
      >
        {menu &&
          menu.map((m) => (
            <Sub
              key={m.id}
              sub={m}
              hover={this.state.hover}
              active={subID === m.id}
              onClick={() => history.push(m.path)}
              noarrow={this.props.noarrow}
            />
          ))}
        <AddonContainer>{addons}</AddonContainer>
      </Container>
    );
  }
}

export default withRouter(Sidebar);

const Container = styled.div`
  display: inline-block;
  padding: 0 20px;
  min-width: 160px;
  max-width: 200px;
  height: 100%;
  transition: 0.2s ease;
  @media (max-width: 1400px) {
    padding: 0 15px;
  }
  @media (max-width: 1200px) {
    padding: 0 10px;
  }
`;

const Tab = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin: 20px auto;
  margin-left: 20px;
  color: ${(p) => p.theme.colors.primary};
  font-weight: 510;
`;

const TabIcon = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  /* margin-right: ${(p) => (p.title.length > 17 ? '-5px' : '7px')}; */
  margin-left: -15px;
  /* margin-left: ${(p) => (p.title.length > 17 ? '-10px' : '-15px')}; */
`;

const TabTitle = styled.div`
  /* text-align: center; */
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: ${(p) =>
    p.active ? p.theme.colors.secondary : p.theme.colors.primary};
  border-bottom: ${(p) =>
    p.active ? `2px solid ${p.theme.colors.secondary}` : 'none'};

  @media (max-width: 1400px) {
    font-size: 0.8rem;
  }
`;

const ArrowIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
`;

const AddonContainer = styled.div`
  color: ${(p) => p.theme.colors.primary};
  height: 100%;
`;
