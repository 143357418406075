import React, { useState } from 'react';
import styled from 'styled-components';
import {
  moduleTemplate,
  _findDup,
  _generatePath,
} from '../../../components/scenario/Reducer';

import Popover, { ArrowContainer } from 'react-tiny-popover';

import { _sendFeedback } from '../../../_tools/ui';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';

import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#2D2F7B' },
    secondary: { main: '#fff' },
  },
});

const useStyles = makeStyles((theme) => ({
  whiteColor: {
    color: 'white',
  },
  icon: {
    fill: 'white',
  },
  select: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
  },
}));

export const AddResultPopover = (props) => {
  const classes = useStyles();
  const {
    m: { w },
    modules,
    allIds,
    Bot,
    focus,
    dispatch,
  } = props;

  const [name, setName] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const closePopover = () => {
    setName('');
    setIsPopoverOpen(false);
  };

  const handleSave = async () => {
    let ansId = _findDup(name, allIds);
    if (name) {
      let newAns = moduleTemplate({
        id: ansId,
        name: `Résultat: ${name}`,
        tag: 'Résultat',
        jump: focus.id,
        disable: false,
        answer: true,
      });

      let ansKey = newAns.key;
      delete newAns[ansKey];
      let k = Object.keys(focus.trigger).find((k) => k.match(/p:results/));
      let type = focus.content.func.default.daliType;

      let newFunc = {
        ...focus.content.func,
        [type]: {
          ...(focus.content.func[type] || []),
          [newAns.id]: type === 'quiz' ? [0, 0] : null,
        },
      };

      let newKFunc = {
        ...focus.trigger[k].content.func,
        [type]: {
          ...(focus.trigger[k].content.func[type] || []),
          [newAns.id]: type === 'quiz' ? [0, 0] : null,
        },
      };

      let newFocus = {
        ...focus,
        content: {
          ...focus.content,
          func: newFunc,
        },
        trigger: {
          ...focus.trigger,
          [k]: {
            ...focus.trigger[k],
            content: {
              ...focus.trigger[k].content,
              func: newKFunc,
            },
          },
          [ansKey]: newAns,
        },
      };

      let { key, ...other } = newFocus;

      _sendFeedback('warning', 'loader');
      let res = await Bot._updateModule({
        path: _generatePath(focus),
        module: { [key]: { ...other } },
      });
      if (res.success) {
        dispatch({ type: 'setFocus', payload: newFocus });
        dispatch({
          type: 'setModules',
          payload: modules.map((el) => (el.key === key ? newFocus : el)),
        });
        dispatch({
          type: 'setIds',
          payload: allIds.concat(newAns.id),
        });
        _sendFeedback('success', w.success.saved);
        closePopover();
      } else _sendFeedback('danger', w.error.general);
    }
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      position={['right', 'bottom']}
      padding={5}
      onClickOutside={closePopover}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={'#2D2F7B'}
          arrowSize={10}
          arrowStyle={{ zIndex: 10000, borderRight: '15px solid #2D2F7B' }}
        >
          <PopupContainer>
            <Header>
              <HeaderText>{w.scenario.addResult}</HeaderText>
              <ButtonContainer>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={name === ''}
                  onClick={handleSave}
                >
                  {w.generic.save}
                </Button>
              </ButtonContainer>
            </Header>
            <SectionContainer>
              <SectionTitle>{w.scenario.result}</SectionTitle>
              <SectionInput>
                <FormControl
                  variant="filled"
                  color="primary"
                  classes={{
                    root: classes.whiteColor,
                  }}
                >
                  <Input
                    classes={{
                      root: classes.whiteColor,
                      borderColor: 'white',
                    }}
                    value={name}
                    color="secondary"
                    variant="outlined"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </SectionInput>
            </SectionContainer>
          </PopupContainer>
        </ArrowContainer>
      )}
    >
      <PopoverButton
        onClick={() => {
          setIsPopoverOpen(!isPopoverOpen);
        }}
      >
        <MuiThemeProvider theme={theme}>
          <PopoverButtonIcon>
            <AddBoxRoundedIcon color="secondary" style={{ fontSize: '18px' }} />
          </PopoverButtonIcon>
          <PopoverButtonText>{w.scenario.addResult}</PopoverButtonText>
        </MuiThemeProvider>
      </PopoverButton>
    </Popover>
  );
};

const PopupContainer = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  position: relative;
  z-index: 1000;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled.span`
  margin-right: 20px;
  font-size: 1.1rem;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;

const SectionTitle = styled.div`
  margin-bottom: 10px;
`;
const SectionInput = styled.div`
  color: white;
`;

const PopoverButton = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  border-radius: 30px;
  padding: 10px 15px;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20px;
  cursor: pointer;
  border: 1px solid ${(p) => p.theme.colors.primary};
  margin: 0 10px 10px;
  width: 80%;
`;

const PopoverButtonIcon = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopoverButtonText = styled.div`
  color: white;
  font-size: 0.9rem;
  text-align: center;
`;
