import React, { Component } from 'react';
import { withGlobals } from '../../hoc/globals';
import { _breakOph, _mergeOph } from '../../_tools/oph';
import { _sendFeedback } from '../../_tools/ui';
import { Button, Icon, Field } from '@ask-mona/ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dates from './Dates';
import Blocks from './Blocks';
import TextField from '@material-ui/core/TextField';

class Part extends Component {
  constructor(props) {
    super(props);
    let hasYear = props.part.oph.match(/\d{4}/);
    this.state = {
      hovered: false,
      perm: hasYear,
      comment: props.comment
        ? props.comment.includes('special')
          ? props.comment.slice(0, -1).split(': ')[1]
          : ''
        : '',
    };
  }

  onCommentChange = () => {
    const { part } = this.props;
    const { comment, ...other } = _breakOph(part.oph);
    let changedComment = '';
    if (this.props.comment === 'general') {
      changedComment = '';
    } else if (this.props.comment === '') {
      changedComment = 'general';
    }
    this.updateComment({ comment: changedComment, ...other }, changedComment);
  };

  onBlockChange = (nBlocks) => {
    const { part } = this.props;
    const { blocks, ...other } = _breakOph(part.oph);
    this.update({ blocks: nBlocks, ...other });
  };

  onDateChange = ({ date, name }, index) => {
    const { part } = this.props;
    const { dates, ...other } = _breakOph(part.oph);
    let newDates = [];
    if (name === 'endDate')
      newDates = [
        {
          ...dates.find((d, i) => i === index),
          [name]: date ? date.format('YYYY-MM-DD') : null,
        },
      ];
    else if (name === 'period') {
      let base = { ...dates.find((d, i) => i === index) };
      date.forEach((d) => {
        if (d.name && d.date)
          base = { ...base, [d.name]: d.date.format('YYYY-MM-DD') };
      });
      newDates = [base];
    } else
      newDates = dates.map((d, i) =>
        i === index
          ? { ...d, [name]: date ? date.format('YYYY-MM-DD') : null }
          : d
      );
    this.update({ dates: newDates, ...other });
  };

  onAddDate = ({ date, name }) => {
    const { part } = this.props;
    const { dates, ...other } = _breakOph(part.oph);
    let newDates = dates.concat({ startDate: date.format('YYYY-MM-DD') });
    this.update({ dates: newDates, ...other });
  };

  onPopDate = (index) => {
    const { part } = this.props;
    const { dates, ...other } = _breakOph(part.oph);
    let newDates = dates.filter((d, i) => i !== index);
    this.update({ dates: newDates, ...other });
  };

  update = (nPart) => {
    const {
      part,
      fct: { onChange },
      comment,
      name,
    } = this.props;
    const { dates, blocks, ...other } = nPart;
    let hasUnique = dates.some((d) => d.startDate && !d.endDate);
    let nBlocks = blocks;
    if (hasUnique) nBlocks = blocks.map((b) => ({ ...b, days: [] }));
    let oph = _mergeOph(
      { ...nPart, blocks: nBlocks, ...other },
      comment,
      name === 'off' ? true : false,
      this.state.perm || this.props.event
    );
    onChange && onChange({ ...part, oph: oph });
  };

  updateComment = async (nPart, aComment) => {
    const {
      part,
      fct: { onChange },
      name,
    } = this.props;
    const { dates, blocks, ...other } = nPart;
    let hasUnique = dates.some((d) => d.startDate && !d.endDate);
    let nBlocks = blocks;
    if (hasUnique) nBlocks = blocks.map((b) => ({ ...b, days: [] }));
    let oph = _mergeOph(
      { ...nPart, blocks: nBlocks, ...other },
      aComment,
      name === 'off' ? true : false,
      this.state.perm || this.props.event
    );
    onChange && onChange({ ...part, oph: oph });
  };

  updatePerm = () => {
    const {
      part,
      fct: { onChange },
      comment,
      name,
    } = this.props;
    let OPH = _breakOph(part.oph);
    const { dates, blocks, ...other } = OPH;
    let hasUnique = dates.some((d) => d.startDate && !d.endDate);
    let nBlocks = blocks;
    if (hasUnique) nBlocks = blocks.map((b) => ({ ...b, days: [] }));
    let oph = _mergeOph(
      { ...OPH, blocks: nBlocks, ...other },
      comment,
      name === 'off' ? true : false,
      this.state.perm
    );
    onChange && onChange({ ...part, oph: oph });
  };

  render() {
    const {
      part,
      fct: { popOph },
      g: { w },
    } = this.props;
    const { addOph } = this.props;
    const { hovered } = this.state;
    const { dates, blocks } = _breakOph(part.oph);
    return (
      <div
        className={`has-background-primary-lighter rad-5 m-xs ${
          hovered ? 'has-background-danger-light' : ''
        }`}
        // Allows to dynamically change the background color if a wording has been added or not to a special part
        style={{
          backgroundColor:
            this.props.name === 'special' &&
            this.props.sectionName !== 'todo' &&
            (this.state.comment ? '#E9FAE3' : '#FFF0D7'),
        }}
      >
        {part.oph && this.props.sectionName !== 'todo' && (
          <div className="p-t-xs p-l-xs is-size-7 flex space-between">
            <FormControlLabel
              control={
                <Checkbox
                  checked={!this.state.perm}
                  onChange={() => {
                    this.setState(
                      (state) => ({ perm: !state.perm }),
                      () => {
                        this.updatePerm();
                      }
                    );
                  }}
                />
              }
              label={w.schedule.renewableLabel}
            />
            {this.props.name === 'open' && (
              <Button
                color="primary"
                className="h-100 shrink is-borderless"
                onClick={this.onCommentChange}
              >
                <Icon icon="fas fa-arrows-alt-v" />
              </Button>
            )}
          </div>
        )}
        {this.props.sectionName !== 'todo' && (
          <div className={`flex space-between`}>
            <div className="flex row grow-1">
              <Dates
                {...this.props}
                dates={dates}
                onDateChange={this.onDateChange}
                onAddDate={this.onAddDate}
                onPopDate={this.onPopDate}
              />
              <Blocks
                {...this.props}
                blocks={blocks}
                onChange={this.onBlockChange}
                daysAllowed={!(dates[0].startDate && !dates[0].endDate)}
              />
            </div>
            <div
              onMouseOver={() => this.setState({ hovered: true })}
              onMouseOut={() => this.setState({ hovered: false })}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                flex: '0',
              }}
            >
              <Button
                color="danger"
                className="h-100 shrink is-borderless"
                onClick={() => popOph(part)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <Icon icon="fas fa-trash" />
              </Button>
            </div>
          </div>
        )}

        {/* Special parts content */}
        {this.props.name === 'special' &&
          /* If todo section, only allows functionnality to put in open or close sections  */
          (this.props.sectionName === 'todo' ? (
            <div className="flex row space-between p-md items-center">
              <p>{w.schedule.toBeFiled}</p>
              <p>{part.value}</p>
              <div className="flex row">
                <Button
                  className="m-r-sm"
                  onClick={() => addOph({ oph: part.oph.split(' ')[0] })}
                >
                  {w.schedule.placeInOpen}
                </Button>
                <Button onClick={() => addOph({ oph: part.oph })}>
                  {w.schedule.placeInClose}
                </Button>
              </div>
            </div>
          ) : (
            // If open or close section, allows to add special wording to the OPH
            <div className="m-sm flex grow-1 column">
              <Field title={w.schedule.specificMessage} className="m-b-md">
                <TextField
                  multiline={true}
                  label={w.schedule.specificMessageLabel}
                  value={this.state.comment}
                  fullWidth
                  onChange={(e) =>
                    this.setState({
                      comment: e.target.value,
                    })
                  }
                />
              </Field>
              <div style={{ width: '20%' }} className="m-b-md">
                <Button
                  color="primary"
                  className="h-100 shrink"
                  onClick={() => {
                    const { comment, ...other } = _breakOph(part.oph);
                    this.updateComment(
                      { comment: `special: ${this.state.comment}`, ...other },
                      `special: ${this.state.comment}`
                    );
                    _sendFeedback(
                      'info',
                      "Le texte à été ajouté, n'oubliez pas d'enregistrer"
                    );
                  }}
                >
                  {w.schedule.updateTheText}
                </Button>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default withGlobals(Part);
