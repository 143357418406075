import React from 'react';

export default ({ small, text, ...props }) => (
  <div
    className={`
      is-round
      border
      has-background-white
      overflow-hidden
      ${small ? 'is-24x24' : 'is-48x48'}
      flex
      center
      items-center
    `}
    {...props}
  >
    {text}
  </div>
);
