import gql from 'graphql-tag';
import {
  _mutate,
  //  _query
} from './client';

class Mediation {
  constructor(client) {
    this.client = client;
  }

  _createMediation = async (variables, opt) => {
    return await _mutate(this.client, CREATE_MEDIATION, variables, opt);
  };

  _deleteMediation = async (variables, opt) => {
    return await _mutate(this.client, DELETE_MEDIATION, variables, opt);
  };

  _updateMediation = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_MEDIATION, variables, opt);
  };
}

export default Mediation;

const CREATE_MEDIATION = gql`
  mutation(
    $bot_id: Int!
    $date_start: String
    $date_end: String
    $name: String!
    $description: String
    $sub_place_id: Int
  ) {
    createMediation(
      bot_id: $bot_id
      date_start: $date_start
      date_end: $date_end
      name: $name
      description: $description
      sub_place_id: $sub_place_id
    ) {
      id
      date_start
      date_end
      name
      description
    }
  }
`;

export const UPDATE_MEDIATION = gql`
  mutation(
    $id: Int!
    $date_start: String
    $date_end: String
    $name: String
    $published: Boolean
    $description: String
    $sub_place_id: Int
  ) {
    updateMediation(
      id: $id
      date_start: $date_start
      date_end: $date_end
      published: $published
      name: $name
      description: $description
      sub_place_id: $sub_place_id
    ) {
      id
      date_start
      date_end
      published
      name
      description
    }
  }
`;

export const GET_MEDIATION = gql`
  query getMediation($id: Int!) {
    getMediation(id: $id) {
      id
      name
      artworks {
        id
        cover_image
        translations {
          language {
            id
            code
          }
          name
        }
      }
    }
  }
`;

export const GET_MEDIATIONS = gql`
  query getMediations($bot_id: Int!) {
    getMediations(bot_id: $bot_id) {
      id
      date_start
      date_end
      published
      name
      sub_place {
        name
      }
    }
  }
`;

export const DELETE_MEDIATION = gql`
  mutation($id: Int!) {
    deleteMediation(id: $id)
  }
`;
