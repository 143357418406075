import React, { useReducer } from 'react';
import styled from 'styled-components';
import { withMain } from '../hoc/main';
import { Query } from 'react-apollo';
import MedApi, { GET_MEDIATION } from '../_api/mediation';
import Foundations from '../components/base/Foundations';
import Sidebar, { Sub } from '../components/base/Sidebar';
import Header from '../components/base/Header';
import ErrorBoundary from '../components/ErrorBoundary';
import Portal from '../components/Portal';
import Loader from '../components/Loader';
import { Icon } from '@ask-mona/ui';
import { mediation as sections, _sendFeedback } from '../_tools/ui';
import Artworks from './mediation/Artworks';
import Scenario from './mediation/Scenario';
import Advanced from './mediation/Advanced';

import MUIButton from '@material-ui/core/Button';
import SearchBar from '../components/SearchBar';

import { _reducer, initialState } from '../components/scenario/Reducer';

import { LangSwitcher } from '../components/scenario/header/LangSwitcher';
import { SaveButton } from '../components/scenario/header/SaveButton';
import { EditPopover } from './scenarios/header/EditPopover';

const _mediationReducer = (state, action) => {
  switch (action.type) {
    case 'setSub':
      return { ...state, subID: action.payload };
    case 'onChange':
      const { name, value } = action.payload;
      return { ...state, [name]: value };
    case 'setInfo':
      return { ...state, getInfo: action.payload };
    default:
      return { ...state };
  }
};

const Mediation = (props) => {
  const [state, dispatch] = useReducer(_mediationReducer, {
    api: new MedApi(props.m.client),
    subID: 0,
    getInfo: false,
    search: '',
  });

  const [scenarioState, scenarioDispatch] = useReducer(_reducer, initialState);

  const {
    m: { w },
  } = props;
  const medId = props.match.params.id;
  return (
    <Foundations
      aside={
        <Sidebar
          c={state}
          addons={sections.map((s) => (
            <Sub
              noarrow={true}
              key={s.id}
              sub={{ ...s, title: w.subsections[s.w] }}
              active={state.subID === s.id || (s.id === 1 && state.subID === 2)}
              onClick={() => dispatch({ type: 'setSub', payload: s.id })}
            />
          ))}
        />
      }
      header={
        <Header
          title={
            state.subID === 0 || state.subID === 1
              ? `${w.med.mediation}`
              : scenarioState.focus && scenarioState.focus.name
          }
          addons={
            state.subID === 0 || state.subID === 1
              ? null
              : [
                  <LangSwitcher
                    {...scenarioState}
                    {...props}
                    dispatch={scenarioDispatch}
                  />,
                  <SaveButton
                    {...scenarioState}
                    {...props}
                    dispatch={scenarioDispatch}
                  />,
                ]
          }
          titleaddon={[
            <EditPopover
              {...scenarioState}
              {...props}
              dispatch={scenarioDispatch}
              toggleUpdate={() => {}}
              advanced={true}
            />,
          ]}
        />
      }
    >
      {(state.subID === 0 || state.subID === 1) && (
        <Portal
          component={
            <Head {...props} {...state} dispatch={dispatch} medId={medId} />
          }
          selector="base-header"
        />
      )}
      <ErrorBoundary>
        <Gate
          {...props}
          {...state}
          scenarioState={scenarioState}
          scenarioDispatch={scenarioDispatch}
          medId={medId}
          dispatch={dispatch}
        />
      </ErrorBoundary>
    </Foundations>
  );
};

const Gate = (props) => {
  const { subID, medId, scenarioState, scenarioDispatch } = props;
  return (
    <Query query={GET_MEDIATION} variables={{ id: Number(medId) }}>
      {({ loading, error, data, ...other }) => {
        if (loading) return <Loader className="has-text-primary-ter" />;
        if (error) throw new Error(error);
        if (subID === 0)
          return (
            <ErrorBoundary>
              <Artworks {...props} data={data} other={other} />
            </ErrorBoundary>
          );
        if (subID === 1)
          return (
            <ErrorBoundary>
              <Scenario {...props} data={data} other={other} />
            </ErrorBoundary>
          );
        if (subID === 2)
          return (
            <ErrorBoundary>
              <Advanced
                {...props}
                subID={1}
                force={`mediation${medId}`}
                state={scenarioState}
                dispatch={scenarioDispatch}
              />
            </ErrorBoundary>
          );
        if (subID === 3)
          return (
            <ErrorBoundary>
              <Advanced
                {...props}
                subID={1}
                force={`mediation${medId}`}
                state={scenarioState}
                dispatch={scenarioDispatch}
                subforce={`leave`}
              />
            </ErrorBoundary>
          );
        return null;
      }}
    </Query>
  );
};

const Head = ({ m: { w }, api, history, medId, getInfo, subID, dispatch }) => (
  <div className="m-l-md flex row grow-1 space-between">
    <SearchBarContainer>
      {subID === 0 && (
        <SearchBar
          name="search"
          onChange={(e) => dispatch({ type: 'onChange', payload: e.target })}
          placeholder={w.generic.search}
        />
      )}
    </SearchBarContainer>
    <div className="flex row">
      <ButtonsContainer>
        <ButtonContainer>
          <MUIButton
            variant="contained"
            color="secondary"
            onClick={() => history.goBack()}
          >
            {w.generic.return}
          </MUIButton>
        </ButtonContainer>
        <Spacing />
        <ButtonContainer>
          <MUIButton
            // className="m-l-xs"
            color="primary"
            variant="contained"
            onClick={async () => {
              _sendFeedback('warning', 'loader');
              const res = await api._updateMediation({
                id: medId,
                published: true,
              });
              if (res.success) _sendFeedback('success', w.success.saved);
              else _sendFeedback('danger', w.error.general);
            }}
          >
            {w.med.publish}
          </MUIButton>
        </ButtonContainer>
        <MUIButton
          className="shrink is-outlined m-l-xs"
          onClick={() => dispatch({ type: 'setInfo', payload: !getInfo })}
        >
          <Icon className="has-text-primary" icon="fas fa-exclamation-circle" />
        </MUIButton>
      </ButtonsContainer>
    </div>
  </div>
);

export default withMain(Mediation);

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
`;

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

const Spacing = styled.div`
  border: none;
  background-color: none;
  display: flex;
  width: 10px;
`;
