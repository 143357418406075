import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { _sendFeedback } from '../../../_tools/ui';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { _generatePath } from '../../../components/scenario/Reducer';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#fff' },
    secondary: { main: '#E66E6E' },
  },
});

export const EditSubModulePopover = (props) => {
  const {
    m: { w, bot_lang },
    focus,
    Bot,
    dispatch,
    modules,
    isFocused,
    subModules,
    sub,
    isClose,
  } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [name, setName] = useState('');
  const [analytics, setAnalytics] = useState(false);

  useEffect(() => {
    const { content, id } = sub;
    if (sub) {
      setName(sub.name);
      if (content) {
        setAnalytics(
            bot_lang.some((el) => {
              const formattedLang = el === "fr" ? "default" : el;
              if (Object.keys(content).includes(formattedLang)) {
                if (formattedLang === 'default') {
                  if (content[formattedLang].includes(`LOGIC_ANALYTICS(${id})`)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  if (content[formattedLang].includes(`LOGIC_ANALYTICS(${id}_${formattedLang})`)) {
                    return true;
                  } else {
                    return false;
                  }
                }
              } else {
                return false;
              }
            })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    const { key, id, content } = sub;
    let newContent = { ...content };

    if (analytics) {
      bot_lang.map((el) => {
        const formattedLang = el === "fr" ? "default" : el;
        if (Object.keys(newContent).includes(formattedLang)) {
          if (formattedLang === 'default') {
            if (!newContent[formattedLang].includes(`LOGIC_ANALYTICS(${id})`)) {
              newContent = {
                ...newContent,
                [formattedLang]: [`LOGIC_ANALYTICS(${id})`, ...newContent[formattedLang]],
              };
            }
          } else {
            if (!newContent[formattedLang].includes(`LOGIC_ANALYTICS(${id}_${formattedLang})`)) {
              newContent = {
                ...newContent,
                [formattedLang]: [`LOGIC_ANALYTICS(${id}_${formattedLang})`, ...newContent[formattedLang]],
              };
            }
          }
        }
        return newContent;
      });
    } else {
      bot_lang.map((el) => {
        const formattedLang = el === "fr" ? "default" : el;
        if (Object.keys(newContent).includes(formattedLang)) {
          if (formattedLang === 'default') {
            if (newContent[formattedLang].includes(`LOGIC_ANALYTICS(${id})`)) {
              newContent = {
                ...newContent,
                [formattedLang]: [...newContent[formattedLang]].filter(
                  (i) => i !== `LOGIC_ANALYTICS(${id})`
                ),
              };
            }
          } else {
            if (newContent[formattedLang].includes(`LOGIC_ANALYTICS(${id}_${formattedLang})`)) {
              newContent = {
                ...newContent,
                [formattedLang]: [...newContent[formattedLang]].filter(
                  (i) => i !== `LOGIC_ANALYTICS(${id}_${formattedLang})`
                ),
              };
            }
          }
        }
        return newContent;
      });
    }
    let res = await Bot._updateModule({
      path: isClose
        ? _generatePath(focus).concat(['trigger', key])
        : ['module', 'trigger', key],
      module: {
        [key]:{
          id: sub.id, 
          custom: sub.custom, 
          disable: sub.disable,
          jump: sub.jump, 
          tag: sub.tag,
          name: name, 
          content: newContent 
        }
      },
    });
    if (res.success) {
      if (isClose) {
        let newFocus = {
          ...focus,
          trigger: {
            ...focus.trigger,
            [key]: {
              ...focus.trigger[key],
              name: name,
              content: newContent,
            },
          },
        };
        dispatch({ type: 'setFocus', payload: newFocus });
        dispatch({
          type: 'setModules',
          payload: modules.map((m) => (m.key === focus.key ? newFocus : m)),
        });
        dispatch({
          type: 'setSubFocus',
          payload: { ...sub, name: name, content: newContent },
        });
        _sendFeedback('success', w.success.saved);
      } else {
        _sendFeedback('success', w.success.saved);
        dispatch({
          type: 'setSubModules',
          payload: subModules.map((m) =>
            m.key === key ? { ...m, name: name, content: newContent } : m
          ),
        });
      }
    } else {
      setName(sub.name);
      _sendFeedback('danger', w.error.general);
    }

    setIsPopoverOpen(false);
  };

  const handleDelete = async () => {
    if (isClose) {
      const { key } = sub;
      let newFocus = { ...focus };
      let res = await Bot._deleteModule({
        path: _generatePath(focus).concat(['trigger', sub.key]),
      });
      if (sub.answer) {
        let type = focus.content.func.default.daliType;
        let k = Object.keys(focus.trigger).find((k) => k.match(/p:results/));
        let newType = { ...(focus.content.func[type] || []) };
        delete newType[sub.id];
        res = await Bot._updateModule({
          path: _generatePath(focus).concat(['content', 'func', type]),
          module: { [type]: newType },
        });
        res = await Bot._updateModule({
          path: _generatePath(focus).concat([
            'trigger',
            k,
            'content',
            'func',
            type,
          ]),
          module: { [type]: newType },
        });
        newFocus = {
          ...focus,
          content: {
            ...focus.content,
            func: {
              ...focus.content.func,
              [type]: newType,
            },
          },
          trigger: {
            ...focus.trigger,
            [k]: {
              ...focus.trigger[k],
              content: {
                ...focus.trigger[k].content,
                func: {
                  ...focus.trigger[k].content.func,
                  [type]: newType,
                },
              },
            },
          },
        };
      }
      if (res.success) {
        delete newFocus['trigger'][key];
        dispatch({ type: 'setFocus', payload: newFocus });
        if (props.subforce) {
          let reg = new RegExp(props.subforce);
          let subModule = Object.keys(newFocus.trigger).find((m) =>
            newFocus.trigger[m].id.match(reg)
          );
          if (subModule)
            dispatch({
              type: 'setSubFocus',
              payload: {
                key: subModule,
                ...newFocus.trigger[subModule],
              },
            });
        }
        _sendFeedback('success', w.success.saved);
      } else _sendFeedback('danger', w.error.general);
    } else {
      _sendFeedback('warning', 'loader');
      let res = await Bot._deleteModule({
        path: ['module', 'trigger', sub.key],
      });
      if (res.success) {
        dispatch({ type: 'setFocus', payload: focus });
        dispatch({
          type: 'setSubModules',
          payload: subModules.filter((m) => m.key !== sub.key),
        });
        _sendFeedback('success', w.success.deleted);
      } else _sendFeedback('danger', w.error.general);
    }
  };

  return focus && focus.key !== 'first' && focus.key !== 'fallback' ? (
    <Popover
      isOpen={isPopoverOpen}
      position={['right', 'bottom']}
      padding={5}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={'#2D2F7B'}
          arrowSize={10}
          arrowStyle={{ zIndex: 10000, borderRight: '15px solid #2D2F7B' }}
        >
          {' '}
          <Container>
            <Header>
              <HeaderText>{w.scenario.modifyModule}</HeaderText>
              <MuiThemeProvider theme={theme}>
                <ButtonContainer>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleSave}
                  >
                    {w.generic.save}
                  </Button>
                </ButtonContainer>
              </MuiThemeProvider>
            </Header>
            <LineContainer>
              <LineTitle>{w.scenario.name}</LineTitle>
              <NameInput>
                <CustomInput
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </NameInput>
            </LineContainer>
            <MuiThemeProvider theme={theme}>
              <OptionsContainer>
                <Option>
                  <Switch
                    color="primary"
                    checked={analytics}
                    onChange={() => setAnalytics(!analytics)}
                  />
                  <TextContainer>
                    {w.scenario.addAnalyticsToModule}
                  </TextContainer>
                </Option>
              </OptionsContainer>
            </MuiThemeProvider>
            {sub && !sub.undeletable && (
              <LineContainer>
                <DeleteButton onClick={handleDelete}>
                  <DeleteButtonIcon>
                    <DeleteRoundedIcon
                      color="primary"
                      style={{ fontSize: '18px' }}
                    />
                  </DeleteButtonIcon>
                  <DeleteButtonText>{w.scenario.deleteModule}</DeleteButtonText>
                </DeleteButton>
              </LineContainer>
            )}
          </Container>
        </ArrowContainer>
      )}
    >
      <IconContainer module={true}>
        <EditRoundedIcon
          color={isFocused ? 'secondary' : 'primary'}
          style={{ fontSize: '12px' }}
          onClick={() => {
            setIsPopoverOpen(!isPopoverOpen);
          }}
        />
      </IconContainer>
    </Popover>
  ) : null;
};

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 30px;
  margin-left: 3px;
`;

const DeleteButton = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: #ececec;
  }
`;

const DeleteButtonIcon = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 3px;
`;

const DeleteButtonText = styled.div`
  color: ${(p) => p.theme.colors.primary};
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const CustomInput = styled.input`
  background-color: ${(p) => p.theme.colors.primary};
  color: white;
  border: 1px solid white;
  border-radius: 2px;
  height: 40px;
  width: 250px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  &:focus {
    border-color: white;
    outline-color: white;
  }
`;

const IconContainer = styled.div`
  margin-left: ${(p) => (p.module ? '3px' : '10px')};
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  position: relative;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1000 !important;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled.span`
  margin-right: 20px;
  font-size: 1.1rem;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;

const LineTitle = styled.div`
  margin-bottom: 10px;
`;

const NameInput = styled.div``;
