import React from 'react';
import { withGlobals } from '../../../hoc/globals';
import { withApi } from '../../../hoc/api';
import CheckList from '../../../components/CheckList';
import Filter from '../../../components/base/Filter';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

const Types = ({ g: { w, lang }, api: { ttt }, onChange, types }) => {
  const query = ttt._getQuery('GET_TYPES');
  return (
    <Filter icon="fas fa-list-ol" label={w.events.categorie}>
      <FormControl component="fieldset">
        <FormGroup>
          <CheckList
            name="types"
            query={query}
            variables={{ language: lang }}
            onChange={onChange}
            selected={types}
          />
        </FormGroup>
      </FormControl>
    </Filter>
  );
};

export default withGlobals(withApi(Types));
