import React, { useState } from 'react';
import moment from 'moment';
import useHover from '../../hooks/useHover';
import SingleDateRange from '../SingleDateRange';
import { Button } from '@ask-mona/ui';

export default ({ g: { w }, dates, ...other }) => {
  const { startDate, endDate, hasYear } = dates;
  const start = startDate
    ? moment(startDate).format(hasYear ? 'Do MMMM YYYY' : 'Do MMMM')
    : '';
  const end = endDate
    ? moment(endDate).format(hasYear ? 'Do MMMM YYYY' : 'Do MMMM')
    : '';
  const [fixed, setFixed] = useState(false);
  const [hide, setHide] = useState(false);

  const element = (hovered) => (
    <div>
      {start !== 'Invalid date' &&
        start &&
        `${end ? w.generic.from : w.generic.the} ${start}${
          end ? '\n' + w.generic.to + ' ' : ''
        }${end}`}
      {!startDate && !endDate && (
        <Button
          className="is-outlined is-borderless"
          onClick={() => {
            setFixed(true);
            setHide(false);
          }}
        >
          <span className="is-underlined is-size-7">{w.schedule.addDate}</span>
        </Button>
      )}
      {(hovered || fixed) && !hide && (
        <div
          className="border-primary-ter animation-fadeInFast absolute has-background-white p-xs arrow-primary-ter rad-5"
          style={{ zIndex: 1 }}
        >
          <SingleDateRange
            {...dates}
            {...other}
            onFocus={(bool) => setFixed(bool)}
            setFixed={setFixed}
            setHide={setHide}
          />
        </div>
      )}
    </div>
  );

  const [hoverable] = useHover(element);

  return (
    <div
      className="p-md flex column basis-0 grow-1 space-pre"
      onClick={() => setHide(false)}
    >
      {hoverable}
    </div>
  );
};
