import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import StyledInput from '../../../../components/parameters/StyledInput';
import { _uploadImg } from '../../../../_api/upload';
import {
  IconOption,
  listIconMenu,
  IconMenu,
} from '../webchat/templates/IconMenu';
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { Pencil } from '@styled-icons/foundation/Pencil';
import { Trash } from '@styled-icons/boxicons-regular/Trash';
import { Upload } from '@styled-icons/bootstrap/Upload';
import LanguagesDropdown from "../../../../components/LanguagesDropdown";
import { _sendFeedback } from "../../../../_tools/ui";

const CreateMenu = ({ m, state, dispatch, w, botId }) => {
  const [items, setItems] = useState([false, false, false]);
  const [validateItem, setValidateItem] = useState([false, false, false]);
  const [switchLang, setSwitchLang] = useState(m.bot.default_language);

  useEffect(() => {
    let menuItems = [...state.menuItems];
    let activeItems = [...items];

    const recurse = (x) => {
      if (x === 3) {
        return;
      } else {
        menuItems.push({ index: x + 1 });
        recurse(x + 1);
      }
    };

    if (state.menuItems.length < 3) {
      recurse(state.menuItems.length);
      dispatch({
        type: 'setMenuItems',
        payload: menuItems,
      });
    }

    state.menuItems.map((el, i) => {
      if (
        Object.keys(el).length !== 0 &&
        el.constructor === Object &&
        el.title
      ) {
        activeItems[i] = true;
        setItems(activeItems);
        menuItems[i].index = i;
        !menuItems[i].index &&
          dispatch({
            type: 'setMenuItems',
            payload: menuItems,
          });
      }
    });
  }, []);

  return state.menuItems.map((el, i) => (
    <WrapperDiv mr={'.4rem'} key={i}>
      {!items[i] && (
        <div>
          Ajouter un item
          <StyledPlusCircle
            size={26}
            onClick={() => {
              let arrItems = [...items];
              let arrValidate = [...validateItem];
              let menuItems = [...state.menuItems];
              if (!menuItems[i])
                menuItems[i] = { index: state.menuItems.length + 1 };
              arrItems[i] = true;
              arrValidate[i] = true;
              setItems(arrItems);
              setValidateItem(arrValidate);
              dispatch({
                type: 'setMenuItems',
                payload: menuItems,
              });
            }}
          />
        </div>
      )}
      {Object.keys(el).length !== 0 &&
        el.constructor === Object &&
        !validateItem[i] &&
        items[i] && (
          <WrapperDiv space={'space-between'} width={'100%'} mr={'.4rem'}>
            <StyledDivWrapper>
              <h4>{w.menu_creation_item_title}</h4>
              <div>{el.title?.fr}</div>
            </StyledDivWrapper>
            <StyledDivWrapper>
              <h4>{w.menu_creation_link_to_module}</h4>
              <div>{el?.payload}</div>
            </StyledDivWrapper>
            <StyledDivWrapper alignItem={'center'}>
              <h4>{w.menu_creation_icon_preview}</h4>
              {el.img ? (
                <img src={el.img} width="32" />
              ) : (
                <IconMenu icon={el?.icon} />
              )}
            </StyledDivWrapper>
            <StyledDivWrapper>
              <StyledPencil
                size={24}
                onClick={() => {
                  let arrValidate = [...validateItem];
                  arrValidate[i] = true;
                  setValidateItem(arrValidate);
                }}
              />
              <StyledTrash
                size={24}
                onClick={() => {
                  let menuItems = [...state.menuItems];
                  menuItems[i] = {};
                  dispatch({
                    type: 'setMenuItems',
                    payload: menuItems,
                  });
                  let arrItems = [...items];
                  arrItems[i] = false;
                  setItems(arrItems);
                }}
              />
            </StyledDivWrapper>
          </WrapperDiv>
        )}
      {items[i] && validateItem[i] && (
        <StyledItems alignItem={'flex-end'}>
          <WrapperDiv mr={'.4rem'}>
            <h4>{w.menu_creation_item_title}</h4>
            <WrapperInput>
              <StyledInput
                type="text"
                maxLength="37"
                value={
                  el.title && el.title[switchLang] ? el.title[switchLang] : ''
                }
                placeholder={
                  el.title && el.title[switchLang] ? el.title[switchLang] : ''
                }
                onChange={(e) => {
                  let menuItems = [...state.menuItems];
                  menuItems[i] = {
                    ...menuItems[i],
                    title: {
                      ...menuItems[i].title,
                      [switchLang]: e.target.value,
                    },
                  };
                  dispatch({
                    type: 'setMenuItems',
                    payload: menuItems,
                  });
                }}
              />
              <span>
                <LanguagesDropdown
                  languages={m.bot_lang}
                  selectedLanguage={switchLang}
                  onLanguageSelected={(language) => setSwitchLang(language)}
                />
              </span>

            </WrapperInput>
          </WrapperDiv>
          <WrapperDiv mr={'.4rem'}>
            <h4>{w.menu_creation_link_to_module}</h4>
            <StyledWidth>
              <Select
                options={state.modulesScenario}
                isSearchable="true"
                placeholder={el?.payload}
                onChange={(e) => {
                  let menuItems = [...state.menuItems];
                  menuItems[i] = {
                    ...menuItems[i],
                    payload: e.value,
                    type: 'postback',
                  };
                  dispatch({
                    type: 'setMenuItems',
                    payload: menuItems,
                  });
                }}
              />
            </StyledWidth>
          </WrapperDiv>
          <WrapperDiv mr={'.4rem'}>
            {el.img ? (
              <>
                <h4>{w.menu_creation_image_preview}</h4>
                <img src={el.img} width="32" />
              </>
            ) : (
              <>
                <h4>{w.menu_creation_icon_select}</h4>
                <StyledWidth>
                  <Select
                    options={listIconMenu}
                    components={{ Option: IconOption }}
                    placeholder={IconMenu({
                      icon: el?.icon,
                    })}
                    isSearchable="false"
                    onChange={(val) => {
                      let menuItems = [...state.menuItems];
                      menuItems[i] = {
                        ...menuItems[i],
                        icon: val,
                      };
                      dispatch({
                        type: 'setMenuItems',
                        payload: menuItems,
                      });
                    }}
                  />
                </StyledWidth>
              </>
            )}
          </WrapperDiv>
          <ButtonWrapper>
            <span>
              <Upload size={14} />
            </span>
            <InputUpload
              type="file"
              onChange={async (e) => {
                dispatch({ type: 'setLoadUpload', payload: true });
                await _uploadImg(
                  botId,
                  e,
                  (res) => {
                    dispatch({ type: 'setLoadUpload', payload: false });
                    let menuItems = [...state.menuItems];
                    menuItems[i] = {
                      ...menuItems[i],
                      icon: '',
                      img: res.path,
                    };
                    dispatch({
                      type: 'setMenuItems',
                      payload: menuItems,
                    });

                    _sendFeedback("success", m.w.success.saved);
                  },
                  (err) => {
                    dispatch({ type: 'setLoadUpload', payload: false });
                  }
                );
              }}
            />
          </ButtonWrapper>
          <WrapperDiv alignItem={'center'} mr={'.4rem'}>
            <StyledCheckCircle
              size={26}
              onClick={() => {
                let arrValidate = [...validateItem];
                arrValidate[i] = false;
                setValidateItem(arrValidate);
              }}
            />
          </WrapperDiv>
        </StyledItems>
      )}
    </WrapperDiv>
  ));
};

const WrapperDiv = styled.div`
  display: flex;
  align-items: ${({ alignItem }) => alignItem || 'flex-start'};
  margin: ${({ mr }) => (mr ? mr : '2rem 0')};
  flex-wrap: wrap;
  justify-content: ${({ space }) => space};
  width: ${({ width }) => width};
`;

const StyledDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItem }) => alignItem || 'flex-start'};
  margin-right: ${({ mr }) => mr};
  width: ${({ width }) => width};
`;

const StyledItems = styled.div`
  display: flex;
  align-items: ${({ alignItem }) => alignItem};
`;

const StyledWidth = styled.div`
  width: 100%;
`;

const StyledPencil = styled(Pencil)`
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.6;
  }
`;

const StyledCheckCircle = styled(CheckCircle)`
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.6;
  }
`;

const StyledPlusCircle = styled(PlusCircle)`
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.6;
  }
`;

const StyledTrash = styled(Trash)`
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: red;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: 40px;
  height: 44px;
  text-align: center;
  margin-top: 1rem;
  transition: 0.3s;
  cursor: pointer;
  transition: opacity 0.3s;

  > span {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.white};
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 12px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const InputUpload = styled.input`
  display: inline-block;
  position: absolute;
  z-index: 1000000;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const WrapperInput = styled.div`
  position: relative;

  > span {
    position: absolute;
    top: 10px;
    right: 0px;
    cursor: pointer;
  }

  > input {
    padding-right: 50px;
  }
`;

export default CreateMenu;
