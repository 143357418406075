import React from 'react';
import { Icon } from '@ask-mona/ui';

export const Header = ({ title, subtitle, addons }) => (
  <div
    id="current-header"
    className={`flex row ${title || subtitle || addons ? 'p-md' : ''}`}
  >
    <div className="flex center items-center">
      <span className="has-text-primary-ter">
        {title}
        {subtitle && <Icon icon="fas fa-caret-right" />}
      </span>
      {subtitle && <span className="has-text-primary">{subtitle}</span>}
    </div>
    {addons}
  </div>
);
