import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import Modal from '../../components/Modal';
import Event, { GET_EVENTS } from '../../_api/event';
import { withMain } from '../../hoc/main';
import { _getKeyValue } from '../../_tools/other';
import Portal from '../../components/Portal';
import Loader from '../../components/Loader';
import Tutorial from '../../components/Tutorial';
import { _sendFeedback } from '../../_tools/ui';
import { Header, Heading, Footer, Delete } from './list/Ui';
import Events from './list/Events';

class List extends Component {
  constructor(props) {
    super(props);
    this.Event = null;
    this.state = {
      getInfo: false,
      events: [],
      event: null,
      action: null,
      fct: {
        onAddEvent: this.onAddEvent,
        onGetInfo: this.onGetInfo,
        onListEvent: this.onListEvent,
        ppEvents: this.ppEvents,
        onSelect: this.onSelect,
        onConfirm: this.onConfirm,
      },
    };
  }

  componentDidMount() {
    const {
      m: { client },
    } = this.props;
    this.Event = new Event(client);
  }

  ppEvents = (id) => {
    let events = this.state.events || [];
    if (id < 0) {
      if (events.length === 30) events = [];
      else {
        let list = _getKeyValue(this.props.data, 'events') || [];
        events = list.map((o) => o.id);
      }
    } else {
      if (this.state.events.find((m) => m === id))
        events = this.state.events.filter((n) => n !== id);
      else events = this.state.events.concat(id);
    }
    this.setState({ events });
  };

  onGetInfo = () => this.setState((state) => ({ getInfo: !state.getInfo }));

  onSelect = (item) => {
    this.setState({ action: item.id });
  };

  onAddEvent = () => {
    this.props.editEvent(-42);
  };

  onConfirm = async () => {
    const {
      m: { w },
    } = this.props;
    const { events, action } = this.state;
    if (events.length && action !== null) {
      _sendFeedback('warning', 'loader');
      try {
        events.forEach(async (id) => {
          let res = null;
          if (action === 0)
            res = await this.Event._updateEvent(
              { id: id, published: true },
              { refetchQueries: ['getEvents'] }
            );
          else if (action === 1)
            res = await this.Event._deleteEvent(
              { id: id },
              { refetchQueries: ['getEvents'] }
            );
          if (res && !res.success) throw new Error(res.error);
        });
        _sendFeedback('success', w.success.saved);
        this.setState({ action: null, events: [] });
      } catch (err) {
        console.log(err);
        _sendFeedback('danger', w.error.general);
      }
    }
  };

  onListEvent = async (e, type) => {
    const {
      m: { w },
    } = this.props;
    switch (type) {
      case 'toggle':
        _sendFeedback('warning', 'loader');
        const res = await this.Event._updateEvent(
          { id: e.id, published: !e.published },
          { refetchQueries: ['getEvents'] }
        );
        if (res.success) _sendFeedback('success', w.success.saved);
        else _sendFeedback('danger', w.error.general);
        break;
      case 'delete':
        this.setState({ event: e });
        break;
      case 'pp':
        this.ppEvents(e.id);
        break;
      case 'edit':
        this.props.editEvent(e.id);
        break;
      default:
        break;
    }
  };

  deleteEvent = async () => {
    const {
      m: { w },
    } = this.props;
    const { event } = this.state;
    _sendFeedback('warning', 'loader');
    let res = await this.Event._deleteEvent(
      { id: event.id },
      { refetchQueries: ['getEvents'] }
    );
    if (res.success) _sendFeedback('success', w.success.deleted);
    else _sendFeedback('danger', w.error.general);
    this.setState({ event: null });
  };

  render() {
    const {
      m: { w },
    } = this.props;
    if (this.props.data.error) throw new Error(this.props.data.error);
    let total = _getKeyValue(this.props.data.getEvents, 'total') || 0;
    return (
      <div className="has-text-primary w-100p overflow-scroll relative">
        <Portal
          component={
            <Header
              {...this.props}
              {...this.state}
              counter={total}
              onChange={this.props.setTitle}
            />
          }
          selector="base-header"
        />
        {this.props.data.loading && (
          <Loader className="has-text-primary overlay" />
        )}
        {this.props.data.getEvents && (
          <Heading {...this.props} {...this.state} />
        )}
        {this.props.data.getEvents && (
          <Events {...this.props} {...this.state} />
        )}
        {this.props.data.getEvents && (
          <Footer {...this.props} {...this.state} />
        )}
        <Modal
          isOpen={this.state.getInfo}
          close={() => this.onGetInfo()}
          selector="#root"
        >
          <Tutorial tuto={w.tutorial.events} close={() => this.onGetInfo()} />
        </Modal>
        <Modal
          isOpen={this.state.event}
          close={() => this.setState({ event: null })}
          selector="#root"
        >
          <Delete
            {...this.props}
            {...this.state}
            onCancel={() => this.setState({ event: null })}
            onConfirm={this.deleteEvent}
          />
        </Modal>
      </div>
    );
  }
}

const ListWithMain = withMain(List);

const withEvents = graphql(GET_EVENTS, {
  options: ({ setTitle, setOffset, editEvent, ...props }) => {
    const variables = Object.assign({}, props);
    // PUBLISHED
    if (variables.published === 3 || !variables.published)
      delete variables['published'];
    else variables.published = variables.published === 1;
    // LANGUAGE
    if (!variables.language || variables.language === 3)
      delete variables['language'];
    else variables.language = variables.language === 1 ? 'fr' : 'en';
    // PLACE_ID
    if (variables.selectedPlaceId) variables['place_id'] = [variables.selectedPlaceId];
    delete variables.selectedPlaceId
    // DATE_INTERVAL
    if (variables.date_interval.length === 0) delete variables['date_interval'];
    // THEMES
    if (variables.themes.length === 0) delete variables['themes'];
    // TYPE
    if (variables.types.length === 0) delete variables['types'];
    // TITLE
    if (!variables.title) delete variables['title'];

    return {
      variables: variables,
    };
  },
});

export default compose(withEvents)(ListWithMain);
