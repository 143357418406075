import React from 'react';

export default ({ str, is_user }) => (
  <div
    className={`p-md rad-15px is-size-7 has-background-${
      is_user
        ? 'primary-lighter has-text-primary-ter'
        : 'grey-lighter has-text-black'
    } word-break-break-word`}
  >
    {str}
  </div>
);
