import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import useTopicLinks from '../../../../api/graphql/hooks/useTopicLinks';
import { Api } from '../../../../api/graphql/hooks/useTopics';
import { Topic as TopicType } from '../../../../types/qa/topics';
import { Icon } from '@ask-mona/ui';

interface Props {
  close: () => any;
  name: string;
  botId: number;
  token: string;
  api: Api;
  topic: TopicType;
  w: any;
}

type ScenarioContent = {
  id: string;
  name: string;
};

interface ButtonsProps {
  readonly background: string;
}

type Feature = {
  name: string;
  id: string;
};

const features = [
  { key: "feature_schedule", name: 'horaires', id: 'openings' },
  { key: "feature_programming", name: 'programmation', id: 'prog' },
  { key: "feature_itinerary", name: 'itinéraire', id: 'intinerary' },
  { key: "feature_prices", name: 'tarifs', id: 'price' },
  { key: "feature_speak_human", name: 'parler à un humain', id: 'webchat_messaging' },
  { key: "feature_share_social_media", name: 'partage réseaux sociaux', id: 'share' },
  { key: "feature_my_selection", name: 'ma sélection', id: 'selection' },
  { key: "feature_language", name: 'changement de langues', id: 'language' }
];

const LinkTo = (props: Props) => {
  const [scenarioSelected, setScenarioSelected] = useState<ScenarioContent>();
  const [featureSelected, setFeatureSelected] = useState<Feature>();
  const [linkTo, setLinkTo] = useState<string>('');
  const { scenarioContents } = useTopicLinks(props.botId, props.token);

  const translatedFeatures = useMemo(() => features.map(feature => ({
    name: props.w[feature.key],
    id: feature.id,
  })), [props.w]);

  return (
    <div className="w-4 has-background-white">
      <div className="p-md has-text-white has-background-primary relative">
        {props.w.link_feature_scenario}
        <span className="has-text-white absolute top right p-md pointer">
          <Icon icon="fas fa-times" onClick={() => props.close()} />
        </span>
      </div>
      <div className="p-md">
        <StyledTitle>{props.w.link_to}</StyledTitle>
        <WrapperInput>
          <div>
            <StyledInput
              checked={linkTo === 'Feature'}
              type="radio"
              id="feature"
              onChange={() => setLinkTo('Feature')}
            />
            <label htmlFor="feature">{props.w.a_feature}</label>
          </div>
          <div>
            <StyledInput
              checked={linkTo === 'ScenarioContent'}
              type="radio"
              id="scenario"
              onChange={() => setLinkTo('ScenarioContent')}
            />
            <label htmlFor="scenario">{props.w.a_scenario}</label>
          </div>
        </WrapperInput>
        {linkTo === 'ScenarioContent' && (
          <>
            <div>
              <StyledTitle>{props.w.link_to_scenario}</StyledTitle>
              <WrapperSelect>
                <Select
                  className="w-60p"
                  isClearable
                  options={scenarioContents}
                  getOptionLabel={(scenarioContent: ScenarioContent) =>
                    scenarioContent.name
                  }
                  getOptionValue={(scenarioContent: ScenarioContent) =>
                    scenarioContent.id
                  }
                  onChange={(option) => setScenarioSelected(option)}
                />
                <Button
                  background={'#738EFB'}
                  onClick={() => {
                    if (props.topic.link === null) {
                      if (scenarioSelected)
                        props.api.createTopicLink(
                          props.topic.id,
                          scenarioSelected.id,
                          linkTo
                        );
                    } else {
                      if (scenarioSelected)
                        props.api.updateTopicLink(
                          props.topic.link.id,
                          scenarioSelected.id,
                          linkTo
                        );
                    }
                    props.close();
                  }}
                >
                  {props.w.link}
                </Button>
              </WrapperSelect>
            </div>
          </>
        )}
        {linkTo === 'Feature' && (
          <>
            <div>
              <StyledTitle>{props.w.link_to_feature}</StyledTitle>
              <WrapperSelect>
                <Select
                  className="w-60p"
                  options={translatedFeatures}
                  getOptionLabel={(feature) => feature.name}
                  getOptionValue={(feature) => feature.id}
                  isClearable
                  onChange={(option) => setFeatureSelected(option)}
                />
                <Button
                  background={'#738EFB'}
                  onClick={() => {
                    if (props.topic.link === null) {
                      if (featureSelected)
                        props.api.createTopicLink(
                          props.topic.id,
                          featureSelected.id,
                          linkTo
                        );
                    } else {
                      if (featureSelected)
                        props.api.updateTopicLink(
                          props.topic.link.id,
                          featureSelected.id,
                          linkTo
                        );
                    }
                    props.close();
                  }}
                >
                  {props.w.link}
                </Button>
              </WrapperSelect>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
`;

const Button = styled.button<ButtonsProps>`
  display: flex;
  padding: 0.25em 1em;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  border-radius: 3px;
  border: none;
  background-color: ${({ background }) => background};
  color: white;
`;

const WrapperSelect = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled.p`
  margin: 1rem 0;
  color: #2d307b;
`;

const WrapperInput = styled.div`
  display: flex;
  justify-content: space-around;
  color: #2d307b;
`;

const StyledInput = styled.input`
  margin-right: 0.4rem;
`;

export default LinkTo;
