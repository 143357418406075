import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import StyledContainer from '../../../components/parameters/StyledContainers';
import StyledTitle from '../../../components/parameters/StyledTitle';
import StyledInput from '../../../components/parameters/StyledInput';
import StyledLabel from '../../../components/parameters/StyledLabel';
import InputRadio from '../../../components/parameters/InputRadio';
import CircleImg from '../../../components/parameters/CircleImg';
import StyledImage from '../../../components/parameters/StyledImage';
import Upload from '../../../_assets/icon/Upload';
import InputFile from '../../../components/parameters/InputFile';
import StyledUpload from '../../../components/parameters/StyledUpload';
import { _uploadImg } from '../../../_api/upload';
import Bot from '../../../_api/bot';
import { _sendFeedback } from '../../../_tools/ui';
import FlagIcon from "../../../components/FlagIcon";
import languageTranslations from '../../../translations/languages';
import {Icon, Button} from "@ask-mona/ui";
import BaseModal from "../../../components/BaseModal";
import CreateQr from "../../questionsAnswers/components/modals/CreateQr";
import LanguagesSelection from "./LanguagesSelection";

const ChatbotIndentity = ({ m, setSaveFunction }) => {
  const [languageFeatures, setLanguageFeatures] = useState([]);
  const [nameBot, setNameBot] = useState(m.bot.name);
  const [nameOrganization, setNameOrganization] = useState(
    m.bot.organization.name
  );
  const [familiar, setFamiliar] = useState();
  const [defaultVisual, setDefaultVisual] = useState('');
  const [lang, setLang] = useState([]);
  const [, setLoadUpload] = useState(false);
  const [logo, setLogo] = useState(m.bot.panel_logo);
  const [platform, setPlatform] = useState({});
  const [modalLanguages, setModalLanguages] = useState(false);

  const Api = new Bot(m.client, m.bot.id);
  const w = m.w.parameters;

  useEffect(() => {
    fetchBot();
  }, []);

  useEffect(() => {
    if (setSaveFunction) {
      setSaveFunction(onSave);
    }
  }, [nameBot, defaultVisual, familiar, logo]);

  useEffect(() => {
    if (setSaveFunction) {
      setSaveFunction(onSaveOrganization);
    }
  }, [nameOrganization]);

  useEffect(() => {
    if (setSaveFunction) {
      setSaveFunction(onSavePlatform);
    }
  }, [platform]);

  useEffect(() => {
    if (setSaveFunction) {
      setSaveFunction(() => onUpdateLang(lang));
    }
  }, [lang]);

  const onSave = async () => {
    const bot = {
      id: m.bot.id,
      name: nameBot,
      default_image: defaultVisual,
      panel_logo: logo,
      familiar,
    };
    if (Api && bot) {
      const res = await Api._updateBot(bot);
      if (res.success) {
        _sendFeedback('success', m.w.success.saved);
      } else {
        _sendFeedback('danger', m.w.error.general);
      }
    }
  };

  const onSavePlatform = async () => {
    if (Api && platform) {
      const res = await Api._updatePlatform({
        id: m.bot.id,
        messenger: platform.messenger,
        webchat: platform.webchat,
      });

      if (res.success) {
        _sendFeedback('success', m.w.success.saved);
      } else {
        _sendFeedback('danger', m.w.error.general);
      }
    }
  };

  const onSaveOrganization = async () => {
    const botOrganization = {
      id: m.bot.organization_id,
      name: nameOrganization,
    };

    if (Api && botOrganization) {
      const res = await Api._updateOrganization(botOrganization);
      if (res.success) {
        _sendFeedback('success', m.w.success.saved);
      } else {
        _sendFeedback('danger', m.w.error.general);
      }
    }
  };

  const fetchBot = async () => {
    const res = await Api._getFeatures();
    const languageFeatures = (res.getFeatures.features || []).filter(
        ({type}) => type === "language"
    );
    setLanguageFeatures(languageFeatures);
    const bot = {
      id: m.bot.id,
    };
    if (Api && bot) {
      const res = await Api._getBot(bot);
      if (res.success) {
        setPlatform(res.getBot.platforms);
        setLogo(res.getBot.panel_logo);
        setDefaultVisual(res.getBot.default_image);
        setFamiliar(res.getBot.familiar.activated);
        setLang(languageFeatures.filter(({slug})=> res.getBot.features.findIndex((feature)=> feature.slug === slug)>=0)
            .map((languageFeature) => ({
              feature_id: languageFeature.id,
              is_bot_active: res.getBot.features.some(
                  (feature) => feature.slug === languageFeature.slug && feature.is_bot_active
              )
            })))
      }
    }
  }

  const onUpdateLang = async (lang) => {
    let counterError = 0;
    if (Api) {
      return languageFeatures.reduce((result, languageFeature) => {
        return result.then(async () => {
          let res;
          const botLang = lang.find((el)=> el.feature_id === languageFeature.id);
          if(botLang) {
            res = await Api._addBotFeature({
              bot_id: m.bot.id,
              feature_id: [languageFeature.id],
              is_bot_active: botLang.is_bot_active,
            });
          } else {
            res = await Api._removeFeatures({
              bot_id: m.bot.id,
              feature_id: [languageFeature.id],
            });
          }
          if (!res.success) {
            counterError++;
          }
        });
      }, Promise.resolve()).finally(() => {
        counterError === 0 && _sendFeedback('success', m.w.success.saved);
        counterError >= 1 && _sendFeedback('danger', m.w.error.general);
        m.fct.updateBotLang(languageFeatures.filter(({id})=> lang.find((el) => el.feature_id === id)).map(({slug})=> slug));
      });
    }
  };

  return (
    <StyledContainer width={'80'}>
      <StyledTitle>{w.identity}</StyledTitle>
      <WrapperDiv>
        <StyledLabel width={'40'}>
          {w.nameBot}
          <StyledInput
            type="text"
            placeholder={nameBot}
            value={nameBot}
            onChange={(e) => {
              setNameBot(e.target.value);
            }}
          />
        </StyledLabel>
        <StyledLabel width={'40'}>
          {w.nameOrga}
          <StyledInput
            type="text"
            value={nameOrganization}
            onChange={(e) => {
              setNameOrganization(e.target.value);
            }}
          />
        </StyledLabel>
      </WrapperDiv>
      <WrapperTitle>
        <StyledDivWrapper>
        <StyledTitle>{w.lang}</StyledTitle>
        </StyledDivWrapper>
        <StyledDivWrapper>
          <StyledTitleEditLabel>
            <Button
                className="is-borderless shrink has-text-primary"
                onClick={() => setModalLanguages(true)}
            >
              {w.editLanguages} {' '}
            <Icon icon="fas fa-pen has-text-primary" className="is-size-7" />{' '}
            </Button>
          </StyledTitleEditLabel>
          {modalLanguages && (
              <BaseModal isOpen={modalLanguages} selector="#root">
                <LanguagesSelection
                    close={() => setModalLanguages(!modalLanguages)}
                    languageFeatures={languageFeatures}
                    languageBotFeatures={lang}
                    onBotBotFeaturesUpdated={(lang)=> setLang(lang)}
                    m={m}
                />
              </BaseModal>
          )}
        </StyledDivWrapper>
      </WrapperTitle>
      <WrapperDiv>
        <StyledDivWrapper>
          <StyledText>{w.inPanel}</StyledText>
          <StyledCheckbox>
            {languageFeatures.filter(({id})=> lang.findIndex(({feature_id})=> id === feature_id)>=0).map((languageFeature) => (
                <StyledCheckboxLabel key={languageFeature.id}>
                  <FlagIcon code={languageTranslations.relatedCountry[languageFeature.slug]} />
                </StyledCheckboxLabel>
            ))}
          </StyledCheckbox>
        </StyledDivWrapper>
        <StyledDivWrapper>
          <StyledText>{w.inBot}</StyledText>
          <StyledCheckbox>
            {languageFeatures.filter(({id})=> lang.findIndex(({feature_id, is_bot_active})=> id === feature_id && is_bot_active)>=0).map((languageFeature) => (
                <StyledCheckboxLabel key={languageFeature.id}>
                  <FlagIcon code={languageTranslations.relatedCountry[languageFeature.slug]} />
                </StyledCheckboxLabel>
            ))}
          </StyledCheckbox>
        </StyledDivWrapper>
      </WrapperDiv>
      <StyledTitle>{w.familiarChoice}</StyledTitle>
      <StyledCheckbox>
        <StyledCheckboxLabel flex mr>
          <InputRadio
            type="radio"
            name="familiar"
            value={familiar}
            checked={familiar ? true : false}
            onChange={(e) => setFamiliar(!familiar)}
          />
          {w.familiar}
        </StyledCheckboxLabel>
        <StyledCheckboxLabel flex>
          <InputRadio
            type="radio"
            name="familiar"
            value={familiar}
            checked={familiar ? false : true}
            onChange={(e) => setFamiliar(!familiar)}
          />
          {w.formal}
        </StyledCheckboxLabel>
      </StyledCheckbox>
      <StyledTitle>{w.platform}</StyledTitle>
      <StyledCheckbox>
        <StyledCheckboxLabel>
          <StyledInputCheckbox
            type="checkbox"
            name="platform"
            checked={platform.webchat}
            onChange={(e) =>
              setPlatform({ ...platform, webchat: !platform.webchat })
            }
          />
          {w.webchat}
        </StyledCheckboxLabel>
        <StyledCheckboxLabel>
          <StyledInputCheckbox
            type="checkbox"
            name="platform"
            checked={platform.messenger}
            onChange={(e) =>
              setPlatform({ ...platform, messenger: !platform.messenger })
            }
          />
          {w.messenger}
        </StyledCheckboxLabel>
      </StyledCheckbox>
      <StyledTitle>{w.chatbotVisual}</StyledTitle>
      <WrapperDiv>
        <StyledDivWrapper
          width={'50%'}
          alignItem
          justifyContent={'space-around'}
        >
          <SubititleLight mb={'1rem'}>{w.logoBot}</SubititleLight>
          {logo ? (
            <StyledDivWrapper alignItem={'flex-start'}>
              <CircleImg width={'80'} height={'80'}>
                <StyledImage width={'80'} src={logo} alt="" />
              </CircleImg>
              <ButtonWrapper>
                <span>{w.changeTheIcon}</span>
                <InputUpload
                  type="file"
                  onChange={async (e) => {
                    setLoadUpload(true);
                    await _uploadImg(
                      m.bot.id,
                      e,
                      (res) => {
                        setLoadUpload(false);
                        _sendFeedback("success", m.w.success.saved);
                        setLogo(res.path);
                      },
                      (err) => {
                        setLoadUpload(false);
                      }
                    );
                  }}
                />
              </ButtonWrapper>
            </StyledDivWrapper>
          ) : (
            <>
              <StyledUpload noMargin width={'75'}>
                <Upload />
                <div>
                  <p>{w.download}</p>
                  <p>{w.downloadSize})</p>
                </div>
                <InputFile
                  type="file"
                  onChange={async (e) => {
                    setLoadUpload(true);
                    await _uploadImg(
                      m.bot.id,
                      e,
                      (res) => {
                        setLoadUpload(false);
                        _sendFeedback("success", m.w.success.saved);
                        setLogo(res.path);
                      },
                      (err) => {
                        setLoadUpload(false);
                      }
                    );
                  }}
                />
              </StyledUpload>
              <StyledText width={'75%'}>{w.downloadFormat}</StyledText>
            </>
          )}
        </StyledDivWrapper>
        <StyledDivWrapper
          width={'50%'}
          alignItem
          justifyContent={'space-around'}
        >
          <SubititleLight mb={'1rem'}>{w.defaultVisual}</SubititleLight>
          {defaultVisual ? (
            <StyledDivWrapper alignItem={'flex-start'}>
              <CircleImg width={'80'} height={'80'}>
                <StyledImage width={'80'} src={defaultVisual} alt="" />
              </CircleImg>
              <ButtonWrapper>
                <span>{w.changeTheIcon}</span>
                <InputUpload
                  type="file"
                  onChange={async (e) => {
                    setLoadUpload(true);
                    await _uploadImg(
                      m.bot.id,
                      e,
                      (res) => {
                        _sendFeedback("success", m.w.success.saved);
                        setLoadUpload(false);
                        setDefaultVisual(res.path);
                      },
                      (err) => {
                        setLoadUpload(false);
                      }
                    );
                  }}
                />
              </ButtonWrapper>
            </StyledDivWrapper>
          ) : (
            <>
              <StyledUpload noMargin width={'75'}>
                <Upload />
                <div>
                  <p>{w.download}</p>
                  <p>{w.downloadSize})</p>
                </div>
                <InputFile
                  type="file"
                  onChange={async (e) => {
                    setLoadUpload(true);
                    await _uploadImg(
                      m.bot.id,
                      e,
                      (res) => {
                        _sendFeedback("success", m.w.success.saved);
                        setLoadUpload(false);
                        setDefaultVisual(res.path);
                      },
                      (err) => {
                        setLoadUpload(false);
                      }
                    );
                  }}
                />
              </StyledUpload>
              <StyledText width={'75%'}>{w.downloadFormat}</StyledText>
            </>
          )}
        </StyledDivWrapper>
      </WrapperDiv>
    </StyledContainer>
  );
};

const WrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem 0;
`;

const WrapperTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledTitleEditLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const StyledCheckbox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  > span {
    width: 36px;
    height: 36px;
  }
`;

const StyledInputCheckbox = styled.input`
  margin-right: 1rem;
`;

const StyledDivWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start
`;

const StyledText = styled.p`
  font-weight: 300;
  font-size: 1em;
`;

const SubititleLight = styled.h3`
  font-size: 1em;
  margin-bottom: ${({ mb }) => mb};
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: 150px;
  text-align: center;
  margin-top: 1rem;
  transition: 0.3s;
  cursor: pointer;
  transition: opacity 0.3s;

  > span {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.white};
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 12px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const InputUpload = styled.input`
  display: inline-block;
  position: absolute;
  z-index: 1000000;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

export default ChatbotIndentity;
