export default {
  getCity: ['La ville a été trouvé !', 'The city has been found!'],
  createPlace: ['Le nouveau lieu a été crée !', 'The new place has been created!'],
  updatePlace: ['Le lieu a été mis à jour', 'The location has been updated'],
  createSubPlace: [
    'Le nouveau sous-lieu a été crée !',
    'The new sub-location has been created!',
  ],
  sync_with_clarifai: ["ynchronisé avec Clarifai", "Synchronized with Clarifai"],
};
