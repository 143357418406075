import React from 'react';
import { Field, Select } from '@ask-mona/ui';
import { _getKeyValue } from '../../../_tools/other';

export default ({ m: { w }, event, errors, bot, fct }) => {
  const type = _getKeyValue(event, 'type') || { slug: '', translations: '' };
  let label = '';
  if (type.translations)
    type.translations.forEach((t) => {
      label = label || t.short_name || t.name;
    });
  label = label || type.slug;
  let list = [];
  let items = bot.types || [];
  items.forEach((i) => {
    let label = '';
    i.translations &&
      i.translations.forEach((t) => {
        label = label || t.short_name || t.name;
      });
    label = label || i.slug;
    list.push({ id: i.id, value: label, data: i });
  });
  return (
    <Field
      title={w.event.type}
      className="m-b-md"
      error={errors.type ? w.error.missingType : ''}
    >
      <Select
        items={list}
        className="w-100p"
        defaultValue={label}
        onSelect={fct.selectType}
      />
    </Field>
  );
};
