import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { withMain } from '../hoc/main';
import { withApi } from '../hoc/api';
import Foundations from '../components/base/Foundations';
import Sidebar from '../components/base/Sidebar';
import Header from '../components/base/Header';
import ErrorBoundary from '../components/ErrorBoundary';

import Schedule from './settings/Schedule';
import Pricing from './settings/Pricing';
import Direction from './settings/Direction';

//Api
import Analytics from '../_api/analytics';
import Bot from '../_api/bot';

const Settings = (props) => {
  const {
    m: { bot, client, menu, isIframe },
  } = props;
  const preURL = props.match.url;
  const section = menu.find(({path}) => path.includes("/office/general")) || {};
  const [subID, setSubID] = useState(0);
  const [botID, setBotID] = useState(bot.id);

  useEffect(() => {
    generateMenu(props);
    createAnalyticsFeatures(bot.id, client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (botID !== bot.id) {
      setBotID(bot.id);
      generateMenu(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bot.id]);

  const generateMenu = (props) => {
    let curr = menu.find((m) => m.id === section.id);
    let index = 0;
    switch (true) {
      case props.location.pathname.includes("schedules"):
        index = 0;
        break;
      case props.location.pathname.includes("pricing"):
        index = 1;
        break;
      case props.location.pathname.includes("direction"):
        index = 2;
        break;
      default:
        break;
    }
    let m = curr.menu[index];
    m && changeSub(m.id);
    m && props.history.push(m.path);
  };

  const createAnalyticsFeatures = async (botID, client) => {
    const Api = new Bot(client, botID);
    const res = await Api._getBot();
    const array = [
      { name: 'evts_published', activated: true },
      { name: 'evts_unpublished', activated: true },
      { name: 'messages', activated: true },
      { name: 'nb_users', activated: false },
      { name: 'duration', activated: true },
      { name: 'nb_sessions_users', activated: false },
      { name: 'nb_users_fr', activated: false },
      { name: 'nb_clic_evts', activated: false },
    ];

    if (!res.getBot.config.analyticsFeatures) {
      array.map(async (a) => {
        await new Analytics(client, botID)._createAnalyticsFeatures({
          bot_id: botID,
          name: a.name,
          activated: a.activated,
        });
      });
    }
  };

  const changeSub = (id) => {
    setSubID(id);
  };

  let sub = section.menu.find((c) => c.id === subID);
  let state = { section, subID, fct: { changeSub: changeSub } };

  return (
    <Foundations
      aside={!isIframe && <Sidebar menu={section.menu} c={state} noarrow={true} />}
      header={
        <Header
          title={section.title}
          subtitle={sub ? sub.title : ''}
          {...props}
          // addons={[<ScheduleHeader {...this.props} {...this.state} />]}
        />
      }
    >
      <Route
        exact
        path={`${preURL}/schedule`}
        render={() => (
          <ErrorBoundary>
            <Schedule {...props} {...state} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path={`${preURL}/pricing`}
        render={() => (
          <ErrorBoundary>
            <Pricing {...props} {...state} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path={`${preURL}/direction`}
        render={() => (
          <ErrorBoundary>
            <Direction {...props} {...state} />
          </ErrorBoundary>
        )}
      />
    </Foundations>
  );
};

export default withApi(withMain(Settings));
