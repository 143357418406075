import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import StyledBigTitle from '../../../../components/parameters/StyledTitle';
import StyledLabel from '../../../../components/parameters/StyledLabel';
import InputRadio from '../../../../components/parameters/InputRadio';
import StyledUpload from '../../../../components/parameters/StyledUpload';
import InputFile from '../../../../components/parameters/InputFile';
import Gradient from '../../../../components/parameters/Gradient';
import Upload from '../../../../_assets/icon/Upload';
import CircleImg from '../../../../components/parameters/CircleImg';
import Toggle from '../../../../components/parameters/Toggle';
import { RightArrow } from '@styled-icons/boxicons-solid/RightArrow';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';
import Aside from './assets/images/aside.png';
import Standard from './assets/images/standard.png';
import templateWindow from './templates/Window';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WrapperDiv = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ mr }) => (mr ? mr : '2rem 0')};
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent && 'space-around'};
  width: ${({ width }) => width};
`;

const StyledImage = styled.img`
  max-width: 100%;
  vertical-align: ${({ align }) => align};
`;

const SubititleLight = styled.h3`
  font-size: 1.1.rem;
`;

const StyledText = styled.p`
  font-weight: 300;
  font-size: 0.8em;
  width: 50%;
`;

const WrapperUpload = styled.div`
  width: 50%;
`;

const WrapperLabel = styled.div`
  display: flex;
  align-items: baseline;
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: 150px;
  text-align: center;
  margin-top: 1rem;
  transition: 0.3s;
  cursor: pointer;
  transition: opacity 0.3s;

  > span {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.white};
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 12px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const InputUpload = styled.input`
  display: inline-block;
  position: absolute;
  z-index: 1000000;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const StyledDivWrapper = styled.div`
  display: flex;
  flex-direction: ${({ noColumn }) => !noColumn && 'column'};
  align-items: ${({ alignItem }) => alignItem || (!alignItem && 'center')};
  margin-right: ${({ mr }) => mr};
  width: ${({ width }) => width};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const Window = ({ w, wWebchat, state, dispatch, handleBackgroundImage }) => {
  const [hideFirstContent, setHideFirstContent] = useState(true);
  const [hideSecondContent, setHideSecondContent] = useState(true);
  const [backgroundSelected, setBackgroundSelected] = useState('');
  const [toggleShadow, setToggleShadow] = useState(false);
  const { Handle } = Slider;

  useEffect(() => {
    if (state.imageBody) {
      setBackgroundSelected('image');
    } else if (state.backgroundColorBody?.includes('linear-gradient')) {
      setBackgroundSelected('gradient');
    } else if (
      !state.backgroundColorBody?.includes('linear-gradient') &&
      !state.imageBody
    ) {
      setBackgroundSelected('color');
    }
  }, []);

  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} %`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  return (
    <Wrapper>
      <StyledBigTitle>
        {wWebchat.templateChoice}
        <span onClick={() => setHideFirstContent(!hideFirstContent)}>
          {hideFirstContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideFirstContent && (
        <>
          <StyledBigTitle>{wWebchat.windowLayout}</StyledBigTitle>
          <WrapperDiv justifyContent>
            <StyledLabel radio width={'45'}>
              <StyledImage src={Standard} alt="" />
              <WrapperLabel>
                <InputRadio
                  mt
                  type="radio"
                  value="standard"
                  checked={
                    JSON.stringify(state.templateWindow) ===
                    JSON.stringify(templateWindow('standard'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setTemplateWindow',
                      payload: templateWindow(e.target.value),
                    });
                    dispatch({ type: 'setFullpage', payload: true });
                  }}
                />
                {wWebchat.standard}
              </WrapperLabel>
            </StyledLabel>
            <StyledLabel radio width={'45'}>
              <StyledImage src={Aside} alt="" />
              <WrapperLabel>
                <InputRadio
                  mt
                  type="radio"
                  value="aside"
                  checked={
                    JSON.stringify(state.templateWindow) ===
                    JSON.stringify(templateWindow('aside'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setTemplateWindow',
                      payload: templateWindow(e.target.value),
                    });
                    dispatch({ type: 'setFullpage', payload: true });
                  }}
                />
                {wWebchat.aside}
              </WrapperLabel>
            </StyledLabel>
          </WrapperDiv>
        </>
      )}
      <StyledBigTitle>
        {wWebchat.templateConfig}
        <span onClick={() => setHideSecondContent(!hideSecondContent)}>
          {hideSecondContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideSecondContent && (
        <>
          <WrapperDiv>
            <SubititleLight>{wWebchat.borderWindow}</SubititleLight>
          </WrapperDiv>
          <WrapperDiv width={'40%'} mr={'0 1rem'}>
            <Slider
              trackStyle={{ backgroundColor: 'red', height: '10px' }}
              railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
              handleStyle={{
                width: '20px',
                height: '20px',
                border: '1px solid lightgray',
                boxShadow:
                  'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
              }}
              min={0}
              max={40}
              step={1}
              defaultValue={state.borderRadius}
              value={state.borderRadius}
              onChange={(value) => {
                dispatch({ type: 'setBorderRadius', payload: value });
              }}
              handle={handle}
            />
          </WrapperDiv>
          <Toggle
            toggle={toggleShadow}
            handleClick={() => {
              setToggleShadow(!toggleShadow);
              toggleShadow
                ? dispatch({
                    type: 'setBoxShadowWebchat',
                    payload:
                      '0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)',
                  })
                : dispatch({ type: 'setBoxShadowWebchat', payload: 'none' });
            }}
            content={
              state.boxShadowWebchat === 'none'
                ? wWebchat.window_back_shadow_disabled
                : wWebchat.window_back_shadow_enabled
            }
          />
          <WrapperDiv>
            <SubititleLight>{wWebchat.backgroundWindow}</SubititleLight>
          </WrapperDiv>

          <WrapperDiv mr={'2rem'}>
            <StyledLabel radio flex width={'50'} alignSelf={'start'}>
              <InputRadio
                type="radio"
                value="color"
                name="background"
                checked={backgroundSelected === 'color'}
                onChange={(e) => {
                  setBackgroundSelected(e.target.value);
                  dispatch({ type: 'setImageBody', payload: '' });
                }}
              />
              {wWebchat.chooseBg}
            </StyledLabel>
            <ChromePicker
              color={
                backgroundSelected === 'color' && state.backgroundColorBody
              }
              onChangeComplete={(val) => {
                if (backgroundSelected === 'color') {
                  dispatch({
                    type: 'setBackgroundColorBody',
                    payload: val.hex,
                  });
                  dispatch({ type: 'setImageBody', payload: '' });
                }
              }}
            />
          </WrapperDiv>

          <WrapperDiv mr={'2rem'}>
            <StyledLabel radio flex>
              <InputRadio
                type="radio"
                name="background"
                value="gradient"
                checked={backgroundSelected === 'gradient'}
                onChange={(e) => {
                  setBackgroundSelected(e.target.value);
                  dispatch({ type: 'setImageBody', payload: '' });
                }}
              />
              {wWebchat.chooseGradient}
              <Gradient
                background={state.backgroundColorBody}
                dispatch={dispatch}
                type={'setBackgroundColorBody'}
              />
            </StyledLabel>
          </WrapperDiv>

          <WrapperDiv mr={'2rem'}>
            <StyledLabel radio flex width={'50'}>
              <InputRadio
                type="radio"
                name="background"
                value="image"
                checked={backgroundSelected === 'image'}
                onChange={(e) => {
                  setBackgroundSelected(e.target.value);
                }}
              />
              {wWebchat.chooseImage}
            </StyledLabel>
            <WrapperUpload>
              {state.imageBody ? (
                <StyledDivWrapper alignItem={'flex-start'}>
                  <CircleImg width={'none'} height={'none'} noRadius>
                    <StyledImage
                      align={'bottom'}
                      src={state.imageBody}
                      alt=""
                    />
                  </CircleImg>
                  <ButtonWrapper>
                    <span>{wWebchat.changeIcon}</span>
                    <InputUpload
                      type="file"
                      onChange={(e) => {
                        handleBackgroundImage(e);
                      }}
                    />
                  </ButtonWrapper>
                </StyledDivWrapper>
              ) : (
                <>
                  <StyledUpload width={'65'}>
                    <Upload />
                    <div>
                      <p>{w.download}</p>
                      <p>{w.downloadSize})</p>
                    </div>
                    <InputFile
                      type="file"
                      onChange={(e) => {
                        handleBackgroundImage(e);
                      }}
                    />
                  </StyledUpload>
                  <StyledText>{w.downloadFormat}</StyledText>
                </>
              )}
            </WrapperUpload>
          </WrapperDiv>
        </>
      )}
    </Wrapper>
  );
};

export default Window;
