import React, { Component, useEffect, useState } from 'react';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';

import { GET_CATEGORIES_AND_INTENTS } from '../_api/intent';
import Bot from '../_api/bot';
import { Route } from 'react-router-dom';
import { withMain } from '../hoc/main';
import { withApi } from '../hoc/api';
import Foundations from '../components/base/Foundations';
// import Sidebar from '../components/base/Sidebar';
import { Sidebar } from './qa/sidebars/Sidebar';
import Header from '../components/base/Header';
import ErrorBoundary from '../components/ErrorBoundary';
import QA from './qa/QA';
import QaState from './qa/context/QaState';

import { SetLang } from './qa/header/SetLang';

class QAContainerComponent extends Component {
  constructor(props) {
    super(props);
    const {
      m: { sectionID, menu },
    } = props;
    this.preURL = props.match.url;
    this.state = {
      section: menu.find((s) => s.id === sectionID) || {},
      subID: 0,
      fct: {
        changeSub: this.changeSub,
      },
    };
  }

  componentDidMount() {
    this.generateMenu(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.botID !== nextProps.m.bot.id) {
      this.setState({ botID: nextProps.m.bot.id });
      this.generateMenu(nextProps);
    }
  }

  generateMenu = (props) => {
    const {
      m: { menu, sectionID },
    } = props;
    let curr = menu.find((m) => m.id === sectionID);
    let m = curr.menu[0];
    m && this.changeSub(m.id);
    m && props.history.push(m.path);
  };

  changeSub = (id) => {
    this.setState({ subID: id });
  };

  render() {
    const { subID, section } = this.state;
    let isScenario = subID === 0 || subID === 4;
    let sub = section.menu.find((c) => c.id === subID);
    return (
      <Foundations
        aside={<Sidebar menu={section.menu} c={this.state} {...this.props} />}
        header={
          <Header
            title={isScenario ? '' : section.title}
            subtitle={sub && !isScenario ? sub.title : ''}
            addons={[<SetLang {...this.props} />]}
          />
        }
      >
        <Route
          exact
          path={`${this.preURL}`}
          render={() => (
            <ErrorBoundary>
              <QA
                {...this.props}
                changeSub={this.changeSub}
                subID={this.state.subID}
              />
            </ErrorBoundary>
          )}
        />
      </Foundations>
    );
  }
}

const QAContainer = (props) => {
  const {
    m: { client, bot },
    api: { intent },
    data,
  } = props;

  const { getIntents, getIntentsV2, getCategoriesV2 } = data;
  const [payloads, setPayloads] = useState([]);
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(null);
  const [addCat, setAddCat] = useState(false);
  const [lang, setLang] = useState('fr');

  useEffect(() => {
    if (getIntents) {
      getIntents.forEach((e) => {
        if (e.intents) {
          e.intents.forEach(async (el) => {
            if (!el[lang]) {
              await intent._updateIntent(
                {
                  bot_id: bot.id,
                  id: el.id,
                  language: lang,
                  name: el['fr'].name,
                },
                { refetchQueries: ['getCategoriesAndIntents'] }
              );
            }
          });
        }
      });
    } else if (getIntentsV2) {
      getIntentsV2.forEach((e) => {
        if (e.intents) {
          e.intents.forEach(async (el) => {
            if (!el) {
              await intent._updateIntent(
                {
                  bot_id: bot.id,
                  id: el.id,
                  language: lang,
                  name: el.name,
                },
                { refetchQueries: ['getCategoriesAndIntents'] }
              );
            }
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    if (getIntents) {
      if (focus) {
        if (focus.id !== 'allActivated' && focus.id !== 'allDesactivated')
          setFocus(getIntents.find((el) => el.id === focus.id));
      } else setFocus(getIntents[0]);
    } else if (getIntentsV2) {
      if (focus) {
        if (focus.id !== 'allActivated' && focus.id !== 'allDesactivated')
          setFocus(getIntentsV2.find((el) => el.id === focus.id));
      } else setFocus(getIntentsV2[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIntents, getIntentsV2, getCategoriesV2]);

  useEffect(() => {
    const fetchScenario = async () => {
      let api = new Bot(client, bot.id);
      let res = await api._getModules();
      if (res.success && res.json) {
        if ('trigger' in res.json) {
          let triggers = res.json['trigger'];
          let payloads = Object.keys(triggers)
            .filter((k) => !triggers[k].tag)
            .map((k) => ({
              value: triggers[k].id,
              label: triggers[k].name,
            }));
          setPayloads(payloads);
        }
      }
    };
    fetchScenario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.m.bot]);

  return (
    <QaState>
      <QAContainerComponent
        {...props}
        data={data}
        payloads={payloads}
        cats={getIntents ? getIntents : getIntentsV2}
        allCats={getCategoriesV2}
        focus={focus}
        setFocus={setFocus}
        lang={lang}
        setLang={setLang}
        addCat={addCat}
        setAddCat={setAddCat}
        search={search}
        setSearch={setSearch}
      />
    </QaState>
  );
};

const withIntents = graphql(GET_CATEGORIES_AND_INTENTS, {
  options: (ownProps) => {
    return {
      variables: {
        bot_id: ownProps.m.bot.id,
      },
      fetchPolicy: 'no-cache',
    };
  },
});

export default withMain(withApi(compose(withIntents)(QAContainer)));
