import React from 'react';
import styled from 'styled-components';
import { withMain } from '../../../hoc/main';

const StyledInput = styled.input`
  appearance: none;
  box-sizing: border-box;
  border: 1px solid #e4e0f5;
  background-color: #f5f4fb;
  color: #5540bf;
  font-size: 0.6rem;
  outline: none;
  margin: 0 2px 2px 2px;
  width: 170px;
  ::-webkit-datetime-edit-text {
    padding: 0 0.1rem;
  }
  ::-webkit-inner-spin-button {
    display: none;
  }
`;

const StyledInputTime = styled.input`
  outline: none;
  width: 35px;
  line-height: 1.65;
  padding: 0;
  margin: 0;
  border: 1px solid #eee;
`;

const WrapperInputTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  margin-top: 0.3rem;
`;

const StyledLine = styled.div`
  display: flex;
  margin: 0.3rem;
  align-items: center;
`;

function ScheduleAlert(props) {
  const {
    addDate,
    error,
    fct,
    item,
    update,
    index,
    allDates,
    hour,
    display,
    m: { w },
  } = props;

  return (
    <React.Fragment>
      <div className="flex row space-between is-size-8 font-weight-light m-t-xs h-3 border-dashed">
        <WrapperColumn width={'60%'}>
          {update &&
            item.date.map((item, k) => {
              if (item.startDate && item.endDate) {
                return (
                  <StyledLine key={k}>
                    <p>{w.generic.from}</p>
                    <StyledInput
                      onChange={(e) =>
                        fct.updateDate(e.target.value, k, index, 'date_start')
                      }
                      type="date"
                      value={item.startDate ? item.startDate : ''}
                    />
                    <p>{w.generic.at}</p>
                    <StyledInput
                      onChange={(e) =>
                        fct.updateDate(e.target.value, k, index, 'date_end')
                      }
                      type="date"
                      value={item.endDate ? item.endDate : ''}
                    />
                    <i
                      className="far fa-times-circle m-xs transition color_hover--danger"
                      onClick={() => fct.deleteDate(k, true, index)}
                    ></i>
                  </StyledLine>
                );
              } else if (item.startDate) {
                return (
                  <StyledLine key={k}>
                    <p className="m-l-md m-t-xs">{w.generic.the}</p>
                    <StyledInput
                      onChange={(e) =>
                        fct.updateDate(e.target.value, k, index, 'date_start')
                      }
                      type="date"
                      value={item.startDate ? item.startDate : ''}
                    />
                    <i
                      className="far fa-times-circle m-xs transition color_hover--danger"
                      onClick={() => fct.deleteDate(k, true, index)}
                    ></i>
                  </StyledLine>
                );
              }
              return null;
            })}
          {!update &&
            allDates &&
            allDates.map((item, k) => {
              if (item.endDate !== undefined) {
                return (
                  <StyledLine key={k}>
                    <p className="m-l-xs">{w.generic.from}</p>
                    <StyledInput
                      onChange={(e) =>
                        fct.changeDate('date_start', k, e.target.value)
                      }
                      name="date_start"
                      type="date"
                      value={item.startDate ? item.startDate : ''}
                    />
                    <p className="m-l-xs">{w.generic.at}</p>
                    <StyledInput
                      onChange={(e) =>
                        fct.changeDate('date_end', k, e.target.value)
                      }
                      name="date_end"
                      type="date"
                      value={item.endDate ? item.endDate : ''}
                    />
                    <i
                      className="far fa-times-circle m-xs transition color_hover--danger"
                      onClick={() => fct.deleteDate(k, false)}
                    ></i>
                  </StyledLine>
                );
              } else {
                return (
                  <StyledLine key={k}>
                    <p className="m-l-xs m-t-xs">{w.generic.the}</p>
                    <StyledInput
                      onChange={(e) =>
                        fct.changeDate('date_start', k, e.target.value)
                      }
                      name="date_start"
                      type="date"
                      value={item.startDate ? item.startDate : ''}
                    />
                    <i
                      className="far fa-times-circle m-xs transition color_hover--danger"
                      onClick={() => fct.deleteDate(k, false)}
                    ></i>
                  </StyledLine>
                );
              }
            })}
          {update && (
            <div className="flex column is-size-8 m-xs">
              {error.dates && (
                <p className="danger_alert p-xs">
                  {w.alertMessages.addDateAtLeast}
                </p>
              )}
              <div className="w-50p has-background-primary-lighter m-xs p-xs rad-10px transition period_hover">
                <div
                  className="p-l-xs flex row "
                  onClick={() => fct.addNewDate('date', index)}
                >
                  <i className="fas fa-plus fa-xs m-xs"></i>
                  <p className="p-l-xs">{w.alertMessages.addDate}</p>
                </div>
              </div>
              <div className="w-50p has-background-primary-lighter m-xs p-xs rad-10px transition period_hover">
                <div
                  className="p-l-xs flex row"
                  onClick={() => fct.addNewDate('period', index)}
                >
                  <i className="fas fa-plus fa-xs m-xs"></i>
                  <p className="p-l-xs">{w.alertMessages.addPeriod}</p>
                </div>
              </div>
            </div>
          )}
          {!update && (
            <div className="flex column is-size-8">
              {error.dates && (
                <p className="danger_alert p-xs">
                  {w.alertMessages.addDateAtLeast}
                </p>
              )}
              <div className="w-50p has-background-primary-lighter m-xs p-xs rad-10px transition period_hover">
                <div
                  className="p-l-xs flex row"
                  onClick={() => addDate('date')}
                >
                  <i className="fas fa-plus fa-xs m-xs"></i>
                  <p className="p-l-xs">{w.alertMessages.addDate}</p>
                </div>
              </div>
              <div className="w-50p has-background-primary-lighter m-xs p-xs rad-10px transition period_hover">
                <div
                  className="p-l-xs flex row"
                  onClick={() => addDate('period')}
                >
                  <i className="fas fa-plus fa-xs m-xs"></i>
                  <p className="p-l-xs">{w.alertMessages.addPeriod}</p>
                </div>
              </div>
            </div>
          )}
        </WrapperColumn>
        <WrapperColumn width={'40%'}>
          {update && item.hours.HE && item.date.length !== 0 && (
            <WrapperInputTime>
              <p className="m-r-md">{w.generic.from_place}</p>
              <StyledLine>
                <StyledInputTime
                  className={
                    error.hourStart
                      ? 'size-box-number danger_alert'
                      : 'size-box-number'
                  }
                  onChange={(e) =>
                    fct.updateHour(e.target.value, index, 'hourStart')
                  }
                  value={item.hours.HS}
                  maxLength={2}
                  size={2}
                  min={0}
                  max={23}
                  type="number"
                />
                :
                <StyledInputTime
                  className={
                    error.minStart
                      ? 'size-box-number danger_alert'
                      : 'size-box-number'
                  }
                  onChange={(e) =>
                    fct.updateHour(e.target.value, index, 'minStart')
                  }
                  value={item.hours.MS}
                  maxLength={2}
                  size={2}
                  min={0}
                  max={59}
                  type="number"
                />
              </StyledLine>
              <p className="m-r-md">à</p>
              <StyledLine>
                <StyledInputTime
                  className={
                    error.hourEnd
                      ? 'size-box-number danger_alert'
                      : 'size-box-number'
                  }
                  onChange={(e) =>
                    fct.updateHour(e.target.value, index, 'hourEnd')
                  }
                  value={item.hours.HE}
                  maxLength={2}
                  size={2}
                  min={0}
                  max={23}
                  type="number"
                />
                :
                <StyledInputTime
                  className={
                    error.minEnd
                      ? 'size-box-number danger_alert'
                      : 'size-box-number'
                  }
                  onChange={(e) =>
                    fct.updateHour(e.target.value, index, 'minEnd')
                  }
                  value={item.hours.ME}
                  maxLength={2}
                  size={2}
                  min={0}
                  max={59}
                  type="number"
                />
              </StyledLine>
            </WrapperInputTime>
          )}
          {!update && display && (
            <WrapperInputTime>
              <p className="m-r-xs">{w.generic.from_place}</p>
              <StyledLine>
                <StyledInputTime
                  className={
                    error.hourStart
                      ? 'size-box-number danger_alert '
                      : 'size-box-number'
                  }
                  onChange={(e) => fct.changeHour(e, 'hourStart')}
                  value={hour.hourStart ? hour.hourStart : ''}
                  maxLength={2}
                  size={2}
                  min={0}
                  max={23}
                  type="number"
                />
                <span> : </span>
                <StyledInputTime
                  className={
                    error.minStart
                      ? 'size-box-number danger_alert '
                      : 'size-box-number'
                  }
                  onChange={(e) => fct.changeHour(e, 'minStart')}
                  value={hour.minStart ? hour.minStart : ''}
                  maxLength={2}
                  size={2}
                  min={0}
                  max={59}
                  type="number"
                />
              </StyledLine>
              <p className="m-r-xs">à</p>
              <StyledLine>
                <StyledInputTime
                  className={
                    error.hourEnd
                      ? 'size-box-number danger_alert '
                      : 'size-box-number'
                  }
                  onChange={(e) => fct.changeHour(e, 'hourEnd')}
                  value={hour.hourEnd ? hour.hourEnd : ''}
                  maxLength={2}
                  size={2}
                  min={0}
                  max={23}
                  type="number"
                />
                <span> : </span>
                <StyledInputTime
                  className={
                    error.minEnd
                      ? 'size-box-number danger_alert '
                      : 'size-box-number'
                  }
                  onChange={(e) => fct.changeHour(e, 'minEnd')}
                  value={hour.minEnd ? hour.minEnd : ''}
                  maxLength={2}
                  size={2}
                  min={0}
                  max={59}
                  type="number"
                />
              </StyledLine>
            </WrapperInputTime>
          )}
          {update && item.date.length !== 0 && !item.hours.HE && (
            <div className="w-80p has-background-primary-lighter m-xs p-xs rad-10px transition period_hover">
              <div
                className="is-size-8 flex row center"
                onClick={() => fct.addHour(index)}
              >
                <i className="fas fa-plus fa-xs m-xs"></i>
                <p className="p-l-xs">{w.alertMessages.addHours}</p>
              </div>
            </div>
          )}
          {update && item.date.length !== 0 && item.hours.HE && (
            <div className="flex center transition m-xs">
              <p
                className="is-size-8 w-2 m-xs p-xs rad-15px danger_alert period_hover--danger p-l-xs"
                onClick={() => fct.deleteHour(index)}
              >
                <i className="fas fa-minus fa-xs"></i>
                {w.generic.delete}
              </p>
            </div>
          )}
          {!update && allDates.length !== 0 && !display && (
            <div className="w-80p has-background-primary-lighter m-xs p-xs rad-10px transition period_hover">
              <div
                className="is-size-8 flex row center"
                onClick={() => fct.displayHour()}
              >
                <i className="fas fa-plus fa-xs m-xs"></i>
                <p className="p-l-xs">{w.alertMessages.addHours}</p>
              </div>
            </div>
          )}
          {!update && allDates.length !== 0 && display && (
            <div className="flex center transition m-xs">
              <p
                className="is-size-8 w-2 m-xs p-xs rad-15px danger_alert period_hover--danger p-l-xs"
                onClick={() => fct.displayHour()}
              >
                <i className="fas fa-minus"></i>
                {w.generic.delete}
              </p>
            </div>
          )}
        </WrapperColumn>
      </div>
    </React.Fragment>
  );
}

export default withMain(ScheduleAlert);
