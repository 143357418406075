import gql from 'graphql-tag';
import Client, { _mutate, _query } from './client';

class User {
  constructor(client) {
    this.client = client;
    this.user = null;
    this.organization = null;
  }

  _login = async (variables) => {
    const res = await _mutate(this.client, LOGIN, variables);
    if (res.success && res.login) {
      const { organization, ...user } = res.login;
      this.user = user;
      this.organization = organization;
      this.client = new Client(user.token);
    }
    return res;
  };

  _logout = async () => {
    this.client = new Client();
  };

  _getUser = async (variables) => {
    const res = await _query(this.client, GET_USER, variables);
    if (res.success) {
      if (!res.getUser) return { success: false, error: 'User not found' };
      const { organization, ...user } = res.getUser;
      this.user = user;
      this.organization = organization;
    }
    return res;
  };

  _getAllUsers = async (variables, opt) => {
    return await _query(this.client, GET_ALL_USERS, variables, opt);
  };

  _updateStatus = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_STATUS, variables, opt);
  };

  _revokeFromOrg = async (variables, opt) => {
    return await _mutate(this.client, REVOKE_FROM_ORG, variables, opt);
  };

  _createInvitation = async (variables, opt) => {
    return await _mutate(this.client, CREATE_INVITATION, variables, opt);
  };

  _deleteInvitation = async (variables, opt) => {
    return await _mutate(this.client, DELETE_INVITATION, variables, opt);
  };

  async getInvitation (params, opt) {
    return await _mutate(this.client, GET_INVITATION, params, opt);
  }

  _updateUser = async (variables) => {
    const res = await _mutate(this.client, UPDATE_USER, variables);
    if (res.success) {
      const user = res.updateUser;
      this.user = { ...this.user, ...user };
    }
    return res;
  };

  _validateAndCreateUser = async (variables) => {
    const res = await _mutate(this.client, VALIDATE_AND_CREATE_USER, variables);
    if (res.success) {
      const { organization, ...user } = res.validateAndCreateUser;
      this.user = user;
      this.organization = organization;
    }
    return res;
  };

  _register = async (variables) => {
    return await _mutate(this.client, REGISTER, variables);
  };

  _forgetPassword = async (variables) => {
    return await _mutate(this.client, FORGET_PASSWORD, variables);
  };

  _updateAfterForget = async (variables) => {
    return await _mutate(this.client, UPDATE_AFTER_FORGET, variables);
  };

  _getOrganizations = async (variables, opt) => {
    return await _query(this.client, GET_ORGANIZATIONS, variables, opt);
  };

  _createOrganization = async (variables, opt) => {
    return await _mutate(this.client, CREATE_ORGANIZATION, variables, opt);
  };
}

const DELETE_INVITATION = gql`
  mutation($email: String!) {
    deleteInvitation(email: $email) {
      id
      email
      is_removed
    }
  }
`;

// USER MUTATION
const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      email
      firstname
      lastname
      token
      permissions
      language_code
      organization {
        id
        verified
        name
        permissions
        invitations {
          id
          email
          token
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation(
    $id: Int!
    $firstname: String
    $lastname: String
    $password: String
    $organization_id: Int
  ) {
    updateUser(
      id: $id
      firstname: $firstname
      lastname: $lastname
      password: $password
      organization_id: $organization_id
    ) {
      firstname
      lastname
      permissions
    }
  }
`;

const REGISTER = gql`
  mutation($email: String!) {
    register(email: $email)
  }
`;

const VALIDATE_AND_CREATE_USER = gql`
  mutation(
    $token: String!
    $firstname: String!
    $lastname: String
    $email: String
    $password: String!
    $permissions: Int
  ) {
    validateAndCreateUser(
      token: $token
      firstname: $firstname
      lastname: $lastname
      email: $email
      password: $password
      permissions: $permissions
    ) {
      id
      email
      firstname
      lastname
      token
      permissions
      organization {
        id
        verified
        name
        permissions
        invitations {
          id
          email
          token
        }
      }
    }
  }
`;

const FORGET_PASSWORD = gql`
  mutation($email: String!) {
    forgetPassword(email: $email)
  }
`;

const UPDATE_AFTER_FORGET = gql`
  mutation($password: String!, $token: String!) {
    updateAfterForget(password: $password, token: $token)
  }
`;

// USER QUERY
const GET_USER = gql`
  query getUser($id: Int, $email: String, $token: String) {
    getUser(id: $id, email: $email, token: $token) {
      id
      email
      firstname
      lastname
      token
      permissions
      bots {
        id
      }
      organization {
        id
        verified
        name
        permissions
        invitations {
          id
          email
          token
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers($organization_id: Int!) {
    getUsers(organization_id: $organization_id) {
      users {
        id
        email
        firstname
        lastname
        permissions
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query getAllUsers($organization_id: Int!) {
    getUser {
      id
      firstname
      lastname
      email
      permissions
    }
    getUsers(organization_id: $organization_id) {
      users {
        id
        email
        firstname
        lastname
        permissions
      }
    }
    getOrganization(id: $organization_id) {
      invitations {
        id
        email
        token
        is_removed
      }
    }
  }
`;

const REVOKE_FROM_ORG = gql`
  mutation($user_id: Int!, $bot_id: Int!) {
    revokeFromOrg(user_id: $user_id, bot_id: $bot_id) {
      id
    }
  }
`;

const CREATE_INVITATION = gql`
  mutation($email: String!, $permission: Int, $organization_id: Int!) {
    createInvitation(
      email: $email
      organization_id: $organization_id
      permission: $permission
    ) {
      id
      token
    }
  }
`;

const GET_INVITATION = gql`
  query($token: String) {
    getInvitation(token: $token) {
      language_code
    }
  }
`;

const UPDATE_STATUS = gql`
  mutation($user_id: Int!, $status: String!) {
    updateStatus(user_id: $user_id, status: $status) {
      id
      permissions
    }
  }
`;

const GET_ORGANIZATIONS = gql`
  query getOrganizations {
    getOrganizations {
      id
      name
    }
  }
`;

const CREATE_ORGANIZATION = gql`
  mutation($name: String!) {
    createOrganization(name: $name) {
      id
      name
    }
  }
`;

export default User;
