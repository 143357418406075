import React, { useState, useEffect } from 'react';
import Loader from '../../../../components/Loader';

const month1 = [
  'Janvier',
  'Fevrier',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Aout',
  'Septembre',
  'Octobre',
  'Novembre',
  'Decembre',
];

const period = (item) => {
  let res = '';
  for (var i = 0; month1.length >= i; i++) {
    if (item[1].includes(0)) {
      item[1] = item[1][1];
    }
    if (i === parseInt(item[1])) {
      res = `${item[2]} ${month1[i - 1]} ${item[0]}`;
      return res;
    }
  }
}

const Oh = (props) => {
  const { date, hours, w } = props;

  const [array, setArray] = useState(null);

  useEffect(() => {
    let ohFinal = '';
    date.forEach((item, k) => {
      let result = '';
      let resStart = item.startDate.split('-');

      let resEnd = '';
      if (item.endDate) {
        resEnd = item.endDate.split('-');
      }
      if (resStart) {
        result = period(resStart);
      }
      if (resEnd) {
        result = result.concat(` ${w.generic.at} `, period(resEnd));
      }
      if (result) {
        ohFinal =
          ohFinal === ''
            ? ohFinal.concat(' ', result)
            : ohFinal.concat(' / ', result);
      }
    });
    if (hours.HS && hours.HE) {
      const hourStart = String(hours.HS).concat(':', hours.MS);
      const hourEnd = String(hours.HE).concat(':', hours.ME);
      const res = `${w.generic.from_place} ${hourStart} ${w.generic.hTo} ${hourEnd}`;
      ohFinal = ohFinal.concat(' ', res);
    }
    setArray(ohFinal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  if (!array) {
    return <Loader />;
  } else {
    return <div>{array}</div>;
  }
}

export default Oh;
