import React from 'react';
import { Button, Input } from '@ask-mona/ui';
import { _sendFeedback } from '../../_tools/ui';

export const sections = [
  {
    id: 0,
    name: { fr: 'Contenu du push', en: 'Push content' },
    icon: 'fas fa-info',
  },
  {
    id: 1,
    name: { fr: 'Définir les cibles du push', en: 'Define the push targets' },
    icon: 'fas fa-user',
  },
];

export const TitlePush = ({ w, fct, save, errorTitle, state, status }) => (
  <div id="current-header" className="flex row p-md">
    <div
      className={
        !status || save
          ? 'flex row space-around disabled'
          : 'flex row space-around'
      }
      style={!status || save ? { opacity: '.5' } : { opacity: '1' }}
    >
      <h3 className="has-text-primary-ter">{w.push.title}*</h3>
      <Input
        color={errorTitle ? 'danger' : ''}
        onChange={(e) => fct.onChangeContent(e.target.value, 'title')}
        placeholder={w.push.placeholderNew}
        value={
          state.variables.contentPush.title
            ? state.variables.contentPush.title
            : ''
        }
      />
    </div>
  </div>
);

export const Head = ({ w, fct, save, array, id, finalDate, status }) => (
  <div className="m-l-md flex row grow-1 flex-end">
    {!status && finalDate && (
      <div
        className={
          !status
            ? 'flex row center has-text-primary-ter disabled'
            : 'flex row center has-text-primary-ter'
        }
        style={{ width: '65%', alignItems: 'center' }}
      >
        <p style={{ marginRight: '6px' }}>{finalDate}</p>
      </div>
    )}
    {status && finalDate && (
      <div
        style={{ width: '65%', alignItems: 'center' }}
        className="flex row center has-text-primary-ter"
      >
        <p style={{ marginRight: '6px' }}>{finalDate}</p>
        <i
          style={{ marginRight: '6px' }}
          onClick={() => fct.toggleModalTime()}
          className="fas fa-pencil-alt fa-xs"
        ></i>
        <i
          onClick={() => fct.deleteDate()}
          className="fas fa-trash-alt fa-xs"
        ></i>
      </div>
    )}
    {status && !finalDate && (
      <div style={{ width: '65%' }} className="flex row center">
        <Button
          className="m-l-xs"
          color="primary-bis"
          onClick={() => fct.toggleModalTime()}
        >
          {w.push.scheduleDate}
        </Button>
      </div>
    )}
    {status && save && (
      <div style={{ width: '60%' }} className="flex row">
        <Button style={{ marginRight: '5px' }} onClick={() => fct.saveDraft()}>
          {w.generic.draft}
        </Button>
        <Button
          style={{ marginRight: '5px' }}
          onClick={() => fct.deleteContentPush()}
        >
          {w.generic.edit}
        </Button>
        {finalDate && (
          <Button onClick={() => fct.toggleModalProg()}>
            {w.generic.schedule}
          </Button>
        )}
        {!finalDate && (
          <Button onClick={() => fct.toggleModalProg()}>
            {w.generic.send}
          </Button>
        )}
      </div>
    )}
    {status && array && !save && id === 'new' && (
      <div style={{ width: '35%' }} className="flex row center">
        <Button className="m-l-xs" onClick={() => fct.saveContentPush()}>
          {w.generic.saveContent}
        </Button>
      </div>
    )}
    {status && array && !save && id !== 'new' && (
      <div style={{ width: '35%' }} className="flex row center">
        <Button className="m-l-xs" onClick={() => fct.updateContentPush()}>
          {w.generic.saveContent}
        </Button>
      </div>
    )}
    {!status && (
      <div style={{ width: '35%' }} className="flex row center">
        <Button className="m-l-xs" onClick={() => fct.saveDraft()}>
          {w.generic.back}
        </Button>
      </div>
    )}
  </div>
);

export const deleteEltArray = (array) => {
  array.forEach((a, i) => {
    if (a.name === 'Texte' && a.content.text === '') array.splice(i, 1);
    else if (a.name === 'Image' && a.content.attachment.payload.url === '')
      array.splice(i, 1);
    else if (a.name === 'Vidéo' && a.content.attachment.payload.url === '')
      array.splice(i, 1);
  });
  return array;
};

export const checkArray = (array) => {
  array.forEach((a, i) => {
    if (a.name === 'Texte' && a.content.text === '') {
      _sendFeedback('warning', 'Votre contenu texte est incomplet');
      return false;
    } else if (a.name === 'Image' && a.content.attachment.payload.url === '') {
      _sendFeedback('warning', 'Votre contenu image est incomplet');
      return false;
    } else if (a.name === 'Vidéo' && a.content.attachment.payload.url === '') {
      _sendFeedback('warning', 'Votre contenu vidéo est incomplet');
      return false;
    }
  });
  return true;
};
