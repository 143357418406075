import React, { Component } from 'react';
import ScheduleAlert from './ScheduleAlert';
import { Textarea } from '@ask-mona/ui';
import { withMain } from '../../../hoc/main';

class CreateAlert extends Component {
  render() {
    const {
      fct,
      periods,
      error,
      bool,
      allDates,
      hour,
      display,
      contentFR,
      m: { w },
    } = this.props;

    return (
      <div className="flex row border-dashed has-background-lighter">
        <div className="m-xs w-4">
          <p className="is-size-6 has-text-weight-light m-b-xs">
            {w.alertMessages.content}
          </p>
          <Textarea
            placeholder={w.alertMessages.addContent}
            className={error.content ? 'createAlert__danger' : ''}
            resize={'none'}
            rows="8"
            onChange={fct.changeContent}
            value={contentFR ? contentFR : ''}
          />
        </div>
        <div className="p-xs w-6 border-left-dashed">
          <p className="is-size-6 has-text-weight-light m-b-xs">
            {w.alertMessages.scheduleAlert}
          </p>
          <ScheduleAlert
            date={bool.date}
            period={bool.period}
            fct={fct}
            addDate={fct.addDate}
            addPeriod={fct.addPeriod}
            error={error}
            periods={periods}
            udpate={false}
            allDates={allDates}
            hour={hour}
            display={display}
          />
        </div>
      </div>
    );
  }
}

export default withMain(CreateAlert);
