import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { _random } from '../_tools/other';
import { withGlobals } from '../hoc/globals';
import { SingleDatePicker } from 'react-dates';

class SingleDate extends Component {
  state = {
    date: this.props.defaultDate || null,
    focused: null,
  };

  dateChange = (date) => {
    const { onChange, name } = this.props;
    this.setState({ date }, () => {
      onChange && onChange({ date, name });
    });
  };

  onFocusChange = ({ focused }) => {
    const { onFocus } = this.props;
    onFocus && onFocus(focused);
    this.setState({ focused });
  };

  render() {
    const {
      g: { lang },
      onChange,
      onFocus,
      defaultDate,
      name,
      date_min,
      date_max,
      ...other
    } = this.props;
    let firstVisibleMonth;
    if (this.props.firstVisibleMonth) {
      firstVisibleMonth = moment(this.props.firstVisibleMonth);
    } else {
      firstVisibleMonth = moment();
    }
    moment.locale(lang);

    return (
      <SingleDatePicker
        date={this.state.date} // momentPropTypes.momentObj or null
        onDateChange={this.dateChange} // PropTypes.func.isRequired
        focused={this.state.focused} // PropTypes.bool
        initialVisibleMonth={() => firstVisibleMonth}
        onFocusChange={this.onFocusChange} // PropTypes.func.isRequired
        id={_random()} // PropTypes.string.isRequired,s
        numberOfMonths={1}
        isDayHighlighted={(day) => day.isSame(moment(), 'day')}
        isOutsideRange={(date) => {
          if (date_min && date_max)
            return !(date >= date_min && date <= date_max);
          else if (date_min) return !(date >= date_min);
          else if (date_max) return !(date <= date_max);
          else return false;
        }}
        hideKeyboardShortcutsPanel
        {...other}
      />
    );
  }
}

export default withGlobals(SingleDate);
