import React from 'react';
import { Icon } from '@ask-mona/ui';

export default ({ small, src, ...props }) => (
  <Icon
    className={`
      rad-x
      border-grey-lighter
      contain
      ${small ? 'is-24x24' : 'is-80x80'}
    `}
    {...props}
  />
);
