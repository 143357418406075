const Caroussels = (template) => {
  switch (template) {
    case 'template1':
      return {
        v2: true,
        borderFirstChild: false,
        buttons: {
          first: true,
          width: '90%',
          shadow: '',
          icon: false,
          boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
          justifyContent: 'center'
        },
        generic: {
          imgCover: false,
          marginImg: true,
          marginTitle: '',
        }
      }
    case 'template2':
      return {
        v2: true,
        borderFirstChild: false,
        buttons: {
          first: true,
          width: '70%',
          shadow: '',
          icon: false,
          boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
          justifyContent: 'center'
        },
        generic: {
          imgCover: false,
          marginImg: true,
          marginTitle: '',
        }
      }
    case 'template3':
      return {
        v2: true,
        generic: {
          imgCover: true,
          marginImg: false,
          marginTitle: '',
        },
        borderFirstChild: false,
        buttons: {
          first: true,
          width: '90%',
          shadow: '',
          icon: false,
          boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
          justifyContent: 'center'
        },
      }
    case 'template6':
      return {
        v2: true,
        borderFirstChild: true,
        buttons: {
          first: false,
          width: '',
          shadow: '',
          icon: true,
          boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
          justifyContent: 'center',
        },
        generic: {
          imgCover: false,
          marginImg: true,
          marginTitle: '',
        }
      }
    case 'template7':
      return {
        v2: true,
        borderFirstChild: false,
        buttons: {
          first: false,
          width: '',
          shadow: '',
          icon: true,
          boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
          justifyContent: 'center',
        },
        generic: {
          imgCover: false,
          marginImg: true,
          marginTitle: '',
        }
      }
      case 'template8':
        return {
          v2: true,
          borderFirstChild: false,
          buttons: {
            first: false,
            width: '',
            shadow: '',
            icon: true,
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            justifyContent: 'space-between',
          },
          generic: {
            imgCover: true,
            marginImg: false,
            marginTitle: '',
          }
        }
    default:
      break;
  }
};

export default Caroussels;
