export default {
  first: {
    default: {
      lang: 'Quelle langue voulez-vous que je parle ?',
      begin: 'Comment puis-je vous aider ?',
    },
    en: {
      lang: 'What language do you want me to speak ?',
      begin: 'How can I help you ?',
    },
    it: {
      begin: 'Come posso aiutarla ?',
      lang: 'Che lingua vuoi che ti parli ?',
    },
  },
  ANALYTICS: {
    default: {
      begin_did: '',
      did: 'de gens on fait le même choix que toi.',
      begin_didnt: '',
      didnt: 'de gens on fait le choix inverse.',
    },
    en: {
      begin_did: '',
      did: 'of people did the same choice as you.',
      begin_didnt: '',
      didnt: 'of people did the other choice.',
    },
  },
  'p:share': {
    default: {
      result: {
        image_url: '',
        cta_share: 'Partager le chatbot',
        title_shared: 'Discuter avec ',
      },
    },
    en: {
      result: {
        image_url: '',
        cta_share: 'Share the bot',
        title_shared: 'Chat with ',
      },
    },
    it: {
      result: {
        image_url: '',
        cta_share: 'Share the bot',
        title_shared: 'Chat with ',
      },
    },
  },
  'p:affluence': {
    default: {
      waiting_time: "minutes d'attente pour",
      ti: 'Il y a actuellement',
      prevision: "Voici les prévisions d'affluences pour",
      begin: "Pour quelle exposition voulez-vous connaître l'affluence ?",
      result: '',
      error: "Oups, j'ai perdu la connexion avec Affluence...",
    },
    en: {
      waiting_time: 'minutes of waiting for',
      ti: 'There is currently',
      prevision: 'Here are the forecasts for',
      begin: 'For which exhibition would you like to know the peak period ?',
      result: '',
      error: 'Oops, I lost the connection with Affluence ...',
    },
    it: {
      waiting_time: 'minutes of waiting for',
      ti: 'There is currently',
      prevision: 'Here are the forecasts for',
      begin: 'For which exhibition would you like to know the peak period ?',
      result: '',
      error: 'Oops, I lost the connection with Affluence ...',
    },
  },
  'p:new_search': {
    default: {
      begin: 'Comment puis-je vous aider ?',
    },
    en: {
      begin: 'How can I help you ?',
    },
    it: {
      begin: 'How can I help you ?',
    },
  },
  'p:selection': {
    default: {
      error: [
        'Vous n’avez pas de représentation enregistrée dans votre sélection.',
        'Afin de créer votre sélection, je vous invite à cliquez sur “voir les séances”, puis sur le bouton “sauvegarder” dans les cartes événements.',
      ],
      added: [
        'Cette représentation a été ajoutée à votre sélection.',
        'Vous pouvez retrouver et mettre à jour votre sélection de représentations en cliquant sur le bouton "Ma sélection" ou en posant la question.',
      ],
      removed: "L'événement a été retiré de votre sélection.",
      already: "L'événement est déjà dans votre sélection.",
      share:
        'Pour proposer à la personne de votre choix de vous accompagner, je viens de créer une invitation à lui partager :',
      show: "Voici votre sélection d'événements.",
      show_end:
        'Vous pouvez la modifier en ajoutant des événements depuis programmation ou en les retirant ici.',
    },
    en: {
      error: [
        'Your selection is empty.',
        'In order to create your selection, I invite you to click on the save button on the event cards in the programming.',
      ],
      added: [
        'This representation has been added to your selection.',
        'You can find and update your selection of representations by clicking on "My Selection" button or asking the question.',
      ],
      removed: 'The event has been removed from your selection.',
      already: 'The event is already in your selection.',
      share:
        'I have just created an invitation to ask the person of your choice to accompany you:',
      show: 'Here are the events you selected',
      show_end:
        'You can modify it by adding events from programming or removing them here.',
    },
    it: {
      error: [
        'Your selection is empty.',
        'In order to create your selection, I invite you to click on the save button on the event cards in the programming.',
      ],
      added: [
        'This representation has been added to your selection.',
        'You can find and update your selection of representations by clicking on "My Selection" button or asking the question.',
      ],
      removed: 'The event has been removed from your selection.',
      already: 'The event is already in your selection.',
      share:
        'I have just created an invitation to ask the person of your choice to accompany you:',
      show: 'Here are the events you selected',
      show_end:
        'You can modify it by adding events from programming or removing them here.',
    },
  },
  'p:pdc': {
    default: {},
    en: {},
    it: {},
  },
  'p:price': {
    default: {
      free_w: 'gratuit !',
      tarif: 'Ce tarif est ',
      also: 'Mais aussi pour les ',
      valuable: 'Il est valable pour les ',
      valuable_free: "La gratuité s'applique aux ",
      price: "Le prix de l'entrée est de",
      free: '',
      of: 'de',
      full_price: 'Le tarif plein est ',
      result: '',
      question_ticket:
        'Pour quel tarif en particulier souhaitez-vous obtenir cette information ?',
      begin: '',
      ask_tarif:
        'Pour quel type de tarif souhaitez-vous obtenir des renseignements ?',
      select_ticket: 'Quel billet vous intéresse ?',
    },
    en: {
      free_w: 'free !',
      tarif: 'This rate is ',
      also: 'But it is also valid for ',
      valuable: 'It is valid for ',
      price: 'The price of the entrance is',
      valuable_free: 'Free admission applies to ',
      free: '',
      of: '',
      full_price: 'The full rate is ',
      result: '',
      question_ticket:
        'For which type of rate in particular would you like this information ?',
      ask_tarif: 'For which type of rate would you like details ?',
      select_ticket: 'Which ticket are you interested in ?',
      begin: '',
    },
    it: {
      free_w: 'free !',
      tarif: 'This rate is ',
      also: 'But it is also valid for ',
      valuable: 'It is valid for ',
      price: 'The price of the entrance is',
      valuable_free: 'Free admission applies to ',
      free: '',
      of: '',
      full_price: 'The full rate is ',
      result: '',
      question_ticket:
        'For which type of rate in particular would you like this information ?',
      ask_tarif: 'For which type of rate would you like details ?',
      select_ticket: 'Which ticket are you interested in ?',
      begin: '',
    },
  },
  'p:openings': {
    default: {
      opening: 'Voici les horaires d’ouverture',
      begin: 'À quelle date souhaitez-vous venir ?',
      error: "Nous ne proposons pas d'événement à cette dates là.",
    },
    en: {
      opening: 'Here are the opening hours',
      begin: 'When would you like to come?',
      error: 'No event is planned for this date.',
    },
    it: {
      opening: 'Here are the opening hours',
      begin: 'When would you like to come?',
      error: 'No event is planned for this date.',
    },
  },
  'p:prog': {
    default: {
      geoloc:
        'Indique moi le nom de ta ville 🏠 ou clique sur le bouton pour donner ta géolocalisation.',
      intro_wording: true,
      see_more: 'Voir plus',
      filter: 'Filtrer',
      idf: "Je n'ai pas trouvé",
      p_nw:
        'pour la période indiquée mais voici ceux qui auront lieu la semaine prochaine :',
      p_nm: 'pour la période indiquée mais ceux qui auront lieu ce mois-ci :',
      nf: "Malheureusement je n'ai trouvé aucun",
      nf_end: 'correspondant à votre recherche.',
      odon: "Les horaires d'ouverture varient en fonction des",
      odon_end: 'vous pouvez les consulter sur les cartes ci-dessous :',
      on: 'en',
      wao: "mais voilà ce qu'il se passe en",
      tb: "pour aujourd'hui mais voici les",
      fnd: 'dans les prochains jours :',
      wp: 'Nous vous proposons',
      if: "J'ai trouvé",
      fy: 'pour vous :',
      h: 'Voilà',
      l: 'dernier',
      ycs: 'Vous pouvez consulter encore',
      event: 'événement',
      show: 'spectacle',
      filter_show: "Quel type d'événement vous intéresse ?",
      concert: 'événement',
      curation_begin: 'Quand voulez-vous venir ?',
      curation_tag: 'Quelle thématique recherchez-vous ?',
      idf_prefix_event: "d'",
      intro_infos: '',
      begin: [],
    },
    en: {
      geoloc:
        'Tell me the name of your city 🏠 or click on the button to give your geolocation.',
      filter_show: 'What kind of event do you want to see ?',
      curation_begin: 'When would you like to come ?',
      intro_wording: true,
      see_more: 'See more',
      filter: 'Filter',
      idf: 'I did not find',
      p_nw:
        'for the provided period but you can see what is happenning next week :',
      p_nm:
        'for the provided period but you can see what is happenning this month :',
      nf: 'Unfortunately I did not find any',
      nf_end: 'for your search.',
      odon: 'Opening depends on',
      odon_end: 'but you can see them on the cards below :',
      on: 'on',
      wao: 'but here is what happens on',
      tb: 'for today but here are the',
      fnd: 'in the next days :',
      wp: 'We suggest you',
      if: 'I found',
      fy: 'for you :',
      h: 'Here',
      l: 'last',
      ycs: 'You can still consult',
      event: 'event',
      show: 'show',
      intro_infos: '',
      concert: 'event',
      curation_tag: 'Which subject are you looking for ?',
      idf_prefix_event: 'any ',
      begin: [],
    },
    it: {
      geoloc:
        'Tell me the name of your city 🏠 or click on the button to give your geolocation.',
      filter_show: 'What kind of event do you want to see ?',
      curation_begin: 'When would you like to come ?',
      intro_wording: true,
      see_more: 'See more',
      filter: 'Filter',
      idf: 'I did not find',
      p_nw:
        'for the provided period but you can see what is happenning next week :',
      p_nm:
        'for the provided period but you can see what is happenning this month :',
      nf: 'Unfortunately I did not find any',
      nf_end: 'for your search.',
      odon: 'Opening depends on',
      odon_end: 'but you can see them on the cards below :',
      on: 'on',
      wao: 'but here is what happens on',
      tb: 'for today but here are the',
      fnd: 'in the next days :',
      wp: 'We suggest you',
      if: 'I found',
      fy: 'for you :',
      h: 'Here',
      l: 'last',
      ycs: 'You can still consult',
      event: 'event',
      show: 'show',
      intro_infos: '',
      concert: 'event',
      curation_tag: 'Which subject are you looking for ?',
      idf_prefix_event: 'any ',
      begin: [],
    },
  },
  'p:itin': {
    default: {
      error: "Je n'ai pas compris votre adresse. Pouvez-vous préciser ?",
      no_itin: "Nous n'avons pas d'itinéraire à vous proposer",
      result:
        'Merci ! Vous pouvez afficher votre itinéraire en cliquant sur le bouton ci-dessous.',
      begin:
        'Pouvez-vous m\'indiquer votre adresse en cliquant sur "Envoyer la position" ?',
      begin_wc:
        "Pouvez-vous m'envoyer votre adresse afin de vous proposer un itinéraire ?",
    },
    en: {
      no_itin: 'We have no itinerary for you',
      error:
        'I did not understand you address. Could you please be more specific ?',
      begin:
        'Could you indicate your location by clicking on the button below ?',
      result:
        'Thank you ! You can consult your itinerary by clicking the button below.',
      begin_wc: 'Can you send me your address to suggest an itinerary?',
    },
    it: {
      no_itin: 'We have no itinerary for you',
      error:
        'I did not understand you address. Could you please be more specific ?',
      begin:
        'Could you indicate your location by clicking on the button below ?',
      result:
        'Thank you ! You can consult your itinerary by clicking the button below.',
      begin_wc: 'Can you send me your address to suggest an itinerary?',
    },
  },
  _LOCATION: {
    default: {
      result:
        'Merci ! Vous pouvez afficher votre itinéraire en cliquant sur le bouton ci-dessous.',
    },
    en: {
      result: 'Thanks ! You can consult your itinerary here !',
    },
  },
  'p:disabled': {
    default: {
      result: {
        image_url: '',
        title: 'Handicapé',
        subtitle: 'Information à destination des handicapés',
      },
    },
    en: {
      result: {
        image_url: '',
        title: 'Disabled persons',
        subtitle: 'Information for the disabled',
      },
    },
    it: {
      result: {
        image_url: '',
        title: 'Disabled persons',
        subtitle: 'Information for the disabled',
      },
    },
  },
  'p:talk_to_human': {
    default: {
      result: "Merci, une personne de l'équipe va vous répondre au plus vite.",
    },
    en: {
      result: 'Thank you, a member of the team will come back to you shortly.',
    },
    it: {
      result: 'Thank you, a member of the team will come back to you shortly.',
    },
  },
  'p:human_message': {
    default: {
      result: "Merci, une personne de l'équipe va vous répondre au plus vite.",
    },
    en: {
      result: 'Thank you, a member of the team will come back to you shortly.',
    },
    it: {
      result: 'Thank you, a member of the team will come back to you shortly.',
    },
  },
  IMAGE: {
    default: {},
    en: {},
  },
  LANGUAGE: {
    default: {
      result: 'Très bien, je vous parlerai français désormais !',
    },
    en: {
      result: "Very well, I'll speak to you in english until now !",
    },
  },
  FB: {
    default: {
      tth: 'Parler à un humain',
      fail_payload: 'Que puis-je faire pour vous ? !',
      weather: 'Et voilà ;)',
      weather_card: 'Météo',
      fail: "Je n'ai pas compris votre demande.",
      fail_first:
        "Ça alors, ça fait beaucoup d'un coup... 🤔Pouvez-vous reformuler avec une phrase plus courte ?",
    },
    en: {
      tth: 'Talk to human',
      fail_payload: 'What can I do for you ?',
      weather: 'Here you go ;)',
      weather_card: 'Weather',
      fail: 'I did not understand your request.',
      fail_first:
        'Could you help me by rephrasing your request in a shorter sentence ?',
    },
  },
};
