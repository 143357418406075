export default {
  checklist: ['Checklist', 'Checklist'],
  textAnalytics: [
    "Choisir les analytics que vous souhaitez mettre en avant sur votre page d'accueil : (5 maximum)",
    'Choose the analytics you want to highlight on your homepage: (5 maximum)',
  ],
  evtsPublished: ['Événements publiés', 'Published events'],
  evtsUnpublished: ['Événements non publiés', 'Unpublished events'],
  messages: ['Messages en attente', 'Pending messages'],
  nbUsers: ["Nombre d'utilisateurs", 'Number of users'],
  duration: ["Durée moyenne d'une session", 'Average length of a session'],
  nbSessions: [
    'Nombre moyen de sessions par utilisateur',
    'Average number of sessions per user',
  ],
  nbUsersFr: [
    "Pourcentage d'utilisateurs francophones",
    'Percentage of French speaking users',
  ],
  nbClicEvts: [
    'Nombre de clics sur les événements',
    'Number of clicks on events',
  ],
  refresh: ['Rafraichir', 'Refresh'],
};
