import React from 'react';
import Content from '../../components/scenario/Content';
import FunctionalitiesSubsbar from '../../components/scenario/FunctionalitiesSubsbar';

export default (props) => {
  const { state, dispatch } = props;

  return (
    <div
      className="has-text-primary-ter h-100p w-100p relative space-nowrap overflow-hidden"
      style={{ display: 'flex' }}
    >
      {/* <Sidebar {...props} {...state} dispatch={dispatch} /> */}
      <FunctionalitiesSubsbar {...props} {...state} dispatch={dispatch} />
      <Content {...props} {...state} dispatch={dispatch} />
    </div>
  );
};
