import React from 'react';
import styled from 'styled-components';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#2D2F7B' },
    secondary: { main: '#fff' },
  },
});

const SearchBar = (props) => {
  return (
    <>
      {' '}
      <MuiThemeProvider theme={theme}>
        <SearchLayout height={props.height}>
          <SearchInput
            type="text"
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange}
          />
          <SearchButton disabled="true">
            <SearchIcon>
              <SearchRoundedIcon className={{ fontSize: 15 }} color="primary" />
            </SearchIcon>
          </SearchButton>
        </SearchLayout>
      </MuiThemeProvider>
    </>
  );
};

export default SearchBar;

const SearchLayout = styled.form`
  /* flex: 0 0 40%; */

  display: flex;
  align-items: center;
  justify-content: center;
  width: 218px;
  height: ${(p) => (p.height ? `${p.height}px` : `44px`)};

  margin-left: -8px;
  border-radius: 16px;
  border: 1px solid rgba(45, 47, 123, 0.24);
  border-left: none;

  /* box-shadow: 0px 14px 24px 0px rgba(133, 118, 118, 0.05),
    0px 0px 24px 0px rgba(45, 47, 123, 0.09); */

  @media (max-width: 1400px) {
    width: 200px;
    /* margin-left: 12px; */
  }
  @media (max-width: 1200px) {
    width: 180px;
    /* margin-left: 4px; */
  }
  @media (max-width: 1000px) {
    width: 160px;
    /* margin-left: 0px; */
  }
`;

const SearchInput = styled.input`
  font-size: 16px;
  font-weight: 500;
  color: ${(p) => p.theme.colors.primary};
  background-color: white;
  padding: 0.7rem 2rem;
  padding-right: 2.25rem;
  border-radius: 16px;
  border: 1px solid rgba(45, 47, 123, 0.24);
  box-shadow: 0 2px 5px 0px #ececec;
  width: 90%;
  transition: all 0.2s;
  margin-right: -2.5rem;
  /* box-shadow: 10px 10px 20px #ececec, 10px 10px 20px #ececec; */

  &:focus {
    outline: none;
    border: 2px solid rgba(45, 47, 123, 0.24);
  }

  &::-webkit-input-placeholder {
    font-weight: 100;
    color: ${(p) => p.theme.colors.primary};
  }
`;

const SearchButton = styled.button`
  border: none;
  background-color: white;
`;

const SearchIcon = styled.svg`
  height: 2rem;
  width: 2rem;
`;
