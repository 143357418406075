import gql from 'graphql-tag';
import { _mutate, _query } from './client';

class Mediation {
  constructor(client) {
    this.client = client;
  }

  _getArtwork = async (variables, opt) => {
    return await _query(this.client, GET_ARTWORK, variables, opt);
  };

  _updateArtwork = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_ARTWORK, variables, opt);
  };

  _setArtworkTranslation = async (variables, opt) => {
    return await _mutate(this.client, SET_ARTWORK_TRANSLATION, variables, opt);
  };

  _createArtwork = async (variables, opt) => {
    return await _mutate(this.client, CREATE_ARTWORK, variables, opt);
  };

  _deleteArtwork = async (variables, opt) => {
    return await _mutate(this.client, DELETE_ARTWORK, variables, opt);
  };

  _formatVariables = (variables) => {
    const { tags, authors, ...args } = variables;
    let newArg = args;
    if (tags) newArg = { ...newArg, tags: tags.map((p) => p.id) };
    if (authors) newArg = { ...newArg, authors: authors.map((p) => p.id) };
    return newArg;
  };

  _syncArtworkFiles = async (variables, opt) => {
    return await _mutate(this.client, SYNC_ARTWORK_FILES, variables, opt);
  };
}

export default Mediation;

const CREATE_ARTWORK = gql`
  mutation($mediation_id: Int!, $slug: String!) {
    createArtwork(mediation_id: $mediation_id, slug: $slug) {
      id
    }
  }
`;

const DELETE_ARTWORK = gql`
  mutation($id: Int!) {
    deleteArtwork(id: $id)
  }
`;

const GET_ARTWORK = gql`
  query($id: Int, $languages: [String]) {
    getArtwork(id: $id, languages: $languages) {
      id
      cover_image
      translations {
        language {
          id
          code
        }
        name
      }
      authors {
        id
        slug
        translations {
          id
          language {
            id
            code
            name
          }
          firstname
          lastname
        }
      }
      images {
        id
        path
        filename
      }
      tags {
        id
        name
      }
      slug
    }
  }
`;

const UPDATE_ARTWORK = gql`
  mutation(
    $id: Int!
    $authors: [Int!]
    $tags: [Int]
    $cover_image: String
    $slug: String
  ) {
    updateArtwork(
      id: $id
      authors: $authors
      tags: $tags
      cover_image: $cover_image
      slug: $slug
    ) {
      id
    }
  }
`;

const SET_ARTWORK_TRANSLATION = gql`
  mutation($artwork_id: Int!, $language_id: Int!, $name: String!) {
    setArtworkTranslation(
      artwork_id: $artwork_id
      language_id: $language_id
      name: $name
    ) {
      name
    }
  }
`;

const SYNC_ARTWORK_FILES = gql`
  mutation($id: Int!) {
    syncArtworkFiles(id: $id)
  }
`;
