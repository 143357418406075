export default {
  dates: ['Dates', 'Dates'],
  place: ['Lieu', 'Location'],
  state: ['Statut', 'Status'],
  categorie: ['Catégorie', 'Category'],
  published: ['Événement publié', 'Published event'],
  unpublished: ['Événement non-publié', 'Unpublished event'],
  finished: ['Événement terminé', 'Event completed'],
  publish: ['Publication', 'Publication'],
  noTitle: ['Pas de titre', 'No title'],
  noCategorie: ['Pas de catégorie', 'No category'],
  deleteConfirmation: [
    'Souhaitez-vous vraiment supprimer cet événement ?',
    'Do you really want to delete this event?',
  ],
};
