import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Icon } from '@ask-mona/ui';
import { useClickInside } from '../hooks/useClickInside';

export default ({ isOpen, close, selector, children, clickinside = false }) => {
  const element = document.querySelector(selector);
  const dp = React.createRef();
  const [inside, setInside] = useClickInside(dp);

  useEffect(() => {
    setInside(true);
  });
  useEffect(() => {
    if (clickinside && !inside) close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inside, close]);

  if (!isOpen || !element) return null;

  return ReactDOM.createPortal(
    <div className="modal absolute top left flex center items-center">
      <span className="has-text-white absolute top right p-md pointer">
        <Icon icon="fas fa-times" onClick={close} />
      </span>
      <div ref={dp} className="mh-90p overflow-scroll">{children}</div>
    </div>,
    element
  );
};
