import gql from 'graphql-tag';
import { _mutate, _query } from './client';

class Alert {
  constructor(client, id) {
    this.client = client;
    this.id = id;
    this.hasCanceled = false;
  }

  _getAlertMessages = async (opt) => {
    return await _query(
      this.client,
      GET_ALERT_MESSAGES,
      { bot_id: this.id },
      opt
    );
  };

  _updateAlertMessages = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_ALERT_MESSAGES, variables, opt);
  };

  _createAlertMessages = async (variables, opt) => {
    return await _mutate(this.client, CREATE_ALERT_MESSAGES, variables, opt);
  };

  _deleteAlertMessages = async (variables, opt) => {
    return await _mutate(this.client, DELETE_ALERT_MESSAGES, variables, opt);
  };
}

export const GET_ALERT_MESSAGES = gql`
  query($bot_id: Int!) {
    getAlertMessages(bot_id: $bot_id) {
      id
      content {
        fr
        en
      }
      opening_hours
    }
  }
`;

export const UPDATE_ALERT_MESSAGES = gql`
  mutation(
    $bot_id: Int!
    $id: String!
    $fr: [String]
    $en: [String]
    $opening_hours: String
  ) {
    updateAlertMessages(
      bot_id: $bot_id
      id: $id
      fr: $fr
      en: $en
      opening_hours: $opening_hours
    )
  }
`;

export const CREATE_ALERT_MESSAGES = gql`
  mutation(
    $bot_id: Int!
    $fr: [String]
    $en: [String]
    $opening_hours: String
  ) {
    createAlertMessages(
      bot_id: $bot_id
      fr: $fr
      en: $en
      opening_hours: $opening_hours
    )
  }
`;

export const DELETE_ALERT_MESSAGES = gql`
  mutation($bot_id: Int!, $id: String!) {
    deleteAlertMessages(bot_id: $bot_id, id: $id)
  }
`;

export default Alert;
