import React from 'react';
import { Checkbox } from '@ask-mona/ui';

function CheckboxBool(props) {
  const { classname, label, bool, data, onCreate, onDelete, disabled } = props;

  const createClick = (data) => {
    onCreate(data);
  };

  const deleteClick = (data) => {
    onDelete(data);
  };

  return (
    <React.Fragment>
      {bool && disabled && (
        <div className={classname}>
          <Checkbox
            onClick={() => deleteClick(data)}
            label={label}
            defaultChecked
            disabled
          />
        </div>
      )}
      {bool && !disabled && (
        <div className={classname}>
          <Checkbox
            onClick={() => deleteClick(data)}
            label={label}
            defaultChecked
          />
        </div>
      )}
      {!bool && disabled && (
        <div className={classname}>
          <Checkbox onClick={() => createClick(data)} label={label} disabled />
        </div>
      )}
      {!bool && !disabled && (
        <div className={classname}>
          <Checkbox onClick={() => createClick(data)} label={label} />
        </div>
      )}
    </React.Fragment>
  );
}

export default CheckboxBool;
