import React from 'react';

export const Globals = React.createContext();

export const withGlobals = (Component) => {
  return (props) => {
    return (
      <Globals.Consumer>
        {(context) => <Component {...props} g={context} />}
      </Globals.Consumer>
    );
  };
};
