import React, { useState, useEffect } from 'react';
import Client from '../Client';

type Email = {
  id: number;
  bot_id: number;
  email: string;
  chat_notification_disabled: boolean;
};

type Emails = Array<Email>;

const useEmails = (botId: number, token: string) => {
  const [emails, setEmails] = useState<Emails>([]);
  const [loading, setLoading] = React.useState<string>('');
  const [error, setError] = useState<any>();

  let client = new Client(`${process.env.REACT_APP_HTTP_LINK_V3}`, token);

  useEffect(() => {
    const getEmails = async () => {
      try {
        if (client) {
          let api = client.EmailRecipient;
          let res = await api.getEmails({ bot_id: botId });
          if (res) setEmails(res.data.getEmailRecipients.emailRecipients);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading('load');
      }
    };
    getEmails();
  }, []);

  const createEmail = async (email: string) => {
    try {
      if (client) {
        setLoading('loading');
        let api = client.EmailRecipient;
        let res = await api.createEmail({ bot_id: botId, email });
        if (res) setEmails([...emails, res.data.createEmailRecipient]);
      }
    } catch (err) {
      setError(err);
      setLoading('error');
    } finally {
      setLoading('loaded');
    }
  };

  const updateEmail = async (email: Email) => {
    try {
      if (client && email) {
        setLoading('loading');
        let api = client.EmailRecipient;
        let res = await api.updateEmail({
          id: email.id,
          email: email.email,
          chat_notification_disabled: email.chat_notification_disabled,
        });
        if (res) {
          let newArr = [...emails];
          const index = newArr.findIndex((el) => el.id === email.id);
          newArr[index] = res.data.updateEmailRecipient;
          setEmails(newArr);
        }
      }
    } catch (err) {
      setError(err);
      setLoading('error');
    } finally {
      setLoading('loaded');
    }
  };

  const deleteEmail = async (id: number) => {
    try {
      if (client && id) {
        setLoading('loading');
        let api = client.EmailRecipient;
        let res = await api.deleteEmail({
          id,
        });
        if (res) {
          let newArr = [...emails];
          const index = newArr.findIndex((email) => email.id === id);
          newArr.splice(index, 1);
          setEmails(newArr);
        }
      }
    } catch (err) {
      setError(err);
      setLoading('error');
    } finally {
      setLoading('loaded');
    }
  };

  return {
    emails,
    loading,
    error,
    createEmail,
    deleteEmail,
    updateEmail,
  };
};

export default useEmails;
