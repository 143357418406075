import React, { Component } from 'react';
import Option from './tag/Option';
import { Input, Button } from '@ask-mona/ui';
import { withMain } from '../../../../hoc/main';
import { timestampToDate } from '../../../../_tools/other';
import moment from 'moment';
import Loader from '../../../../components/Loader';
//API
import {
  _associateTagPreset,
  _associateTagPresetSuggest,
} from '../../../../_api/push';

function _alreadyExist(arr, item) {
  for (var i = 0; arr.length >= i; i++) {
    if (arr[i] === item) {
      return true;
    }
  }
  return false;
}
class Tab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tag: null,
      botID: props.m.bot.id,
      activate: false,
      edit: false,
      sign: null,
      nbUser: null,
      session: null,
      period: null,
      startDate: null,
      date: null,
      checkDate: false,
      lang: null,
      gender: null,
      event: '',
      feature: null,
      allFeatures: [],
      resGender: null,
      resLang: null,
      resSession: null,
      resDate: null,
      resAllFeatures: [],
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.changeSession = this.changeSession.bind(this);
    this.changeStartDate = this.changeStartDate.bind(this);
    this.selectGender = this.selectGender.bind(this);
    this.selectLang = this.selectLang.bind(this);
    this.selectFeatures = this.selectFeatures.bind(this);
    this.delete = this.delete.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const { arrTags } = this.props;
    let {
      session,
      date,
      lang,
      allFeatures,
      gender,
      resLang,
      resGender,
      resSession,
      resDate,
      resAllFeatures,
    } = this.state;
    arrTags.forEach((tag) => {
      if (tag.error) {
        return null;
      } else {
        const name = tag.name.split('-');
        if (name[0].trim() === 'lang') {
          lang = name[1].trim() === 'fr_FR' ? 'Français' : 'Anglais';
          resLang = tag;
        } else if (name[0].trim() === 'gender') {
          gender = name[1].trim() === 'female' ? 'Femme' : 'Homme';
          resGender = tag;
        } else if (name[0].trim() === 'session') {
          session = name[1].trim();
          resSession = tag;
        } else if (name[0].trim() === 'date') {
          const dateFinal = timestampToDate(name[1].trim());
          moment.locale('fr');
          const d = moment(dateFinal).format('L').split('/');
          date = d[2] + '-' + d[1] + '-' + d[0];
          resDate = tag;
        } else if (tag.name) {
          allFeatures.push(tag.name);
          resAllFeatures.push(tag);
        }
        this.setState({
          lang,
          gender,
          session,
          date,
          allFeatures,
          resGender,
          resLang,
          resSession,
          resDate,
          resAllFeatures,
        });
      }
    });
  }

  toggleEdit() {
    const { edit } = this.state;
    if (!edit) this.setState({ edit: true });
    else this.setState({ edit: false });
  }

  selectTag(data) {
    const { fct } = this.props;
    fct.selectAllTags(data);
  }

  async associateTagPreset(name, data) {
    let { botID, resGender, resLang, resSession, resDate } = this.state;
    const d = await _associateTagPreset(botID, name, data);
    await fetch(d.url, d.req)
      .then((res) => res.json())
      .then((data) => {
        const name = data.result.name.split('-');
        if (name[0].trim() === 'lang') {
          resLang = data.result;
        }
        if (name[0].trim() === 'gender') {
          resGender = data.result;
        }
        if (name[0].trim() === 'session') {
          resSession = data.result;
        }
        if (name[0].trim() === 'date') {
          resDate = data.result;
        }
        this.setState({ resGender, resLang, resSession, resDate });
        this.selectTag(data.result);
      });
  }

  changeSession(value, name) {
    const { sign, nbUser } = this.state;
    if (name === 'sign') this.setState({ sign: value });
    else if (name === 'number') this.setState({ nbUser: value });
    else if (name === 'check' && sign && nbUser) {
      const data = sign === '-' ? sign + nbUser : nbUser;
      this.associateTagPreset('session', data);
      this.setState({ session: data });
    }
  }

  changeStartDate(value, name) {
    const { period, dateStart } = this.state;
    if (name === 'period') this.setState({ period: value, checkDate: false });
    else if (name === 'date')
      this.setState({ dateStart: value, checkDate: false });
    else if (name === 'check' && period && dateStart) {
      const sign = period === '+' ? '' : period;
      const myDate = dateStart.split('-');
      const newDate = myDate[1] + '/' + myDate[2] + '/' + myDate[0];
      const timestamp = sign + new Date(newDate).getTime() / 1000;
      this.associateTagPreset('date', timestamp);
      this.setState({ date: dateStart, checkDate: true });
    }
  }

  selectGender(item) {
    const data = item.value === ('Femme' || 'Female') ? 'female' : 'male';
    this.associateTagPreset('gender', data);
    this.setState({ gender: item.value });
  }

  selectLang(item) {
    const data = item.value === 'Français' ? 'fr_FR' : 'en_EN';
    this.associateTagPreset('lang', data);
    this.setState({ lang: item.value });
  }

  async selectFeatures(item) {
    const { allFeatures, botID } = this.state;
    let arr = allFeatures;

    const d = await _associateTagPresetSuggest(botID, item.value);
    await fetch(d.url, d.req)
      .then((res) => res.json())
      .then((data) => {
        this.selectTag(data.result);
      });

    if (!_alreadyExist(arr, item.value)) arr.push(item.value);

    this.setState({ allFeatures: arr });
  }

  delete(id, i) {
    const { allFeatures } = this.state;
    switch (id) {
      case 0:
        this.setState({ gender: null });
        break;
      case 1:
        this.setState({ date: null });
        break;
      case 2:
        this.setState({ session: null });
        break;
      case 3:
        this.setState({ lang: null });
        break;
      case 4:
        allFeatures.splice(i, 1);
        this.setState({ allFeatures: allFeatures });
        break;
      default:
        break;
    }
  }

  option(id, items, suggest, fct, preset) {
    const {
      tag,
      allFeatures,
      gender,
      sign,
      lang,
      session,
      period,
      date,
      checkDate,
      resGender,
      resLang,
      resSession,
      resDate,
    } = this.state;
    const {
      m: { w },
    } = this.props;
    if (preset === null) return <Loader />;
    if (preset !== null) {
      if (id === 0) {
        if (preset.includes('gender')) {
          return (
            <Option
              items={items}
              onSelect={this.selectGender}
              id={id}
              data={gender}
              onDelete={this.delete}
              fct={fct}
              tag={resGender}
            />
          );
        } else {
          return (
            <div className="w-80p p-md has-text-primary-bis">
              {' '}
              {w.push.available}{' '}
            </div>
          );
        }
      }
      if (id === 1) {
        if (preset.includes('created_at')) {
          return (
            <div className="w-80p p-md">
              <div className="flex row w-100p">
                <div
                  style={{ fontSize: '.8rem' }}
                  className="flex row space-around has-text-primary m-r-xs w-10p border-primary-bis"
                >
                  {period === '-' ? (
                    <span className="flex row center items-center w-50p has-background-primary-ter has-text-white pointer">
                      {' '}
                      avant{' '}
                    </span>
                  ) : (
                    <span
                      className="flex row center w-50p has-background-primary-light items-center border-r-primary-bis pointer"
                      onClick={() => this.changeStartDate('-', 'period')}
                    >
                      {' '}
                      avant{' '}
                    </span>
                  )}
                  {period === '+' ? (
                    <span className="flex row center items-center w-50p has-background-primary-ter has-text-white pointer">
                      {' '}
                      après{' '}
                    </span>
                  ) : (
                    <span
                      className="flex row center w-50p has-background-primary-light items-center pointer"
                      onClick={() => this.changeStartDate('+', 'period')}
                    >
                      {' '}
                      après{' '}
                    </span>
                  )}
                </div>
                <input
                  className="dateInput m-r-xs"
                  onChange={(e) => this.changeStartDate(e.target.value, 'date')}
                  type="date"
                />
                <Button
                  color="primary-bis"
                  onClick={() => this.changeStartDate(null, 'check')}
                >
                  <i className="fas fa-check"></i>
                </Button>
                {date && (
                  <div className="flex row center items-center m-l-md w-60p">
                    <div
                      className="p-l-xs p-r-xs flex row space-between border-tag rad-15px has-background-primary-ter has-text-white is-size-7 has-text-weight-semibold m-r-xs"
                      style={{ width: '50%' }}
                    >
                      <span className="m-r-xs has-text-weight-light">
                        {period === '-' && checkDate
                          ? 'Avant le ' + date
                          : 'Du ' + date + " jusqu'à aujourd'hui"}
                      </span>
                      <span
                        onClick={() => {
                          fct.deleteTagsArray(resDate);
                          this.delete(id);
                        }}
                      >
                        <i className="fas fa-times turn-90"></i>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div className="w-80p p-md has-text-primary-bis">
              {' '}
              {w.push.available}{' '}
            </div>
          );
        }
      }
      if (id === 2) {
        if (preset.includes('sessions')) {
          return (
            <div className="w-80p p-md">
              <div className="flex row w-100p">
                <div
                  style={{ fontSize: '1.3rem' }}
                  className="flex row space-around has-text-primary m-r-xs w-10p border-primary-bis"
                >
                  {sign === '-' ? (
                    <span className="flex row center items-center w-50p has-background-primary-ter has-text-white pointer">
                      {' '}
                      {`-`}{' '}
                    </span>
                  ) : (
                    <span
                      className="flex row center w-50p has-background-primary-light items-center border-r-primary-bis pointer"
                      onClick={() => this.changeSession('-', 'sign')}
                    >
                      {' '}
                      {`-`}{' '}
                    </span>
                  )}
                  {sign === '+' ? (
                    <span className="flex row center items-center w-50p has-background-primary-ter has-text-white pointer">
                      {' '}
                      {`+`}{' '}
                    </span>
                  ) : (
                    <span
                      className="flex row center w-50p has-background-primary-light items-center pointer"
                      onClick={() => this.changeSession('+', 'sign')}
                    >
                      {' '}
                      {`+`}{' '}
                    </span>
                  )}
                </div>
                <div className="m-r-xs w-20p">
                  <Input
                    onChange={(e) =>
                      this.changeSession(e.target.value, 'number')
                    }
                    type="number"
                  />
                </div>
                <Button
                  color="primary-bis"
                  onClick={() => this.changeSession(null, 'check')}
                >
                  <i className="fas fa-check"></i>
                </Button>
                {session && (
                  <div className="flex row center items-center m-l-md w-60p">
                    <div className="p-l-xs p-r-xs flex row space-between border-tag rad-15px has-background-primary-ter has-text-white is-size-7 has-text-weight-semibold m-r-xs">
                      <span className="m-r-xs has-text-weight-light">
                        {session}
                      </span>
                      <span
                        onClick={() => {
                          fct.deleteTagsArray(resSession);
                          this.delete(id);
                        }}
                      >
                        <i className="fas fa-times turn-90"></i>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div className="w-80p p-md has-text-primary-bis">
              {' '}
              {w.push.available}{' '}
            </div>
          );
        }
      }
      if (id === 3) {
        if (preset.includes('locale')) {
          return (
            <Option
              items={items}
              onSelect={this.selectLang}
              id={id}
              data={lang}
              onDelete={this.delete}
              fct={fct}
              tag={resLang}
            />
          );
        } else {
          return (
            <div className="w-80p p-md has-text-primary-bis">
              {' '}
              {w.push.available}{' '}
            </div>
          );
        }
      }
      if (id === 4 && suggest !== null && suggest.length > 0) {
        return (
          <Option
            items={suggest}
            onSelect={this.selectFeatures}
            id={id}
            data={allFeatures}
            onDelete={this.delete}
            fct={fct}
            tag={tag}
          />
        );
      } else {
        return (
          <div className="w-80p p-md has-text-primary-bis">
            {' '}
            {w.push.available}{' '}
          </div>
        );
      }
    }
  }

  render() {
    const { data, lng, fct, suggest, preset } = this.props;

    let items = null;
    if (data.id === 0)
      items = data.items.map((item) => {
        return { value: item.gender[lng] };
      });
    else if (data.id === 3)
      items = data.items.map((item) => {
        return { value: item.lng[lng] };
      });

    return (
      <div className="flex row border-tab-b items-center text-align-center has-text-primary is-size-7">
        <div className="w-20p">
          <p>{data.title[lng]}</p>
        </div>
        {this.option(data.id, items, suggest, fct, preset)}
      </div>
    );
  }
}

export default withMain(Tab);
