import React from 'react';
import styled from 'styled-components';

const LogicLang = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  color: grey;
`;

const FakeMenu = styled.div`
  display: flex;
  flex-direction: row;
`;

const FakeMenuLang = styled.div`
  color: #ccc;
  margin: 5px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const LogicLangNext = styled.button`
  margin: auto;
  padding: 8px;
  color: #aaa;
`;

export default (props) => {
  const {
    m: { bot_lang, w },
    modules,
    dispatch,
  } = props;
  let start_lang_module = modules.find((mod) => mod.key === 'p:start_lang');

  return (
    <LogicLang>
      <FakeMenu>
        {bot_lang.map((lang) => {
          return <FakeMenuLang key={lang}>{lang}</FakeMenuLang>;
        })}
      </FakeMenu>
      <LogicLangNext
        onClick={() => {
          dispatch({ type: 'setFocus', payload: start_lang_module });
        }}
      >
        {w.scenario.continuation_in_language_section}
      </LogicLangNext>
    </LogicLang>
  );
};
