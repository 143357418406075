import gql from 'graphql-tag';
import { _mutate, _query } from './client';

export default class Analytics {
  constructor(client, id) {
    this.client = client;
    this.id = id;
    this.hasCanceled = false;
  }

  _getAnalyticsFeatures = async (opt) => {
    return await _query(
      this.client,
      GET_ANALYTICS_FEATURES,
      { bot_id: this.id },
      opt
    );
  };

  _createAnalyticsFeatures = async (variables, opt) => {
    return await _mutate(
      this.client,
      CREATE_ANALYTICS_FEATURES,
      variables,
      opt
    );
  };

  _updateAnalyticsFeatures = async (variables, opt) => {
    return await _mutate(
      this.client,
      UPDATE_ANALYTICS_FEATURES,
      variables,
      opt
    );
  };
}

export const GET_ANALYTICS_FEATURES = gql`
  query($bot_id: Int!) {
    getAnalyticsFeatures(bot_id: $bot_id) {
      name
      activated
    }
  }
`;

export const CREATE_ANALYTICS_FEATURES = gql`
  mutation($bot_id: Int!, $name: String, $activated: Boolean) {
    createAnalyticsFeatures(bot_id: $bot_id, name: $name, activated: $activated)
  }
`;

export const UPDATE_ANALYTICS_FEATURES = gql`
  mutation($bot_id: Int!, $name: String, $activated: Boolean) {
    updateAnalyticsFeatures(bot_id: $bot_id, name: $name, activated: $activated)
  }
`;

export const _getAllAnalytics = async (botID, start, end) => {
  let url = null;
  if (!start && !end)
    url = `${process.env.REACT_APP_HTTP_LINK}/rest/analytics/${botID}`;
  else
    url = !end
      ? `${process.env.REACT_APP_HTTP_LINK}/rest/analytics/${botID}?from=${start}`
      : `${process.env.REACT_APP_HTTP_LINK}/rest/analytics/${botID}?from=${start}&to=${end}`;
  const req = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    return await { url, req };
  } catch (err) {
    console.log(err);
  }
};

export const _getCharts = async (id) => {
  let url = `${process.env.REACT_APP_HTTP_LINK}/rest/analytics/${id}/user/traffic`;
  const req = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await fetch(url, req).then((res) => res.json());
    if (res) return res;
    else return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getAnalytic = async (botID) => {
  let url = `${process.env.REACT_APP_HTTP_LINK}/rest/analytics/${botID}`;
  const req = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await fetch(url, req).then((res) => res.json());
    return { success: true, json: res };
  } catch (err) {
    console.log('err');
  }
};
