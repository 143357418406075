import React from 'react';
import { Button } from '@ask-mona/ui';
import FlagIcon from '../../../components/FlagIcon';

const SetLang = (props) => {
  const {
    m: { bot_lang },
    lang,
    setLang,
  } = props;
  return (
    <React.Fragment>
      <Button
        className={`m-r-sm is-outlined ${
          lang === 'en' ? 'is-borderless' : ''
        } shrink`}
        onClick={() => setLang('fr')}
      >
        <FlagIcon code="fr" />
      </Button>
      {bot_lang.some((l) => l === 'en') && (
        <Button
          className={`m-r-sm is-outlined ${
            lang === 'en' ? '' : 'is-borderless'
          } shrink`}
          onClick={() => setLang('en')}
        >
          <FlagIcon code="gb" />
        </Button>
      )}
    </React.Fragment>
  );
};

export { SetLang };
