import gql from 'graphql-tag';
import { _mutate, _query } from './client';

class Event {
  constructor(client) {
    this.client = client;
    this.queries = [GET_EVENTS, GET_EVENT];
  }

  _createEvent = async (variables, opt) => {
    return await _mutate(this.client, CREATE_EVENT, variables, opt);
  };

  _updateEvent = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_EVENT, variables, opt);
  };

  _deleteEvent = async (variables, opt) => {
    return await _mutate(this.client, DELETE_EVENT, variables, opt);
  };

  _getEvent = async (variables, opt) => {
    return await _query(this.client, GET_EVENT, variables, opt);
  };

  _getSimpleListEvents = async (variables, opt) => {
    return await _query(this.client, GET_SIMPLE_LIST_EVENTS, variables, opt);
  };

  _getTags = async (variables, opt) => {
    return await _query(this.client, GET_TAGS, variables, opt);
  };

  _setEventTranslation = async (variables, opt) => {
    return await _mutate(this.client, SET_EVENT_TRANSLATION, variables, opt);
  };

  _updateEventTranslation = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_EVENT_TRANSLATION, variables, opt);
  };

  _checkRequirement = (translations, event) => {
    let success = true;
    let errors = {};
    const { type, place_id } = event;
    let title = (translations || []).find((m) => m.short_title || m.long_title);
    if (!type || !place_id || !title) success = false;
    if (!type) errors = { ...errors, type: true };
    if (!place_id) errors = { ...errors, place: true };
    if (!title) errors = { ...errors, title: true };
    return { success, errors };
  };

  _formatVariables = (variables) => {
    const { publics, themes, tags, type, place, ...args } = variables;
    let newArg = args;
    if (publics) newArg = { ...newArg, publics: publics.map((p) => p.id) };
    if (themes) newArg = { ...newArg, themes: themes.map((p) => p.id) };
    if (tags) newArg = { ...newArg, tags: tags.map((p) => p.id) };
    if (tags) newArg = { ...newArg, tags: tags.map((p) => p.id) };
    if (type) newArg = { ...newArg, type_id: type.id };
    return newArg;
  };

  _getQuery = (name) => {
    return this.queries[name];
  };
}

export default Event;

export const GET_EVENT = gql`
  query getEvent($id: Int!, $languages: [String], $optimized: Boolean) {
    getEvent(id: $id, languages: $languages, optimized: $optimized) {
      id
      place_id
      published
      free
      opening_hours
      price
      date_start
      date_end
      main_image
      place {
        id
        name
        address
        sub_places {
          id
          name
        }
        short_address
      }
      sub_place_id
      type {
        id
        slug
        translations {
          language {
            code
          }
          name
        }
      }
      themes {
        id
        slug
        translations {
          language {
            code
          }
          name
        }
      }
      tags {
        id
        slug
        translations {
          language {
            code
          }
          name
        }
      }
      publics {
        id
        slug
        translations {
          language {
            code
          }
          name
        }
      }
      translations {
        id
        event_id
        language {
          code
        }
        language_id
        price
        date
        description
        url
        short_title
        long_title
        url_ticketing
        more_infos
        practical_infos
        anecdote
      }
      seen
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation(
    $bot_id: Int!
    $place_id: Int!
    $type_id: Int!
    $themes: [Int]
    $tags: [Int]
    $publics: [Int]
    $free: Boolean
    $verified: Boolean
    $published: Boolean
    $reduction: Boolean
    $opening_hours: String
    $price: String
    $date_start: String
    $date_end: String
    $main_image: String
    $external_id: String
    $sub_place_id: Int
    $rush_hours: String
  ) {
    createEvent(
      bot_id: $bot_id
      themes: $themes
      tags: $tags
      publics: $publics
      free: $free
      verified: $verified
      published: $published
      reduction: $reduction
      opening_hours: $opening_hours
      price: $price
      date_start: $date_start
      date_end: $date_end
      main_image: $main_image
      place_id: $place_id
      external_id: $external_id
      type_id: $type_id
      sub_place_id: $sub_place_id
      rush_hours: $rush_hours
    ) {
      id
      date_end
      date_start
      published
    }
  }
`;

export const GET_EVENTS = gql`
  query getEvents(
    $bot_id: Int!
    $types: [Int]
    $title: String
    $themes: [Int]
    $tags: [Int]
    $time: String
    $date_interval: [String]
    $place_id: [Int]
    $long: Float
    $lat: Float
    $country: String
    $region: String
    $city: String
    $radius: Float
    $sortBy: [String]
    $free: Boolean
    $language: String
    $verified: Boolean
    $published: Boolean
    $finished: Boolean
    $limit: Int
    $offset: Int
    $optimized: Boolean
  ) {
    getEvents(
      bot_id: $bot_id
      types: $types
      title: $title
      themes: $themes
      tags: $tags
      time: $time
      date_interval: $date_interval
      long: $long
      lat: $lat
      place_id: $place_id
      country: $country
      region: $region
      city: $city
      radius: $radius
      sortBy: $sortBy
      free: $free
      language: $language
      verified: $verified
      published: $published
      finished: $finished
      limit: $limit
      offset: $offset
      optimized: $optimized
    ) {
      events {
        id
        published
        date_start
        date_end
        opening_hours
        place {
          name
          id
        }
        type {
          translations {
            language {
              code
            }
            name
          }
        }
        themes {
          translations {
            language {
              code
            }
            name
          }
        }
        translations {
          language {
            code
          }
          short_title
          long_title
        }
        seen
      }
      pagination {
        total
        remaining
      }
    }
  }
`;
export const GET_SIMPLE_LIST_EVENTS = gql`
  query getSimpleListEvents(
    $bot_id: Int!
    $types: [Int]
    $title: String
    $themes: [Int]
    $tags: [Int]
    $time: String
    $date_interval: [String]
    $long: Float
    $lat: Float
    $country: String
    $region: String
    $city: String
    $radius: Float
    $sortBy: [String]
    $free: Boolean
    $language: String
    $verified: Boolean
    $published: Boolean
    $finished: Boolean
    $limit: Int
    $offset: Int
    $optimized: Boolean
  ) {
    getEvents(
      bot_id: $bot_id
      types: $types
      title: $title
      themes: $themes
      tags: $tags
      time: $time
      date_interval: $date_interval
      long: $long
      lat: $lat
      country: $country
      region: $region
      city: $city
      radius: $radius
      sortBy: $sortBy
      free: $free
      language: $language
      verified: $verified
      published: $published
      finished: $finished
      limit: $limit
      offset: $offset
      optimized: $optimized
    ) {
      events {
        id
        translations {
          short_title
          long_title
        }
      }
    }
  }
`;

const GET_TAGS = gql`
  query getTags($bot_id: Int) {
    getTags(bot_id: $bot_id) {
      tags {
        id
        slug
      }
    }
  }
`;

const UPDATE_EVENT = gql`
  mutation(
    $id: Int!
    $themes: [Int]
    $tags: [Int]
    $free: Boolean
    $verified: Boolean
    $published: Boolean
    $reduction: Boolean
    $opening_hours: String
    $price: String
    $date_start: String
    $date_end: String
    $main_image: String
    $place_id: Int
    $sub_place_id: Int
    $type_id: Int
    $publics: [Int]
  ) {
    updateEvent(
      id: $id
      themes: $themes
      tags: $tags
      free: $free
      verified: $verified
      published: $published
      reduction: $reduction
      opening_hours: $opening_hours
      price: $price
      date_start: $date_start
      date_end: $date_end
      main_image: $main_image
      place_id: $place_id
      sub_place_id: $sub_place_id
      type_id: $type_id
      publics: $publics
    ) {
      id
      date_end
      date_start
      published
    }
  }
`;

const UPDATE_EVENT_TRANSLATION = gql`
  mutation(
    $event_id: Int!
    $language_id: Int!
    $price: String
    $date: String
    $description: String
    $url: String
    $short_title: String
    $long_title: String
    $url_ticketing: String
    $more_infos: String
    $practical_infos: String
    $anecdote: String
  ) {
    updateEventTranslation(
      event_id: $event_id
      language_id: $language_id
      price: $price
      date: $date
      description: $description
      url: $url
      short_title: $short_title
      long_title: $long_title
      url_ticketing: $url_ticketing
      more_infos: $more_infos
      practical_infos: $practical_infos
      anecdote: $anecdote
    ) {
      id
    }
  }
`;

const SET_EVENT_TRANSLATION = gql`
  mutation(
    $event_id: Int!
    $language_id: Int!
    $price: String
    $date: String
    $description: String
    $url: String
    $short_title: String
    $long_title: String
    $url_ticketing: String
    $more_infos: String
    $practical_infos: String
    $anecdote: String
  ) {
    setEventTranslation(
      event_id: $event_id
      language_id: $language_id
      price: $price
      date: $date
      description: $description
      url: $url
      short_title: $short_title
      long_title: $long_title
      url_ticketing: $url_ticketing
      more_infos: $more_infos
      practical_infos: $practical_infos
      anecdote: $anecdote
    ) {
      id
    }
  }
`;

const DELETE_EVENT = gql`
  mutation($id: Int!) {
    deleteEvent(id: $id)
  }
`;
