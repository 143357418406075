import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { wording, _extractByLang } from '../translations/';
import { Globals } from '../hoc/globals.js';
import * as s from '../_tools/security';
import PrivateRoute from '../components/PrivateRoute';
import Loader from '../components/Loader';
import User from '../_api/user';
import ApolloClient from 'apollo-boost';

// ROUTES
import P404, { Nosection } from '../components/singleton/404';
import Maintenance from '../components/singleton/Maintenance';
import Door from './Door';
// LOGIN
import Signin from './Signin';
import Signup from './Signup';
import PasswordForget from './PasswordForget';
import PasswordReset from './PasswordReset';
import ValidateUser from './ValidateUser';
// PANEL
import Office from './Office';

import { getUser } from "../api/graphql/user.api";

class Routes extends Component {
  constructor(props) {
    super(props);
    const client = new ApolloClient({
      uri: `${process.env.REACT_APP_HTTP_LINK}/graphql`,
    });
    this.state = {
      w: _extractByLang(wording, 'fr'),
      user: new User(client),
      client: client,
      lang: 'fr',
      fct: {
        switchLang: this.switchLang,
      },
      serverUp: null,
      currentUser: '',
      isIframe: new URLSearchParams(window.location.search).get("noHeader") === "true",
    };
  }

  async componentDidMount() {
    const res = await s._isServerUp();
    console.log('SERVER UP: ', res);
    this.setState({ serverUp: res });

    try {
      const user = await getUser();
      this.setState({ currentUser: user.email });
    } catch (err) {
      this.setState({ currentUser: undefined });
    }

    const languageCode = localStorage.getItem("languageCode");

    if (languageCode) {
      this.switchLang(languageCode);
    } else if(this.state.lang) {
      localStorage.setItem('languageCode', this.state.lang);
    }
  }

  switchLang = (langCode) => {
    if (langCode !== this.state.lang) {
      let newLanguageCode;

      if (langCode) {
        newLanguageCode = langCode;
      }

      if (!langCode) {
        const availableLanguageCodes = ["en", "fr"];
        const navigatorLanguage = navigator.language;
        newLanguageCode = availableLanguageCodes.find(
          languageCode => navigatorLanguage.includes(languageCode)
        ) || availableLanguageCodes[0];
      }

      localStorage.setItem('languageCode', newLanguageCode);
      this.setState((state) => ({
        lang: newLanguageCode,
        w: _extractByLang(wording, newLanguageCode),
      }));
    }
  };

  render() {
    const { serverUp } = this.state;
    if (typeof serverUp !== 'boolean')
      return <Loader className="has-text-white" />;
    if (!serverUp) return <Maintenance g={this.state} />;
    return (
      <React.Fragment>
        <Globals.Provider value={this.state}>
          <BrowserRouter basename="/">
            <Switch>
              <Route exact path="/" component={Door} />
              <Route exact path="/signin" component={Signin} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/password/forget" component={PasswordForget} />
              <Route
                exact
                path="/password/reset/:token"
                component={PasswordReset}
              />
              <Route exact path="/validate/:token" component={ValidateUser} />
              <PrivateRoute
                path="/office"
                component={Office}
                check={s._userIsAuthenticated}
                redirectTo="/signin"
              />
              <Route path="/nosection" component={Nosection} />
              <Route path="*" component={P404} />
            </Switch>
          </BrowserRouter>
        </Globals.Provider>
      </React.Fragment>
    );
  }
}

export default Routes;
