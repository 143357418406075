const Controls = (template) => {
  switch (template) {
    case 'template1':
      return {
        v2: true,
        first: false,
        borderArrow: '',
      };
    case 'template2':
      return {
        v2: true,
        first: false,
        borderArrow: '4px',
      };
    case 'template3':
      return {
        v2: true,
        first: true,
        borderArrow: '50%',
      };
    default:
      break;
  }
};

export default Controls;
