import React, { useState, useEffect, useContext } from "react";
import Client from "../Client";
import { QAContext } from "../../../containers/questionsAnswers/context/QAContext";

export type Api = {
  getTopics: (id: number, categoryId: number, languageCode: string) => void;
  createTopicAnswer: (
    topicId: number,
    content: string,
    languageCode: string
  ) => void;
  deleteTopicAnswer: (answerId: number) => void;
  updateTopic: (
    topicId: number,
    languageCode: string,
    fieldToUpdate: string,
    valueField: boolean | string | number
  ) => void;
  updateTopicAnswers: (
    id: number,
    content: string,
    enabled: boolean,
    startDate: string | null,
    endDate: string | null,
    reminderDate: string | null,
    reminderEmails: string | null,
    placeId: number | undefined
  ) => void;
  createTopicTarget: (
    topicId: number,
    intentId: number | undefined,
    groupEntityId: number | undefined
  ) => void;
  deleteTopicTarget: (id: number) => void;
  createTopicLink: (topicId: number, content: string, type: string) => void;
  updateTopicLink: (id: number, content: string, type: string) => void;
  deleteTopicLink: (id: number) => void;
  createTopic: (
    categoryId: number,
    botId: number,
    name: string,
    description: string
  ) => any;
  exportTopics: (botId: number) => void;
  createTopicCategory: (
    botId: number,
    name: string,
    languageCode?: string
  ) => any;
  updateTopicCategory: (id: number, name: string, languageCode?: string) => any;
};

const useTopics = (botId: number, token: string) => {
  const { state, dispatch } = useContext(QAContext);
  const [loading, setLoading] = React.useState<string>("");
  const [error, setError] = useState<any>();

  let client = new Client(`${process.env.REACT_APP_HTTP_LINK_V3}`, token);

  const getCategories = async () => {
    try {
      if (client) {
        let api = client.Topics;
        let res = await api.getTopicCategories({
          botId,
          languageCode: state.language,
        });
        if (res)
          dispatch({
            type: "SET_CATEGORIES",
            payload: res.data.getTopicCategories.categories,
          });
        dispatch({
          type: "SET_CURRENT_CATEGORY_ID",
          payload: res.data.getTopicCategories.categories[0]?.id,
        });
      }
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    getCategories();
  }, [state.language]);

  const getTopics = async (
    id: number,
    categoryId: number | null,
    name: string | null
  ) => {
    try {
      if (client && id) {
        let api = client.Topics;
        let res = await api.getTopics({
          botId: id,
          categoryId: categoryId ? categoryId : null,
          name: state.searchValue.length ? state.searchValue : null,
          languageCode: state.language,
        });
        if (res) {
          dispatch({
            type: "SET_TOPICS",
            payload: res.data.getTopics.topics,
          });
        }
      }
    } catch (err) {
      setError(err);
    }
  };

  const createTopicAnswer = async (
    topicId: number,
    content: string,
    languageCode: string
  ) => {
    try {
      if (client && topicId) {
        setLoading("loading");
        let api = client.Topics;
        let res = await api.createTopicAnswer({
          topicId,
          content,
          languageCode: state.language,
        });
        if (res) {
          dispatch({
            type: "ADD_ANSWER",
            payload: res.data.createTopicAnswer,
          });
        }
      }
    } catch (err) {
      setError(err);
      setLoading("error");
    } finally {
      setLoading("loaded");
    }
  };

  const deleteTopicAnswer = async (answerId: number) => {
    try {
      if (client && answerId) {
        setLoading("loading");
        let api = client.Topics;
        let res = await api.deleteTopicAnswer({
          id: answerId,
        });
        if (res.data.deleteTopicAnswer) {
          dispatch({
            type: "DELETE_ANSWER",
            payload: answerId,
          });
        }
      }
    } catch (err) {
      setError(err);
      setLoading("error");
    } finally {
      setLoading("loaded");
    }
  };

  const updateTopic = async (
    topicId: number,
    languageCode: string,
    fieldToUpdate: string,
    valueField: boolean | string | number
  ) => {
    try {
      if (client && botId && languageCode) {
        setLoading("loading");
        let api = client.Topics;
        let res = await api.updateTopic({
          id: topicId,
          languageCode: state.language,
          [fieldToUpdate]: valueField,
        });
        if (res.data) {
          if (fieldToUpdate === "categoryId") {
            dispatch({
              type: "CHANGE_CATEGORY",
              payload: { topicId: topicId, catId: Number(valueField) },
            });
          } else {
            dispatch({
              type: "UPDATE_TOPIC",
              payload: { topicId, fieldToUpdate, valueField },
            });
          }
        }
      }
    } catch (err) {
      setError(err);
      setLoading("error");
    } finally {
      setLoading("loaded");
    }
  };

  const updateTopicAnswers = async (
    id: number,
    content: string,
    enabled: boolean,
    startDate: string | null,
    endDate: string | null,
    reminderDate: string | null,
    reminderEmails: string | null,
    placeId: number | undefined
  ) => {
    try {
      if (client && botId) {
        setLoading("loading");
        let api = client.Topics;
        let res = await api.updateTopicAnswer({
          id,
          content,
          enabled,
          startDate,
          endDate,
          reminderDate,
          reminderEmails,
          placeId,
        });
      }
    } catch (err) {
      setError(err);
      setLoading("error");
    } finally {
      setLoading("loaded");
    }
  };

  const createTopicTarget = async (
    topicId: number,
    intentId: number | undefined,
    groupEntityId: number | undefined
  ) => {
    try {
      if (client && botId) {
        setLoading("loading");
        let api = client.Topics;
        let res = await api.createTopicTarget({
          topicId,
          intentId,
          groupEntityId,
          type: groupEntityId ? "GroupEntity" : "Intent",
        });
        if (res.data) {
          dispatch({
            type: "CREATE_LINK_TARGET",
            payload: {
              target: res.data.createTopicTarget,
              topicId: topicId,
            },
          });
        }
      }
    } catch (err) {
      setError(err);
      setLoading("error");
    } finally {
      setLoading("loaded");
    }
  };

  const deleteTopicTarget = async (id: number) => {
    try {
      if (client && botId) {
        setLoading("loading");
        let api = client.Topics;
        let res = await api.deleteTopicTarget({
          id,
        });
        if (res.data) {
          dispatch({
            type: "DELETE_LINK_TARGET",
            payload: id,
          });
        }
      }
    } catch (err) {
      setError(err);
      setLoading("error");
    } finally {
      setLoading("loaded");
    }
  };

  const createTopicLink = async (topicId: number, content: string, type) => {
    try {
      if (client) {
        setLoading("loading");
        let api = client.Topics;
        let res = await api.createTopicLink({
          topicId,
          scenarioContentId: type === "ScenarioContent" ? content : null,
          featureSlug: type === "Feature" ? content : null,
          languageCode: state.language,
          type,
        });
        if (res) {
          dispatch({
            type: "SET_TOPIC_LINK",
            payload: res.data.createTopicLink,
          });
        }
      }
    } catch (err) {
      setError(err);
      setLoading("error");
    } finally {
      setLoading("loaded");
    }
  };

  const updateTopicLink = async (id: number, content: string, type: string) => {
    try {
      if (client) {
        setLoading("loading");
        let api = client.Topics;
        let res = await api.updateTopicLink({
          id,
          scenarioContentId: type === "ScenarioContent" ? content : null,
          featureSlug: type === "Feature" ? content : null,
          type,
        });
        if (res) {
          dispatch({
            type: "SET_TOPIC_LINK",
            payload: res.data.updateTopicLink,
          });
        }
      }
    } catch (err) {
      setError(err);
      setLoading("error");
    } finally {
      setLoading("loaded");
    }
  };

  const deleteTopicLink = async (id: number) => {
    try {
      if (client) {
        setLoading("loading");
        let api = client.Topics;
        let res = await api.deleteTopicLink({
          id,
        });
        if (res) {
          dispatch({
            type: "DELETE_TOPIC_LINK",
            payload: id,
          });
        }
      }
    } catch (err) {
      setError(err);
      setLoading("error");
    } finally {
      setLoading("loaded");
    }
  };

  const createTopic = async (
    categoryId: number,
    botId: number,
    name: string,
    description: string
  ) => {
    try {
      if (client) {
        let api = client.Topics;
        let res = await api.createTopic({
          categoryId,
          botId,
          name,
          description,
          enabled: true,
          languageCode: state.language,
        });
        if (res) {
          return res.data.createTopic;
        }
      }
    } catch (err) {
      setError(err);
      setLoading("error");
    }
  };

  const exportTopics = async (botId: number) => {
    try {
      if (client) {
        let api = client.Topics;
        let res = await api.exportTopics({
          botId,
        });
        if (res) {
          return res.data.exportTopics.url;
        }
      }
    } catch (err) {
      setError(err);
      setLoading("error");
    }
  };

  const importTopics = async (file: File, botId: number) => {
    try {
      if (client) {
        setLoading("loading");
        let api = client.Topics;
        let res = await api.importTopics({
          botId,
          file,
        });
        if (res) {
          dispatch({
            type: "SET_CURRENT_CATEGORY_ID",
            payload: null,
          });
          getCategories();
        }
      }
    } catch (err) {
      setError(err);
      setLoading("error");
    } finally {
      setLoading("loaded");
    }
  };

  const createTopicCategory = async (
    botId: number,
    name: string,
    languageCode?: string
  ) => {
    try {
      if (client) {
        let api = client.Topics;
        let res = await api.createTopicCategory({
          botId,
          name,
          languageCode,
        });
        if (res) {
          return res.data.createTopicCategory;
        }
      }
    } catch (err) {}
  };

  const updateTopicCategory = async (
    id: number,
    name: string,
    languageCode?: string
  ) => {
    try {
      if (client) {
        let api = client.Topics;
        let res = await api.updateTopicCategory({
          id,
          name,
          languageCode,
        });

        if (res) {
          console.log(res);
          return res.data.updateTopicCategory;
        }
      }
    } catch (err) {}
  };

  const topicsApi = {
    getTopics,
    createTopicAnswer,
    deleteTopicAnswer,
    updateTopic,
    updateTopicAnswers,
    createTopicTarget,
    deleteTopicTarget,
    createTopicLink,
    updateTopicLink,
    deleteTopicLink,
    createTopic,
    exportTopics,
    importTopics,
    createTopicCategory,
    updateTopicCategory,
    loading,
  };

  return {
    state,
    topicsApi,
    loading,
    error,
  };
};

export default useTopics;
