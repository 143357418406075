import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from "@ask-mona/ui";
import { QAContext } from "../../context/QAContext";
import { Api } from "../../../../api/graphql/hooks/useTopics";
import { _sendFeedback } from "../../../../_tools/ui";

interface Props {
  close: Function;
  api: Api;
  w: any;
}

const CreateTopicCategory: FC<Props> = ({ close, api, w }) => {
  const { state, dispatch } = useContext(QAContext);
  const [formValues, setFormValues] = useState<{
    categoryNameFr: string;
    categoryNameEn: string;
  }>({
    categoryNameFr: "",
    categoryNameEn: "",
  });

  const [isAuthorizedSubmit, setIsAuthorizedSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (formValues.categoryNameEn && formValues.categoryNameFr && state.botId) {
      setIsAuthorizedSubmit(true);
    } else {
      setIsAuthorizedSubmit(false);
    }
  }, [formValues, state.botId]);

  const handleClose = () => {
    close();
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const submitTopicCategory = async () => {
    const res = await api.createTopicCategory(
      state.botId,
      formValues.categoryNameEn
    );
    if (res) {
      const trad = await api.updateTopicCategory(
        res.id,
        formValues.categoryNameFr,
        "fr"
      );
      if (trad) {
        handleClose();
        dispatch({
          type: "ADD_TOPIC_CATEGORY",
          payload: { ...res },
        });
      }
    }
  };

  const handleSubmit = async () => {
    if (isAuthorizedSubmit) {
      await submitTopicCategory();
    } else {
      _sendFeedback(
        "danger",
          w.fill_required_fields
      );
    }
  };

  return (
    <div className="w-4 h-auto has-background-white">
      <div className="p-md has-text-white has-background-primary relative">
        {w.createCategory}
        <span className="has-text-white absolute top right p-md pointer">
          <Icon icon="fas fa-times" onClick={handleClose} />
        </span>
      </div>
      <WrapperContent>
        <WrapperInput>
          <label htmlFor="">{w.categoryNameFr}</label>
          <StyledInput
            type="text"
            name="categoryNameFr"
            value={formValues.categoryNameFr}
            onChange={handleValueChange}
          />
        </WrapperInput>
        <WrapperInput>
          <label htmlFor="">{w.categoryNameEn}</label>
          <StyledInput
            type="text"
            name="categoryNameEn"
            value={formValues.categoryNameEn}
            onChange={handleValueChange}
          />
        </WrapperInput>
        <WrapperButtons>
          <Button onClick={handleClose}>{w.cancel}</Button>
          <Button
            onClick={handleSubmit}
            color={isAuthorizedSubmit ? "#5054BE" : "#e6e6e6"}
          >
            {w.save}
          </Button>
        </WrapperButtons>
      </WrapperContent>
    </div>
  );
};

const WrapperContent = styled.div`
  padding: 1rem;
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  > label {
    color: #2d307b;
  }
`;

const StyledInput = styled.input`
  border: 1px solid #c7c8ea;
  background-color: #dadbf1;
  color: #2d307b;
  padding: 7px;
  outline: none;
  font-size: 1rem;
`;

const Button = styled.button`
  display: flex;
  padding: 0.25em 1em;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  border-radius: 3px;
  border: none;
  color: white;
  background: ${({ color }) => (color ? color : "#e08585")};
`;

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`;

export default CreateTopicCategory;
