import React from 'react';
import styled from 'styled-components';
import { Icon } from '@ask-mona/ui';
import '../_styles/css/main.css';

const WrapperLoader = styled.div`
  color: ${({ webchat }) => webchat && '#2D2F7B'};
`;

const Loader = ({ className, webchat }) => (
  <WrapperLoader webchat={webchat} className={`has-content-centered ${className}`}>
    <div className="animation-spin">
      <Icon icon="fas fa-spinner" />
    </div>
  </WrapperLoader>
);

export default Loader
