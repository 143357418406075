import Schema from './Schema';
import gql from 'graphql-tag';

class User extends Schema {
  getUsers = async (variables?: object) => {
    return await this.query(GET_USERS, variables);
  };
}

const GET_USERS = gql`
  query getUsers($organization_id: Int!) {
    getUsers(organization_id: $organization_id) {
      users {
        id
        email
        firstname
        lastname
        permissions
      }
    }
  }
`;

export default User;
