import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { _sendFeedback } from '../../../_tools/ui';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import Switch from '@material-ui/core/Switch';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FlagIcon from '../../../components/FlagIcon';
import LangDropdown from '../../../components/LangDropdown';
import { _generatePath } from '../../../components/scenario/Reducer';
import LanguagesDropdown from "../../../components/LanguagesDropdown";

const theme = createMuiTheme({
  palette: {
    primary: { main: '#fff' },
    secondary: { main: '#E66E6E' },
  },
});

const useStyles = makeStyles((theme) => ({
  whiteColor: {
    color: 'white',
  },
  root: {
    color: '#fff',
  },
}));

export const EditPopover = (props) => {
  const {
    m: { bot_lang, w },
    lang,
    focus,
    Bot,
    advanced,
    toggleUpdate,
    dispatch,
    modules,
  } = props;
  const classes = useStyles();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [name, setName] = useState('');
  const [activated, setActivated] = useState('');

  const [menu, setMenu] = useState(false);
  const [beta, setBeta] = useState(false);
  const [analytics, setAnalytics] = useState(false);
  const [oldAnalytics, setOldAnalytics] = useState(false);

  const [selectedLang, setSelectedLang] = useState(
    lang === 'default' ? 'fr' : lang
  );

  const [change, setChange] = useState(false);

  useEffect(() => {
    focus && (focus.menu === true || focus.menu === false)
      ? setMenu(focus.menu)
      : setMenu(false);
    focus && (focus.beta === true || focus.beta === false)
      ? setBeta(focus.beta)
      : setBeta(false);
  }, [focus]);

  useEffect(() => {
    if (focus) {
      if (
        selectedLang &&
        focus &&
        focus.name_translations &&
        focus.name_translations[selectedLang]
      ) {
        setName(focus.name_translations[selectedLang]);
      } else {
        setName(focus.name);
      }

      setActivated(!focus.disable);
    }
  }, [focus, lang, selectedLang]);

  useEffect(() => {
    if (!isPopoverOpen) {
      setSelectedLang('fr');
    }
  }, [isPopoverOpen]);

  useEffect(() => {
    if (focus) {
      const { content, id } = focus;
      setName(focus.name);
      if (content) {
        let condition = bot_lang.some((el) => {
          const formattedLang = el === "fr" ? "default" : el;
          if (Object.keys(content).includes(formattedLang)) {
            if (formattedLang === 'default') {
              if (content[formattedLang].includes(`LOGIC_ANALYTICS(${id})`)) {
                return true;
              } else {
                return false;
              }
            } else {
              if (content[formattedLang].includes(`LOGIC_ANALYTICS(${id}_${formattedLang})`)) {
                return true;
              } else {
                return false;
              }
            }
          } else {
            return false;
          }
        });
        setAnalytics(condition);

        setOldAnalytics(condition);
      }
    }
  }, [focus]);

  const handleSave = async () => {
    let res = [0, 0, 0, 0, 0];

    const { key, id, content } = focus;

    let newContent = { ...content };

    if (analytics) {
      bot_lang.map((el) => {
        const formattedLang = el === "fr" ? "default" : el;
        if (Object.keys(newContent).includes(formattedLang)) {
          if (formattedLang === 'default') {
            if (!newContent[formattedLang].includes(`LOGIC_ANALYTICS(${id})`)) {
              newContent = {
                ...newContent,
                [formattedLang]: [`LOGIC_ANALYTICS(${id})`, ...newContent[formattedLang]],
              };
            }
          } else {
            if (!newContent[formattedLang].includes(`LOGIC_ANALYTICS(${id}_${formattedLang})`)) {
              newContent = {
                ...newContent,
                [formattedLang]: [`LOGIC_ANALYTICS(${id}_${formattedLang})`, ...newContent[formattedLang]],
              };
            }
          }
        }
        return newContent;
      });
    } else {
      bot_lang.map((el) => {
        const formattedLang = el === "fr" ? "default" : el;
        if (Object.keys(newContent).includes(formattedLang)) {
          if (formattedLang === 'default') {
            if (newContent[formattedLang].includes(`LOGIC_ANALYTICS(${id})`)) {
              newContent = {
                ...newContent,
                [formattedLang]: [...newContent[formattedLang]].filter(
                  (i) => i !== `LOGIC_ANALYTICS(${id})`
                ),
              };
            }
          } else {
            if (newContent[formattedLang].includes(`LOGIC_ANALYTICS(${id}_${formattedLang})`)) {
              newContent = {
                ...newContent,
                [formattedLang]: [...newContent[formattedLang]].filter(
                  (i) => i !== `LOGIC_ANALYTICS(${id}_${formattedLang})`
                ),
              };
            }
          }
        }
        return newContent;
      });
    }

    if (focus && name !== focus.name && selectedLang === 'fr') {
      if (change === false) setChange(true);
      _sendFeedback('warning', 'loader');
      let resName = await Bot._updateModule({
        path: _generatePath(focus).concat('name'),
        module: {
          name: name,
        },
      });
      if (resName.success) {
        res[0] = 1;
        _sendFeedback('success', w.success.saved);
      } else {
        setName(focus.name);
        _sendFeedback('danger', w.error.general);
      }
    }

    if (focus && selectedLang) {
      if (change === false) setChange(true);
      _sendFeedback('warning', 'loader');
      let resTranslationName = await Bot._updateModule({
        path: _generatePath(focus).concat('name_translations'),
        module: {
          name_translations: focus.name_translations
            ? { ...focus.name_translations, [selectedLang]: name }
            : { [selectedLang]: name },
        },
      });
      if (resTranslationName.success) {
        res[5] = 1;
        _sendFeedback('success', w.success.saved);
      } else {
        setName(focus.name);
        _sendFeedback('danger', w.error.general);
      }
    }

    if (focus && focus.disable !== !activated) {
      if (change === false) setChange(true);
      _sendFeedback('warning', 'loader');
      let resDisable = await Bot._updateModule({
        path: _generatePath(focus).concat('disable'),
        module: { disable: !activated },
      });
      if (resDisable.success) {
        res[1] = 1;
        _sendFeedback('success', w.success.saved);
      } else _sendFeedback('danger', w.error.general);
    }

    if (
      advanced &&
      focus &&
      ((focus.beta && focus.beta !== beta) || !focus.beta)
    ) {
      if (change === false) setChange(true);
      let resBeta = await Bot._updateModule({
        path: _generatePath(focus).concat('beta'),
        module: { beta: beta },
      });
      if (resBeta.success) {
        res[2] = 1;
        _sendFeedback('success', w.success.saved);
      } else _sendFeedback('danger', w.error.general);
    }

    if (
      advanced &&
      focus &&
      ((focus.menu && focus.menu !== menu) || !focus.menu)
    ) {
      if (change === false) setChange(true);
      let resMenu = await Bot._updateModule({
        path: _generatePath(focus).concat('menu'),
        module: { menu: menu },
      });
      if (resMenu.success) {
        res[3] = 1;
        _sendFeedback('success', w.success.saved);
      } else _sendFeedback('danger', w.error.general);
    }

    if (advanced && oldAnalytics !== analytics) {
      if (change === false) setChange(true);
      let resAnalytics = await Bot._updateModule({
        path: _generatePath(focus).concat('content'),
        module: { content: newContent },
      });
      if (resAnalytics.success) {
        res[4] = 1;
        _sendFeedback('success', w.success.saved);
      } else _sendFeedback('danger', w.error.general);
    }

    if (res[2] === 1 || res[3] === 1) {
      let newModules = modules.map((m) =>
        m.key === key ? { ...focus, beta: beta } : m
      );
      await dispatch({ type: 'setModules', payload: newModules });
    }

    if (res !== [0, 0, 0, 0, 0, 0]) {
      toggleUpdate();
      await dispatch({
        type: 'setFocus',
        payload: {
          ...focus,
          name: selectedLang === 'fr' ? name : focus.name,
          name_translations: focus.name_translations
            ? { ...focus.name_translations, [selectedLang]: name }
            : { [selectedLang]: name },
          content: newContent,
          disable: !activated,
          menu: menu,
          beta: beta,
        },
      });
    }

    setIsPopoverOpen(false);
  };

  const handleDelete = async () => {
    setIsPopoverOpen(false)
    dispatch({ type: 'setFocus', payload: modules[0] });
    let newModules = [...modules]
    let indexModuleToDelete = newModules.findIndex((el) =>{
      return el.key === focus.key;
    })
    if (indexModuleToDelete !== -1)
    newModules.splice(indexModuleToDelete, 1);
    dispatch({ type: 'deleteModule', payload: newModules })

    let res = await Bot._deleteModule({
      path: _generatePath(focus),
      module: {
        name: focus.name
      }
    });
    if (res.success) {
        _sendFeedback('success', w.success.saved);
      } else _sendFeedback('danger', w.error.general);
  };

  return focus && focus.key !== 'first' && focus.key !== 'fallback' ? (
    <Popover
      isOpen={isPopoverOpen}
      position={['bottom', 'right']}
      padding={5}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={'#2D2F7B'}
          arrowSize={10}
          arrowStyle={{ zIndex: 10000, borderBottom: '15px solid #2D2F7B' }}
        >
          {' '}
          <MuiThemeProvider theme={theme}>
            <Container>
              <Header>
                <HeaderText>{w.scenario.modifyScenario}</HeaderText>
                <ButtonContainer>
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={!(name?.length > 0)}
                    onClick={handleSave}
                  >
                    {w.generic.save}
                  </Button>
                </ButtonContainer>
              </Header>
              <LineContainer>
                <LineTitle>{w.scenario.name}</LineTitle>
                <NameInput>
                  <CustomInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {advanced && (
                      <LanguagesDropdown
                          languages={bot_lang}
                          selectedLanguage={selectedLang}
                          onLanguageSelected={(language) =>  setSelectedLang(language)}
                      />
                  )}
                </NameInput>
              </LineContainer>
              {advanced && focus && focus.id && (
                <LineContainer>
                  <LineTitle>
                    {w.scenario.scenarioId} : {focus.id}
                  </LineTitle>
                </LineContainer>
              )}
              <LineContainer>
                <LineTitle>{w.generic.status}</LineTitle>
                <StatusContainer>
                  <Radio
                    checked={activated === true}
                    classes={{ root: classes.root }}
                    onChange={() => {
                      setActivated(true);
                    }}
                    value="d"
                    color="primary"
                  />
                  <TextContainer>{w.scenario.enabled}</TextContainer>
                  <Radio
                    checked={activated === false}
                    classes={{ root: classes.root }}
                    onChange={() => {
                      setActivated(false);
                    }}
                    value="d"
                    color="primary"
                  />
                  <TextContainer>{w.scenario.disabled}</TextContainer>
                </StatusContainer>
              </LineContainer>
              {advanced && (
                <OptionsContainer>
                  <Option>
                    <Switch
                      color="primary"
                      checked={analytics}
                      onChange={() => setAnalytics(!analytics)}
                    />
                    <TextContainer>
                      {w.scenario.addAnalyticsToScenario}
                    </TextContainer>
                  </Option>
                  <Option>
                    <Switch
                      color="primary"
                      checked={menu}
                      onChange={() => setMenu(!menu)}
                    />
                    <TextContainer>{w.scenario.addToMenu}</TextContainer>
                  </Option>
                  <Option>
                    <Switch
                      color="primary"
                      checked={beta}
                      onChange={() => setBeta(!beta)}
                    />
                    <TextContainer>{w.scenario.addToBeta}</TextContainer>
                  </Option>
                </OptionsContainer>
              )}
              <LineContainer alignItem={"center"} width={"100%"}>
                <DeleteButton onClick={handleDelete}>
                  <DeleteButtonIcon>
                    <DeleteRoundedIcon
                      color="secondary"
                      style={{ fontSize: '18px' }}
                    />
                  </DeleteButtonIcon>
                  <DeleteButtonText>{w.scenario.deleteScenario}</DeleteButtonText>
                </DeleteButton>
              </LineContainer>
            </Container>
          </MuiThemeProvider>
        </ArrowContainer>
      )}
    >
      <IconContainer>
        <EditRoundedIcon
          color="primary"
          onClick={() => {
            setIsPopoverOpen(!isPopoverOpen);
          }}
        />
      </IconContainer>
    </Popover>
  ) : null;
};

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const FlagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 30px;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 30px;
  margin-left: 3px;
`;

const CustomInput = styled.input`
  background-color: ${(p) => p.theme.colors.primary};
  color: white;
  border: 1px solid white;
  border-radius: 2px;
  height: 40px;
  width: 250px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  &:focus {
    border-color: white;
    outline-color: white;
  }
`;

const IconContainer = styled.div`
  margin-left: 10px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  position: relative;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1000 !important;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled.span`
  margin-right: 20px;
  font-size: 1.1rem;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItem }) => alignItem || 'flex-start'};
  width: ${({ width }) => width};
  margin-top: 20px;
`;

const LineTitle = styled.div`
  margin-bottom: 10px;
`;

const NameInput = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const DeleteButton = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: #ececec;
  }
`;

const DeleteButtonIcon = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 3px;
`;

const DeleteButtonText = styled.div`
  color: ${(p) => p.theme.colors.secondary};
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
