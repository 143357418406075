import React, { useState } from 'react';
import styled from 'styled-components';
import WrapperGrid from '../../../components/parameters/WrapperGrid';

const StyledItem = styled.div`
  place-self: center;
`;

const StyledSelect = styled.select`
  place-self: center;
  width: 70%;
  padding: 0.2rem 0.3rem;
  border: 1px solid #2d2f7b;
  color: ${(props) => props.theme.colors.primary};
  &:focus {
    outline: none;
  }
`;

const StyledCheckbox = styled.input`
  place-self: center;
`;

const Feature = ({
  id,
  name,
  description,
  activeBackoffice,
  required_permission,
  is_bot_active,
  handleValuePermission,
  handleActiveBackoffice,
  handleBotActive,
  w,
}) => {
  const [valuePermission, setValuePermission] = useState(required_permission);
  const [activedBackoffice, setActivedBackoffice] = useState(activeBackoffice);
  const [isBotActive, setIsBotActive] = useState(is_bot_active);

  const disabledInput = !activedBackoffice;
  return (
    <>
      <WrapperGrid>
        <StyledItem>{name}</StyledItem>
        <StyledItem>{description}</StyledItem>
        <StyledCheckbox
          type="checkbox"
          value={activedBackoffice}
          checked={activedBackoffice ? true : false}
          onChange={(e) => {
            setActivedBackoffice(!activedBackoffice);
            handleActiveBackoffice(e.target.value, id);
          }}
        />
        <StyledSelect
          value={valuePermission || undefined}
          disabled={disabledInput}
          onChange={(e) => {
            setValuePermission(e.target.value);
            handleValuePermission(e.target.value, id);
          }}
        >
          <option value={2 || null}>{w.askMonaClient}</option>
          <option value={1}>{w.askMona}</option>
        </StyledSelect>
        <StyledCheckbox
          type="checkbox"
          value={isBotActive || undefined}
          disabled={disabledInput}
          checked={isBotActive ? true : false}
          onChange={() => {
            setIsBotActive(!isBotActive);
            handleBotActive(!isBotActive, id);
          }}
        />
      </WrapperGrid>
    </>
  );
};

export default Feature;
