import React, { useState, useEffect, useRef, useReducer } from 'react';
import { initialState, reducer } from '../reducer/WebchatReducer';
import Loader from '../../../components/Loader';
import { NavLink, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { KeyboardArrowRight } from '@styled-icons/material-sharp/KeyboardArrowRight';
import { KeyboardArrowLeft } from '@styled-icons/material-sharp/KeyboardArrowLeft';
import ContainerScroll from '../../../components/parameters/ContainerScroll';
import { Preview as PreviewIcon } from '@styled-icons/material-outlined/Preview';
import {
  _uploadImg,
  _uploadVideo,
  uploadImage,
  uploadBotFile,
} from '../../../_api/upload';
import Bot from '../../../_api/bot';
import { _sendFeedback } from '../../../_tools/ui';
import Preview from './webchat/Preview';
import Templates from './webchat/Templates';
import Homepage from './webchat/Homepage';
import Bubble from './webchat/Bubble';
import Window from './webchat/Window';
import Header from './webchat/Header';
import Menu from './webchat/Menu';
import Qr from './webchat/Qr';
import Input from './webchat/Input';
import Caroussels from './webchat/Caroussels';
import Webview from './webchat/Webview';
import Message from './webchat/Message';
import Contact from './webchat/Contact';
import Fullpage from './webchat/Fullpage';
import PreviewFullpage from './webchat/PreviewFullpage';
import PreviewHomePage from './webchat/PreviewHomePage';
import Messaging from './webchat/Messaging/Messaging';
import PreviewMail from './webchat/Messaging/PreviewMail';

const transformTranslatedValue = (value, defaultLanguage) => {
  if(typeof value === 'object') {
    return Object.keys(value).reduce((result, key)=>{
      const keyComponents = key.split("_");
      if(keyComponents.length === 2) {
        return {
          ...result,
          [keyComponents[0]]: value[key]
        }
      } else {
        return {
          ...result,
          [key]: value[key]
        }
      }
    }, {});
  } else {
    return {
      [defaultLanguage]: value
    }
  }
}

const Webchat = ({ m, setSaveFunction, api, preURL, pathname, history }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [currentPage, setCurrentPage] = useState('');
  const [catchphrase, setCatchphrase] = useState('');
  const [watBot, setWatBot] = useState('');
  const [isActiveWebchat, setIsActiveWebchat] = useState('');
  const [loadData, setLoadData] = useState(false);
  const [displayFullpage, setDisplayFullpage] = useState(false);
  const Api = new Bot(m.client, m.bot.id);
  const w = m.w.parameters;
  const wWebchat = m.w.webchat;
  const refNav = useRef();

  const fetchWebchat = async () => {
    setLoadData(false);
    if (api) {
      const res = await api.createWebchat._getWebchat(m.bot.id);
      if (res.success) {
        setLoadData(true);
        const json = res.json.v2;
        json.menu = json.menu.map((menuItem)=> ({
          ...menuItem,
          title: transformTranslatedValue(menuItem.title, "fr")
        }));
        json.titleWidget = transformTranslatedValue(json.titleWidget, "fr")
        json.subtitleWidget = transformTranslatedValue(json.subtitleWidget, "fr")
        if(json.styles.webchat.homepage) {
          json.styles.webchat.homepage.title = transformTranslatedValue(json.styles.webchat.homepage.title, "fr");
          json.styles.webchat.homepage.content = transformTranslatedValue(json.styles.webchat.homepage.content, "fr");
          json.styles.webchat.homepage.buttons = json.styles.webchat.homepage.buttons.map((button)=> ({
            ...button,
            title: transformTranslatedValue(button.title, "fr")
          }));
        }
        if(json.translations) {
          json.translations.placeholder = transformTranslatedValue(json.translations.placeholder, "fr");
        }
        const webchat = json.styles.webchat;

        if (res.json.opts) {
          const entry = res.json.opts.entry;
          setCatchphrase(catchphrase.str);
        }
        if (json) {
          const str = json.styles.borderRadius?.split('px');
          dispatch({ type: 'setHeaderWebchat', payload: json.name });
          dispatch({
            type: 'setSubtitleWidget',
            payload: json.subtitleWidget,
          });
          dispatch({
            type: 'setTitle',
            payload: json.titleWidget,
          });
          dispatch({ type: 'setSubtitle', payload: json.subtitleHeader });
          dispatch({ type: 'setInputTextActive', payload: json.input });
          dispatch({ type: 'setBorderRadius', payload: str && str[0] });
          dispatch({ type: 'setWidgetIcon', payload: json.styles.icon });
          dispatch({ type: 'setV2Header', payload: webchat.header?.v2 });
          dispatch({
            type: 'setColorHeader',
            payload: webchat.header?.background,
          });
          dispatch({ type: 'setHeaderSize', payload: webchat.header?.height });
          dispatch({
            type: 'setPositionHeader',
            payload: webchat.header?.position,
          });
          dispatch({ type: 'setHeaderSubtitle', payload: json.subtitleWidget });
          dispatch({
            type: 'setDistanceIconText',
            payload: webchat.header?.marginTitle.slice(0, -3),
          });
          dispatch({
            type: 'setDisplayLogo',
            payload: webchat.header?.displayLogo,
          });
          dispatch({
            type: 'setDisplayTitle',
            payload: webchat.header?.displayTitle,
          });
          dispatch({
            type: 'setDisplaySubtitle',
            payload: webchat.header?.displaySubtitle,
          });
          dispatch({
            type: 'setSizeIcon',
            payload:
              webchat.header?.heightLogo?.slice(0, -2) ||
              webchat.header?.widthLogo,
          });
          dispatch({
            type: 'setHasHeader',
            payload: webchat.header?.hideHeader,
          });
          dispatch({
            type: 'setToggleMobile',
            payload: webchat.header?.displayMobile,
          });
          dispatch({
            type: 'setBackgroundColorBody',
            payload: webchat.body?.background,
          });
          dispatch({ type: 'setImageBody', payload: webchat.body?.img });
          dispatch({ type: 'setHeaderCurved', payload: webchat.body?.curved });
          dispatch({
            type: 'setTemplateMessage',
            payload: {
              boxShadow: webchat.body?.message?.boxShadow,
              v2: webchat.body?.message?.v2,
              borderRadius: webchat.body?.message?.borderRadius,
              borderRadiusBottom: webchat.body?.message?.borderRadiusBottom,
              borderRadiusTop: webchat.body?.message?.borderRadiusTop,
            },
          });
          dispatch({
            type: 'setIconPosition',
            payload: webchat.body?.message?.alignItems,
          });
          dispatch({ type: 'setUserIcon', payload: json.styles.usrIcon });
          dispatch({ type: 'setBotIcon', payload: json.styles.botIcon });
          dispatch({
            type: 'setColorBubbleUser',
            payload: webchat.body?.message?.backgroundUser,
          });
          dispatch({ type: 'setColorBubbleFontUser', payload: webchat.color });
          dispatch({
            type: 'setColorBubbleBot',
            payload: webchat.body?.message?.backgroundBot,
          });
          dispatch({
            type: 'setColorBubbleFontBot',
            payload: webchat.colorBot,
          });
          dispatch({
            type: 'setIconMessageUser',
            payload: webchat.body?.message?.hideAvatarUser,
          });
          dispatch({
            type: 'setIconMessageBot',
            payload: webchat.body?.message?.hideAvatarBot,
          });
          dispatch({
            type: 'setFontSizeMessage',
            payload: webchat.body?.message?.fontSize || 'inherit',
          });
          dispatch({
            type: 'setQrTemplate',
            payload: {
              v2: webchat.body?.qr?.v2,
              boxShadow: webchat.body?.qr?.boxShadow,
              hover: webchat.body?.qr?.hover,
            },
          });
          dispatch({
            type: 'setQrBorderColor',
            payload:
              webchat.body?.qr?.border === 'transparent'
                ? 'transparent'
                : webchat.body?.qr?.color,
          });
          dispatch({
            type: 'setColorQr',
            payload: webchat.body?.qr?.background,
          });
          dispatch({
            type: 'setColorFontQr',
            payload: webchat.body?.qr?.color,
          });
          dispatch({
            type: 'setBorderQr',
            payload: webchat.body?.qr?.borderRadius.slice(0, -2),
          });
          dispatch({
            type: 'setTemplateCaroussels',
            payload: {
              v2: webchat.body?.caroussel?.v2,
              borderFirstChild: webchat.body?.caroussel?.borderFirstChild,
              buttons: {
                first: webchat.body?.caroussel?.buttons.first,
                width: webchat.body?.caroussel?.buttons.width,
                shadow: webchat.body?.caroussel?.buttons.shadow,
                icon: webchat.body?.caroussel?.buttons.icon,
                boxShadow: webchat.body?.caroussel?.buttons.boxShadow,
                justifyContent: webchat.body?.caroussel?.buttons.justifyContent,
              },
              generic: {
                imgCover: webchat.body?.caroussel?.generic.imgCover,
                marginImg: webchat.body?.caroussel?.generic.marginImg,
                marginTitle: webchat.body?.caroussel?.generic.marginTitle,
              },
            },
          });
          dispatch({
            type: 'setRadiusCardCaroussel',
            payload: webchat.body?.caroussel?.buttons.borderRadius?.slice(
              0,
              -2
            ),
          });
          dispatch({
            type: 'setFontSizeTitleCaroussel',
            payload: webchat.body?.caroussel?.fontSizeTitle,
          });
          dispatch({
            type: 'setFontSizeSubtitleCaroussel',
            payload: webchat.body?.caroussel?.fontSizeSubtitle,
          });
          dispatch({
            type: 'setFontSizeButtons',
            payload: webchat.body?.caroussel?.buttons.fontSize
              ?.toString()
              .includes('em')
              ? webchat.body?.caroussel?.buttons?.fontSize
              : `${webchat.body?.caroussel?.buttons.fontSize}em`,
          });
          dispatch({
            type: 'setPaddingButtons',
            payload: webchat.body?.caroussel?.buttons.padding,
          });
          dispatch({
            type: 'setTemplateWebview',
            payload: webchat.body?.webview,
          });
          dispatch({
            type: 'setTemplateAnimation',
            payload: webchat.body?.loader?.v2 || false,
          });
          dispatch({
            type: 'setColorAnimation',
            payload: webchat.body?.loader?.color,
          });
          dispatch({
            type: 'setTemplateInput',
            payload: {
              v2: webchat.board?.v2,
              borderRadius: webchat.board?.borderRadius,
              boxShadow: webchat.board?.boxShadow,
            },
          });
          dispatch({
            type: 'setBackgroundInput',
            payload: webchat.board?.background,
          });
          dispatch({
            type: 'setColorInputText',
            payload: webchat.board?.color,
          });
          dispatch({
            type: 'setColorInputIcons',
            payload: webchat.board?.colorSendBtn,
          });
          dispatch({ type: 'setSendButton', payload: webchat.board?.iconSend });
          dispatch({
            type: 'setMenuTemplate',
            payload: {
              first: webchat.board?.menu.first,
              second: webchat.board?.menu.second,
              third: webchat.board?.menu.third,
              borderColor: webchat.board?.menu.borderColor,
              borderIcon: webchat.board?.menu.borderIcon,
              backgroundIcon: webchat.board?.menu.backgroundIcon,
              colorIcon: webchat.board?.menu.colorIcon,
              backgroundBtn: webchat.board?.menu.backgroundBtn,
              shadowIcon: webchat.board?.menu.shadowIcon,
              borderTopRadius: webchat.board?.menu.borderTopRadius,
            },
          });
          dispatch({
            type: 'setBackgroundMenu',
            payload: webchat.board?.menu.background,
          });
          dispatch({
            type: 'setColorMenu',
            payload: webchat.board?.menu.color,
          });
          dispatch({ type: 'setMenuOpen', payload: webchat.board?.menu.open });
          dispatch({
            type: 'setColorBubble',
            payload: json.styles.catchphrase?.background,
          });
          dispatch({
            type: 'setColorFontBubble',
            payload: json.styles.catchphrase?.color,
          });
          dispatch({
            type: 'setRightMobile',
            payload: json.styles.catchphrase?.rightMobile,
          });
          dispatch({
            type: 'setBottomMobile',
            payload: json.styles.catchphrase?.bottomMobile,
          });
          dispatch({
            type: 'setTemplate',
            payload: {
              catchphrase: {
                bottom: json.styles.catchphrase?.bottom,
                right: json.styles.catchphrase?.right,
                borderRadius: json.styles.catchphrase?.borderRadius || '',
                border: json.styles.catchphrase?.border,
              },
              widget: json.styles.widget,
            },
          });
          dispatch({ type: 'setTemplateWindow', payload: json.styles.window });
          dispatch({ type: 'setSendImage', payload: json.img });
          dispatch({ type: 'setColorText', payload: webchat.header?.color });
          dispatch({
            type: 'setWidgetHover',
            payload: json.styles.entry?.altIcon,
          });
          dispatch({ type: 'setFullpage', payload: json.styles.fullpage });
          dispatch({
            type: 'setBoxShadowWebchat',
            payload: json.styles.boxShadow,
          });
          dispatch({
            type: 'setBackgroundSize',
            payload: webchat.body?.backgroundSize,
          });
          dispatch({
            type: 'setBackgroundPosition',
            payload: webchat.body?.backgroundPosition,
          });
          dispatch({
            type: 'setFontSizeQr',
            payload: webchat.body?.qr?.fontSize.toString().includes('em')
              ? webchat.body?.qr?.fontSize
              : `${webchat.body?.qr?.fontSize}em`,
          });
          dispatch({
            type: 'setTextInput',
            payload:
              json.translations?.placeholder.fr || 'Ecrire un message...',
          });
          dispatch({
            type: 'setFontSizeBoard',
            payload: webchat.board?.fontSize,
          });
          dispatch({ type: 'setMenuItems', payload: json.menu });
          dispatch({
            type: 'setBackgroundButtonCaroussel',
            payload: webchat.body?.caroussel?.buttons.background,
          });
          dispatch({
            type: 'setColorBtnCard',
            payload: webchat.body?.caroussel?.buttons.color,
          });
          dispatch({
            type: 'setBackgroundCard',
            payload: webchat.body?.caroussel?.background,
          });
          dispatch({
            type: 'setColorFontCaroussel',
            payload: webchat.body?.generic?.colorTitle,
          });
          dispatch({
            type: 'setBorderColorButton',
            payload: state.templateCaroussels.borderFirstChild
              ? webchat.body?.borderColor
              : webchat.body?.caroussel.buttons?.borderColor,
          });
          dispatch({
            type: 'setColorPagination',
            payload: webchat.body?.controls?.colorPagination,
          });
          dispatch({
            type: 'setColorArrow',
            payload: webchat.body?.controls?.colorArrow,
          });
          dispatch({
            type: 'setColorBgArrow',
            payload: webchat.body?.controls?.colorBgArrow,
          });
          dispatch({
            type: 'setTemplateControls',
            payload: {
              v2: webchat.body?.controls?.v2,
              first: webchat.body?.controls?.first,
              // backgroundArrow: webchat.body?.controls?.backgroundArrow,
              borderArrow: webchat.body?.controls?.borderArrow,
            },
          });
          dispatch({
            type: 'setFontSelected',
            payload: json.styles.fontFamily,
          });
          dispatch({ type: 'setUrlFont', payload: json.styles.urlFont });
          dispatch({ type: 'setFormatFont', payload: json.styles.formatFont });
          dispatch({
            type: 'setHeightQr',
            payload: webchat.body?.qr?.height?.slice(0, -2),
          });
          dispatch({
            type: 'setWidthQr',
            payload: webchat.body?.qr?.width?.slice(0, -2),
          });
          dispatch({ type: 'setWording', payload: res.json.wording });
          dispatch({ type: 'setOpts', payload: res.json.opts });
          dispatch({ type: 'setSkin', payload: res.json.skin });
          dispatch({
            type: 'setOldBackground',
            payload: json.styles.background,
          });
          dispatch({
            type: 'setLayoutCenter',
            payload: webchat.fullpage?.layoutCenter,
          });
          dispatch({
            type: 'setNoBackgroundMessage',
            payload: webchat.fullpage?.noBackgroundMessage,
          });
          dispatch({
            type: 'setHideAvatarFullpage',
            payload: webchat.fullpage?.hideAvatar,
          });
          dispatch({
            type: 'setColorMessageUserFullpage',
            payload: webchat.fullpage?.colorMessageUser,
          });
          dispatch({
            type: 'setColorMessageBotFullpage',
            payload: webchat.fullpage?.colorMessageBot,
          });
          dispatch({
            type: 'setHideHeaderFullpage',
            payload: webchat.fullpage?.header.hideHeader,
          });
          dispatch({
            type: 'setIconMenu',
            payload: webchat.board?.iconMenu,
          });
          dispatch({
            type: 'setIconSendImage',
            payload: webchat.board?.iconSendImage,
          });
          dispatch({
            type: 'setSizeIconSend',
            payload: webchat.board?.sizeIconSend || 22,
          });
          dispatch({
            type: 'setSizeIconSendImage',
            payload: webchat.board?.sizeIconSendImage || 22,
          });
          dispatch({
            type: 'setSizeIconMenu',
            payload: webchat.board?.sizeIconMenu || 22,
          });
          dispatch({
            type: 'setNoBg',
            payload: webchat.board?.noBg,
          });
          dispatch({
            type: 'setSizeTitle',
            payload: webchat.header?.fontSizeTitle,
          });
          dispatch({
            type: 'setSizeSubtitle',
            payload: webchat.header?.fontSizeSubtitle,
          });
          dispatch({
            type: 'setSizeHeaderIcon',
            payload: webchat.header?.sizeHeaderIcon,
          });
          dispatch({
            type: 'setCustomIcon',
            payload: webchat.header?.customIcon,
          });
          dispatch({
            type: 'setAvatarHeader',
            payload: webchat.header?.avatar,
          });
          dispatch({
            type: 'setHomeActive',
            payload: webchat.homepage?.active,
          });
          dispatch({
            type: 'setHomeTitle',
            payload: webchat.homepage?.title || {},
          });
          dispatch({
            type: 'setHomeImage',
            payload: webchat.homepage?.image,
          });
          dispatch({
            type: 'setHomeContent',
            payload: webchat.homepage?.content || {},
          });
          dispatch({
            type: 'setHomeButtons',
            payload: webchat.homepage?.buttons || [],
          });
          dispatch({
            type: 'setHomeTitleFontSize',
            payload: webchat.homepage?.styles.title.fontSize,
          });
          dispatch({
            type: 'setHomeTitleColor',
            payload: webchat.homepage?.styles.title.color,
          });
          dispatch({
            type: 'setHomeContentBg',
            payload: webchat.homepage?.styles.content.background,
          });
          dispatch({
            type: 'setHomeContentFontSize',
            payload: webchat.homepage?.styles.content.fontSize,
          });
          dispatch({
            type: 'setHomeContentColor',
            payload: webchat.homepage?.styles.content.color,
          });
          dispatch({
            type: 'setHomeButtonsColor',
            payload: webchat.homepage?.styles.buttons.color,
          });
          dispatch({
            type: 'setHomeButtonsWidth',
            payload: webchat.homepage?.styles.buttons.width,
          });
          dispatch({
            type: 'setHomeButtonsHeight',
            payload: webchat.homepage?.styles.buttons.height,
          });
          dispatch({
            type: 'setHomeButtonsBorder',
            payload: webchat.homepage?.styles.buttons.border,
          });
          dispatch({
            type: 'setHomeButtonsBackground',
            payload: webchat.homepage?.styles.buttons.background,
          });
          dispatch({
            type: 'setWebviewMailColor',
            payload: webchat.body?.webviewMail?.color,
          });
        }
      }
    }
  };

  useEffect(() => {
    history.push('/office/parameters/functionality/webchat/templates');
  }, []);

  useEffect(() => {
    fetchWebchat();
  }, []);

  useEffect(() => {
    fetchBot();
  }, []);

  useEffect(() => {
    if (setSaveFunction) {
      setSaveFunction(onSave);
    }
  }, [state]);

  useEffect(() => {
    const pathBase = '/office/parameters/functionality/webchat/'.length;
    const currentRoute = pathname.slice(pathBase);
    findRoute(currentRoute);
  }, [pathname]);

  const fetchBot = async () => {
    const bot = {
      id: m.bot.id,
    };
    const res = await Api._getBot(bot);
    if (res.success) {
      setWatBot(res.getBot.wat);
      setIsActiveWebchat(res.getBot.platforms.webchat);
    }
  };

  const onSave = async () => {
    if (api && jsonTemplate) {
      const res = await api.createWebchat._updateWebchat(
        m.bot.id,
        jsonTemplate
      );
      if (res.success) {
        _sendFeedback('success', m.w.success.saved);
      } else {
        _sendFeedback('danger', m.w.error.general);
      }
    }
  };

  const handleNav = (direction) => {
    if (refNav && direction === 'left') {
      refNav.current.scrollLeft -= 200;
    } else if (refNav && direction === 'right') {
      refNav.current.scrollLeft += 200;
    }
  };

  const jsonTemplate = {
    config: {
      name: state.headerWebchat,
      v2: {
        name: state.headerWebchat,
        titleWidget: state.title,
        subtitleWidget: state.subtitleWidget,
        subtitleHeader: state.subtitle,
        img: state.sendImage,
        input: state.inputTextActive,
        menu: state.menuItems.filter(
          (value) => Object.keys(value).length !== 0 && value.title
        ),
        translations: {
          placeholder: {
            fr: state.textInput || 'Écrire un message...',
            en: 'Write a message...',
          },
        },
        styles: {
          background: state.oldBackground,
          fullpage: true,
          bottom: '30px',
          right: '12px',
          boxShadow: state.boxShadowWebchat,
          borderRadius: `${state.borderRadius}px`,
          fontFamily: state.fontSelected,
          urlFont: state.urlFont,
          formatFont: state.formatFont,
          zIndex: '2147483647',
          icon: state.widgetIcon,
          usrIcon: state.userIcon,
          botIcon: state.botIcon,
          entry: {
            altIcon: state.widgetHover,
            altIconOpen: state.widgetIcon,
          },
          webchat: {
            homepage: {
              active: state.homeActive,
              title: state.homeTitle,
              image: state.homeImage,
              content: state.homeContent,
              buttons: state.homeButtons,
              styles: {
                title: {
                  fontSize: state.homeTitleFontSize,
                  color: state.homeTitleColor,
                },
                content: {
                  background: state.homeContentBg,
                  fontSize: state.homeContentFontSize,
                  color: state.homeContentColor,
                },
                buttons: {
                  color: state.homeButtonsColor,
                  background: state.homeButtonsBackground,
                  border: state.homeButtonsBorder,
                  height: state.homeButtonsHeight,
                  width: state.homeButtonsWidth,
                },
              },
            },
            color: state.colorBubbleFontUser,
            colorBot: state.colorBubbleFontBot,
            border: 'none',
            header: {
              v2: state.v2Header,
              background: state.colorHeader,
              fontFamilly: '',
              height: state.headerSize,
              position: state.positionHeader,
              marginTitle: `${state.distanceIconText}rem`,
              displayLogo: state.displayLogo,
              displayTitle: state.displayTitle,
              displaySubtitle: state.displaySubtitle,
              heightLogo: `${state.sizeIcon || 30}px`,
              widthLogo: `${state.sizeIcon || 30}px`,
              hideHeader: state.hasHeader,
              color: state.colorText,
              fontSizeTitle: state.sizeTitle,
              fontSizeSubtitle: state.sizeSubtitle,
              sizeHeaderIcon: state.sizeHeaderIcon,
              customIcon: state.customIcon,
              avatar: state.avatarHeader,
              displayMobile: state.toggleMobile,
            },
            body: {
              background: state.backgroundColorBody,
              img: state.imageBody,
              backgroundSize: state.backgroundSize,
              backgroundPosition: state.backgroundPosition,
              gradient: '',
              curved: state.headerCurved,
              cardV2: true,
              message: {
                ...state.templateMessage,
                backgroundUser: state.colorBubbleUser,
                backgroundBot: state.colorBubbleBot,
                backgroundUserResa: '',
                backgroundBotResa: '',
                widthBotAvatar: '',
                heightBotAvatar: '',
                linearGradientUser: '',
                linearGradientBot: '',
                hideAvatarUser: state.iconMessageUser,
                hideAvatarBot: state.iconMessageBot,
                alignItems: state.iconPosition,
                fontSize: state.fontSizeMessage,
              },
              qr: {
                border: state.borderColorQr,
                ...state.qrTemplate,
                background: state.colorQr,
                color: state.colorFontQr,
                fontSize: state.fontSizeQr,
                borderRadius: `${state.borderQr}px`,
                height: `${state.heightQr}px`,
                width: `${state.widthQr}px`,
              },
              caroussel: {
                ...state.templateCaroussels,
                background: state.backgroundCard,
                borderColor: state.borderColorButton,
                fontSizeTitle: state.fontSizeTitleCaroussel,
                fontSizeSubtitle: state.fontSizeSubtitleCaroussel,
                generic: {
                  ...state.templateCaroussels.generic,
                  colorTitle: state.colorFontCaroussel,
                },
                buttons: {
                  ...state.templateCaroussels.buttons,
                  color: state.colorBtnCard,
                  background: state.backgroundButtonCaroussel,
                  borderColor: state.borderColorButton,
                  borderRadius: `${state.radiusCardCaroussel}px`,
                  padding: state.paddingButtons,
                  fontSize: state.fontSizeButtons,
                },
              },
              webview: state.templateWebview,
              webviewMail: {
                color: state.webviewMailColor,
              },
              loader: {
                ...state.templateAnimation,
                color: state.colorAnimation,
              },
              controls: {
                ...state.templateControls,
                colorPagination: state.colorPagination,
                colorArrow: state.colorArrow,
                colorBgArrow: state.colorBgArrow,
              },
            },
            board: {
              ...state.templateInput,
              background: state.backgroundInput,
              color: state.colorInputText,
              colorSendBtn: state.colorInputIcons,
              fontSize: state.fontSizeBoard,
              iconSend: state.sendButton,
              iconMenu: state.iconMenu,
              iconSendImage: state.iconSendImage,
              sizeIconSend: state.sizeIconSend,
              sizeIconSendImage: state.sizeIconSendImage,
              sizeIconMenu: state.sizeIconMenu,
              noBg: state.noBg,
              menu: {
                ...state.menuTemplate,
                background: state.backgroundMenu,
                color: state.colorMenu,
                open: state.menuOpen,
              },
            },
            fullpage: {
              layoutCenter: state.layoutCenter,
              noBackgroundMessage: state.noBackgroundMessage,
              hideAvatar: state.hideAvatarFullpage,
              colorMessageUser:
                state.colorMessageUserFullpage || state.colorBubbleFontUser,
              colorMessageBot:
                state.colorMessageBotFullpage || state.colorBubbleFontBot,
              header: {
                hideHeader: state.hideHeaderFullpage,
              },
            },
          },
          catchphrase: {
            bottom: state.template.catchphrase?.bottom,
            right: state.template.catchphrase?.right,
            background: state.colorBubble,
            color: state.colorFontBubble,
            borderRadius: state.template.catchphrase?.borderRadius,
            border: state.template.catchphrase?.border,
            bottomMobile: state.bottomMobile,
            rightMobile: state.rightMobile,
          },
          widget: state.template.widget,
          window: state.templateWindow,
        },
      },
      wording: state.wording,
      opts: state.opts,
      skin: state.skin,
    },
  };

  const menuParam = [
    'templates',
    "page-d'accueil",
    'bulle',
    'fenêtre',
    'en-tête',
    'menu',
    'qr',
    'champ-Texte',
    'caroussels',
    'webview',
    'contact',
    'bulles-messages',
    'pleine-page',
    'messagerie',
  ];

  const findRoute = (route) => {
    switch (route) {
      case 'templates':
        setCurrentPage('templates');
        break;
      case "page-d'accueil":
        setCurrentPage("page-d'accueil");
        break;
      case 'bulle':
        setCurrentPage('bulle');
        break;
      case 'fenêtre':
        setCurrentPage('fenêtre');
        break;
      case 'en-tête':
        setCurrentPage('en-tête');
        break;
      case 'menu':
        setCurrentPage('menu');
        break;
      case 'qr':
        setCurrentPage('qr');
        break;
      case 'champ-Texte':
        setCurrentPage('champ-Texte');
        break;
      case 'caroussels':
        setCurrentPage('caroussels');
        break;
      case 'webview':
        setCurrentPage('webview');
        break;
      case 'contact':
        setCurrentPage('contact');
        break;
      case 'bulles-messages':
        setCurrentPage('bulles-messages');
        break;
      case 'pleine-page':
        setCurrentPage('pleine-page');
        break;
      case 'messagerie':
        setCurrentPage('messagerie');
        break;
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      {loadData && (
        <>
          <ContainerScroll
            opacity={displayFullpage}
            width={currentPage === 'pleine-page' ? 100 : 80}
            messaging={currentPage === 'messagerie'}
          >
            <WrapperNav messaging={currentPage === 'messagerie'}>
              <IconNav onClick={() => handleNav('left')}>
                <KeyboardArrowLeft size={30} />
              </IconNav>
              <StyledNav ref={refNav}>
                {menuParam.map((item, key) => (
                  <LinkFunctionnality
                    to={`${preURL}/functionality/webchat/${item}`}
                    key={key}
                  >
                    {wWebchat[`menu_${item}`]}
                  </LinkFunctionnality>
                ))}
              </StyledNav>
              <IconNav onClick={() => handleNav('right')}>
                <KeyboardArrowRight size={30} />
              </IconNav>
            </WrapperNav>
            {currentPage === 'pleine-page' && (
              <WrapperPreview>
                <PreviewIcon
                  size={30}
                  onMouseEnter={() => setDisplayFullpage(true)}
                  onMouseLeave={() => setDisplayFullpage(false)}
                />
              </WrapperPreview>
            )}
            <Switch>
              <Route exact path={`${preURL}/functionality/webchat/templates`}>
                <Templates
                  wat={watBot}
                  w={w}
                  wWebchat={wWebchat}
                  state={state}
                  dispatch={dispatch}
                  bot={m.bot.id}
                  client={m.client}
                />
              </Route>
              <Route
                exact
                path={`${preURL}/functionality/webchat/page-d'accueil`}
              >
                <Homepage
                  wat={watBot}
                  w={w}
                  m={m}
                  wWebchat={wWebchat}
                  state={state}
                  dispatch={dispatch}
                  bot={m.bot.id}
                  client={m.client}
                  handleMedia={async (e) => {
                    dispatch({ type: 'setLoadUpload', payload: true });
                    let file = Object.values(e.target.files)[0];
                    if (/\.(jpe?g|png|gif|svg)$/i.test(file.name)) {
                      const resUrl = await uploadBotFile(
                        file.name,
                        Number(m.bot.id)
                      );
                      if (resUrl.success) {
                        let reader = new FileReader();
                        reader.readAsArrayBuffer(file);
                        let res = null;
                        reader.onload = async () => {
                          const signedUrl = resUrl.url.signedUrl;
                          const fileUrl = resUrl.url.fileUrl;
                          res = await uploadImage(
                            signedUrl,
                            reader.result,
                            file.type
                          );
                          if (res.success) {
                            _sendFeedback('success', m.w.success.saved);
                            dispatch({ type: 'setLoadUpload', payload: false });
                            dispatch({
                              type: 'setHomeImage',
                              payload: fileUrl,
                            });
                          }
                        };
                      }
                    }
                    if (/\.(webm|mp4)$/i.test(file.name)) {
                      await _uploadVideo(
                        m.bot.id,
                        e,
                        (res) => {
                          dispatch({ type: 'setLoadUpload', payload: false });
                          dispatch({ type: 'setHomeImage', payload: res.path });
                        },
                        (err) => {
                          dispatch({ type: 'setLoadUpload', payload: false });
                        }
                      );
                    }
                  }}
                />
              </Route>
              <Route exact path={`${preURL}/functionality/webchat/bulle`}>
                <Bubble
                  m={m}
                  w={w}
                  wWebchat={wWebchat}
                  state={state}
                  dispatch={dispatch}
                  handleImage={async (e) => {
                    dispatch({ type: 'setLoadUpload', payload: true });
                    await _uploadImg(
                      m.bot.id,
                      e,
                      (res) => {
                        _sendFeedback("success", m.w.success.saved);
                        dispatch({ type: 'setLoadUpload', payload: false });
                        dispatch({ type: 'setWidgetIcon', payload: res.path });
                      },
                      (err) => {
                        dispatch({ type: 'setLoadUpload', payload: false });
                      }
                    );
                  }}
                  handleImageHover={async (e) => {
                    dispatch({ type: 'setLoadUpload', payload: true });
                    await _uploadImg(
                      m.bot.id,
                      e,
                      (res) => {
                        _sendFeedback("success", m.w.success.saved);
                        dispatch({ type: 'setLoadUpload', payload: false });
                        dispatch({ type: 'setWidgetHover', payload: res.path });
                      },
                      (err) => {
                        dispatch({ type: 'setLoadUpload', payload: false });
                      }
                    );
                  }}
                />
              </Route>
              <Route exact path={`${preURL}/functionality/webchat/fenêtre`}>
                <Window
                  w={w}
                  wWebchat={wWebchat}
                  state={state}
                  dispatch={dispatch}
                  handleBackgroundImage={async (e) => {
                    dispatch({ type: 'setLoadUpload', payload: true });
                    await _uploadImg(
                      m.bot.id,
                      e,
                      (res) => {
                        _sendFeedback("success", m.w.success.saved);
                        dispatch({ type: 'setLoadUpload', payload: false });
                        dispatch({ type: 'setImageBody', payload: res.path });
                        dispatch({
                          type: 'setBackgroundSize',
                          payload: 'cover',
                        });
                        dispatch({
                          type: 'setBackgroundPosition',
                          payload: 'center',
                        });
                        dispatch({
                          type: 'setBackgroundColorBody',
                          payload: '',
                        });
                      },
                      (err) => {
                        dispatch({ type: 'setLoadUpload', payload: false });
                      }
                    );
                  }}
                />
              </Route>
              <Route exact path={`${preURL}/functionality/webchat/en-tête`}>
                <Header
                  w={w}
                  wWebchat={wWebchat}
                  state={state}
                  dispatch={dispatch}
                  handleImage={async (e, type) => {
                    dispatch({ type: 'setLoadUpload', payload: true });
                    await _uploadImg(
                      m.bot.id,
                      e,
                      (res) => {
                        _sendFeedback("success", m.w.success.saved);
                        dispatch({ type: 'setLoadUpload', payload: false });
                        dispatch({ type, payload: res.path });
                      },
                      (err) => {
                        dispatch({ type: 'setLoadUpload', payload: false });
                      }
                    );
                  }}
                />
              </Route>
              <Route exact path={`${preURL}/functionality/webchat/menu`}>
                <Menu
                  m={m}
                  w={w}
                  wWebchat={wWebchat}
                  state={state}
                  dispatch={dispatch}
                  bot={m.bot.id}
                  client={m.client}
                />
              </Route>
              <Route exact path={`${preURL}/functionality/webchat/qr`}>
                <Qr
                  w={w}
                  wWebchat={wWebchat}
                  state={state}
                  dispatch={dispatch}
                />
              </Route>
              <Route exact path={`${preURL}/functionality/webchat/champ-Texte`}>
                <Input
                  w={w}
                  wWebchat={wWebchat}
                  state={state}
                  dispatch={dispatch}
                  handleImage={async (e, type) => {
                    dispatch({ type: 'setLoadUpload', payload: true });
                    await _uploadImg(
                      m.bot.id,
                      e,
                      (res) => {
                        _sendFeedback("success", m.w.success.saved);
                        dispatch({ type: 'setLoadUpload', payload: false });
                        dispatch({ type, payload: res.path });
                      },
                      (err) => {
                        dispatch({ type: 'setLoadUpload', payload: false });
                      }
                    );
                  }}
                />
              </Route>
              <Route exact path={`${preURL}/functionality/webchat/caroussels`}>
                <Caroussels
                  w={w}
                  wWebchat={wWebchat}
                  state={state}
                  dispatch={dispatch}
                />
              </Route>
              <Route exact path={`${preURL}/functionality/webchat/webview`}>
                <Webview
                  state={state}
                  wWebchat={wWebchat}
                  dispatch={dispatch}
                />
              </Route>
              <Route exact path={`${preURL}/functionality/webchat/contact`}>
                <Contact
                  state={state}
                  wWebchat={wWebchat}
                  dispatch={dispatch}
                />
              </Route>
              <Route
                exact
                path={`${preURL}/functionality/webchat/bulles-messages`}
              >
                <Message
                  w={w}
                  wWebchat={wWebchat}
                  state={state}
                  dispatch={dispatch}
                  handleChangeAvatar={async (e, type) => {
                    dispatch({ type: 'setLoadUpload', payload: true });
                    await _uploadImg(
                      m.bot.id,
                      e,
                      (res) => {
                        _sendFeedback("success", m.w.success.saved);
                        dispatch({ type: 'setLoadUpload', payload: false });
                        dispatch({ type, payload: res.path });
                      },
                      (err) => {
                        dispatch({ type: 'setLoadUpload', payload: false });
                      }
                    );
                  }}
                />
              </Route>
              <Route exact path={`${preURL}/functionality/webchat/pleine-page`}>
                <Fullpage
                  wWebchat={wWebchat}
                  state={state}
                  dispatch={dispatch}
                />
              </Route>
              <Route exact path={`${preURL}/functionality/webchat/messagerie`}>
                <Messaging
                  wWebchat={wWebchat}
                  state={state}
                  dispatch={dispatch}
                  user={m.user.user}
                  botId={m.bot.id}
                  w={m.w}
                />
              </Route>
            </Switch>
          </ContainerScroll>
          {currentPage !== 'pleine-page' &&
            currentPage !== "page-d'accueil" &&
            currentPage !== 'messagerie' && (
                <Preview currentPage={currentPage} m={m} w={w} state={state} />
            )}
          {currentPage === 'pleine-page' && displayFullpage && (
            <PreviewFullpage state={state} wWebchat={wWebchat} />
          )}
          {currentPage === "page-d'accueil" && (
            <PreviewHomePage state={state} m={m} wWebchat={wWebchat} />
          )}
          {currentPage === 'messagerie' && (
            <PreviewMail state={state} wWebchat={wWebchat} />
          )}
        </>
      )}
      {!loadData && <Loader webchat />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
`;

const StyledNav = styled.nav`
  width: 100%;
  color: #2e2c80;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  transition: 0.6s;
`;

const active = 'active';

const LinkFunctionnality = styled(NavLink).attrs({ active })`
  cursor: pointer;
  border: ${(props) => `1px solid ${props.theme.colors.primary}`};
  border-radius: 5px;
  margin: 1rem;
  height: 20px;
  padding: 0.6rem;
  white-space: nowrap;
  box-shadow: rgba(45, 47, 123, 0.3) 0px 4px 9px 0px;
  color: ${(props) => props.theme.colors.primary};
  &.${active} {
    background-color: ${(props) => props.theme.colors.primary};
    color: #fff;
  }
  &::first-letter {
    text-transform: uppercase;
  }
`;

const WrapperNav = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => props.messaging && '0 2rem'};
`;

const WrapperPreview = styled.div`
  display: inline;
  position: absolute;
  right: 40px;
  > svg {
    cursor: pointer;
  }
`;

const IconNav = styled.div`
  background: #2e2c80;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
`;

export default Webchat;
