export default [
  {
    id: 1,
    value: 'le',
  },
  {
    id: 2,
    value: 'la',
  },
  {
    id: 3,
    value: 'les',
  },
  {
    id: 4,
    value: "l'",
  },
  {
    id: 5,
    value: 'du',
  },
  {
    id: 6,
    value: 'des',
  },
  {
    id: 7,
    value: 'de',
  },
  {
    id: 8,
    value: 'la',
  },
];
