import React, { useState } from 'react';
import moment from 'moment';
import SingleDate from './SingleDate';
import { Button, Icon } from '@ask-mona/ui';
import { withGlobals } from '../hoc/globals';

const SingleDateRange = ({
  g: { w },
  startDate,
  endDate,
  onFocus,
  setFixed,
  onChange,
  direction,
  date_min,
  date_max,
  setHide,
}) => {
  const [period, setPeriod] = useState(endDate ? true : false);
  const [addPeriod, setAddPeriod] = useState(false);
  const [dates, setDates] = useState({});
  const [minDate, setMinDate] = useState('');

  return (
    <div className={`flex ${direction || 'column'} items-center`}>
      <div className="m-r-xs">
        {`${period ? w.generic.from : w.generic.the}`}
      </div>
      <div className={`${direction === 'row' ? 'flex row items-center' : ''}`}>
        <SingleDate
          small
          name="startDate"
          placeholder={w.generic.dateStart}
          defaultDate={startDate ? moment(startDate) : ''}
          onChange={
            addPeriod
              ? (date) => {
                  setMinDate(date);
                  setDates(date);
                }
              : period
              ? (date) => {
                  setMinDate(date);
                  setDates(date);
                  onChange(date);
                }
              : (date) => {
                  setMinDate(date);
                  setDates(date);
                  onChange(date);
                  setHide(true);
                }
          }
          onFocus={onFocus}
          showClearDate
          date_min={date_min}
          date_max={date_max}
          firstVisibleMonth={startDate ? moment(startDate) : ''}
        />
      </div>
      {!period && (
        <Button
          className={`${
            direction === 'row' ? 'shrink is-size-6' : 'expand'
          } is-outlined is-borderless`}
          onClick={() => {
            setAddPeriod(true);
            setPeriod(true);
            setFixed && setFixed(true);
          }}
        >
          {direction === 'row' ? (
            <Icon icon="fas fa-plus" className="is-size-7" />
          ) : (
            <span className="is-size-7">{w.generic.addPeriod}</span>
          )}
        </Button>
      )}
      {period && (
        <>
          <div className="m-xs">{w.generic.to}</div>
          <div
            className={`${direction === 'row' ? 'flex row items-center' : ''}`}
          >
            <SingleDate
              small
              name="endDate"
              placeholder={w.generic.dateEnd}
              defaultDate={endDate ? moment(endDate) : ''}
              onChange={
                addPeriod
                  ? (date) => {
                      onChange({ date: [dates, date], name: 'period' });
                      setHide(true);
                    }
                  : (date) => {
                      onChange(date);
                      setHide(true);
                    }
              }
              onFocus={onFocus}
              showClearDate
              date_min={
                minDate.date
                  ? moment(minDate.date._d)
                  : startDate
                  ? moment(startDate)
                  : ''
              }
              date_max={date_max}
              firstVisibleMonth={
                minDate.date
                  ? minDate.date._d
                  : startDate
                  ? moment(startDate)
                  : ''
              }
            />
          </div>
        </>
      )}
      {period && (
        <Button
          className={`${
            direction === 'row' ? 'shrink is-size-6' : 'expand'
          } is-outlined is-borderless`}
          color="danger"
          onClick={() => {
            onChange({ date: null, name: 'endDate' });
            setPeriod(false);
            setAddPeriod(false);
          }}
        >
          <Icon icon="fas fa-minus" className="is-size-7" />
        </Button>
      )}
    </div>
  );
};

export default withGlobals(SingleDateRange);
