import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withApi } from '../../hoc/api';
import Bot from '../../_api/bot';
import { Cross } from '@styled-icons/entypo/Cross';
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';
import { Cancel } from '@styled-icons/material/Cancel';
import { _sendFeedback } from '../../_tools/ui';

const AddBot = ({ setAddBot, m, updateList, api, w }) => {
  const [name, setName] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const [newOrganization, setNewOrganization] = useState('');
  const [orgaSelected, setOrgaSelected] = useState('');
  const [types, setTypes] = useState([]);
  const [typeSelected, setTypeSelected] = useState([]);
  const [addOrga, setAddOrga] = useState(false);
  const [isMultisite, setIsMultisite] = useState(false);

  const botApi = new Bot(m.client);
  useEffect(() => {
    fetchTypes();
    fetchOrga();
  }, []);

  const fetchTypes = async () => {
    const res = await api.ttt._getTypes();
    if (res.success) {
      setTypes(res.getTypes.types);
    }
  };

  const fetchOrga = async () => {
    const res = await api.user._getOrganizations();
    if (res.success) {
      setOrganizations(res.getOrganizations);
    }
  };

  const createOrga = async () => {
    const res = await api.user._createOrganization({
      name: newOrganization,
    });
    if (res.success) {
      _sendFeedback('success', w.newOrgaCreated);
      setOrganizations([...organizations, res.createOrganization]);
      setAddOrga(false);
    } else {
      _sendFeedback('danger', w.error);
    }
  };

  const createBot = async () => {
    if (name && typeSelected && orgaSelected) {
      const res = await botApi._createBot({
        name,
        organization_id: Number(orgaSelected),
        types: isMultisite
          ? [Number(typeSelected), 30]
          : [Number(typeSelected)],
      });
      if (res.success) {
        const orga = organizations.find(
          (e) => e.id === Number(orgaSelected)
        )?.name;
        updateList(res.createBot, orga);
        const resFeature = await botApi._addBotFeature({
          bot_id: res.createBot.id,
          feature_id: 12,
          is_bot_active: true,
        });
        if (resFeature.success) {
          _sendFeedback('success', m.w.success.saved);
        }
      }
    } else {
      _sendFeedback('danger', w.fillFields);
    }
  };

  return (
    <Wrapper>
      <StyledCross size={26} onClick={() => setAddBot(false)} />
      <WrapperContent>
        <h3>{w.createBot}</h3>
        <WrapperInput>
          <StyledLabel>
            {w.name}
            <StyledInput
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </StyledLabel>
        </WrapperInput>
        <WrapperInput>
          {w.orga}
          {addOrga ? (
            <>
              <StyledLabel>
                <StyledInput
                  type="text"
                  value={newOrganization}
                  onChange={(e) => setNewOrganization(e.target.value)}
                />
              </StyledLabel>
              <ControlOrga>
                <StyledBtnOrga save onClick={() => createOrga()}>
                  {w.save} <CheckCircle size={20} />
                </StyledBtnOrga>
                <StyledBtnOrga onClick={() => setAddOrga(false)}>
                  {w.cancel} <Cancel size={20} />
                </StyledBtnOrga>
              </ControlOrga>
            </>
          ) : (
            <>
              <StyledSelect
                value={orgaSelected}
                onChange={(e) => {
                  setOrgaSelected(e.target.value);
                }}
              >
                <option>{w.chooseOrga}</option>
                {organizations.map((o) => (
                  <option key={o.id} value={o.id}>
                    {o.name}
                  </option>
                ))}
              </StyledSelect>
              <StyledCreateOrga onClick={() => setAddOrga(true)}>
                {w.createOrga} <PlusCircle size={16} />
              </StyledCreateOrga>
            </>
          )}
        </WrapperInput>
        <WrapperInput>
          <StyledLabel row>
            <StyledInput
              type="checkbox"
              checked={isMultisite}
              onChange={() => {
                setIsMultisite(!isMultisite);
              }}
              mr
            />
            {w.multiSites}
          </StyledLabel>
        </WrapperInput>

        <WrapperInput>
          <StyledLabel>
            {w.type}
            <StyledSelect
              value={typeSelected}
              onChange={(e) => {
                setTypeSelected(e.target.value);
              }}
            >
              <option>{w.chooseType}</option>
              {types
                .filter((el) => el.id !== 30)
                .map((t) => {
                  const languageCode = localStorage.getItem("languageCode");

                  const languageId = (languageCode === "fr" && 1) ||
                    (languageCode === "en" && 2) || undefined;

                  const translation = t.translations
                    .find(({ language_id }) => language_id === languageId) || t.translations[0];
                  const content = translation.name;
                  return (
                    <option key={t.id} value={t.id}>
                      {content}
                    </option>
                  )
                })}
            </StyledSelect>
          </StyledLabel>
        </WrapperInput>
        <StyledButton onClick={() => createBot()}>{w.validate}</StyledButton>
      </WrapperContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  background-color: #fff;
  color: ${(props) => props.theme.colors.primary};
  width: 450px;
  height: 450px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
`;

const WrapperContent = styled.div`
  display: flex;
  width: 80%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
`;

const StyledCross = styled(Cross)`
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
`;

const StyledButton = styled.div`
  background-color: #e06d6d;
  color: #fff;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
`;

const WrapperInput = styled.div`
  margin: 0.6rem;
  width: 100%;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: ${({ row }) => !row && 'column'};
  align-items: ${({ row }) => row && 'center'};
`;

const StyledSelect = styled.select`
  place-self: center;
  width: 100%;
  height: 35px;
  padding: 0.2rem 0.3rem;
  border: 1px solid #2d2f7b;
  color: ${(props) => props.theme.colors.primary};
  &:focus {
    outline: none;
  }
`;

const StyledInput = styled.input`
  height: 30px;
  color: ${(props) => props.theme.colors.primary};
  font-size: 1em;
  padding: 0.2rem 0.6rem;
  margin-right: ${(mr) => mr && '.4rem'};
  outline: 0;
  border-width: 0 0 2px;
  border-color: ${(props) => props.theme.colors.primary};

  &:focus {
    border-color: ${(props) => props.theme.colors.secondary};
  }
`;

const ControlOrga = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.6rem;
`;

const StyledBtnOrga = styled.p`
  cursor: pointer;
  margin-right: 0.4rem;
  transition: all 0.3s;

  &:hover {
    color: ${({ save }) => (save ? 'green' : 'red')};
    transform: scale(1.1);
  }
`;

const StyledCreateOrga = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-top: 0.4rem;
`;

export default withApi(AddBot);
