import React from 'react';
import Loader from '../../components/Loader';

function DataAnalytics({ wording, extra, analytics, name, time }) {
  if (!analytics.users) return null;
  const duration = (analytics) => {
    const res = [];
    const duration = analytics.users.sessions.duration.average;
    const d = duration.split(':');
    if (d[0] !== 0) {
      res.push(d[0] + 'h');
    }
    res.push(d[1]);
    res.push('m'.toLowerCase());
    res.push(d[2]);
    res.push('s'.toLowerCase());
    const join = res.join('');
    return join;
  };

  let bool = !analytics.ga_analytics ? false : true;
  if (bool) bool = !analytics.ga_analytics['Event Action'] ? false : true;

  return (
    <React.Fragment>
      <h6 className="p-t-xs text-align-center has-text-weight-light">{time}</h6>
      <h3 className="p-t-xs is-size-7 text-align-center is-uppercase">
        {wording}
      </h3>
      {extra && (
        <h6 className="p-t-xxs text-align-center has-text-weight-light">
          {extra}
        </h6>
      )}
      {!analytics && <Loader />}
      {analytics && name === 'nb_users' && (
        <span className="m-md is-size-5 text-align-center">
          {analytics.users.count.total}
        </span>
      )}
      {analytics && name === 'duration' && (
        <span className="m-md is-size-5 text-align-center is-lowercase">
          {duration(analytics)}
        </span>
      )}
      {analytics && name === 'nb_sessions_users' && (
        <span className="m-md is-size-5 text-align-center">
          {analytics.users.sessions.session_number_by_user.average.toFixed(2)}
        </span>
      )}
      {analytics && bool && name === 'nb_clic_evts' && (
        <span className="m-md is-size-5 text-align-center">
          {analytics.ga_analytics['Event Action'].total}
        </span>
      )}
      {analytics && !bool && name === 'nb_clic_evts' && (
        <span className="m-md is-size-5 text-align-center">0</span>
      )}
    </React.Fragment>
  );
}

export default DataAnalytics;
