import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Icon } from '@ask-mona/ui';
import StarRoundedIcon from '@material-ui/icons/StarRounded';

import ScenariosContext from '../../containers/scenarios/context/scenariosContext';

const FunctionalitiesSubsbar = (props) => {
  const {
    m: {
      w,
      user: { organization },
    },
    focus,
  } = props;

  const [focusKey, setFocusKey] = useState(focus && focus.key ? focus.key : '');

  const scenariosContext = useContext(ScenariosContext);

  const handleFocus = (el) => {
    scenariosContext.setFunctionalitiesSubsbarFocus(el);
  };

  useEffect(() => {
    if (focus && focus.key && focusKey !== focus.key) {
      setFocusKey(focus.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus]);

  useEffect(() => {
    scenariosContext.setFunctionalitiesSubsbarFocus('content');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusKey]);

  return (
    <RootContainer className="inline-block overflow-auto w-20p align-top space-initial">
      {organization.id === 1 && (
        <Legend>
          <StarRoundedIcon style={{ fontSize: 16 }} color="secondary" /> :{' '}
          {w.account.askMonaOnly}
        </Legend>
      )}
      {organization.id === 1 && (
        <Section
          focus={'content' === scenariosContext.functionalitiesSubsbarFocus}
          el={'content'}
          handleFocus={handleFocus}
          askMona={true}
          content={true}
          w={w}
        />
      )}
      <SectionSeparatorContainer>
        <SectionSeparator />
      </SectionSeparatorContainer>
      {scenariosContext.functionalitiesSubsbar &&
        [
          ...Object.keys(scenariosContext.functionalitiesSubsbar).filter((el) =>
            !scenariosContext.functionalitiesSubsbar[el]['parent']
              ? organization.id === 1
                ? true
                : scenariosContext.functionalitiesSubsbar[el]['edit']
                ? true
                : false
              : false
          ),
        ].map((el) => (
          <Section
            focus={el === scenariosContext.functionalitiesSubsbarFocus}
            el={el}
            handleFocus={handleFocus}
            askMona={
              organization.id === 1 &&
              scenariosContext.functionalitiesSubsbar[el] &&
              !scenariosContext.functionalitiesSubsbar[el]['edit']
            }
            w={w}
          />
        ))}
    </RootContainer>
  );
};

export default FunctionalitiesSubsbar;

const Section = ({ w, focus, el, handleFocus, askMona, content }) => {
  const scenariosContext = useContext(ScenariosContext);

  return (
    <SectionContainer onClick={() => handleFocus(el)} focus={focus}>
      <SectionContent focus={focus} askMona={askMona} content={content}>
        {el === 'content'
          ? w.scenario.moduleContent
          : scenariosContext.functionalitiesSubsbar[el]['name']}
      </SectionContent>
      {askMona && (
        <StarIconContainer focus={focus}>
          <StarRoundedIcon style={{ fontSize: 18 }} color="secondary" />
        </StarIconContainer>
      )}
      {focus && (
        <Icon icon="fas fa-chevron-right" className="has-text-secondary" />
      )}
    </SectionContainer>
  );
};

const RootContainer = styled.div`
  border-right: 1px solid #ececec;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 5px;
  min-width: 150px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-wrap: anywhere;
  align-items: center;
  width: 100%;
  color: ${(p) =>
    p.focus ? p.theme.colors.secondary : p.theme.colors.primary};
  cursor: pointer;
  margin-top: 20px;
  margin-right: 5px;
`;

const SectionContent = styled.div`
  position: relative;
  display: flex;
  width: 80%;
  flex-wrap: center;
  word-break: keep-all;
  align-items: center;
  text-decoration: ${(p) =>
    p.focus ? `underline ${p.theme.colors.secondary}` : 'none'};
  ${(p) => p.content && 'font-size: 1.1rem'};
  ${(p) => p.content && 'text-align: center'};
`;
const SectionSeparatorContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const SectionSeparator = styled.div`
  width: 70px;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-right: 20px;
  background-color: #ececec;
`;

const StarIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  margin-right: ${(p) => (p.focus ? '0' : '24px')};
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.primary};
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 0.8rem;
  margin-left: -20px;
`;
