import React, { Component } from 'react';
import Date from './timePush/Date';
import Hour from './timePush/Hour';
import Modal from '../../../components/Modal';
import { Button } from '@ask-mona/ui';
import { withMain } from '../../../hoc/main';

class ModalTime extends Component {
  render() {
    const {
      m: { w },
      show,
      close,
      fct,
      error,
      date,
    } = this.props;
    return (
      <Modal isOpen={show} close={close} selector="#root">
        <div className="flex column w-40p h-20 has-background-white box-shadow">
          <div className="flex column center items-center has-background-primary-lighter p-md has-text-primary is-size-7">
            <h3>{w.push.contentTime}</h3>
            <p>{w.push.subtitleTime}</p>
          </div>
          <div className="flex column p-md">
            <div className="p-b-md">
              <Date
                onChange={fct.changeDate}
                w={w.push.dateTime}
                date={date.date}
                error={error.errorDate}
              />
            </div>
            <div className="modalTime__time-hour">
              <Hour
                onChangeHour={fct.changeHour}
                w={w}
                date={date}
                error={error}
              />
            </div>
          </div>
          <div className="flex row space-between">
            <Button
              className="m-md"
              color="danger"
              onClick={fct.toggleModalTime}
            >
              {' '}
              {w.generic.cancel}{' '}
            </Button>
            <Button className="m-md" onClick={fct.saveDate}>
              {w.generic.validate}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withMain(ModalTime);
