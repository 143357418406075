import React, { useReducer, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Foundations from '../components/base/Foundations';
import Sidebar, { Sub } from '../components/base/Sidebar';
import Header from '../components/base/Header';
import Loader from '../components/Loader';
import Nodata from '../components/Nodata';
import ErrorBoundary from '../components/ErrorBoundary';
import NavPrompt from '../components/NavPrompt';
import Advanced from '../containers/mediation/Advanced';
import { withMain } from '../hoc/main';
import { withApi } from '../hoc/api';
import FlagIcon from '../components/FlagIcon';
import { Button } from '@ask-mona/ui';
import { artwork as sections, _sendFeedback } from '../_tools/ui';
import { _defaultTranslation, _formatTranslation } from '../_tools/translation';
//Section Component
import Information from './artwork/Information';
import Trigger from './artwork/Trigger';
import { _reducer, initialState } from './artwork/Reducer';
import {
  _reducer as reducerScenario,
  initialState as intialStateScenario,
} from '../components/scenario/Reducer';
import { LangSwitcher } from '../components/scenario/header/LangSwitcher';
import { SaveButton } from '../components/scenario/header/SaveButton';

const Artwork = (props) => {
  const {
    m: { w, lang, bot },
  } = props;

  const [state, dispatch] = useReducer(_reducer, initialState);
  const [scenarioState, scenarioDispatch] = useReducer(
    reducerScenario,
    intialStateScenario
  );
  const [sectionsAddons, setSectionsAddons] = useState(sections);
  let artId = props.match.params.id;
  let medId = props.match.params.medId;

  useEffect(() => {
    !artId
      ? setSectionsAddons([{ id: 0, icon: 'fas fa-info', w: 'artInformation' }])
      : setSectionsAddons(sections);
  }, [artId]);

  useEffect(() => {
    const fetchArtwork = async () => {
      let api = props.api.artwork;
      let artId = props.match.params.id;
      if (artId) {
        dispatch({ type: 'setLoading', payload: true });
        let res = await api._getArtwork({ id: Number(artId) });
        if (res.success) {
          dispatch({ type: 'setArtwork', payload: res.getArtwork });
          let langs = res.getArtwork?.translations.map(
            (tr) => tr.language.code
          );
          dispatch({ type: 'setLangs', payload: langs });
        }
        dispatch({ type: 'setLoading', payload: false });
      } else {
        dispatch({
          type: 'setArtwork',
          payload: {
            cover_image: null,
            translations: [
              {
                name: '',
                short_title: '',
                long_title: '',
                anecdote: '',
                date: '',
                description: '',
                more_infos: '',
                practical_infos: '',
                price: '',
                url: '',
                url_ticketing: '',
                language_id: 1,
                language: {
                  code: 'fr',
                },
              },
            ],
            authors: [],
            images: [],
            tags: [],
            slug: '',
          },
        });
      }
      dispatch({ type: 'setLoading', payload: false });
    };
    fetchArtwork();
  }, []);

  const { loading, subID, error } = state;
  let title = '';
  if (state.artwork) {
    let tr = _formatTranslation(
      state.artwork.translations || [_defaultTranslation(bot.default_language)]
    )[lang];
    title = tr?.name.value;
  }

  return (
    <Foundations
      aside={
        <Sidebar
          c={state}
          addons={sectionsAddons.map((s) => (
            <Sub
              key={s.id}
              sub={{ ...s, title: w.subsections[s.w] }}
              active={subID === s.id}
              onClick={() => dispatch({ type: 'setSubID', payload: s.id })}
            />
          ))}
        />
      }
      header={
        <Header
          title={`${w.generic.mediation}`}
          subtitle={title || w.artwork.defaultTitle}
          addons={
            subID !== 1 ? (
              <Head
                {...props}
                {...state}
                dispatch={dispatch}
                state={state}
                artId={artId}
                medId={medId}
              />
            ) : (
              [
                <LangSwitcher
                  {...scenarioState}
                  {...props}
                  dispatch={scenarioDispatch}
                />,
                <SaveButton
                  {...scenarioState}
                  {...props}
                  dispatch={scenarioDispatch}
                  addToSave={async () => {
                    const { api } = props;
                    const { artwork } = state;
                    if (artId) {
                      let Api = api.artwork;
                      const { translations, images, ...variables } = artwork;
                      let args = Api._formatVariables(variables);
                      let res = null;
                      _sendFeedback('warning', 'loader');
                      res = await Api._updateArtwork({ ...args });
                      if (res.success)
                        _sendFeedback('success', w.success.saved);
                      else _sendFeedback('danger', w.error.general);
                      if (translations && translations.length) {
                        _sendFeedback('warning', 'loader');
                        translations.forEach(async (t) => {
                          const {
                            language_id,
                            language: { id },
                            name,
                          } = t;
                          res = await Api._setArtworkTranslation({
                            name: name,
                            language_id: id || language_id,
                            artwork_id: args.id,
                          });
                        });
                        if (res.success)
                          _sendFeedback('success', w.success.saved);
                        else _sendFeedback('danger', w.error.general);
                      }
                    }
                  }}
                />,
              ]
            )
          }
        />
      }
    >
      {loading && <Loader className="has-text-primary-ter" />}
      {error && (
        <Nodata
          title={w.error.general}
          subtitle={process.env.REACT_APP_DEV ? error.message : ''}
        />
      )}
      {!error && !loading && subID === 0 && (
        <ErrorBoundary>
          <Information {...props} {...state} dispatch={dispatch} />
        </ErrorBoundary>
      )}
      {!error && !loading && subID === 1 && artId && (
        <ErrorBoundary>
          <Advanced
            {...props}
            state={scenarioState}
            dispatch={scenarioDispatch}
            subID={1}
            force={`mediation${medId}`}
            subforce={`artwork${artId}`}
            folder={`artwork${artId}`}
          />
        </ErrorBoundary>
      )}
      {!error && !loading && subID === 2 && artId && (
        <ErrorBoundary>
          <Trigger {...props} {...state} dispatch={dispatch} />
        </ErrorBoundary>
      )}
      <NavPrompt when={state.hasChange} confirm={() => console.log('Save')} />
    </Foundations>
  );
};

const Head = ({
  m: { w, bot_lang },
  langs,
  history,
  api,
  subID,
  artwork,
  dispatch,
  state,
  artId,
  medId,
}) => {
  return (
    <div className="m-l-md flex row grow-1 flex-end">
      {bot_lang.map((l) => {
        if (l === 'fr') return null;
        if (langs.some((lang) => lang === l)) return null;
        return (
          <Button
            key={l}
            onClick={() => dispatch({ type: 'addLang', payload: l })}
            className="is-outlined is-borderless shrink m-r-xs"
          >
            <FlagIcon code={l === 'en' ? 'gb' : l} />
          </Button>
        );
      })}
      <ReturnButton to={`/office/mediation/${medId}`}>
        {w.generic.return}
      </ReturnButton>
      {subID !== 1 && (
        <Button
          className="m-l-xs"
          color="primary-bis"
          onClick={async () => {
            if (!artId) {
              let Api = api.artwork;
              let res = await Api._createArtwork({
                mediation_id: Number(medId),
                slug: artwork.translations[0].name,
              });
              if (res.success) {
                const artworkId = res.createArtwork.id;
                dispatch({
                  type: 'setArtwork',
                  payload: {
                    ...state.artwork,
                    id: artworkId,
                  },
                });

                let { translations, images, ...variables } = state.artwork;
                variables = { ...variables, id: artworkId };
                let args = Api._formatVariables(variables);
                let resUpdate = null;
                _sendFeedback('warning', 'loader');
                resUpdate = await Api._updateArtwork({ ...args });
                if (resUpdate.success)
                  _sendFeedback('success', w.success.saved);
                else _sendFeedback('danger', w.error.general);
                if (translations && translations.length) {
                  _sendFeedback('warning', 'loader');
                  translations.forEach(async (t) => {
                    const {
                      language_id,
                      language: { id },
                      name,
                    } = t;
                    await Api._setArtworkTranslation({
                      name: name,
                      language_id: id || language_id,
                      artwork_id: args.id,
                    });
                  });
                }
                history.push(
                  `/office/artwork/${medId}/${res.createArtwork.id}`
                );
                _sendFeedback('success', w.success.created);
              } else _sendFeedback('danger', w.error.general);
            }
            if (artId) {
              let Api = api.artwork;
              const { translations, images, ...variables } = artwork;
              let args = Api._formatVariables(variables);
              let res = null;
              _sendFeedback('warning', 'loader');
              res = await Api._updateArtwork({ ...args });
              if (res.success) _sendFeedback('success', w.success.saved);
              else _sendFeedback('danger', w.error.general);
              if (translations && translations.length) {
                _sendFeedback('warning', 'loader');
                translations.forEach(async (t) => {
                  const {
                    language_id,
                    language: { id },
                    name,
                  } = t;
                  res = await Api._setArtworkTranslation({
                    name: name,
                    language_id: id || language_id,
                    artwork_id: args.id,
                  });
                });
                if (res.success) _sendFeedback('success', w.success.saved);
                else _sendFeedback('danger', w.error.general);
              }
            }
          }}
        >
          {w.generic.save}
        </Button>
      )}
    </div>
  );
};

const ReturnButton = styled(NavLink)`
  border: 2px solid #9592c9;
  background-color: #a6a3d2;
  color: white;
  display: flex;
  padding: 0.25em 1em;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  font-size: 1em;
  border-radius: 3px;
`;

export default withMain(withApi(Artwork));
