import React, { useState } from 'react';
import ScheduleAlert from '../ScheduleAlert';
import { Textarea, Button } from '@ask-mona/ui';
import { withMain } from '../../../../hoc/main';

function Edit(props) {
  const {
    item,
    index,
    display,
    fct,
    periods,
    error,
    state,
    m: { w },
  } = props;

  const [date, setDate] = useState(false);
  const [period, setPeriod] = useState(false);

  const addDate = () => {
    date ? setDate(false) : setDate(true);
    setPeriod(false);
  };

  const addPeriod = () => {
    period ? setPeriod(false) : setPeriod(true);
    setDate(false);
  };

  return (
    <div
      className="h-3"
      style={!display ? { visibility: 'hidden' } : { visibility: 'visible' }}
    >
      <div className="flex row m-xs border-dashed has-background-white h-4 p-xs is-size-8 has-text-weight-light">
        <div className="w-5 p-r-xs border-right-dashed">
          <div className="h-1 m-b-md is-size-6">{w.alertMessages.content}</div>
          <Textarea
            placeholder={w.alertMessages.addContent}
            className={!item.content.fr[0] ? 'createAlert__danger' : ''}
            onChange={(e) => fct.updateContent(e.target.value, index)}
            resize={'none'}
            rows="5"
            value={item.content.fr[0]}
          />
        </div>
        <div className="w-7 p-l-xs flex column">
          <div className="flex row space-between p-b-xs is-size-6">
            <p>{w.alertMessages.ScheduleAlert}</p>
            {index === state.loader.index && state.loader.bool && (
              <Button loading>{w.alertMessages.validate}</Button>
            )}
            {!state.loader.bool && (
              <Button onClick={() => fct.updateAlert(index, 'update')}>
                {w.alertMessages.validate}
              </Button>
            )}
          </div>
          <div className="border_dashed h-6 overflow-auto">
            <ScheduleAlert
              date={date}
              period={period}
              addDate={addDate}
              addPeriod={addPeriod}
              error={error}
              periods={periods}
              fct={fct}
              update={true}
              index={index}
              item={item}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withMain(Edit);
