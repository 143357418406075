import React, { Component } from 'react';
import Tab from './targetPush/Tab';
import { withMain } from '../../../hoc/main';
//API
import { _suggestTags, _showAllPushs } from '../../../_api/push';

const criteria = [
  {
    id: 0,
    title: { fr: 'Genres', en: 'Gender' },
    items: [
      { id: 0, gender: { fr: 'Femme', en: 'Female' } },
      { id: 1, gender: { fr: 'Homme', en: 'Male' } },
    ],
  },
  { id: 1, title: { fr: "Date d'arrivée de l'utilisateur", en: '' } },
  {
    id: 2,
    title: { fr: "Nombre d'utilisation du chatbot", en: 'Number of uses' },
  },
  {
    id: 3,
    title: { fr: "Langue de l'utilisateur", en: 'Languages' },
    items: [
      { id: 0, lng: { fr: 'Français', en: 'French' } },
      { id: 1, lng: { fr: 'Anglais', en: 'English' } },
    ],
  },
  { id: 4, title: { fr: 'Suggestion de tags', en: 'Suggest tags' } },
];

class Target extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggest: null,
      modalCreate: false,
      modalEdit: false,
      edit: false,
      arrCriteria: [],
      variables: {
        name: '',
        text: '',
        opt: '',
        optEdit: '',
        allOpt: [],
        error: {
          errorName: '',
          errorText: '',
          errorOpt: '',
        },
      },
      presetOptions: null,
      fct: {
        openModalCreate: this.openModalCreate.bind(this),
        openModalEdit: this.openModalEdit.bind(this),

        onChange: this.onChange.bind(this),

        onClickOpt: this.onClickOpt.bind(this),
        onClickChange: this.onClickChange.bind(this),
        onClickDelete: this.onClickDelete.bind(this),

        addCriteria: this.addCriteria.bind(this),
        updateCriteria: this.updateCriteria.bind(this),
        deleteCriteria: this.deleteCriteria.bind(this),
      },
    };
    this.suggestTags = this.suggestTags.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const {
      m: { bot },
    } = this.props;
    await this.suggestTags(bot.id);
    await this.getPresetOptions();
  }

  async suggestTags(botID) {
    const d = await _suggestTags(botID);
    await fetch(d.url, d.req)
      .then((res) => res.json())
      .then((data) => {
        if (data && data.length > 0) {
          let arr = data.map((item) => {
            return { value: item };
          });
          this.setState({ ...this.state, suggest: arr });
        } else {
          this.setState({ ...this.state, suggest: [] });
        }
      });
  }

  //MODAL CREATE OPEN/CLOSE
  openModalCreate = () => {
    this.setState({
      modalCreate: true,
    });
  };

  closeModalCreate = () => {
    this.setState({
      modalCreate: false,
    });
  };

  //MODAL EDIT OPEN/CLOSE
  openModalEdit = () => {
    this.setState({
      modalEdit: true,
    });
  };

  closeModalEdit = () => {
    this.setState({
      modalEdit: false,
    });
  };

  async getPresetOptions() {
    const {
      m: { bot },
    } = this.props;
    const d = await _showAllPushs(bot.id);
    await fetch(d.url, d.req)
      .then((res) => res.json())
      .then((data) => {
        this.setState({ presetOptions: data.presetOptions });
      });
  }

  //FCT
  onChange(item, name) {
    const { allOpt } = this.state.variables;
    var variables = Object.assign(this.state.variables);
    if (name === 'title') {
      variables.name = item;
      variables.error.errorName = '';
    } else if (name === 'text') {
      variables.text = item;
      variables.error.errorText = '';
    } else if (name === 'opt') {
      variables.opt = item;
      variables.error.errorOpt = allOpt.length > 1 ? '' : null;
    } else if (name === 'optEdit') {
      variables.optEdit = item;
    }
    this.setState({ variables });
  }

  onClickOpt() {
    const { opt, allOpt } = this.state.variables;
    //add option dans la BDD
    let arr = allOpt;
    arr.push(opt);
    this.setState({
      variables: { ...this.state.variables, allOpt: arr, opt: '' },
    });
  }

  onClickChange(item) {
    const { optEdit, allOpt } = this.state.variables;
    for (var i = 0; allOpt.length > i; i++) {
      if (allOpt[i] === item) {
        allOpt[i] = optEdit;
      }
    }
    this.setState({ variables: { ...this.state.variables, allOpt: allOpt } });
  }

  onClickDelete(i) {
    const { allOpt } = this.state.variables;
    allOpt.splice(i, 1);
    this.setState({ variables: { ...this.state.variables, allOpt: allOpt } });
  }

  addCriteria() {
    const {
      arrCriteria,
      variables: { name, text, allOpt },
    } = this.state;
    let newCriteria;
    if (!name)
      this.setState({
        variables: {
          ...this.state.variables,
          error: { errorName: 'Ajouter un Nom' },
        },
      });
    else if (!text)
      this.setState({
        variables: {
          ...this.state.variables,
          error: { errorText: 'Ajouter une question' },
        },
      });
    else if (allOpt.length < 2)
      this.setState({
        variables: {
          ...this.state.variables,
          error: { errorOpt: 'Ajouter au moins deux options' },
        },
      });
    //IF ALL CRITERIA IS OK ADD NEW CRITERIA BDD
    else {
      newCriteria = { title: name, question: text, opts: allOpt };
      arrCriteria.push(newCriteria);
      this.setState({
        ...this.state,
        arrCriteria,
        variables: {
          name: '',
          text: '',
          opt: '',
          allOpt: [],
          error: { errorName: '', errorText: '', errorOpt: '' },
        },
      });
      this.closeModalCreate();
    }
  }

  updateCriteria(value, name, index, indexOpt) {
    let {
      arrCriteria,
      variables: { opt },
    } = this.state;
    if (name === 'title') {
      arrCriteria.forEach((item, i) => {
        if (index === i) item.title = value;
      });
    } else if (name === 'question') {
      arrCriteria.forEach((item, i) => {
        if (index === i) item.question = value;
      });
    } else if (name === 'opts') {
      arrCriteria.forEach((item, i) => {
        if (index === i) item.opts.push(opt);
      });
    } else if (name === 'optEdit') {
      arrCriteria.forEach((item, i) => {
        if (index === i) {
          item.opts[indexOpt] = value;
        }
      });
    }
    this.setState({ arrCriteria });
  }

  deleteCriteria(index) {
    const { arrCriteria } = this.state;
    arrCriteria.forEach((item, i) => {
      if (index === i) arrCriteria.splice(i, 1);
    });
    this.setState({ arrCriteria });
  }

  render() {
    const {
      m: { w, bot },
      lng,
      date,
      fonction,
      save,
      arrTags,
      status,
    } = this.props;
    const { suggest, presetOptions } = this.state;

    return (
      <div
        className={!status || save ? 'flex column disabled' : 'flex column'}
        style={!status || save ? { opacity: '.5' } : { opacity: '1' }}
      >
        <div className="flex column center h-1 items-center has-background-primary-lighter p-md has-text-primary">
          <h3 className="is-size-7">{w.push.content}</h3>
          <p className="is-size-7">{w.push.subtitle}</p>
        </div>
        <div className="flex column border-tab m-md">
          <div className="flex has-background-primary-lighter w-100p has-text-primary is-size-7">
            <h3 className="m-xs">{w.push.target}</h3>
          </div>
          <div className="overflow-none">
            {criteria.map((data, i) => (
              <Tab
                key={i}
                data={data}
                lng={lng}
                fct={fonction}
                variables={date}
                botID={bot.id}
                suggest={suggest}
                arrTags={arrTags}
                preset={presetOptions}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withMain(Target);
