import React, { Component } from 'react';
import { withMain } from '../../hoc/main';
import PhoneCase from './formPushMain/PhoneCase';
import NewPush from './formPushMain/NewPush';
import TargetPush from './formPushMain/Target';

class FormPushMain extends Component {
  switchSection = (view_id) => {
    const {
      data,
      variables,
      fct,
      arrTags,
      status,
      id,
      variables: {
        contentPush: { save, title },
      },
    } = this.props.context;
    switch (view_id) {
      case 0:
        return (
          <NewPush
            state={this.props.context}
            date={variables.contentPush}
            bot_id={data.bot_id}
            create={id}
            title={title}
            save={save}
          />
        );
      case 1:
        return (
          <TargetPush
            fonction={fct}
            date={variables.criteriaPush}
            save={variables.contentPush.save}
            lng={data.lang}
            arrTags={arrTags}
            status={status}
          />
        );
      default:
        return (
          <div className="has-all-centered">
            <span className="icon is-large has-text-white">
              <i className="fas fa-3x fa-wrench"></i>
            </span>
          </div>
        );
    }
  };

  render() {
    const { data, variables, fct, subID, array, status } = this.props.context;
    return (
      <div className="is-view w-100p is-splitted border-top">
        <div className="main scrollable">{this.switchSection(subID)}</div>
        {subID !== 1 && subID !== 2 && (
          <PhoneCase
            data={variables}
            lang={data.lang}
            event={variables.event}
            translations={variables.translations}
            fct={fct}
            array={array}
            status={status}
          />
        )}
      </div>
    );
  }
}

export default withMain(FormPushMain);
