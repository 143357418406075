import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import InfoPart from './featuresPanel/InfoPart';
import ScenarioPart from './featuresPanel/ScenarioPart';
import CheckboxBool from './featuresPanel/CheckboxBool';
import { withApollo } from 'react-apollo';
import { UPDATE_CARROUSEL, UPDATE_CARDLIST } from '../../../_api/checklist';

export const items = [
  { fr: 'Ask Mona', en: 'Ask Mona' },
  { fr: 'Tout le monde', en: 'Everyone' },
];

class FeaturesPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idFeatures: null,
    };

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(value, name) {
    const { client } = this.props;
    let bool = value === 'Ask Mona' ? true : false;

    if (name === 'carrousel') {
      client
        .mutate({
          mutation: UPDATE_CARROUSEL,
          variables: {
            bot_id: this.props.bot_id,
            carrousel: bool,
          },
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      client
        .mutate({
          mutation: UPDATE_CARDLIST,
          variables: {
            bot_id: this.props.bot_id,
            card_list: bool,
          },
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    //const allItems = items.map(b => { return { value: b[this.props.lang]}});
    const {
      idFeatures,
      open,
      price,
      itinerary,
      prog,
      nlp,
      scenario,
      adv_scenario,
      messaging,
      mediation,
      targeting,
      fct,
    } = this.props.state;
    let boolInfo = '';
    let boolScenario = '';

    if (open.bool && price.bool && itinerary.bool) boolInfo = true;
    else boolInfo = false;

    if (nlp.bool && scenario.bool && adv_scenario.bool) boolScenario = true;
    else boolScenario = false;

    if (!idFeatures) return <Loader className="has-text-primary" />;
    return (
      <React.Fragment>
        <div className="flex row space-between">
          <div className="w-50">
            <div className="m-md">
              <CheckboxBool
                classname="has-text-weight-semibold"
                label="Info pratiques"
                bool={boolInfo}
                onCreate={fct.createFeatures}
                onDelete={fct.deleteFeatures}
                data={[2, 3, 5]}
              />
              <InfoPart
                idFeatures={idFeatures}
                context={this.props.state}
                fct={fct}
              />
            </div>
            <div className="m-md">
              <CheckboxBool
                classname="has-text-weight-semibold"
                label="Programmation"
                bool={prog.bool}
                onCreate={fct.createFeatures}
                onDelete={fct.deleteFeatures}
                data={[1]}
              />
            </div>
            <div className="m-md">
              <CheckboxBool
                classname="has-text-weight-semibold"
                label="Scenario"
                bool={boolScenario}
                onCreate={fct.createFeatures}
                onDelete={fct.deleteFeatures}
                data={[7, 8, 15]}
              />
              <ScenarioPart
                idFeatures={idFeatures}
                context={this.props.state}
                onCreate={fct.createFeatures}
                onDelete={fct.deleteFeatures}
              />
            </div>
            <div className="m-md">
              <CheckboxBool
                classname="has-text-weight-semibold"
                label="Mediation"
                bool={mediation.bool}
                onCreate={fct.createFeatures}
                onDelete={fct.deleteFeatures}
                data={[10]}
              />
            </div>
            <div className="m-md">
              <CheckboxBool
                classname="has-text-weight-semibold"
                label="Messagerie"
                bool={messaging.bool}
                onCreate={fct.createFeatures}
                onDelete={fct.deleteFeatures}
                data={[9]}
              />
            </div>
            <div className="m-md">
              <CheckboxBool
                classname="has-text-weight-semibold"
                label="Targeting Push"
                bool={targeting.bool}
                onCreate={fct.createFeatures}
                onDelete={fct.deleteFeatures}
                data={[10]}
              />
            </div>
          </div>
          {/* <div className="w-50">
						<div className="m-md">
							<p className="has-text-weight-semibold has-text-primary-ter m-b-md">Création de carroussels</p>
							<Select onSelect={(e) => this.onSelect(e.value, "carrousel")} placeholder="AskMona" items={allItems} noSearch={true}/>
						</div>
						<div className="m-md">
							<p className="has-text-weight-semibold has-text-primary-ter m-b-md">Création de cartes listes</p>
							<Select onSelect={(e) => this.onSelect(e.value, "card_list")} placeholder="AskMona" items={allItems} noSearch={true}/>
						</div>
					</div> */}
        </div>
      </React.Fragment>
    );
  }
}

export default withApollo(FeaturesPanel);
