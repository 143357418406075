import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const noop = () => <div>null</div>;

export default ({
  component: Component,
  render,
  check,
  redirectTo,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      return check() ? (
        Component ? (
          <Component {...props} />
        ) : (
          (render || noop)()
        )
      ) : (
        <Redirect to={{ pathname: redirectTo }} />
      );
    }}
  />
);
