export default {
  analytics: ["Analytics", "Analytics"],
  information: ['Infos pratiques', 'Practical information'],
  programmation: ['Programmation', 'Agenda'],
  scenarios: ['Scénarios', 'Scenarios'],
  qa: ['Questions / réponses', 'Questions answers'],
  custom: ['Personnalisation', 'Customization'],
  inbox: ['Messagerie', 'Messaging'],
  mediation: ['Médiation', 'Mediation'],
  personal: ['Paramètres personnels', 'Personal settings'],
  test: ['Test modèle', 'Model test'],
  checklist: ['Checklist', 'Checklist'],
  homepage: ['Accueil', 'Home'],
  pushs: ['Push', 'Push'],
  settings: ["Paramètres", "Settings"],
};
