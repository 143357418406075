const type = Object.freeze({
  isUNKNOWN: 0,
  isIMAGE: 1,
  isVIDEO: 2,
  isYOUTUBE: 3,
  isDAILYMOTION: 4,
});

export const _findType = (str) => {
  const ret = { code: 0, ...type };
  if (_isImg(str)) ret.code = 1;
  else if (_isVid(str) || _isYoutube(str) || _isDailymotion(str)) ret.code = 2;
  return ret;
};

export const _isImg = (str) => {
  return /(?!\s)https?:\/\/.*(.png|.jpg|.gif)/.test(str);
};

export const _isVid = (str) => {
  return /(?!\s)https?:\/\/.*(.mov|.mp4|.ogg|.webm)/.test(str);
};

export const _isYoutube = (str) => {
  return /https:\/\/(www.)?youtu(\.be|be.com)?\/(embed\/)?[\w?=-]*/.test(str);
};

export const _isDailymotion = (str) => {
  return /https:\/\/(www.)?dai(\.ly|lymotion.com)\/(embed\/)?(video\/)?\w*/.test(
    str
  );
};
