import React, { Component } from 'react';
import { withMain } from '../hoc/main';
import Published from './homepage/Published';
import Message from './homepage/Message';
import Rank from './homepage/Rank';
import DataAnalytics from './homepage/DataAnalytics';
import { daysInMonth } from '../_tools/other';
import ErrorBoundary from '../components/ErrorBoundary';
import moment from 'moment';
import Loader from '../components/Loader';
//Api
import { _getAllAnalytics, _getCharts } from '../_api/analytics';
import Bot from '../_api/bot';
import { Line } from 'react-chartjs-2';

const findID = (allFeatures) => {
  for (var i = 0; allFeatures.length > i; i++) {
    if (allFeatures[i].id === 35) return true;
  }
  return false;
};

const BoxData = ({ clipPath, children }) => {
  return (
    <div className="m-t-md rad-5px has-background-white flex column transition p-relative w-18 translateY dataBloc">
      {children}
      <div className={clipPath}></div>
    </div>
  );
};

const BoxFunct = ({ analytics, botID, lang, w, action, fct, loader }) => {
  return (
    <div className="rad-5px has-background-white overflow-auto w-31 tabPortBloc">
      <Rank
        analytics={analytics}
        action={action}
        bot_id={botID}
        lang={lang}
        wording={w}
        fct={fct}
        loader={loader}
      />
    </div>
  );
};

class Analytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      charts: null,
      allFeatures: this.props.m.bot.features,
      analytics: '',
      analyticsFeatures: [],
      orgaID: this.props.m.user.organization.id,
      loader: false,
      clicsTime: 'Depuis le début',
      fct: {
        selectOption: this.selectOption.bind(this),
      },
    };
  }

  async componentDidMount() {
    const {
      m: { bot, client },
    } = this.props;
    if (process.env.REACT_APP_DEV !== 'true') {
      this.getAllAnalytics(bot);
      this.createAnalyticsFeatures(bot.id, client);
    }
  }

  createAnalyticsFeatures = async (botID, client) => {
    const Api = new Bot(client, botID);
    try {
      const res = await Api._getBot();
      if (res.getBot.config.analyticsFeatures)
        this.setState({
          analyticsFeatures: res.getBot.config.analyticsFeatures,
        });
    } catch (err) {
      console.log(err);
    }
  };

  getAllAnalytics = async (bot) => {
    const d = await _getAllAnalytics(bot.id);
    await fetch(d.url, d.req)
      .then((res) => res.json())
      .then((data) => {
        this.setState({ analytics: {
          ...data,
            ga_analytics: {
            ...data.ga_analytics,
              "NLP match rate": {
                ...data.ga_analytics["NLP match rate"],
                data: data.ga_analytics["NLP match rate"]?.data?.filter(({name})=> name != "NLP failed") || []
              }
            }
          }
        });
      });
    const charts = await _getCharts(bot.id);
    this.setState({ charts: charts });
  };

  getAllAnalyticsDate = async (bot, action, resStart, resEnd) => {
    let { analytics } = this.state;
    const d = await _getAllAnalytics(bot.id, resStart, resEnd);
    await fetch(d.url, d.req)
      .then((res) => res.json())
        .then((data) => ({
          ...data,
          ga_analytics: {
            ...data.ga_analytics,
            "NLP match rate": {
              ...data.ga_analytics["NLP match rate"],
              data: data.ga_analytics["NLP match rate"]?.data?.filter(({name})=> name != "NLP failed") || []
            }
          }
        }))
      .then((data) => {
        if (!data.ga_analytics) delete analytics.ga_analytics[action];
        else analytics.ga_analytics[action] = data.ga_analytics[action];
        setTimeout(this.setState({ analytics: analytics }), 3000);
      });
  };

  async selectOption(item, action, dateFromTo) {
    const {
      m: { bot },
    } = this.props;
    let { analytics, clicsTime } = this.state;
    let res = null;
    this.setState({
      input: item,
      loader: true,
      clicsTime: action === 'Event Action' ? item : clicsTime,
    });

    var now = new Date();
    moment.locale('fr');
    const date = moment(now).format('L');
    const split = date.split('/');
    analytics.ga_analytics[action] = null;
    this.setState({ analytics: analytics });

    if (item === 'interval') {
      if (dateFromTo.startDate || dateFromTo.endDate) {
        const startDate = moment(dateFromTo.startDate).format('L'),
          endDate = moment(dateFromTo.endDate).format('L'),
          start = startDate.split('/'),
          resStart = start[2] + '-' + start[1] + '-' + start[0],
          end = endDate.split('/'),
          resEnd = end[2] + '-' + end[1] + '-' + end[0];
        this.getAllAnalyticsDate(bot, action, resStart, resEnd);
        this.setState({
          clicsTime:
            action === 'Event Action'
              ? 'Du ' + resStart + ' au ' + resEnd
              : clicsTime,
        });
      } else {
        this.getAllAnalytics(bot);
        this.setState({ clicsTime: 'Depuis le début' });
      }
    } else if (item === 'Depuis le début') {
      this.getAllAnalytics(bot);
    } else {
      if (item === 'Ce mois-ci') res = split[2] + '-' + split[1] + '-01';
      if (item === 'Cette semaine') {
        let nb = 0;
        const day = moment(split[2] + '-' + split[1] + '-' + split[0]).format(
          'dddd'
        );
        if (day === 'mardi') {
          nb = 1;
        }
        if (day === 'mercredi') {
          nb = 2;
        }
        if (day === 'jeudi') {
          nb = 3;
        }
        if (day === 'vendredi') {
          nb = 4;
        }
        if (day === 'samedi') {
          nb = 5;
        }
        if (day === 'dimanche') {
          nb = 6;
        }
        if (split[0][0] === '0') {
          if (split[0][1] <= nb.toString()) {
            if (split[1] === '01' && split[0][1] <= nb.toString()) {
              const dInM = daysInMonth('0' + (split[1] - 1), split[2]);
              const r = parseInt(split[0][1]) - nb;
              res = split[2] - 1 + '-' + 12 + '-' + (dInM + r);
            } else {
              const dInM = daysInMonth('0' + (split[1] - 1), split[2]);
              const r = parseInt(split[0][1]) - nb;
              res = `${split[2]} + '-' + ('0' + ${split[1] - 1}) + '-' + ${
                dInM + r
              }`;
            }
          } else {
            let day = split[0] - nb;
            if (day < 10) day = '0' + day;
            res = split[2] + '-' + split[1] + '-' + day;
          }
        } else {
          if (split[0] - nb < 10)
            res = `${split[2]} + '-' + ${split[1]} + '-' + ('0' + ${
              split[0] - nb
            })`;
          else res = split[2] + '-' + split[1] + '-' + (split[0] - nb);
        }
      }
      this.getAllAnalyticsDate(bot, action, res);
    }
  }

  render() {
    const {
      m: { client, bot, lang, w, unreadMessages },
    } = this.props;
    const {
      analytics,
      analyticsFeatures,
      fct,
      loader,
      orgaID,
      allFeatures,
      clicsTime,
    } = this.state;
    let count = 0;
    const idAnalytic = findID(allFeatures);

    if (analyticsFeatures.length > 0)
      analyticsFeatures.forEach((f) => {
        if (f.activated === true) {
          count = count + 1;
        }
      });

    if ((orgaID === 1 || idAnalytic) && !analytics)
      return (
        <div className="has-text-white">
          <Loader />
        </div>
      );
    if ((orgaID === 1 || idAnalytic) && analyticsFeatures.length > 0) {
      return (
        <ErrorBoundary>
          <div className="flex column dataTab">
            <div
              className={
                count < 5
                  ? 'flex row space-around m-b-md h-2 dataTab'
                  : 'flex row space-between m-b-md h-2 dataTab'
              }
            >
              {analyticsFeatures.map((a, i) => {
                if (a.name === 'evts_published' && a.activated) {
                  return (
                    <BoxData key={i} clipPath="clipPath-1">
                      <Published
                        bot_id={bot.id}
                        lang={lang}
                        wording={w.homepage.published}
                        published={true}
                        time={w.homepage.now}
                      />
                    </BoxData>
                  );
                }
                if (a.name === 'evts_unpublished' && a.activated) {
                  return (
                    <BoxData key={i} clipPath="clipPath-2">
                      <Published
                        bot_id={bot.id}
                        lang={lang}
                        wording={w.homepage.unpublished}
                        published={false}
                        time={w.homepage.now}
                      />
                    </BoxData>
                  );
                }
                if (a.name === 'messages' && a.activated) {
                  return (
                    <BoxData key={i} clipPath="clipPath-3">
                      <Message
                        wording={w}
                        unreadMessages={unreadMessages}
                        time={w.homepage.now}
                      />
                    </BoxData>
                  );
                }
                if (a.name === 'nb_users' && a.activated) {
                  return (
                    <BoxData key={i} clipPath="clipPath-4">
                      <DataAnalytics
                        wording={w.homepage.nbUser}
                        analytics={analytics}
                        name="nb_users"
                        time={w.homepage.fromBeginning}
                      />
                    </BoxData>
                  );
                }
                if (a.name === 'duration' && a.activated) {
                  return (
                    <BoxData key={i} clipPath="clipPath-5">
                      <DataAnalytics
                        wording={w.homepage.duration}
                        analytics={analytics}
                        name="duration"
                        time={w.homepage.fromBeginning}
                      />
                    </BoxData>
                  );
                }
                if (a.name === 'nb_sessions_users' && a.activated) {
                  return (
                    <BoxData key={i} clipPath="clipPath-6">
                      <DataAnalytics
                        wording={w.homepage.nbSession}
                        extra={w.homepage.perUser}
                        analytics={analytics}
                        name="nb_sessions_users"
                        time={w.homepage.fromBeginning}
                      />
                    </BoxData>
                  );
                }
                if (a.name === 'nb_clic_evts' && a.activated) {
                  return (
                    <BoxData key={i} clipPath="clipPath-7">
                      <DataAnalytics
                        wording={w.homepage.nbClicEvts}
                        analytics={analytics}
                        name="nb_clic_evts"
                        time={clicsTime}
                      />
                    </BoxData>
                  );
                }
                return null;
              })}
            </div>
            <div className="flex row space-between tabRank">
              <BoxFunct
                analytics={analytics}
                w={w.homepage.event}
                action={'Event Action'}
                lang={lang}
                fct={fct}
                loader={loader}
              />
              <BoxFunct
                analytics={analytics}
                w={w.homepage.theme}
                action={'NLP match rate'}
                lang={lang}
                fct={fct}
                loader={loader}
              />
              <BoxFunct
                analytics={analytics}
                w={w.homepage.fct}
                action={'Functionality'}
                lang={lang}
                fct={fct}
                loader={loader}
              />
            </div>
            <ErrorBoundary>
              {analyticsFeatures.some((el) => el.name === 'nb_users') &&
              this.state.charts ? (
                <div className="has-background-white m-t-md m-b-md rad-5px p-md">
                  <Line data={this.state.charts} />
                </div>
              ) : (
                <div className="has-background-white m-t-md m-b-md rad-5px p-md text-align-center">
                  {w.homepage.unavailable}
                </div>
              )}
            </ErrorBoundary>
          </div>
        </ErrorBoundary>
      );
    } else {
      return null;
    }
  }
}

export default withMain(Analytics);
