import React, { Component } from 'react';

class Tag extends Component {
  render() {
    let { tag, tags, onDelete, id, i, fct } = this.props;
    if (tags.length === 0) tags = '';
    return (
      <React.Fragment>
        {id !== 4 && (
          <div
            key={i}
            className="p-l-xs p-r-xs flex row space-between border-tag rad-15px has-background-primary-ter has-text-white is-size-7 has-text-weight-semibold m-r-xs"
          >
            <span className="m-r-xs has-text-weight-light">{tags}</span>
            <span
              onClick={() => {
                fct.deleteTagsArray(tag);
                onDelete(id, i);
              }}
            >
              <i className="fas fa-times turn-90"></i>
            </span>
          </div>
        )}
        {id === 4 &&
          tags.length > 0 &&
          tags.map((t, i) => {
            return (
              <div
                key={i}
                className="p-l-xs p-r-xs flex row space-between border-tag rad-15px has-background-primary-ter has-text-white is-size-7 has-text-weight-semibold m-r-xs"
              >
                <span className="m-r-xs has-text-weight-light">{t}</span>
                <span
                  onClick={() => {
                    fct.deleteTagsArray(t, 'features');
                    onDelete(id, i);
                  }}
                >
                  <i className="fas fa-times turn-90"></i>
                </span>
              </div>
            );
          })}
      </React.Fragment>
    );
  }
}

export default Tag;
