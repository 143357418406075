import gql from 'graphql-tag';
import {
  //  _query
  _mutate,
  _query,
} from './client';

class Intent {
  constructor(client) {
    this.client = client;
  }

  _getIntents = async (variables, opt) => {
    return await this.client.query({
      query: GET_INTENTS,
      variables: variables,
      ...opt,
    });
  };

  _getCategories = async (variables, opt) => {
    return await _query(this.client, GET_CATEGORIES, variables, opt);
  };

  _createCategory = async (variables, opt) => {
    return await _mutate(this.client, CREATE_CATEGORY, variables, opt);
  };

  _updateCategory = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_CATEGORY, variables, opt);
  };

  _deleteCategory = async (variables, opt) => {
    return await _mutate(this.client, DELETE_CATEGORY, variables, opt);
  };

  _changeCategory = async (variables, opt) => {
    return await _mutate(this.client, CHANGE_CATEGORY, variables, opt);
  };

  _deleteContent = async (variables, opt) => {
    return await _mutate(this.client, DELETE_CONTENT, variables, opt);
  };

  _updateContent = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_CONTENT, variables, opt);
  };

  _archiveContent = async (variables, opt) => {
    return await _mutate(this.client, ARCHIVE_CONTENT, variables, opt);
  };

  _createContent = async (variables, opt) => {
    return await _mutate(this.client, CREATE_CONTENT, variables, opt);
  };

  _toggleIntent = async (variables, opt) => {
    return await _mutate(this.client, TOGGLE_INTENT, variables, opt);
  };

  _createIntent = async (variables, opt) => {
    return await _mutate(this.client, CREATE_INTENT, variables, opt);
  };

  _updateIntent = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_INTENT, variables, opt);
  };
}

export default Intent;

export const GET_INTENTS = gql`
  query getIntents($bot_id: Int!, $deleted: Boolean) {
    getIntentsV2(bot_id: $bot_id, deleted: $deleted) {
      id
      fr
      en
      bot_id
      intents {
        ... on DialogflowIntent {
          programmatic
          id
          jump
          fr {
            name
            deleted
            questions
            content {
              id
              period
              content
              activated
              archived_date
              mail {
                mail
                remind_interval
                activated
              }
            }
            parameters
          }
          en {
            name
            deleted
            questions
            content {
              id
              period
              content
              archived_date
              activated
              mail {
                mail
                remind_interval
                activated
              }
            }
            parameters
          }
        }
        ... on LeoIntent {
          id
          language
          name
          specific_id
          jump
          programmatic
          parameters
          deleted
          answers {
            id
            period
            activated
            archived_date
            content
            mail {
              mail
              remind_interval
              activated
            }
          }
        }
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getCategories($bot_id: Int) {
    getCategoriesV2(bot_id: $bot_id) {
      bot_id
      id
      fr
      en
      intents {
        ... on DialogflowIntent {
          id
          fr {
            name
          }
        }
        ... on LeoIntent {
          id
          name
        }
      }
    }
  }
`;

export const GET_CATEGORIES_AND_INTENTS = gql`
  query getCategoriesAndIntents($bot_id: Int!, $deleted: Boolean) {
    getCategoriesV2(bot_id: $bot_id) {
      bot_id
      id
      fr
      en
      intents {
        ... on DialogflowIntent {
          id
          fr {
            name
          }
        }
        ... on LeoIntent {
          id
          name
        }
      }
    }
    getIntentsV2(bot_id: $bot_id, deleted: $deleted) {
      id
      fr
      en
      bot_id
      intents {
        ... on DialogflowIntent {
          programmatic
          id
          jump
          fromMonolith
          fr {
            name
            deleted
            questions
            content {
              id
              period
              content
              activated
              archived_date
              place_id
              mail {
                mail
                remind_interval
                activated
              }
            }
            parameters
          }
          en {
            name
            deleted
            questions
            content {
              id
              period
              content
              archived_date
              activated
              mail {
                mail
                remind_interval
                activated
              }
            }
            parameters
          }
        }
        ... on LeoIntent {
          id
          language
          name
          specific_id
          jump
          programmatic
          parameters
          deleted
          answers {
            id
            period
            activated
            archived_date
            content
            mail {
              mail
              remind_interval
              activated
            }
          }
        }
      }
    }
  }
`;

// export const GET_INTENTS = gql`
//   query getIntents($bot_id: Int!, $deleted: Boolean) {
//     getIntents(bot_id: $bot_id, deleted: $deleted) {
//       id
//       fr
//       en
//       bot_id
//       intents {
//         programmatic
//         id
//         jump
//         fr {
//           name
//           deleted
//           questions
//           content {
//             id
//             period
//             content
//             activated
//             archived_date
//             mail {
//               mail
//               remind_interval
//               activated
//             }
//           }
//           parameters
//         }
//         en {
//           name
//           deleted
//           questions
//           content {
//             id
//             period
//             content
//             archived_date
//             activated
//             mail {
//               mail
//               remind_interval
//               activated
//             }
//           }
//           parameters
//         }
//       }
//     }
//   }
// `;

const CHANGE_CATEGORY = gql`
  mutation ($bot_id: Int!, $intent_id: String!, $category_id: String!) {
    changeCategoryV2(
      bot_id: $bot_id
      intent_id: $intent_id
      category_id: $category_id
    ) {
      ... on DialogflowIntent {
        id
      }
      ... on LeoIntent {
        id
      }
    }
  }
`;

const DELETE_CONTENT = gql`
  mutation (
    $bot_id: Int!
    $id: String!
    $language: String!
    $content_id: String
  ) {
    deleteContentV2(
      bot_id: $bot_id
      id: $id
      language: $language
      content_id: $content_id
    ) {
      ... on DialogflowIntent {
        id
      }
      ... on LeoIntent {
        id
      }
    }
  }
`;

const UPDATE_CONTENT = gql`
  mutation (
    $bot_id: Int!
    $id: String!
    $language: String!
    $content_id: String!
    $content: [String!]!
    $period: String
    $activated: Boolean
    $archived_date: String
    $mail: [String]
    $remind_interval: String
    $place_id: Int
  ) {
    updateContentV2(
      bot_id: $bot_id
      id: $id
      language: $language
      content_id: $content_id
      archived_date: $archived_date
      content: $content
      period: $period
      activated: $activated
      mail: $mail
      remind_interval: $remind_interval
      place_id: $place_id
    ) {
      ... on DialogflowIntent {
        id
      }
      ... on LeoIntent {
        id
      }
    }
  }
`;

const ARCHIVE_CONTENT = gql`
  mutation (
    $bot_id: Int!
    $intent_id: String!
    $language: String!
    $content_id: String!
  ) {
    archiveContent(
      bot_id: $bot_id
      intent_id: $intent_id
      language: $language
      content_id: $content_id
    ) {
      ... on DialogflowIntent {
        id
      }
      ... on LeoIntent {
        id
      }
    }
  }
`;

const CREATE_CONTENT = gql`
  mutation (
    $bot_id: Int!
    $id: String!
    $language: String!
    $content: [String!]!
    $period: String
    $mail: [String]
    $remind_interval: String
  ) {
    createContentV2(
      bot_id: $bot_id
      id: $id
      language: $language
      content: $content
      period: $period
      mail: $mail
      remind_interval: $remind_interval
    ) {
      ... on DialogflowIntent {
        id
      }
      ... on LeoIntent {
        id
      }
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation ($bot_id: Int!, $fr: String, $en: String) {
    createCategoryV2(bot_id: $bot_id, fr: $fr, en: $en) {
      id
      intents {
        ... on DialogflowIntent {
          id
        }
        ... on LeoIntent {
          id
        }
      }
    }
  }
`;

const TOGGLE_INTENT = gql`
  mutation ($bot_id: Int!, $id: String!, $language: String!) {
    toggleIntentV2(bot_id: $bot_id, id: $id, language: $language) {
      ... on DialogflowIntent {
        id
      }
      ... on LeoIntent {
        id
      }
    }
  }
`;

const CREATE_INTENT = gql`
  mutation (
    $bot_id: Int!
    $language: String!
    $name: String!
    $category: String!
    $deleted: Boolean
    $content: [String]!
    $questions: [String]!
    $jump: String
    $specific_id: String
  ) {
    createIntentV2(
      bot_id: $bot_id
      language: $language
      category: $category
      deleted: $deleted
      name: $name
      jump: $jump
      content: $content
      questions: $questions
      specific_id: $specific_id
    ) {
      ... on DialogflowIntent {
        id
      }
      ... on LeoIntent {
        id
      }
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation ($id: String!, $fr: String, $en: String) {
    updateCategoryV2(id: $id, fr: $fr, en: $en) {
      id
      intents {
        ... on DialogflowIntent {
          id
        }
        ... on LeoIntent {
          id
        }
      }
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation ($id: String!, $bot_id: Int) {
    deleteCategoryV2(id: $id, bot_id: $bot_id)
  }
`;

const UPDATE_INTENT = gql`
  mutation (
    $bot_id: Int!
    $id: String!
    $language: String!
    $name: String
    $questions: [String]
    $jump: String
    $specific_id: String
  ) {
    updateIntentV2(
      bot_id: $bot_id
      id: $id
      language: $language
      name: $name
      questions: $questions
      jump: $jump
      specific_id: $specific_id
    ) {
      ... on DialogflowIntent {
        id
      }
      ... on LeoIntent {
        id
      }
    }
  }
`;
