export const _random = (len = 20) => {
  let charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let ret = [];
  for (let i = 0, length = charset.length; len > i; i++) {
    ret[i] = charset[Math.floor(Math.random() * length)];
  }
  return ret.join('');
};

// addEventListener => support for IE8
export const _bindEvent = (element, eventName, eventHandler) => {
  if (element.addEventListener)
    element.addEventListener(eventName, eventHandler, false);
  else if (element.attachEvent)
    element.attachEvent('on' + eventName, eventHandler);
};

export const _unbindEvent = (element, eventName, eventHandler) => {
  if (element.addEventListener)
    element.removeEventListener(eventName, eventHandler);
  else if (element.detachEvent)
    element.detachEvent('on' + eventName, eventHandler);
};

// Merge State avoid conflict for undefined object
export const _mergeState = (base, args, config) => {
  let state = _mergeRecursive(base, args);
  state = _mergeRecursive(state, config);
  return state;
};

const _mergeRecursive = (base, other) => {
  for (var key in other) {
    try {
      if (typeof other[key] === 'object')
        base[key] = _mergeRecursive(base[key], other[key]);
      else base[key] = other[key];
    } catch (err) {
      base[key] = other[key];
    }
  }
  return base;
};

export const _getKeyValue = (obj, key) => {
  let found = null;
  if (typeof obj === 'object') {
    for (let k in obj) {
      if (k === key) found = obj[k];
      else if (typeof obj[k] === 'object' && !Array.isArray(obj[k]))
        found = _getKeyValue(obj[k], key);
      if (found) return found;
    }
  }
  return found;
};
