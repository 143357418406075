import React, { useEffect, useReducer, useState } from 'react';
import { _sendFeedback } from '../../../_tools/ui';
import { Button, Image, Icon, Textarea, Input, Field } from '@ask-mona/ui';
import Video from '../../../components/Video';
import { Board } from './Ui';
import _reducer from './Reducer';
import List from '../../../components/scenario/components/List';
import Btn from '../../../components/scenario/components/Button';
import { _uploadImg, _uploadVid } from '../../../_api/upload';
import {
  _isImg,
  _isVid,
  _isGif,
  _isList,
  _isYoutube,
  _isDailymotion,
  _isButton,
} from '../../../_tools/is';
const _contentToItems = (content) => {
  let items = (content || []).map((c) => {
    if (_isList(c)) return { type: 'list', content: c };
    if (_isButton(c)) return { type: 'button', content: c };
    if (_isImg(c)) return { type: 'img', content: c };
    if (_isGif(c)) return { type: 'gif', content: c };
    if (_isVid(c)) return { type: 'vid', content: c };
    if (_isYoutube(c)) return { type: 'youtube', content: c };
    if (_isDailymotion(c)) return { type: 'dailymotion', content: c };
    return { type: 'text', content: c };
  });
  return items;
};
const Content = (props) => {
  const {
    m: { w, user, bot },
    api: { intent },
    lang,
    el,
    c,
    cIndex,
  } = props;
  const [state, dispatch] = useReducer(_reducer, c);
  const items = _contentToItems(state.content);
  const [hasChange, setHasChange] = useState(false);
  const { content } = state;
  const leoBot = bot.features.some((el) => el.slug === 'leo');

  useEffect(() => {
    if (!hasChange) setHasChange(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <div className="p-md has-background-white rad-5px border-primary-light">
      <div className="has-text-weight-medium flex row items-center space-between">
        <span>{`${w.qa.answer} ${cIndex + 1}`}</span>
        <div className="flex row">
          {hasChange && (
            <Button
              className="is-size-7"
              onClick={async () => {
                const res = await intent._updateContent(
                  {
                    bot_id: bot.id,
                    id: el.id,
                    language: lang,
                    content: state.content,
                    period: state.period,
                    mail:
                      state.mail && state.mail.mail ? state.mail.mail : null,
                    content_id: state.id,
                    activated: true,
                    remind_interval:
                      state.mail && state.mail.remind_interval
                        ? state.mail.remind_interval
                        : null,
                    place_id: state.place_id,
                  },
                  { refetchQueries: ['getCategoriesAndIntents'] }
                );
                if (res.success) _sendFeedback('success', w.success.saved);
              }}
            >
              {w.generic.save}
            </Button>
          )}
          <Button
            className="is-size-7 shrink is-outlined is-borderless"
            color="danger"
            onClick={async () => {
              _sendFeedback('warning', 'loader');
              let date = new Date().toISOString();
              date = date.split('T')[0].split('-').reverse().join('/');
              _sendFeedback('warning', 'loader');
              const res = await intent._archiveContent(
                {
                  bot_id: bot.id,
                  intent_id: props.el.id,
                  language: lang,
                  content_id: state.id,
                },
                { refetchQueries: ['getCategoriesAndIntents'] }
              );
              setHasChange(true);
              if (res.success) _sendFeedback('success', w.success.saved);
              else _sendFeedback('danger', w.error.general);
            }}
          >
            <Icon icon="fas fa-trash" />
          </Button>
        </div>
      </div>
      <div>
        {items.map((c, i) => (
          <Element
            key={i}
            {...props}
            content={c}
            elIndex={i}
            dispatch={dispatch}
          />
        ))}
      </div>
      <Board {...props} c={state} dispatch={dispatch} items={items} />
    </div>
  );
};
const Element = (props) => {
  const {
    m: { w, bot },
    content: { content, type },
    dispatch,
    elIndex,
  } = props;
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addLink, setAddLink] = useState(false);
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');

  return (
    <div
      className={`w-100p m-xs rad-5px flex row items-center ${
        hover ? 'has-background-danger-light' : ''
      }`}
    >
      <div>
        <Button
          className="is-outlined is-borderless shrink"
          onClick={() =>
            dispatch({
              type: 'mveElement',
              payload: { index: elIndex, type: 'up' },
            })
          }
        >
          <Icon icon="fas fa-chevron-up" />
        </Button>
        <Button
          className="is-outlined is-borderless shrink"
          onClick={() =>
            dispatch({
              type: 'mveElement',
              payload: { index: elIndex, type: 'down' },
            })
          }
        >
          <Icon icon="fas fa-chevron-down" />
        </Button>
      </div>
      {((type) => {
        switch (type) {
          case 'img':
          case 'gif':
            return (
              <div
                className={`p-sm w-100p has-text-white rad-15px flex center has-background-primary-${
                  focus ? 'bis' : 'ter'
                }`}
              >
                <Image
                  src={content !== 'placeholder.png' ? content : ''}
                  placeholder={w.generic.imgPlaceholder}
                  onUpload={async (e) => {
                    setLoading(true);
                    _sendFeedback('warning', 'loader');
                    await _uploadImg(
                      bot.id,
                      e,
                      (res) => {
                        setLoading(false);
                        _sendFeedback('success', w.success.saved);
                        dispatch({
                          type: 'chgElement',
                          payload: { index: elIndex, value: res.path },
                        });
                      },
                      (err) => {
                        setLoading(false);
                        _sendFeedback('danger', w.error.general);
                      }
                    );
                  }}
                  onChange={(e) =>
                    dispatch({
                      type: 'chgElement',
                      payload: { index: elIndex, value: e.target.value },
                    })
                  }
                  fixed={loading ? 'true' : ''}
                  loading={loading ? 'true' : ''}
                />
              </div>
            );
          case 'vid':
          case 'youtube':
          case 'dailymotion':
            return (
              <div
                className={`p-sm w-100p has-text-white rad-15px flex center has-background-primary-${
                  focus ? 'bis' : 'ter'
                }`}
              >
                <Video
                  src={content !== 'placeholder.webm' ? content : ''}
                  placeholder={w.generic.vidPlaceholder}
                  onUpload={async (e) => {
                    setLoading(true);
                    _sendFeedback('warning', 'loader');
                    await _uploadVid(
                      bot.id,
                      e.target.value,
                      (res) => {
                        setLoading(false);
                        _sendFeedback('success', w.success.saved);
                        dispatch({
                          type: 'chgElement',
                          payload: { index: elIndex, value: res.path },
                        });
                      },
                      (err) => {
                        setLoading(false);
                        _sendFeedback('danger', w.error.general);
                      }
                    );
                  }}
                  onChange={(e) =>
                    dispatch({
                      type: 'chgElement',
                      payload: { index: elIndex, value: e.target.value },
                    })
                  }
                  fixed={loading ? 'true' : ''}
                  loading={loading ? 'true' : ''}
                />
              </div>
            );
          case 'list':
            return (
              <div
                className={`p-sm w-100p has-text-white rad-15px flex center has-background-primary-${
                  focus ? 'bis' : 'ter'
                }`}
              >
                <div className="has-text-primary">
                  <List
                    {...props}
                    element={JSON.parse(content)}
                    index={elIndex}
                  />
                </div>
              </div>
            );
          case 'button':
            return (
              <div
                className={`p-sm w-100p has-text-white rad-15px flex center has-transparent-background`}
              >
                <div className="has-text-primary">
                  <Btn
                    qa={true}
                    dispatch={dispatch}
                    {...props}
                    element={JSON.parse(content)}
                    index={elIndex}
                    setAddLink={() => setAddLink(false)}
                  />
                </div>
              </div>
            );
          default:
            return (
              <div
                className={`p-sm w-100p has-text-white rad-15px flex center has-background-primary-${
                  focus ? 'bis' : 'ter'
                }`}
              >
                <div className="flex column" style={{ width: '100%' }}>
                  <div className="flex row flex-start content-flex-start">
                    <Textarea
                      resize="auto"
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                      value={content || ''}
                      onChange={(e) =>
                        dispatch({
                          type: 'chgElement',
                          payload: { index: elIndex, value: e.target.value },
                        })
                      }
                      style={{
                        width: '100%',
                        justifySelf: 'start',
                      }}
                      className="is-transparent flex self-flex-start"
                    />
                  </div>
                  {addLink && (
                    <>
                      <div className="flex column center content-flex-center m-t-md">
                        <Field className="flex self-center">
                          {w.qa.buttonTitle}
                        </Field>
                        <Input
                          className="flex self-center"
                          style={{
                            width: '80%',
                            margin: '0 auto',
                          }}
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        ></Input>
                      </div>
                      <div className="flex column center content-flex-center m-t-sm">
                        <Field className="flex self-center">
                          {w.qa.buttonLink}
                        </Field>
                        <Input
                          className="flex self-center"
                          style={{ width: '80%', margin: '0 auto 1rem' }}
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                        ></Input>
                      </div>
                    </>
                  )}
                  <Button
                    className="is-underlined is-size-7 is-outlined is-borderless shrink"
                    style={{ color: 'white' }}
                    onClick={() => {
                      setAddLink(!addLink);
                      dispatch({
                        type: 'addButtonToElement',
                        payload: { index: elIndex, title: title, url: url },
                      });
                    }}
                  >
                    {addLink ? w.qa.cancel : w.qa.addLink}{' '}
                  </Button>
                </div>
              </div>
            );
        }
      })(type)}
      <Button
        color="danger"
        className="is-outlined is-borderless shrink is-size-7"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() =>
          dispatch({ type: 'popElement', payload: { index: elIndex } })
        }
      >
        <Icon icon="fas fa-times" />
      </Button>
    </div>
  );
};
export default Content;
