import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import AnswerContent from './AnswerContent';
import DateManagement from '../components/DateManagement';
import Select from 'react-select';

import { Answer as AnswerType, Place } from '../../../types/qa/topics';
import { _isImg, _isButton, _isGif, _isVid } from '../../../_tools/is';
import { QAContext } from '../context/QAContext';
import { Api } from '../../../api/graphql/hooks/useTopics';
import trash from '../../../_assets/icon/delete.svg';

interface Props {
  answer: AnswerType;
  index: number;
  api: Api;
  w: any;
}

type Content = {
  type: string;
  content: string;
};

type Contents = Array<Content>;

const Answer = (props: Props) => {
  const [displayMenu, setDisplayMenu] = useState<boolean>(false);
  const [displayDate, setDisplayDate] = useState<boolean>(false);
  const [placeSelected, setPlaceSelected] = useState<Place>();
  const [dateToDisplay, setDateToDisplay] = useState<string>('');
  const { state, dispatch } = useContext(QAContext);

  useEffect(() => {
    if (state.isMultisite)
      props.answer.place
        ? setPlaceSelected(props.answer.place)
        : setPlaceSelected(state.places.find((el) => el.main_site));
  }, []);

  useEffect(() => {
    if (props.answer.start_date && props.answer.end_date) {
      setDateToDisplay(
        `Programmé du ${new Date(
          props.answer.start_date
        ).toLocaleDateString()} au ${new Date(
          props.answer.end_date
        ).toLocaleDateString()}`
      );
    } else if (props.answer.start_date) {
      setDateToDisplay(
        `Programmé le ${new Date(props.answer.start_date).toLocaleDateString()}`
      );
    }
  }, [props.answer.start_date, props.answer.end_date]);

  const answersParsed = JSON.parse(props.answer?.content);
  const contents: Contents = answersParsed.map((content: object) => {
    const contentStringified = JSON.stringify(content);
    if (_isButton(contentStringified))
      return { type: 'button', content: contentStringified };
    if (_isImg(contentStringified))
      return { type: 'image', content: contentStringified };
    if (_isGif(contentStringified))
      return { type: 'gif', content: contentStringified };
    if (_isVid(contentStringified))
      return { type: 'video', content: contentStringified };
    return { type: 'text', content: contentStringified };
  });

  return (
    <StyledAnswers
      onClick={() =>
        dispatch({ type: 'SET_CURRENT_ANSWER', payload: props.answer.id })
      }
    >
      <StyledHeader>
        <span>{`${props.w.answer} ${props.index + 1}`}</span>
        <StyledAction>
          <Button
            className="is-size-7"
            onClick={() =>
              props.api.updateTopicAnswers(
                props.answer.id,
                props.answer.content,
                props.answer.enabled,
                props.answer.start_date,
                props.answer.end_date,
                props.answer.reminder_date,
                props.answer.reminder_emails,
                props.answer.place?.id
              )
            }
          >
            {state.wording.save}
          </Button>
          <WrapperDelete
            className="is-size-7 shrink is-outlined is-borderless"
            color="danger"
          >
            <span
              onClick={() => {
                dispatch({
                  type: 'UPDATE_ANSWER',
                  payload: {
                    enabled: false,
                    id: props.answer.id,
                    startDate: props.answer.start_date,
                    endDate: props.answer.end_date,
                    reminderDate: props.answer.reminder_date,
                    reminderEmails: props.answer.reminder_emails,
                    place: props.answer.place,
                  },
                });
                props.api.updateTopicAnswers(
                  props.answer.id,
                  props.answer.content,
                  false,
                  props.answer.start_date,
                  props.answer.end_date,
                  props.answer.reminder_date,
                  props.answer.reminder_emails,
                  props.answer.place?.id
                );
              }}
            >
              <img src={trash} width="16px" />
            </span>
          </WrapperDelete>
        </StyledAction>
      </StyledHeader>
      {contents.map((content: Content, i) => (
        <AnswerContent
          key={i}
          index={i}
          content={content}
          answerId={props.answer.id}
          w={props.w}
        />
      ))}
      {displayMenu && (
        <StyledAddItems>
          <div
            onClick={() =>
              dispatch({
                type: 'ADD_TOPIC_ANSWER',
                payload: {
                  contentType: 'text',
                  answerId: props.answer.id,
                  contentId: state.currentAnswerContentIndex,
                },
              })
            }
          >
            <i className="fas fa-font"></i>
            {state.wording.text}
          </div>
          <div
            onClick={() =>
              dispatch({
                type: 'ADD_TOPIC_ANSWER',
                payload: {
                  contentType: 'image',
                  answerId: props.answer.id,
                  contentId: state.currentAnswerContentIndex,
                },
              })
            }
          >
            <i className="fas fa-image"></i>
            {props.w.image}
          </div>
          <div
            onClick={() =>
              dispatch({
                type: 'ADD_TOPIC_ANSWER',
                payload: {
                  contentType: 'video',
                  answerId: props.answer.id,
                  contentId: state.currentAnswerContentIndex,
                },
              })
            }
          >
            <i className="fas fa-youtube"></i>
            {props.w.video}
          </div>
          <div
            onClick={() =>
              dispatch({
                type: 'ADD_TOPIC_ANSWER',
                payload: {
                  contentType: 'gif',
                  answerId: props.answer.id,
                  contentId: state.currentAnswerContentIndex,
                },
              })
            }
          >
            <i className="fas fa-file"></i>
            {props.w.gif}
          </div>
        </StyledAddItems>
      )}
      <StyledBoard>
        <StyledBoardItem onClick={() => setDisplayMenu(!displayMenu)}>
          {props.w.addElement}
        </StyledBoardItem>
        <StyledBoardItem
          onClick={() =>
            dispatch({
              type: 'COPY_TOPIC_ANSWER',
              payload: props.answer.content,
            })
          }
        >
          {state.wording.copyElements}
        </StyledBoardItem>
        {!!state.topicAnswerCopied.length && (
          <div>
            <StyledBoardItem
              onClick={() =>
                dispatch({
                  type: 'PASTE_TOPIC_ANSWER',
                  payload: props.answer.id,
                })
              }
            >
              {state.wording.pasteElements}
            </StyledBoardItem>
            <i
              className="fas fa-times"
              onClick={() =>
                dispatch({
                  type: 'COPY_TOPIC_ANSWER',
                  payload: '',
                })
              }
            ></i>
          </div>
        )}
        {(props.answer.start_date || props.answer.end_date) && (
          <StyledInfos>{dateToDisplay}</StyledInfos>
        )}
        {props.answer.reminder_date && props.answer.reminder_emails && (
          <StyledInfos>
            {state.wording.reminder}
            {new Date(props.answer.reminder_date).toLocaleDateString()},
            <br></br>
            {state.wording.reminder} {props.answer.reminder_emails}
          </StyledInfos>
        )}
        <StyledBoardItem onClick={() => setDisplayDate(!displayDate)}>
          {state.wording.addParam}
        </StyledBoardItem>
        {!!displayDate && <DateManagement answer={props.answer} w={props.w} />}
      </StyledBoard>
      {state.isMultisite && (
        <div className="w-4 has-background-white">
          <WrapperScope>
            <p>{props.w.answerScope}</p>
            <StyledPlace>{placeSelected?.name}</StyledPlace>
          </WrapperScope>
          <WrapperSelect>
            <Select<Place>
              placeholder={props.w.replaceLocation}
              options={state.places}
              getOptionLabel={(place: Place) => place.name}
              getOptionValue={(place: Place) => place.id}
              onChange={(val) => {
                setPlaceSelected(val);
                dispatch({
                  type: 'UPDATE_ANSWER',
                  payload: {
                    enabled: props.answer.enabled,
                    id: props.answer.id,
                    startDate: props.answer.start_date,
                    endDate: props.answer.end_date,
                    reminderDate: props.answer.reminder_date,
                    reminderEmails: props.answer.reminder_emails,
                    place: {
                      id: val.id,
                      name: val.name,
                    },
                  },
                });
              }}
            />
          </WrapperSelect>
        </div>
      )}
    </StyledAnswers>
  );
};

const WrapperScope = styled.div`
  display: flex;
  align-items: center;
  margin: 0.6rem 0;
  font-size: 14px;
`;

const WrapperSelect = styled.div`
  width: 50%;
  font-size: 14px;
`;

const StyledPlace = styled.div`
  background: #2d2e7c;
  color: #fff;
  padding: 0.4rem;
  margin-left: 0.4rem;
`;

const StyledAnswers = styled.div`
  background-color: #fff;
  border: 1px solid #dbdbf0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledAction = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Button = styled.div`
  display: flex;
  padding: 0.25em 1em;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  border-radius: 3px;
  border: 2px solid #4246b3;
  background-color: #5054be;
  color: white;
`;

const StyledBoard = styled.div``;

const StyledBoardItem = styled.div`
  border: none;
  color: #5054be;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
`;

const StyledAddItems = styled.div`
  display: flex;
  > div {
    text-decoration: underline;
    color: #5054be;
    display: flex;
    padding: 0.25em 1em;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1em;
  }
`;

const StyledInfos = styled.div`
  white-space: pre-line;
  font-size: 0.75em;
  font-style: italic;
`;

const WrapperDelete = styled.div`
  margin-left: 0.6rem;
  cursor: pointer;
`;

export default Answer;
