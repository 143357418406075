import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { moduleTemplate, _findDup, _generatePath } from '../Reducer';

import Popover, { ArrowContainer } from 'react-tiny-popover';

import { _slugify } from '../../../_tools/other';
import { _sendFeedback } from '../../../_tools/ui';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';

import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#2D2F7B' },
    secondary: { main: '#fff' },
  },
});

const useStyles = makeStyles((theme) => ({
  whiteColor: {
    color: 'white',
  },
  icon: {
    fill: 'white',
  },
  select: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
  },
}));

export const AddSubModulePopover = (props) => {
  const classes = useStyles();
  const {
    m: { w },
    folders,
    isClose,
    allIds,
    Bot,
    focus,
    dispatch,
    subModules,
    modules
  } = props;

  const [name, setName] = useState('');
  const [categorieName, setCategorieName] = useState('');
  const [categorie, setCategorie] = useState('');
  const [folder, setFolder] = useState('');
  const [folderArray, setFolderArray] = useState(folders);
  const [addCategorie, setAddCategorie] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    setFolderArray(folders);
  }, [folders, isPopoverOpen]);

  useEffect(() => {
    setFolder(
      categorie === '' ? (props.subforce ? props.subforce : '') : categorie
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorie]);

  const closePopover = () => {
    setIsPopoverOpen(false);
    setName('');
    setCategorieName('');
    setCategorie('');
  };

  const handleSave = async () => {
    if (!name) return;
    let id = _slugify(name) || 'emojisolo';
    if (isClose) {
      id = _findDup(id, allIds);
      let base = {
        id,
        name,
        tag: folder,
        jump: focus.id,
        disable: false,
      };
      if (focus.dali)
        base = {
          ...base,
          content: {
            default: ['LOGIC_DALI_COUNT'],
            func: { default: {} },
          },
        };
      let module = moduleTemplate(base);
      const { key, ...other } = module;
      let newFocus = {
        ...focus,
        trigger: { ...focus.trigger, [key]: other },
      };
      let res = await Bot._createModule({
        path: ['module', 'trigger', `p:${focus.id}` , 'trigger', key],
        module: { [key]: other },
      });
      if (res.success) {
        const modulesUpdated = modules;
        modulesUpdated[modules.length -1] = newFocus;
        dispatch({ type: 'setFocus', payload: newFocus });
        dispatch({ type: 'setIds', payload: allIds.concat(id) });
        dispatch({ type: 'setSubFocus', payload: module });
        dispatch({ type: 'setSubModules', payload: subModules.concat(module)});
        dispatch({ type: 'setModules', payload: modulesUpdated })
        _sendFeedback('success', w.success.saved);
        setFolder(props.subforce ? props.subforce : '');
        closePopover();
      } else _sendFeedback('danger', w.error.general);
    } else {
      id = _findDup(id, allIds);
      let module = moduleTemplate({
        id,
        name,
        tag: `${focus.id}/${folder || ''}`,
        jump: 'FIRST',
        disable: false,
      });
      _sendFeedback('warning', 'loader');
      const { key, ...other } = module;
      let res = await Bot._createModule({
        path: ['module', 'trigger', key],
        module: { [key]: { ...other } },
      });
      if (res.success) {
        dispatch({
          type: 'setSubModules',
          payload: subModules.concat(module),
        });
        dispatch({ type: 'setIds', payload: allIds.concat(id) });
        _sendFeedback('success', w.success.saved);
        setFolder('');
        closePopover();
      } else _sendFeedback('danger', w.error.general);
    }
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      position={['right', 'bottom']}
      padding={5}
      onClickOutside={closePopover}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={'#2D2F7B'}
          arrowSize={10}
          arrowStyle={{ zIndex: 10000, borderRight: '15px solid #2D2F7B' }}
        >
          <PopupContainer>
            <Header>
              <HeaderText>{w.scenario.addModule}</HeaderText>
              <ButtonContainer>
                <Button
                  color="secondary"
                  disabled={name === ''}
                  // disabled={name === '' || categorie === ''}
                  variant="contained"
                  onClick={handleSave}
                >
                  {w.generic.save}
                </Button>
              </ButtonContainer>
            </Header>
            <SectionContainer>
              <SectionTitle>{w.scenario.modname}</SectionTitle>
              <SectionInput>
                <FormControl
                  variant="filled"
                  color="primary"
                  classes={{
                    root: classes.whiteColor,
                  }}
                >
                  <Input
                    classes={{
                      root: classes.whiteColor,
                      borderColor: 'white',
                    }}
                    value={name}
                    color="secondary"
                    variant="outlined"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </SectionInput>
            </SectionContainer>
            <SectionContainer>
              <SectionTitle>{w.scenario.folder}</SectionTitle>
              <SectionInput>
                {addCategorie || folderArray.length === 0 ? (
                  <FormControl
                    variant="filled"
                    color="primary"
                    classes={{
                      root: classes.whiteColor,
                    }}
                  >
                    <Input
                      classes={{
                        root: classes.whiteColor,
                        borderColor: 'white',
                      }}
                      value={categorieName}
                      color="secondary"
                      variant="outlined"
                      onChange={(e) => setCategorieName(e.target.value)}
                    />
                  </FormControl>
                ) : (
                  <FormControl
                    variant="filled"
                    color="secondary"
                    classes={{
                      root: classes.whiteColor,
                    }}
                  >
                    <NativeSelect
                      classes={{
                        root: classes.whiteColor,
                        icon: classes.whiteColor,
                      }}
                      color="secondary"
                      variant="outlined"
                      value={categorie}
                      onChange={(event) => {
                        setCategorie(event.target.value);
                      }}
                    >
                      <StyledOption id={focus.id} value="">
                        {w.scenario.nonClassifiedModules}
                      </StyledOption>
                      {folderArray &&
                        folderArray.map((folder, i) => (
                          <StyledOption id={folder.id} key={i} value={folder.value}>
                            {folder.id}
                          </StyledOption>
                        ))}
                    </NativeSelect>
                  </FormControl>
                )}
                {folderArray.length === 0 || addCategorie ? (
                  <Button
                    onClick={() => {
                      setAddCategorie(false);
                      setFolderArray([
                        ...folderArray,
                        {
                          id: categorieName,
                          key: categorieName,
                          value: props.subforce
                            ? `${props.subforce}/${categorieName}`
                            : categorieName,
                        },
                      ]);
                      setCategorie(categorieName);
                      setCategorieName('');
                    }}
                    color="secondary"
                  >
                    {`${w.generic.add} ${categorieName}`}
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    onClick={() => setAddCategorie(true)}
                  >
                    {w.scenario.addFolder}
                  </Button>
                )}
              </SectionInput>
            </SectionContainer>
          </PopupContainer>
        </ArrowContainer>
      )}
    >
      <PopoverButtonContainer>
        <MuiThemeProvider theme={theme}>
          <PopoverButton
            onClick={() => {
              setIsPopoverOpen(!isPopoverOpen);
            }}
          >
            <PopoverButtonIcon>
              <AddBoxRoundedIcon
                color="secondary"
                style={{ fontSize: '18px' }}
              />
            </PopoverButtonIcon>
            <PopoverButtonText>{w.scenario.module}</PopoverButtonText>
          </PopoverButton>
        </MuiThemeProvider>
      </PopoverButtonContainer>
    </Popover>
  );
};

const PopupContainer = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  position: relative;
  z-index: 1000;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled.span`
  margin-right: 20px;
  font-size: 1.1rem;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;

const SectionTitle = styled.div`
  margin-bottom: 10px;
`;
const SectionInput = styled.div`
  color: white;
`;

const PopoverButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const PopoverButton = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  border-radius: 30px;
  padding: 10px 15px;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  cursor: pointer;
  border: 1px solid ${(p) => p.theme.colors.primary};
`;

const PopoverButtonIcon = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopoverButtonText = styled.div`
  color: white;
  font-size: 0.9rem;
  text-align: center;
`;

const StyledOption = styled.option`
  color: #111 !important;
`;
