import React, { Component } from 'react';
import { File, Field, Input } from '@ask-mona/ui';
import { _isYoutube } from '../_tools/is';
import { _uploadVideo } from '../_api/upload';
import { withMain } from '../hoc/main';

class Video extends Component {
  state = {
    hover: false,
    fixed: false,
  };

  fix = () => {
    this.setState({ fixed: true });
  };

  onSuccess = (res) => {
    const { fct, onChange } = this.props;
    fct && fct.setVideo(res.id);
    onChange && onChange({ target: { value: res.id, type: 'id' } });
  };

  onError = (err) => {
    console.log(err);
  };

  onUpload = async (e) => {
    const {
      m: { bot },
    } = this.props;
    await _uploadVideo(bot.id, e, this.onSuccess, this.onError);
  };

  onChange = async (e) => {
    e.preventDefault();
    e.persist();
    this.setState({ fixed: true });
    const { onUpload, onChange } = this.props;
    onChange && onChange(e);
    if (_isYoutube(e.target.value)) onUpload && (await onUpload(e));
    this.setState({ fixed: false });
  };

  render() {
    const { title, subtitle, onChange, onUpload, src, ...other } = this.props;
    return (
      <div
        className="askmona-ui-video"
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        {((this.state.hover && !this.props.fixed) || this.state.fixed) && (
          <div className="askmona-ui-hover">
            <Field title={title} subtitle={subtitle}>
              <Input {...other} value={src} onChange={this.onChange} />
              <File {...other} onChange={this.onUpload} onClick={this.fix} />
            </Field>
          </div>
        )}
        {src && (
          <video controls key={src}>
            <source id="myVideo" src={src} type={`video/webm`} />
          </video>
        )}
      </div>
    );
  }
}

export default withMain(Video);
