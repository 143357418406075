import { HttpLink, InMemoryCache, ApolloClient } from 'apollo-boost';
import { ApolloLink } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { onError } from 'apollo-link-error';

export default (token) => {
  const retryLink = new RetryLink({
    delay: { initial: 500, max: 2000, jitter: true },
    attempts: { max: 2 },
  });

  const errorLink = onError((data) => {
    if (data.graphQLErrors) {
      console.log(data.graphQLErrors);
      console.log(`[graphql error]: ${data.graphQLErrors[0].message}`);
    }
    if (data.networkError) console.log(`[network Error]: ${data.networkError}`);
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_HTTP_LINK + '/graphql',
  });

  const middlewareLink = new ApolloLink((req, next) => {
    req.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return next(req);
  });

  const link = ApolloLink.from([
    retryLink,
    errorLink,
    middlewareLink.concat(httpLink),
  ]);

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  };

  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache({
      dataIdFromObject: (object) => object.key || null,
    }),
    defaultOptions,
  });
  return client;
};

export const _mutate = async (client, mutation, variables, opt) => {
  try {
    const res = await client.mutate({
      mutation: mutation,
      variables: variables,
      ...opt,
    });
    if (res.error || res.errors) throw new Error(res.error || res.errors);
    return { success: true, ...res.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const _query = async (client, query, variables, opt) => {
  try {
    const res = await client.query({
      query: query,
      variables: variables,
      ...opt,
    });
    if (res.error || res.errors) throw new Error(res.error || res.errors);
    return { success: true, ...res.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
