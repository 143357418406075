import React, { useState, useEffect } from 'react';
import { Select, Checkbox } from '@ask-mona/ui';

const freq = [
  { fr: "Dés qu'un message est reçu", en: 'As soon as a message is received' },
  { fr: 'Une fois par jour', en: 'One time per day' },
  { fr: 'Une fois par semaine', en: 'One time per week' },
];

function ScenarioChatbot(props) {
  const [messageCheck, setMessageCheck] = useState(false),
    [scrapCheck, setScrapCheck] = useState(false);

  useEffect(() => {
    //GET inside BDD
  });

  const changeMessageCheck = () => {
    messageCheck ? setMessageCheck(false) : setMessageCheck(true);
  };

  const changeScrapCheck = () => {
    scrapCheck ? setScrapCheck(false) : setScrapCheck(true);
  };

  const allFreq = freq.map((b) => {
    return { value: b[props.lang] };
  });

  return (
    <React.Fragment>
      <div className="w-100">
        <div className="m-md">
          {messageCheck ? (
            <div className="has-text-weight-semibold">
              <Checkbox
                onClick={changeMessageCheck}
                label="Messages back-office"
                defaultChecked
              />
            </div>
          ) : (
            <div className="has-text-weight-semibold">
              <Checkbox
                onClick={changeMessageCheck}
                label="Messages back-office"
              />
            </div>
          )}
        </div>
        {messageCheck && (
          <div className="m-md">
            <Select
              placeholder={freq[0][props.lang]}
              items={allFreq}
              noSearch={true}
            />
          </div>
        )}
        <div className="m-md">
          {scrapCheck ? (
            <div className="has-text-weight-semibold">
              <Checkbox
                onClick={changeScrapCheck}
                label="Dès que des événements sont scrapés"
                defaultChecked
              />
            </div>
          ) : (
            <div className="has-text-weight-semibold">
              <Checkbox
                onClick={changeScrapCheck}
                label="Dès que des événements sont scrapés"
              />
            </div>
          )}
        </div>
        {scrapCheck && (
          <div className="m-md">
            <Select
              placeholder={freq[0][props.lang]}
              items={allFreq}
              noSearch={true}
            />
          </div>
        )}
        <div className="m-md">
          <div className="has-text-weight-semibold">
            <Checkbox label="Dés les événements qui déterminent les horaires sont terminés" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ScenarioChatbot;
