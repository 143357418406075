export default {
  editQuestion: ['Éditer les questions', 'Editing questions'],
  remove: ['Délier la question/réponse', 'Unlink the question/answer'],
  nameQA: ['Nom de la question/réponse', 'Name of the question/answer'],
  exQuest: ['Description: ', 'Description: '],
  addQuest: ['Ajouter une question', 'Add a question'],
  addNewQA: ['Ajouter une question/réponse', 'Add a question/answer'],
  catName: ['Nom de la catégorie', 'Name of the category'],
  catNameFR: ['Nom de la catégorie (fr)', 'Category name (fr)'],
  catNameEN: ['Nom de la catégorie (en)', 'Category name (en)'],
  addCat: ['Ajouter une catégorie', 'Add a category'],
  addContent: ['+ Ajouter une réponse', '+ Add an answer'],
  add_an_answer: ["Ajouter une réponse", "Add an answer"],
  reminder: ['Un rappel vous sera envoyé', 'A reminder will be sent to you'],
  setPeriod: [
    'Programmer cette réponse pour une période ou une date',
    'Setup this answer for a period of time',
  ],
  startingDate: ["Date de début:", "Starting date:"],
  endingDate: ["Date de fin:", "Ending date:"],
  setMail: ['Programmer un rappel par mail', 'Setup an email reminder'],
  addElement: ['+ Ajouter un élément', '+ Add an item'],
  copyElements: ['+ Copier les éléments', '+ Copy items'],
  pasteElements: ['+ Coller les éléments', '+ Paste elements'],
  prog: ['Programmé', 'Scheduled'],
  addParam: [
    '+ Modifier les rappels et les périodes',
    '+ Modify reminders and periods',
  ],
  answerScope: [
    "Scope de la réponse:",
    "Answer scope:"
  ],
  replaceLocation: ["Remplacer le lieu", "Replace location"],
  allActivated: [
    'Voir toutes les réponses activées',
    'See all activated answers',
  ],
  allDesactivated: [
    'Voir toutes les réponses désactivées',
    'See all disabled answers',
  ],
  theme: ['Thématique', 'Theme'],
  answer: ['Réponse', 'Answer'],
  state: ['Statut', 'Status'],
  options: ['Options', 'Options'],
  activated: ['Réponses activées', 'Activated responses'],
  desactivated: ['Réponses désactivées', 'Disabled responses'],
  catNoTitle: ['Pas de titre sur la catégorie', 'No title on the category'],
  changeCategory: ['Changer de catégorie', 'Change category'],
  talkToHumain: ['Parler à un humain', 'Talk to human'],
  toCat: ['dans la catégorie', 'in the category'],
  editor: ['Éditeur', 'Publisher'],
  exQuestion: ['Description: ', 'Description: '],
  getHistory: ["Consulter l'historique des réponses", 'View response history'],
  history: ['Historique', 'History'],
  archivedBy: ['Archivé le', 'Archived on'],
  addLink: ['+ Ajouter un bouton', '+ Add a boutton'],
  buttonTitle: ['Titre du bouton', "Button title"],
  buttonLink: ['Adresse du lien', "Link address"],
  save: ['Enregister', 'Save'],
  enable: ['Activer', 'Enable'],
  disable: ['Désactiver', 'Disable'],
  cancel: ['Annuler', 'Cancel'],
  editCategorie: [
    'Modifier les informations sur la catégorie',
    'Edit category information',
  ],
  deleteCategorie: ['Supprimer cette catégorie', 'Delete this category'],
  text: ['Texte', 'Text'],
  for: ['Pour:', 'For:'],
  search: ['Rechercher', 'Search'],
  searchQa: ['Rechercher une q/r', 'Search for a q/a'],
  categoryOf: ['Catégories de q/r', 'Categories of q/a'],
  activatedQa: ['QR activées ', 'QA activated '],
  disabledQa: ['QR désactivées ', 'QA deactivated '],
  allQa: ['Toutes les QR ', 'All QA'],
  addQr: ['Ajouter un QR', 'Add a QA'],
  by: ['par', 'by'],
  back: ['retour', 'back'],
  sendQaTo: [
    'Envoyer la QR dans la catégorie:',
    'Send the QR in the category:',
  ],
  disableTalkToHuman: [
    'Êtes-vous sûre de vouloir désactiver la fonctionnalité “Parler à un humain” pour cette thématique ?',
    'Are you sure you want to disable the “talk to human” feature for this theme ?',
  ],
  enableTalkToHuman: [
    'Êtes-vous sûre de vouloir activer la fonctionnalité “Parler à un humain” pour cette thématique ?',
    'Are you sure you want to activate the “talk to human” feature for this theme ?',
  ],
  topicName: ['Nom de la thématique*', "Topic name*"],
  category: ['Catégorie*', "Category*"],
  description: ['Description', "Description"],
  intentLinked: ['"Intent" lié à cette thématique*', '"Intent" linked to this topic*'],
  groupEntityLinked: ['“Group entity” lié à cette thématique', '"Group entity" linked to this topic'],
  error: ['Erreur de liaison', "Link error"],
  intentAlreadyLinked: ['Cet intent est déjà lié à la thématique "', "This intent is already linked to the topic"],
  connectionError: ['Erreur de liaison'],
  groupEntityAlreadyLinked: ['Ce group entity est déjà lié à la thématique "', "This group entity is already linked to the topic"],
  createQa: ['Créer une QR', "Create a QA"],
  linkTopicToLEO: ['Liaison de cette thématique à LEO', "Link this topic to LEO"],
  linkToIntent: ['Lier un “intent” à cette thématique*', 'Link an "intent" to this topic*'],
  linkToGrpeEntity: ['Lier un “group entity” à cette thématique', 'link a "group entity" to this topic'],
  link: ['Lier', "Link"],
  qa_title: ["Questions / réponses", "Questions / answers"],
  missing_place: ["Lieu manquant", "Missing place"],
  missing_place_description: [
    'Créez un nouveau lieu dans l\'onglet “Infos pratiques” avant d\'éditer une réponse à une question',
    "Create a new place  in the tab \"Practical information\" before editing an answer to a question"
  ],

  direct_link_to_scenario: ["Lien direct vers le scénario", "Direct link to scenario"],
  direct_link_to_feature: ["Lien direct vers la fonctionnalité", "Direct link to feature"],
  unlink_qa: ["Délier la question/réponse", "Unlink the q/a"],

  link_feature_scenario: ["Liaison à une fonctionnalité / scénario", "Link to a feature / scenario"],
  link_to: ["lier à:", "Link to:"],
  link_to_scenario: ["Lier à un scénario:", "Link to a scenario:"],
  link_to_feature: ["Lier à la fontionnalité:", "Link to the feature"],
  a_feature: ["Une fonctionnalité", "A feature"],
  a_scenario: ["Un scénario", "A scenario"],

  feature_schedule: ["horaires", "schedule"],
  feature_programming: ["programmation", "programming"],
  feature_itinerary: ["itinéraire", "itinerary"],
  feature_prices: ["tarifs", "prices"],
  feature_speak_human: ["parler à un humain", "speak to a human"],
  feature_share_social_media: ["partgage réseaux sociaux", "share on social media"],
  feature_my_selection: ["ma sélection", "my selection"],
  feature_language: ["changement de langue", "change language"],

  fill_required_fields: ["Merci de remplir les champs obligatoires (avec une asterisque *)", "please, fill out the required fields (the ones with an asterisk*)"],
  saved: ["Enregistré !", "Saved !"],
  error: ["Une erreur est survenue.", "An error occutred."],
  image: ["Image", "Image"],
  video: ["Vidéo", "Video"],
  gif: ["GIF", "GIF"],
  the: ["le", "the"],
  for: ["pour", "for"],

  addCategory: ['Ajouter une catégorie', 'Add a category'],
  createCategory: ['Créer une catégorie', 'Create a category'],
  categoryNameFr: ['Nom de la catégorie* (FR)', 'Name of the category* (FR)'],
  categoryNameEn: ['Nom de la catégorie* (EN)', 'Name of the category* (EN)'],


};
