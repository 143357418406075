import React, { Component } from 'react';
import { Switch, Field, Select } from '@ask-mona/ui';
import Place from '../../_api/place';

class SubPlaceCreator extends Component {

  constructor(props) {
    super(props);
    this.state = {
      placesDeclaredInBot: [],
    };
  }

  componentDidMount() {
    this.fetchPlacesDeclaredInBot();
  }

  fetchPlacesDeclaredInBot = async () => {
    const callApi = new Place(this.props.m.client)
    const currentBotId = this.props.m.bot.id;
    const fetchPlaces = await callApi._getPlacesByBotId({ bot_id: currentBotId });
    if (fetchPlaces) {
      this.setState({
        placesDeclaredInBot: fetchPlaces.getPlaces.places,
      })
    }
  }

  render() {
    const {
      m: { w },
      isSub,
      onChange,
      onSelect,
      isSwitchVisible,
    } = this.props;

    const items = this.state.placesDeclaredInBot.map((p) => {
      return { ...p, value: p.name };
    });

    return (
      <div>
        {isSwitchVisible &&
          <Field className="m-b-md">
            <div className="has-text-weight-semibold m-r-md">
              {w.place.isSubPlace}
            </div>
            <Switch
              color="secondary"
              checked={isSub}
              onChange={() =>
                onChange({ target: { name: 'isSub', value: !isSub } })
              }
            />
          </Field>
        }
        <Field className="m-b-md">
          {isSub && (
            <Select items={items} onSelect={onSelect} className="w-100p" />
          )}
        </Field>
      </div >
    );
  }
}

export default SubPlaceCreator;
