import styled from "styled-components";

const InputFile = styled.input`
  position: absolute;
  opacity: 0;
  z-index: 1000;
  display: block;
  width: 100%;
  display: block;
  height: 100%;
  top: 0;
  bottom: 0;
  cursor: pointer;
`;

export default InputFile;
