import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import StyledImage from '../../components/parameters/StyledImage';
import CircleImg from '../../components/parameters/CircleImg';
import { Bot as BotIcon } from '@styled-icons/boxicons-regular/Bot';
import flagEn from '../../_assets/flag_gb.png';
import flagFr from '../../_assets/flag_fr.png';
import flagIt from '../../_assets/flag_it.png';
import FlagIcon from "../../components/FlagIcon";
import languageTranslations from "../../translations/languages";

const Bot = ({
  id,
  panel_logo,
  name,
  organization,
  status,
  findBot,
  total_users,
  created_at,
  platforms,
  admin,
  features,
  w,
}) => {
  const [languages, setLanguages] = useState([]);

  const platform =
    platforms.webchat && platforms.messenger
      ? 'Messenger + Webchat'
      : platforms.webchat
      ? 'Webchat'
      : platforms.messenger
      ? 'Messenger'
      : w.notSpecified;
  const dateOnline = new Date(Number(created_at)).toLocaleDateString('fr-FR');

  useEffect(() => {
      setLanguages(features.filter(({type})=> type === "language"));
  }, []);

  return (
    <WrapperGrid admin={admin} onClick={() => findBot(id)}>
      <StyledItem>
        {panel_logo ? (
          <CircleImg width={'30'} height={'30'}>
            <StyledImage
              width={'30'}
              src={panel_logo}
              alt={`Logo de ${name}`}
            />
          </CircleImg>
        ) : (
          <WrapperIcon>
            <BotIcon size={25} />
          </WrapperIcon>
        )}
      </StyledItem>
      <StyledItem>{name}</StyledItem>
      <StyledItem>{organization.name}</StyledItem>
      <StyledItem>{dateOnline}</StyledItem>
      <StyledItem>{total_users ? total_users : w.noDatas}</StyledItem>
      <StyledItem>{platform}</StyledItem>
      {admin && <StyledItem>{id}</StyledItem>}
      <StyledItem flex>
          {languages.map(({slug})=> (
              <StyledFlagIcon><FlagIcon code={languageTranslations.relatedCountry[slug]} /></StyledFlagIcon>
          ))}
      </StyledItem>
      <StyledItem>
        <SpanStatus
          color={
            status === 'ok'
              ? '#7ed321'
              : status === 'pending'
              ? '#F99B1C'
              : '#db7070'
          }
        ></SpanStatus>
      </StyledItem>
    </WrapperGrid>
  );
};

const WrapperGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ admin }) =>
    admin
      ? '1fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr'
      : '1fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr'};
  justify-content: center;
  border-bottom: 1px solid #2d2f7b;
  text-align: center;
  align-items: center;
  padding: 0.8rem;
  margin-bottom: 0.8rem;
  background: #fff;
  color: #2c2f7b;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.5s;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledFlagIcon= styled.div`
  > span {
    margin: 0 0.1rem;
    border: 1px solid #2d2f7b;
    border-radius: 10%;
  }
`;

const StyledItem = styled.div`
  place-self: center;
  display: ${({ flex }) => flex && 'flex'};
`;

const SpanStatus = styled.span`
  height: 15px;
  width: 15px;
  background-color: #7ed321;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 0.6rem;
`;

const WrapperIcon = styled.div`
  border-radius: 50%;
  border: 1px solid #2d2f7b;
  padding: 0.2rem;
`;

export default Bot;
