import styled from 'styled-components';

const CircleImg = styled.div`
  position: ${({ webview }) => webview && 'absolute'};
  right: ${({ webview }) => webview && '41%'};
  top: ${({ webview }) => webview && '-21px'};
  overflow: hidden;
  z-index: 2147483647;
  width: ${(props) => (props.width ? props.width : 40)}px;
  height: ${(props) => (props.height ? props.height : 40)}px;
  margin-right: ${(props) => (props.mr ? props.mr : 0)}rem;
  border: ${({ noBorder }) => !noBorder && '1px solid rgb(221, 221, 221)'};
  border-radius: ${({ noRadius }) => !noRadius && '50%'};
  bottom: unset;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
  align-self: ${(props) => (props.position ? props.position : null)};
  z-index: 1;
`;

export default CircleImg;
