import React from 'react';

export default ({ m: { w }, state }) => (
  <div
    className="flex row has-background-primary has-text-white is-size-6 sticky top items-center"
    style={{ zIndex: state.addPlace || state.edit || state.getInfo ? 0 : 1 }}
  >
    <div className="p-sm flex grow-3 basis-0">{w.direction.title}</div>
    <div className="p-sm flex grow-3 basis-0">{w.direction.address}</div>
    <div className="p-sm flex grow-1 basis-0 center">
      {w.direction.itinerary}
    </div>
    <div className="p-sm flex grow-1 basis-0 center">{w.generic.action}</div>
  </div>
);
