export function roleToPermissions (role) {
  switch(role) {
    case "Admin":
      return 1;
    case "Owner":
      return 2;
    case "Member":
      return 4;
    case "Unknown":
      return null;
  }
}

export function permissionsToRole (permissions) {
  switch (permissions) {
    case 1:
      return "Admin";
    case 2:
      return "Owner";
    case 4:
      return "Member";
    case null:
    default:
      return "Unkown";
  }
}
