import React from 'react';
import { Query } from 'react-apollo';
import Loader from './Loader';
import { _getKeyValue } from '../_tools/other';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default ({ onChange, name, selected, ...query }) => (
  <Query {...query}>
    {({ loading, error, data }) => {
      if (loading) return <Loader className="has-text-primary-ter" />;
      if (error) return null;
      let list = [];
      let items = _getKeyValue(data, name) || [];
      items.forEach((i) => {
        let label = '';
        i.translations &&
          i.translations.forEach((t) => {
            label = label || t.short_name || t.name || i.slug;
          });
        list.push(
          <FormControlLabel
            control={
              <Checkbox
                key={i.id}
                color="primary"
                label={label}
                onChange={() => onChange(i.id)}
                defaultChecked={selected.find((id) => i.id === id)}
              />
            }
            label={label}
            fontSize="small"
          />
        );
      });
      return list;
    }}
  </Query>
);
