import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import ArrowRightSharpIcon from '@material-ui/icons/ArrowRightSharp';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavWrapper = styled.nav`
  margin-top: 1.6rem;
`;

const active = 'active';
const Link = styled(NavLink).attrs({ active })`
  letter-spacing: 2px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.1em;
  display: inline-block;
  text-align: left;
  margin: 1rem 0;
  color: ${(props) => props.theme.colors.primary};

  &.${active} {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const LinkFunctionnality = styled(NavLink).attrs({ active })`
  letter-spacing: 2px;
  cursor: pointer;
  text-align: center;
  font-size: 0.9em;
  display: inline-block;
  text-align: left;
  text-transform: capitalize;
  margin: 1rem 0;
  color: ${(props) => props.theme.colors.primary};

  &.${active} {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const Title = styled.p`
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  cursor: pointer;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.1em;
  color: ${(props) => props.theme.colors.primary};
`;

const SideBarParameter = ({ w }) => {
  const [toggleActive, setToggleActive] = useState(false);
  const handleClick = () => {
    setToggleActive(!toggleActive);
  };

  return (
    <NavWrapper>
      <Link exact to='/office/parameters'>
        {w.parameters.chatBotIdentity}
      </Link>
      <Title onClick={handleClick}>
        {w.subsections.customFunctions}
        {toggleActive ? (
          <ArrowDropDownSharpIcon style={{ fontSize: 30 }} />
        ) : (
          <ArrowRightSharpIcon style={{ fontSize: 30 }} />
        )}
      </Title>
      {toggleActive && (
        <Wrapper>
          <LinkFunctionnality exact to='/office/parameters/functionality/admin'>
            {w.parameters.administration}
          </LinkFunctionnality>
          <LinkFunctionnality
            exact
            to='/office/parameters/functionality/server'
          >
            {w.parameters.servers}
          </LinkFunctionnality>
          <LinkFunctionnality
            exact
            to='/office/parameters/functionality/webchat'
          >
            {w.parameters.webchat}
          </LinkFunctionnality>
          <LinkFunctionnality
            exact
            to='/office/parameters/functionality/messenger'
          >
            {w.parameters.messenger}
          </LinkFunctionnality>
        </Wrapper>
      )}
      <Link exact to='/office/checklist'>
        {w.sections.analytics}
      </Link>
    </NavWrapper>
  );
};

export default SideBarParameter;
