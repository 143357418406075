import { languages } from '../../translations/';
import { _updateTranslations } from '../../_tools/translation';

export const _reducer = (state, action) => {
  switch (action.type) {
    case 'onChange':
      let {
        artwork: { translations },
      } = state;
      let newTranslations = _updateTranslations(translations, action.payload);
      return {
        ...state,
        artwork: { ...state.artwork, translations: newTranslations },
      };
    case 'setLangs':
      return { ...state, langs: action.payload };
    case 'addLang':
      return {
        ...state,
        langs: state.langs.concat(
          action.payload === 'fr' ? 'default' : action.payload
        ),
        artwork: {
          ...state.artwork,
          translations: state.artwork.translations.concat({
            language: {
              code: action.payload,
              id: languages[action.payload],
            },
            name: '',
          }),
        },
      };
    case 'setSlug':
      return { ...state, artwork: { ...state.artwork, slug: action.payload } };
    case 'setSubID':
      return { ...state, subID: action.payload };
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setArtwork':
      return { ...state, artwork: action.payload };
    case 'setImage':
      return {
        ...state,
        artwork: { ...state.artwork, cover_image: action.payload },
      };
    case 'setInfo':
      return { ...state, getInfo: action.payload };
    case 'addImage':
      return {
        ...state,
        artwork: {
          ...state.artwork,
          images: state.artwork.images.concat(action.payload),
        },
      };
    case 'setMarks':
      const { item, name } = action.payload;
      switch (name) {
        case 'getArtworkTags':
          let newTags = state.artwork.tags;
          if (!newTags.find((i) => i.id === item.id))
            return {
              ...state,
              artwork: { ...state.artwork, tags: newTags.concat(item.data) },
            };
          else
            return {
              ...state,
              artwork: {
                ...state.artwork,
                tags: newTags.filter((i) => i.id !== item.id),
              },
            };
        case 'authors':
          let newAuthors = state.artwork.authors;
          if (!newAuthors.find((i) => i.id === item.id))
            return {
              ...state,
              artwork: {
                ...state.artwork,
                authors: newAuthors.concat(item.data),
              },
            };
          else
            return {
              ...state,
              artwork: {
                ...state.artwork,
                authors: newAuthors.filter((i) => i.id !== item.id),
              },
            };
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
};

export const initialState = {
  loading: true,
  getInfo: false,
  error: null,
  langs: ['fr'],
  subID: 0,
  artwork: null,
  errors: {},
};
