import React, { Component } from 'react';
import AllElements from './newPush/AllElements';
import NavPrompt from '../../../components/NavPrompt';
import { withMain } from '../../../hoc/main';

class NewPush extends Component {
  elts() {
    const {
      state: { hasChange, fct, focus, payloads, element, array },
      title,
      save,
      create,
      bot_id,
    } = this.props;

    var buttons = [
      { name: 'Texte', icon: 'fas fa-font' },
      { name: 'Image', icon: 'fas fa-images' },
      { name: 'Vidéo', icon: 'fas fa-play-circle' },
      { name: 'Carousel', icon: 'far fa-window-restore' },
    ];

    return (
      <div>
        <div
          className="h-90 overflow-auto"
          style={save ? { opacity: '.5' } : { opacity: '1' }}
        >
          <NavPrompt
            when={hasChange === false && title !== null}
            confirm={
              create === 'new' ? fct.saveContentPush : fct.updateContentPush
            }
          />
          <AllElements
            bot_id={bot_id}
            array={array}
            payloads={payloads}
            element={element}
            focus={focus}
            fct={fct}
          />
        </div>
        <div
          className={
            array.length > 1
              ? 'flex row flex-wrap center m-t-md disabled'
              : 'flex row flex-wrap center m-t-md'
          }
          style={array.length > 0 ? { opacity: '.5' } : { opacity: '1' }}
        >
          {buttons.map((button, i) => {
            return (
              <button
                key={i}
                className="rad-15px is-size-7 button-push"
                onClick={(e) => fct.addContent(button.name)}
              >
                <span className="m-r-xs">
                  <i className={`m-r-xs" ${button.icon}`}></i>
                </span>
                {button.name}
              </button>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const {
      m: { w },
      state: { status },
      date,
    } = this.props;
    return (
      <div
        className={
          !status || date.save ? 'flex column disabled' : 'flex column'
        }
        style={!status || date.save ? { opacity: '.5' } : { opacity: '1' }}
      >
        <div className="flex column center h-1 items-center has-background-primary-lighter p-md has-text-primary">
          <h3 className="is-size-7">{w.push.contentNew}</h3>
          <p className="is-size-7">{w.push.subtitleNew}</p>
        </div>
        {this.elts()}
      </div>
    );
  }
}

export default withMain(NewPush);
