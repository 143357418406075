import React from 'react';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';

const Contact = ({ state, dispatch, wWebchat }) => (
  <div>
    <h3>{wWebchat.configuration}</h3>
    <div>
      <SubtitleLight margin={'1rem 0'}>{wWebchat.colorFont}</SubtitleLight>
      <ChromePicker
        color={state.webviewMailColor}
        onChangeComplete={(val) => {
          dispatch({ type: 'setWebviewMailColor', payload: val.hex });
        }}
      />
    </div>
  </div>
);

const SubtitleLight = styled.h3`
  font-size: 1.1.rem;
  margin: ${({ margin }) => margin};
`;

export default Contact;
