import React from 'react';
import CheckboxBool from './CheckboxBool';

function infoPart(props) {
  const { nlp, scenario, adv_scenario, fct } = props.context;
  return (
    <div className="m-t-md m-b-md m-l-md">
      <CheckboxBool
        label="Questions / réponses"
        bool={nlp.bool}
        fct={fct}
        array={[8]}
      />
      <CheckboxBool
        label="Fonctionnalités"
        bool={scenario.bool}
        fct={fct}
        array={[7]}
      />
      <CheckboxBool
        label="Scénarios personnalisés"
        bool={adv_scenario.bool}
        fct={fct}
        array={[15]}
      />
      {/* <Checkbox label="Messages par défaut"/> */}
    </div>
  );
}

export default infoPart;
