import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Intent from './Intent';
import Modal from '../../../components/Modal';
import { _sendFeedback } from '../../../_tools/ui';
import { Icon, Button, Field, Input } from '@ask-mona/ui';

import QaContext from '../context/qaContext';

const Categorie = (props) => {
  const {
    m: {
      w,
      user: { organization },
      bot,
    },
    search,
    focus,
    lang,
    cat,
  } = props;
  const [open, setOpen] = useState(focus.id === cat.id);
  const [addNew, setAddNew] = useState(false);
  let activated = [];
  let desactivated = [];
  const qaContext = useContext(QaContext);

  const leoBot = bot.features.some((el) => el.slug === 'leo');

  useEffect(() => {
    if (search) setOpen(true);
  }, [search]);

  cat['intents'] &&
    cat['intents'].forEach((el) => {
      if (leoBot) {
        if (el) {
          if (el.deleted) desactivated.push(el);
          else activated.push(el);
        }
      } else {
        if (el[lang]) {
          if (el[lang].deleted) desactivated.push(el);
          else activated.push(el);
        }
      }
    });

  return (
    <div>
      {organization.id === 1 &&
        focus.id !== 'allActivated' &&
        focus.id !== 'allDesactivated' &&
        !search &&
        false && (
          <Button onClick={() => setAddNew(true)} className="m-b-md">
            {w.qa.addNewQA}
          </Button>
        )}
      <AddNewQA {...props} isOpen={addNew} close={() => setAddNew(false)} />
      {open && (
        <>
          {qaContext.susbarFocus === 'activated' && !search && (
            <Table {...props} intents={activated} label={w.qa.activated} />
          )}
          {qaContext.susbarFocus === 'desactivated' && !search && (
            <Table
              {...props}
              intents={desactivated}
              label={w.qa.desactivated}
            />
          )}
          {(qaContext.susbarFocus === 'all' || search) && (
            <Table
              {...props}
              intents={activated.concat(...desactivated)}
              label={'Tous'}
            />
          )}
        </>
      )}
    </div>
  );
};

const AddNewQA = (props) => {
  const {
    m: { w, bot },
    api: { intent },
    cat,
    lang,
    isOpen,
    close,
  } = props;
  const [name, setName] = useState('');
  const [question, setQuestion] = useState('');
  const [questions, setQuestions] = useState([]);
  return (
    <Modal isOpen={isOpen} close={close} selector="#root">
      <div className="w-4 has-background-white">
        <div className="has-background-primary p-md has-text-white flex row space-between items-center">
          {w.qa.addNewQA}
          <Button onClick={close} className="is-outlined is-borderless shrink">
            <Icon icon="fas fa-times" />
          </Button>
        </div>
        <div className="p-md">
          <Field title={w.qa.nameQA} className="m-b-md">
            <Input onChange={(e) => setName(e.target.value)} />
          </Field>
          <Field title={w.qa.addQuest} className="m-b-md">
            <Input
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            <Button
              onClick={() => {
                if (question) setQuestions(questions.concat(question));
                setQuestion('');
              }}
            >
              {w.generic.add}
            </Button>
          </Field>
          {questions.length > 0 && (
            <>
              <span className="is-underlined">{w.qa.exQuest}</span>
              <div className="p-sm border-primary-lighter">
                {questions.map((el, i) => (
                  <div className="flex row flex-end items-center">
                    <div
                      key={i}
                      className="p-xs m-xs has-background-primary-lighter rad-5px"
                    >
                      {el}
                    </div>
                    <Button
                      color="danger"
                      className="is-outlined is-borderless shrink"
                      onClick={() => {
                        setQuestions(questions.filter((e, ind) => ind !== i));
                      }}
                    >
                      <Icon icon="fas fa-times" />
                    </Button>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="flex row space-between p-t-md">
            <Button color="danger" onClick={close}>
              {w.generic.cancel}
            </Button>
            <Button
              onClick={async () => {
                if (name && questions.length > 0) {
                  _sendFeedback('warning', 'loader');
                  let res = intent._createIntent(
                    {
                      bot_id: bot.id,
                      language: lang,
                      category: cat.id,
                      questions: questions,
                      content: [],
                      name: name,
                    },
                    { refetchQueries: ['getIntents'] }
                  );
                  if (res.success) {
                    _sendFeedback('success', w.success.created);
                    close();
                  } else _sendFeedback('danger', w.error.general);
                }
              }}
            >
              {w.generic.validate}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Table = (props) => {
  const {
    m: { w },
    intents,
    search,
  } = props;

  if (!intents.length) return null;

  return (
    <div>
      <div className="border-primary-light animation-slideDownFast overflow-hidden">
        {!search && (
          <TableHeader className="flex row has-background-primary has-text-white">
            <div className="p-xs flex center grow-3 basis-0">{w.qa.theme}</div>
            <div className="p-xs flex center grow-4 basis-0">{w.qa.answer}</div>
            <div className="p-xs flex center grow-2 basis-0">
              {w.qa.options}
            </div>
            <div className="p-xs flex center grow-1 basis-0">{w.qa.state}</div>
          </TableHeader>
        )}
        {intents
          .filter((i) => {
            if (i.programmatic && props.m.user.user.permissions !== 1)
              return false;
            else return true;
          })
          .map((el) => {
            return (
              <Intent
                key={el.id}
                {...props}
                el={el}
                programmatic={el.programmatic ? el.programmatic : null}
              />
            );
          })}
      </div>
    </div>
  );
};

const TableHeader = styled.div`
  height: 40px;
  align-items: center;
`;

export default Categorie;
