import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { withMain } from '../hoc/main';
import { withApi } from '../hoc/api';
import Foundations from '../components/base/Foundations';
import Sidebar from '../components/base/Sidebar';
import Header from '../components/base/Header';
import Button from '@material-ui/core/Button';
import ErrorBoundary from '../components/ErrorBoundary';
import ChatbotIndentity from './parameter/components/ChatbotIdentity';
import Administration from './parameter/functionality/Administration';
import SidebarParameter from './parameter/SideBarParameter';
import Messenger from './parameter/functionality/Messenger';
import Server from './parameter/functionality/Server';
import Webchat from './parameter/functionality/Webchat';

class Parameters extends Component {
  constructor(props) {
    super(props);
    const {
      m: { sectionID, menu },
    } = props;
    this.preURL = props.match.url;
    this.state = {
      section: menu.find((s) => s.id === sectionID) || {},
      subID: 0,
      saveFunction: async () => {},
    };
  }

  findTitle = (route) => {
    switch (route) {
      case '/office/parameters' || '/office/parameters/chatbot':
        return this.props.m.w.parameters.chatBotIdentity;
      case '/office/parameters/functionality/admin':
        return this.props.m.w.parameters.adminFeatures;
      case '/office/parameters/functionality/webchat':
        return this.props.m.w.parameters.webchatFeat;
      case "/office/parameters/functionality/webchat/page-d'accueil":
        return this.props.m.w.parameters.webchatFeat;
      case '/office/parameters/functionality/webchat/templates':
        return this.props.m.w.parameters.webchatFeat;
      case '/office/parameters/functionality/webchat/bulle':
        return this.props.m.w.parameters.webchatFeat;
      case '/office/parameters/functionality/webchat/fenêtre':
        return this.props.m.w.parameters.webchatFeat;
      case '/office/parameters/functionality/webchat/en-tête':
        return this.props.m.w.parameters.webchatFeat;
      case '/office/parameters/functionality/webchat/menu':
        return this.props.m.w.parameters.webchatFeat;
      case '/office/parameters/functionality/webchat/qr':
        return this.props.m.w.parameters.webchatFeat;
      case '/office/parameters/functionality/webchat/champ-Texte':
        return this.props.m.w.parameters.webchatFeat;
      case '/office/parameters/functionality/webchat/caroussels':
        return this.props.m.w.parameters.webchatFeat;
      case '/office/parameters/functionality/webchat/webview':
        return this.props.m.w.parameters.webchatFeat;
      case '/office/parameters/functionality/webchat/bulles-messages':
        return this.props.m.w.parameters.webchatFeat;
      case '/office/parameters/functionality/webchat/messagerie':
        return this.props.m.w.parameters.webchatFeat;
      case '/office/parameters/functionality/messenger':
        return this.props.m.w.parameters.adminFeatures;
      case '/office/parameters/functionality/server':
        return this.props.m.w.parameters.adminFeatures;
      case '/office/parameters/analytics':
        return this.props.m.w.parameters.analyticsFeat;
      default:
        break;
    }
  };

  setSaveFunction = (saveFunction) => {
    this.setState({
      saveFunction: saveFunction,
    });
  };

  render() {
    const { section, saveFunction } = this.state;
    const { m, api, location } = this.props;
    const w = m.w.parameters;
    const history = this.props.history;
    return (
      <Foundations
        aside={
          <Sidebar
            menu={section.menu}
            c={this.state}
            noarrow

            addons={[<SidebarParameter key={1} w={m.w} />]}
          />
        }
        header={
          <Header
            title={this.findTitle(this.props.history.location.pathname)}
            addons={
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  saveFunction();
                }}
              >
                {w.save}
              </Button>
            }
          />
        }
      >
        <Route
          exact
          path={`${this.preURL}`}
          render={() => (
            <ErrorBoundary>
              <ChatbotIndentity m={m} setSaveFunction={this.setSaveFunction} />
            </ErrorBoundary>
          )}
        />
        <Route
          exact
          path={`${this.preURL}/functionality/admin`}
          render={() => (
            <ErrorBoundary>
              <Administration m={m} setSaveFunction={this.setSaveFunction} />
            </ErrorBoundary>
          )}
        />
        <Route
          path={`${this.preURL}/functionality/webchat`}
          render={() => (
            <ErrorBoundary>
              <Webchat
                m={m}
                setSaveFunction={this.setSaveFunction}
                api={api}
                preURL={this.preURL}
                pathname={location.pathname}
                history={history}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          exact
          path={`${this.preURL}/functionality/messenger`}
          render={() => (
            <ErrorBoundary>
              <Messenger m={m} setSaveFunction={this.setSaveFunction} />
            </ErrorBoundary>
          )}
        />
        <Route
          exact
          path={`${this.preURL}/functionality/server`}
          render={() => (
            <ErrorBoundary>
              <Server m={m} setSaveFunction={this.setSaveFunction} />
            </ErrorBoundary>
          )}
        />
      </Foundations>
    );
  }
}

export default withMain(withApi(Parameters));
