import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon } from '@ask-mona/ui';
import ArrowRightSharpIcon from '@material-ui/icons/ArrowRightSharp';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      active: true,
    };
  }

  handleClick = () => {
    this.setState({ active: !this.state.active });
    this.props.history &&
      this.props.history.push(`${this.props.match.path}${this.props.subpath}`);
  };

  render() {
    return (
      <Container
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <Tab onClick={this.handleClick}>
          <TabContent>
            <TabIcon>
              <Icon icon={this.props.icon} color="primary" />
            </TabIcon>
            <TabTitle>{this.props.label}</TabTitle>
          </TabContent>

          <ArrowIcon>
            {(!this.props.click && (this.state.hover || this.state.fixed)) ||
              (this.props.click && this.state.active) ? (
                <ArrowDropDownSharpIcon style={{ fontSize: 30 }} />
              ) : (
                <ArrowRightSharpIcon style={{ fontSize: 30 }} />
              )}
          </ArrowIcon>
        </Tab>
        <AddonContainer
          className={`${this.props.padding || 'p-md'} overflow-${this.props.overflow || 'hidden'
            } ${(!this.props.click && (this.state.hover || this.state.fixed)) ||
              (this.props.click && this.state.active)
              ? 'animation-slideDown'
              : 'none'
            }`}
        >
          {this.props.children}
        </AddonContainer>
      </Container>
    );
  }
}

export default Filter;

const Container = styled.div``;

const Tab = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 20px auto;
  color: ${(p) => p.theme.colors.primary};
  font-weight: 510;
`;

const TabContent = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const TabIcon = styled.div`
  margin-right: 7px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1px;
`;

const TabTitle = styled.div`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 1px;
`;

const ArrowIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
`;

const AddonContainer = styled.div`
  color: ${(p) => p.theme.colors.primary};
  margin-top: -12px;
`;
