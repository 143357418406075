const Webview = (template) => {
  switch (template) {
    case 'template1':
      return {
        v2: true,
        backgroundHeader: '#eee',
        borderRadius: '25px',
        height: '95%',
        icon: true,
      }
    case 'template2':
      return {
        v2: true,
        backgroundHeader: '#eee',
        borderRadius: '25px',
        height: '75%',
        icon: true,
      }
    case 'template3':
      return {
        v2: true,
        backgroundHeader: '#eee',
        borderRadius: '25px',
        height: '95%',
      }
    case 'template4':
      return {
        v2: true,
        backgroundHeader: '#eee',
        borderRadius: '0px',
        height: '95%',
      }
    default:
      break;
  }
};

export default Webview;