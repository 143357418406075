import React, { useEffect, useState } from 'react';
import { withGlobals } from '../hoc/withGlobals';

const Header = ({ context: { focus = {} }, w }) => {
  const { history } = focus;
  const user = focus.id?.split('_')[1].slice(0, 4);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  useEffect(() => {
    if (history) {
      setDate(
        new Date(history[0].time)
          .toLocaleDateString()
          .substr(0, 10)
          .replace(/-/g, '/')
      );
      setTime(new Date(history[0].time).toTimeString().substr(0, 5));
    }
  }, [history]);

  return (
    <div
      className={`flex p-md space-between has-text-white has-background-primary-ter`}
    >
      <span>{w.generic.user} {user}</span>
      <span>{`${w.webchat.history_first_conversation} ${date} ${w.generic.at_time} ${time}` || ''}</span>
    </div>
  );
};

export default withGlobals(Header);
