import gql from 'graphql-tag';
import { _mutate, _query } from './client';

class Place {
  constructor(client) {
    this.client = client;
  }

  _getPlace = async (variables, opt) => {
    return await _query(this.client, GET_PLACE, variables, opt);
  };

  _getPlacesByBotId = async (variables, opt) => {
    return await _query(this.client, GET_PLACES_BY_BOT_ID, variables, opt);
  };

  _getBotHasPlace = async (variables, opt) => {
    return await _query(this.client, GET_BOT_HAS_PLACES, variables, opt);
  };

  _createPlace = async (variables, opt) => {
    return await _mutate(this.client, CREATE_PLACE, variables, opt);
  };

  _createSubPlace = async (variables, opt) => {
    return await _mutate(this.client, CREATE_SUB_PLACE, variables, opt);
  };

  _updatePlace = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_PLACE, variables, opt);
  };

  _updateSubPlace = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_SUB_PLACE, variables, opt);
  };

  _updateBotHasPlace = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_BOT_HAS_PLACE, variables, opt);
  };

  _setBotHasPlace = async (variables, opt) => {
    return await _mutate(this.client, SET_BOT_HAS_PLACE, variables, opt);
  };

  _deleteBotHasPlace = async (variables, opt) => {
    return await _mutate(this.client, DELETE_BOT_HAS_PLACE, variables, opt);
  };

  _deleteSubPlace = async (variables, opt) => {
    return await _mutate(this.client, DELETE_SUB_PLACE, variables, opt);
  };
}

export default Place;

const UPDATE_PLACE = gql`
  mutation (
    $id: Int!
    $city_id: Int
    $name: String
    $address: String
    $short_address: String
    $lat: Float
    $long: Float
    $google_place_id: String
    $postcode: String
    $opening_hours: String
    $image_url: String
    $pronoun: String
  ) {
    updatePlace(
      id: $id
      city_id: $city_id
      name: $name
      address: $address
      short_address: $short_address
      lat: $lat
      long: $long
      google_place_id: $google_place_id
      postcode: $postcode
      opening_hours: $opening_hours
      image_url: $image_url
      pronoun: $pronoun
    ) {
      id
      name
      opening_hours
      pronoun
    }
  }
`;

const UPDATE_SUB_PLACE = gql`
  mutation (
    $id: Int!
    $name: String
    $address: String
    $opening_hours: String
    $lat: Float
    $long: Float
    $place_id: Int
    $itinerary: Boolean
  ) {
    updateSubPlace(
      id: $id
      name: $name
      address: $address
      opening_hours: $opening_hours
      lat: $lat
      long: $long
      place_id: $place_id
      itinerary: $itinerary
    ) {
      id
    }
  }
`;

export const GET_PLACE = gql`
  query getPlace($id: Int!) {
    getPlace(id: $id) {
      id
      opening_hours
      image_url
      pronoun
    }
  }
`;

export const GET_SUB_PLACE = gql`
  query getSubPlace($id: Int) {
    getSubPlace(id: $id) {
      id
      opening_hours
      itinerary
      name
    }
  }
`;

export const GET_SUB_PLACES = gql`
  query (
    $place: String
    $name: String
    $address: String
    $limit: Int
    $offset: Int
  ) {
    getSubPlaces(
      place: $place
      name: $name
      address: $address
      limit: $limit
      offset: $offset
    ) {
      subplaces {
        id
        name
        address
        place {
          id
          name
        }
      }
    }
  }
`;

export const GET_PLACES = gql`
  query ($name: String) {
    getPlaces(name: $name) {
      places {
        id
        name
        short_address
        address
        image_url
        sub_places {
          id
          name
          address
        }
      }
    }
  }
`;

const GET_PLACES_BY_BOT_ID = gql`
  query ($bot_id: Int) {
    getPlaces(bot_id: $bot_id) {
      places {
        id
        name
        short_address
        address
        image_url
        sub_places {
          id
          name
          address
        }
      }
    }
  }
`;

const CREATE_PLACE = gql`
  mutation (
    $city_id: Int
    $name: String!
    $address: String!
    $short_address: String!
    $lat: Float!
    $long: Float!
    $google_place_id: String
    $postcode: String
    $image_url: String
    $pronoun: String
  ) {
    createPlace(
      city_id: $city_id
      name: $name
      address: $address
      short_address: $short_address
      lat: $lat
      long: $long
      google_place_id: $google_place_id
      postcode: $postcode
      image_url: $image_url
      pronoun: $pronoun
    ) {
      id
      name
      short_address
      address
      pronoun
    }
  }
`;

const CREATE_SUB_PLACE = gql`
  mutation (
    $name: String!
    $address: String
    $opening_hours: String
    $lat: Float
    $long: Float
    $place_id: Int!
    $itinerary: Boolean
  ) {
    createSubPlace(
      name: $name
      address: $address
      opening_hours: $opening_hours
      lat: $lat
      long: $long
      place_id: $place_id
      itinerary: $itinerary
    ) {
      id
      name
      itinerary
    }
  }
`;

const SET_BOT_HAS_PLACE = gql`
  mutation ($bot_id: Int!, $place_id: Int!, $itinerary: Boolean) {
    setBotHasPlace(
      bot_id: $bot_id
      place_id: $place_id
      itinerary: $itinerary
    ) {
      id
    }
  }
`;

export const GET_BOT_HAS_PLACES = gql`
  query getBotHasPlaces($bot_id: Int!, $place_id: Int) {
    getBotHasPlaces(bot_id: $bot_id, place_id: $place_id) {
      itinerary
      place_id
      bot_id
    }
  }
`;

export const UPDATE_BOT_HAS_PLACE = gql`
  mutation ($bot_id: Int!, $place_id: Int!, $itinerary: Boolean) {
    updateBotHasPlace(
      bot_id: $bot_id
      place_id: $place_id
      itinerary: $itinerary
    ) {
      id
    }
  }
`;

const DELETE_BOT_HAS_PLACE = gql`
  mutation ($bot_id: Int!, $place_id: Int!) {
    deleteBotHasPlace(bot_id: $bot_id, place_id: $place_id)
  }
`;

const DELETE_SUB_PLACE = gql`
  mutation ($id: Int!) {
    deleteSubPlace(id: $id)
  }
`;
