import React from 'react';
import Text from './components/Text';
import Image from './components/Image';
import Video from './components/Video';
import Audio from './components/Audio';
import Generic from './components/Generic';
import Button from './components/Button';
import Media from './components/Media';
import Iframe from './components/Iframe';
import List from './components/List';
import EventsElement from './components/EventsElement';
import Random from './components/Random';

export default ({ el, ...props }) => {
  const {
    m: { w },
  } = props;
  let element = el;

  let ret = null;

  //Convert string to text template
  if (typeof el === 'string') {
    if (el.includes('LOGIC_PROG')) {
      ret = <EventsElement {...props} logicString={el} />;
      element = null;
    } else {
      element = { text: el };
    }
  }

  if (Array.isArray(el)) ret = <Random {...props} arrayOfElements={element} />;
  if (element?.attachment?.payload?.template_type === 'iframe')
    ret = <Iframe {...props} element={element} toWebview />;
  if (element && 'text' in element) ret = <Text {...props} element={element} />;
  else if (element && 'attachment' in element) {
    const { type } = element['attachment'];
    switch (type) {
      case 'image':
        ret = <Image {...props} element={element} />;
        break;
      case 'video':
        ret = <Video {...props} element={element} />;
        break;
      case 'audio':
        ret = <Audio {...props} element={element} />;
        break;
      case 'iframe':
        ret = <Iframe {...props} element={element} />;
      case 'template':
        const {
          payload: { template_type },
        } = element['attachment'];
        switch (template_type) {
          case 'generic':
            ret = <Generic {...props} element={element} />;
            break;
          case 'button':
            ret = <Button {...props} element={element} />;
            break;
          case 'list':
            ret = <List {...props} element={element} />;
            break;
          case 'media':
            ret = <Media {...props} element={element} />;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  ret = ret || (
    <div className="p-md rad-15px has-background-grey-lighter">
      {w.error.unhandled}
    </div>
  );

  return ret;
};
