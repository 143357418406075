export default {
  schedule: ['Horaires du lieu', 'Schedule of the place'],
  pricing: ['Tarifs', 'Pricing'],
  direction: ['Itinéraire', 'Itinerary'],

  customFunctions: ['Fonctionnalités', "Features"],
  scenarioBasic: ['Scénario', 'Scenario'],
  scenarioAdvanced: ['Scénarios persos', 'Custom scenarios'],
  knowledge: ['Questions / Réponses', 'Questions / Answers'],
  menu: ['Menu', 'Menu'],
  knowledgeTest: ['Questions / Réponses (Test)', 'Questions / Answers (Test)'],
  parameters: ['Paramètres', 'Parameters'],

  eventInformation: ['Informations générales', 'General information'],
  eventSchedule: ["Horaires de l'événement", "Schedule of the event"],
  eventDescription: ["Description de l'événement", "Description of the event"],
  eventTranslation: ["Description de l'évènement", "Description of the event"],
  inboxMessage: ['Messagerie', 'Messaging'],
  inboxArchive: ['Conversations archivés', 'Archived Conversations'],
  wcHistory: ['Historique de webchat', 'Webchat history'],
  wcMail: ['Messagerie du webchat', "Webchat messaging"],
  accountUser: ['Mon compte', 'My account'],
  accountTeam: ['Mon équipe', 'My team'],
  medArtworks: ['Les oeuvres du parcours', 'The artworks of the course'],
  medScenario: ['Scénario du parcours', 'Scenario of the course'],
  artInformation: ['Informations générales', 'General information'],
  artContent: ["Contenu sur l'oeuvre", 'Content about the art work'],
  artTrigger: ['Déclancheur', 'Trigger'],
  botSettings: ['Paramètres du chatbot', "Chatbot settings"],

  //CHECKLIST
  featuresPanel: ['Fonctionnalités du panel', 'Panel features'],
  featuresAnalytics: ["Fonctionnalités page d'accueil", 'Home page features'],
  scenario: ['Scenario/Type Chatbot', 'Scenario/Type Chatbot'],
  specificityScenario: [
    'Spécificités scenario chatbot',
    'Specificities of the chatbot scenario',
  ],
  place: ['Lieu principal', 'Main location'],
  frequency: ['Fréquence', 'Frequency'],
  tracker: ['Tracker', 'Tracker'],
  languages: ['Langues', 'Languages'],
};
