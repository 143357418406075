import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { Icon, Button } from '@ask-mona/ui';
import AlertMessages from '../../components/AlertMessages';
import StyledImage from '../../components/parameters/StyledImage';
import CircleImg from '../../components/parameters/CircleImg';

const DropdownMenu = (props) => {
  const [isActive, setIsActive] = useState(false);

  const disconnect = () => {
    const { user } = props;
    user._logout();
    localStorage.removeItem("token");
    sessionStorage.clear();
    props.history.push('/signin');
  };

  return (
    <Container>
      {!props.bot?.panel_logo?.length ? (
        <AvatarContainer onClick={() => setIsActive(!isActive)}>
          <Icon icon="fas fa-user" />
        </AvatarContainer>
      ) : (
        <CircleImg
          onClick={() => setIsActive(!isActive)}
          width={'50'}
          height={'50'}
          mr={'1'}
          noBorder
        >
          <StyledImage
            width={'50'}
            src={props.bot.panel_logo}
            alt={`Logo de ${props.bot.panel_logo}`}
          />
        </CircleImg>
      )}
      <Menu
        isActive={isActive}
        disconnect={disconnect}
        w={props.w}
        bot={props.bot}
        {...props}
      />
    </Container>
  );
};

const Menu = ({
  isActive,
  toggle,
  disconnect,
  w,
  bot,
  user: { user, organization },
  switchBot,
  ...other
}) => {
  if (!isActive) return null;
  return (
    <DropdownContainer
      className="absolute right flex column has-background-white p-xs shadow rad-5 animation-slideDown overflow-auto"
      style={{ zIndex: 3 }}
    >
      <UserName>{`${user.firstname} ${user.lastname}`}</UserName>
      {Object.keys(bot).length !== 0 && (
        <Link to="/office/account" className="flex center">
          <Button
            className="m-xs is-outlined is-borderless has-text-primary"
            style={{ color: '#2D2F7B' }}
          >
            {w.sections.personal}
          </Button>
        </Link>
      )}
      {organization.id === 1 && Object.keys(bot).length !== 0 && (
        <Link to="/office/checklist" className="flex center">
          <Button
            className="m-xs is-outlined is-borderless has-text-primary"
            style={{ color: '#2D2F7B' }}
          >
            {w.sections.checklist}
          </Button>
        </Link>
      )}
      <Button
        className="m-xs is-outlined is-borderless has-text-primary flex center"
        style={{ color: '#2D2F7B' }}
        onClick={disconnect}
      >
        {w.generic.disconnect}
        <Icon icon="fas fa-sign-out-alt" />
      </Button>
      {Object.keys(bot).length !== 0 && (
        <Display>
          <AlertContainer>
            <AlertMessages bot_id={bot.id} />
          </AlertContainer>
        </Display>
      )}
    </DropdownContainer>
  );
};

export default withRouter(DropdownMenu);

const Container = styled.div``;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.primary};
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #fafafa;
  border: ${(p) => p.theme.colors.primary} 1px solid;
`;

const Display = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (min-width: 1700px) {
    display: none;
  }
`;

const AlertContainer = styled.div`
  margin-right: 14px;
  margin-bottom: 5px;
  text-align: center;
`;

const DropdownContainer = styled.div`
  color: ${(p) => p.theme.colors.primary};

  @media (min-width: 1400px) {
    margin-bottom: 50px;
  }
`;

const UserName = styled.div`
  color: ${(p) => p.theme.colors.primary};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
`;
