import React from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { makeStyles } from '@material-ui/core/styles';
import FlagIcon from "./FlagIcon";
import languageTranslations from "../translations/languages";

const StyledOption = styled.option`
  color: #111 !important;
`;

const useStyles = makeStyles((theme) => ({
  whiteColor: {
    color: 'white',
  },
  icon: {
    fill: 'white',
  },
  select: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
  },
}));

const LangDropdown = (props) => {
  const {
    m: { bot_lang },
    selectedLang,
    setSelectedLang,
  } = props;
  const classes = useStyles();

  if (bot_lang.length <= 1) return null;
  return (
    <FormControl
      variant="filled"
      color="secondary"
      classes={{
        root: classes.whiteColor,
      }}
    >
      <NativeSelect
        classes={{
          root: classes.whiteColor,
          icon: classes.whiteColor,
        }}
        color="secondary"
        variant="outlined"
        value={selectedLang}
        onChange={(event) => {
          setSelectedLang(event.target.value);
        }}
      >
        {bot_lang &&
          bot_lang.map((lang, i) => (
            <StyledOption id={lang} key={i} value={lang}>
              {lang === 'fr'
                ? 'Titre en français'
                : lang === 'en'
                ? 'Titre en anglais'
                : lang === 'it'
                ? 'Titre en italien'
                : lang}
            </StyledOption>
          ))}
      </NativeSelect>
    </FormControl>
  );
};

export default LangDropdown;
