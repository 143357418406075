import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import StyledContainer from '../../../components/parameters/StyledContainers';
import StyledLabel from '../../../components/parameters/StyledLabel';
import StyledTitle from '../../../components/parameters/StyledTitle';
import StyledInput from '../../../components/parameters/StyledInput';
import InputRadio from '../../../components/parameters/InputRadio';
import CircleImg from '../../../components/parameters/CircleImg';
import StyledImage from '../../../components/parameters/StyledImage';
import { _uploadImg } from '../../../_api/upload';
import StyledUpload from '../../../components/parameters/StyledUpload';
import InputFile from '../../../components/parameters/InputFile';
import Upload from '../../../_assets/icon/Upload';
import Bot from '../../../_api/bot';
import { _sendFeedback } from '../../../_tools/ui';

const Messenger = ({ m, setSaveFunction }) => {
  const [pageId, setPageId] = useState('');
  const [token, setToken] = useState('');
  const [personaName, setPersonaName] = useState('');
  const [personaNameTeam, setPersonaNameTeam] = useState('');
  const [idPersona, setIdPersona] = useState('');
  const [idPersonaTeam, setIdPersonaTeam] = useState('');
  const [personaNameUser, setPersonaNameUser] = useState([]);
  const [personaPictureUser, setPersonaPictureUser] = useState([]);
  const [personaPicture, setPersonaPicture] = useState('');
  const [personaTeamPicture, setPersonaTeamPicture] = useState('');
  const [LoadUpload, setLoadUpload] = useState(false);
  const [personaExist, setPersonaExist] = useState(false);
  const [personaTeamExist, setPersonaTeamExist] = useState(false);
  const [isActiveMessenger, setIsActiveMessenger] = useState('');

  const Api = new Bot(m.client, m.bot.id);
  const w = m.w.parameters;

  useEffect(() => {
    fetchAccessKey();
  }, []);

  useEffect(() => {
    if (setSaveFunction) {
      setSaveFunction(updateAccessKey);
    }
  }, [token]);

  useEffect(() => {
    getPersona();
  }, []);

  useEffect(() => {
    if (setSaveFunction && !personaExist) {
      setSaveFunction(createPersona);
    } else if (setSaveFunction && personaExist && idPersona) {
      setSaveFunction(updatePersona);
    }
  }, [personaName, personaPicture]);

  useEffect(() => {
    if (setSaveFunction && !personaTeamExist) {
      setSaveFunction(createPersonaTeam);
    } else if (setSaveFunction && personaTeamExist && idPersonaTeam) {
      setSaveFunction(updatePersonaTeam);
    }
  }, [personaNameTeam, personaTeamPicture]);

  useEffect(() => {
    fetchIsOnline();
  }, []);

  const fetchIsOnline = async () => {
    const bot = {
      id: m.bot.id,
    };
    const res = await Api._getBot(bot);
    if (res.success) {
      setIsActiveMessenger(res.getBot.platforms.messenger);
      setPageId(res.getBot.page_id);
    }
  };

  const fetchAccessKey = async () => {
    const bot = {
      id: m.bot.id,
    };
    const res = await Api._getFacebook(bot);
    if (res.success) {
      setToken(res);
    }
  };

  const updateAccessKey = async () => {
    const bot = {
      id: m.bot.id,
      facebook_access_key: token,
      page_id: pageId,
    };
    if (Api && bot) {
      const res = await Api._updateBot(bot);
      if (res.success) {
        setToken(res);
        _sendFeedback('success', m.w.success.saved);
      } else {
        _sendFeedback('danger', m.w.error.general);
      }
    }
  };

  const getPersona = async () => {
    const bot = {
      bot_id: m.bot.id,
    };
    if (Api && bot) {
      const res = await Api._getPersona(bot);
      if (res.getPersona) {
        const personaGlobal = res.getPersona.find(
          (persona) => persona.type === 'bot'
        );
        if (personaGlobal) {
          setPersonaName(personaGlobal.name);
          setPersonaPicture(personaGlobal.profile_picture);
          setPersonaExist(true);
          setIdPersona(personaGlobal.id);
        }

        const personaTeam = res.getPersona.find(
          (persona) => persona.type === 'team'
        );
        if (personaTeam) {
          setPersonaNameTeam(personaTeam.name);
          setPersonaTeamPicture(personaTeam.profile_picture);
          setPersonaTeamExist(true);
          setIdPersonaTeam(personaTeam.id);
        }
      }
    }
  };

  const createPersona = async () => {
    const persona = {
      bot_id: m.bot.id,
      name: personaName,
      profile_picture: personaPicture,
      type: 'bot',
    };
    if (Api && persona) {
      const res = await Api._createPersona(persona);
      if (res.success) {
        setPersonaExist(true);
        _sendFeedback('success', m.w.success.saved);
      } else {
        _sendFeedback('danger', m.w.error.general);
      }
    }
  };

  const updatePersona = async () => {
    const persona = {
      id: idPersona,
      name: personaName,
      profile_picture: personaPicture,
    };
    if (Api && persona) {
      const res = await Api._updatePersona(persona);
      if (res.success) {
        _sendFeedback('success', m.w.success.saved);
      } else {
        _sendFeedback('danger', m.w.error.general);
      }
    }
  };

  const createPersonaTeam = async () => {
    const persona = {
      bot_id: m.bot.id,
      name: personaNameTeam,
      profile_picture: personaTeamPicture,
      type: 'team',
    };
    if (Api && persona) {
      const res = await Api._createPersona(persona);
      if (res.success) {
        setPersonaTeamExist(true);
        _sendFeedback('success', m.w.success.saved);
      } else {
        _sendFeedback('danger', m.w.error.general);
      }
    }
  };

  const updatePersonaTeam = async () => {
    const persona = {
      id: idPersonaTeam,
      name: personaNameTeam,
      profile_picture: personaTeamPicture,
    };
    if (Api && persona) {
      const res = await Api._updatePersona(persona);
      if (res.success) {
        _sendFeedback('success', m.w.success.saved);
      } else {
        _sendFeedback('danger', m.w.error.general);
      }
    }
  };

  return (
    <StyledContainer width={'100'}>
      <WrapperInline>
        <StyledTitle>
          {isActiveMessenger
            ? w.messenger_online_on_facebook
            : w.messenger_offline_on_facebook}
        </StyledTitle>
      </WrapperInline>
      <StyledLabel width={'50'}>
        {w.pageId}
        <StyledInput
          type="text"
          value={pageId || undefined}
          onChange={(e) => {
            setPageId(e.target.value);
          }}
        />
      </StyledLabel>
      <StyledLabel width={'50'}>
        {w.token}
        <StyledInput
          type="text"
          value={token}
          placeholder={token ? token : w.messenger_enter_token}
          onChange={(e) => {
            setToken(e.target.value);
          }}
        />
      </StyledLabel>
      <StyledTitle>{w.persona}</StyledTitle>
      <WrapperDiv>
        <StyledLabel width={'30'} noMargin>
          {w.globalPersonaName}
          <StyledInput
            type="text"
            placeholder={personaName ? personaName : w.personaGlobalPlaceholder}
            value={personaName}
            maxLength="45"
            onChange={(e) => {
              setPersonaName(e.target.value);
            }}
          />
        </StyledLabel>
        <StyledDivWrapper>
          <StyledText>{w.globalPersonaImage}</StyledText>
          <StyledWrapperPersona>
            {personaPicture && (
              <>
                <CircleImg width={'80'} height={'80'} mr={'1'}>
                  <StyledImage width={'80'} src={personaPicture} alt="" />
                </CircleImg>
              </>
            )}
            <StyledUpload width={'37'}>
              <Upload width={42} />
              <div>
                <p>{w.download}</p>
              </div>
              <InputFile
                type="file"
                onChange={async (e) => {
                  setLoadUpload(true);
                  await _uploadImg(
                    m.bot.id,
                    e,
                    (res) => {
                      _sendFeedback("success", m.w.success.saved);
                      setLoadUpload(false);
                      setPersonaPicture(res.path);
                    },
                    (err) => {
                      setLoadUpload(false);
                    }
                  );
                }}
              />
            </StyledUpload>
          </StyledWrapperPersona>
        </StyledDivWrapper>
      </WrapperDiv>
      <WrapperDiv>
        <StyledLabel width={'30'} noMargin>
          {w.personaTeamName}
          <StyledInput
            type="text"
            maxLength="45"
            placeholder={
              personaNameTeam ? personaNameTeam : w.personaTeamPlaceholder
            }
            value={personaNameTeam}
            onChange={(e) => {
              setPersonaNameTeam(e.target.value);
            }}
          />
        </StyledLabel>
        <StyledDivWrapper>
          <StyledText>{w.personaTeamImage}</StyledText>
          <StyledWrapperPersona>
            {personaTeamPicture && (
              <>
                <CircleImg width={'80'} height={'80'} mr={'1'}>
                  <StyledImage width={'80'} src={personaTeamPicture} alt="" />
                </CircleImg>
              </>
            )}
            <StyledUpload width={'37'}>
              <Upload width={42} />
              <div>
                <p>{w.download}</p>
              </div>
              <InputFile
                type="file"
                onChange={async (e) => {
                  setLoadUpload(true);
                  await _uploadImg(
                    m.bot.id,
                    e,
                    (res) => {
                      _sendFeedback("success", m.w.success.saved);
                      setLoadUpload(false);
                      setPersonaTeamPicture(res.path);
                    },
                    (err) => {
                      setLoadUpload(false);
                    }
                  );
                }}
              />
            </StyledUpload>
          </StyledWrapperPersona>
        </StyledDivWrapper>
      </WrapperDiv>
    </StyledContainer>
  );
};

const WrapperInline = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const WrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 1rem 0;
`;

const StyledText = styled.p`
  font-weight: 300;
  font-size: 1em;
`;

const StyledDivWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const StyledWrapperPersona = styled.div`
  display: flex;
  align-items: center;
`;

export default Messenger;
