import React, { useCallback, useReducer } from 'react';
import { _sendFeedback } from '../../_tools/ui';
import { Field, Input, Button } from '@ask-mona/ui';
import LanguagesDropdown from "../../components/LanguagesDropdown";

import { updateUser } from "../../api/graphql/user.api";

const LANGUAGES = ["fr", "en"];

const _reducer = (state, action) => {
  switch (action.type) {
    case 'onChange':
      const { name, value } = action.payload;
      return { ...state, [name]: value, errors: {} };
    case 'setErrors':
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

const _checkFields = (state, w) => {
  let errors = null;
  const { firstname, lastname, password, newPassword } = state;
  if (!firstname) errors = { ...errors, firstname: w.error.missingFirstname };
  if (!lastname) errors = { ...errors, lastname: w.error.missingLastname };
  if (password || newPassword) {
    if (password !== newPassword)
      errors = { ...errors, password: w.error.passwordDoesNotMatch };
  }
  return errors;
};

export default (props) => {
  const {
    data: { getUser },
    m: { w, user, lang, fct: { switchLang} },
    other,
  } = props;
  const [state, dispatch] = useReducer(_reducer, {
    errors: {},
    firstname: '',
    lastname: '',
    password: '',
    newPassword: '',
    language_code: lang,
    ...getUser,
  });

  const onLanguageChange = useCallback((languageCode) => {
    dispatch({ type: "onChange", payload: { name: "language_code", value: languageCode} });
  }, [dispatch]);

  return (
    <div className="has-text-primary-ter p-md">
      <Field
        className="m-b-md"
        title={w.account.yourAccount}
        subtitle={w.account.info}
      ></Field>
      <Field
        className="m-b-md"
        title={w.account.firstname}
        error={state.errors.firstname}
      >
        <Input
          name="firstname"
          value={state.firstname}
          onChange={(e) => dispatch({ type: 'onChange', payload: e.target })}
        />
      </Field>
      <Field
        className="m-b-md"
        title={w.account.lastname}
        error={state.errors.lastname}
      >
        <Input
          name="lastname"
          value={state.lastname}
          onChange={(e) => dispatch({ type: 'onChange', payload: e.target })}
        />
      </Field>
      <Field
        className="m-b-md"
        title={w.account.newPass}
        error={state.errors.password}
      >
        <Input
          name="password"
          type="password"
          title={state.password}
          onChange={(e) => dispatch({ type: 'onChange', payload: e.target })}
        />
      </Field>
      <Field
        className="m-b-md"
        title={w.account.newPassTwo}
        error={state.errors.password}
      >
        <Input
          name="newPassword"
          type="password"
          value={state.newPassword}
          onChange={(e) => dispatch({ type: 'onChange', payload: e.target })}
        />
      </Field>

      <Field
        className="m-b-md"
        title={w.account.language}
      >
        <LanguagesDropdown
          languages={LANGUAGES}
          selectedLanguage={state.language_code}
          onLanguageSelected={onLanguageChange}
        />
      </Field>
      <div className="flex flex-end">
        <Button
          onClick={async () => {
            let errors = null;
            if ((errors = _checkFields(state, w))) {
              dispatch({ type: 'setErrors', payload: errors });
              return;
            } else {
              _sendFeedback('warning', 'loader');
              let variables = { id: user.user.id };
              if (state.firstname)
                variables = { ...variables, firstname: state.firstname };
              if (state.lastname)
                variables = { ...variables, lastname: state.lastname };
              if (state.password)
                variables = { ...variables, password: state.password };
              if(state.language_code)
                variables = { ...variables, language_code: state.language_code };

              try {
                const res = await updateUser(variables);
                switchLang(res.language_code);
                other.refetch();
                _sendFeedback('success', w.success.saved);

              } catch (err) {
                _sendFeedback('danger', w.error.general);
              }
            }
          }}
        >
          {w.generic.save}
        </Button>
      </div>
    </div>
  );
};
