import React from 'react';
import styled from 'styled-components';
import { Icon } from '@ask-mona/ui';
import MUIButton from '@material-ui/core/Button';

export default ({ m: { w }, dispatch }) => {
  return (
    <div className="m-l-md flex row grow-1 space-between">
      <div className="flex row">
        <MUIButton
          color="secondary"
          variant="contained"
          onClick={() => dispatch({ type: 'setAddPlace', payload: true })}
        >
          {w.generic.addPlace}
        </MUIButton>
      </div>
      <Spacing />
      <div className="flex row">
        <MUIButton
          color="primary"
          variant="contained"
          onClick={() => dispatch({ type: 'save' })}
        >
          {w.generic.save}
        </MUIButton>
        <MUIButton
          color="primary"
          className="shrink is-outlined m-l-xs"
          onClick={() => dispatch({ type: 'toggleInfo' })}
        >
          <Icon className="has-text-primary" icon="fas fa-exclamation-circle" />
        </MUIButton>
      </div>
    </div>
  );
};

const Spacing = styled.div`
  display: flex;
  width: 20px;
`;
