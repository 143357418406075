import styled from 'styled-components';

const StyledContainer = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  color: #2d2f7b;
  padding: 0 2rem;
  border-right: ${(props) => (props.border ? '1px solid #979797' : '')};
  height: fit-content;
`;

export default StyledContainer;
