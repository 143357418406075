import React, { useState } from 'react';
import styled from 'styled-components';
import Loader from '../../../components/Loader.js';
import SidebarSection from '../../../components/base/SidebarSection';
import Nodata from '../../../components/Nodata';

import { Icon } from '@ask-mona/ui';

import Popover, { ArrowContainer } from 'react-tiny-popover';

import { _sendFeedback } from '../../../_tools/ui';
import { makeStyles } from '@material-ui/core/styles';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';

export default (props) => {
  const {
    m: { w },
    sidebarheight,
    state,
    dispatch,
  } = props;

  if (state.loading) return <Loader className="has-text-primary-ter" />;

  if ('activated' in state && !state.activated)
    return <Nodata title={w.scenario.botDesactivated} />;

  if (state.error) throw new Error(state.error);

  return (
    <SidebarSection
      label={w.subsections.scenarioAdvanced}
      padding="-"
      overflow="-"
      click={true}
      hashistory={true}
      subpath="/personal-scenarios"
      maxheight={sidebarheight / 2 - 70}
      {...props}
    >
      <BoutonAjouterScenario {...props} {...state} />
      <Container
        onClick={() => {
          props.history.location.pathname !==
            `${props.match.path}/personal-scenarios` &&
            props.history.push(`${props.match.path}/personal-scenarios`);
        }}
      >
        {state.modules
          .filter((m) => !m.disable)
          .map((m) => (
            <Module
              key={m.id}
              {...props}
              mod={m}
              {...state}
              dispatch={dispatch}
            />
          ))}
        {state.modules
          .filter((m) => m.disable)
          .map((m) => (
            <Module
              key={m.id}
              {...props}
              mod={m}
              {...state}
              dispatch={dispatch}
            />
          ))}
      </Container>
    </SidebarSection>
  );
};

const Module = (props) => {
  const {
    location: { pathname },
    focus,
    dispatch,
    mod,
  } = props;

  const name = mod.name;

  return (
    <ModuleContainer>
      <ModuleElement
        onClick={() => dispatch({ type: 'setFocus', payload: mod })}
      >
        <ModuleBullet>
          {!mod.disable ? <ActivatedBullet /> : <DesactivatedBullet />}
        </ModuleBullet>
        <ModuleTitle focus={focus} mod={mod} pathname={pathname}>
          {' '}
          {mod.key === 'fallback' ? 'Autres' : name}{' '}
        </ModuleTitle>
        {pathname === '/office/scenarios/personal-scenarios' &&
          focus.id === mod.id && (
            <Icon icon="fas fa-chevron-right" className="has-text-secondary" />
          )}
      </ModuleElement>
    </ModuleContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  whiteColor: {
    color: 'white',
  },
  icon: {
    fill: 'white',
  },
  select: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
  },
}));

const BoutonAjouterScenario = (props) => {
  const classes = useStyles();
  const {
    m: { w },
    modules,
    allIds,
    Bot,
    focus,
    dispatch,
  } = props;

  const [name, setName] = useState('');
  const [type, setType] = useState('private-scenario');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      position={['right', 'bottom']}
      padding={5}
      onClickOutside={() => {
        setIsPopoverOpen(false);
        setName('');
      }}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={'#2D2F7B'}
          arrowSize={10}
          arrowStyle={{ zIndex: 10000, borderRight: '15px solid #2D2F7B' }}
        >
          <PopupContainer>
            <Header>
              <HeaderText>{w.scenario.addScenario}</HeaderText>
              <ButtonContainer>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={!(name?.length > 0)}
                  onClick={async () => {
                    _sendFeedback('warning', 'loader');
                    let res = await Bot._createScenarioFromTemplate({
                      name: name,
                      type: type,
                    });
                    if (res.success) {
                      if (!focus)
                        dispatch({ type: 'setFocus', payload: res.json });
                      dispatch({
                        type: 'setModules',
                        payload: modules.concat(res.json),
                      });
                      dispatch({
                        type: 'setIds',
                        payload: allIds.concat(res.json.id),
                      });
                      _sendFeedback('success', w.success.saved);
                      setIsPopoverOpen(false);
                      setType('private-scenario');
                    } else _sendFeedback('danger', w.error.general);
                  }}
                >
                  {w.generic.save}
                </Button>
              </ButtonContainer>
            </Header>
            <SectionContainer>
              <SectionTitle>{w.scenario.name}</SectionTitle>
              <SectionInput>
                <FormControl
                  variant="filled"
                  color="primary"
                  classes={{
                    root: classes.whiteColor,
                  }}
                >
                  <Input
                    classes={{
                      root: classes.whiteColor,
                      borderColor: 'white',
                    }}
                    value={name}
                    color="secondary"
                    variant="outlined"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </SectionInput>
            </SectionContainer>
            <SectionContainer>
              <SectionTitle>{w.scenario.type}</SectionTitle>
              <SectionInput>
                <FormControl
                  variant="filled"
                  color="secondary"
                  classes={{
                    root: classes.whiteColor,
                  }}
                >
                  <NativeSelect
                    classes={{
                      root: classes.whiteColor,
                      icon: classes.whiteColor,
                    }}
                    color="secondary"
                    variant="outlined"
                    value={type}
                    onChange={(event) => {
                      setType(event.target.value);
                    }}
                  >
                    <StyledOption id={0} key={0} value={'private-scenario'}>
                      {w.scenario.privateScenario}
                    </StyledOption>
                    <StyledOption id={1} key={1} value={'public-scenario'}>
                      {w.scenario.publicScenario}
                    </StyledOption>
                    <StyledOption id={2} key={2} value={'match'}>
                      {w.scenario.matchScenario}
                    </StyledOption>
                    <StyledOption id={3} key={3} value={'quizz'}>
                      {w.scenario.quizzScenario}
                    </StyledOption>
                  </NativeSelect>
                </FormControl>
              </SectionInput>
            </SectionContainer>
          </PopupContainer>
        </ArrowContainer>
      )}
    >
      <ButtonAddContainer
        onClick={() => {
          setIsPopoverOpen(!isPopoverOpen);
        }}
      >
        <ButtonPlusSymbol>
          <AddBoxRoundedIcon color="primary" />
        </ButtonPlusSymbol>
        <ButtonText>{props.m.w.scenario.addScenario}</ButtonText>
      </ButtonAddContainer>
    </Popover>
  );
};

const ButtonAddContainer = styled.div`
  background-color: white;
  padding: 8px 20px 8px 13px;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  border: 1px solid ${(p) => p.theme.colors.primary};
  margin: 10px auto 20px;
  cursor: pointer;
`;

const ButtonPlusSymbol = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled.div`
  color: ${(p) => p.theme.colors.primary};
  font-size: 0.9rem;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.colors.primary};
  height: 100%;
`;

const ModuleContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const ModuleElement = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
`;

const ModuleTitle = styled.span`
  display: flex;
  width: 150px;
  color: ${(p) =>
    p.pathname === '/office/scenarios/personal-scenarios' &&
    p.focus.id === p.mod.id
      ? p.theme.colors.secondary
      : p.theme.colors.primary};
  text-decoration: ${(p) =>
    p.pathname === '/office/scenarios/personal-scenarios' &&
    p.focus.id === p.mod.id
      ? `underline ${p.theme.colors.secondary}`
      : 'none'};
`;

const ModuleBullet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin-right: 10px;
`;

const ActivatedBullet = styled.span`
  width: 13px;
  height: 13px;
  background-color: rgba(126, 211, 33, 1);
  border-radius: 50%;
`;

const DesactivatedBullet = styled.span`
  width: 13px;
  height: 13px;
  background-color: rgba(226, 70, 41, 1);
  border-radius: 50%;
`;

const PopupContainer = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  position: relative;
  z-index: 1000;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled.span`
  margin-right: 20px;
  font-size: 1.1rem;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;

const SectionTitle = styled.div`
  margin-bottom: 10px;
`;
const SectionInput = styled.div`
  color: white;
`;

const StyledOption = styled.option`
  color: #111 !important;
`;
