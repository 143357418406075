import React from 'react';
import TimeInput from 'react-time-input';

export default ({ name, onTimeChange, ...other }) => (
  <TimeInput
    {...other}
    onTimeChange={(time) => onTimeChange({ time, name })}
    className="_input_time"
  />
);
