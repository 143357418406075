import gql from 'graphql-tag';

import { apiV3 } from "./apiV3";

import { permissionsToRole } from "../../_tools/role";

type createInvitationArgs = {
  email: string;
  permission: 1 | 2 | 4;
  languageCode: string;
  organizationId?: number;
};

type createInvitationOutput = {
  id: number;
  token: string;
};

const CREATE_INVITATION = gql`
  mutation createInvitation ($email: String!, $languageCode: String, $role: String, $organizationId: Int) {
    createInvitation(email: $email, language_code: $languageCode, role: $role, organization_id: $organizationId) {
      email
      token
    }
  }
`;

export async function createInvitation({ permission, ...args }: createInvitationArgs, opt): Promise<createInvitationOutput> {
  const role = permissionsToRole(permission);

  return await apiV3.mutate({
    mutation: CREATE_INVITATION,
    variables: { role, ...args },
    ...opt,
  });
}
