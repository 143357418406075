import React, { useRef, useEffect } from 'react';

const Case = ({ children, translation }) => {
  const caseBox = useRef();
  useEffect(() => {
    setTimeout(() => {
      let scrollHeight = caseBox.current.scrollHeight;
      let height = caseBox.current.clientHeight;
      let maxScrollTop = scrollHeight - height;
      caseBox.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }, 150);
  }, [
    translation.description.value,
    translation.anecdote.value,
    translation.date.value,
    translation.price.value,
    translation.practical_infos.value,
    translation.more_infos.value,
    translation.url.value,
    translation.url_ticketing.value,
  ]);

  return (
    <div id="iphone" className="animation-bounceInRight">
      <div id="hearing"></div>
      <div ref={caseBox} id="screen" className="overflow-scroll">
        {children}
      </div>
      <div id="button"></div>
    </div>
  );
};

export default Case;
