import React, { Component } from 'react';
import { withMain } from '../hoc/main';
import ChecklistMain from './checklist/ChecklistMain';
import { checklist as sections } from '../_tools/ui';
import Foundations from '../components/base/Foundations';
import Sidebar, { Sub } from '../components/base/Sidebar';
import { Header } from './checklist/Ui';
import { Icon, Button } from '@ask-mona/ui';

class Checklist extends Component {
  state = {
    titleFocus: '',
    view: 0,
    data: {
      lang: this.props.m.lang,
      organization_id: this.props.m.bot.organization_id,
      subID: 0,
    },
  };

  componentDidMount() {
    this.setState({ subID: 0 });
  }

  reload = () => window.location.reload();

  render() {
    const {
      m: { w },
    } = this.props;
    const { subID, subtitle, view } = this.state;
    return (
      <Foundations
        aside={
          <Sidebar
            c={this.state}
            addons={sections.map((s) => (
              <Sub
                key={s.id}
                noarrow={true}
                sub={{ ...s, title: w.subsections[s.w] }}
                active={subID === s.id}
                onClick={() =>
                  this.setState({
                    subID: s.id,
                    subtitle: w.subsections[s.w],
                    view: s.id,
                  })
                }
              />
            ))}
          />
        }
        header={
          <Header
            subID={subID}
            title={w.checklist.checklist}
            subtitle={!subtitle ? w.subsections.featuresAnalytics : subtitle}
            addons={
              subID === 1 && (
                <div className="m-l-md" onClick={this.reload}>
                  <Button>
                    <Icon icon="fas fa-sync-alt"></Icon>Rafraichir
                  </Button>
                </div>
              )
            }
          />
        }
      >
        <ChecklistMain
          view={view}
          organization_id={this.state.data.organization_id}
          user={this.props}
          lang={this.state.data.lang}
          fct={this.state.fct}
        />
      </Foundations>
    );
  }
}

export default withMain(Checklist);
