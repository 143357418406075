import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Switch, Icon } from '@ask-mona/ui';
import Modal from '../../../../../components/Modal';
import UpdateEmail from './UpdateEmail';
import DeleteEmail from './DeleteEmail';
import { _sendFeedback } from '../../../../../_tools/ui';
import { _isEmail } from '../../../../../_tools/is';

const User = ({
  email,
  chat_notification_disabled,
  id,
  handleUpdate,
  handleDelete,
  wWebchat,
  w,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [valueEmail, setValueEmail] = useState('');

  useEffect(() => {
    setIsActive(!chat_notification_disabled);
  }, []);

  useEffect(() => {
    setValueEmail(email);
  }, []);

  const handleUpdateEmail = () => {
    if (_isEmail(valueEmail)) {
      handleUpdate(id, chat_notification_disabled, valueEmail);
      setModalUpdate(false);
    } else {
      _sendFeedback('danger', w.errors.invalid);
    }
  };

  return (
    <WrapperGrid>
      <StyledItem>{email}</StyledItem>
      <StyledItem>
        <Switch
          color="secondary"
          checked={isActive}
          onChange={() => {
            setIsActive(!isActive);
            handleUpdate(id, !isActive, email);
          }}
        />
      </StyledItem>
      <StyledItem>
        <StyledIcon icon="fas fa-edit" onClick={() => setModalUpdate(true)} />
        {modalUpdate && (
          <Modal
            isOpen={modalUpdate}
            close={() => setModalUpdate(false)}
            selector="#root"
          >
            <UpdateEmail
              value={valueEmail}
              setValueEmail={setValueEmail}
              handleUpdateEmail={handleUpdateEmail}
              close={() => setModalUpdate(false)}
              wWebchat={wWebchat}
            />
          </Modal>
        )}
        <StyledIcon
          icon="fas fa-trash"
          color="red"
          onClick={() => setModalDelete(true)}
        />
        {modalDelete && (
          <Modal
            isOpen={modalDelete}
            close={() => setModalDelete(false)}
            selector="#root"
          >
            <DeleteEmail
              close={() => setModalDelete(false)}
              onConfirm={() => {
                handleDelete(id);
                setModalDelete(false);
              }}
              wWebchat={wWebchat}
            />
          </Modal>
        )}
      </StyledItem>
    </WrapperGrid>
  );
};

const WrapperGrid = styled.div`
  width: 100%;
  color: ${({ header }) => (header ? '#FFF' : '#2D307B')};
  background: ${({ header }) => (header ? '#2D307B' : '#FFF')};
  height: 56px;
  align-items: center;
  text-align: center;
  display: grid;
  grid-template-columns: 3fr 3fr 2fr;
  border-bottom: 1px solid #dadbf1;
`;

const StyledItem = styled.div`
  place-self: center;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export default User;
