import React, { useReducer } from 'react';
import DateRange from '../../components/DateRange';
import Loader from '../../components/Loader';
import { _getKeyValue } from '../../_tools/other';
import { Field, Input, Button, Select, Textarea, Switch } from '@ask-mona/ui';

const _reducer = (state, action) => {
  switch (action.type) {
    case 'onChange':
      const { name, value } = action.payload;
      return { ...state, [name]: value };
    case 'setPeriod':
      return {
        ...state,
        hasPeriod: action.payload,
        date_start: null,
        date_end: null,
      };
    case 'setDates':
      let { startDate, endDate } = action.payload;
      let date_start = startDate ? startDate.format('YYYY-MM-DD') : null;
      let date_end = endDate ? endDate.format('YYYY-MM-DD') : null;
      return { ...state, date_start, date_end };
    default:
      return { ...state };
  }
};

const NewMed = (props) => {
  const {
    m: { w, bot },
    onCancel,
    onConfirm,
    subPlaceData,
  } = props;
  const [state, dispatch] = useReducer(_reducer, {
    bot_id: bot.id,
    name: '',
    date_start: null,
    date_end: null,
    permanent: false,
    sub_place_id: null,
    description: '',
    errors: {},
    loading: false,
  });
  if (!subPlaceData || subPlaceData.loading)
    return <Loader className="has-text-primary-ter" />;
  const { getSubPlaces } = subPlaceData;
  let subplaces = _getKeyValue(getSubPlaces, 'subplaces');
  return (
    <div className="w-4 has-background-white rad-5">
      <div className="has-background-primary p-md has-text-white">
        {w.med.create}
      </div>
      <div className="p-md">
        <Field className="m-b-md" title={w.med.name}>
          <Input
            name="name"
            onChange={(e) => dispatch({ type: 'onChange', payload: e.target })}
          />
        </Field>
        <Field title={w.med.affPeriod} className="m-b-md">
          <div className="flex space-between w-100p m-t-md">
            {!state.permanent && (
              <DateRange
                anchorDirection="right"
                showClearDates
                small
                onChange={(dates) =>
                  dispatch({ type: 'setDates', payload: dates })
                }
                start={state.date_start}
                end={state.date_end}
              />
            )}
            <Switch
              color="secondary"
              defaultChecked={!state.permanent}
              onChange={() =>
                dispatch({
                  type: 'onChange',
                  payload: { name: 'permanent', value: !state.permanent },
                })
              }
            />
          </div>
        </Field>
        {subplaces && subplaces.length && (
          <Field title={w.med.place} className="m-b-md">
            <Select
              items={subplaces.map((p) => ({ id: p.id, value: p.name }))}
              className="w-100p"
              onSelect={(item) =>
                dispatch({
                  type: 'onChange',
                  payload: { name: 'sub_place_id', value: item.id },
                })
              }
            />
          </Field>
        )}
        <Field title={w.med.description}>
          <Textarea
            resize="auto"
            maxHeight={200}
            name="description"
            onChange={(e) => dispatch({ type: 'onChange', payload: e.target })}
          />
        </Field>
      </div>
      <div className="p-md">
        <div className="flex row space-between wrap">
          <Button onClick={onCancel} color="danger">
            {w.generic.cancel}
          </Button>
          <Button
            onClick={() => {
              const { loading, errors, hasPeriod, ...form } = state;
              onConfirm && onConfirm(form);
            }}
          >
            {w.generic.validate}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewMed;
