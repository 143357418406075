import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Frame, Field, Input } from '@ask-mona/ui';
import { _isEmail } from '../_tools/is';
import { withGlobals } from '../hoc/globals';
import logo from '../_assets/logoWhite.png';
import '../_styles/css/main.css';

class Signin extends Component {
  state = {
    loading: false,
    success: '',
    form: {
      email: '',
    },
    error: {
      email: '',
    },
  };

  submit = async () => {
    const { form } = this.state;
    const { w, user } = this.props.g;
    this.setState({ loading: true });
    const res = await user._register(form);
    if (!res.success) {
      if (!_isEmail(form.email))
        this.setState((state) => ({
          error: {
            email: w.error.email,
          },
        }));
      else
        this.setState((state) => ({
          error: {
            email: w.error.general,
          },
        }));
    } else this.setState({ success: w.form.registerSuccess });
    this.setState({ loading: false });
  };

  onChange = ({ target: { name, value } }) => {
    this.setState((state) => ({
      form: { ...state.form, [name]: value },
      error: { ...state.error, [name]: '' },
    }));
  };

  render() {
    const { w } = this.props.g;
    const {
      form: { email },
      error,
      loading,
      success,
    } = this.state;
    return (
      <div className="has-content-centered">
        <div className={`p-md w-4`}>
          <Frame background="transparent">
            <img
              src={logo}
              alt="AskMona-Logo"
              onLoad={() => this.setState({ loading: false })}
            />
          </Frame>
          <div className="has-background-white flex column p-md">
            <div>
              <Field
                title={w.form.email}
                subtitle={w.form.emailSub}
                error={error.email}
                className="m-b-md"
              >
                <Input name="email" value={email} onChange={this.onChange} />
                <Button
                  loading={loading}
                  className="is-uppercase"
                  onClick={this.submit}
                >
                  {w.generic.validate}
                </Button>
              </Field>
              {success && <div className="is-size-7">{success}</div>}
            </div>
            <div className="flex row space-between wrap">
              <Link to="/">
                <Button className="m-xs is-uppercase">
                  {w.generic.return}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withGlobals(Signin);
