import React from "react";
import { components } from 'react-select';
import { Key } from "@styled-icons/boxicons-solid/Key";
import { Keyboard } from "@styled-icons/entypo/Keyboard";
import { SearchAlt } from "@styled-icons/boxicons-regular/SearchAlt";
import { Photograph } from "@styled-icons/heroicons-outline/Photograph";
import { Instagram } from "@styled-icons/boxicons-logos/Instagram";
import { Messenger } from "@styled-icons/boxicons-logos/Messenger";
import { Share } from "@styled-icons/bootstrap/Share";
import { PhotoCamera } from "@styled-icons/material/PhotoCamera";
import { Alarm } from "@styled-icons/boxicons-regular/Alarm";
import { Award } from "@styled-icons/boxicons-regular/Award";
import { Bot } from "@styled-icons/boxicons-regular/Bot";
import { CalendarEvent } from "@styled-icons/boxicons-regular/CalendarEvent";
import { Robot } from "@styled-icons/fa-solid/Robot";
import { Cart } from "@styled-icons/boxicons-regular/Cart";
import { MoneyBillAlt } from "@styled-icons/fa-regular/MoneyBillAlt";
import { Directions } from "@styled-icons/boxicons-regular/Directions";
import { DonateHeart } from "@styled-icons/boxicons-regular/DonateHeart";
import { Gift } from "@styled-icons/boxicons-regular/Gift";
import { Handicap } from "@styled-icons/boxicons-regular/Handicap";
import { Like } from "@styled-icons/boxicons-regular/Like";
import { MapAlt } from "@styled-icons/boxicons-regular/MapAlt";
import { MapPin } from "@styled-icons/fa-solid/MapPin";
import { MapMarked } from "@styled-icons/fa-solid/MapMarked";
import { ChatAlt2 } from "@styled-icons/heroicons-outline/ChatAlt2";
import { PatchQuestionFill } from "@styled-icons/bootstrap/PatchQuestionFill";
import { QuestionSquareFill } from "@styled-icons/bootstrap/QuestionSquareFill";

export const IconMenu = ({icon, size}) => {
  switch(icon) {
    case "fas fa-key":
      return (<Key size={size || 24} />);
    case "fas fa-keyboard":
      return (<Keyboard size={size || 24}/>);
    case "fas fa-searchalt":
      return (<SearchAlt size={size || 24}/>);
    case "fas fa-photograph":
      return (<Photograph size={size || 24}/>);
    case "fas fa-instagram":
      return (<Instagram size={size || 24}/>);
    case "fas fa-messenger":
      return (<Messenger size={size || 24}/>);
    case "fas fa-share":
      return (<Share size={size || 24}/>);
    case "fas fa-photocamera":
      return (<PhotoCamera size={size || 24}/>);
    case "fas fa-alarm":
      return (<Alarm size={size || 24}/>);
    case "fas fa-award":
      return (<Award size={size || 24}/>);
    case "fas fa-bot":
      return (<Bot size={size || 24}/>);
    case "fas fa-calendarevent":
      return (<CalendarEvent size={size || 24}/>);
    case "fas fa-robot":
      return (<Robot size={size || 24}/>);
    case "fas fa-cart":
      return (<Cart size={size || 24}/>);
    case "fas fa-moneybillalt":
      return (<MoneyBillAlt size={size || 24}/>);
    case "fas fa-directions":
      return (<Directions size={size || 24}/>);
    case "fas fa-donateheart":
      return (<DonateHeart size={size || 24}/>);
    case "fas fa-gift":
      return (<Gift size={size || 24}/>);
    case "fas fa-handicap":
      return (<Handicap size={size || 24}/>);
    case "fas fa-like":
      return (<Like size={size || 24}/>);
    case "fas fa-mapalt":
      return (<MapAlt size={size || 24}/>);
    case "fas fa-mappin":
      return (<MapPin size={size || 24}/>);
    case "fas fa-mapmarked":
      return (<MapMarked size={size || 24}/>);
    case "fas fa-chatalt2":
      return (<ChatAlt2 size={size || 24}/>);
    case "fas fa-patchquestionfll":
      return (<PatchQuestionFill size={size || 24}/>);
    case "fas fa-questionsquarefill":
      return (<QuestionSquareFill size={size || 24}/>);
    default:
      return null;
  }
}

export const listIconMenu = ["fas fa-key", "fas fa-keyboard", "fas fa-searchalt", "fas fa-photograph", "fas fa-instagram", "fas fa-messenger", "fas fa-share", "fas fa-photocamera", "fas fa-alarm", "fas fa-award", "fas fa-bot", "fas fa-calendarevent", "fas fa-robot", "fas fa-cart", "fas fa-moneybillalt", "fas fa-directions", "fas fa-donateheart", "fas fa-gift", "fas fa-handicap", "fas fa-like", "fas fa-mapalt", "fas fa-mappin", "fas fa-mapmarked", "fas fa-chatalt2", "fas fa-patchquestionfll", "fas fa-questionsquarefill"];

const { Option } = components;
export const IconOption = props => (
  <Option {...props}>
    <IconMenu icon={props.data} />
  </Option>
);
