import React from 'react';
import styled from 'styled-components';
import Pagination from 'react-js-pagination';
import { _getKeyValue } from '../../../_tools/other';
import { Icon, Button, Checkbox, Field, Select } from '@ask-mona/ui';
import MUIButton from '@material-ui/core/Button';
import SearchBar from '../../../components/SearchBar';

const actions = [
  { id: 0, label: { fr: 'Publier', en: 'Publish' } },
  { id: 1, label: { fr: 'Supprimer', en: 'Delete' } },
];

export const Header = ({ m: { w, lang }, events, counter, fct, onChange }) => {
  return (
    <div className="m-l-xs flex row grow-1 space-between">
      <div className="flex items-center has-text-primary-ter">
        <Counter>{`(${counter}) `}</Counter>
        <SearchBarContainer>
          <SearchBar
            onChange={onChange}
            className="m-l-md"
            placeholder={w.generic.search}
            height={30}
          />
        </SearchBarContainer>
        {events.length > 0 && (
          <Field className="m-l-xs">
            <Select
              items={actions.map((a) => ({ ...a, value: a.label[lang] }))}
              onSelect={fct.onSelect}
              placeholder={w.generic.actions}
            />
            <MUIButton
              color="secondary"
              variant="contained"
              className="shrink"
              onClick={fct.onConfirm}
            >
              {w.generic.validate}
            </MUIButton>
          </Field>
        )}
      </div>
      <div className="flex row">
        <MUIButton
          color="secondary"
          variant="contained"
          onClick={fct.onAddEvent}
        >
          {`${w.generic.add} ${w.generic.an} ${w.generic.event.toLowerCase()}`}
        </MUIButton>
        <MUIButton
          color="primary"
          className="shrink is-outlined m-l-xs"
          onClick={fct.onGetInfo}
        >
          <Icon className="has-text-primary" icon="fas fa-exclamation-circle" />
        </MUIButton>
      </div>
    </div>
  );
};

export const Heading = ({ m: { w }, fct, events, event, getInfo }) => (
  <div
    className="flex row has-background-primary is-size-6 sticky top has-text-white"
    style={{ zIndex: event || getInfo ? 0 : 1 }}
  >
    <div className="p-xs flex">
      {' '}
      <Checkbox
        checked={events.length === 30}
        onChange={() => fct.ppEvents(-42)}
        className="small"
      />{' '}
    </div>
    <div className="p-xs flex grow-2 basis-0">{w.generic.title}</div>
    <div className="p-xs flex grow-2 basis-0">{w.events.dates}</div>
    <div className="p-xs flex grow-2 basis-0">{w.events.place}</div>
    <div className="p-xs flex grow-2 basis-0">{w.events.categorie}</div>
    <div className="p-xs flex grow-1 basis-0 center">{w.generic.language}</div>
    <div className="p-xs flex grow-1 basis-0 center">{w.events.publish}</div>
    <div className="p-xs flex grow-1 basis-0 center">{w.generic.action}</div>
  </div>
);

export const Footer = ({ offset, limit, data, setOffset }) => {
  const total = _getKeyValue(data, 'total') || 0;

  // the offset and setOffset variables are passed as props in the src/containers/Events.js file, and recovered from the EventContext in src/containers/events/list/context/EventContext.js file in order to fix the page number
  return (
    <div className="flex center has-background-primary-lighter p-xs">
      <div className="w-4">
        <Pagination
          activePage={offset / limit + 1}
          itemsCountPerPage={limit}
          totalItemsCount={total}
          pageRangeDisplayed={5}
          onChange={(page) => setOffset((page - 1) * limit)}
          linkClass="has-text-primary-ter has-text-weight-normal"
          activeLinkClass="has-text-primary-ter has-text-weight-medium"
        />
      </div>
    </div>
  );
};

export const Delete = ({ event, onConfirm, onCancel, m: { w } }) => (
  <div className="w-4 has-background-white rad-5">
    <div className="has-background-primary p-md has-text-white">
      {w.events.deleteConfirmation}
    </div>
    <div className="p-md">
      <div className="flex row space-between wrap">
        <Button onClick={onCancel} color="danger">
          {w.generic.cancel}
        </Button>
        <Button onClick={onConfirm}>{w.generic.validate}</Button>
      </div>
    </div>
  </div>
);

const Counter = styled.div`
  margin-left: -20px;
  margin-right: 40px;
  width: 20px;
`;

const SearchBarContainer = styled.div`
  @media (max-width: 1400px) {
    margin-right: 20px;
  }
`;
