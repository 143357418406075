import Schema from "./Schema";
import gql from "graphql-tag";

class Topics extends Schema {
  getTopicCategories = async (variables?: object) =>
    await this.query(GET_TOPICS_CATEGORIES, variables);

  getTopics = async (variables?: object) =>
    await this.query(GET_TOPICS, variables);

  createTopic = async (variables?: object) =>
    await this.mutate(CREATE_TOPIC, variables);

  setTopicTranslation = async (variables?: object) =>
    await this.mutate(SET_TOPIC_TRANSLATION, variables);

  deleteTopicTranslation = async (variables?: object) =>
    await this.mutate(DELETE_TOPIC_TRANSLATION, variables);

  updateTopic = async (variables?: object) =>
    await this.mutate(UPDATE_TOPIC, variables);

  deleteTopic = async (variables?: object) =>
    await this.mutate(DELETE_TOPIC, variables);

  createTopicAnswer = async (variables?: object) =>
    await this.mutate(CREATE_TOPIC_ANSWER, variables);

  updateTopicAnswer = async (variables?: object) =>
    await this.mutate(UPDATE_TOPIC_ANSWER, variables);

  deleteTopicAnswer = async (variables?: object) =>
    await this.mutate(DELETE_TOPIC_ANSWER, variables);

  createTopicTarget = async (variables?: object) =>
    await this.mutate(CREATE_TOPIC_TARGET, variables);

  updateTopicTarget = async (variables?: object) =>
    await this.mutate(UPDATE_TOPIC_TARGET, variables);

  deleteTopicTarget = async (variables?: object) =>
    await this.mutate(DELETE_TOPIC_TARGET, variables);

  getLinkableScenarioContents = async (variables?: object) =>
    await this.query(GET_LINKABLE_SCENARIO_CONTENTS, variables);

  createTopicLink = async (variables?: object) =>
    await this.mutate(CREATE_TOPIC_LINK, variables);

  updateTopicLink = async (variables?: object) =>
    await this.mutate(UPDATE_TOPIC_LINK, variables);

  deleteTopicLink = async (variables?: object) =>
    await this.mutate(DELETE_TOPIC_LINK, variables);

  exportTopics = async (variables?: object) =>
    await this.query(EXPORT_TOPICS, variables);

  importTopics = async (variables?: object) =>
    await this.mutate(IMPORT_TOPICS, variables);

  createTopicCategory = async (variables?: object) =>
    await this.mutate(CREATE_TOPIC_CATEGORY, variables);

  updateTopicCategory = async (variables?: object) =>
    await this.mutate(UPDATE_TOPIC_CATEGORY, variables);
}

const GET_TOPICS_CATEGORIES = gql`
  query getTopicCategories($languageCode: String, $botId: Int!) {
    getTopicCategories(language_code: $languageCode, bot_id: $botId) {
      categories {
        id
        name
        topics_count
        enabled_topics_count
      }
    }
  }
`;

const GET_TOPICS = gql`
  query getTopics(
    $languageCode: String!
    $categoryId: Int
    $name: String
    $botId: Int!
  ) {
    getTopics(
      language_code: $languageCode
      category_id: $categoryId
      name: $name
      bot_id: $botId
    ) {
      topics {
        id
        name
        description
        enabled
        is_fallback
        answers {
          id
          content
          enabled
          start_date
          end_date
          reminder_date
          reminder_emails
          disabled_date
          disabled_user {
            id
            email
          }
          place {
            id
            name
          }
        }
        targets {
          id
          group_entity_id
          intent_id
        }
        link {
          id
          type
          feature_slug
          scenario_content_id
        }
      }
    }
  }
`;

const CREATE_TOPIC = gql`
  mutation createTopic(
    $categoryId: Int!
    $botId: Int!
    $name: String!
    $description: String!
    $languageCode: String!
    $enabled: Boolean
  ) {
    createTopic(
      category_id: $categoryId
      bot_id: $botId
      name: $name
      description: $description
      language_code: $languageCode
      enabled: $enabled
    ) {
      id
      name
      description
      enabled
      is_fallback
    }
  }
`;

const SET_TOPIC_TRANSLATION = gql`
  mutation setTopicTranslation(
    $topicId: Int!
    $name: String!
    $description: String!
    $languageCode: String!
  ) {
    setTopicTranslation(
      topic_id: $topicId
      name: $name
      description: $description
      language_code: $languageCode
    ) {
      id
      name
      description
      enabled
      is_fallback
    }
  }
`;

const DELETE_TOPIC_TRANSLATION = gql`
  mutation deleteTopicTranslation($topicId: Int!, $languageCode: String!) {
    deleteTopicTranslation(topic_id: $topicId, language_code: $languageCode)
  }
`;

const UPDATE_TOPIC = gql`
  mutation updateTopic(
    $id: Int!
    $categoryId: Int
    $name: String
    $description: String
    $languageCode: String!
    $enabled: Boolean,
    $isFallback: Boolean
  ) {
    updateTopic(
      id: $id
      category_id: $categoryId
      name: $name
      description: $description
      language_code: $languageCode
      enabled: $enabled,
      is_fallback: $isFallback
    ) {
      id
      name
      description
      enabled
      is_fallback
    }
  }
`;

const DELETE_TOPIC = gql`
  mutation deleteTopic($id: Int!) {
    deleteTopic(id: $id)
  }
`;

const CREATE_TOPIC_ANSWER = gql`
  mutation createTopicAnswer(
    $topicId: Int!
    $content: String!
    $languageCode: String!
    $startDate: DateTime
    $placeId: Int
  ) {
    createTopicAnswer(
      topic_id: $topicId
      content: $content
      language_code: $languageCode
      start_date: $startDate
      place_id: $placeId
    ) {
      id
      start_date
      end_date
      reminder_date
      reminder_emails
      disabled_date
      disabled_user {
        id
        email
      }
      enabled
      content
      place {
        id
        name
      }
    }
  }
`;

const UPDATE_TOPIC_ANSWER = gql`
  mutation updateTopicAnswer(
    $id: Int!
    $content: String!
    $startDate: DateTime
    $endDate: DateTime
    $placeId: Int
    $enabled: Boolean
    $reminderDate: DateTime
    $reminderEmails: String
  ) {
    updateTopicAnswer(
      id: $id
      content: $content
      start_date: $startDate
      end_date: $endDate
      place_id: $placeId
      enabled: $enabled
      reminder_date: $reminderDate
      reminder_emails: $reminderEmails
    ) {
      id
      enabled
      start_date
      end_date
      reminder_date
      reminder_emails
      disabled_date
      disabled_user {
        id
        email
      }
      content
      place {
        id
        name
      }
    }
  }
`;

const DELETE_TOPIC_ANSWER = gql`
  mutation deleteTopicAnswer($id: Int!) {
    deleteTopicAnswer(id: $id)
  }
`;

const CREATE_TOPIC_TARGET = gql`
  mutation addIntentToTopic(
    $topicId: Int!
    $intentId: Int!
    $type: String!
    $groupEntityId: Int
  ) {
    createTopicTarget(
      topic_id: $topicId
      intent_id: $intentId
      type: $type
      group_entity_id: $groupEntityId
    ) {
      id
      intent_id
      type
      group_entity_id
    }
  }
`;

const UPDATE_TOPIC_TARGET = gql`
  mutation updateTopicTarget($id: Int!, $intentId: Int!, $type: String!) {
    updateTopicTarget(id: $id, intent_id: $intentId, type: $type) {
      id
      intent_id
      type
      group_entity_id
    }
  }
`;

const DELETE_TOPIC_TARGET = gql`
  mutation deleteTopicTarget($id: Int!) {
    deleteTopicTarget(id: $id)
  }
`;

const GET_LINKABLE_SCENARIO_CONTENTS = gql`
  query getLinkableScenarioContents($languageCode: String!, $botId: Int!) {
    getLinkableScenarioContents(language_code: $languageCode, bot_id: $botId) {
      scenarioContents {
        id
        name
      }
    }
  }
`;

const CREATE_TOPIC_LINK = gql`
  mutation createTopicLink(
    $topicId: Int!
    $scenarioContentId: String
    $featureSlug: String
    $languageCode: String!
    $type: String!
  ) {
    createTopicLink(
      topic_id: $topicId
      scenario_content_id: $scenarioContentId
      feature_slug: $featureSlug
      type: $type
      language_code: $languageCode
    ) {
      id
      scenario_content_id
      type
      feature_slug
    }
  }
`;

const UPDATE_TOPIC_LINK = gql`
  mutation updateTopicLink(
    $id: Int!
    $featureSlug: String
    $scenarioContentId: String
    $type: String!
  ) {
    updateTopicLink(
      id: $id
      feature_slug: $featureSlug
      scenario_content_id: $scenarioContentId
      type: $type
    ) {
      id
      scenario_content_id
      type
      feature_slug
    }
  }
`;

const DELETE_TOPIC_LINK = gql`
  mutation deleteTopicLink($id: Int!) {
    deleteTopicLink(id: $id)
  }
`;

const EXPORT_TOPICS = gql`
  query exportTopics($botId: Int!) {
    exportTopics(bot_id: $botId) {
      url
    }
  }
`;

const IMPORT_TOPICS = gql`
  mutation importTopics($botId: Int!, $file: Upload!) {
    importTopics(bot_id: $botId, file: $file)
  }
`;

const CREATE_TOPIC_CATEGORY = gql`
  mutation createTopicCategory(
    $name: String!
    $botId: Int!
    $languageCode: String
  ) {
    createTopicCategory(
      name: $name
      bot_id: $botId
      language_code: $languageCode
    ) {
      id
      name
      topics_count
      enabled_topics_count
    }
  }
`;

const UPDATE_TOPIC_CATEGORY = gql`
  mutation updateTopicCategory(
    $id: Int!
    $name: String!
    $languageCode: String
  ) {
    updateTopicCategory(id: $id, name: $name, language_code: $languageCode) {
      id
      name
      topics_count
      enabled_topics_count
    }
  }
`;

export default Topics;
