import React, { memo, useContext, useState } from 'react';
import styled from 'styled-components';
import { QAContext } from '../../context/QAContext';
import Select from 'react-select';
import { Categorie } from '../../../../types/qa/topics';
import { Icon } from '@ask-mona/ui';

interface Props {
  close: () => any;
  handleChangeCategory: (id: number) => any;
}

interface ButtonsProps {
  readonly background: string;
}

const ChangeCategory = memo((props: Props) => {
  const { state } = useContext(QAContext);
  const [valueSelected, setValueSelected] = useState<number | null>(null);

  return (
    <div className="w-4 has-background-white">
      <div className="p-md has-text-white has-background-primary relative">
        {state.wording.changeCategory}
        <span className="has-text-white absolute top right p-md pointer">
          <Icon icon="fas fa-times" onClick={() => props.close()} />
        </span>
      </div>
      <div className="p-md">
        {state.wording.sendQaTo}
        <div>
          <Select<Categorie>
            options={state.categories}
            getOptionLabel={(category: Categorie) => category.name}
            getOptionValue={(category: Categorie) => category.id}
            className="w-100p"
            onChange={(option) => setValueSelected(option.id)}
          />
        </div>
        <WrapperButtons>
          <Button background={'#e08585'} onClick={props.close}>
            {state.wording.cancel}
          </Button>
          <Button
            background={'#5054be'}
            onClick={() => {
              valueSelected && props.handleChangeCategory(valueSelected);
            }}
          >
            {state.wording.save}
          </Button>
        </WrapperButtons>
      </div>
    </div>
  );
});

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
`;

const Button = styled.button<ButtonsProps>`
  display: flex;
  padding: 0.25em 1em;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  border-radius: 3px;
  border: none;
  background-color: ${({ background }) => background};
  color: white;
`;

export default ChangeCategory;
