import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ChromePicker } from 'react-color';
import Select from 'react-select';
import Bot from '../../../../_api/bot';
import { _isImg, _isVid } from '../../../../_tools/is';
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';
import { Trash } from '@styled-icons/boxicons-regular/Trash';
import { RightArrow } from '@styled-icons/boxicons-solid/RightArrow';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';
import StyledImage from '../../../../components/parameters/StyledImage';
import StyledUpload from '../../../../components/parameters/StyledUpload';
import Upload from '../../../../_assets/icon/Upload';
import InputFile from '../../../../components/parameters/InputFile';
import StyledLabel from '../../../../components/parameters/StyledLabel';
import InputRadio from '../../../../components/parameters/InputRadio';
import StyledInput from '../../../../components/parameters/StyledInput';
import flagEn from '../../../../_assets/flag_gb.png';
import flagFr from '../../../../_assets/flag_fr.png';
import LanguagesDropdown from "../../../../components/LanguagesDropdown";

const Homepage = ({
  state,
  dispatch,
  bot,
  client,
  wWebchat,
  handleMedia,
  w,
  m
}) => {
  const [hideButtonsContent, setHideButtonsContent] = useState(true);
  const [switchLangTitle, setSwitchLangTitle] = useState(m.bot.default_language);
  const [switchLangContent, setSwitchLangContent] = useState(m.bot.default_language);
  const [switchLangButton, setSwitchLangButton] = useState(m.bot.default_language);

  useEffect(() => {
    const fetchScenario = async () => {
      let api = new Bot(client, bot);
      let res = await api._getModules();
      if (res.success && res.json) {
        if ('trigger' in res.json) {
          let triggers = res.json['trigger'];
          let payloads = Object.keys(triggers)
            .filter((k) => !triggers[k].tag)
            .map((k) => ({
              value: k.replace('p:', ''),
              label: triggers[k].name,
            }));
          dispatch({ type: 'setModulesScenario', payload: payloads });
        }
      }
    };
    fetchScenario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bot]);
  return (
    <Wrapper>
      <WrapperDiv mr={'1rem 0 0 0'}>
        <SubititleLight>{m.w.webchat.homepage_status_title}</SubititleLight>
        <StyledLabel radio flex widht={'20'} mr>
          <InputRadio
            type="radio"
            name="homeActive"
            value={state.homeActive}
            checked={!state.homeActive}
            onChange={() => {
              dispatch({ type: 'setHomeActive', payload: !state.homeActive });
            }}
          />
          {m.w.webchat.homepage_disabled}
        </StyledLabel>
        <StyledLabel radio flex widht={'20'}>
          <InputRadio
            type="radio"
            name="homeActive"
            value={state.homeActive}
            checked={state.homeActive}
            onChange={() => {
              dispatch({ type: 'setHomeActive', payload: !state.homeActive });
            }}
          />
          {m.w.webchat.homepage_enabled}
        </StyledLabel>
      </WrapperDiv>
      <WrapperDiv mr={'2rem'} space={'space-between'}>
        <StyledLabel width={'40'}>
          {m.w.generic.title}
          <WrapperInput>
            <StyledInput
              type="text"
              maxLength="37"
              value={
                  state.homeTitle && state.homeTitle[switchLangTitle] ? state.homeTitle[switchLangTitle] : ''
              }
              onChange={(e) => {
                  dispatch({ type: 'setHomeTitle', payload: {
                          ...state.homeTitle,
                          [switchLangTitle]: e.target.value
                      }
                  })
              }}
            />
              <span>
                <LanguagesDropdown
                    languages={m.bot_lang}
                    selectedLanguage={switchLangTitle}
                    onLanguageSelected={(language) => setSwitchLangTitle(language)}
                />
              </span>
          </WrapperInput>
        </StyledLabel>
        <StyledLabel width={'40'}>
          {m.w.generic.content}
          <WrapperInput>
            <StyledTextarea
                value={
                    state.homeContent && state.homeContent[switchLangContent] ? state.homeContent[switchLangContent] : ''
                }
                onChange={(e) => {
                    dispatch({ type: 'setHomeContent', payload: {
                            ...state.homeContent,
                            [switchLangContent]: e.target.value
                        }
                    })
                }}
            />
              <span>
                <LanguagesDropdown
                    languages={m.bot_lang}
                    selectedLanguage={switchLangContent}
                    onLanguageSelected={(language) => setSwitchLangContent(language)}
                />
              </span>
          </WrapperInput>
        </StyledLabel>
      </WrapperDiv>
      <WrapperDiv mr={'2rem'} space={'space-between'}>
        <StyledLabel width={'40'}>
          {m.w.webchat.homepage_title_font_size}
          <Slider
            trackStyle={{ backgroundColor: 'red', height: '10px' }}
            railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
            handleStyle={{
              width: '20px',
              height: '20px',
              border: '1px solid lightgray',
              boxShadow:
                'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
            }}
            min={14}
            max={24}
            defaultValue={state.homeTitleFontSize}
            value={state.homeTitleFontSize}
            onChange={(value) => {
              dispatch({ type: 'setHomeTitleFontSize', payload: value });
            }}
            handle={handle}
          />
        </StyledLabel>
        <StyledLabel width={'40'}>
          {m.w.webchat.homepage_content_font_size}
          <Slider
            trackStyle={{ backgroundColor: 'red', height: '10px' }}
            railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
            handleStyle={{
              width: '20px',
              height: '20px',
              border: '1px solid lightgray',
              boxShadow:
                'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
            }}
            min={12}
            max={20}
            defaultValue={state.homeContentFontSize}
            value={state.homeContentFontSize}
            onChange={(value) => {
              dispatch({ type: 'setHomeContentFontSize', payload: value });
            }}
            handle={handle}
          />
        </StyledLabel>
      </WrapperDiv>
      <WrapperDiv mr={'2rem'} space={'space-between'} alignItem={'flex-start'}>
        <StyledDivWrapper width={'50%'}>
          <SubititleLight center>{m.w.webchat.homepage_title_colour}</SubititleLight>
          <ChromePicker
            color={state.homeTitleColor}
            onChangeComplete={(val) => {
              dispatch({ type: 'setHomeTitleColor', payload: val.hex });
            }}
          />
        </StyledDivWrapper>
        <StyledDivWrapper width={'50%'}>
          <SubititleLight center>{m.w.webchat.homepage_background_colour}</SubititleLight>
          <ChromePicker
            color={state.homeContentBg}
            onChangeComplete={(val) => {
              dispatch({ type: 'setHomeContentBg', payload: val.hex });
            }}
          />
        </StyledDivWrapper>
      </WrapperDiv>
      <WrapperDiv mr={'2rem'} space={'space-between'} alignItem={'flex-start'}>
        <StyledDivWrapper width={'40%'}>
          <SubititleLight center>{m.w.webchat.homepage_content_colour}</SubititleLight>
          <ChromePicker
            color={state.homeContentColor}
            onChangeComplete={(val) => {
              dispatch({ type: 'setHomeContentColor', payload: val.hex });
            }}
          />
        </StyledDivWrapper>
      </WrapperDiv>
      <Wrapper>
        <SubititleLight center>{m.w.webchat.homepage_media_selection}</SubititleLight>
        <WrapperDiv mr={'2rem'} space={'space-between'}>
          {state.homeImage ? (
            <>
              <StyledDivWrapper alignItem={'flex-start'}>
                {_isImg(state.homeImage) && (
                  <StyledImage width={'150'} src={state.homeImage} alt="" />
                )}
                {_isVid(state.homeImage) && (
                  <video width={'150'} controls src={state.homeImage}>
                    <source src={state.homeImage} type={`video/webm`} />
                  </video>
                )}

                <ButtonWrapper>
                  <span>{m.w.webchat.homepage_change_media}</span>
                  <InputUpload
                    type="file"
                    onChange={(e) => {
                      handleMedia(e, 'setCustomIcon');
                    }}
                  />
                </ButtonWrapper>
              </StyledDivWrapper>
            </>
          ) : (
            <>
              <WrapperColumn>
                <StyledUpload noMargin width={'56'}>
                  <Upload />
                  <div>
                    <p>{w.download}</p>
                    <p>{w.downloadSize}</p>
                  </div>
                  <InputFile
                    type="file"
                    onChange={(e) => {
                      handleMedia(e);
                    }}
                  />
                </StyledUpload>
                <StyledText width={'55%'}>
                  {m.w.webchat.homepage_media_accepted_formats}
                </StyledText>
              </WrapperColumn>
            </>
          )}
        </WrapperDiv>
      </Wrapper>

      <SubititleLight center mr>
        {m.w.webchat.homepage_buttons_configuration}
        <span onClick={() => setHideButtonsContent(!hideButtonsContent)}>
          {hideButtonsContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
          {hideButtonsContent && (
              <LanguagesDropdown
                  languages={m.bot_lang}
                  selectedLanguage={switchLangButton}
                  onLanguageSelected={(language) => setSwitchLangButton(language)}
              />
          )}
      </SubititleLight>
      {hideButtonsContent && (
        <>


            {state.homeButtons?.map((el, i) => (
            <WrapperDiv mr={'0'} key={i}>
              <StyledLabel width={'40'}>
                {m.w.generic.title}
                <WrapperInput>
                  <StyledInput
                    type="text"
                    maxLength="37"
                    value={el.title[switchLangButton] || ''}
                    onChange={(e) => {
                      let arrButtons = [...state.homeButtons];
                      arrButtons[i] = {
                        ...arrButtons[i],
                        title: {
                          ...arrButtons[i].title,
                          [switchLangButton]: e.target.value,
                        },
                      };
                      dispatch({
                        type: 'setHomeButtons',
                        payload: arrButtons,
                      });
                    }}
                  />
                </WrapperInput>
              </StyledLabel>
              <WrapperDiv mr={'.4rem'}>
                <h4>{m.w.webchat.homepage_buttons_module_link}</h4>
                <StyledWidth>
                  <Select
                    options={state.modulesScenario}
                    isSearchable="true"
                    placeholder={el?.payload}
                    onChange={(e) => {
                      let arrButtons = [...state.homeButtons];
                      arrButtons[i] = {
                        ...arrButtons[i],
                        payload: e.value,
                      };
                      dispatch({
                        type: 'setHomeButtons',
                        payload: arrButtons,
                      });
                    }}
                  />
                </StyledWidth>
              </WrapperDiv>
              <WrapperTrash>
                <StyledTrash
                  size={24}
                  onClick={() => {
                    let arrButtons = [...state.homeButtons];
                    arrButtons.splice(i, 1);
                    dispatch({
                      type: 'setHomeButtons',
                      payload: arrButtons,
                    });
                  }}
                />
              </WrapperTrash>
            </WrapperDiv>
          ))}
          <div>
            {m.w.webchat.homepage_add_button}
            <StyledPlusCircle
              size={26}
              onClick={() => {
                dispatch({
                  type: 'setHomeButtons',
                  payload: [
                    ...state.homeButtons,
                    {
                      content_type: 'text',
                      payload: '',
                      title: '',
                    },
                  ],
                });
              }}
            />
          </div>
          <WrapperDiv
            mr={'2rem'}
            space={'space-between'}
            alignItem={'flex-start'}
          >
            <StyledDivWrapper width={'40%'}>
              <SubititleLight center>{m.w.webchat.homepage_buttons_colour}</SubititleLight>
              <ChromePicker
                color={state.homeButtonsColor}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setHomeButtonsColor', payload: val.hex });
                }}
              />
            </StyledDivWrapper>
            <StyledDivWrapper width={'40%'}>
              <SubititleLight center>{m.w.webchat.homepage_buttons_background}</SubititleLight>
              <ChromePicker
                color={state.homeButtonsBackground}
                onChangeComplete={(val) => {
                  dispatch({
                    type: 'setHomeButtonsBackground',
                    payload: val.hex,
                  });
                }}
              />
            </StyledDivWrapper>
          </WrapperDiv>
          <WrapperDiv mr={'2rem 0 0 2rem'}>
            <SubititleLight>{wWebchat.borderQr}</SubititleLight>
          </WrapperDiv>
          <WrapperDiv mr={'2rem 3rem'} width={'50%'}>
            <Slider
              trackStyle={{ backgroundColor: 'red', height: '10px' }}
              railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
              handleStyle={{
                width: '20px',
                height: '20px',
                border: '1px solid lightgray',
                boxShadow:
                  'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
              }}
              min={0}
              max={25}
              defaultValue={state.homeButtonsBorder}
              value={state.homeButtonsBorder}
              onChange={(value) => {
                dispatch({ type: 'setHomeButtonsBorder', payload: value });
              }}
              handle={handle}
            />
          </WrapperDiv>
          <WrapperDiv mr={'0 2rem'}>
            <SubititleLight>{m.w.webchat.homepage_bubble_height}</SubititleLight>
          </WrapperDiv>
          <WrapperDiv mr={'2rem 3rem'} width={'50%'}>
            <Slider
              trackStyle={{ backgroundColor: 'red', height: '10px' }}
              railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
              handleStyle={{
                width: '20px',
                height: '20px',
                border: '1px solid lightgray',
                boxShadow:
                  'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
              }}
              min={30}
              max={44}
              step={1}
              defaultValue={state.homeButtonsHeight}
              value={state.homeButtonsHeight}
              onChange={(value) => {
                dispatch({ type: 'setHomeButtonsHeight', payload: value });
              }}
              handle={handle}
            />
          </WrapperDiv>
          <WrapperDiv mr={'0 2rem'}>
            <SubititleLight>{m.w.webchat.homepage_bubble_width}</SubititleLight>
          </WrapperDiv>
          <WrapperDiv mr={'2rem 3rem'} width={'50%'}>
            <Slider
              trackStyle={{ backgroundColor: 'red', height: '10px' }}
              railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
              handleStyle={{
                width: '20px',
                height: '20px',
                border: '1px solid lightgray',
                boxShadow:
                  'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
              }}
              min={45}
              max={267}
              step={1}
              defaultValue={state.homeButtonsWidth}
              value={state.homeButtonsWidth}
              onChange={(value) => {
                dispatch({ type: 'setHomeButtonsWidth', payload: value });
              }}
              handle={handle}
            />
          </WrapperDiv>
        </>
      )}
    </Wrapper>
  );
};

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  const { Handle } = Slider;

  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} %`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WrapperDiv = styled.div`
  display: flex;
  align-items: ${({ alignItem }) => alignItem || 'flex-start'};
  margin: ${({ mr }) => (mr ? mr : '2rem 0')};
  flex-wrap: wrap;
  justify-content: ${({ space }) => space};
  width: ${({ width }) => width};
`;

const SubititleLight = styled.h3`
  font-size: 1.1.rem;
  margin-right: 2rem;
  margin-bottom: ${({ mr }) => mr && '1rem'};
  display: flex;
  align-items: center;
`;

const StyledDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: ${({ mr }) => mr};
  width: ${({ width }) => width};
`;

const StyledPlusCircle = styled(PlusCircle)`
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.6;
  }
`;

const StyledWidth = styled.div`
  width: 100%;
  margin-top: 0.2rem;
`;

const StyledTrash = styled(Trash)`
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: red;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: 150px;
  text-align: center;
  margin-top: 1rem;
  transition: 0.3s;
  cursor: pointer;
  transition: opacity 0.3s;

  > span {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.white};
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 12px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const InputUpload = styled.input`
  display: inline-block;
  position: absolute;
  z-index: 1000000;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledText = styled.p`
  font-weight: 300;
  font-size: 0.8em;
  margin-top: ${({ mt }) => mt};
  width: ${({ width }) => width};
`;

const WrapperTrash = styled.div`
  align-self: center;
`;

const StyledTextarea = styled.textarea`
  color: #2d2f7b;
  padding: 0.6rem;
  margin-top: 0.6rem;
  border: 1px solid #2d2f7b;
  &::placeholder {
    color: #2d2f7b;
    font-weight: 500;
  }
`;

const WrapperInput = styled.div`
  position: relative;
  width: auto;

  > span {
    position: absolute;
    top: 10px;
    right: 0px;
    cursor: pointer;
  }

  > input,
  textarea {
    padding-right: 50px;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
  }
`;

export default Homepage;
