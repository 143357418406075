import styled from 'styled-components';

const WrapperGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 2fr 1fr;
  justify-content: center;
  border-bottom: 1px solid #2d2f7b;
  text-align: center;
  align-items: center;
  padding: 1rem;
`;

export default WrapperGrid;
