import React from 'react';
import { Field, Input } from '@ask-mona/ui';
import {
  _defaultTranslation,
  _formatTranslation,
} from '../../../_tools/translation';

export default ({
  m: { w, lang, bot_lang },
  errors,
  event: { translations },
  fct,
}) => {
  let target = bot_lang[0] || lang;
  let tr = _formatTranslation(translations || [_defaultTranslation(target)])[
    target
  ];
  if (!tr) tr = _formatTranslation([_defaultTranslation(target)])[target];
  let title = tr.short_title.value ? tr.short_title : tr.long_title;
  return (
    <Field
      title={`${w.generic.title} *`}
      className="m-b-md"
      error={errors.title ? w.error.missingTitle : ''}
    >
      <Input
        {...title}
        maxLength={
          title.value.length > title.maxLength ? 'default' : title.maxLength
        }
        onChange={fct.setTranslation}
      />
    </Field>
  );
};
