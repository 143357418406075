import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import Loader from '../../components/Loader';
import Nodata from '../../components/Nodata';
import { Susbar } from './sidebars/Susbar';
import View from './content/View';

import LeoApi from '../../api/rest/LeoApi';

import QaContext from './context/qaContext';

const QA = (props) => {
  const {
    m: { w },
    data,
    subID,
    changeSub,
  } = props;
  const { getIntents, getIntentsV2, error, loading } = data;

  if (subID !== 2) changeSub(2);

  return (
    <div
      className="has-text-primary-ter w-100p relative space-nowrap h-100p"
      style={{ height: 'inherit', display: 'flex' }}
    >
      {loading && <Loader />}
      {error && <Nodata title={w.error.section} />}
      {(getIntents || getIntentsV2) && !loading && !error && (
        <Content {...props} />
      )}
    </div>
  );
};

const Content = (props) => {
  const {
    m: {
      user: { user },
    },
  } = props;
  const qaContext = useContext(QaContext);

  useEffect(() => {
    const getIntents = async () => {
      let api = new LeoApi(
        `${process.env.REACT_APP_MLAPI}/intents`,
        user.token
      );
      let params = {
        take: '',
        skip: '',
        title: '',
        models: '',
      };
      try {
        let res = await api.get(null, params);
        qaContext.setLeoIntents(res.data);
      } catch (err) {
        console.error(err);
      }
    };
    getIntents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentContainer
      className="has-text-primary-ter relative overflow-hidden"
      style={{ display: 'flex', height: 'inherit' }}
    >
      <Susbar {...props} />
      <View {...props} />
    </ContentContainer>
  );
};

export default QA;

const ContentContainer = styled.div`
  width: 100%;
`;
