const sections = [
  { id: 0, path: '/office/analytics', w: 'analytics' },
  { id: 1, path: '/office/general', w: 'information' },
  { id: 2, path: '/office/events', w: 'programmation' },
  { id: 3, path: '/office/scenarios', w: 'scenarios' },
  { 
    id: 4,
    path(botId) {
      return `/office/bot/${botId}/qa`;
    },
    w: 'qa',
  },
  { id: 5, path: '/office/mediations', w: 'mediation' },
  { id: 6, path: '/office/pushs', w: 'pushs' },
  { id: 7, path: '/office/inbox', w: 'inbox' },
];

export const settings = [
  {
    id: 0,
    icon: 'fas fa-clock',
    path: '/office/general/schedule',
    w: 'schedule',
  },
  {
    id: 1,
    icon: 'fas fa-money-check-alt',
    path: '/office/general/pricing',
    w: 'pricing',
  },
  {
    id: 2,
    icon: 'fas fa-map',
    path: '/office/general/direction',
    w: 'direction',
  },
];

export const customize = [
  {
    id: 0,
    icon: 'fas fa-comment-alt',
    path: '/office/customize/basic',
    w: 'customFunctions',
  },
  {
    id: 1,
    icon: 'fas fa-palette',
    path: '/office/customize/advanced',
    w: 'scenarioAdvanced',
  },
  {
    id: 2,
    icon: 'fas fa-question',
    path: '/office/customize/knowledge',
    w: 'knowledge',
  },
  {
    id: 3,
    icon: 'fas fa-cogs',
    path: '/office/customize/parameters',
    w: 'parameters',
  },
  { id: 4, icon: 'fas fa-list', path: '/office/customize/menu', w: 'menu' },
];

export const scenarios = [
  {
    id: 0,
    icon: 'fas fa-comment-alt',
    path: '/office/scenarios',
    w: 'customFunctions',
  },
  {
    id: 1,
    icon: 'fas fa-palette',
    path: '/office/scenarios/personal-scenarios',
    w: 'scenarioAdvanced',
  },
];

export const qa = [
  {
    id: 0,
    icon: 'fas fa-question',
    path: '/office/qa/qa',
    w: 'knowledge',
  },
];

export const inbox = [
  {
    id: 0,
    icon: 'fab fa-facebook-messenger',
    path: '/office/inbox/message',
    w: 'inboxMessage',
  },
  {
    id: 1,
    icon: 'fas fa-archive',
    path: '/office/inbox/archived',
    w: 'inboxArchive',
  },
  {
    id: 2,
    icon: 'fab fa-weixin',
    path: '/office/inbox/webchat',
    w: 'wcHistory',
  },
  {
    id: 3,
    icon: 'fas fa-envelope-open-text',
    path: '/office/inbox/mail',
    w: 'wcMail',
  },
];

export const event = [
  { id: 0, icon: 'fas fa-info', w: 'eventInformation' },
  { id: 1, icon: 'fas fa-clock', w: 'eventSchedule' },
  { id: 2, icon: 'fas fa-list-ul', w: 'eventDescription' },
];

export const account = [
  { id: 0, icon: 'fas fa-user', w: 'accountUser' },
  { id: 1, icon: 'fas fa-users-cog', w: 'accountTeam' },
];

export const mediation = [
  { id: 0, icon: 'fas fa-images', w: 'medArtworks' },
  { id: 1, icon: 'fas fa-film', w: 'medScenario' },
];

export const artwork = [
  { id: 0, icon: 'fas fa-info', w: 'artInformation' },
  { id: 1, icon: 'fas fa-archive', w: 'artContent' },
  { id: 2, icon: 'fas fa-film', w: 'artTrigger' },
];

export const checklist = [
  { id: 0, icon: 'fas fa-tasks', w: 'featuresAnalytics' },
];

const subSections = [[], settings, [], [], [], [], [], inbox];

export default (features, w, orgID, permissions, defaultLanguage, botId) => {
  var base = [[0], [0, 0, 0], [0], [0], [0, 0, 0, 0, 0], [0], [0], [0, 0, 0]];
  var languages = [];
  let generatedMenu = [];

  features.forEach((v) => {
    if (
      v.required_permission === null ||
      permissions === 1 ||
      (permissions !== 1 && v.required_permission === 2)
    ) {
      switch (v.slug) {
        case 'openings':
          base[1][0] = 1;
          break;
        case 'price':
          base[1][1] = 1;
          break;
        case 'intinerary':
          base[1][2] = 1;
          break;
        case 'prog':
        case 'prog(around)':
        case 'prog(deep)':
          base[2][0] = 1;
          break;
        case 'scenario':
        case 'advanced_scenario':
        case 'nlp':
          base[3][3] = 1;
          if (v.slug === 'scenario') {
            base[3][0] = 1;
            base[3][4] = 1;
          }
          if (v.slug === 'advanced_scenario')
            if (orgID === 1 || orgID === 51 || orgID === 23 || orgID === 5)
              base[3][1] = 1;
          if (v.slug === 'nlp') {
            base[4][0] = 1;
          }
          break;
        case 'mediation':
          if (orgID === 1) base[5][0] = 1;
          break;
        case 'push':
          if (orgID === 1) base[6][0] = 1;
          break;
        case 'messaging':
          base[7][0] = 1;
          base[7][1] = 1;
          break;
        case 'webchat_messaging':
          base[7][3] = 1;
          break;
        case 'webchat_history':
          base[7][2] = 1;
          break;
        case 'affluence':
        case 'disabled':
        case 'enhanced_tth':
          break;
        default:
          break;
      }
      if(v.type==="language") {
        languages.push(v.slug);
      }
    }
  });

  languages = languages.sort((lang) => {
    if (lang === defaultLanguage) return -1;
    else return 1;
  });

  base.forEach((b, i) => {
    let hasValue = false;
    for (let i in b) {
      if (b[i]) hasValue = true;
    }
    if (hasValue) {
      generatedMenu.push({
        ...sections[i],
        ...(typeof sections[i].path === "function" && {
          path: sections[i].path(botId),
        }),
        menu: _generateSub(b, subSections[i], w),
        title: sections[i].w,
      });
    }
  });

  if (permissions === 1)
    generatedMenu.push({
      id: 8,
      menu: [],
      path: '/office/parameters',
      title: 'settings',
      w: 'parameters',
    });

  generatedMenu.unshift({
    id: 9,
    menu: [],
    path: '/office/analytics',
    title: 'analytics',
    w: 'analytics',
  });

  return { menu: generatedMenu, bot_lang: languages };
};

const _generateSub = (perm, subs, w) => {
  let menu = [];
  perm.forEach((p, i) => {
    if (p && subs[i])
      menu.push({ ...subs[i], title: w.subsections[subs[i].w] });
  });
  return menu;
};

export const _sendFeedback = (type, item, delay = 3000) => {
  let el = document.createElement('div');
  el.className = `has-background-${type} p-xs has-text-white rad-5 m-xs animation-fadeInFast ${
    item === 'loader' ? 'loader' : ''
  } zTop`;
  if (item !== 'loader') el.innerHTML += item;
  let targetEl = document.getElementById('feedback');
  targetEl.appendChild(el);
  setTimeout(() => {
    targetEl.removeChild(el);
  }, delay);
};
