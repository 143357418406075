import React from 'react';
import { Checkbox } from '@ask-mona/ui';

function ScenarioChatbot(props) {
  return (
    <React.Fragment>
      <div className="m-md">
        <Checkbox label={`Cité de l'archi (moyen ${props.w.generic.museum})`} />
      </div>
      <div className="m-md">
        <Checkbox label={`Fondation Louis Vuitton (grand ${props.w.generic.museum})`} />
      </div>
      <div className="m-md">
        <Checkbox label={`Azay-le-rideau (${props.w.generic.monument} à faible programmation)`} />
      </div>
      <div className="m-md">
        <Checkbox label={`Panthéon (${props.w.generic.monument} à forte programmation)`} />
      </div>
      <div className="m-md">
        <Checkbox label="Théâtre du châtelet (théâtres)" />
      </div>
      <div className="m-md">
        <Checkbox label="Centre val de Loire (Mondrian)" />
      </div>
    </React.Fragment>
  );
}

export default ScenarioChatbot;
