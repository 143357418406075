import React from 'react';
import styled from 'styled-components';
import { Cross } from '@styled-icons/entypo/Cross';

const PreviewMail = ({ color }) => (
  <Mailbox>
    <Container>
      <Header color={color}>
        Contact
        <StyledCross size={16} color="#111" />
      </Header>
      <Mail>
        <Title color={color}>Renseigner votre adresse mail: </Title>
        <Input color={color} />
        <Title color={color}>Saisir votre message</Title>
        <Input color={color} />
        <StyledButton>Envoyer le message</StyledButton>
      </Mail>
    </Container>
  </Mailbox>
);

const Mailbox = styled.div`
  background: white;
  width: stretch;
  z-index: 2;
  margin: 0.4rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px #f1eeee;
  position: relative;
`;

const Container = styled.div`
  margin: auto;
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  height: 80%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
  justify-content: center;
  font-size: 1.2em;
  margin-bottom: 1rem;
  font-weight: 600;
  color: ${({ color }) => color || '#111'};
`;

const Mail = styled.div`
  position: relative;
  padding: 0.3rem;
  background: white;
`;

const Title = styled.div`
  color: ${({ color }) => color || '#111'};
  margin: 1rem 0 0.4rem 0;
  font-weight: 200;
`;

const StyledButton = styled.button`
  color: ${({ color }) => color || '#111'};
  border-radius: 5px;
  padding: 0.6rem;
  width: 100%;
  padding: 1rem;
  border: none;
  margin-top: 0.4rem;
  cursor: pointer;
`;

const Input = styled.input`
  border: 1px solid #eee;
  cursor: text;
  font-size: inherit;
  max-height: 150px;
  resize: none;
  outline: none;
  width: stretch;
  padding: 0.3rem;
  margin-bottom: 0.3rem;
  height: 30px;
  border-radius: 5px;
  color: ${({ color }) => color || '#111'};
`;

const StyledCross = styled(Cross)`
  position: absolute;
  right: 4px;
  top: 6px;
`;

export default PreviewMail;
