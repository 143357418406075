import React, { useState } from 'react';
import styled from 'styled-components';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import ArrowRightSharpIcon from '@material-ui/icons/ArrowRightSharp';

import { EditCategoriePopover } from './popovers/EditCategoriePopover';
import { EditSubModulePopover } from './popovers/EditSubModulePopover';
import { AddSubModulePopover } from './popovers/AddSubModulePopover';
import { AddCategoriePopover } from './popovers/AddCategoriePopover';
import { AddResultPopover } from './popovers/AddResultPopover';
import { Icon } from '@ask-mona/ui';

const PersonalScenariosSubsbar = (props) => {
  const {
    dispatch,
    focus,
    subfocus,
    m: { w },
  } = props;

  const [active, setActive] = useState(true);

  const isClose = 'trigger' in focus && typeof focus.trigger == 'object';
  let [items, folders] = _formatSubs(props, isClose);

  if (props.subforce && props.subforce !== 'leave')
    items = items.filter((i) => i.key.indexOf(props.subforce) !== -1);

  return (
    <RootContainer
      className="inline-block overflow-auto w-20p align-top space-initial"
      style={{ height: 'inherit' }}
    >
      <ButtonsContainer>
        <ButtonsTopContainer>
          <AddCategoriePopover
            {...props}
            folders={folders}
            isClose={isClose}
            items={items}
          />
          <AddSubModulePopover
            {...props}
            folders={folders}
            isClose={isClose}
            items={items}
          />
        </ButtonsTopContainer>
        {focus.dali && (
          <AddResultPopover
            {...props}
            folders={folders}
            isClose={isClose}
            items={items}
          />
        )}
      </ButtonsContainer>

      <FlexColumn>
        <FolderContainer
          onClick={() => {
            dispatch({ type: 'setFocus', payload: focus });
            setActive(!active);
          }}
        >
          <IntroductionContainer>
            <IntroductionText isFocused={focus && !subfocus}>
              {'Introduction'}
            </IntroductionText>
            {focus && !subfocus && (
              <Icon
                icon="fas fa-chevron-right"
                className="has-text-secondary"
              />
            )}
          </IntroductionContainer>
        </FolderContainer>
        <FolderContainer>{w.scenario.nonClassifiedModules}</FolderContainer>
      </FlexColumn>
      {items}
    </RootContainer>
  );
};

export default PersonalScenariosSubsbar;

const SubModule = (props) => {
  const {
    m: {
      user: { organization },
    },
    subfocus,
    dispatch,
    sub,
  } = props;

  let isFocused = subfocus ? subfocus.id === sub.id : false;
  if (sub.donotshow) return null;

  return (
    <SubModuleLinkContainer>
      <SubModuleContainer
        isFocused={isFocused}
        onClick={() => {
          dispatch({ type: 'setSubFocus', payload: sub });
        }}
      >
        <span> {sub.name} </span>
        {sub.key !== 'first' && organization.id === 1 && (
          <EditSubModulePopover {...props} isFocused={isFocused} />
        )}
      </SubModuleContainer>
      {isFocused && (
        <Icon icon="fas fa-chevron-right" className="has-text-secondary" />
      )}
    </SubModuleLinkContainer>
  );
};

const Folder = (props) => {
  const { stack, title } = props;
  const [active, setActive] = useState(true);

  return (
    <div>
      {title && (
        <FlexColumn>
          <FolderContainer>
            <React.Fragment>
              <div
                onClick={() => {
                  setActive(!active);
                }}
              >
                {props.subforce
                  ? title.replace(`${props.subforce}/`, '')
                  : title}
              </div>
              <EditCategoriePopover {...props} />
              <ArrowIcon
                onClick={() => {
                  setActive(!active);
                }}
              >
                {active ? (
                  <ArrowDropDownSharpIcon style={{ fontSize: 30 }} />
                ) : (
                  <ArrowRightSharpIcon style={{ fontSize: 30 }} />
                )}
              </ArrowIcon>
            </React.Fragment>
          </FolderContainer>
        </FlexColumn>
      )}
      {active && (
        <div className="animation-slideDown overflow-hidden">{stack}</div>
      )}
    </div>
  );
};

const _formatSubs = (props, isClose = true) => {
  const { focus } = props;
  let subs = [];
  let items = [];
  let folders = [];

  if (isClose) {
    const { trigger } = focus;
    let keys = Object.keys(trigger);
    keys.forEach((k) => {
      subs.push({ key: k, ...trigger[k] });
    });
  } else {
    let reg = new RegExp(`^${focus.id}/`);
    const { subModules } = props;
    subs = subModules.filter((m) => reg.test(m.tag));
  }

  subs = subs.filter((m) => !m.donotshow);

  subs.sort((a, b) => {
    if (a.tag < b.tag) return -1;
    if (a.tag > b.tag) return 1;
    else return 0;
  });

  let fold = subs[0] ? subs[0].tag : '';
  let stack = [];
  if ('fallback' in focus && !subs.find((el) => el.key === 'fallback')) {
    subs.push({
      key: 'fallback',
      id: 'fallback',
      name: 'Editer le fallback',
      jump: focus.id,
      content: focus.fallback?.content,
    });
  }

  subs.forEach((m, i) => {
    stack.push(<SubModule key={m.id} {...props} sub={m} isClose={isClose} />);
    let next = subs[i + 1];
    if (!next || (fold || '') !== (next.tag || '')) {
      let tag = m.tag || '';
      if (!isClose) tag = tag ? tag.replace(/^.*\//i, '') : '';
      let title = tag;
      items.push(<Folder key={title} {...props} title={title} stack={stack} />);
      tag && folders.push({ id: tag, value: tag, key: tag });
      if (next) fold = next.tag;
      stack = [];
    }
  });

  return [items, folders, subs];
};

const RootContainer = styled.div`
  border-right: 1px solid #ececec;
  margin-bottom: 20px;
`;

const SubModuleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 300;
  color: ${(p) =>
    p.isFocused ? p.theme.colors.secondary : p.theme.colors.primary};
  text-decoration: ${(p) =>
    p.isFocused ? `underline ${p.theme.colors.secondary}` : 'none'};
`;

const SubModuleLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-bottom: 15px;
  margin-left: 20px;
  cursor: pointer;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FolderContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.primary};
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  width: 100%;
`;

const IntroductionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  height: 20px;
`;

const IntroductionText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) =>
    p.isFocused ? p.theme.colors.secondary : p.theme.colors.primary};
  text-decoration: ${(p) =>
    p.isFocused ? `underline ${p.theme.colors.secondary}` : 'none'};
  width: 90%;
  padding-left: ${(p) => (p.isFocused ? '11px' : '7px')};
`;

const ArrowIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonsTopContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px 0px 0px;
  width: 100%;
`;
