export default {
  nameSub: ['Nom du sous-lieu', 'Name of the sub-location'],
  addPlace: ["Ajout d'un lieu", 'Add a location'],
  createPlace: ['Créer un lieu ', 'Create a place'],
  searchPlace: ['Rechercher un lieu:', 'Search for a place:'],
  name: ['Nom du lieu *', "Name of the place *"],
  address: ['Adresse complète du lieu *', 'Complete address of the place *'],
  fullAddressPlaceholder: ['Adresse complète', 'omplete address of the place'],
  namePlaceholder: ['Nom du lieu', "Name of the place"],
  shortAddress: ['Addresse courte du lieu', 'Short address of the place'],
  postcode: ['Code postal', 'Postal code'],
  isSubPlace: ["Ce lieu est-il dépendant d'un autre ?", 'Is this a subplace?'],
  editPlace: ['Éditer une place', 'Edit a place'],
  pronoun: ['Déterminant du lieu', "Location determiner"],
};
