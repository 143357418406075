import React from 'react';
import { Select } from '@ask-mona/ui';
import CheckboxBool from './featuresPanel/CheckboxBool';
import Loader from '../../../components/Loader';

export const resp = [
  { fr: 'Sur messenger via backoffice', en: 'sur messenger via backoffice' },
  { fr: 'Par mail', en: 'via email' },
];

export const hour = [
  { fr: 'Basique', en: 'Basic' },
  { fr: 'En fonction des événements', en: 'According to the events' },
  {
    fr: 'En fonction de certains événements',
    en: 'According to certain events',
  },
];

function SpecificityScenario(props) {
  console.log(props);
  const {
    state: { idFeatures, tth, fct },
  } = props;

  const allResp = resp.map((b) => {
    return { value: b[props.lang] };
  });
  const allHour = hour.map((b) => {
    return { value: b[props.lang] };
  });

  if (!idFeatures) return <Loader className="has-text-primary" />;
  return (
    <React.Fragment>
      <div className="w-50">
        <div className="m-md">
          <CheckboxBool
            label="Etapes avant proposition de parler à un humain"
            bool={tth.bool}
            fct={fct}
            array={[14]}
          />
        </div>
        <div className="m-xs">
          <p className="has-text-primary-ter has-text-weight-semibold m-b-md">
            Outil de réponse pour parler à un humain
          </p>
          <Select
            placeholder={resp[0][props.lang]}
            items={allResp}
            noSearch={true}
          />
        </div>
        <div className="m-xs">
          <p className="has-text-primary-ter has-text-weight-semibold m-b-md">
            Fonctionalité horaires
          </p>
          <Select
            placeholder={hour[0][props.lang]}
            items={allHour}
            noSearch={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default SpecificityScenario;
