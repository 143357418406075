import React from 'react';
import { Button } from '@ask-mona/ui';
import FlagIcon from '../../../components/FlagIcon';
import languageTranslations from "../../../translations/languages";

const LangSwitcher = (props) => {
  const {
    m: { bot_lang },
    lang,
    dispatch,
  } = props;
  return (
    <React.Fragment>
      {bot_lang.map((l)=> {
        const formattedLang = l === "fr" ? 'default' : l;
        return (
            <Button
                className={`m-r-sm is-outlined ${
                    lang === formattedLang ? '' : 'is-borderless'
                } shrink`}
                onClick={() =>
                    dispatch({type: 'setLang', payload: {lang: formattedLang }})
                }
            >
              <FlagIcon style={{height: '40px', width: '40px'}} code={languageTranslations.relatedCountry[l]} />
            </Button>
        )
      })}
    </React.Fragment>
  );
};

export { LangSwitcher };
