import React from 'react';
import Case from '../../components/singleton/Case';
import {
  _formatTranslation,
  _defaultTranslation,
} from '../../_tools/translation';
import Generic from '../../components/scenario/components/Generic';
import ActText from '../../components/scenario/components/ActText';
import Bubble from '../../components/scenario/components/Bubble';

export default (props) => {
  const {
    m: { w, lang, bot },
    subID,
    event,
  } = props;
  const {
    translations,
    place,
    main_image,
    date_start,
    price,
    opening_hours,
    free,
  } = event;

  let tr = _formatTranslation(translations || [_defaultTranslation(bot.default_language)]);

  const defaultTranslation = tr[bot.default_language] || tr[lang] || Object.values(tr)[0];

  let title = defaultTranslation
    ? defaultTranslation.short_title.value
      ? defaultTranslation.short_title.value
      : defaultTranslation.long_title.value
    : '';
  let url = defaultTranslation ? defaultTranslation.url.value : '';
  let name = (place || {}).name;
  let address = (place || {}).address || (place || {}).short_address;
  let date = date_start || opening_hours ? w.generic.open : '';
  let pr = free ? w.generic.free : (price || '0') + '€';

  let subtitle = [
    `${name ? name : ''}`,
    `${address ? `📍 ${address}` : ''}`,
    `${date ? `✅ ${date}` : ''}`,
    `${pr ? `💰 ${pr}` : ''}`,
    `${url ? url : ''}`,
  ]
    .filter((o) => o)
    .join('\n');

  const conditionTellMeMore =
      defaultTranslation && (defaultTranslation.description.value || defaultTranslation.anecdote.value);

  const conditionTellMeInfo =
      defaultTranslation &&
      (defaultTranslation.date.value ||
      defaultTranslation.price.value ||
      defaultTranslation.practical_infos.value ||
      defaultTranslation.more_infos.value ||
      defaultTranslation.url.value ||
      defaultTranslation.url_ticketing.value);

  const buttonTellMeMore = {
    type: 'web_url',
    url: '',
    payload: '',
    title: w.event.tellMeMore,
  };
  const buttonTellMeInfo = {
    type: 'web_url',
    url: '',
    payload: '',
    title: w.event.tellMeInfo,
  };
  const buttonTellMeDirection = {
    type: 'web_url',
    url: '',
    payload: '',
    title: w.event.tellMeDirection,
  };

  return (
    <div
      className={`flex ${
        subID === 2 ? 'w-40p' : 'w-50p'
      } center items-center border-l-white-ter has-text-primary-ter`}
    >
      <Case translation={defaultTranslation}>
        <div>
          <Bubble>
            <Generic
              fill="true"
              disabled="true"
              full="true"
              focus={{ key: 'Something' }}
              {...props}
              element={{
                attachment: {
                  type: 'template',
                  payload: {
                    template_type: 'generic',
                    elements: [
                      {
                        title: title,
                        image_url: main_image || bot.default_image || '',
                        subtitle: subtitle || '',
                        default_action: {
                          type: 'web_url',
                          url: '',
                        },
                        buttons: [
                          buttonTellMeMore,
                          buttonTellMeInfo,
                          buttonTellMeDirection,
                        ],
                      },
                    ],
                  },
                },
              }}
            />
          </Bubble>
          {tr[lang] && conditionTellMeMore && (
            <Bubble is_user="true">
              <ActText str={w.event.tellMeMore} is_user="true" />
            </Bubble>
          )}
          {tr[lang] && tr[lang].description.value && (
            <Bubble>
              <ActText str={tr[lang].description.value} />
            </Bubble>
          )}
          {tr[lang] && tr[lang].anecdote.value && (
            <Bubble>
              <ActText str={tr[lang].anecdote.value} />
            </Bubble>
          )}

          {tr[lang] && conditionTellMeInfo && (
            <Bubble is_user="true">
              <ActText str={w.event.tellMeInfo} is_user="true" />
            </Bubble>
          )}
          {tr[lang] && tr[lang].date.value && (
            <Bubble>
              <ActText str={tr[lang].date.value} />
            </Bubble>
          )}
          {tr[lang] && tr[lang].price.value && (
            <Bubble>
              <ActText str={tr[lang].price.value} />
            </Bubble>
          )}
          {tr[lang] && tr[lang].practical_infos.value && (
            <Bubble>
              <ActText str={tr[lang].practical_infos.value} />
            </Bubble>
          )}
          {tr[lang] && tr[lang].more_infos.value && (
            <Bubble>
              <ActText str={tr[lang].more_infos.value} />
            </Bubble>
          )}
          {tr[lang] && tr[lang].url.value && (
            <Bubble>
              <ActText str={tr[lang].url.value} />
            </Bubble>
          )}
          {tr[lang] && tr[lang].url_ticketing.value && (
            <Bubble>
              <ActText str={tr[lang].url_ticketing.value} />
            </Bubble>
          )}
        </div>
      </Case>
    </div>
  );
};
