import React, { useState } from 'react';
import { Icon, Button, Checkbox } from '@ask-mona/ui';
import moment from 'moment';

export default ({ m: { w }, filters, setFilters, focus, setFocus, logs }) => {
  const [filter, setFilter] = useState(false);
  return (
    <div className="inline-block w-30p h-100 border-r-primary-lighter is-size-7 has-text-weight-medium overflow-scroll">
      <div className="sticky top border-b-primary-lighter has-background-white">
        <div className="p-md flex row space-between items-center">
          {`${w.inbox.treat} (${logs.length})`}
          <Button
            className="is-outlined is-borderless"
            onClick={() => setFilter(!filter)}
          >
            {w.inbox.filter}
            <Icon icon="fas fa-caret-down" />
          </Button>
        </div>
        {filter && (
          <div className="has-background-primary-lighter p-md animation-slideDown overflow-hidden">
            <Checkbox
              onChange={() => setFilters({ ...filters, read: !filters.read })}
              className="is-size-7 has-text-weight-normal p-b-xs"
              label={w.inbox.msgUnread}
            />
            <Checkbox
              onChange={() => setFilters({ ...filters, wait: !filters.wait })}
              className="is-size-7 has-text-weight-normal p-b-xs"
              label={w.inbox.msgWaiting}
            />
            <Checkbox
              onChange={() =>
                setFilters({ ...filters, disabled: !filters.disabled })
              }
              className="is-size-7 has-text-weight-normal p-b-xs"
              label={w.inbox.msgDesactivated}
            />
          </div>
        )}
      </div>
      <div>
        {logs.map((l) => (
          <Logs
            key={l.id}
            {...l}
            w={w}
            focus={() => setFocus(l)}
            focused={l.id === (focus || {}).id}
          />
        ))}
      </div>
    </div>
  );
};

const Logs = ({ w, name, bot_disabled, message, read, focus, focused }) => {
  let last = message[message.length - 1];
  let date = parseInt(last.date, 10);
  moment.locale(localStorage.getItem("languageCode"));
  const momentDate = moment(date);
  date = `${momentDate.format("Do MMMM YYYY")} ${w.generic.at_time} ${momentDate.format("HH:mm")}`;
  return (
    <div
      className={`p-md border-b-primary-lighter cursor pointer ${
        focused ? 'has-background-primary-ter has-text-white' : ''
      }`}
      onClick={focus}
    >
      <div className="flex row space-between wrap">
        {name}
        <div className="flex has-text-weight-normal">
          {last.is_user && (
            <div className="is-size-7 has-text-white inline-block rad-5 has-background-info p-xs m-r-xs">
              {w.inbox.waiting}
            </div>
          )}
          {bot_disabled && (
            <div className="is-size-7 has-text-white inline-block rad-5 has-background-danger p-xs">
              {w.inbox.disabled}
            </div>
          )}
        </div>
      </div>
      <div className="has-text-weight-normal">
        {last.content.replace(/\\n/g, '\n')}
      </div>
      <div className="has-text-weight-normal">{`${w.inbox.send} ${date}`}</div>
    </div>
  );
};
