import React from 'react';
import Mark, { _formatMark } from '../../components/Mark';
import Title from './information/Title';
import Image from './information/Image';

const Information = (props) => {
  const {
    m: { w, bot },
    langs,
    artwork,
    match,
    dispatch,
  } = props;
  let medId = match.params.medId;
  return (
    <div className="p-md has-text-primary-ter w-40p overflow-auto">
      <h3 className="p-b-xs m-b-xs border-b-white-ter">
        {w.subsections.artInformation}
      </h3>
      {langs.map((lang) => {
        return <Title key={lang} code={lang} {...props} />;
      })}
      {artwork.id && <Image artwork={artwork} dispatch={dispatch} w={w} />}
      <Mark
        selected={_formatMark(artwork.authors)}
        variables={{ bot_id: bot.id }}
        name="authors"
        query="GET_AUTHORS"
        mutation={{
          create: 'CREATE_AUTHOR',
          set: 'SET_AUTHOR_TRANSLATION',
          variables: { mediation_id: Number(medId) },
        }}
        title={w.artwork.author}
        onSelect={(item, name) =>
          dispatch({ type: 'setMarks', payload: { item, name } })
        }
        {...props}
      />
      <Mark
        selected={_formatMark(artwork.tags)}
        variables={{ mediation_id: Number(medId) }}
        name="getArtworkTags"
        query="GET_ARTWORK_TAGS"
        mutation={{
          create: 'CREATE_ARTWORK_TAG',
          variables: { mediation_id: Number(medId) },
        }}
        title={w.artwork.tag}
        onSelect={(item, name) =>
          dispatch({ type: 'setMarks', payload: { item, name } })
        }
        {...props}
      />
    </div>
  );
};

export default Information;
