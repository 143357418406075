export default {
  schedule: [
    ` Vous pouvez ajouter directement les horaires d’ouverture de votre institution en cliquant sur le calendrier. Si les horaires évoluent en fonction des jours ou périodes de l’année, rendez-vous dans l’option avancé en cliquant sur “Mode avancé”.\n\n
    Après avoir définis vos horaires d’ouverture, n’oubliez pas d’ajouter d’éventuels jours ou périodes de fermeture exceptionnelle en cliquant sur “Période de fermeture”.\n\n
    Si besoin n’hésitez-pas à nous contacter par chat en cliquant sur “Posez-nous vos questions”.`,

    `You can add directly the opening hours of your institution by clicking on the calendar. If the schedules change according to the days or periods of the year, go to the advanced option by clicking on "Advanced mode".
    After defining your opening hours. Don't forget to add possible days or periods of exceptional closing by clicking on "Closing period".\n\n
    If needed, don't hesitate to contact us using the chat by clicking on "Ask us your questions".`
  ],
  default: [
    `Si besoin n’hésitez-pas à nous contacter par chat en cliquant sur “Posez-nous vos questions”.`,
    `If you need to contact us by chat by clicking on "Ask us your questions".`,
  ],
  pricing: [
    `Vous pouvez ajouter ou modifier vos tarifs tel qu’ils seront affichés dans le chatbot. Dans cette perspective, vous pouvez reproduire la structure de votre grille tarifaire en organisant les tarifs par catégories ou sous-catégories.\n\nLa catégorie correspond aux différents types de billets proposés par votre institution\nEx : exposition permanente, exposition temporaire, billets combinés, spectacle etc\nChaque catégorie possède ensuite différents tarifs adaptés à la diversité de ses publics.\n\nNous vous recommandons d’ajouter dans un premier temps les différentes catégories (et sous-catégories si besoin), puis de lier des tarifs à ces dernières.\n➡️Exemple : Votre grille tarifaire contient des tarifs qui s’appliquent spécifiquement aux visiteurs individuels qui visitent les collections permanentes et d’autres tarifs pour les visiteurs individuels qui visitent les expositions temporaires. Vous pouvez commencer par ajouter une catégorie “Visiteurs individuels” dans laquelle vous allez ajouter une sous-catégorie “Collections permanentes” et “Expositions temporaires”. Vous ajouter ensuite des tarifs liés à ces catégories.\n\nVous pouvez ajouter vos tarifs en les organisant par catégories ou sous-catégories définies au préalable. Vous pouvez également commencer par ajouter directement vos tarifs si votre grille tarifaire est plus simple et ne nécessite pas de catégories et sous-catégories.\n➡️Exemple : Pour les expositions temporaires, vous avez un tarif plein et un tarif réduit. Vous pouvez ajouter dans la catégorie ou sous-catégorie “Expositions temporaires” deux tarifs : “Tarif plein” et “Tarif réduit” en définissant leur valeur nominale et leurs bénéficiaires.`,

    `You can add or modify your rates as they will be displayed in the chatbot. In this perspective you can reproduce the structure of your price list by organizing the prices by categories or sub-categories.\n\n
    The category corresponds to the different types of tickets offered by your institution\n
    Ex: permanent exhibition, temporary exhibition, combined tickets, show etc\n
    Each category then has different rates adapted to the diversity of its audiences.\n\n
    We recommend that you first add the different categories (and sub-categories if necessary) and then link rates to them.\n
    ➡️Example: Your price list contains rates that apply specifically to individual visitors who visit the permanent collections and other rates for individual visitors who visit temporary exhibitions. You can start by adding a category "Individual Visitors" in which you will add a sub-category "Permanent Collections" and "Temporary Exhibitions". You can then add rates related to these categories.\n\n
    You can add your rates by organizing them by predefined categories or subcategories. You can also start by adding your rates directly if your rate schedule is simpler and does not require categories and sub-categories.\n
    ➡️Example: For temporary exhibitions. You have a full rate and a reduced rate. You can add in the category or subcategory "Temporary exhibitions" two rates: "Full rate" and "Reduced rate" by defining their nominal value and beneficiaries.`,
  ],
  direction: [
    `Vous pouvez définir ici une ou plusieurs adresses correspondant à votre établissement et à ses différentes entités (café, restaurant, bibliothèque, boutique…).\n\nVous pouvez ensuite décider d’activer l’itinéraire pour une ou plusieurs de ses adresses. Lorsque vous avez activez l’itinéraire pour une adresse, le chatbot propose aux utilisateurs un itinéraire vers l’adresse en question dans la fonctionnalité “Itinéraire”.\n\nNous vous conseillons d’activer l’itinéraire dès qu”il existe une adresse différente des autres. Par exemple, s’il existe plusieurs entrées pour accéder à l’établissement culturel ou bien que le restaurant n’est pas exactement à la même adresse que le musée.\n\nSi besoin n’hésitez-pas à nous contacter par chat en cliquant sur “Posez-nous vos questions”.`,
    `You can define here one or more addresses corresponding to your establishment and its different services (café, restaurant, library, store...) You can then decide to activate the itinerary feature for one or more of these addresses. When you have activated the itinerary for an address, the chatbot offers to the users an itinerary to the address in question in the "Itinerary" feature. We advise you to activate the route as soon as there is a different address. For example, if there are several entrances to the cultural institution or if the restaurant is not exactly at the same address as the museum. If you have any questions, don't hesitate to contact us via chat by clicking on "Ask us your questions".`,
  ],
  events: [
    `Vous trouverez ici tous les événements proposés par le chatbot à ses utilisateurs.\n\n
    Pour ajouter un nouvel événement, cliquez sur “ajouter un événement” et laissez-vous guider à travers les différentes catégories à renseigner.\n\n
    Si besoin n’hésitez-pas à nous contacter par chat en cliquant sur “Posez-nous vos questions”.`,

    `You will find here all the events proposed by the chatbot to its users.\n\n
    To add a new event, click on "add an event" and let yourself be guided through the different categories to fill in.\n\n
    If you need to contact us by chat by clicking on "Ask us your questions".`
  ],
  botSettings: [
    `Vous pouvez modifier ici le nom du chatbot ainsi que l’image par défaut qui est ajoutée aux événements qui n’ont pas d’image principale.\n\n
    Si besoin n’hésitez-pas à nous contacter par chat en cliquant sur “Posez-nous vos questions”.`,

    `Here you can change the name of the chatbot as well as the default image that is added to events that do not have a main image.\n\n
    If you need to contact us by chat by clicking on "Ask us your questions".`
  ],
  inbox: [
    `Vous pouvez répondre ici aux messages du chatbot restés sans réponse ou aux personnes qui ont activé la fonctionnalité parler à un humain. Vos réponses seront envoyées directement dans Messenger à l’utilisateur.\n\n
    Si besoin n’hésitez-pas à nous contacter par chat en cliquant sur “Posez-nous vos questions”.`,

    `You can reply here to unanswered chatbot messages or to people who have enabled the talk to a human feature. Your responses will be sent directly to the user in Messenger.\n\n
    If you need to contact us by chat by clicking on "Ask us your questions".`
  ],
  push: [
    `Vous trouverez ici tous les push que vous avez créé.\n\n
    Pour ajouter un nouveau push cliquez sur “Ajouter un push” et laissez-vous guider à travers les différentes catégories à renseigner.\n\n
    Si besoin n’hésitez-pas à nous contacter par chat en cliquant sur “Posez-nous vos questions”.`,

    `Here you will find all the pushes you have created.\n\n
    To add a new push click on "Add a push" and let yourself be guided through the different categories.\n\n
    If you need to contact us by chat by clicking on "Ask us your questions".`
  ],
};
