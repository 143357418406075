import styled from 'styled-components';

const StyledLabel = styled.label`
  width: ${(props) => props.width}%;
  display: flex;
  align-items: ${(props) => (props.radio ? 'center' : '')};
  flex-direction: ${(props) => (props.flex ? 'initial' : 'column')};
  margin-bottom: ${(props) => (props.noMargin ? 'none' : '2rem')};
  margin-right: ${(props) => (props.mr ? '4rem' : '')};
  align-self: ${({ alignSelf }) => alignSelf};
  justify-content: ${({ justifyContent }) => justifyContent};
  margin-top: ${({ mt }) => mt};
  margin: ${({ margin }) => margin};
`;

export default StyledLabel;
