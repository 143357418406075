import React, { useState, useEffect } from 'react';
import { withMain } from '../../../hoc/main';
import Loader from '../../../components/Loader';
import { Checkbox } from '@ask-mona/ui';
import CheckboxBool from './featuresPanel/CheckboxBool';
import { GET_BOT } from '../../../_api/bot';
import { UPDATE_LANGUAGE_BOT } from '../../../_api/checklist';

function Languages(props) {
  //LANGUAGE PANEL
  const { fr, en, fct } = props.state;

  const [init, setInit] = useState(true);
  // LANGUAGE BOT
  const [lngFr, setLngFr] = useState(null);
  const [lngEn, setLngEn] = useState(null);

  useEffect(() => {
    const {
      m: { client, bot },
    } = props;
    if (init) {
      client
        .query({
          query: GET_BOT,
          variables: { id: Number(bot.id) },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          if (
            res.data.getBot.config.languages &&
            (res.data.getBot.config.languages.fr ||
              res.data.getBot.config.languages.en)
          ) {
            setLngFr(res.data.getBot.config.languages.fr);
            setLngEn(res.data.getBot.config.languages.en);
          } else {
            onChange(null, false);
          }
        })
        .catch((err) => {
          console.log('error');
        });
      setInit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (lng, bool) => {
    const {
      m: { client, bot },
    } = props;
    let frBool = null;
    let enBool = null;

    if (lng !== null) {
      frBool = lng === 'fr' ? bool : lngFr;
      enBool = lng === 'en' ? bool : lngEn;
    }

    client
      .mutate({
        mutation: UPDATE_LANGUAGE_BOT,
        variables: {
          bot_id: bot.id,
          fr: lng !== null ? frBool : false,
          en: lng !== null ? enBool : false,
        },
      })
      .then((res) => {
        if (res.data.updateLanguageBot) {
          setLngFr(lng !== null ? frBool : false);
          setLngEn(lng !== null ? enBool : false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (lngFr === null && lngEn === null)
    return <Loader className="has-text-primary" />;
  return (
    <React.Fragment>
      <p className="has-text-weight-semibold has-text-primary-ter m-b-md">
        Panel
      </p>
      <div className="m-md">
        <CheckboxBool
          classname=""
          label="Français"
          bool={fr.bool}
          onCreate={fct.createFeatures}
          onDelete={fct.deleteFeatures}
          array={[12]}
        />
        <CheckboxBool
          classname=""
          label="Anglais"
          bool={en.bool}
          onCreate={fct.createFeatures}
          onDelete={fct.deleteFeatures}
          array={[11]}
        />
      </div>
      <p className="has-text-weight-semibold has-text-primary-ter m-b-md">
        Chatbot
      </p>
      <div className="m-md">
        {lngFr && (
          <Checkbox
            onClick={() => onChange('fr', false)}
            label="Français"
            defaultChecked
          />
        )}
        {!lngFr && (
          <Checkbox onClick={() => onChange('fr', true)} label="Français" />
        )}
        {lngEn && (
          <Checkbox
            onClick={() => onChange('en', false)}
            label="Anglais"
            defaultChecked
          />
        )}
        {!lngEn && (
          <Checkbox onClick={() => onChange('en', true)} label="Anglais" />
        )}
      </div>
    </React.Fragment>
  );
}

export default withMain(Languages);
