import React, { useReducer } from 'react';
import { withMain } from '../hoc/main';
import { State, Options } from './mediations/Sidebar';
import Foundations from '../components/base/Foundations';
import Sidebar from '../components/base/Sidebar';
import Header from '../components/base/Header';
import ErrorBoundary from '../components/ErrorBoundary';
import List from './mediations/List';

const _reducer = (state, action) => {
  switch (action.type) {
    case 'setState':
      return { ...state, published: action.payload };
    case 'setFinished':
      return { ...state, finished: action.payload };
    case 'setTitle':
      return { ...state, title: action.payload };
    default:
      return state;
  }
};

const Mediations = (props) => {
  const [state, dispatch] = useReducer(_reducer, {
    published: 0,
    finished: false,
    title: '',
  });
  const {
    m: { w },
  } = props;
  return (
    <Foundations
      aside={
        <Sidebar
          c={state}
          addons={[
            <State
              key={0}
              onChange={(bool) => dispatch({ type: 'setState', payload: bool })}
              published={state.published}
            />,
            <Options
              key={1}
              onChange={() =>
                dispatch({ type: 'setFinished', payload: !state.finished })
              }
              finished={state.finished}
            />,
          ]}
          fixed
        />
      }
      header={<Header title={`${w.med.mediations}`} />}
    >
      <ErrorBoundary>
        <List {...props} filters={state} dispatch={dispatch} />
      </ErrorBoundary>
    </Foundations>
  );
};

export default withMain(Mediations);
