import React from 'react';
import { withGlobals } from '../../../hoc/globals';
import Filter from '../../../components/base/Filter';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const Options = ({ g: { w }, finished, onChange }) => (
  <Filter icon="fas fa-cogs" label={w.events.state}>
    <FormControl component="fieldset">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              label={w.med.finished}
              color="primary"
              onChange={onChange}
              defaultChecked={finished}
            />
          }
          label={w.med.finished}
          fontSize="small"
        />
      </FormGroup>
    </FormControl>
  </Filter>
);

export default withGlobals(Options);
