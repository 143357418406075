import React from 'react';
import styled from 'styled-components';
import monaError from '../../../../../_assets/mona_error.svg';

const NoContent = ({ setModalCreate, wWebchat }) => {
  return (
    <Wrapper>
      <StyledImage src={monaError} width="180px" />
      <StyledText>
        {wWebchat.empty} <br /> {wWebchat.addMailContent}
        <br />
        {wWebchat.clickBelow}
      </StyledText>
      <StyledButton onClick={() => setModalCreate(true)}>
        {wWebchat.addEmail}
      </StyledButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
`;

const StyledText = styled.p`
  text-align: center;
  margin-bottom: 2rem;
`;

const StyledImage = styled.img`
  margin: 2rem auto 0.4rem;
`;

const StyledButton = styled.button`
  background: #fff;
  padding: 0.4rem 0.5rem;
  font-weight: 600;
  border: 2px solid #d27270;
  border-radius: 5px;
  color: #d27270;
  cursor: pointer;
`;

export default NoContent;
