class createWebchat {
  constructor() {
    this.hasCanceled = false;
  }

  _updateWebchat = async (botId, template) => {
    return await this.fetch(botId, template);
  };

  _getWebchat = async (botId) => {
    return await this.fetchGetDatas(botId);
  };

  fetch = async (botId, template) => {
    let url = `${process.env.REACT_APP_HTTP_LINK}/rest/webchat/config/${botId}`;
    let req = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(template),
    };

    try {
      const res = await fetch(url, req).then((res) => res.json());
      if (res && res.error) throw new Error(res.error);
      if (this.hasCanceled) throw new Error('fetch canceled');
      return { success: true, json: res };
    } catch (err) {
      console.log(err);
      return { success: false, error: err, canceled: this.hasCanceled };
    }
  };

  fetchGetDatas = async (botId) => {
    let url = `${process.env.REACT_APP_HTTP_LINK}/rest/webchat/config/${botId}`;
    let req = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await fetch(url, req).then((res) => res.json());
      if (res && res.error) throw new Error(res.error);
      if (this.hasCanceled) throw new Error('fetch canceled');
      return { success: true, json: res };
    } catch (err) {
      console.log(err);
      return { success: false, error: err, canceled: this.hasCanceled };
    }
  };
}

export default createWebchat;
