import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Icon } from '@ask-mona/ui';
import SearchBar from '../../../components/SearchBar';
import { useWindowSize } from '../../../hooks/useWindowSize';

import SidebarSection from '../../../components/base/SidebarSection';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';

import QaContext from '../context/qaContext';
import { EditCategoriePopover } from '../popovers/EditCategoriePopover';

export const Sidebar = (props) => {
  const {
    m: {
      w,
      user: { organization, user },
      bot,
    },
    data,
    lang,
    search,
    cats,
    focus,
    setFocus,
    setAddCat,
    setSearch,
  } = props;

  const windowSize = useWindowSize();

  const [searchActive, setSearchActive] = useState(true);
  const [categoriesActive, setCategoriesActive] = useState(true);

  const leoBot = bot.features.some((el) => el.slug === 'leo');

  const qaContext = useContext(QaContext);

  const { getCategoriesV2 } = data;

  const setTabFocus = (i, activated, desactivated) => {
    setFocus(i);
    qaContext.setActivatedNumber(activated);
    qaContext.setDesactivatedNumber(desactivated);
  };

  useEffect(() => {
    if (focus) {
      let active = focus.intents.filter((e) => {
        if (leoBot) {
          return !e.deleted;
        } else {
          if (!e[lang]) return false;
          return !e[lang].deleted;
        }
      });
      let desactive = focus?.intents.filter((e) => {
        if (leoBot) {
          return e.deleted;
        } else {
          if (!e[props.lang]) return false;
          return e[lang].deleted;
        }
      });
      qaContext.setActivatedNumber(active.length);
      qaContext.setDesactivatedNumber(desactive.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus]);

  return (
    <SidebarContainer>
      <Spacing>
        <SidebarSection
          label={'Rechercher une Q/R'}
          active={searchActive}
          toggleActive={() => setSearchActive(!searchActive)}
          maxheight={100}
          click={true}
          overflowHidden
        >
          <SearchBarContainer>
            <SearchBar
              placeholder={w.generic.search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </SearchBarContainer>
        </SidebarSection>
      </Spacing>
      {!search && (
        <SidebarSection
          label={'Catégories de Q/R'}
          active={categoriesActive}
          toggleActive={() => setCategoriesActive(!categoriesActive)}
          maxheight={
            windowSize.width >= 1400
              ? 395
              : windowSize.width >= 1200
              ? 385
              : 370
          }
          forceScrollArrow={true}
          click={true}
        >
          {cats &&
            cats
              .filter((c) => {
                if (organization.id === 1) return true;
                else return c.id !== '1545240456' || c.id !== '12331565';
              })
              .filter((c) => {
                if (
                  c.fr &&
                  c.fr.toLowerCase() === 'programmatique' &&
                  user.permissions !== 1
                )
                  return false;
                else return true;
              })
              .sort((a, b) => {
                if (a.intents.length < b.intents.length) return 1;
                else if (a.intents.length > b.intents.length) return -1;
                else return 0;
              })
              .map((i) => {
                let isFocused = focus ? focus.id === i.id : false;
                let active = i.intents.filter((e) => {
                  if (leoBot) {
                    return !e.deleted;
                  } else {
                    if (!e[lang]) return false;
                    return !e[lang].deleted;
                  }
                });
                let desactive = i?.intents.filter((e) => {
                  if (leoBot) {
                    return e.deleted;
                  } else {
                    if (!e[lang]) return false;
                    return e[lang].deleted;
                  }
                });
                return (
                  <Tab
                    {...props}
                    key={i.id}
                    label={i.fr}
                    deletable={i.bot_id}
                    id={i.id}
                    active={active.length}
                    desactive={desactive.length}
                    setFocus={() =>
                      setTabFocus(i, active.length, desactive.length)
                    }
                    focused={isFocused}
                    programmatique={
                      i.fr && i.fr.toLowerCase() === 'programmatique'
                        ? true
                        : false
                    }
                  />
                );
              })}
          {bot.features.some((el) => el.slug === 'leo') &&
            getCategoriesV2 &&
            getCategoriesV2
              .filter((cat) => {
                if (
                  cat.bot_id === bot.id &&
                  cat.intents === null &&
                  !cats.some((el) => el.id === cat.id)
                )
                  return true;
                else return false;
              })
              .map((i) => {
                let isFocused = focus ? focus.id === i.id : false;
                return (
                  <Tab
                    {...props}
                    key={i.id}
                    label={i.fr}
                    deletable={i.bot_id}
                    id={i.id}
                    active={0}
                    desactive={0}
                    setFocus={() => setFocus(i)}
                    focused={isFocused}
                    programmatique={
                      i.fr && i.fr.toLowerCase() === 'programmatique'
                        ? true
                        : false
                    }
                  />
                );
              })}
          {organization.id === 1 && (
            <ButtonAddWrapper>
              <ButtonAddContainer onClick={() => setAddCat(true)}>
                <ButtonPlusSymbol>
                  <AddBoxRoundedIcon color="primary" />
                </ButtonPlusSymbol>
                <ButtonText>{`+ ${w.pricing.addCategorie}`}</ButtonText>
              </ButtonAddContainer>
            </ButtonAddWrapper>
          )}
        </SidebarSection>
      )}
    </SidebarContainer>
  );
};

const Tab = (props) => {
  const {
    label,
    focused,
    setFocus,
    id,
    m: {
      user: { organization },
    },
    active,
    desactive,
  } = props;
  const [changeName] = useState(id && organization.id === 1);

  // const qaContext = useContext(QaContext);

  let title = label;
  let counter = '';
  if (typeof active === 'number') counter = ` (${active}/${desactive})`;

  return (
    <div
      className={`p-md flex row items-center space-between pointer ${
        props.programmatique ? 'has-border-secondary has-text-white' : ''
      }`}
      onClick={setFocus}
      style={{ backroundColor: props.programmatique && 'orangered' }}
    >
      <CategorieContainer>
        <CategorieTitle
          className="space-pre-line word-break-break-word"
          focused={focused}
        >
          {title}{' '}
          <CategorieCounter focused={focused}>{counter}</CategorieCounter>
        </CategorieTitle>
        <EditCategoriePopover {...props} changeName={changeName} />
      </CategorieContainer>
      {focused && (
        <Icon icon="fas fa-chevron-right" className="has-text-secondary" />
      )}
    </div>
  );
};

const SidebarContainer = styled.div`
  display: flex;
  padding: 0 15px;
  width: 245px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1400px) {
    padding: 0 10px;
    width: 240px;
  }
  @media (max-width: 1200px) {
    padding: 0 10px;
    width: 200px;
  }
  @media (max-width: 1000px) {
    padding: 0 10px;
    width: 170px;
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
`;

const Spacing = styled.div`
  margin-bottom: 10px;
`;
const CategorieContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CategorieTitle = styled.span`
  color: ${(p) =>
    p.focused ? p.theme.colors.secondary : p.theme.colors.primary};
  text-decoration: ${(p) =>
    p.focused ? `underline ${p.theme.colors.secondary}` : 'none'};
`;

const CategorieCounter = styled.span``;

const ButtonAddWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonAddContainer = styled.div`
  background-color: white;
  padding: 8px 20px 8px 13px;
  box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  border: 1px solid ${(p) => p.theme.colors.primary};
  margin: 10px auto 20px;
  cursor: pointer;
`;

const ButtonPlusSymbol = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled.div`
  color: ${(p) => p.theme.colors.primary};
  font-size: 0.9rem;
`;
