import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Query } from 'react-apollo';
import SingleDateRange from '../../../components/SingleDateRange';
import SingleDate from '../../../components/SingleDate';
import { _sendFeedback } from '../../../_tools/ui';
import { GET_ALL_USERS } from '../../../_api/user';
import { Button, Icon, Checkbox, Select } from '@ask-mona/ui';
import { _breakOph } from '../../../_tools/oph';
import Loader from '../../../components/Loader';

const GateSettings = (props) => {
  const {
    m: {
      bot: { organization_id },
    },
  } = props;
  return (
    <Query
      query={GET_ALL_USERS}
      variables={{ organization_id: organization_id }}
      skip={!organization_id}
    >
      {({ loading, error, data, ...other }) => {
        if (loading) return <Loader className="has-text-primary-ter" />;
        if (error) return null;
        return <Settings {...props} getAllUsers={data} />;
      }}
    </Query>
  );
};

export default GateSettings;

const Settings = (props) => {
  const {
    m: { w, bot },
    api: { intent },
    lang,
    el,
    c,
    dispatch,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const [date, setDate] = useState(
    c.period && c.period !== 'permanent' ? true : false
  );
  const [mail, setMail] = useState(c.mail.activated);
  const [hide, setHide] = useState(false);
  const part = _breakOph(c.period);

  useEffect(() => {
    if (!date) dispatch({ type: 'setPeriod', payload: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    let part = _breakOph(c.mail.remind_interval);
    dispatch({ type: 'setActivated', payload: mail });
    dispatch({
      type: 'setReminderInterval',
      payload: {
        date: part.dates[0].startDate
          ? moment(part.dates[0].startDate)
          : moment().add(7, 'd'),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mail]);

  useEffect(() => {
    const update = async () => {
      _sendFeedback('warning', 'loader');
      let obj = {
        bot_id: bot.id,
        id: el.id,
        language: lang,
        content_id: c.id,
        content: c.content,
        period: c.period,
        archived_date: ``,
        place_id: c.place_id,
      };
      if (c.mail.activated && c.mail.remind_interval) {
        obj = {
          ...obj,
          mail: c.mail.mail,
          activated: c.mail.activated,
          remind_interval: c.mail.remind_interval,
        };
      }
      const res = await intent._updateContent(obj);
      if (res.success) {
        dispatch({ type: 'changeContent', payload: obj });
        _sendFeedback('success', w.success.saved);
      } else _sendFeedback('danger', w.error.general);
    };
    if (loaded) update();
    else setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [c.mail, c.period]);

  return (
    <div>
      <Checkbox
        defaultChecked={date}
        className="is-size-7 space-pre-line"
        label={w.qa.setPeriod}
        onClick={() => setDate(!date)}
      />
      {date && (
        <div className="m-t-sm m-b-sm">
          <SingleDateRange
            {...props}
            {...part.dates[0]}
            setHide={setHide}
            direction="row"
            onChange={(target) =>
              dispatch({ type: 'setDates', payload: target })
            }
          />
        </div>
      )}
      <Checkbox
        defaultChecked={mail}
        className="is-size-7 space-pre-line"
        label={w.qa.setMail}
        onClick={() => setMail(!mail)}
      />
      {mail && (
        <div className="m-t-sm m-b-sm">
          <Mail {...props} />
        </div>
      )}
    </div>
  );
};

const Mail = (props) => {
  const {
    m: { w, user },
    c: { mail },
    dispatch,
    getAllUsers: {
      getUser,
      getUsers: { users },
    },
  } = props;
  let part = _breakOph(mail.remind_interval);
  let items = users.map((u) => ({ id: u.email, value: u.email }));

  useEffect(() => {
    const userMail = user.user.email;
    if (!(mail.mail || []).some((mail) => mail === userMail))
      dispatch({
        type: 'setMailMail',
        payload: [...new Set([...(mail.mail || []), userMail])],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="flex row items-center m-b-sm">
        <div className="m-r-xs">{`${w.generic.the}`}</div>
        <SingleDate
          small
          name="remind_interval"
          placeholder={w.generic.date}
          defaultDate={
            part.dates[0].startDate
              ? moment(part.dates[0].startDate)
              : moment().add(7, 'd')
          }
          onChange={(target) =>
            dispatch({ type: 'setReminderInterval', payload: target })
          }
          showClearDate
        />
        <div className="m-l-xs m-r-xs is-lowercase">{`${w.generic.for}`}</div>
        <Select
          defaultValue={getUser.email}
          items={items || []}
          onSelect={(item) =>
            dispatch({
              type: 'setMailMail',
              payload: [...new Set([...(mail.mail || []), item.value])],
            })
          }
        />
      </div>
      {(mail.mail || []).map((u) => {
        return (
          <div
            className="p-xs m-xs is-size-7 has-background-primary-light rad-5px flex row space-between items-center"
            key={u}
          >
            {u}
            <Button
              color="danger"
              className="is-outlined is-borderless shrink"
              onClick={() => {
                dispatch({
                  type: 'setMailMail',
                  payload: mail.mail.filter((e) => e !== u),
                });
              }}
            >
              <Icon icon="fas fa-times" />
            </Button>
          </div>
        );
      })}
    </div>
  );
};
