export default {
  eventCreation: ["Création d'un événement", "Creating an event"],
  eventEdition: ["Édition d'un événement", "Editing an event"],
  start: ['Début', "Start"],
  end: ['Fin', "End"],
  draft: ['Enregistrer comme brouillon', 'Save as draft'],
  dateDetected: ['Une période a été détectée', "A period has been detected"],
  uniqDetected: ['Votre événement comporte plusieurs occurrences', "Your event has several occurrences"],
  dateParams: [
    'Validez-vous la période durant laquelle se déroule cet événement ?',
    "Do you validate the period during which this event takes place?",
  ],
  first: ['Première occurrence', "First occurrence"],
  last: ['Dernière occurrence', "Last occurrence"],
  publish: ['Enregistrer et publier', 'Save and publish'],
  image: ["Photo de l'événement", "Picture of the event"],
  type: ["Sélectionner un type d'événement", 'Select an event type'],
  theme: ["Sélectionner un type d'événement", 'Select an event type'],
  tag: ['Ajouter des mots-clés', 'Add keywords'],
  public: [
    "Spécifier le type de public (par défaut l'événement est tout public)",
    'Specify the audience (by default the event is for all audiences)',
  ],
  affPlace: [
    `Souhaitez-vous affilier cet événement aux horaires définis dans "Infos pratiques" ?`,
    `Do you wish to affiliate this event with the schedule defined in "Practical info"?`,
  ],
  affPeriod: ["Période de l'événement ?", 'Period of the event ?'],
  practicalInfo: ['Infos pratiques', 'Practical information'],
  information: ['Informations complémentaires', "Additional information"],
  description: ['Description', 'Description'],
  anecdote: [
    'Anecdote ou autres ressources disponibles (MOOC, application...) :',
    'Anecdote or other available resources (MOOC application...) :',
  ],
  moreInfoSchedule: ["Plus d'informations sur les horaires :", 'More information on the schedule:'],
  moreInfoPricing: ["Plus d'informations sur les tarifs :", 'More information on the rates:'],
  moreInfo: ['Informations complémentaires :', 'Additional information:'],
  linkToWebsite: ['Lien vers le site :', 'Link to the site:'],
  linkToResa: ['Lien vers la billetterie', 'Link to the ticketing page'],
  tellMePrice: ['Infos sur les tarifs', "Pricing information"],
  tellMeSome: ['Anecdote', "Anecdote"],
  tellMeMore: ["Dis m'en plus", 'Tell me more'],
  tellMeInfo: ['Infos Pratiques', 'Practical information'],
  tellMeDirection: ['Itinéraire', 'Itinerary'],
  pushCreation: ["Création d'un push", "Creating a push"],
  pushEdition: ["Édition d'un push", "Editing a push"],
  other: ['Autre', "Other"],
  titleLimitWarningMessage: [
    'À partir de 90 caractères votre titre sera coupé. Pensez à le réduire dans la mesure du possible',
    "From 90 characters your title will be cut. Think of reducing it as much as possible",
  ],
  fieldLimitWarningMessage: [
    'Vous allez bientôt atteindre la limite, pensez à finir votre phrase',
    "You're about to reach the limit",
  ],
  fieldLimitPassedWarningMessage: [
    'Vous avez atteint la limite conseillée, pensez à réduire votre texte',
    "You have reached the recommended limit",
  ],
  urlValid: ['Veuillez renseigner une URL valide', 'Please enter a valid URL'],
};
