import React from 'react';
import styled from 'styled-components';

const StyledGradient = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
  background: ${({ gradient }) => gradient};
  border-radius: 25px;
  margin: 0 .4rem;

  &:after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: ${({ selected }) => selected && 'blue 1px solid'};
  border-radius: 50%;
}
`;

const gradient = [
  {
    id: 1,
    color: 'grey',
    gradient: 'linear-gradient(180deg, rgba(254,249,249,1) 0%, rgba(239,236,236,1) 16%, rgba(226,221,221,1) 32%, rgba(209,206,206,1) 54%, rgba(197,192,192,1) 79%, rgba(183,178,178,1) 100%)'
  },
  {
    id: 2,
    color: 'blue',
    gradient: 'linear-gradient(180deg, rgba(254,240,241,1) 0%, rgba(248,195,196,1) 16%, rgba(240,149,151,1) 32%, rgba(234,116,120,1) 54%, rgba(228,91,96,1) 79%, rgba(217,26,41,1) 100%)'
  },
  {
    id: 3,
    color: 'red',
    gradient: 'linear-gradient(180deg, rgba(241,247,252,1) 0%, rgba(204,216,245,1) 16%, rgba(162,180,235,1) 32%, rgba(128,151,227,1) 54%, rgba(75,106,215,1) 79%, rgba(45,79,207,1) 100%)'
  },
]

const Gradient = ({
  background,
  dispatch,
  type
}) => {

  return (
    <>
      {gradient.map((el) => (
        <StyledGradient 
          id={el.id}
          key={el.id}
          gradient={el.gradient}
          selected={background === el.gradient}
          onClick={(e) => {
            dispatch({ type, payload: el.gradient });
          }}
        />
      ))}
    </>
  )
};

export default Gradient;
