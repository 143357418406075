import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from '@ask-mona/ui';
import { _generatePath } from '../Reducer';
import { _sendFeedback } from '../../../_tools/ui';
import { _checkRanges } from '../../../_tools/other';

const DaliLogic = (props) => {
  const {
    m: { w },
    focus,
    subfocus,
    Bot,
    modules,
    dispatch,
  } = props;

  const [range, setRange] = React.useState([0, 0]);
  const [baseRange, setBaseRange] = React.useState([0, 0]);

  React.useEffect(() => {
    if (
      focus &&
      subfocus &&
      focus.dali &&
      focus.content.func.default.daliType === 'quiz' &&
      subfocus.answer
    ) {
      try {
        let range = focus.content.func['quiz'][subfocus.id];
        setRange(range);
        setBaseRange(range);
      } catch (err) {
        console.log(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subfocus]);

  if (
    focus &&
    subfocus &&
    focus.dali &&
    focus.content.func.default.daliType === 'quiz' &&
    subfocus.answer
  )
    return (
      <Container>
        <DescritpionText>{w.scenario.step}</DescritpionText>
        <div
          className="flex row m.r.xs items-center"
          style={{ width: 'min-content' }}
        >
          <Input
            value={range[0] || ''}
            type="text"
            onChange={(e) => setRange([e.target.value, range[1]])}
          />
          <AndText>{w.generic.and}</AndText>
          <Input
            value={range[1] || ''}
            type="text"
            onChange={(e) => setRange([range[0], e.target.value])}
          />
          {(range[0] !== baseRange[0] || range[1] !== baseRange[1]) && (
            <Button
              className="is-outlined is-borderless shrink"
              onClick={async () => {
                let newFocus = { ...focus };
                let newQuiz = {
                  ...focus.content.func['quiz'],
                  [subfocus.id]: range,
                };

                let k = Object.keys(focus.trigger).find((k) =>
                  k.match(/p:results/)
                );

                let res = await Bot._updateModule({
                  path: _generatePath(newFocus).concat([
                    'content',
                    'func',
                    'quiz',
                  ]),
                  module: { quiz: newQuiz },
                });

                res = await Bot._updateModule({
                  path: _generatePath(newFocus).concat([
                    'trigger',
                    k,
                    'content',
                    'func',
                    'quiz',
                  ]),
                  module: { quiz: newQuiz },
                });

                if (res.success) {
                  newFocus = {
                    ...focus,
                    content: {
                      ...focus.content,
                      func: {
                        ...focus.content.func,
                        quiz: newQuiz,
                      },
                    },
                    trigger: {
                      ...focus.trigger,
                      [k]: {
                        ...focus.trigger[k],
                        content: {
                          ...focus.trigger[k].content,
                          func: {
                            ...focus.trigger[k].content.func,
                            quiz: newQuiz,
                          },
                        },
                      },
                    },
                  };
                  let newModules = modules.map((m) =>
                    m.key === newFocus.key ? newFocus : m
                  );
                  dispatch({ type: 'setModules', payload: newModules });
                  dispatch({ type: 'setFocus', payload: newFocus });
                  dispatch({ type: 'setSubFocus', payload: subfocus });
                  setBaseRange(range);
                  _sendFeedback('success', w.success.saved);
                } else _sendFeedback('danger', w.error.general);

                if (!_checkRanges(newQuiz))
                  _sendFeedback('danger', w.scenario.wtf);
              }}
            >
              <Icon icon="fas fa-check" />
            </Button>
          )}
        </div>
      </Container>
    );
  return null;
};

export { DaliLogic };

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 40px;
  margin-left: 20px;
  font-size: 1rem;
`;

const DescritpionText = styled.span`
  color: ${(p) => p.theme.colors.primary};
  margin-right: 10px;
`;

const Input = styled.input`
  border: 2px solid ${(p) => p.theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 35px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  color: ${(p) => p.theme.colors.primary};

  &:active {
    border: 2px solid ${(p) => p.theme.colors.primary};
  }

  &:focus {
    border: 2px solid ${(p) => p.theme.colors.primary};
  }
`;

const AndText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  color: ${(p) => p.theme.colors.primary};
`;
