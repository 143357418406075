import React, { Component } from 'react';
import styled from 'styled-components';
import { withMain } from '../../hoc/main';
import { withApi } from '../../hoc/api';
import { Field, Input, Icon, Button, Image as Img, Select } from '@ask-mona/ui';
import { _uploadImg } from '../../_api/upload';
import City from '../../_api/city';
import { _sendFeedback } from '../../_tools/ui';
import { _getKeyValue } from '../../_tools/other';
import Autocomplete from './GoogleAutocomplete';
import Animator from '../Animator';
import SubPlaceCreator from './SubPlaceCreator';
import pronoun from './data/pronoun';

class PlaceCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSub: false,
      advanced: false,
      city_id: null,
      lat: null,
      long: null,
      name: props.default || '',
      address: '',
      short_address: '',
      google_place_id: '',
      postcode: '',
      pronoun: '',
    };
  }

  onConfirm = async () => {
    _sendFeedback('warning', 'loader');
    const {
      api: { place },
      m: { w },
      onSuccess,
      onError,
    } = this.props;
    let res = null;
    if (this.state.isSub)
      res = await place._createSubPlace({ ...this.state, itinerary: false });
    else res = await place._createPlace({ ...this.state });
    if (res.success) {
      _sendFeedback('success', w.feedback.createPlace);
      onSuccess &&
        onSuccess(res[this.state.isSub ? 'createSubPlace' : 'createPlace']);
    } else {
      _sendFeedback('danger', w.error.createPlace);
      onError && onError(res);
    }
  };

  onGetPlace = async (place) => {
    const { sub_places, value, name, id, ...other } = place;
    this.setState({
      ...other,
      place_id: id,
    });
  };

  onSelect = async (place) => {
    const {
      m: { w },
    } = this.props;
    if (place) {
      const { other, ...form } = place;
      _sendFeedback('warning', 'loader');
      const city = await this.setCity(other);
      if (city.success) _sendFeedback('success', w.feedback.getCity);
      else _sendFeedback('danger', w.error.getCity);
      this.setState({ ...form, city_id: _getKeyValue(city, 'id') });
    }
  };

  setCity = async (data) => {
    const {
      m: { client },
    } = this.props;
    let city = new City(client, data);
    let ret = await city._getCity();
    if (!ret || !ret.getCity) ret = await city._createCity();
    return ret;
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  reset = (e) => {
    this.setState({
      lat: null,
      long: null,
      city_id: null,
      address: e.target.value,
      short_address: '',
      google_place_id: '',
      postcode: '',
    });
  };

  toggleAdvanced = () => {
    this.setState((state) => ({ advanced: !state.advanced }));
  };

  render() {
    const {
      m: { w },
      onCancel,
      subOpt,
    } = this.props;
    return (
      <div className="w-4 has-background-white rad-5">
        <div className="flex row space-between items-center has-background-primary p-md">
          <div className="has-text-white">{w.place.createPlace}</div>
          <Button
            onClick={onCancel}
            className="is-outlined is-borderless shrink"
          >
            <Icon icon="fas fa-times" />
          </Button>
        </div>
        <div className="p-md">
          <Field title={w.place.name} className="m-b-md">
            <Input
              name="name"
              value={this.state.name}
              placeolder={w.place.namePlaceholder}
              onChange={this.onChange}
              className="w-100p"
            />
          </Field>
          {subOpt && subOpt.length >= 1 && (
            <SubPlaceCreator
              {...this.props}
              isSwitchVisible
              isSub={this.state.isSub}
              onChange={this.onChange}
              onSelect={this.onGetPlace}
            />
          )}
          <StyledTitle>{w.place.pronoun}</StyledTitle>
          <Select
            items={pronoun}
            onSelect={(e) => this.setState({ pronoun: e.value })}
            className="w-100p m-b-md"
          />
          <Field title={w.place.address} className="m-b-md">
            <Autocomplete
              onSelect={this.onSelect}
              onChange={this.reset}
              default={this.state.address}
              style={{ width: '100%' }}
            />
          </Field>
          <div className="flex center">
            <Button
              className="is outlined is-borderless is-size-7"
              onClick={this.toggleAdvanced}
            >
              {this.state.advanced ? (
                <Icon icon="fas fa-minus" />
              ) : (
                <Icon icon="fas fa-plus" />
              )}
            </Button>
          </div>
          <Animator
            isOpen={this.state.advanced}
            enter="animation-slideDown overflow-hidden"
          >
            <Advanced
              {...this.props}
              data={this.state}
              onChange={this.onChange}
            />
          </Animator>
          <div className="flex row space-between wrap">
            <Button
              className="is-uppercase"
              onClick={onCancel}
              color="secondary"
            >
              {w.generic.cancel}
            </Button>
            <Button
              className="is-uppercase"
              onClick={this.onConfirm}
              disabled={
                this.state.isSub
                  ? !this.state.name ||
                    !this.state.address ||
                    !this.state.place_id
                  : !this.state.lat ||
                    !this.state.long ||
                    !this.state.name ||
                    !this.state.address ||
                    !this.state.short_address ||
                    !this.state.google_place_id ||
                    !this.state.postcode
              }
            >
              {w.generic.validate}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export const Advanced = ({
  m: { w, bot },
  data: { short_address, postcode, lat, long, image_url },
  onChange,
}) => (
  <React.Fragment>
    <Field title={w.place.shortAddress} className="m-b-md">
      <Input
        name="short_address"
        value={short_address || ''}
        placeolder={w.place.shortAddressPlaceholder}
        onChange={onChange}
        className="w-100p"
      />
    </Field>
    <Field title={w.place.postcode} className="m-b-md">
      <Input
        name="postcode"
        value={postcode || ''}
        placeolder={w.place.postcodePlaceholder}
        onChange={onChange}
        className="w-100p"
      />
    </Field>
    <Field title={w.place.image} className="m-b-md">
      <Img
        src={image_url || bot.default_image}
        onUpload={async (e) => {
          await _uploadImg(
            bot.id,
            e,
            (res) => {
              _sendFeedback("success", w.success.saved);
              onChange({ target: { name: 'image_url', value: res.path } });
            },
            (err) => console.log(err)
          );
        }}
        onChange={(e) => {
          onChange({ target: { name: 'image_url', value: e.target.value } });
        }}
        className="border-grey-lighter"
      />
    </Field>
    <div className="has-text-success is-size-7">
      {`${lat || ''}   ${long || ''}`}
    </div>
  </React.Fragment>
);

const StyledTitle = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 2px;
  color: #2d307b;
`;

export default withMain(withApi(PlaceCreator));
