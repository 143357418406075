import React, { Component } from 'react';
import Tag from './Tag';
import { Select } from '@ask-mona/ui';

class Option extends Component {
  render() {
    const { items, onSelect, id, data, onDelete, fct, tag } = this.props;
    return (
      <div className="w-80p p-md">
        <div className="flex row w-100pg">
          <div>
            {items === null && <p> null </p>}
            {items !== null && (
              <Select small items={items} onSelect={onSelect} />
            )}
          </div>
          <div className="flex row center items-center m-l-md w-80p">
            {data && (
              <Tag
                id={id}
                tags={data}
                onDelete={onDelete}
                fct={fct}
                tag={tag}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Option;
