import React from 'react';
import { Select } from '@ask-mona/ui';
import { closingDates } from '../../_tools/oph';

export default (props) => {
  const {
    g: { w },
  } = props;

  const {
    fct: { addOph },
  } = props;
  return (
    <div>
      <Select
        placeholder={w.generic.selectDate}
        items={closingDates}
        onSelect={(item) => addOph({ oph: item.oph })}
      />
    </div>
  );
};
