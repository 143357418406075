import { useState, useEffect } from 'react';

const useClickInside = (ref, initial = false) => {
  const [inside, clickInside] = useState(initial);
  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && ref.current.contains(e.target)) return;
      clickInside(false);
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
  return [inside, clickInside];
};

export { useClickInside };
