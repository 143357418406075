import React, { useContext } from 'react';
import styled from 'styled-components';
import Content from '../components/topicAnswerContent';
import { QAContext } from '../context/QAContext';

interface Content {
  index: number;
  content: {
    type: string;
    content: string;
  };
  answerId: number;
  w: any;
}

const AnswerContent = (props: Content) => {
  const { dispatch } = useContext(QAContext);

  return (
    <WrapperContent>
      <StyledArrows>
        <div
          onClick={() =>
            dispatch({
              type: 'MOVE_ANSWER_CONTENT',
              payload: {
                answerId: props.answerId,
                contentId: props.index,
                direction: 'up',
              },
            })
          }
        >
          <i className="fas fa-chevron-up" />
        </div>
        <div
          onClick={() =>
            dispatch({
              type: 'MOVE_ANSWER_CONTENT',
              payload: {
                answerId: props.answerId,
                contentId: props.index,
                direction: 'down',
              },
            })
          }
        >
          <i className="fas fa-chevron-down" />
        </div>
      </StyledArrows>
      <div
        className="w-100p p-sm"
        onClick={() =>
          dispatch({ type: 'SET_CURRENT_ANSWER_CONTENT', payload: props.index })
        }
      >
        <Content
          isEditable={true}
          content={props.content}
          id={props.index}
          answerId={props.answerId}
          w={props.w}
        />
      </div>
      <StyledCross
        onClick={() => {
          dispatch({
            type: 'SET_CURRENT_ANSWER_CONTENT',
            payload: props.index,
          });
          dispatch({
            type: 'UPDATE_TOPIC_ANSWER',
            payload: {
              value: '',
              type: props.content.type,
              remove: true,
              buttons: null,
              id: props.index,
              answerId: props.answerId,
            },
          });
        }}
      >
        <i className="fas fa-times" />
      </StyledCross>
    </WrapperContent>
  );
};

const WrapperContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
`;

const StyledArrows = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCross = styled.div`
  color: #e08585;
  cursor: pointer;
`;

export default AnswerContent;
