import React, { useReducer } from 'react';
import ScenariosContext from './scenariosContext';
import ScenariosReducer from './scenariosReducer';

const ScenariosState = (props) => {
  const initialState = {
    functionalitiesSubsbar: {},
    functionalitiesSubsbarFocus: '',
    functionalitiesSubsbarFunc: [],
    functionalitiesSubsbarLang: '',
  };

  const [state, dispatch] = useReducer(ScenariosReducer, initialState);

  const setFunctionalitiesSubsbar = (elements) =>
    dispatch({ type: 'SET_FUNCTIONALITIES_SUBSBAR', payload: elements });

  const setFunctionalitiesSubsbarFocus = (focus) =>
    dispatch({ type: 'SET_FUNCTIONALITIES_SUBSBAR_FOCUS', payload: focus });

  const setFunctionalitiesSubsbarFunc = (func) =>
    dispatch({ type: 'SET_FUNCTIONALITIES_SUBSBAR_FUNC', payload: func });

  const setFunctionalitiesSubsbarLang = (lang) =>
    dispatch({ type: 'SET_FUNCTIONALITITES_SUBSBAR_LANG', payload: lang });

  return (
    <ScenariosContext.Provider
      value={{
        functionalitiesSubsbar: state.functionalitiesSubsbar,
        functionalitiesSubsbarFocus: state.functionalitiesSubsbarFocus,
        functionalitiesSubsbarFunc: state.functionalitiesSubsbarFunc,
        functionalitiesSubsbarLang: state.functionalitiesSubsbarLang,
        setFunctionalitiesSubsbar,
        setFunctionalitiesSubsbarFocus,
        setFunctionalitiesSubsbarFunc,
        setFunctionalitiesSubsbarLang,
      }}
    >
      {props.children}
    </ScenariosContext.Provider>
  );
};

export default ScenariosState;
