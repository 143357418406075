import React, { Component } from 'react';
//import { Checkbox } from '@ask-mona/ui';
import { withGlobals } from '../../../hoc/globals';
import Filter from '../../../components/base/Filter';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class State extends Component {
  state = {
    hover: false,
  };

  onChange = (value) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const {
      g: { w },
      published,
    } = this.props;

    return (
      <Filter icon="fas fa-bookmark" label={w.events.state}>
        <FormControl component="fieldset">
          <FormGroup>
            {published === 1 && (
              <FormControlLabel
                control={
                  <Checkbox
                    label={w.pushs.published}
                    color="primary"
                    onChange={() => this.onChange(1)}
                    defaultChecked={true}
                  />
                }
                label={w.pushs.published}
                fontSize="small"
              />
            )}
            {published !== 1 && (
              <FormControlLabel
                control={
                  <Checkbox
                    label={w.pushs.published}
                    color="primary"
                    onChange={() => this.onChange(1)}
                    defaultChecked={false}
                  />
                }
                label={w.pushs.published}
                fontSize="small"
              />
            )}
            {published === 2 && (
              <FormControlLabel
                control={
                  <Checkbox
                    label={w.pushs.unpublished}
                    color="primary"
                    onChange={() => this.onChange(2)}
                    defaultChecked={true}
                  />
                }
                label={w.pushs.unpublished}
                fontSize="small"
              />
            )}
            {published !== 2 && (
              <FormControlLabel
                control={
                  <Checkbox
                    label={w.pushs.unpublished}
                    color="primary"
                    onChange={() => this.onChange(2)}
                    defaultChecked={false}
                  />
                }
                label={w.pushs.unpublished}
                fontSize="small"
              />
            )}
            {published === 3 && (
              <FormControlLabel
                control={
                  <Checkbox
                    label={w.pushs.draft}
                    color="primary"
                    onChange={() => this.onChange(3)}
                    defaultChecked={true}
                  />
                }
                label={w.pushs.draft}
                fontSize="small"
              />
            )}
            {published !== 3 && (
              <FormControlLabel
                control={
                  <Checkbox
                    label={w.pushs.draft}
                    color="primary"
                    onChange={() => this.onChange(3)}
                    defaultChecked={false}
                  />
                }
                label={w.pushs.draft}
                fontSize="small"
              />
            )}
          </FormGroup>
        </FormControl>
      </Filter>
    );
  }
}

export default withGlobals(State);
