import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { _sendFeedback } from '../../../_tools/ui';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#fff' },
    secondary: { main: '#E66E6E' },
  },
});

export const EditCategoriePopover = (props) => {
  const {
    label,
    focused,
    focus,
    deletable,
    id,
    m: { w, bot },
    api: { intent },
    lang,
    changeName,
  } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [name, setName] = useState('');

  useEffect(() => {
    if (focus && focus[lang]) {
      setName(focus[lang]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus]);

  const handleSave = async () => {
    if (name !== label) {
      _sendFeedback('warning', 'loader');
      let res = await intent._updateCategory(
        {
          id: id,
          [lang]: name,
        },
        {
          refetchQueries: ['getCategoriesAndIntents'],
        }
      );
      if (res.success) _sendFeedback('success', w.success.saved);
      else _sendFeedback('danger', w.error.general);
    }
    setIsPopoverOpen(false);
  };

  const handleDelete = async () => {
    _sendFeedback('warning', 'loader');
    let res = await intent._deleteCategory(
      {
        bot_id: bot.id,
        id: id,
      },
      {
        refetchQueries: ['getCategoriesAndIntents'],
      }
    );
    if (res) _sendFeedback('success', w.success.saved);
    else _sendFeedback('danger', w.error.general);
  };

  return changeName && deletable ? (
    <Popover
      isOpen={isPopoverOpen}
      position={['right', 'bottom']}
      padding={5}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={'#2D2F7B'}
          arrowSize={10}
          arrowStyle={{ zIndex: 10000, borderRight: '15px solid #2D2F7B' }}
        >
          {' '}
          <Container>
            <Header>
              <HeaderText>{w.qa.editCategorie}</HeaderText>
              <MuiThemeProvider theme={theme}>
                <ButtonContainer>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleSave}
                  >
                    {w.generic.save}
                  </Button>
                </ButtonContainer>
              </MuiThemeProvider>
            </Header>
            <LineContainer>
              <LineTitle>{w.qa.catName}</LineTitle>
              <NameInput>
                <CustomInput
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </NameInput>
            </LineContainer>
            {deletable && (
              <LineContainer>
                <DeleteButton onClick={handleDelete}>
                  <DeleteButtonIcon>
                    <DeleteRoundedIcon
                      color="primary"
                      style={{ fontSize: '18px' }}
                    />
                  </DeleteButtonIcon>
                  <DeleteButtonText>{w.qa.deleteCategorie}</DeleteButtonText>
                </DeleteButton>
              </LineContainer>
            )}
          </Container>
        </ArrowContainer>
      )}
    >
      <IconContainer>
        <EditRoundedIcon
          color={focused ? 'secondary' : 'primary'}
          style={{ fontSize: '12px' }}
          onClick={() => {
            setIsPopoverOpen(!isPopoverOpen);
          }}
        />
      </IconContainer>
    </Popover>
  ) : null;
};

const DeleteButton = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: #ececec;
  }
`;

const DeleteButtonIcon = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 3px;
`;

const DeleteButtonText = styled.div`
  color: ${(p) => p.theme.colors.primary};
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const CustomInput = styled.input`
  background-color: ${(p) => p.theme.colors.primary};
  color: white;
  border: 1px solid white;
  border-radius: 2px;
  height: 40px;
  width: 250px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  &:focus {
    border-color: white;
    outline-color: white;
  }
`;

const IconContainer = styled.span`
  margin-left: 10px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  position: relative;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1000 !important;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled.span`
  margin-right: 20px;
  font-size: 1.1rem;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;

const LineTitle = styled.div`
  margin-bottom: 10px;
`;
const NameInput = styled.div``;
