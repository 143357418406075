import React, { useReducer } from 'react';
import QaContext from './qaContext';
import QaReducer from './qaReducer';

const QaState = (props) => {
  const initialState = {
    items: [],
    leoIntents: [],
    leoEntities: [],
    susbarFocus: 'activated',
    activatedNumber: 0,
    desactivatedNumber: 0,
  };

  const [state, dispatch] = useReducer(QaReducer, initialState);

  const copyItems = (items) => dispatch({ type: 'COPY_ITEMS', payload: items });

  const setLeoIntents = (leoIntents) =>
    dispatch({ type: 'SET_LEO_INTENTS', payload: leoIntents });

  const setLeoEntities = (leoEntities) =>
    dispatch({ type: 'SET_LEO_ENTITIES', payload: leoEntities });

  const setSusbarFocus = (focusString) =>
    dispatch({ type: 'SET_SUSBAR_FOCUS', payload: focusString });

  const setActivatedNumber = (activatedLength) =>
    dispatch({ type: 'SET_ACTIVATED_NUMBER', payload: activatedLength });

  const setDesactivatedNumber = (desactivatedLength) =>
    dispatch({ type: 'SET_DESACTIVATED_NUMBER', payload: desactivatedLength });

  return (
    <QaContext.Provider
      value={{
        items: state.items,
        leoIntents: state.leoIntents,
        leoEntities: state.leoEntities,
        susbarFocus: state.susbarFocus,
        activatedNumber: state.activatedNumber,
        desactivatedNumber: state.desactivatedNumber,
        copyItems,
        setLeoIntents,
        setLeoEntities,
        setSusbarFocus,
        setActivatedNumber,
        setDesactivatedNumber,
      }}
    >
      {props.children}
    </QaContext.Provider>
  );
};

export default QaState;
