import React, { useState } from 'react';
import { _random } from '../../_tools/other';
import { Button, Icon } from '@ask-mona/ui';
import TimeRange from '../../components/TimeRange';
import useHover from '../../hooks/useHover';

export default ({ block, onChange, g: { w } }) => {
  const [add, setAdd] = useState(false);
  const [addTime, setAddTime] = useState({ start: '', end: '' });

  return (
    <div className="flex column items-flex-end">
      <div>
        {block.hours.map((h, index) => (
          <Times
            key={_random()}
            {...h}
            onTimeChange={({ time, name }) => {
              const newHours = block.hours.map((h, i) => {
                return index === i ? { ...h, [name]: time } : h;
              });
              onChange && onChange({ ...block, hours: newHours });
            }}
            onClick={() => {
              onChange &&
                onChange({
                  ...block,
                  hours: block.hours.filter((h, i) => index !== i),
                });
            }}
          />
        ))}
        {!add && (
          <Button
            className="is-outlined is-borderless shrink"
            onClick={() => setAdd(true)}
          >
            <span className="is-underlined is-size-7 has-text-primary-ter">
              {w.schedule.addHours}
            </span>
          </Button>
        )}
        {add && (
          <Times
            {...addTime}
            onClick={() => setAdd(false)}
            onTimeChange={({ time, name }) => {
              let nAddTime = { ...addTime, [name]: time };
              if (nAddTime.start && nAddTime.end)
                onChange &&
                  onChange({ ...block, hours: block.hours.concat(nAddTime) });
              else setAddTime(nAddTime);
            }}
          />
        )}
      </div>
    </div>
  );
};

const Times = ({ onClick, ...other }) => {
  const element = (hovered) => (
    <Button
      className="shrink is-outlined is-borderless"
      color="danger"
      onClick={onClick}
    >
      <Icon icon="fas fa-times" className="is-size-8" />
    </Button>
  );

  const [hoverable, hovered] = useHover(element);

  return (
    <div
      className={`m-b-xs flex row items-center ${
        hovered ? 'border-b-danger has-background-danger-light' : ''
      }`}
    >
      {hoverable}
      <TimeRange {...other} />
    </div>
  );
};
