import React from 'react';

export const Main = React.createContext();

export const withMain = (Component) => {
  return (props) => {
    return (
      <Main.Consumer>
        {(context) => <Component {...props} m={context} />}
      </Main.Consumer>
    );
  };
};
