class Webchat {
  constructor() {
    this.hasCanceled = false;
  }

  _getHistory = async (body) => {
    return await this.fetch(body);
  };

  _cancelFetch = () => {
    this.hasCanceled = true;
  };

  fetch = async (body = null) => {
    let url = `${process.env.REACT_APP_HTTP_LINK}/rest/users/history?`;
    // TO QUERY STRING
    let params = Object.keys(body)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);
      })
      .join('&');
    url = url + params;
    let req = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await fetch(url, req).then((res) => res.json());
      if (res && res.error) throw new Error(res.error);
      if (this.hasCanceled) throw new Error('fetch canceled');
      return { success: true, json: res };
    } catch (err) {
      console.log('err');
      return { success: false, error: err, canceled: this.hasCanceled };
    }
  };
}

export default Webchat;
