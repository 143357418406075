import React, { useState, useEffect } from 'react';
import CheckboxBool from './featuresPanel/CheckboxBool';
import Bot from '../../../_api/bot';
import Analytics from '../../../_api/analytics';
import Loader from '../../../components/Loader';
import { withMain } from '../../../hoc/main';

const FeaturesAnalystics = ({ m: { w }, client, bot }) => {
  const [features, setFeatures] = useState(null);
  const [nbActivated, setNbActivated] = useState(null);

  useEffect(() => {
    getBot();
  });

  const getBot = async () => {
    const Api = new Bot(client, bot.id);
    const res = await Api._getBot();
    setFeatures(res.getBot.config.analyticsFeatures);
    countFeaturesActivated();
  };

  const countFeaturesActivated = () => {
    if (features) {
      const nb = features.filter((f) => f.activated === true);
      setNbActivated(nb.length);
    }
  };

  const activatedAnalytics = async (name) => {
    await new Analytics(client, bot.id)._updateAnalyticsFeatures({
      bot_id: bot.id,
      name: name,
      activated: true,
    });
    getBot();
  };

  const desactivatedAnalytics = async (name) => {
    await new Analytics(client, bot.id)._updateAnalyticsFeatures({
      bot_id: bot.id,
      name: name,
      activated: false,
    });
    getBot();
  };

  const AnalyticsFeature = ({ label, f }) => {
    return (
      <CheckboxBool
        classname={
          nbActivated >= 5 && !f.activated ? 'm-md opacity-middle' : 'm-md'
        }
        label={label}
        bool={f.activated}
        onCreate={activatedAnalytics}
        onDelete={desactivatedAnalytics}
        data={f.name}
        disabled={nbActivated >= 5 && !f.activated ? true : false}
      />
    );
  };

  if (!features) return <Loader />;
  else
    return (
      <React.Fragment>
        <div className="has-text-primary-bis has-text-weight-semibold">
          {w.checklist.textAnalytics}
        </div>
        {features &&
          features.map((f, i) => {
            if (f.name === 'evts_published')
              return (
                <AnalyticsFeature
                  key={i}
                  label={w.checklist.evtsPublished}
                  f={f}
                />
              );
            if (f.name === 'evts_unpublished')
              return (
                <AnalyticsFeature
                  key={i}
                  label={w.checklist.evtsUnpublished}
                  f={f}
                />
              );
            if (f.name === 'messages')
              return (
                <AnalyticsFeature key={i} label={w.checklist.messages} f={f} />
              );
            if (f.name === 'nb_users')
              return (
                <AnalyticsFeature key={i} label={w.checklist.nbUsers} f={f} />
              );
            if (f.name === 'duration')
              return (
                <AnalyticsFeature key={i} label={w.checklist.duration} f={f} />
              );
            if (f.name === 'nb_sessions_users')
              return (
                <AnalyticsFeature
                  key={i}
                  label={w.checklist.nbSessions}
                  f={f}
                />
              );
            if (f.name === 'nb_users_fr')
              return (
                <AnalyticsFeature key={i} label={w.checklist.nbUsersFr} f={f} />
              );
            if (f.name === 'nb_clic_evts')
              return (
                <AnalyticsFeature
                  key={i}
                  label={w.checklist.nbClicEvts}
                  f={f}
                />
              );
            return null;
          })}
        {!features && <Loader />}
      </React.Fragment>
    );
};

export default withMain(FeaturesAnalystics);
