import React, { Component } from 'react';
import { _formatConversation } from './tools/format';
import { GlobalsContext } from './hoc/withGlobals';
import Webchat from './containers/Webchat';
import Loader from '../Loader';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      opts: {
        reply: {
          bot: {
            type: 'icon',
            src: 'http://askmonastudio.com/26/jmgr.png',
          },
          user: {
            type: 'icon',
            src: 'http://askmonastudio.com/26/jmgr.png',
          },
        },
      },
      conversation: {
        history: [],
        quickReplies: [],
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { focus } = nextProps;
    if (focus && this.state.focus.id !== focus.id)
      this.setState({
        focus: focus,
        conversation: _formatConversation(focus.history),
      });
  }

  componentDidMount() {
    const { focus } = this.props;
    if (focus) {
      this.setState({
        focus: focus,
        conversation: _formatConversation(focus.history),
      });
    }
  }

  render() {
    const { loading } = this.state;
    if (loading) return <Loader />;
    return (
      <GlobalsContext.Provider value={this.state}>
        <Webchat w={this.props.m.w} />
      </GlobalsContext.Provider>
    );
  }
}

export default App;
