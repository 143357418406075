import React, { useReducer } from 'react';
import Portal from '../../components/Portal';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import { GET_BOT_HAS_PLACES } from '../../_api/place';
import { GET_BOT } from '../../_api/bot';
import { _sendFeedback } from '../../_tools/ui';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import Nodata from '../../components/Nodata';
import AddPlaceModal from '../../components/place/AddPlaceModal';
import PlaceEditor from '../../components/place/PlaceEditor';
import Tutorial from '../../components/Tutorial';

import { _reducer, initialState } from './direction/Reducer';
import Header from './direction/Header';
import Heading from './direction/Heading';
import Table from './direction/Table';

const Direction = ({ subID, fct, ...other }) => {
  const {
    placeData,
    botData,
    m: { w, bot },
    api,
  } = other;
  const [state, dispatch] = useReducer(_reducer, initialState);
  if (subID !== 2) fct.changeSub(2);

  if (!placeData || placeData.loading || !botData || botData.loading)
    return <Loader className="has-text-primary" />;
  if (placeData.error || botData.error)
    throw new Error(placeData.error || botData.error);

  return (
    <div className="w-100p has-text-primary-ter relative h-100 overflow-auto">
      <Portal
        component={<Header {...other} dispatch={dispatch} />}
        selector="base-header"
      />
      {(!placeData.getBotHasPlaces ||
        placeData.getBotHasPlaces.length === 0 ||
        !botData.getBot.places[0]) && <Nodata title={w.error.noPlaces} />}
      <Heading {...other} state={state} />
      <Table {...other} dispatch={dispatch} />
      <Modal
        isOpen={state.getInfo}
        close={() => dispatch({ type: 'toggleInfo' })}
        selector="#root"
      >
        <Tutorial
          tuto={w.tutorial.direction}
          close={() => dispatch({ type: 'toggleInfo' })}
        />
      </Modal>
      <Modal
        isOpen={state.edit}
        close={() => dispatch({ type: 'setEdit', payload: null })}
        selector="#root"
      >
        <PlaceEditor
          place={state.edit}
          onCancel={() => dispatch({ type: 'setEdit', payload: null })}
          onSuccess={() => {
            dispatch({ type: 'setEdit', payload: null });
            botData.refetch();
            placeData.refetch();
          }}
        />
      </Modal>
      <Modal
        isOpen={state.addPlace}
        close={() => dispatch({ type: 'setAddPlace', payload: false })}
        selector="#root"
      >
        <AddPlaceModal
          onCancel={() => dispatch({ type: 'setAddPlace', payload: false })}
          subOpt={botData.getBot.places}
          onAdd={async (place) => {
            if (place) {
              if (typeof place.itinerary !== 'boolean') {
                _sendFeedback('warning', 'loader');
                let res = await api.place._setBotHasPlace(
                  { bot_id: bot.id, place_id: place.id, itinerary: false },
                  { refetchQueries: ['getBot', 'getBotHasPlaces'] }
                );
                if (res.success) _sendFeedback('success', w.success.saved);
                else _sendFeedback('danger', w.error.generic);
              } else botData.refetch();
            }
            dispatch({ type: 'setAddPlace', payload: false });
          }}
        />
      </Modal>
    </div>
  );
};

const withPlaces = graphql(GET_BOT_HAS_PLACES, {
  options: (ownProps) => ({
    variables: {
      bot_id: ownProps.m.bot.id,
    },
  }),
  name: 'placeData',
});

const withBot = graphql(GET_BOT, {
  options: (ownProps) => {
    return {
      variables: {
        id: ownProps.m.bot.id,
      },
    };
  },
  name: 'botData',
});

export default compose(withPlaces, withBot)(Direction);
