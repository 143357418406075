import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import { GET_LOGS, GET_INTENTS } from '../../_api/log';
import Loader from '../../components/Loader';
import Nodata from '../../components/Nodata';
import Sidebar from './Sidebar';
import Main from './Main';

const Messagerie = ({ logsData, intentData, changeSub, subID, ...other }) => {
  const {
    m: { w },
    archived,
    wc,
  } = other;
  if (subID !== 0 && !archived && !wc) changeSub(0);
  else if (subID !== 1 && archived && !wc) changeSub(1);
  else if (subID !== 3 && !archived && wc) changeSub(3);
  const [filters, setFilters] = useState({
    read: false,
    wait: false,
    disabled: false,
  });
  const [focus, setFocus] = useState(null);
  if (!logsData || !intentData || logsData.loading || intentData.loading)
    return <Loader className="has-text-primary" />;
  if (!logsData.getLogs.length) return <Nodata title={w.inbox.noLogs} />;
  // FORMAT INTENT ==========================
  let getIntents = intentData.getIntents || [];
  let quickAnswers = [];
  getIntents.forEach((a) => {
    const {
      m: { lang },
    } = other;
    a.intents
      .filter((b) => b[lang].content && b[lang].content.length)
      .forEach((b) => {
        let name = b[lang].name;
        let content = [];
        b[lang].content
          .filter((c) => c.content && c.content.length)
          .forEach((c) => c.content.forEach((d) => d && content.push(d)));
        if (name && content.length) quickAnswers.push({ name, content });
      });
  });
  // FORMAT LOGS =============================
  let getLogs = logsData.getLogs || [];
  // Filters logs
  getLogs = getLogs.filter((l) => {
    const { message, read, bot_disabled } = l;
    let last = message[message.length - 1];

    return filters.read
      ? !read
      : true && filters.wait
      ? read && last.is_user
      : true && filters.disabled
      ? bot_disabled
      : true && wc
      ? l.wc === true
      : l.wc === null;
  });

  // Sort logs
  getLogs.sort((a, b) => {
    try {
      let date_a = parseInt(a.message[a.message.length - 1].date, 10);
      let date_b = parseInt(b.message[b.message.length - 1].date, 10);
      if (date_a > date_b) return -1;
      if (date_a < date_b) return 1;
      else return 0;
    } catch (err) {
      return 0;
    }
  });
  // Autofocus on first logs
  if ((!focus || !getLogs.find((l) => l.id === focus.id)) && getLogs.length > 1)
    setFocus(getLogs[0] || {});
  return (
    <div className="has-text-primary w-100p flex row">
      <Sidebar
        {...other}
        setFilters={(obj) => {
          setFilters(obj);
          setFocus(null);
        }}
        filters={filters}
        logs={getLogs}
        focus={focus}
        setFocus={setFocus}
      />
      <Main
        {...other}
        logs={getLogs}
        focus={focus}
        setFocus={setFocus}
        quickAnswers={quickAnswers}
        wc={wc}
      />
    </div>
  );
};

const withLogs = graphql(GET_LOGS, {
  options: (ownProps) => ({
    variables: {
      bot_id: ownProps.botID,
      archived: ownProps.archived && !ownProps.wc ? true : false,
    },
    fetchPolicy: 'network-first',
    pollInterval: 5000,
  }),
  name: 'logsData',
});

const withIntents = graphql(GET_INTENTS, {
  options: (ownProps) => ({
    variables: {
      bot_id: ownProps.botID,
    },
    fetchPolicy: 'network-only',
  }),
  name: 'intentData',
});

export default compose(withLogs, withIntents)(Messagerie);
