import React from 'react';
import { withGlobals } from '../hoc/withGlobals';
import ImgIcon from './ImgIcon';
import TextIcon from './TextIcon';

const ReplyIcon = ({
  context: {
    opts: { reply },
  },
  to,
}) => {
  const { type, ...props } = reply[to ? 'user' : 'bot'];
  switch (type) {
    case 'text':
      return <TextIcon small {...props} />;
    case 'icon':
      return (
        <ImgIcon small {...props} icon={to ? 'fas fa-user' : 'fas fa-robot'} />
      );
    default:
      return <TextIcon small text={to ? 'U' : 'B'} />;
  }
};

export default withGlobals(ReplyIcon);
