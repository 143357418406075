import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Logo from '../components/Logo';
import InboxCounter from './header/InboxCounter';
import DropdownMenu from './header/DropdownMenu';
import ToggleBot from './header/ToggleBot';

const Tab = ({ menu, active, addons, onChange }) => {
  return (
    <SectionNavLink to={menu.path}>
      <div className={` p-md relative `} onClick={onChange}>
        <SectionTitle>{menu.title}</SectionTitle>
        {addons}
      </div>
    </SectionNavLink>
  );
};

const Sections = ({ menu, sectionID, onChange, bot, client }) => {
  return (
    <>
      {menu.length &&
        menu.map((m) => (
          <Tab
            key={m.id}
            menu={m}
            active={sectionID === m.id}
            onChange={() => onChange(m.id)}
            addons={
              m.w === 'inbox' ? (
                <InboxCounter bot={bot} client={client} />
              ) : null
            }
          />
        ))}
    </>
  );
};

const Header = ({ m: { fct, menu = [], bot, w, client, ...other } }) => {
  const redirectToList =
    other.user.user.permissions === 1 || other.user.user.bots.length > 1;

  const translatedMenu = Array.isArray(menu)
    ? menu.map(m => ({
      ...m,
      title: w.sections[m.title],
    }))
    : menu;

  return (
    <Container>
      <LogoContainer>
        {redirectToList ? (
          <>
            <LogoNavLink
              to="/office/homepageAdmin"
              onClick={() => fct.switchBot()}
            >
              <Logo />
            </LogoNavLink>
          </>
        ) : (
          <>
            <LogoNavLink to={`/office/bot/${bot.id}`}>
              <Logo />
            </LogoNavLink>
          </>
        )}
      </LogoContainer>
      <SectionsContainer>
        <Sections
          menu={translatedMenu}
          onChange={fct.changeSection}
          {...other}
          bot={bot}
          client={client}
        />
      </SectionsContainer>
      <AvatarContainer>
        {bot && (
          <ToggleContainer>
            <ToggleBot bot={bot} w={w} />
          </ToggleContainer>
        )}
        <DropdownMenu {...other} w={w} bot={bot} />
      </AvatarContainer>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  height: 70px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1400px) {
    font-size: 0.8rem;
  }
`;

const LogoContainer = styled.div`
  margin-left: 20px;

  @media (max-width: 1000px) {
    margin-left: 10px;
  }
`;

const LogoNavLink = styled(NavLink)`
  margin: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const SectionsContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
`;

const SectionNavLink = styled(NavLink)`
  color: ${(p) => p.theme.colors.primary};
  display: flex;
  height: 100%;
  transform: translateZ(0);
  align-items: center;
  text-align: center;
  position: relative;
  margin: 0 15px 0;

  @media (max-width: 1400px) {
    margin: 0 10px 0;
  }
  @media (max-width: 1200px) {
    margin: 0 5px 0;
  }
  @media (max-width: 1000px) {
    margin: 0 0px 0;
  }

  &:hover {
    color: #eb6866;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 50px;
    height: 3px;
    width: 100%;
    background-color: ${(p) => p.theme.colors.secondary};
    transform: scaleX(0);
    transition: transform 0.3s, width 0.5s cubic-bezier(1, 0, 0, 1) 0.5s;
    will-change: width;
    backface-visibility: hidden;
    perspective: 1000;
  }

  &:hover::before {
    transform: scaleX(1);
    width: 100%;
  }

  &.active::before {
    transform: scaleX(1);
    width: 100%;
  }

  &.active {
    color: #eb6866;
  }
`;

const SectionTitle = styled.div`
  font-size: 0.8rem;
  @media (max-width: 1400px) {
    font-size: 0.7rem;
  }
`;

const AvatarContainer = styled.div`
  height: 100%;
  display: flex;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
`;

const ToggleContainer = styled.div`
  margin-right: 14px;
  margin-bottom: 15px;
  margin-top: 10px;
  text-align: center;
`;

export default Header;
