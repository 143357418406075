import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { RightArrow } from '@styled-icons/boxicons-solid/RightArrow';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';
import StyledBigTitle from '../../../../components/parameters/StyledTitle';
import InputRadio from '../../../../components/parameters/InputRadio';
import StyledLabel from '../../../../components/parameters/StyledLabel';
import Gradient from '../../../../components/parameters/Gradient';
import templateQr from './templates/Qr';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WrapperDiv = styled.div`
  display: flex;
  align-items: ${({ alignItem }) => alignItem};
  margin: ${({ mr }) => (mr ? mr : '2rem 0')};
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
`;

const SubititleLight = styled.h3`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: ${({ mb }) => mb};
`;

const StyledDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItem }) => alignItem || 'center'};
  margin-right: ${({ mr }) => mr};
  width: ${({ width }) => width};
`;

const StyledQr = styled.div`
  background: ${({ background }) => background};
  color: ${({ background }) => (background === 'none' ? '#2d307b' : '#FFF')};
  font-size: 0.9em;
  border: ${({ background }) =>
    background === 'none' ? '1px solid #2d307b' : 'none'};
  padding: 0.4rem 1rem;
  border-radius: 25px;
  box-shadow: ${({ background }) =>
    background === 'none' ? '' : 'rgba(120, 120, 120, 0.8) 0px 2px 2px 0px'};
  transition-duration: 0.3s;
  cursor: pointer;
  outline: none;
  margin: 0.6rem;
`;

const Qr = ({ wWebchat, state, dispatch }) => {
  const [hideFirstContent, setHideFirstContent] = useState(true);
  const [hideSecondContent, setHideSecondContent] = useState(true);
  const [backgroundSelected, setBackgroundSelected] = useState('');
  const { Handle } = Slider;

  useEffect(() => {
    state.colorQr?.includes('linear-gradient') &&
      setBackgroundSelected('gradient');
  }, []);

  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} %`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  return (
    <Wrapper>
      <StyledBigTitle>
        {wWebchat.templateChoice}
        <span onClick={() => setHideFirstContent(!hideFirstContent)}>
          {hideFirstContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideFirstContent && (
        <>
          <WrapperDiv mr={'0 2rem'}>
            <SubititleLight>{wWebchat.borderQr}</SubititleLight>
          </WrapperDiv>
          <WrapperDiv mr={'2rem 3rem'} width={'50%'}>
            <Slider
              trackStyle={{ backgroundColor: 'red', height: '10px' }}
              railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
              handleStyle={{
                width: '20px',
                height: '20px',
                border: '1px solid lightgray',
                boxShadow:
                  'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
              }}
              min={0}
              max={25}
              defaultValue={state.borderQr}
              value={state.borderQr}
              onChange={(value) => {
                dispatch({ type: 'setBorderQr', payload: value });
              }}
              handle={handle}
            />
          </WrapperDiv>
          <WrapperDiv mr={'0 2rem'}>
            <SubititleLight>{wWebchat.homepage_bubble_height}</SubititleLight>
          </WrapperDiv>
          <WrapperDiv mr={'2rem 3rem'} width={'50%'}>
            <Slider
              trackStyle={{ backgroundColor: 'red', height: '10px' }}
              railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
              handleStyle={{
                width: '20px',
                height: '20px',
                border: '1px solid lightgray',
                boxShadow:
                  'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
              }}
              min={30}
              max={44}
              step={1}
              defaultValue={state.heightQr}
              value={state.heightQr}
              onChange={(value) => {
                dispatch({ type: 'setHeightQr', payload: value });
              }}
              handle={handle}
            />
          </WrapperDiv>
          <WrapperDiv mr={'0 2rem'}>
            <SubititleLight>{wWebchat.homepage_bubble_width}</SubititleLight>
          </WrapperDiv>
          <WrapperDiv mr={'2rem 3rem'} width={'50%'}>
            <Slider
              trackStyle={{ backgroundColor: 'red', height: '10px' }}
              railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
              handleStyle={{
                width: '20px',
                height: '20px',
                border: '1px solid lightgray',
                boxShadow:
                  'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
              }}
              min={135}
              max={230}
              step={1}
              defaultValue={state.widthQr}
              value={state.widthQr}
              onChange={(value) => {
                dispatch({ type: 'setWidthQr', payload: value });
              }}
              handle={handle}
            />
          </WrapperDiv>
          <WrapperDiv mr={'0 2rem'}>
            <SubititleLight>{wWebchat.filling}</SubititleLight>
          </WrapperDiv>
          <WrapperDiv justifyContent={'space-around'}>
            <StyledDivWrapper>
              <StyledLabel radio>
                <StyledQr background="none">{wWebchat.example}</StyledQr>
                <div>
                  <InputRadio
                    type="radio"
                    value="template1"
                    checked={
                      JSON.stringify(state.qrTemplate) ===
                      JSON.stringify(templateQr('template1'))
                    }
                    onChange={(e) => {
                      dispatch({
                        type: 'setQrTemplate',
                        payload: templateQr(e.target.value),
                      });
                      dispatch({
                        type: 'setQrBorderColor',
                        payload: state.colorFontQr,
                      });
                    }}
                  />
                  {wWebchat.outline}
                </div>
              </StyledLabel>
            </StyledDivWrapper>
            <StyledDivWrapper>
              <StyledLabel radio>
                <StyledQr background={'#2d307b'}>{wWebchat.example}</StyledQr>
                <div>
                  <InputRadio
                    type="radio"
                    value="template2"
                    checked={
                      JSON.stringify(state.qrTemplate) ===
                      JSON.stringify(templateQr('template2'))
                    }
                    onChange={(e) => {
                      dispatch({
                        type: 'setQrTemplate',
                        payload: templateQr(e.target.value),
                      });
                      dispatch({
                        type: 'setQrBorderColor',
                        payload: 'transparent',
                      });
                    }}
                  />
                  {wWebchat.full}
                </div>
              </StyledLabel>
            </StyledDivWrapper>
          </WrapperDiv>
        </>
      )}
      <StyledBigTitle>
        {wWebchat.templateConfig}
        <span onClick={() => setHideSecondContent(!hideSecondContent)}>
          {hideSecondContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideSecondContent && (
        <>
          <WrapperDiv justifyContent={'space-around'}>
            <StyledDivWrapper alignItem={'flex-start'}>
              <SubititleLight mb={'1rem'}>{wWebchat.colorQr}</SubititleLight>
              <ChromePicker
                color={state.colorQr}
                onChangeComplete={(val) => {
                  setBackgroundSelected('color');
                  dispatch({ type: 'setColorQr', payload: val.hex });
                }}
              />
            </StyledDivWrapper>
            <StyledDivWrapper alignItem={'flex-start'}>
              <SubititleLight mb={'1rem'}>{wWebchat.colorFont}</SubititleLight>
              <ChromePicker
                color={state.colorFontQr}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setColorFontQr', payload: val.hex });
                  dispatch({ type: 'setQrBorderColor', payload: val.hex });
                }}
              />
            </StyledDivWrapper>
          </WrapperDiv>
          <WrapperDiv justifyContent={'space-around'}>
            <StyledDivWrapper>
              <SubititleLight>{wWebchat.fontSize}</SubititleLight>
              <Slider
                trackStyle={{ backgroundColor: 'red', height: '10px' }}
                railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                handleStyle={{
                  width: '20px',
                  height: '20px',
                  border: '1px solid lightgray',
                  boxShadow:
                    'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                }}
                min={0.8}
                max={1.1}
                step={0.1}
                value={state.fontSizeQr.slice(0, -2)}
                onChange={(value) => {
                  dispatch({ type: 'setFontSizeQr', payload: `${value}em` });
                }}
                handle={handle}
              />
            </StyledDivWrapper>
          </WrapperDiv>
          <WrapperDiv>
            <StyledLabel radio flex>
              <InputRadio
                type="radio"
                value="gradient"
                checked={backgroundSelected === 'gradient'}
                onChange={(e) => {
                  setBackgroundSelected(e.target.value);
                }}
              />
              {wWebchat.chooseGradient}
              <Gradient
                background={state.colorQr}
                dispatch={dispatch}
                type={'setColorQr'}
              />
            </StyledLabel>
          </WrapperDiv>
        </>
      )}
    </Wrapper>
  );
};

export default Qr;
