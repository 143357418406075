export default {
  grantee: ['Bénéficiaire', 'Beneficiary'],
  information: ['Information complémentaire', 'Additional information'],
  price: ['Prix', 'Price'],
  addCategorie: ['Ajouter une catégorie', 'Add a category'],
  addSubCategorie: ['Ajouter une sous-catégorie', 'Add a sub-category'],
  addTarif: ['Ajouter un tarif', 'Add a rate'],
  edit: ['Éditer', 'Edit'],
  add: ['Ajouter', 'Add'],
  editCategorie: ['la catégorie', 'the category'],
  editSubCategorie: ['la sous-catégorie', 'the subcategory'],
  editPrice: ['le tarif', 'the rate'],
  default: ['Éditer', 'Edit'],
  linkToCategorie: [
    'Rattacher ce tarif à une catégorie',
    'Attach this rate to a category',
  ],
  pricing: ['Tarification', 'Pricing'],
  pricingName: ['Nom du tarif', 'Name of the tariff'],
  fullPricing: ['Plein tarif', 'Full price'],
  addLanguage: ['Ajouter une langue', 'Add a language'],
  removeLanguage: ['Supprimer une langue', 'Delete a language'],
};
