import React, { useState, useEffect, useContext } from 'react';
import { Button, Field, Icon, Input, Switch } from '@ask-mona/ui';
import { _sendFeedback } from '../../../_tools/ui';
import Modal from '../../../components/Modal';
import Editor from './Editor';
import List from '../../../components/scenario/components/List';
import { _isButton, _isList } from '../../../_tools/is';
import Select from 'react-select';
import styled from 'styled-components';

import LeoApi from '../../../api/rest/LeoApi';

import QaContext from '../context/qaContext';

export default (props) => {
  const {
    m: {
      w,
      user: { organization, user },
      bot,
    },
    payloads,
    api: { intent },
    lang,
    el,
  } = props;

  const leoBot = bot.features.some((el) => el.slug === 'leo');

  const [changeFolder, setChangefolder] = useState(false);
  const [linkTo, setLinkTo] = useState(false);
  const [edit, setEdit] = useState(false);
  const [linkToLeoIntent, setLinkToLeoIntent] = useState(false);
  const [ch, changeName] = useState(false);
  const [name, setName] = useState(leoBot ? el.name : el[lang].name);

  const qaContext = useContext(QaContext);

  let firstContent = leoBot
    ? (el.answers || []).find((el) => el.activated) || null
    : (el[lang].content || []).find((el) => el.activated) || null;

  let fc = firstContent ? (firstContent.content || [])[0] : '';

  if (fc && (_isList(fc) || _isButton(fc)))
    fc = <List {...props} element={JSON.parse(fc)} index={0} disabled="true" />;

  return (
    <div
      className={`has-text-primary overflow-auto ${
        props.programmatic ? 'border-programmatic' : 'border-b-primary-lighter'
      }`}
    >
      <div className="flex row border-b-primary-lighter" style={{}}>
        <div className="p-xs flex items-center row border-r-primary-lighter items-center grow-3 basis-0 space-between">
          {ch && (
            <Field>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
              <Button
                className="shrink is-size-7"
                onClick={async () => {
                  if (
                    (!leoBot && name !== el[lang].name) ||
                    (leoBot && name !== el.name)
                  ) {
                    _sendFeedback('warning', 'loader');
                    let res = await intent._updateIntent(
                      {
                        bot_id: bot.id,
                        id: el.id,
                        language: lang,
                        name: name,
                      },
                      { refetchQueries: ['getCategoriesAndIntents'] }
                    );
                    if (res.success) _sendFeedback('success', w.success.saved);
                    else _sendFeedback('danger', w.error.general);
                  }
                  ch && changeName(false);
                }}
              >
                <Icon className="has-text-primary" icon="fas fa-check" />
              </Button>
            </Field>
          )}
          {!ch && (
            <React.Fragment>
              <span className="space-pre-line word-break-break-word">
                {leoBot ? el.name : el[lang].name}
              </span>
              {organization.id === 1 && (
                <Button
                  onClick={() => changeName(true)}
                  className="is-outlined is-borderless shrink m-l-sm is-size-7"
                  color="primary"
                >
                  <Icon className="has-text-primary" icon="fas fa-pen" />
                </Button>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="p-xs flex border-r-primary-lighter space-pre-line word-break-break-word items-center grow-4 basis-0">
          {!el.jump && fc}
          {el.jump &&
            `Lien direct vers le module ${
              (payloads.find((it) => it.value === el.jump) || {}).value
            }`}
        </div>

        <div className="p-xs flex items-center center grow-2 basis-0">
          <Button
            className="is-size-7 is-outlined is-borderless shrink"
            onClick={() => setEdit(!edit)}
          >
            {' '}
            <Icon className="has-text-primary" icon="fas fa-pen" />{' '}
          </Button>
          <Button
            className="is-size-7 is-outlined is-borderless shrink"
            onClick={() => setChangefolder(true)}
          >
            {' '}
            <Icon className="has-text-primary" icon="fas fa-folder" />
          </Button>
          {organization.id === 1 && (
            <Button
              className="is-size-7 is-outlined is-borderless shrink"
              onClick={() => setLinkTo(true)}
            >
              {' '}
              <Icon className="has-text-primary" icon="fas fa-link" />
            </Button>
          )}
          {leoBot && user.permissions === 1 && (
            <Button
              className="is-size-7 is-outlined is-borderless shrink"
              onClick={() => setLinkToLeoIntent(true)}
              color={el.specific_id ? 'secondary' : 'primary'}
            >
              {' '}
              <Icon icon="fas fa-comment" />
            </Button>
          )}
        </div>
        <div className="p-xs flex border-r-primary-lighter space-pre-line items-center center grow-1 basis-0">
          <Switch
            defaultChecked={leoBot ? !el.deleted : !el[lang].deleted}
            className="has-text-primary"
            color="primary"
            onClick={async () => {
              _sendFeedback('warning', 'loader');
              let res = await intent._toggleIntent(
                {
                  bot_id: bot.id,
                  id: el.id,
                  language: lang,
                },
                { refetchQueries: ['getCategoriesAndIntents'] }
              );
              if (res.success) {
                _sendFeedback('success', w.success.saved);
                if (leoBot ? el.deleted : el[lang].deleted) {
                  qaContext.setActivatedNumber(qaContext.activatedNumber + 1);
                  qaContext.setDesactivatedNumber(
                    qaContext.desactivatedNumber - 1
                  );
                } else if (leoBot ? !el.deleted : !el[lang].deleted) {
                  qaContext.setActivatedNumber(qaContext.activatedNumber - 1);
                  qaContext.setDesactivatedNumber(
                    qaContext.desactivatedNumber + 1
                  );
                }
              } else {
                _sendFeedback('danger', w.error.general);
              }
            }}
          />
        </div>
      </div>
      <LinkToModule {...props} isOpen={linkTo} close={() => setLinkTo(false)} />
      <ChangeFolder
        {...props}
        isOpen={changeFolder}
        close={() => setChangefolder(false)}
      />
      <Editor {...props} isOpen={edit} close={() => setEdit(false)} />
      <LinkToLeoIntent
        {...props}
        isOpen={linkToLeoIntent}
        close={() => setLinkToLeoIntent(false)}
      />
    </div>
  );
};

const LinkToModule = (props) => {
  const {
    m: { w, bot },
    api: { intent },
    payloads,
    close,
    lang,
    el,
  } = props;

  const leoBot = bot.features.some((el) => el.slug === 'leo');

  const [jump, setJump] = useState(() => {
    if (el.jump)
      return (payloads.find((it) => it.value === el.jump) || {}).value;
    else return '';
  });

  return (
    <Modal {...props} close={close} selector="#root">
      <div className="w-4 has-background-white">
        <div className="p-md has-text-white has-background-primary">
          {w.qa.linkToModule}
        </div>
        <div className="p-md">
          {`${w.generic.link} "${leoBot ? el.name : el[lang].name}" ${
            w.generic.hTo
          }:`}
          <Field className="m-t-sm m-b-md" style={{ width: '300px' }}>
            <Select
              defaultValue={jump}
              options={payloads}
              className="w-100p"
              onChange={(item) => setJump(item.value)}
            />
            <Button
              onClick={async () => {
                _sendFeedback('warning', 'loader');
                let res = await intent._updateIntent(
                  {
                    bot_id: bot.id,
                    id: el.id,
                    language: lang,
                    jump: jump,
                  },
                  { refetchQueries: ['getCategoriesAndIntents'] }
                );
                if (res.success) {
                  _sendFeedback('success', w.success.saved);
                  close();
                } else _sendFeedback('danger', w.error.general);
              }}
            >
              {w.generic.validate}
            </Button>
          </Field>
          <Button color="danger" onClick={close}>
            {w.generic.cancel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const ChangeFolder = (props) => {
  const {
    m: { w, bot },
    api: { intent },
    close,
    lang,
    cat,
    cats,
    allCats,
    el,
  } = props;

  const leoBot = bot.features.some((el) => el.slug === 'leo');

  const [id, setId] = useState(cat.id);
  let items = cats
    .map((c) => {
      return { value: c.id, label: c[lang] || c['fr'] || '' };
    })
    .concat(
      allCats
        .filter((c) => {
          if (leoBot && c.bot_id === bot.id && c.intents === null) return true;
          else return false;
        })
        .map((c) => {
          return { value: c.id, label: c[lang] || c['fr'] || '' };
        })
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Modal {...props} close={close} selector="#root">
      <div className="w-4 has-background-white">
        <div className="p-md has-text-white has-background-primary">
          {w.qa.changeCategory}
        </div>
        <div className="p-md">
          {`${w.generic.send} "${leoBot ? el.name : el[lang].name}" ${
            w.qa.toCat
          }:`}
          <Field className="m-t-sm m-b-md">
            <Select
              defaultValue={(cat[lang] || {}).name}
              options={items}
              className="w-100p"
              onChange={(item) => setId(item.value)}
            />
            <Button
              onClick={async () => {
                if (id !== cat.id) {
                  _sendFeedback('warning', 'loader');
                  const res = await intent._changeCategory(
                    { bot_id: bot.id, intent_id: el.id, category_id: id },
                    { refetchQueries: ['getCategoriesAndIntents'] }
                  );
                  if (res.success) {
                    _sendFeedback('success', w.success.saved);
                    close();
                  } else _sendFeedback('danger', w.error.general);
                }
              }}
            >
              {w.generic.validate}
            </Button>
          </Field>
          <Button color="danger" onClick={close}>
            {w.generic.cancel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const LeoIntentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LeoIntentContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`;

const LeoIntentColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LeoIntentText = styled.div`
  text-align: center;
`;

const LinkToLeoIntent = (props) => {
  const {
    m: {
      w,
      user: { user },
      bot,
    },
    api: { intent },
    close,
    lang,
    el,
  } = props;

  const [entitieValue, setEntitieValue] = useState('');
  const [intentValue, setIntentValue] = useState('');

  const qaContext = useContext(QaContext);

  let intents = qaContext.leoIntents.map((c) => {
    return { value: c.id, label: c.title };
  });

  let entities = qaContext.leoEntities.map((c) => {
    return { value: c.id, label: c.title };
  });

  const [defaultIntent, setDefaultIntent] = useState(
    el.specific_id && el.specific_id.split('_')[0]
      ? intents.filter(
          (intent) =>
            intent.value.toString() === el.specific_id.split('_')[0].substr(3)
        )[0]
      : null
  );

  const [defaultEntitie, setDefaultEntitie] = useState(
    el.specific_id && el.specific_id.split('_')[1]
      ? entities.filter(
          (entitie) =>
            entitie.value.toString() === el.specific_id.split('_')[1].substr(3)
        )[0]
      : null
  );

  useEffect(() => {
    setDefaultIntent(
      el.specific_id && el.specific_id.split('_')[0]
        ? intents.filter(
            (intent) =>
              intent.value.toString() === el.specific_id.split('_')[0].substr(3)
          )[0]
        : null
    );
    setDefaultEntitie(
      el.specific_id && el.specific_id.split('_')[1]
        ? entities.filter(
            (entitie) =>
              entitie.value.toString() ===
              el.specific_id.split('_')[1].substr(3)
          )[0]
        : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el.specific_id, qaContext.leoIntents, qaContext.leoEntities]);

  const [shouldHaveDefaultIntent, setShouldHaveDefaultIntent] = useState(true);
  const [shouldHaveDefaultEntitie, setShouldHaveDefaultEntitie] = useState(
    true
  );

  const closeModal = () => {
    setShouldHaveDefaultIntent(true);
    setShouldHaveDefaultEntitie(true);
    close();
  };

  const handleSave = async () => {
    _sendFeedback('warning', 'loader');
    const res = await intent._updateIntent(
      {
        bot_id: bot.id,
        id: el.id,
        language: lang,
        name: el.name,
        specific_id: entitieValue.value
          ? `INT${intentValue.value}_ENT${entitieValue.value}`
          : intentValue.value
          ? `INT${intentValue.value}`
          : null,
      },
      { refetchQueries: ['getCategoriesAndIntents'] }
    );
    if (res.success) {
      _sendFeedback('success', w.success.saved);
      close();
    } else _sendFeedback('danger', w.error.general);
  };

  const onChangeIntent = (e) => {
    setShouldHaveDefaultIntent(false);
    setShouldHaveDefaultEntitie(false);
    setEntitieValue('');
    setIntentValue(e ? e : '');
  };

  const onChangeEntitie = (e) => {
    setShouldHaveDefaultEntitie(false);
    setEntitieValue(e ? e : '');
  };

  useEffect(() => {
    const getEntities = async () => {
      let api = new LeoApi(`${process.env.REACT_APP_MLAPI}/topics`, user.token);
      let params = {
        take: '',
        skip: '',
        intents: intentValue.value,
      };
      try {
        if (intentValue.value === '') return null;
        else {
          let res = await api.get(null, params);
          qaContext.setLeoEntities(res.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getEntities();
    setEntitieValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intentValue.value]);

  return (
    <Modal {...props} close={closeModal} selector="#root" clickinside={true}>
      <div className="has-background-white">
        <div className="p-md has-text-white has-background-primary">
          <LeoIntentHeader>
            Liaison de cette thématique à LEO
            <Button color="secondary" onClick={handleSave}>
              {w.generic.save}
            </Button>
          </LeoIntentHeader>
        </div>
        <LeoIntentContent>
          <div className="p-md has-text-primary">
            <LeoIntentColumn>
              <LeoIntentText>Intent lié à cette thématique</LeoIntentText>
              <Field className="m-t-sm m-b-md">
                <div style={{ width: `300px` }}>
                  <Select
                    options={intents}
                    value={
                      shouldHaveDefaultIntent
                        ? {
                            value:
                              defaultIntent && defaultIntent.value.toString(),
                            label: defaultIntent && defaultIntent.label,
                          }
                        : intentValue
                    }
                    isClearable={true}
                    onChange={onChangeIntent}
                  />
                </div>
              </Field>
            </LeoIntentColumn>
          </div>
          <div className="p-md has-text-primary">
            <LeoIntentColumn>
              <LeoIntentText>Choisir un group entity</LeoIntentText>
              <Field className="m-t-sm m-b-md">
                <div style={{ width: `300px` }}>
                  <Select
                    options={entities}
                    value={
                      shouldHaveDefaultEntitie
                        ? {
                            value:
                              defaultEntitie && defaultEntitie.value.toString(),
                            label: defaultEntitie && defaultEntitie.label,
                          }
                        : entitieValue
                    }
                    isClearable={true}
                    isDisabled={
                      !defaultIntent || !intentValue || shouldHaveDefaultIntent
                    }
                    onChange={onChangeEntitie}
                  />
                </div>
              </Field>
            </LeoIntentColumn>
          </div>
        </LeoIntentContent>
      </div>
    </Modal>
  );
};
