import React, { useContext } from 'react';
import styled from 'styled-components';
import { Answer } from '../../../../types/qa/topics';
import { _isImg, _isButton, _isGif, _isVid } from '../../../../_tools/is';
import Content from '../topicAnswerContent/index';
import { QAContext } from '../../context/QAContext';
import { Api } from '../../../../api/graphql/hooks/useTopics';

interface Props {
  answer: Answer;
  api: Api;
  w: any;
}

type Content = {
  type: string;
  content: string;
};

type Contents = Array<Content>;

interface IconProps {
  readonly color: string;
}

const HistoryContent = (props: Props) => {
  const { state, dispatch } = useContext(QAContext);

  const answersParsed = JSON.parse(props.answer?.content);

  const contents: Contents = answersParsed.map((content: object) => {
    const contentStringified = JSON.stringify(content);

    if (_isButton(contentStringified))
      return { type: 'button', content: contentStringified };
    if (_isImg(contentStringified))
      return { type: 'image', content: contentStringified };
    if (_isGif(contentStringified))
      return { type: 'gif', content: contentStringified };
    if (_isVid(contentStringified))
      return { type: 'video', content: contentStringified };
    return { type: 'text', content: contentStringified };
  });

  return (
    <>
      <WrapperInfos>
        <StyledText>
          {state.wording.archivedBy}
          {new Date(props.answer.disabled_date).toLocaleDateString()}{' '}
          {state.wording.by}
          {props.answer.disabled_user && props.answer.disabled_user.email}{' '}
        </StyledText>

        <WrapperIcons>
          <StyledIcon color={'#e08585'}>
            <i
              className="fas fa-trash"
              onClick={() => props.api.deleteTopicAnswer(props.answer.id)}
            ></i>
          </StyledIcon>
          <StyledIcon color={'#5054be'}>
            <i
              className="fas fa-share-square"
              onClick={() => {
                dispatch({
                  type: 'UPDATE_ANSWER',
                  payload: {
                    enabled: true,
                    id: props.answer.id,
                    startDate: props.answer.start_date,
                    endDate: props.answer.end_date,
                    reminderDate: props.answer.reminder_date,
                    reminderEmails: props.answer.reminder_emails,
                    place: props.answer.place,
                  },
                });
                props.api.updateTopicAnswers(
                  props.answer.id,
                  props.answer.content,
                  true,
                  props.answer.start_date,
                  props.answer.end_date,
                  props.answer.reminder_date,
                  props.answer.reminder_emails,
                  props.answer.place?.id
                );
              }}
            ></i>
          </StyledIcon>
        </WrapperIcons>
      </WrapperInfos>
      <>
        {contents.map((el, i) => (
          <Content
            content={el}
            id={i}
            answerId={props.answer.id}
            isEditable={false}
            w={props.w}
          />
        ))}
      </>
    </>
  );
};

const StyledIcon = styled.div<IconProps>`
  color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  padding: 0 0.4rem;
  > i {
    cursor: pointer;
    margin: 0.4rem 0;
  }
`;

const WrapperIcons = styled.div`
  display: flex;
  justify-content: end;
`;

const WrapperInfos = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledText = styled.div`
  font-size: 0.75em;
  color: #645eb0;
  font-style: italic;
`;

export default HistoryContent;
