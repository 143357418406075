import React from 'react';
import styled from 'styled-components';
import Pagination from 'react-js-pagination';
import { _getKeyValue } from '../../../_tools/other';
import { Icon, Button, Checkbox, Field, Select } from '@ask-mona/ui';
import MUIButton from '@material-ui/core/Button';
import SearchBar from '../../../components/SearchBar';

const actions = [
  { id: 0, label: { fr: 'Publier', en: 'Publish' } },
  { id: 1, label: { fr: 'Supprimer', en: 'Delete' } },
];

export const Header = ({
  m: { w, lang },
  mediations,
  counter,
  fct,
  onChange,
}) => {
  return (
    <div className="m-l-xs flex row grow-1 space-between">
      <div className="flex items-center has-text-primary-ter">
        <Counter>{`(${counter}) `}</Counter>
        <SearchBarContainer>
          <SearchBar
            onChange={onChange}
            className="m-l-md"
            placeholder={w.generic.search}
          />
        </SearchBarContainer>
        {mediations.length > 0 && (
          <Field className="m-l-xs">
            <Select
              items={actions.map((a) => ({ ...a, value: a.label[lang] }))}
              onSelect={fct.onSelect}
              placeholder={w.generic.actions}
            />
            <MUIButton
              color="secondary"
              variant="contained"
              className="shrink"
              onClick={fct.onConfirm}
            >
              {w.generic.validate}
            </MUIButton>
          </Field>
        )}
      </div>
      <div className="flex row">
        <MUIButton
          variant="contained"
          color="secondary"
          onClick={fct.onAddMediation}
        >
          {`${w.generic.add} ${
            w.generic.an
          } ${w.generic.mediation.toLowerCase()}`}
        </MUIButton>
        <MUIButton
          className="shrink is-outlined m-l-xs"
          onClick={fct.onGetInfo}
        >
          <Icon className="has-text-primary" icon="fas fa-exclamation-circle" />
        </MUIButton>
      </div>
    </div>
  );
};

export const Heading = ({
  m: { w },
  fct,
  mediations,
  create,
  counter,
  mediation,
  getInfo,
}) => (
  <div
    className="flex row has-background-primary is-size-6 has-text-white sticky top"
    style={{ zIndex: mediation || create || getInfo ? 0 : 1 }}
  >
    <div className="p-xs flex">
      {' '}
      <Checkbox
        checked={mediations.length === counter}
        onChange={() => fct.ppMediations(-42)}
        className="small"
      />{' '}
    </div>
    <div className="p-xs flex grow-2 basis-0">{w.generic.title}</div>
    <div className="p-xs flex grow-2 basis-0">{w.events.dates}</div>
    <div className="p-xs flex grow-2 basis-0">{w.events.place}</div>
    <div className="p-xs flex grow-1 basis-0 center">{w.events.publish}</div>
    <div className="p-xs flex grow-1 basis-0 center">{w.generic.action}</div>
  </div>
);

export const Footer = ({ offset, limit, data, setOffset }) => {
  const total = _getKeyValue(data, 'total') || 0;
  return (
    <div className="flex center has-background-primary-lighter p-xs">
      <div className="w-4">
        <Pagination
          activePage={offset / limit + 1}
          itemsCountPerPage={limit}
          totalItemsCount={total}
          pageRangeDisplayed={5}
          onChange={(page) => setOffset((page - 1) * limit)}
          linkClass="has-text-primary-ter has-text-weight-normal"
          activeLinkClass="has-text-primary-ter has-text-weight-medium"
        />
      </div>
    </div>
  );
};

export const Delete = ({ mediation, onConfirm, onCancel, m: { w } }) => (
  <div className="w-4 has-background-white rad-5">
    <div className="has-background-primary p-md has-text-white">
      {w.med.deleteConfirmation}
    </div>
    <div className="p-md">
      <div className="flex row space-between wrap">
        <Button onClick={onCancel} color="danger">
          {w.generic.cancel}
        </Button>
        <Button onClick={onConfirm}>{w.generic.validate}</Button>
      </div>
    </div>
  </div>
);

const Counter = styled.div`
  margin-left: -20px;
  margin-right: 40px;
  width: 20px;
`;

const SearchBarContainer = styled.div`
  margin-right: 20px;
`;
