import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';
import InputRadio from '../../../../components/parameters/InputRadio';
import InputFile from '../../../../components/parameters/InputFile';
import StyledLabel from '../../../../components/parameters/StyledLabel';
import StyledUpload from '../../../../components/parameters/StyledUpload';
import Upload from '../../../../_assets/icon/Upload';
import StyledInput from '../../../../components/parameters/StyledInput';
import StyledBigTitle from '../../../../components/parameters/StyledTitle';
import CircleImg from '../../../../components/parameters/CircleImg';
import StyledImage from '../../../../components/parameters/StyledImage';
import { Cross } from '@styled-icons/entypo/Cross';
import { RightArrow } from '@styled-icons/boxicons-solid/RightArrow';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';
import templateCatchphrase from './templates/Catchphrase';
import LanguagesDropdown from "../../../../components/LanguagesDropdown";

const Bubble = ({
  w,
  m,
  wWebchat,
  state,
  dispatch,
  handleImage,
  handleImageHover,
}) => {
  const [hideFirstContent, setHideFirstContent] = useState(true);
  const [hideSecondContent, setHideSecondContent] = useState(true);
  const [switchLangTitle, setSwitchLangTitle] = useState(m.bot.default_language);
  const [switchLangSubtitle, setSwitchLangSubtitle] = useState(m.bot.default_language);

  return (
    <Wrapper>
      <StyledBigTitle>
        {wWebchat.templateChoice}
        <span onClick={() => setHideFirstContent(!hideFirstContent)}>
          {hideFirstContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideFirstContent && (
        <>
          <WrapperDiv>
            <StyledLabel width={90} noMargin flex radio>
              <InputRadio
                type="radio"
                name="online"
                value="template1"
                checked={
                  JSON.stringify(state.template) ===
                  JSON.stringify(templateCatchphrase('template1'))
                }
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplate',
                    payload: templateCatchphrase(e.target.value),
                  });
                }}
              />
              <WrapperSpace>
                <StyledDivWrapper>
                  <StyledWrapper>
                    <StyledImg src={state.widgetIcon} alt="" />
                    <div>
                      <StyledTitle>{wWebchat.speakWith}</StyledTitle>
                      <StyledText>{wWebchat.moreInfo}</StyledText>
                    </div>
                  </StyledWrapper>
                  <StyledText mt={'1rem'}>{wWebchat.desktopView}</StyledText>
                </StyledDivWrapper>
                <StyledDivWrapper>
                  <StyledWrapperResponsive noColumn >
                    <StyledImgResponsive noColumn src={state.widgetIcon} alt="" />
                  </StyledWrapperResponsive>
                  <StyledText mt={'1rem'}>{wWebchat.mobileView}</StyledText>
                </StyledDivWrapper>
              </WrapperSpace>
            </StyledLabel>
          </WrapperDiv>

          <WrapperDiv>
            <StyledLabel width={90} noMargin flex radio>
              <InputRadio
                type="radio"
                name="online"
                value="template2"
                checked={
                  JSON.stringify(state.template) ===
                  JSON.stringify(templateCatchphrase('template2'))
                }
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplate',
                    payload: templateCatchphrase(e.target.value),
                  });
                }}
              />
              <WrapperSpace>
                <StyledDivWrapper>
                  <StyledWrapper entryReduced>
                    <StyledImg src={state.widgetIcon} alt="" />
                    <div>
                      <StyledTitle>{wWebchat.speakWith}</StyledTitle>
                      <StyledText>{wWebchat.moreInfo}</StyledText>
                    </div>
                  </StyledWrapper>
                  <StyledText mt={'1rem'}>{wWebchat.desktopView}</StyledText>
                </StyledDivWrapper>
                <StyledDivWrapper>
                  <StyledWrapperResponsive>
                    <StyledImgResponsive src={state.widgetIcon} alt="" />
                  </StyledWrapperResponsive>
                  <StyledText mt={'1rem'}>{wWebchat.mobileView}</StyledText>
                </StyledDivWrapper>
              </WrapperSpace>
            </StyledLabel>
          </WrapperDiv>

          <WrapperDiv alignItem={'stretch'}>
            <StyledLabel width={90} noMargin flex radio>
              <InputRadio
                type="radio"
                name="online"
                value="template3"
                checked={
                  JSON.stringify(state.template) ===
                  JSON.stringify(templateCatchphrase('template3'))
                }
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplate',
                    payload: templateCatchphrase(e.target.value),
                  });
                  dispatch({
                    type: 'setRightMobile',
                    payload: '25px',
                  });
                  dispatch({
                    type: 'setBottomMobile',
                    payload: '106px',
                  });
                }}
              />
              <WrapperSpace>
                <StyledDivWrapper noColumn>
                  <StyledWrapper afterContent>
                    <div>
                      <StyledTitle>{wWebchat.speakWith}</StyledTitle>
                      <StyledText>{wWebchat.moreInfo}</StyledText>
                    </div>
                  </StyledWrapper>
                  <StyledImg src={state.widgetIcon} relativeImg noColumn />
                  <StyledText align={'center'}>{wWebchat.desktopView}</StyledText>
                </StyledDivWrapper>
                <StyledDivWrapper justifyContent={'center'}>
                  <StyledWrapperResponsive noColumn >
                    <StyledImgResponsive noColumn src={state.widgetIcon} alt="" />
                  </StyledWrapperResponsive>
                  <StyledText mt={'1rem'}>{wWebchat.mobileView}</StyledText>
                </StyledDivWrapper>
              </WrapperSpace>
            </StyledLabel>
          </WrapperDiv>

          <WrapperDiv>
            <StyledLabel width={90} noMargin flex radio>
              <InputRadio
                type="radio"
                name="online"
                value="template4"
                checked={
                  JSON.stringify(state.template) ===
                  JSON.stringify(templateCatchphrase('template4'))
                }
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplate',
                    payload: templateCatchphrase(e.target.value),
                  });
                }}
              />
              <WrapperSpace>
                <StyledDivWrapper noColumn>
                  <StyledWrapper>
                    <div>
                      <StyledTitle>{wWebchat.speakWith}</StyledTitle>
                      <StyledText>{wWebchat.moreInfo}</StyledText>
                    </div>
                  </StyledWrapper>
                  <StyledImg src={state.widgetIcon} relativeImg noColumn />
                  <StyledText align={'center'}>{wWebchat.desktopView}</StyledText>
                </StyledDivWrapper>
                <StyledDivWrapper justifyContent={'center'}>
                  <StyledWrapperResponsive noColumn>
                    <StyledImgResponsive noColumn src={state.widgetIcon} alt="" />
                  </StyledWrapperResponsive>
                  <StyledText mt={'1rem'}>{wWebchat.mobileView}</StyledText>
                </StyledDivWrapper>
              </WrapperSpace>
            </StyledLabel>
          </WrapperDiv>

          <WrapperDiv>
            <StyledLabel width={90} noMargin flex radio>
              <InputRadio
                type="radio"
                name="online"
                value="template5"
                checked={
                  JSON.stringify(state.template) ===
                  JSON.stringify(templateCatchphrase('template5'))
                }
                onChange={(e) => {
                  dispatch({
                    type: 'setTemplate',
                    payload: templateCatchphrase(e.target.value),
                  });
                  dispatch({
                    type: 'setRightMobile',
                    payload: '25px',
                  });
                  dispatch({
                    type: 'setBottomMobile',
                    payload: '87px',
                  });
                }}
              />
              <WrapperSpace>
                <StyledDivWrapper noColumn>
                  <StyledWrapper borderRadius radius={'50px'}>
                    <div>
                      <StyledTitle>{wWebchat.speakWith}</StyledTitle>
                      <StyledText>{wWebchat.moreInfo}</StyledText>
                    </div>
                  </StyledWrapper>
                  <StyledImg src={state.widgetIcon} relativeImg noColumn />
                  <StyledText align={'center'}>{wWebchat.desktopView}</StyledText>
                </StyledDivWrapper>
                <StyledDivWrapper justifyContent={'center'}>
                  <StyledWrapperResponsive noColumn >
                    <StyledImgResponsive squaredCorner noColumn src={state.widgetIcon} alt="" />
                  </StyledWrapperResponsive>
                  <StyledText mt={'1rem'}>{wWebchat.mobileView}</StyledText>
                </StyledDivWrapper>
              </WrapperSpace>
            </StyledLabel>
          </WrapperDiv>
        </>
      )}
      <StyledBigTitle>
        {wWebchat.templateConfig}
        <span onClick={() => setHideSecondContent(!hideSecondContent)}>
          {hideSecondContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideSecondContent && (
        <>
          <WrapperDiv mr={'.8rem 0 0 0'}>
            <StyledLabel width={40} mr>
              {m.w.generic.title}
              <WrapperInput>
                <StyledInput
                  type="text"
                  value={
                    state.title && state.title[switchLangTitle] ? state.title[switchLangTitle] : ''
                  }
                  onChange={(e) => {
                    dispatch({ type: 'setTitle', payload: {
                      ...state.title,
                        [switchLangTitle]: e.target.value
                      }
                    })
                  }}
                />
                <span>
                <LanguagesDropdown
                    languages={m.bot_lang}
                    selectedLanguage={switchLangTitle}
                    onLanguageSelected={(language) => setSwitchLangTitle(language)}
                />
              </span>
              </WrapperInput>
            </StyledLabel>
            <StyledLabel width={40}>
              {wWebchat.subtitle}
              <WrapperInput>
                <StyledInput
                  type="text"
                  value={
                    state.subtitleWidget && state.subtitleWidget[switchLangSubtitle] ? state.subtitleWidget[switchLangSubtitle] : ''
                  }
                  onChange={(e) => {
                    dispatch({ type: 'setSubtitleWidget', payload: {
                        ...state.subtitleWidget,
                        [switchLangSubtitle]: e.target.value
                      }
                    })
                  }}
                />
                <span>
                <LanguagesDropdown
                    languages={m.bot_lang}
                    selectedLanguage={switchLangSubtitle}
                    onLanguageSelected={(language) => setSwitchLangSubtitle(language)}
                />
              </span>
              </WrapperInput>
            </StyledLabel>
          </WrapperDiv>
          <WrapperDiv mr={'.8rem 0'} alignItem={'flex-start'}>
            <StyledDivWrapper
              width={'50%'}
              alignItem
              justifyContent={'space-around'}
            >
              <SubititleLight mb={'1rem'}>{wWebchat.iconBubble}</SubititleLight>
              {state.widgetIcon ? (
                <StyledDivWrapper alignItem={'flex-start'}>
                  <CircleImg width={'80'} height={'80'}>
                    <StyledImage width={'80'} src={state.widgetIcon} alt="" />
                  </CircleImg>
                  <ButtonWrapper>
                    <span>{wWebchat.changeIcon}</span>
                    <InputUpload
                      type="file"
                      onChange={(e) => {
                        handleImage(e);
                      }}
                    />
                  </ButtonWrapper>
                </StyledDivWrapper>
              ) : (
                <>
                  <StyledUpload noMargin width={'75'}>
                    <Upload />
                    <div>
                      <p>{w.download}</p>
                      <p>{w.downloadSize})</p>
                    </div>
                    <InputFile
                      type="file"
                      onChange={(e) => {
                        handleImage(e);
                      }}
                    />
                  </StyledUpload>
                  <StyledText width={'75%'}>{w.downloadFormat}</StyledText>
                </>
              )}
            </StyledDivWrapper>
            <StyledDivWrapper
              width={'50%'}
              alignItem
              justifyContent={'space-around'}
            >
              <SubititleLight mb={'1rem'}>{wWebchat.iconHover}</SubititleLight>
              {state.widgetHover ? (
                <StyledDivWrapper alignItem={'flex-start'}>
                  <CircleImg width={'80'} height={'80'}>
                    <StyledImage width={'80'} src={state.widgetHover} alt="" />
                  </CircleImg>
                  <ButtonWrapper>
                    <span>{wWebchat.changeIcon}</span>
                    <InputUpload
                      type="file"
                      onChange={(e) => {
                        handleImageHover(e);
                      }}
                    />
                  </ButtonWrapper>
                </StyledDivWrapper>
              ) : (
                <>
                  <StyledUpload noMargin width={'75'}>
                    <Upload />
                    <div>
                      <p>{w.download}</p>
                      <p>{w.downloadSize})</p>
                    </div>
                    <InputFile
                      type="file"
                      onChange={(e) => {
                        handleImageHover(e);
                      }}
                    />
                  </StyledUpload>
                  <StyledText width={'75%'}>{w.downloadFormat}</StyledText>
                </>
              )}
            </StyledDivWrapper>
          </WrapperDiv>

          <WrapperDiv>
            <StyledDivWrapper width={'50%'} alignItem>
              <SubititleLight mb={'1rem'}>
                {wWebchat.colorBubble}
              </SubititleLight>
              <ChromePicker
                color={state.colorBubble}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setColorBubble', payload: val.hex });
                }}
              />
            </StyledDivWrapper>
            <StyledDivWrapper width={'50%'} alignItem>
              <SubititleLight mb={'1rem'}>
                {wWebchat.colorFontBubble}
              </SubititleLight>
              <ChromePicker
                color={state.colorFontBubble}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setColorFontBubble', payload: val.hex });
                }}
              />
            </StyledDivWrapper>
          </WrapperDiv>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WrapperDiv = styled.div`
  display: flex;
  width: 100%;
  margin: ${({ mr }) => (mr ? mr : '1rem 0')};
  align-items: ${({ alignItem }) => alignItem};
`;

const StyledText = styled.p`
  font-weight: 300;
  font-size: 0.8em;
  margin-top: ${({ mt }) => mt};
  width: ${({ width }) => width};
  text-align: ${({ align }) => align === 'center' && 'center'};
`;

const StyledImg = styled.img`
  width: ${({ noColumn }) => (noColumn ? '60px' : '48px')};
  height: ${({ noColumn }) => noColumn ? '60px' : '48px'};
  margin-right: 1rem;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: ${({ noColumn }) =>
    !noColumn
      ? '-1px 2px 15px #eee'
      : '0px 0px 10px rgba(0, 0, 0, 0.17)'};
  position: ${({ relativeImg }) => relativeImg && 'relative'};
  top: ${({ relativeImg }) => relativeImg && '44px'};
  right: ${({ relativeImg }) => relativeImg && '16px'};
  margin-top: ${({ noColumn }) => noColumn && '5rem'};
`;

const StyledImgResponsive = styled.img`
  height: ${({ noColumn }) => noColumn ? '60px' : '42px'};
  width: ${({ noColumn }) => noColumn ? '60px' : '42px'};
  border-radius: 30px;
  border-radius: ${({ squaredCorner }) => squaredCorner && '30px 30px 2px 30px'};

  margin: auto;

  object-fit: cover;
  box-shadow: ${({ noColumn }) =>
  !noColumn
    ? '-1px 2px 15px #eee'
    : '0px 0px 10px rgba(0, 0, 0, 0.17)'};
`

const StyledDivWrapper = styled.div`
  display: flex;
  flex-direction: ${({ noColumn }) => !noColumn && 'column'};

  display: ${({ noColumn }) => noColumn && 'grid'};
  grid-template-columns: ${({ noColumn }) => noColumn && '4fr 1fr'};
  grid-template-rows: ${({ noColumn }) => noColumn && '4fr 1fr'};

  align-items: ${({ alignItem }) => alignItem || (!alignItem && 'center')};
  margin-right: ${({ mr }) => mr};
  width: ${({ width }) => width};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const StyledWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  min-width: ${({ entryReduced }) => (entryReduced ? '200px' : '260px')};
  border: none;
  border-radius: ${({ entryReduced, radius }) =>
    radius ? radius : entryReduced ? '25px 25px 0 0' : '25px'};
  filter: drop-shadow(-1px 2px 15px #eee);
  background: #fff;
  align-items: center;
  justify-content: center;
  padding: 0.9rem 1rem;
  margin-right: 1rem;
  height: 69px;
  border-bottom-right-radius: ${({ borderRadius }) => borderRadius && '1px'};

  &::after {
    content: '';
    display: ${({ afterContent }) => (afterContent ? 'block' : 'none')};
    position: absolute;
    bottom: -20px;
    right: 30px;
    width: 0;
    height: 0;
    border-bottom: 20px solid transparent;
    border-right: 27px solid #fff;
  }
`;

const StyledWrapperResponsive = styled.div`
  filter: drop-shadow(-1px 2px 15px #eee);
  height: 60px;
  width: 60px;
  border-radius: ${({ noColumn }) => noColumn ? '30px' : '10px 10px 0px 0px'};

  box-shadow: ${({ noColumn }) => noColumn ? '' : '0px 0px 10px rgba(0, 0, 0, 0.17)'};

  display: flex;
  justitfy-content: ${({ noColumn }) => noColumn ? 'center' : 'center'};
`

const StyledTitle = styled.h1`
  font-size: 1em;
  width: 100%;
  margin: 0.2rem 1rem 0.2rem 0;
`;

const StyledCross = styled(Cross)`
  position: absolute;
  top: ${({ top }) => top || '7px'};
  right: ${({ right }) => right || '7px'};
  z-index: 2147483648;
`;

const SubititleLight = styled.h3`
  font-size: 1.1.rem;
  margin-bottom: ${({ mb }) => mb};
`;

const WrapperSpace = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: 150px;
  text-align: center;
  margin-top: 1rem;
  transition: 0.3s;
  cursor: pointer;
  transition: opacity 0.3s;

  > span {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.white};
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 12px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const InputUpload = styled.input`
  display: inline-block;
  position: absolute;
  z-index: 1000000;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const WrapperInput = styled.div`
  position: relative;
  width: auto;

  > span {
    position: absolute;
    top: 10px;
    right: 0px;
    cursor: pointer;
  }

  > input {
    padding-right: 50px;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
  }
`;

export default Bubble;
