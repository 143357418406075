import React from 'react';
import Title from './information/Title';
import Place from './information/Place';
import Pricing from './information/Pricing';
import Image from './information/Image';
import Type from './information/Type';
import Mark, { _formatMark } from '../../components/Mark';

const Information = (props) => {
  const {
    m: {
      w,
      user: { organization },
    },
    event,
    bot,
    fct,
  } = props;
  return (
    <div className="p-md has-text-primary-ter w-50p overflow-auto">
      <h3 className="p-b-xs m-b-xs border-b-white-ter">
        {w.subsections.eventInformation}
      </h3>
      <Title {...props} />
      <Place {...props} />
      <Pricing {...props} />
      <Image {...props} />
      {bot.types.length > 1 && <Type {...props} />}
      <Mark
        selected={_formatMark(event.themes)}
        name="themes"
        query="GET_THEMES"
        mutation={
          organization.id === 1
            ? { create: 'CREATE_THEME', set: 'SET_THEME_TRANSLATION' }
            : {}
        }
        variables={{ types: bot.types.map((t) => t.id) }}
        title={w.event.theme}
        onSelect={fct.selectMark}
        {...props}
      />
      <Mark
        selected={_formatMark(event.tags)}
        name="tags"
        query="GET_TAGS"
        mutation={{ create: 'CREATE_TAG', set: 'SET_TAG_TRANSLATION' }}
        title={w.event.tag}
        onSelect={fct.selectMark}
        {...props}
      />
      <Mark
        selected={_formatMark(event.publics)}
        name="publics"
        query="GET_PUBLICS"
        mutation={
          organization.id === 1
            ? { create: 'CREATE_PUBLIC', set: 'SET_PUBLIC_TRANSLATION' }
            : {}
        }
        title={w.event.public}
        onSelect={fct.selectMark}
        {...props}
      />
    </div>
  );
};

export default Information;
