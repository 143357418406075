import React, { Component } from 'react';
import { withMain } from '../../hoc/main';
import FeaturesPanel from './checklistMain/FeaturesPanel';
import FeaturesAnalytics from './checklistMain/FeaturesAnalytics';
import ScenarioChatbot from './checklistMain/ScenarioChatbot';
import SpecificityScenario from './checklistMain/SpecificityScenario';
//import PrincipalPlace 											from './checklistMain/PrincipalPlace'
import Frequency from './checklistMain/Frequency';
import Tracker from './checklistMain/Tracker';
import Languages from './checklistMain/Languages';
import { CREATE_FEATURE, DELETE_FEATURE } from '../../_api/checklist';
//import { GET_BOT_HAS_PLACES, GET_PLACE, UPDATE_BOT_HAS_PLACE } 	from '../../_api/place';
import { GET_BOT } from '../../_api/bot';

class ChecklistMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idFeatures: null,
      open: { id: 2, bool: false },
      price: { id: 3, bool: false },
      itinerary: { id: 5, bool: false },
      prog: { id: 1, bool: false },
      messaging: { id: 9, bool: false },
      mediation: { id: 10, bool: false },
      targeting: { id: 28, bool: false },
      nlp: { id: 8, bool: false },
      scenario: { id: 7, bool: false },
      adv_scenario: { id: 15, bool: false },
      tth: { id: 14, bool: false },
      fr: { id: 12, bool: false },
      en: { id: 11, bool: false },
      // place: {
      // 	name: this.props.user.m.bot.places[0].name ? this.props.user.m.bot.places[0].name : null,
      // 	address: this.props.user.m.bot.places[0].address ? this.props.user.m.bot.places[0].address : null,
      // 	short_address: this.props.user.m.bot.places[0].short_address ? this.props.user.m.bot.places[0].short_address : null,
      // 	id: this.props.user.m.bot.places[0].id ? this.props.user.m.bot.places[0].id : null,
      // 	lat: this.props.user.m.bot.places[0].lat ? this.props.user.m.bot.places[0].lat : null,
      // 	long: this.props.user.m.bot.places[0].long ? this.props.user.m.bot.places[0].long : null,
      // 	opening_hours: this.props.user.m.bot.places[0].opening_hours ? this.props.user.m.bot.places[0].opening_hours : null,
      // 	postcode: this.props.user.m.bot.places[0].postcode ? this.props.user.m.bot.places[0].postcode : null,
      // 	__typename: this.props.user.m.bot.places[0].__typename ? this.props.user.m.bot.places[0].__typename : null,
      // },
      tracker: null,
      languageBot: {
        fr: null,
        en: null,
      },
      fct: {
        deleteFeatures: this.deleteFeatures.bind(this),
        createFeatures: this.createFeatures.bind(this),
        includes: this.includes.bind(this),

        // setPlace: this.setPlace.bind(this),
        // updateBotHasPlace: this.updateBotHasPlace.bind(this),
        // getPlace: this.getPlace.bind(this),
        // getBotHasPlaces: this.getBotHasPlaces.bind(this),
      },
    };
  }

  includes(array) {
    array.includes(2)
      ? this.setState({ open: { ...this.state.open, bool: true } })
      : this.setState({ open: { ...this.state.open, bool: false } });
    array.includes(3)
      ? this.setState({ price: { ...this.state.price, bool: true } })
      : this.setState({ price: { ...this.state.price, bool: false } });
    array.includes(5)
      ? this.setState({ itinerary: { ...this.state.itinerary, bool: true } })
      : this.setState({ itinerary: { ...this.state.itinerary, bool: false } });
    array.includes(1)
      ? this.setState({ prog: { ...this.state.prog, bool: true } })
      : this.setState({ prog: { ...this.state.prog, bool: false } });
    array.includes(9)
      ? this.setState({ messaging: { ...this.state.messaging, bool: true } })
      : this.setState({ messaging: { ...this.state.messaging, bool: false } });
    array.includes(10)
      ? this.setState({ mediation: { ...this.state.mediation, bool: true } })
      : this.setState({ mediation: { ...this.state.mediation, bool: false } });
    array.includes(8)
      ? this.setState({ nlp: { ...this.state.nlp, bool: true } })
      : this.setState({ nlp: { ...this.state.nlp, bool: false } });
    array.includes(7)
      ? this.setState({ scenario: { ...this.state.scenario, bool: true } })
      : this.setState({ scenario: { ...this.state.scenario, bool: false } });
    array.includes(15)
      ? this.setState({
          adv_scenario: { ...this.state.adv_scenario, bool: true },
        })
      : this.setState({
          adv_scenario: { ...this.state.adv_scenario, bool: false },
        });
    array.includes(14)
      ? this.setState({ tth: { ...this.state.tth, bool: true } })
      : this.setState({ tth: { ...this.state.tth, bool: false } });
    array.includes(12)
      ? this.setState({ fr: { ...this.state.fr, bool: true } })
      : this.setState({ fr: { ...this.state.fr, bool: false } });
    array.includes(11)
      ? this.setState({ en: { ...this.state.en, bool: true } })
      : this.setState({ en: { ...this.state.en, bool: false } });
    array.includes(32)
      ? this.setState({ targeting: { ...this.state.targeting, bool: true } })
      : this.setState({ targeting: { ...this.state.targeting, bool: false } });
  }

  async componentDidMount() {
    const {
      m: { bot },
    } = this.props;
    const array = [];
    if (bot.features) {
      bot.features.forEach((e) => {
        array.push(e.id);
      });
    }
    await this.getBot(array);
    await this.createFeatures(array);
    this.includes(array);
  }

  getBot(array) {
    const {
      m: { client, bot },
    } = this.props;
    client
      .query({
        query: GET_BOT,
        variables: {
          id: bot.id,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        this.setState({
          idFeatures: array.sort(),
          tracker: res.data.getBot.config.tracker.embeded,
          languageBot: {
            fr: res.data.getBot.config.languages.fr,
            en: res.data.getBot.config.languages.en,
          },
        });
        this.includes(array);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // getPlace() {
  // 	const { m: { client } } = this.props;
  // 	const { place: { place_id }} = this.state

  // }

  // getBotHasPlaces() {
  // 	const { m: { client, bot } } = this.props;
  // 	client.query({
  // 		query: GET_BOT_HAS_PLACES,
  // 		variables: {
  // 			bot_id: bot.id
  // 		},
  // 		fetchPolicy: "network-only"
  // 		}).then(res => {
  // 			if (res) {
  // 				client.query({
  // 					query: GET_PLACE,
  // 					variables: {
  // 						id: res.data.getBotHasPlaces[0].place_id
  // 					},
  // 					fetchPolicy: "network-only"
  // 					}).then(res => {
  // 						if (res.data.getPlace) {
  // 							let id = res.data.getPlace.id
  // 							let name = res.data.getPlace.name
  // 							let address = res.data.getPlace.address
  // 							let short_address = res.data.getPlace.short_address
  // 							let lat = res.data.getPlace.lat
  // 							let long = res.data.getPlace.long
  // 							let opening_hours = res.data.getPlace.opening_hours
  // 							let postcode = res.data.getPlace.postcode
  // 							let __typename = res.data.getPlace.__typename
  // 							this.setState({ place:
  // 												{ 	name: name,
  // 													address: address,
  // 													short_address: short_address,
  // 													place_id: id,
  // 													lat: lat,
  // 													long: long,
  // 													opening_hours: opening_hours,
  // 													postcode: postcode,
  // 													__typename: __typename
  // 												}
  // 										})
  // 						}

  // 					}).catch(err => {
  // 						console.log(err);
  // 					})
  // 			}
  // 			return;
  // 		}).catch(err => {
  // 			console.log(err);
  // 		})

  // }

  // updateBotHasPlace() {
  // 	const { place : { name, address, short_address, place_id } } = this.state
  // 	const { m: { client, bot } } = this.props;
  // 	client.mutate({
  // 		mutation: UPDATE_BOT_HAS_PLACE,
  // 		variables: {
  // 			bot_id: bot.id,
  // 			place_id: place_id,
  // 			itinerary: false,
  // 		},
  // 	}).then(res => {
  // 		console.log(res)
  // 		return;
  // 	}).catch(err => {
  // 		console.log(err);
  // 	})
  // }

  // async setPlace(place) {
  // 	const newState = Object.assign({}, this.state);
  // 	newState.place.name = place.name;
  // 	newState.place.address = place.address;
  // 	newState.place.short_address = place.short_address;
  // 	newState.place.place_id = place.id;
  // 	this.setState({
  // 	  ...newState
  // 	});

  // 	await this.updateBotHasPlace()
  // 	await this.getBotHasPlaces()
  // }

  deleteFeatures(id) {
    const tmp = this.state.idFeatures.sort();
    const idFeatures = this.state.idFeatures.sort();
    const {
      m: { client, bot },
    } = this.props;
    client.mutate({
      mutation: DELETE_FEATURE,
      variables: {
        bot_id: bot.id,
        feature_id: id,
      },
      refetchQueries: [
        {
          query: GET_BOT,
          fetchPolicy: 'network-only',
        },
      ],
    });

    for (var i = 0; idFeatures.length > i; i++) {
      for (var y = 0; id.length > y; y++) {
        if (idFeatures[i] === id[y]) {
          tmp.splice(i, 1);
        }
      }
    }
    const array = tmp;
    this.includes(array);
  }

  createFeatures(id) {
    const { idFeatures } = this.state;
    if (idFeatures) {
      var tmp = this.state.idFeatures.sort();
    }

    const {
      m: { client, bot },
    } = this.props;
    client
      .mutate({
        mutation: CREATE_FEATURE,
        variables: {
          bot_id: bot.id,
          feature_id: id,
        },
        refetchQueries: [
          {
            query: GET_BOT,
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        if (idFeatures) {
          for (var y = 0; id.length > y; y++) {
            if (!this.state.idFeatures.includes(id[y])) {
              tmp.push(id[y]);
            }
          }
          const array = tmp;
          this.includes(array);
        } else {
          const array = id;
          this.setState({ ...this.state, idFeatures: array.sort() });
          this.includes(array);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const {
      m: { bot, client },
      view,
      organization_id,
      lang,
    } = this.props;
    //const { fct, place } = this.state

    if (view === 0) {
      return (
        <div className="p-md overflow-auto w-100">
          <FeaturesAnalytics client={client} bot={bot} />
        </div>
      );
    } else if (view === 1) {
      return (
        <div className="p-md overflow-auto w-100">
          <FeaturesPanel
            state={this.state}
            lang={lang}
            bot_id={bot.id}
            organization_id={organization_id}
          />
        </div>
      );
    } else if (view === 2) {
      return (
        <div className="p-md overflow-auto w-100">
          <ScenarioChatbot lang={lang} w={this.props.w}/>
        </div>
      );
    } else if (view === 3) {
      return (
        <div className="p-md overflow-auto w-100">
          <SpecificityScenario state={this.state} lang={lang} />
        </div>
      );
    } else if (view === 4) {
      return (
        <div className="p-md overflow-auto w-100">
          {/* <PrincipalPlace context={place} bot_id={bot.id} lang={lang} fct={fct} props={this.props}/> */}
        </div>
      );
    } else if (view === 5) {
      return (
        <div className="p-md overflow-auto w-100">
          <Frequency lang={lang} />
        </div>
      );
    } else if (view === 6) {
      return (
        <div className="p-md overflow-auto w-100">
          <Tracker state={this.state} bot_id={bot.id} lang={lang} />
        </div>
      );
    } else if (view === 7) {
      return (
        <div className="p-md overflow-auto w-100">
          <Languages state={this.state} bot_id={bot.id} lang={lang} />
        </div>
      );
    } else return null;
  }
}

export default withMain(ChecklistMain);
