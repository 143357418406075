import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import ContainerScroll from '../../../../../components/parameters/ContainerScroll';
import StyledBigTitle from '../../../../../components/parameters/StyledTitle';
import mail from '../assets/images/mail.png';
const PreviewMail = ({ state, wWebchat }) => {
  return (
    <ContainerScroll width={'40'} preview border>
      <StyledBigTitle textAlign={'center'}>
        {wWebchat.previewMail}
      </StyledBigTitle>

      <WebchatContainer borderRadius={state.borderRadius}>
        <GlobalStyle
          fontFamilly={state.fontSelected}
          formatFont={state.formatFont}
          fontUrl={state.urlFont}
        />
        <WrapperMail>
          <StyledTitle>
            <span>{wWebchat.previewObject}</span>
          </StyledTitle>
          <StyledContent>
            <img src={mail} width="65" />
            <p>{wWebchat.receivedMessage}</p>
          </StyledContent>
          <div>
            <StyledText>{wWebchat.contact}</StyledText>
          </div>
          <StyledBox>
            <p>{wWebchat.messageDate}</p>
            <StyledMessage>{wWebchat.messagePreview}</StyledMessage>
          </StyledBox>
          <div>
            <StyledText>{wWebchat.connectToAnswer}</StyledText>
          </div>
          <StyledButton>{wWebchat.login} &#62;</StyledButton>
        </WrapperMail>
      </WebchatContainer>
    </ContainerScroll>
  );
};

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: ${({ fontFamilly }) => fontFamilly};
    font-style: normal;
    font-weight: 400;
    src: ${({ fontUrl, formatFont }) =>
      `url('${fontUrl}') format('${formatFont}')`};
    font-display: swap;
  };
`;

const WebchatContainer = styled.div`
  display: flex;
  font-size: 0.8em;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  height: 530px;
  position: relative;
  border: 1px solid lightgray;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: ${({ borderRadius }) => borderRadius}px;
`;

const WrapperMail = styled.div`
  padding: 2rem;
`;

const StyledTitle = styled.h1`
  font-size: 0.8em;
  color: #111;
`;

const StyledContent = styled.div`
  margin: 3rem auto 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  > img {
    margin: 0 auto;
  }
  > p {
    color: #d27270;
    text-align: center;
  }
`;

const StyledBox = styled.div`
  background: #dadbf1;
  border-radius: 3px;
  padding: 1rem;
`;

const StyledButton = styled.div`
  background: #2d307b;
  padding: 0.4rem 0.5rem;
  font-weight: 600;
  border-radius: 8px;
  color: #fff;
  width: max-content;
  margin: 1rem auto 0;
`;

const StyledText = styled.p`
  color: #111;
  font-size: 0.6rem;
  margin: 2rem 0 1rem 0;
`;

const StyledMessage = styled.p`
  font-weight: bold;
  margin-top: 0.8rem;
`;

export default PreviewMail;
