import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import warningIcon from '../../../_assets/icon/warningIcon.png';
import Answers from '../answers/Answers';
import Description from './Description';
import { Topic as TopicTypes } from '../../../types/qa/topics';
import { QAContext } from '../context/QAContext';
import { Api } from '../../../api/graphql/hooks/useTopics';
interface Props {
  topic: TopicTypes;
  api: Api;
  w: any;
}

const EditTopicContent = (props: Props) => {
  const { state } = useContext(QAContext);
  const { w } = props;
  const jump = false;
  const answersDesactivad = [];
  return (
    <Container>
      <WrapperDescription>
        <div className="flex row space-between">
          <span className="m-b-md is-size-6 is-underlined">{w.description}</span>
        </div>
        <Description
          topic={props.topic}
          handleDescription={(value) =>
            props.api.updateTopic(props.topic.id, 'fr', 'description', value)
          }
        />
      </WrapperDescription>
      {state.isMultisite && !state.places.length ? (
        <WrapperError>
          <WrapperContent>
            <StyledIcon>
              <img src={warningIcon} width="30px" />
            </StyledIcon>
            <div>
              <StyledTitle>{w.missing_place}</StyledTitle>
              <StyledContent>
                {w.missing_place_description}
              </StyledContent>
            </div>
          </WrapperContent>
        </WrapperError>
      ) : (
        <WrapperAnswers>
          {!jump && (
            <div className="p-xs flex column grow-1 basis-0 p-b-lg">
              {answersDesactivad.length > 0 && (
                <div className="flex flex-end">
                  <button className="is-outlined is-borderless">
                    <i className="fas fa-folder" />
                    {w.getHistory}
                  </button>
                </div>
              )}
              {props.topic.link === null ? (
                <>
                  <Answers answers={props.topic.answers} api={props.api} w={w} />
                  <div className="flex p-sm center">
                    <Button
                      onClick={() => {
                        if (state.currentTopicId)
                          props.api.createTopicAnswer(
                            state.currentTopicId,
                            '[]',
                            'fr'
                          );
                      }}
                      className="shrink"
                    >
                      {w.add_an_answer}
                    </Button>
                  </div>
                </>
              ) : (
                <div>
                  {props.topic.link.scenario_content_id && (
                    <p>
                      {w.direct_link_to_scenario}{' '}
                      {props.topic.link.scenario_content_id}
                    </p>
                  )}
                  {props.topic.link.feature_slug && (
                    <p>
                      {w.direct_link_to_feature}{' '}
                      {props.topic.link.feature_slug}
                    </p>
                  )}
                  <Button
                    onClick={() =>
                      props.topic.link &&
                      props.api.deleteTopicLink(props.topic.link.id)
                    }
                  >
                    {w.unlink_qa}
                  </Button>
                </div>
              )}
            </div>
          )}
        </WrapperAnswers>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  color: #2d307b;
  width: 100%;
  border-bottom: 1px solid #ededf7;
`;

const WrapperDescription = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ededf7;
  width: 50%;
  padding: 0.25rem;
`;

const WrapperAnswers = styled.div`
  display: flex;
  width: 50%;
`;

const WrapperError = styled.div`
  display: flex;
  align-items: center;
  background: #ececec;
  width: 50%;
  padding: 2rem 1rem;
  overflow: hidden;
  white-space: initial;
`;

const StyledTitle = styled.h3`
  color: #d27270;
  margin-bottom: 0.8rem;
  font-weight: 600;
`;

const StyledContent = styled.p`
  color: #2d307b;
  line-height: 20px;
`;

const WrapperContent = styled.div`
  display: flex;
  width: 100%;
`;

const StyledIcon = styled.div`
  margin-right: 1.2rem;
`;

const Button = styled.div`
  display: flex;
  padding: 0.25em 1em;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  border-radius: 3px;
  border: 2px solid #4246b3;
  background-color: #5054be;
  color: white;
`;

export default EditTopicContent;
