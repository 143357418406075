import React from 'react';
import styled from 'styled-components';

const Toggle = ({ toggle, handleClick, content }) => {
  return (
    <WrapperDiv>
      <WrapperLabelToggle>
        <StyledToggle>
          <ToggleInput
            toggle={toggle}
            type="checkbox"
            name="check"
            value={toggle}
            onClick={() => {
              handleClick();
            }}
          />
          <Indicator toggle={toggle}></Indicator>
        </StyledToggle>
        <ToggleText>{content}</ToggleText>
      </WrapperLabelToggle>
    </WrapperDiv>
  );
};

const WrapperDiv = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ mr }) => (mr ? mr : '2rem 0')};
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent && 'space-around'};
  width: ${({ width }) => width};
`;

const WrapperLabelToggle = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const ToggleText = styled.div`
  margin-left: 16px;
  min-width: 230px;
`;

const StyledToggle = styled.div`
  isolation: isolate;
  position: relative;
  height: 20px;
  width: 40px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6,
    4px 4px 4px 0px #d1d9e6 inset, -4px -4px 4px 0px #ffffff inset;
`;

const ToggleInput = styled.input`
  display: none;
  transform: ${({ toggle }) => toggle && 'translate3d(25%, 0, 0)'};
`;

const Indicator = styled.div`
  height: 100%;
  width: 200%;
  background: #ecf0f3;
  border-radius: 15px;
  transform: translate3d(-75%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
  box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6;
  transform: ${({ toggle }) => toggle && 'translate3d(25%, 0, 0)'};
`;

export default Toggle;
