import gql from 'graphql-tag';
import { _mutate, _query } from './client';

class Log {
  constructor(client) {
    this.client = client;
  }

  _readLog = async (variables, opt) => {
    return await _mutate(this.client, READ_LOG, variables, opt);
  };

  _toggleArchived = async (variables, opt) => {
    return await _mutate(this.client, TOGGLE_ARCHIVED, variables, opt);
  };

  _toggleBot = async (variables, opt) => {
    return await _mutate(this.client, TOGGLE_BOT, variables, opt);
  };

  _answerLog = async (variables, opt) => {
    return await _mutate(this.client, ANSWER_LOG, variables, opt);
  };

  _getLogs = async (variables, opt) => {
    return await _query(this.client, GET_LOGS, variables, opt);
  };
}

export default Log;

export const GET_LOGS = gql`
  query getLogs($bot_id: Int!, $archived: Boolean) {
    getLogs(bot_id: $bot_id, archived: $archived) {
      id
      user_id
      read
      name
      wc
      bot_disabled
      archived
      message {
        date
        content
        is_user
        answered_name
      }
    }
  }
`;

const ANSWER_LOG = gql`
  mutation(
    $bot_id: Int!
    $id: String!
    $answer: String!
    $is_user: Boolean!
    $answered_name: String!
  ) {
    answerLog(
      bot_id: $bot_id
      answer: $answer
      id: $id
      is_user: $is_user
      answered_name: $answered_name
    ) {
      id
      user_id
      read
      name
      bot_disabled
      archived
      message {
        date
        content
        is_user
        answered_name
      }
    }
  }
`;

const TOGGLE_BOT = gql`
  mutation($bot_id: Int!, $user_id: String!) {
    toggleBot(bot_id: $bot_id, user_id: $user_id) {
      desactivated
    }
  }
`;

const TOGGLE_ARCHIVED = gql`
  mutation($bot_id: Int!, $id: String!) {
    toggleArchived(bot_id: $bot_id, id: $id) {
      archived
    }
  }
`;

const READ_LOG = gql`
  mutation($bot_id: Int!, $id: String!) {
    readLog(bot_id: $bot_id, id: $id)
  }
`;

export const GET_INTENTS = gql`
  query getIntents($bot_id: Int!, $deleted: Boolean) {
    getIntents(bot_id: $bot_id, deleted: $deleted) {
      id
      fr
      en
      intents {
        id
        fr {
          name
          deleted
          questions
          content {
            id
            period
            content
            activated
            archived_date
            mail {
              mail
              remind_interval
              activated
            }
          }
          parameters
        }
        en {
          name
          deleted
          questions
          content {
            id
            period
            content
            archived_date
            activated
            mail {
              mail
              remind_interval
              activated
            }
          }
          parameters
        }
      }
    }
  }
`;
