import React, { useState } from 'react';
import Periods from './Periods';
import SingleDate from '../SingleDate';
import useHover from '../../hooks/useHover';
import { Button, Icon } from '@ask-mona/ui';

export default (props) => {
  const {
    g: { w },
    dates,
    onAddDate,
  } = props;
  const [add, setAdd] = useState(false);
  const fDate = dates[0];
  return (
    <div className="flex column basis-0 grow-1 space-pre">
      {dates.map((d, i) => (
        <Date key={i} d={d} i={i} {...props} />
      ))}
      <div className="p-lxs">
        {!add && fDate['startDate'] && !fDate['endDate'] && (
          <Button
            className="is-outlined is-borderless shrink"
            onClick={() => setAdd(true)}
          >
            <Icon icon="fas fa-plus" className="is-size-7" />
          </Button>
        )}
        {add && fDate['startDate'] && !fDate['endDate'] && (
          <div className="flex row">
            <SingleDate
              small
              name="newDate"
              keepOpenOnDateSelect
              focused={add}
              placeholder={w.generic.newDate}
              onChange={onAddDate}
            />
            <Button
              className="is-outlined is-borderless shrink"
              onClick={() => setAdd(false)}
            >
              <Icon icon="fas fa-check" className="is-size-7" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const Date = (props) => {
  const { i, d, onPopDate, onDateChange, ...other } = props;

  const element = (hovered) => (
    <Button
      color="danger"
      className="shrink is-outlined is-borderless"
      onClick={() => onPopDate(i)}
    >
      <Icon icon="fas fa-times" className="is-size-7" />
    </Button>
  );

  const [hoverable, hovered] = useHover(element);

  return (
    <div
      className={`flex row ${
        hovered ? 'border-b-danger has-background-danger-light' : ''
      }`}
      key={i}
    >
      {d.startDate && hoverable}
      <Periods
        {...other}
        dates={d}
        onChange={(date) => onDateChange(date, i)}
        index={i}
      />
    </div>
  );
};
