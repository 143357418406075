import React, { Component } from 'react';
import { withGlobals } from '../../hoc/globals';
import { _formatAddressComponent } from '../../_tools/other';
import Autocomplete from 'react-google-autocomplete';

class GoogleAutocomplete extends Component {
  componentDidMount() {
    this.setDefault(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setDefault(nextProps);
  }

  setDefault = (props) => {
    if (props.default) this.refs['auto'].refs.input.value = props.default;
  };

  onPlaceSelected = (place) => {
    const { onSelect } = this.props;
    if (place.address_components) {
      const data = _formatAddressComponent(place.address_components);
      const obj = {
        address: place.formatted_address,
        short_address: place.formatted_address.split(',')[1],
        google_place_id: place.place_id,
        postcode: data['postal_code'] || '',
        lat: place.geometry.location.lat(),
        long: place.geometry.location.lng(),
        other: data,
      };
      onSelect && onSelect(obj);
    }
  };

  onChange = (e) => {
    const { onChange } = this.props;
    onChange && onChange(e);
  };

  render() {
    const {
      g: { w },
      onSelect,
      ...other
    } = this.props;
    return (
      <Autocomplete
        {...other}
        ref="auto"
        className={`askmona-ui-input`}
        onPlaceSelected={this.onPlaceSelected}
        placeholder={w.place.fullAddressPlaceholder}
        onChange={this.onChange}
        types={[]}
      />
    );
  }
}

export default withGlobals(GoogleAutocomplete);
