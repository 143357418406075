import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withMain } from '../../hoc/main';

const InboxCounter = (props) => {
  if (props.m.unreadMessages === 0) return null;

  return (
    <WrapperNumber>
      <Number>{props.m.unreadMessages}</Number>
    </WrapperNumber>
  );
};

const WrapperNumber = styled.div`
  position: absolute;
  top: -8px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eb6666;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  padding: 0.5rem;
`;

const Number = styled.div`
  color: white;
  font-size: 0.8rem;
  margin-top: 2px;
`;

export default withMain(InboxCounter);
