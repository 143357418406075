import React from 'react';
import { withMain } from '../../../hoc/main';
import { withApi } from '../../../hoc/api';
import CheckList from '../../../components/CheckList';
import Filter from '../../../components/base/Filter';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

const Themes = ({ m: { w, lang, bot }, api: { ttt }, onChange, themes }) => {
  const query = ttt._getQuery('GET_THEMES');
  return (
    <Filter icon="fas fa-list-ol" label={w.events.categorie}>
      <FormControl component="fieldset">
        <FormGroup>
          <CheckList
            name="themes"
            query={query}
            variables={{ language: lang, bot_id: bot.id }}
            onChange={onChange}
            selected={themes}
          />
        </FormGroup>
      </FormControl>
    </Filter>
  );
};

export default withMain(withApi(Themes));
