import React, { Component } from 'react';
import { withGlobals } from '../../../hoc/globals';
import Filter from '../../../components/base/Filter';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class State extends Component {
  state = {
    hover: false,
  };

  onChange = (value) => {
    const { published, onChange } = this.props;
    onChange && onChange(published ^ value);
  };

  render() {
    const {
      g: { w },
      published,
    } = this.props;
    return (
      <Filter icon="fas fa-bookmark" label={w.events.state}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  label={w.med.published}
                  color="primary"
                  onChange={() => this.onChange(1)}
                  defaultChecked={published & 1}
                />
              }
              label={w.med.published}
              fontSize="small"
            />
            <FormControlLabel
              control={
                <Checkbox
                  label={w.med.unpublished}
                  color="primary"
                  onChange={() => this.onChange(2)}
                  defaultChecked={published & 2}
                />
              }
              label={w.med.unpublished}
              fontSize="small"
            />
          </FormGroup>
        </FormControl>
      </Filter>
    );
  }
}

export default withGlobals(State);
