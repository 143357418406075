import React, { useState } from 'react';
import styled from 'styled-components';

import Popover, { ArrowContainer } from 'react-tiny-popover';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#2D2F7B' },
    secondary: { main: '#fff' },
  },
});

export const AddCategoriePopover = (props) => {
  const {
    m: { w },
  } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      position={['right', 'bottom']}
      padding={5}
      onClickOutside={() => {
        setIsPopoverOpen(false);
      }}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={'#2D2F7B'}
          arrowSize={10}
          arrowStyle={{ zIndex: 10000, borderRight: '15px solid #2D2F7B' }}
        >
          <PopupContainer>
            <Header>
              <HeaderText>{w.scenario.addFolder}</HeaderText>
              {/* <ButtonContainer>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={async () => {}}
                >
                  {w.generic.save}
                </Button>
              </ButtonContainer> */}
            </Header>
            <SectionContainer>
              <SectionTitle>{w.scenario.coming_soon}</SectionTitle>
              {/* <SectionTitle>{w.scenario.folderName}</SectionTitle>
              <SectionInput>
                <FormControl
                  variant="filled"
                  color="primary"
                  classes={{
                    root: classes.whiteColor,
                  }}
                >
                  <Input
                    classes={{
                      root: classes.whiteColor,
                      borderColor: 'white',
                    }}
                         value={name}
                    color="secondary"
                    variant="outlined"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </SectionInput> */}
            </SectionContainer>
          </PopupContainer>
        </ArrowContainer>
      )}
    >
      <PopoverButtonContainer>
        <MuiThemeProvider theme={theme}>
          <PopoverButton
            onClick={() => {
              setIsPopoverOpen(!isPopoverOpen);
            }}
          >
            <PopoverButtonIcon>
              <AddBoxRoundedIcon
                color="secondary"
                style={{ fontSize: '18px' }}
              />
            </PopoverButtonIcon>
            <PopoverButtonText>{w.scenario.colFolder}</PopoverButtonText>
          </PopoverButton>
        </MuiThemeProvider>
      </PopoverButtonContainer>
    </Popover>
  );
};

const PopupContainer = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  position: relative;
  z-index: 1000;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled.span`
  margin-right: 20px;
  font-size: 1.1rem;
  font-weight: 500;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;

const SectionTitle = styled.div`
  margin-bottom: 10px;
`;

const PopoverButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const PopoverButton = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  border-radius: 30px;
  padding: 10px 15px;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  cursor: pointer;
  border: 1px solid ${(p) => p.theme.colors.primary};
`;

const PopoverButtonIcon = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopoverButtonText = styled.div`
  color: white;
  font-size: 0.9rem;
  text-align: center;
`;
