import React from 'react';
import styled from 'styled-components';
import logo from '../_assets/logoBlue.png';

export default () => <Image src={logo} alt="askmonastudio_logo" />;

const Image = styled.img`
  width: 150px;

  @media (max-width: 1400px) {
    width: 120px;
  }
  @media (max-width: 1200px) {
    width: 100px;
  }
`;
