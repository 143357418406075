import React, {useMemo} from 'react';
import Select, { components } from "react-select";
import languageTranslations from "../translations/languages";
import FlagIcon from "./FlagIcon";
import styled from "styled-components";

const LanguagesDropdown = ({languages, selectedLanguage, onLanguageSelected}) => {
    const style = {
        container: (base) => ({
            ...base,
            cursor: 'pointer'
        }),
        control: (base) => ({
            ...base,
            border: 0,
            width: 50,
            boxShadow: "none",
            backgroundColor: "transparent",
            cursor: 'pointer'
        }),
        input: (base) => ({
            ...base,
            textAlign: "center",
            width: "100%",
            caretColor: "transparent",
            cursor: 'pointer'
        }),
        valueContainer: (base) => ({
            ...base,
            padding: 0,
            cursor: 'pointer'
        }),
        singleValue: (base) => ({
            ...base,
            width: "100%",
            textAlign: "center",
            maxWidth: "100%",
            padding: 0,
            margin: 0,
            cursor: 'pointer'
        }),
        option: (base) => ({
            ...base,
            textAlign: "center",
            padding: '2px',
            margin: 0,
            cursor: 'pointer'
        }),
        menu: (base) => ({
            ...base,
            width: 50
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999
        })
    };
    const options = useMemo(()=> languages.map((language)=> ({
        label: language, value: language
    })), [languages]);
    const selectedOption = useMemo(()=> (
        options.find(({value})=> value === selectedLanguage)
    ), [options, selectedLanguage])

    return (
        <Select
            components={{
                Option: ({ innerProps, ...props }) => {
                    const onClick = (event) => {
                        event.nativeEvent.stopImmediatePropagation();
                        innerProps.onClick(event);
                    };
                    props.innerProps = { ...innerProps, onClick };
                    return <components.Option {...props} />;
                },
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
            }}
            placeholder=""
            options={options}
            onChange={(option)=> onLanguageSelected(option.value)}
            value={selectedOption}
            styles={style}
            menuPortalTarget={document.body}
            formatOptionLabel={(data)=> (
                <StyledFlag>
                    <FlagIcon style={{height: '40px', width: '40px'}} code={languageTranslations.relatedCountry[data.value]} />
                </StyledFlag>
            )}
        />
    );
};

const StyledFlag = styled.label`
  > span {
    width: 30px;
    height: 30px;
    line-height: normal;
    cursor: pointer;
  }
`;

export default LanguagesDropdown;
