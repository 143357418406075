import { _sendFeedback } from '../_tools/ui';

export const _readImages = (files, onReadEnd) => {
  let items = Object.values(files);
  let allItems = [];
  items.forEach((file) => {
    if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileInfo = {
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result,
        };
        allItems.push(fileInfo);
        if (allItems.length === items.length) onReadEnd && onReadEnd(allItems);
      };
    }
  });
};

export const _uploadAudio = async (botID, e, success, error) => {
  let file = Object.values(e.target.files)[0];
  _sendFeedback('warning', 'loader');
  if (file) {
    if (/\.(mp3)$/i.test(file.name)) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      try {
        reader.onload = async () => {
          let fileInfo = {
            size: Math.round(file.size / 1000),
            base64: reader.result,
          };
          const res = await _uploadFile(botID, { sound: fileInfo.base64 });
          if (!res.success)
            error && error('Une erreur est survenue lors du téléchargement');
          success && success({ ...res, base64: fileInfo.base64 });
        };
      } catch (err) {
        console.log(err);
        error && error('Une erreur est survenue lors du téléchargement.');
      }
    }
  }
};

export const _uploadImg = async (botID, e, success, error) => {
  let file = Object.values(e.target.files)[0];
  _sendFeedback('warning', 'loader');
  if (file) {
    if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      try {
        reader.onload = async () => {
          let fileInfo = {
            size: Math.round(file.size / 1000),
            base64: reader.result,
          };
          if (fileInfo.size > 25000)
            error && error("L'image est trop grande !");
          else {
            const res = await _uploadFile(botID, { image: fileInfo.base64 });
            if (!res.success)
              error && error('Une erreur est survenue lors du téléchargement');
            success && success({ ...res, base64: fileInfo.base64 });
          }
        };
      } catch (err) {
        console.log(err);
        error && error('Une erreur est survenue lors du téléchargement.');
      }
    }
  }
};

export const _uploadVideo = async (botID, e, success, error) => {
  let file = Object.values(e.target.files)[0];
  if (file) {
    if (/\.(webm|mp4)$/i.test(file.name)) {
      let reader = new FileReader();
      reader.readAsBinaryString(file);
      try {
        reader.onload = async () => {
          let fileInfo = {
            size: Math.round(file.size / 1000),
            binaryString: reader.result,
          };
          const res = await _uploadFile(botID, {
            video: fileInfo.binaryString,
          });
          if (!res.success)
            error && error('Une erreur est survenue lors du téléchargement');
          else
            success && success({ ...res, binaryString: fileInfo.binaryString });
        };
      } catch (err) {
        console.log(err);
        error && error('Une erreur est survenue lors du téléchargement.');
      }
    }
  }
};

export const _uploadVid = async (botID, url, success, error) => {
  const res = await _uploadFile(botID, { video: url });
  if (!res.success)
    error && error('Une erreur est survenue lors du téléchargement.');
  else {
    success && success(res);
  }
};

const _uploadFile = async (botID, variables) => {
  let url = `${process.env.REACT_APP_HTTP_LINK}/rest/assets/${botID}/upload`;
  const req = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: await JSON.stringify(variables),
  };

  try {
    const res = await fetch(url, req).then((res) => res.json());
    return { success: true, ...res };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const _addImages = async (artId, files) => {
  let url = `${process.env.REACT_APP_HTTP_LINK}/rest/artwork/${artId}/addImages`;
  const req = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: await JSON.stringify({ files }),
  };

  try {
    const res = await fetch(url, req).then((res) => res.json());
    if (res.error) throw new Error(res.error);
    return { success: true, ...res };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const getArtworkSignedUrl = async (fileNames, artId) => {
  let url = `${process.env.REACT_APP_HTTP_LINK}/rest/assets/artwork/${artId}/upload`;
  const body = fileNames.map((file) => ({
    fileName: file.name,
  }));
  const req = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: await JSON.stringify(body),
  };

  try {
    const res = await fetch(url, req).then((res) => res.json());
    if (res.error) throw new Error(res.error);
    return { success: true, url: [...res] };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const uploadImage = async (signedUrl, file, ext) => {
  let req = {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: ext,
      'Content-Type': ext,
    },
    body: await file,
  };

  try {
    const res = await fetch(signedUrl, req).then((res) => res);
    if (res && res.error) throw new Error(res.error);
    return { success: true, ...res };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const uploadBotFile = async (fileName, botId) => {
  const url = `${process.env.REACT_APP_HTTP_LINK}/rest/assets/public/${botId}/upload`;
  const body = {
    fileName,
  };
  const req = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: await JSON.stringify(body),
  };

  try {
    const res = await fetch(url, req).then((res) => res.json());
    if (res.error) throw new Error(res.error);
    return { success: true, url: { ...res } };
  } catch (err) {
    return { success: false, error: err };
  }
};
