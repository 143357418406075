import React, { useReducer } from 'react';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import { GET_BOT } from '../../_api/bot';
import Portal from '../../components/Portal';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import Nodata from '../../components/Nodata';
import NavPrompt from '../../components/NavPrompt';
import Tutorial from '../../components/Tutorial';

import Header from './pricing/Header';
import Editor from './pricing/Editor';
import Table from './pricing/Table';
import { _sendFeedback } from '../../_tools/ui';
import {
  _reducer,
  initialState,
  _jsonToItems,
  _itemsToJson,
} from './pricing/Reducer';

const Pricing = ({ subID, fct, botData, ...other }) => {
  const [state, dispatch] = useReducer(_reducer, initialState(other));
  const {
    m: { w },
  } = other;
  if (subID !== 1) fct.changeSub(1);

  if (!botData || botData.loading)
    return <Loader className="has-text-primary" />;

  if (botData.error) throw new Error(botData.error);

  if (!state.items) {
    dispatch({ type: 'edit', payload: null });
    //Reset Editor, keep same items.id
    dispatch({
      type: 'setItems',
      payload: _jsonToItems(JSON.parse(botData.getBot.prices)),
      first: true,
    });
  }

  return (
    <div className="w-100p has-text-primary-ter relative h-100">
      <Portal
        component={
          <Header
            {...other}
            dispatch={dispatch}
            onSave={async () => {
              const {
                m: { bot },
              } = other;
              const { apiBot } = state;
              let json = _itemsToJson(state.items);
              _sendFeedback('warning', 'loader');
              let res = await apiBot._updateBot({ id: bot.id, prices: json });
              if (res.success) {
                _sendFeedback('success', w.success.saved);
                dispatch({ type: 'setHasChange', payload: false });
              } else _sendFeedback('danger', w.error.general);
            }}
          />
        }
        selector="base-header"
      />
      {(!botData.getBot.prices ||
        botData.getBot.prices === 'null' ||
        botData.getBot.prices === '{}') &&
        (!state.items || !state.items.length) && (
          <Nodata title={w.error.noPrices} />
        )}
      <Table {...other} dispatch={dispatch} {...state} />
      <Modal
        isOpen={state.getInfo}
        close={() => dispatch({ type: 'getInfo', payload: false })}
        selector="#root"
      >
        <Tutorial
          tuto={w.tutorial.pricing}
          close={() => dispatch({ type: 'getInfo', payload: false })}
        />
      </Modal>
      <Modal
        isOpen={state.edit}
        close={() => dispatch({ type: 'edit', payload: null })}
        selector="#root"
      >
        <Editor
          {...other}
          {...state}
          onSave={(newItems) =>
            dispatch({ type: 'setItems', payload: newItems })
          }
          onClose={() => dispatch({ type: 'edit', payload: null })}
        />
      </Modal>
      <NavPrompt
        when={state.hasChange}
        confirm={async () => {
          const {
            m: { bot },
          } = other;
          const { apiBot } = state;
          let json = _itemsToJson(state.items);
          _sendFeedback('warning', 'loader');
          let res = await apiBot._updateBot({ id: bot.id, prices: json });
          if (res.success) {
            _sendFeedback('success', w.success.saved);
          } else _sendFeedback('danger', w.error.general);
        }}
      />
    </div>
  );
};

const withBot = graphql(GET_BOT, {
  options: (ownProps) => {
    return {
      variables: {
        id: ownProps.m.bot.id,
      },
      fetchPolicy: 'network-only',
    };
  },
  name: 'botData',
});

export default compose(withBot)(Pricing);
