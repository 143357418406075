// Control Server
export const _isServerUp = async () => {
  let url = `${process.env.REACT_APP_HTTP_LINK}/rest/status`;
  try {
    const res = await fetch(url).then((res) => res.json());
    return res.status;
  } catch (err) {
    console.log(err);
    return false;
  }
};

// Control User
export const _userIsAuthenticated = () => {
  return localStorage.getItem('token') ? true : false;
};

// Control Orga and Bot
export const _userHasOrganization = (user) => {
  return user.organization ? true : false;
};

export const _makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      hasCanceled_ = true;
    },
  };
};
