import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { QAContext } from '../../context/QAContext';
import { Categorie as CategorieType } from '../../../../types/qa/topics';
import useIntents from '../../../../api/graphql/hooks/useIntents';
import { Api } from '../../../../api/graphql/hooks/useTopics';
import warningIcon from '../../../../_assets/icon/warningIcon.png';
import { Cross } from '@styled-icons/entypo/Cross';
import { _sendFeedback } from '../../../../_tools/ui';
import { Icon } from '@ask-mona/ui';

interface Props {
  close: () => any;
  api: Api;
  w: any,
}

type Intent = {
  id: number;
  title: string;
  groupEntities: GroupEntities;
  topic?: {
    id: number;
    name: string;
  };
};

type GroupEntitie = {
  id: number;
  title: string;
  topic?: {
    id: number;
    name: string;
  };
};

type GroupEntities = Array<GroupEntitie>;

interface ButtonsProps {
  readonly color?: string;
}

const CreateQr = (props: Props) => {
  const { state, dispatch } = useContext(QAContext);
  const [name, setName] = useState<string>('');
  const [category, setCategory] = useState<number>();
  const [description, setDescription] = useState<string>('');
  const [intentSelected, setIntentSelected] = useState<Intent>();
  const [groupEntitySelected, setGroupEntitySelected] =
    useState<GroupEntitie | null>();
  const [intentGroupEntities, setIntentGroupEntities] =
    useState<GroupEntities>();
  const [grpeEntities, setGrpeEntities] = useState<GroupEntities>([]);
  const [error, setError] = useState<string>('');
  const [authorizedSubmit, setAuthorizedSubmit] = useState<boolean>(false);
  const { intents } = useIntents(state.botId, state.token, state.language);

  useEffect(() => {
    if (
      category &&
      state.botId &&
      name.length &&
      (intentSelected?.id || groupEntitySelected?.id)
    ) {
      setAuthorizedSubmit(true);
    } else {
      setAuthorizedSubmit(false);
    }
  }, [category, name, intentSelected, groupEntitySelected]);

  useEffect(() => {
    let newArray = [...grpeEntities];
    intents.forEach((el) => {
      if (el.groupEntities.length) {
        el.groupEntities.forEach((j) => {
          newArray.push(j);
          setGrpeEntities(newArray);
        });
      }
    });
  }, [intents]);

  const createTopic = async () => {
    if (
      category &&
      state.botId &&
      name.length &&
      (intentSelected?.id || groupEntitySelected?.id)
    ) {
      if (
        intentSelected?.id &&
        groupEntitySelected?.id &&
        (intentSelected?.topic?.id || !intentSelected?.topic?.id) &&
        groupEntitySelected?.topic?.id
      ) {
        setError('groupEntity');
      } else if (
        !groupEntitySelected?.id &&
        intentSelected?.id &&
        intentSelected?.topic?.id
      ) {
        setError('intent');
      } else {
        const res = await props.api.createTopic(
          category,
          state.botId,
          name,
          description
        );
        if (res) {
          props.close();
          dispatch({
            type: 'ADD_TOPIC',
            payload: { ...res, targets: [], answers: [], link: null },
          });
          if (intentSelected?.id || groupEntitySelected?.id) {
            props.api.createTopicTarget(
              res.id,
              intentSelected?.id,
              groupEntitySelected?.id
            );
          }
        }
      }
    } else {
      _sendFeedback(
        'danger',
        'Merci de remplir les champs obligatoires (avec une asterisque *)'
      );
    }
  };

  return (
    <div className="w-4 h-auto has-background-white">
      <div className="p-md has-text-white has-background-primary relative">
        {props.w.createQa}
        <span className="has-text-white absolute top right p-md pointer">
          <Icon icon="fas fa-times" onClick={() => props.close()} />
        </span>
      </div>
      <WrapperContent>
        <WrapperInput>
          <label htmlFor="">{props.w.topicName}</label>
          <StyledInput
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </WrapperInput>
        <WrapperSelectContent>
          <StyledTitle>{props.w.category}</StyledTitle>
          <Select<CategorieType>
            styles={customStyles}
            options={state.categories}
            getOptionLabel={(category: CategorieType) => category.name}
            getOptionValue={(category: CategorieType) => category.id}
            onChange={(cat) => {
              setCategory(cat.id);
            }}
          />
        </WrapperSelectContent>
        <WrapperInput>
          <label htmlFor="">{props.w.description}</label>
          <StyledInput
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </WrapperInput>
        <WrapperSelectContent>
          <StyledTitle>{props.w.intentLinked}</StyledTitle>
          <Select
            options={intents}
            getOptionLabel={(intent: Intent) => intent.title}
            getOptionValue={(intent: Intent) => intent.id}
            className="w-80p"
            onChange={(intent) => {
              setIntentSelected(intent);
              setIntentGroupEntities(intent.groupEntities);
              setGroupEntitySelected(null);
            }}
          />
        </WrapperSelectContent>
        <WrapperSelectContent>
          <StyledTitle>{props.w.groupEntityLinked}</StyledTitle>
          <Select
            options={intentGroupEntities}
            getOptionLabel={(el: GroupEntitie) => el.title}
            getOptionValue={(el: GroupEntitie) => el.id}
            className="w-80p"
            isClearable
            onChange={(el) => setGroupEntitySelected(el)}
          />
        </WrapperSelectContent>
        {error === 'intent' && (
          <WrapperError>
            <StyledCross
              size={16}
              color="#2d307b"
              onClick={() => setError('')}
            />
            <WrapperContentError>
              <StyledIcon>
                <img src={warningIcon} width="30px" />
              </StyledIcon>
              <div>
                <StyledTitleError>{props.w.error}</StyledTitleError>
                <StyledContent>
                  {props.w.intentAlreadyLinked}
                  {intentSelected?.topic?.name}".
                </StyledContent>
              </div>
            </WrapperContentError>
          </WrapperError>
        )}
        {error === 'groupEntity' && (
          <WrapperError>
            <StyledCross
              size={16}
              color="#2d307b"
              onClick={() => setError('')}
            />
            <WrapperContentError>
              <StyledIcon>
                <img src={warningIcon} width="30px" />
              </StyledIcon>
              <div>
                <StyledTitleError>{props.w.error}</StyledTitleError>
                <StyledContent>
                  {props.w.groupEntityAlreadyLinked}
                  {groupEntitySelected?.topic?.name}".
                </StyledContent>
              </div>
            </WrapperContentError>
          </WrapperError>
        )}
        <WrapperButtons>
          <Button onClick={props.close}>{props.w.cancel}</Button>
          <Button
            onClick={() => createTopic()}
            color={authorizedSubmit ? '#5054BE' : '#e6e6e6'}
          >
            {props.w.save}
          </Button>
        </WrapperButtons>
      </WrapperContent>
    </div>
  );
};

const Button = styled.button<ButtonsProps>`
  display: flex;
  padding: 0.25em 1em;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  border-radius: 3px;
  border: none;
  color: white;
  background: ${({ color }) => (color ? color : '#e08585')};
`;

const StyledInput = styled.input`
  border: 1px solid #c7c8ea;
  background-color: #dadbf1;
  color: #2d307b;
  padding: 7px;
  outline: none;
  font-size: 1rem;
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  > label {
    color: #2d307b;
  }
`;

const WrapperContent = styled.div`
  padding: 1rem;
`;

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`;

const WrapperSelectContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

const StyledTitle = styled.label`
  color: #2d307b;
`;

const WrapperError = styled.div`
  display: flex;
  align-items: center;
  background: #ececec;
  padding: 1rem;
  overflow: hidden;
  white-space: initial;
  position: relative;
`;

const StyledIcon = styled.div`
  margin-right: 1.2rem;
`;

const StyledContent = styled.p`
  color: #2d307b;
  line-height: 20px;
`;

const StyledTitleError = styled.div`
  color: #d27270;
  margin: 0.6rem;
`;

const WrapperContentError = styled.div`
  display: flex;
  width: 100%;
`;

const StyledCross = styled(Cross)`
  position: absolute;
  right: 4px;
  top: 6px;
  cursor: pointer;
`;

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#dadbf1',
    border: '1px solid #c7c8ea',
    borderRadius: '0px',
  }),
};

export default CreateQr;
