import React, { useState } from 'react';
import { Icon, Button } from '@ask-mona/ui';

const Bubble = ({ children, is_user, onMove, onDelete, onCopy }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`flex row${is_user ? '' : '-reverse'} m-xs ${
        hover ? 'has-background-danger-light' : ''
      }`}
    >
      <div className="flex column space-between p-sm">
        {onDelete ? (
          <Button
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            color="secondary"
            className="is-outlined is-borderless shrink"
            onClick={onDelete}
          >
            <Icon icon="fas fa-trash" />
          </Button>
        ) : (
          <span></span>
        )}
        {onCopy && (
          <Button
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className="is-outlined is-borderless shrink"
            onClick={onCopy}
          >
            <Icon icon="fas fa-copy" className="has-text-primary" />
          </Button>
        )}
        <Icon icon={`fas ${is_user ? 'fa-user' : 'fa-robot'}`} />
      </div>
      {children}
      {onMove && (
        <div className="flex column center p-sm">
          <Button
            color="primary"
            className="is-outlined is-borderless shrink"
            onClick={() => {
              onMove('up');
            }}
          >
            <Icon icon="fas fa-chevron-up" />
          </Button>
          <Button
            color="primary"
            className="is-outlined is-borderless shrink"
            onClick={() => {
              onMove('down');
            }}
          >
            <Icon icon="fas fa-chevron-down" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Bubble;
