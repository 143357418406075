import React, { Component } from 'react';
import { withMain } from '../../../../hoc/main';
import { Image, Field, Button, Icon } from '@ask-mona/ui';
import Generic from '../../../../components/scenario/components/Generic';
//Api
import { _uploadImg } from '../../../../_api/upload';
import { _sendFeedback } from "../../../../_tools/ui";
import Video from './Video';

const mapCarousel = (gIndex, bIndex, res, elt, name) => {
  res.forEach((e, i) => {
    if (i === gIndex) {
      e.buttons.forEach((b, k) => {
        if (k === bIndex) {
          if (elt === 'postback') {
            delete b['url'];
            b.payload = '';
            b[name] = elt;
          } else if (elt === 'web_url') {
            delete b['payload'];
            b.url = '';
            b[name] = elt;
          } else {
            b[name] = elt;
          }
        }
      });
    }
  });
  return res;
};

class AllElements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: null,
      hasChange: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onChange = (elt, gIndex, bIndex, name, cIndex) => {
    const { array, fct } = this.props;
    this.setState((prev) => ({ hasChange: !prev.hasChange }));
    array.forEach((e, k) => {
      if (k === cIndex) {
        let res = e.content.attachment.payload.elements;
        if (name === 'obj') {
          e.content.attachment.payload.elements =
            elt.attachment.payload.elements;
          fct.attachmentCarousel(array);
        }
        switch (name) {
          case 'url':
            res.forEach((e, i) => {
              if (i === gIndex) {
                if (e.default_action) {
                  e.default_action.url = elt;
                } else {
                  e.default_action = { type: 'web_url', url: elt };
                }
              }
            });
            break;
          case 'img':
            res.forEach((e, i) => {
              if (i === gIndex) {
                e.image_url = elt;
              }
            });
            break;
          case 'title':
            res.forEach((e, i) => {
              if (i === gIndex) {
                e.title = elt;
              }
            });
            break;
          case 'subtitle':
            res.forEach((e, i) => {
              if (i === gIndex) {
                e.subtitle = elt;
              }
            });
            break;
          case 'btn':
            res.forEach((e, i) => {
              if (i === gIndex) {
                e.buttons.push(elt);
              }
            });
            break;
          case 'btnTitle':
            res = mapCarousel(gIndex, bIndex, res, elt, 'title');
            break;
          case 'btnUrl':
            res = mapCarousel(gIndex, bIndex, res, elt, 'url');
            break;
          case 'btnType':
            res = mapCarousel(gIndex, bIndex, res, elt, 'type');
            break;
          case 'btnPay':
            res = mapCarousel(gIndex, bIndex, res, elt, 'payload');
            break;
          case 'btnDelete':
            res.forEach((e, i) => {
              if (i === gIndex) {
                e.buttons.splice(bIndex, 1);
              }
            });
            break;
          default:
            break;
        }
        fct.attachmentCarousel(array);
      }
    });
  };

  onDelete = (index) => {
    const { array, fct } = this.props;
    array.forEach((e, k) => {
      if (k === index) {
        array.splice(k, 1);
      }
    });
    fct.attachmentCarousel(array);
  };

  onSuccessImg = (res) => {
    const { fct } = this.props;
    const { index } = this.state;
    fct.onChangeContent(res.path, 'image', index);
    _sendFeedback("success", this.props.m?.w?.success?.saved);
  };

  onError = (err) => {
    console.log(err);
  };

  onUpload = async (e, bot_id, name, index) => {
    if (name === 'image')
      await _uploadImg(bot_id, e, this.onSuccessImg, this.onError);
    this.setState({ index: index });
  };

  render() {
    let { bot_id, array, fct } = this.props;
    if (array.length > 0) {
      return array.map((item, i) => {
        if (item.name === 'Texte') {
          return (
            <div key={i} className="flex row-reverse m-xs">
              <div className="flex column space-between p-xs">
                <Button
                  color="secondary"
                  className="is-outlined is-borderless shrink"
                  onClick={(e) => this.onDelete(i)}
                >
                  <Icon icon="fas fa-trash" />
                </Button>
                <Icon style={{ color: '#7a7ab8' }} icon="fas fa-robot" />
              </div>
              <textarea
                className="w-40p has-background-primary-ter outline-none rad-10px placeholder p-md has-text-white is-size-7 has-text-weight-semibold resize-none"
                onChange={(e) =>
                  fct.onChangeContent(e.target.value, 'texte', i)
                }
                value={item.content.text}
                rows="3"
              />
              <Chevron array={array} fct={fct} i={i} />
            </div>
          );
        } else if (item.name === 'Image') {
          let url = item.content.attachment.payload.url;
          return (
            <div key={i} className="flex row-reverse m-xs">
              <div className="flex column space-between p-xs">
                <Button
                  color="secondary"
                  className="is-outlined is-borderless shrink"
                  onClick={(e) => this.onDelete(i)}
                >
                  <Icon icon="fas fa-trash" />
                </Button>
                <Icon style={{ color: '#7a7ab8' }} icon="fas fa-robot" />
              </div>
              <Field className="w-40p has-background-primary-ter outline-none rad-10px p-md has-text-white is-size-7 has-text-weight-semibold resize-none">
                <Image
                  src={url ? url : ''}
                  onUpload={(e) => this.onUpload(e, bot_id, 'image', i)}
                />
              </Field>
              <Chevron array={array} fct={fct} i={i} />
            </div>
          );
        } else if (item.name === 'Vidéo') {
          let url = item.content.attachment.payload.url;
          return (
            <div key={i} className="flex row-reverse m-xs">
              <div className="flex column space-between p-xs">
                <Button
                  color="secondary"
                  className="is-outlined is-borderless shrink"
                  onClick={(e) => this.onDelete(i)}
                >
                  <Icon icon="fas fa-trash" />
                </Button>
                <Icon style={{ color: '#7a7ab8' }} icon="fas fa-robot" />
              </div>
              <div
                style={{ width: '50%' }}
                className="w-40p has-background-primary-ter outline-none rad-10px p-md has-text-white is-size-7 has-text-weight-semibold resize-none"
              >
                <Video
                  src={url}
                  botID={bot_id}
                  onChange={(e) =>
                    fct.onChangeContent(e.target.value, 'video', i)
                  }
                />
              </div>
              <Chevron array={array} fct={fct} i={i} />
            </div>
          );
        } else if (item.name === 'Carousel') {
          return (
            <div key={i} className="flex row-reverse m-xs">
              <div className="flex column space-between p-xs">
                <Button
                  color="secondary"
                  className="is-outlined is-borderless shrink"
                  onClick={(e) => this.onDelete(i)}
                >
                  <Icon icon="fas fa-trash" />
                </Button>
                <Icon style={{ color: '#7a7ab8' }} icon="fas fa-robot" />
              </div>
              <Generic
                hasChange={this.state.hasChange}
                {...this.props}
                element={item.content}
                onChange={this.onChange}
                cIndex={i}
              />
              <Chevron array={array} fct={fct} i={i} />
            </div>
          );
        }
        return null;
      });
    } else return <div>null</div>;
  }
}

const Chevron = ({ array, fct, i }) => {
  if (array.length > 1) {
    return (
      <div className="flex column center p-sm">
        <Button
          color="primary-bis"
          className="is-outlined is-borderless shrink"
          onClick={(e) => fct.moveElt(i, 'up')}
        >
          <Icon icon="fas fa-chevron-up" />
        </Button>
        <Button
          color="primary-bis"
          className="is-outlined is-borderless shrink"
          onClick={(e) => fct.moveElt(i, 'down')}
        >
          <Icon icon="fas fa-chevron-down" />
        </Button>
      </div>
    );
  } else return null;
};

export default withMain(AllElements);
