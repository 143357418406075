import React, { Component } from 'react';
import { withGlobals } from '../hoc/withGlobals';
import Generic from './Generic';
import Control from './Control';
import TempButtons from './TempButtons';
import TempList from './TempList';

class Template extends Component {
  constructor(props) {
    super(props);
    const { elements = [] } = props;
    const number = elements ? elements.length : 1;
    const percent = 100.0 / number;
    this.state = {
      isWider: false,
      percent: percent,
      min: -percent * (number - 1),
      offset: 0.0,
    };
  }

  componentDidMount() {
    this.updateControls(this.props.context.width);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateControls(nextProps.context.width);
  }

  updateControls = (width) => {
    if (width !== this.state.width) {
      let isWider = false;
      if (width - 100 < this.templates.offsetWidth) isWider = true;
      this.setState({
        width: width,
        isWider: isWider,
        offset: 0,
      });
    }
  };

  addOffset = () => {
    const { offset, percent } = this.state;
    let newOffset = offset + percent;
    newOffset = newOffset > 0 ? 0 : newOffset;
    this.setState({ offset: newOffset });
  };

  subOffset = () => {
    const { offset, percent, min } = this.state;
    let newOffset = offset - percent;
    newOffset = newOffset < min ? 0 : newOffset;
    this.setState({ offset: newOffset });
  };

  render() {
    const {
      template_type,
      elements = [],
      buttons = [],
      text = '',
    } = this.props;
    const { isWider } = this.state;
    let template = null;
    switch (template_type) {
      case 'generic':
        template = <Generic elements={elements} />;
        break;
      case 'button':
        template = <TempButtons text={text} buttons={buttons} />;
        break;
      case 'list':
        template = (
          <TempList text={text} buttons={buttons} elements={elements} />
        );
        break;
      default:
        break;
    }

    return (
      <div>
        <div
          className="flex"
          ref={(div) => {
            this.templates = div;
          }}
        >
          {template}
        </div>
        {elements && elements.length > 1 && isWider && (
          <React.Fragment>
            <Control onClick={this.addOffset} left icon="fas fa-chevron-left" />
            <Control onClick={this.subOffset} icon="fas fa-chevron-right" />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withGlobals(Template);
