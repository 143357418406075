import React, { useState } from 'react';
import Visual from './trigger/Visual';
import Textual from './trigger/Textual';

const Trigger = (props) => {
  const [isActive, toggle] = useState(true);
  const {
    m: { w },
  } = props;
  return (
    <div className="p-md has-text-primary-ter w-100p overflow-auto">
      <h3 className="p-b-xs m-b-xs border-b-white-ter">
        {w.subsections.artTrigger}
      </h3>
      <div className="flex column grow-1">
        <Header
          {...props}
          onChange={(bool) => toggle(bool)}
          isActive={isActive}
        />
        <div className="border-b-primary-ter border-r-primary-ter border-l-primary-ter rad-b-l-5px rad-b-r-5px">
          {isActive && <Visual {...props} />}
          {!isActive && <Textual {...props} />}
        </div>
      </div>
    </div>
  );
};

const Header = ({ m: { w }, onChange, isActive }) => (
  <div className="flex row">
    <div
      className={`flex grow-1 pointer center p-md has-background-${
        isActive
          ? 'primary-ter has-text-white rad-t-l-5px'
          : 'primary-lighter border-b-primary-ter'
      }`}
      onClick={() => onChange(true)}
    >
      {w.artwork.visual}
    </div>
    <div
      className={`flex grow-1 pointer center p-md has-background-${
        !isActive
          ? 'primary-ter has-text-white rad-t-r-5px'
          : 'primary-lighter border-b-primary-ter'
      }`}
      onClick={() => onChange(false)}
    >
      {w.artwork.textual}
    </div>
  </div>
);

export default Trigger;
