import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';

const SwitchElement = ({ element, dispatch }) => {
  const { value, name } = element;
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    dispatch({
      type: 'onFuncChange',
      payload: { name: name, value: checked },
      arraypayload: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <Container>
      <Text>Activer/Désactiver la fonctionnalité </Text>
      <Switch
        checked={checked}
        onChange={() => {
          setChecked(!checked);
        }}
        name="checkedA"
        color="primary"
      />
    </Container>
  );
};

export default SwitchElement;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  color: ${(p) => p.theme.colors.primary};
  margin-right: -15px;
`;

const Text = styled.div`
  display: flex;
  margin-right: 5px;
`;
