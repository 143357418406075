import React, { memo, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { QAContext } from '../../context/QAContext';
import Select from 'react-select';
import { Api } from '../../../../api/graphql/hooks/useTopics';
import useIntents from '../../../../api/graphql/hooks/useIntents';
import { Icon } from '@ask-mona/ui';
import { _sendFeedback } from '../../../../_tools/ui';
import warningIcon from '../../../../_assets/icon/warningIcon.png';
import { Cross } from '@styled-icons/entypo/Cross';

interface Props {
  close: () => any;
  botId: number;
  token: string;
  api: Api;
  w: any;
}

type Intent = {
  id: number;
  title: string;
  groupEntities: GroupEntities;
  topic?: {
    id: number;
    name: string;
  };
};

type GroupEntitie = {
  id: number;
  title: string;
  topic?: {
    id: number;
    name: string;
  };
};

interface WrapperProps {
  readonly padding?: boolean;
}

type GroupEntities = Array<GroupEntitie>;

const EditLeoLink = (props: Props) => {
  const { state } = useContext(QAContext);
  const [intentSelected, setIntentSelected] = useState<Intent>();
  const [intentGroupEntities, setIntentGroupEntities] =
    useState<GroupEntities>();
  const [grpeEntities, setGrpeEntities] = useState<GroupEntities>([]);
  const [groupEntitySelected, setGroupEntitySelected] =
    useState<GroupEntitie | null>();
  const [error, setError] = useState<string>('');
  const { intents } = useIntents(props.botId, props.token, state.language);
  const topic = state.topics.find((el) => el.id === state.currentTopicId);

  useEffect(() => {
    let newArray = [...grpeEntities];
    intents.forEach((el) => {
      if (el.groupEntities.length) {
        el.groupEntities.forEach((j) => {
          newArray.push(j);
          setGrpeEntities(newArray);
        });
      }
    });
  }, [intents]);

  const createTopicTarget = async () => {
    if (intentSelected?.id || groupEntitySelected?.id) {
      if (
        intentSelected?.id &&
        groupEntitySelected?.id &&
        (intentSelected?.topic?.id || !intentSelected?.topic?.id) &&
        groupEntitySelected?.topic?.id
      ) {
        setError('groupEntity');
      } else if (
        !groupEntitySelected?.id &&
        intentSelected?.id &&
        intentSelected?.topic?.id
      ) {
        setError('intent');
      } else {
        if (state.currentTopicId)
          props.api.createTopicTarget(
            state.currentTopicId,
            intentSelected?.id,
            groupEntitySelected?.id
          );
        props.close();
      }
    } else {
      _sendFeedback(
        'danger',
        props.w.fill_required_fields
      );
    }
  };

  return (
    <WrapperModal>
      <div className="p-md has-text-white has-background-primary relative">
        {state.wording.linkTopicToLEO}
        <span className="has-text-white absolute top right p-md pointer">
          <Icon icon="fas fa-times" onClick={() => props.close()} />
        </span>
      </div>
      <StyledWrapper>
        {state.permission === 1 && (
          <WrapperSelect>
            <WrapperSelectContent>
              <StyledTitle>{state.wording.linkToIntent}</StyledTitle>
              <Select<Intent>
                options={intents}
                getOptionLabel={(intent: Intent) => intent.title}
                getOptionValue={(intent: Intent) => intent.id}
                className="w-80p"
                onChange={(intent) => {
                  setIntentSelected(intent);
                  setIntentGroupEntities(intent.groupEntities);
                  setGroupEntitySelected(null);
                }}
              />
            </WrapperSelectContent>
            <WrapperSelectContent padding>
              <StyledTitle>{state.wording.linkToGrpeEntity}</StyledTitle>
              <Select
                options={intentGroupEntities}
                getOptionLabel={(el: GroupEntitie) => el.title}
                getOptionValue={(el: GroupEntitie) => el.id}
                className="w-80p"
                isClearable
                value={groupEntitySelected || ''}
                onChange={(el) => setGroupEntitySelected(el)}
              />
            </WrapperSelectContent>
            <div>
              <StyledButton
                onClick={() => {
                  createTopicTarget();
                }}
              >
                {state.wording.link}
              </StyledButton>
            </div>
          </WrapperSelect>
        )}
        {error === 'intent' && (
          <WrapperError>
            <StyledCross
              size={16}
              color="#2d307b"
              onClick={() => setError('')}
            />
            <WrapperContentError>
              <StyledIcon>
                <img src={warningIcon} width="30px" />
              </StyledIcon>
              <div>
                <StyledTitleError>{state.wording.error}</StyledTitleError>
                <StyledContent>
                  {state.wording.intentAlreadyLinked}
                  {intentSelected?.topic?.name}”.
                </StyledContent>
              </div>
            </WrapperContentError>
          </WrapperError>
        )}
        {error === 'groupEntity' && (
          <WrapperError>
            <StyledCross
              size={16}
              color="#2d307b"
              onClick={() => setError('')}
            />
            <WrapperContentError>
              <StyledIcon>
                <img src={warningIcon} width="30px" />
              </StyledIcon>
              <div>
                <StyledTitleError>{state.wording.error}</StyledTitleError>
                <StyledContent>
                  {state.wording.groupEntityAlreadyLinked}
                  {groupEntitySelected?.topic?.name}”.
                </StyledContent>
              </div>
            </WrapperContentError>
          </WrapperError>
        )}
        {error === '' && (
          <WrapperTarget>
            <div>
              <StyledTitle>{state.wording.intentLinked}</StyledTitle>
              <ul>
                {topic?.targets
                  .filter((target) => target.group_entity_id === null)
                  .map((el) => (
                    <StyledItem key={el.id}>
                      {' '}
                      {
                        intents.find((intent) => intent.id === el.intent_id)
                          ?.title
                      }
                      {state.permission === 1 && (
                        <i
                          onClick={() => props.api.deleteTopicTarget(el.id)}
                          className="fas fa-trash"
                        ></i>
                      )}
                    </StyledItem>
                  ))}
              </ul>
            </div>
            <div>
              <StyledTitle>{state.wording.groupEntityLinked}</StyledTitle>
              <ul>
                {topic?.targets
                  .filter((target) => target.intent_id === null)
                  .map((el) => (
                    <StyledItem key={el.id}>
                      {
                        grpeEntities?.find((i) => i.id === el.group_entity_id)
                          ?.title
                      }
                      {state.permission === 1 && (
                        <i
                          onClick={() => props.api.deleteTopicTarget(el.id)}
                          className="fas fa-trash"
                        ></i>
                      )}
                    </StyledItem>
                  ))}
              </ul>
            </div>
          </WrapperTarget>
        )}
      </StyledWrapper>
    </WrapperModal>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
`;

const WrapperSelectContent = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  padding-left: ${({ padding }) => padding && '2rem'};
`;

const WrapperModal = styled.div`
  width: auto;
  background: #fff;
`;

const StyledButton = styled.div`
  border: 1px solid #ededf7;
  text-align: center;
  padding: 7px 25px;
  font-size: 1em;
  border-radius: 8px;
  background: #738efb;
  color: #fff;
  margin-left: 2rem;
  cursor: pointer;
`;

const StyledTitle = styled.h3`
  color: #2d307b;
  padding: 1rem 0;
`;

const StyledItem = styled.li`
  display: flex;
  color: #2d307b;
  padding: 0 2rem;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 0.6rem;

  > i {
    cursor: pointer;
  }
`;

const WrapperTarget = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

const WrapperSelect = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;
`;

const StyledContent = styled.p`
  color: #2d307b;
  line-height: 20px;
`;

const StyledTitleError = styled.div`
  color: #d27270;
  margin: 0.6rem;
`;

const WrapperContentError = styled.div`
  display: flex;
  width: 100%;
`;

const StyledCross = styled(Cross)`
  position: absolute;
  right: 4px;
  top: 6px;
  cursor: pointer;
`;

const StyledIcon = styled.div`
  margin-right: 1.2rem;
`;

const WrapperError = styled.div`
  display: flex;
  background: #ececec;
  padding: 1rem;
  overflow: hidden;
  white-space: initial;
  position: relative;
  margin: 1rem 0;
  height: 200px;
`;

export default EditLeoLink;
