import React, { Component } from 'react';
import styled from 'styled-components';
import Bot from '../../_api/bot';
import Modal from '../../components/Modal';
import { Switch, Field, Textarea, Button } from '@ask-mona/ui';

class ToggleBot extends Component {
  state = {
    active: false,
    hasScenario: false,
    modal: false,
    bot: {},
    loading: false,
  };

  async UNSAFE_componentWillMount() {
    this.setState({ bot: this.props.bot });
    await this.checkBot(this.props);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.bot.id !== nextProps.bot.id && !this.state.loading) {
      await this.checkBot(nextProps);
    }
  }

  checkBot = async (props) => {
    this.setState({ loading: true });
    const { bot } = props;
    const b = new Bot(null, bot.id);
    const res = await b._getModules();
    this.setState({
      bot: b,
      hasScenario: res.success ? true : false,
      active: bot.activated,
      loading: false,
    });
  };

  onChange = async () => {
    const { bot, active } = this.state;
    let res = null;
    if (bot) {
      if (!active) {
        res = await bot._activateBot();
        if (res.success) this.setState({ active: true });
      } else this.setState((state) => ({ modal: !state.modal }));
    }
  };

  onConfirm = async (msg) => {
    const { bot, active } = this.state;
    let res = null;
    if (bot) {
      if (active) {
        res = await bot._desactivateBot(msg);
        if (res.success) this.setState({ active: false, modal: false });
      }
    }
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  render() {
    const { bot, w } = this.props;
    const { active, hasScenario, modal } = this.state;
    if (!hasScenario) return null;
    return (
      <Container>
        <Switch checked={active} onChange={this.onChange} color="secondary" />
        <div className="flex center column is-size-7 m-l-xs">
          <Text>{bot.name}</Text>
          <Text>{w.generic[active ? 'active' : 'desactive']}</Text>
        </div>
        <Modal isOpen={modal} close={this.closeModal} selector="#root">
          <Desactivate
            w={w}
            onConfirm={this.onConfirm}
            onCancel={this.closeModal}
          />
        </Modal>
      </Container>
    );
  }
}

class Desactivate extends Component {
  state = {
    input: '',
  };

  onChange = (e) => {
    this.setState({ input: e.target.value });
  };

  render() {
    const { w, onConfirm, onCancel } = this.props;
    const { input } = this.state;
    return (
      <div className="w-4 has-background-white">
        <div className="has-background-primary p-md has-text-white">
          {w.bot.desactivate}
        </div>
        <div className="p-md">
          <Field title={w.bot.title} subtitle={w.bot.subtitle}>
            <Textarea
              className="m-t-xs"
              value={input}
              onChange={this.onChange}
              resize="none"
              rows="4"
              placeholder={w.bot.placeholder}
            />
          </Field>
          <div className="flex m-t-sm row space-between wrap">
            <Button
              className="is-uppercase"
              onClick={onCancel}
              color="secondary"
            >
              {w.generic.cancel}
            </Button>
            <Button className="is-uppercase" onClick={() => onConfirm(input)}>
              {w.generic.validate}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ToggleBot;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  margin-right: 20px;
`;

const Text = styled.span`
  color: ${(p) => p.theme.colors.primary};
`;
