import React, {useState} from "react";
import {Query} from "react-apollo";
import {GET_PLACE, GET_SUB_PLACE} from "../../../_api/place";
import Nodata from "../../../components/Nodata";
import Loader from "../../../components/Loader";
import Schedule from "../../../components/scheduler/";

export default ({p, m: {w}, ...other}) => {
  if (!p || !other._getBot.getBot.places[0])
    return (<Nodata title={w.error.noPlaces} />)
  return (
    <Query query={p.sub ? GET_SUB_PLACE : GET_PLACE} variables={{id: p.pID}} skip={!p.pID}>
      {({loading, data, ...rest}) => {
        if (loading)
          return (<Loader />);
        if (!data)
          return (<Loader />);
        return (<Scheduler p={p} {...other} data={data} rest={rest} />)
      }}
    </Query>
  )
}

const Scheduler = ({p, data, place_oph, fct}) => {
  const place = p.sub ? data.getSubPlace : data.getPlace;
  const [oph, setOph] = useState(place.opening_hours)
  return (
    <div className="p-md">
      <Schedule
        oph={oph}
        set={(oph) => {
          setOph(oph);
          fct.onChange && fct.onChange(oph);
        }}
      />
    </div>
  )
}
