export default {
  generalConfig: ['Configuration génerale', 'General configuration'],
  templateConfig: ['Configuration du template', 'Configuration of the template'],
  templateChoice: ['Choix du template ', 'Choice of template'],
  fontWebchat: ['Police du webchat', 'Webchat font'],
  speakWith: ['Discuter avec Adam !', 'Speak with Adam !'],
  desktopView: ['Vue desktop', 'Desktop view'],
  mobileView: ['Vue responsive', 'Responsive view'],
  moreInfo: [
    "Envie de plus d'infos avant de venir ?",
    'Want more info before coming?',
  ],
  fullSize: ['Vue entière', 'Full view'],
  reducedView: ['Vue réduite', 'Reduced view'],
  reducedViewSimplified: ['Vue réduite simplifié', 'Simplified reduced view'],
  catchphraseTitle: ["Hello ! Moi c'est Adam.", "Hello! I'm Adam."],
  catchphraseSubtitle: [
    'Je suis là pour répondre à toutes tes questions.',
    'I am here to answer all your questions.',
  ],
  iconBubble: ['Icône de la bulle', 'Icon next to the bubble'],
  changeIcon: ["Changer l'icône", 'Change the icon'],
  iconHover: ['Icône au survol (optionnel)', 'Hover icon (optional)'],
  colorBubble: ['Couleur de la bulle', 'Color of the bubble'],
  colorFontBubble: ['Couleur de la police de la bulle', 'Color of the bubble font'],
  subtitle: ['Sous-titre', 'Subtitle'],
  windowLayout: [
    'Disposition de la fenêtre ouverte sur ordinateur',
    'Layout of the open window on computer',
  ],
  standard: ['Standard', 'Standard'],
  aside: ['Bandeau sur le côté', 'Headband on the side'],
  borderWindow: ['Arrondi de la fenêtre :', 'Rounding of the window :'],
  backgroundWindow: [
    'Couleurs de fond de la fenêtre',
    'Background colors of the window',
  ],
  chooseBg: ['Choisir une couleur de fond :', 'Choose a background color:'],
  chooseGradient: ['Choisir un dégradé :', 'Choose a gradient :'],
  chooseImage: ['Choisir une image de fond :', 'Choose a background image:'],
  hasHeader: [
    'Le webchat a-t-il une en-tête ?',
    'Does the webchat have a header?',
  ],
  yes: ['Oui', 'Yes'],
  no: ['Non', 'No'],
  shape: ['Choix de la forme', 'Choice of shape'],
  straight: ['Droit', 'Straight'],
  curved: ['Incurvé', 'Curved'],
  sizeChoice: ['Choix de la taille', 'Choice of size'],
  large: ['Large', 'Large'],
  slim: ['Fin', 'Thin'],
  customize: [
    'Personnalisé (indiquer taille) : ',
    'Customize (indicate size) : ',
  ],
  choiceEl: [
    'Choix des éléments présents (peuvent se combiner)',
    'Choice of elements present (can be combined)',
  ],
  icon: ['Icône', 'Icon'],
  noIcon: ["Pas d'icône", 'No icon'],
  title: ['Titre', 'Title'],
  noTitle: ['Pas de titre', 'No title'],
  noSubtitle: ['Pas de sous-titre', 'No subtitle'],
  positionHeader: [
    'Positionnement du contenu du header :',
    'Positioning of the header content:',
  ],
  left: ['A gauche', 'On the left'],
  colorHeader: ['Couleur du bandeau', 'Color of the header'],
  colorFont: ['Couleur de la police', 'Font color'],
  sizeIcon: ["Taille de l'icône", 'Size of the icon'],
  distance: ['Distance icône - titre', 'Distance icon - title'],
  defaultMenu: [
    'Par défaut le menu du bas est :',
    'By default the bottom menu is:',
  ],
  close: ['Fermé', 'Closed'],
  open: ['Ouvert', 'Open'],
  itemsMenu: ['Choix des items du menu :', 'Choice of menu items:'],
  bgMenu: ['Couleur de fond', 'Background color'],
  borderQr: ["Choix de l'arrondi de la bulle", 'Choice of the rounding of the bubble'],
  filling: ['Choix du remplissage', 'Choice of filling'],
  example: ['Exemple', 'Example'],
  outline: ['Contours', 'Outlines'],
  full: ['Plein', 'Full'],
  colorQr: ['Couleur de la QR', 'Color of the QA'],
  font: ['Police', 'Font'],
  fontSize: ['Taille de la police', 'Font size'],
  inputState: [
    'Le champ de saisir de texte est :',
    'The text entry field is :',
  ],
  active: ['Activé', 'Activated'],
  sendImage: ["L'envoi d'image est :", 'The image sending is :'],
  desactivate: ['Désactivé', 'Off'],
  bg: ['Couleur du fond', 'Background color'],
  submitColor: ['Couleur bouton envoyer', 'Send button color'],
  placeholder: ['Texte du champ de saisie', 'Text of the input field'],
  changeSendIcon: [
    'Changer l’icône du bouton envoyer',
    'Change the icon of the send button',
  ],
  carousselPosition: [
    'Choix du positionnement du contenu dans la carte',
    'Choice of content position in the card',
  ],
  pagination: [
    'Choix de l’affichage de la navigation dans le caroussel',
    'Choice of navigation display in the carousel',
  ],
  borderCaroussel: [
    'Taille de l’arrondi des cartes de caroussel',
    'Rounding size of carousel cards',
  ],
  bgCard: ['Couleur de fond de la carte', 'Background color of the map'],
  bgBtn: ['Couleur de fond des boutons', 'Background color of the buttons'],
  borderColor: ['Couleur du contour des boutons', 'Button outline color'],
  colorTitleSub: ['Couleur titre et sous-titre', 'Title and subtitle color'],
  btnColor: ['Couleur police boutons', 'Button font color'],
  paginationColor: ['Couleur pagination', 'Pagination\'s color'],
  shapeBubble: ['Choix de la forme des bulles', 'Choice of the shape of the bubbles'],
  messageBot: ['Message du chatbot', 'Message from the chatbot'],
  messageUser: ["Message de l'utilisateur", 'Message from the user'],
  animationChoice: [
    'Choix de l’animation quand le chatbot « écrit »',
    'Choice of animation when the chatbot "writes"',
  ],
  iconUser: [
    'Afficher l’icône de l’utilisateur à côté des messages de l’utilisateur',
    'Display the user icon next to the user\'s messages',
  ],
  iconBot: [
    'Afficher l’icône du chatbot à côté des messages du chatbot',
    'Display the chatbot icon next to the chatbot messages',
  ],
  changeIconSend: [
    'Changer l’icône par défaut de l’utilisateur',
    'Change the default user\'s icon',
  ],
  iconPosition: [
    'Position de l’icône par rapport à la bulle :',
    'Position of the icon in relation to the bubble:',
  ],
  top: ['En haut', 'Top of the page'],
  middle: ['Au milieu', 'In the middle'],
  bottom: ['En bas', 'Bottom'],
  colorBubbleBot: ['Couleurs des bulles du chatbot', 'Colors of the chatbot\'s bubbles'],
  solidColor: ['Couleur unie', 'Solid color'],
  gradient: ['Dégradé', 'Gradient'],
  colorBubbleUser: [
    "Couleurs des bulles de l'utilisateur",
    'User\'s bubble colors',
  ],
  colorAnimationBot: [
    'Couleur de l’animation quand le chatbot « écrit »',
    'Color of the animation when the chatbot "writes"',
  ],
  formatHeader: ['Format de l’en-tête', 'Header format'],
  contentHeader: ['Contenus du bandeau', 'Content of the banner'],
  centerMessage: [
    'Message centré avec une marge',
    'Centered message with a margin',
  ],
  classicMessage: ['Message classique', 'Classic message'],
  backgroundActivated: ['Background actif', 'Background on'],
  backgroundDesactivated: ['Background désactivé', 'Background off'],
  avatarVisible: ['Avatar visible', 'Visible avatar'],
  avatarHidden: ['Avatar caché', 'Hidden avatar'],
  headerHidden: ['Header caché', 'Hidden header'],
  headerVisible: ['Header visible', 'Visible header'],
  colorBotText: ['Couleur du texte du bot', 'Color of the bot text'],
  colorUserText: ["Couleur du texte de l'utilisateur", 'User text color'],
  colorIcons: ['Couleur des icônes', 'Color of the icons'],
  titleSize: ['Taille du titre', 'Title size'],
  subtitleSize: ['Taille du sous-titre', 'Subtitle size'],
  iconToClose: ['Icône pour fermer le webchat', 'Icon to close the webchat'],
  crossSize: ['Taille de la croix', 'Size of the cross'],
  avatarUser: ["Avatar de l'utilisateur :", "User's avatar:"],
  avatarBot: ['Avatar du bot :', "Bot's avatar:"],
  changeBot: [
    'Changer l’icône par défaut du bot',
    'Change the default bot icon',
  ],
  configuration: ['Configuration', 'Configuration'],
  configEmail: ['Configurer une adresse email', 'Setting up an email address'],
  addEmail: ['+ Ajouter un mail', '+ Add a mail'],
  configurationEmail: [
    'Configuration des adresses mail',
    'Configuration of email addresses',
  ],
  mail: ['Adresse mail', 'Email address'],
  email: ['Adresse email*', 'Email address*'],
  enablingNotifications: [
    'Activation des notifications',
    'Enabling notifications',
  ],
  action: ['Action', 'Action'],
  empty: ['C’est bien vide ici ...', "It's quite empty here..."],
  addMailContent: [
    'N’attendez pas pour ajouter une adresse mail,',
    "Don't wait to add an email address",
  ],
  clickBelow: ['cliquez ci-dessous !', 'click below !'],
  confirm: ['Valider', 'Confirm'],
  cancel: ['Annuler', 'Cancel'],
  beware: ['Attention, cette action est ', 'Beware, this action is '],
  irreversible: ['irréversible', 'irreversible'],
  confirmDeletion: [
    'Confirmez-vous la suppression de cette adresse mail ?',
    'Do you confirm the deletion of this email address?',
  ],
  previewMail: [
    'Aperçu du mail de notification',
    'Preview of the notification email',
  ],
  previewObject: [
    'Object: Nouveau message visiteur du chatbot “nom du bot”',
    'Object: New visitor message from the chatbot "bot name".',
  ],
  receivedMessage: [
    'Vous avez reçu un nouveau message',
    'You have received a new message',
  ],
  contact: [
    'L’un de vos visiteurs vous a contacté via la messagerie de votre chatbot.',
    'One of your visitors has contacted you via your chatbot.',
  ],
  messageDate: [
    'Reçu le 26/01/2022 à 00h00 de la part de “email”',
    'Received on 26/01/2022 at 00h00 from "email".',
  ],
  messagePreview: [
    '" Bonjour, je peux utiliser mon pass sésame 2019-2020, pour voir l\'exposition Napoléon ? "',
    '"Hello, can I use my 2019-2020 sesame pass, to see Napoleon exhibition?"',
  ],
  connectToAnswer: [
    'Connectez-vous afin de répondre à ce visiteur.',
    'Login to reply to this visitor.',
  ],
  login: ['Se connecter', 'Log in'],
  avatar: ["avatar", "avatar"],

  menu_templates: ["templates", "templates"],
  ["menu_page-d'accueil"]: ["page-d'accueil", "home page"],
  menu_bulle: ["bulle", "bubble"],
  ["menu_fenêtre"]: ["fenêtre", "window"],
  ["menu_en-tête"]: ["en-tête", "header"],
  menu_menu: ["menu", "menu"],
  menu_qr: ["qr", "qa"],
  ["menu_champ-Texte"]: ["champ-Texte", "text field"],
  menu_caroussels: ["caroussels", "carousels"],
  menu_webview: ["webview", "webview"],
  menu_contact: ["contact", "contact"],
  ["menu_bulles-messages"]: ["bulles-messages", "messages-bubbles"],
  ["menu_pleine-page"]: ["pleine-page", "full page"],
  menu_messagerie: ["messagerie", "messaging"],

  carousel_arrow_colour: ["Couleur de la flèche", "Arrow colour"],
  carousel_buttons_height: ["Hauteur des boutons", "Buttons height"],
  carousel_buttons_font_size: ["Taille de la police des boutons", "Buttons font size"],
  carousel_titles_font_size: ["Taille de la police des titres", "Titles font size"],
  carousel_subtitles_font_size: ["Taille de la police des sous-titres", "Subtitles font size"],

  header_enabled_on_mobile: ["Header visible sur mobile", "Header enabled on mobile"],
  header_disabled_on_mobile: ["Header désactivé sur mobile", "Header disabled on mobile"],

  homepage_status_title: ["Par défaut la page d'accueil est :", "By default the home page is:"],
  homepage_enabled: ["Active", "Enabled"],
  homepage_disabled: ["Inactive", "Disabled"],
  homepage_title_font_size: ["Taille de la police du titre", "Title font size"],
  homepage_content_font_size: ["Taille de la police du contenu", "Content font size"],
  homepage_title_colour: ["Couleur du titre", "Title colour"],
  homepage_background_colour: ["Couleur du background", "Background colour"],
  homepage_content_colour: ["Couleur du contenu", "Content colour"],
  homepage_media_selection: ["Choix du média", "Media selection"],
  homepage_change_media: ["Changer le média", "Change media"],
  homepage_media_accepted_formats: ["formats acceptés : png, jpg, gif, mp4, webm", "Accepted formats: png, jpg, gif, mp4, webm"],
  homepage_buttons_configuration: ["Configurations des boutons", "Buttons configurations"],
  homepage_buttons_module_link: ["Lien vers un module:", "Link to a module:"],
  homepage_add_button: ["Ajouter un item", "Add an item"],
  homepage_buttons_colour: ["Couleur des boutons", "Buttons colour"],
  homepage_buttons_background: ["Fond des boutons", "Buttons background"],
  homepage_bubble_height: ["Hauteur de la bulle", "Bubble height"],
  homepage_bubble_width: ["Largeur de la bulle", "Buble width"],

  input_background_enabled: ["Background activé", "Background enabled"],
  input_background_disabled: ["Background désactivé", "Background disabled"],
  input_icon_size: ["Taille de l'icône", "Icon size"],
  input_change_menu_icon: ["Modifier l’icône du menu", "Change the menu icon"],
  input_change_pic_icon: ["Modifier l’icône pour envoyer une photo", "Change the icon for sending an image"],

  menu_font_colour: ["Couleur de la police", "Font colour"],
  menu_creation_item_title: ["Titre de l'item", "Item title"],
  menu_creation_link_to_module: ["Lien vers un module:", "Link to a module"],
  menu_creation_icon_preview: ["Aperçu de l'icône", "Icon preview"],
  menu_creation_icon_select: ["Choisir une icône", "Select an icon"],
  menu_creation_image_preview: ["Aperçu de l'image", "Image preview"],

  window_back_shadow_enabled: ["Ombre de fond activée", "Back shadow enabled"],
  window_back_shadow_disabled: ["Ombre de fond désactivée", "Back shadow disabled"],

  history_first_conversation: ["Première conversation le", "First conversation the"],

  history_new_session: ["Nouvelle session le", "New session the"],
  at: ['à', "at"],
};
