import React from 'react';
import styled from 'styled-components';
import { KeyboardArrowRight } from "@styled-icons/material-rounded/KeyboardArrowRight";

const Ellipsis = styled.div`
  display: flex;
  position: absolute;
  bottom: ${({ bottom }) => bottom || '40px'};
  left: ${({ left }) => left || '88px'};
  align-items: center;

  >div {
    top: 33px;
    width: 6px;
    height: 6px;
    margin: 0 .2rem;
    border-radius: 50%;
    background: #d8d8d8;

    :nth-child(1) {
      background: #908e8e;
    }
  }
`;

const WrapperPagNumber = styled.div`
  display: flex;
  position: absolute;
  bottom: ${({ bottom }) => bottom || '40px'};
  left: ${({ left }) => left || '88px'};
  align-items: center;

  >div {
    font-size: .8em;
    color: #908e8e;
  }
`;

const IconPagination = styled(KeyboardArrowRight)`
  background: ${({ pagSquare, pagNumber }) => (pagSquare || pagNumber) && '#908e8e'};
  color: ${({ pagSquare, pagNumber }) => (pagSquare || pagNumber) && '#FFF'};
  border-radius: ${({ pagSquare, pagNumber }) => (pagSquare && '4px') || (pagNumber && '50%')};
  margin-left: .3rem;
`;

const Pagination = ({
  pagNumber,
  pagSquare,
  left,
  bottom
}) => {
  return (
    <>
    {!pagNumber ?
      <Ellipsis left={left} bottom={bottom}>
        <div></div>
        <div></div>
        <div></div>
        <IconPagination size={12} pagSquare={pagSquare}/>
      </Ellipsis>
      :
      <WrapperPagNumber left={left} bottom={bottom}>
        <div>1/3</div>
        <IconPagination size={12} pagSquare={pagSquare} pagNumber={pagNumber}/>
      </WrapperPagNumber>
    }
    </>
  )
};

export default Pagination;
