import React, { useReducer, useState } from 'react';
import {
  Button,
  Field,
  Textarea,
  Stackarea,
  Select,
  Input,
  Switch,
  Icon
} from '@ask-mona/ui';
import LanguagesDropdown from "../../../components/LanguagesDropdown";

export default props => {
  const {
    m: { w, bot_lang, bot },
    edit: { type, name },
    onClose
  } = props;
  const [editedLang, setEditedLang] = useState(bot.default_language);
  return (
    <div className="w-6 has-background-white">
      <div className="flex row items-center space-between has-background-primary p-md has-text-white">
        {`${!name ? w.pricing.add : w.pricing.edit} ${
          type === 0
            ? w.pricing.editCategorie
            : type === 1
            ? w.pricing.editSubCategorie
            : w.pricing.editPrice
        }`}
        <div className="flex row">
          <LanguagesDropdown
              languages={bot_lang}
              selectedLanguage={editedLang}
              onLanguageSelected={(language) => setEditedLang(language)}
          />
          <Button
            onClick={onClose}
            className="is-outlined is-borderless shrink"
          >
            <Icon icon="fas fa-times" />
          </Button>
        </div>
      </div>
      {type === 0 && <Categorie {...props} editedLang={editedLang} />}
      {type === 1 && <Categorie {...props} editedLang={editedLang} sub="true" />}
      {type === 2 && <Tarif {...props} editedLang={editedLang} />}
    </div>
  );
};

const _reducer = (state, action) => {
  switch (action.type) {
    case 'onChange':
      const {
        payload: { name, value },
        lang
      } = action;
      if (name !== 'price')
        return { ...state, [name]: { ...state[name], [lang]: value } };
      else return { ...state, [name]: value };
    case 'onCheck':
      return { ...state, full_price: action.payload };
    case 'setCategorie':
      return { ...state, cat: action.payload };
    case 'setRecipient':
      return {
        ...state,
        recipient: { ...state.recipient, [action.lang]: action.payload }
      };
    default:
      return state;
  }
};

// Categorie and SubCategorie Editor.
const Categorie = props => {
  const {
    m: { w, bot },
    editedLang,
    edit,
    items,
    onClose,
    onSave
  } = props;
  const [state, dispatch] = useReducer(_reducer, {
    name: {},
    description: {},
    ...edit
  });
  let index = -1;
  items.forEach((c, i) => {
    if (c.id === edit.id) index = i;
  });
  return (
    <div className="p-md">
      <div className="flex column">
        <div className="flex column grow-1 m-b-md">
          <Field
            title={w.generic.title}
          >
            <Input
              value={state.name[editedLang] || ''}
              name="name"
              onChange={e =>
                dispatch({ type: 'onChange', payload: e.target, lang: editedLang })
              }
            />
          </Field>
          {editedLang!==bot.default_language && (<span className="is-size-7 is-italic">{state.name[bot.default_language]}</span>)}
        </div>
        <div className="flex column grow-1 m-b-md">
        <Field
            title={w.generic.description}
            className=""
          >
            <Textarea
              value={state.description[editedLang] || ''}
              name="description"
              resize="auto"
              maxHeight={200}
              onChange={e =>
                dispatch({ type: 'onChange', payload: e.target, lang: editedLang })
              }
            />
          </Field>
          {editedLang!==bot.default_language && (<span className="is-size-7 is-italic">{state.description[bot.default_language]}</span>)}
        </div>
      </div>
      <Board
        {...props}
        onCancel={onClose}
        onConfirm={() => {
          const newItems = items.filter(c => c.id !== edit.id);
          newItems.splice(index !== -1 ? index : newItems.length, 0, state);
          onSave(newItems);
          onClose();
        }}
        disable={!state.name}
      />
    </div>
  );
};

//Tarif Editor
const Tarif = props => {
  const {
    m: { w, bot },
    editedLang,
    edit,
    items,
    onClose,
    onSave
  } = props;
  const [state, dispatch] = useReducer(_reducer, {
    description: {},
    name: {},
    price: '',
    full_price: false,
    recipient: {},
    ...edit
  });
  // Stack regexp for recipient
  let regStack = new RegExp(/\s*(.*?)[,.]/gi);
  let lastCat = null;
  let index = -1;
  items.forEach((c, i) => {
    if (c.id === edit.id) index = i;
  });
  if (index !== -1) {
    for (let i = index; i > 0; i--) {
      if (items[i].type === 0) {
        lastCat = items[i];
        break;
      }
    }
  }
  const categories = items.filter(c => c.type === 0);
  return (
    <div className="p-md">
      {categories.length > 0 && (
        <Field title={w.pricing.linkToCategorie} className="m-b-md">
          <Select
            items={categories.map(c => ({ ...c, value: c.name[bot.default_language] }))}
            defaultValue={lastCat ? lastCat.name[bot.default_language] : ''}
            onSelect={o => dispatch({ type: 'setCategorie', payload: o })}
            className="w-100p"
          />
        </Field>
      )}
      <div className="flex row">
        <div className="flex column grow-1 m-b-md">
          <Field title={w.pricing.pricingName} >
            <Input
              name="name"
              value={state.name[editedLang] || ''}
              onChange={e =>
                dispatch({ type: 'onChange', payload: e.target, lang: editedLang })
              }
            />
          </Field>
          {editedLang!==bot.default_language && (<span className="is-size-7 is-italic">{state.name[bot.default_language]}</span>)}
        </div>
      </div>
      <Field title={w.pricing.pricing} className="m-b-md">
        <Input
          name="price"
          type="number"
          value={state.price || ''}
          onChange={e => dispatch({ type: 'onChange', payload: e.target })}
        />
        <Button disabled>{w.generic.euro}</Button>
      </Field>
      <Field title={w.pricing.fullPricing} className="m-b-md">
        <Switch
          color="secondary"
          defaultChecked={state.full_price}
          onChange={() =>
            dispatch({ type: 'onCheck', payload: !state.full_price })
          }
        />
      </Field>
      {!state.full_price && (
        <div className="flex column grow-1 m-b-md">
          <Field
            title={w.pricing.grantee}
            className="w-100p"
          >
            <Stackarea
                key={editedLang}
              reg={regStack}
              defaultValue={(state.recipient[editedLang] || []).join(',')}
              onChange={str =>
                dispatch({
                  type: 'setRecipient',
                  payload: str.split(','),
                  lang: editedLang
                })
              }
              maxHeight={200}
              resize="auto"
            />
          </Field>
          {editedLang!==bot.default_language && (<span className="is-size-7 is-italic">{(state.recipient[bot.default_language] || []).join(',')}</span>)}
        </div>
      )}
      <div className="flex row">
        <div className="flex column grow-1 m-b-md">
          <Field title={w.pricing.information}>
            <Textarea
              value={state.description[editedLang] || ''}
              name="description"
              resize="auto"
              maxHeight={100}
              onChange={e =>
                dispatch({ type: 'onChange', payload: e.target, lang: editedLang })
              }
            />
          </Field>
          {editedLang!==bot.default_language && (<span className="is-size-7 is-italic">{state.description[bot.default_language]}</span>)}
        </div>
      </div>
      <Board
        {...props}
        onCancel={onClose}
        onConfirm={() => {
          //SAVE TARIF
          const { cat, ...item } = state;
          let newItems = items.filter(o => o.id !== edit.id);
          let catIndex = -1;
          if (cat || lastCat) {
            newItems.forEach((c, i) => {
              if (c.id === (cat ? cat.id : lastCat.id)) catIndex = i;
            });
          }
          newItems.splice(
            index !== -1
              ? index
              : catIndex !== -1
              ? catIndex + 1
              : newItems.length,
            0,
            item
          );
          onSave(newItems);
          onClose();
        }}
        disable={!state.name || !state.price}
      />
    </div>
  );
};

// Shared Component
const Board = props => {
  const {
    m: { w },
    onCancel,
    onConfirm,
    disable
  } = props;
  return (
    <div className="flex row space-between wrap">
      <Button className="is-uppercase" onClick={onCancel} color="secondary">
        {w.generic.cancel}
      </Button>
      <Button className="is-uppercase" onClick={onConfirm} disabled={disable}>
        {w.generic.validate}
      </Button>
    </div>
  );
};
