import { Params, Method } from './types';

class Api {
  token?: string;
  endpoint: string;
  constructor(endpoint: string, token?: string) {
    this.endpoint = endpoint;
    this.token = token;
  }

  get = async (body: Params = null, params: Params = null) => {
    return await this.fetch('', 'GET', body, params);
  };

  getById = async (id: number) => {
    return await this.fetch(`/${id}`);
  };

  post = async (body: Params = null) => {
    return await this.fetch('', 'POST', body);
  };

  put = async (id: number, body: Params = null) => {
    return await this.fetch(`/${id}`, 'PUT', body);
  };

  delete = async (id: number | string) => {
    return await this.fetch(`/${id}`, 'DELETE');
  };

  fetch = async (
    path = '',
    method: Method = 'GET',
    body?: Params,
    params?: Params
  ) => {
    let url = new URL(`${this.endpoint}${path}`);
    Object.entries(params || {}).forEach((b) => {
      if (b[1]) url.searchParams.append(b[0], b[1]);
    });
    let req = {
      method: method,
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: body ? JSON.stringify(body) : null,
    };

    return await fetch(url.href, req).then((res) => res.json());
  };
}

export default Api;
