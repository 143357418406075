import { useEffect } from 'react';

export default (props) => {
  useEffect(() => {
    props.dispatch({ type: 'setSub', payload: 2 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
