const Input = (template) => {
  switch (template) {
    case 'template1':
      return {
        v2: false,
        borderRadius: '',
        boxShadow: '',
      }
    case 'template2':
      return {
        v2: true,
        borderRadius: '10px',
        boxShadow: '',
      }
    case 'template3':
      return {
        v2: true,
        borderRadius: '25px',
        boxShadow: '',
      }
    case 'template4':
      return {
        v2: true,
        borderRadius: '25px',
        boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.5)',
      }
    case 'template5':
      return {
        v2: true,
        borderRadius: '10px',
        boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.5)',
      }
    default:
      break;
  }
}

export default Input;
