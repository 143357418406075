import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import { GET_BOT } from '../../_api/bot';
import { _sendFeedback } from '../../_tools/ui';
import Loader from '../../components/Loader';
import Portal from '../../components/Portal';
import Tutorial from '../../components/Tutorial';
import NavPrompt from '../../components/NavPrompt';
import Header from './schedule/Header';
import Scheduler from './schedule/Scheduler';
import Modal from '../../components/Modal';
import AddPlaceModal from '../../components/place/AddPlaceModal';

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasChange: false,
      place: null,
      addPlace: false,
      getInfo: false,
      fct: {
        onChange: this.onChange,
        onSave: this.onSave,
        onSelect: this.onSelect,
        onGetInfo: this.onGetInfo,
        onAddPlace: this.onAddPlace,
      },
    };
  }

  onChange = (oph) => {
    this.setState((state) => ({
      place: { ...state.place, opening_hours: oph },
      hasChange: true,
    }));
  };

  onSave = async () => {
    if (this.state.hasChange) {
      _sendFeedback('warning', 'loader');
      const {
        api,
        m: { w },
      } = this.props;
      const { place } = this.state;
      let res = null;
      if (place.sub)
        res = await api.place._updateSubPlace({
          id: place.pID,
          opening_hours: place.opening_hours,
        });
      else
        res = await api.place._updatePlace({
          id: place.pID,
          opening_hours: place.opening_hours,
        });
      if (res.success) {
        this.setState({ hasChange: false });
        _sendFeedback('success', w.success.saved);
      } else _sendFeedback('danger', w.error.generic);
    }
  };

  addPlace = async (place) => {
    if (place) {
      const {
        api,
        m: { w, bot },
        _getBot,
      } = this.props;
      if (typeof place.itinerary !== 'boolean') {
        _sendFeedback('warning', 'loader');
        let res = await api.place._setBotHasPlace({
          bot_id: bot.id,
          place_id: place.id,
          itinerary: false,
        });
        if (res.success) {
          this.setState({ addPlace: false });
          _sendFeedback('success', w.success.saved);
        } else _sendFeedback('danger', w.error.generic);
      }
      _getBot.refetch();
    }
  };

  onGetInfo = () => this.setState({ getInfo: true });
  onSelect = (item) => {
    item = item?.value?.includes('✩')
      ? {
          ...item,
          value: item.value.replace('✩', ''),
        }
      : { ...item };
    this.setState({ place: item, hasChange: false });
  };
  onAddPlace = () => this.setState({ addPlace: true });
  closeModal = () => this.setState({ addPlace: false, getInfo: false });

  UNSAFE_componentWillMount() {
    this.props.fct.changeSub(0);
  }

  render() {
    const {
      m: { w },
    } = this.props;
    const { place } = this.state;
    if (!this.props._getBot || this.props._getBot.loading)
      return <Loader className="has-text-primary" />;
    if (this.props._getBot.error) throw new Error(this.props._getBot.error);
    return (
      <div className="w-100p has-text-primary-ter relative overflow-scroll">
        <Portal
          component={<Header {...this.props} fct={this.state.fct} />}
          selector="base-header"
        />
        <Scheduler
          p={place || this.props._getBot.getBot.places[0]}
          {...this.props}
          {...this.state}
        />
        <Modal
          isOpen={this.state.getInfo}
          close={this.closeModal}
          selector="#root"
        >
          <Tutorial tuto={w.tutorial.schedule} close={this.closeModal} />
        </Modal>
        <Modal
          isOpen={this.state.addPlace}
          close={this.closeModal}
          selector="#root"
        >
          <AddPlaceModal
            onCancel={this.closeModal}
            subOpt={this.props._getBot.getBot.places}
            onAdd={this.addPlace}
          />
        </Modal>
        <NavPrompt when={this.state.hasChange} confirm={this.onSave} />
      </div>
    );
  }
}

const withBot = graphql(GET_BOT, {
  options: (ownProps) => {
    return {
      variables: {
        id: ownProps.m.bot.id,
      },
    };
  },
  name: '_getBot',
});

export default compose(withBot)(Schedule);
