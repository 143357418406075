import React from 'react';
import Loader from '../../../components/Loader';
import SelectAlert from './allAlert/SelectAlert';
import { withMain } from '../../../hoc/main';

const AllAlert = (props) => {
  const {
    all,
    select,
    fct,
    periods,
    error,
    bool,
    state,
    array,
    loader,
    m: { w },
  } = props;

  if (!all) return <Loader />;
  if (loader.bool && loader.action === 'create') return <Loader />;
  if (all.length === 0) return <div>{w.alertMessages.noAlert}</div>;
  if (array.length > 0) {
    return array.map((item, i) => {
      return (
        <React.Fragment key={i}>
          <SelectAlert
            item={item}
            index={i}
            fct={fct}
            select={select}
            periods={periods}
            error={error}
            bool={bool}
            state={state}
            w={w}
          />
        </React.Fragment>
      );
    });
  } else {
    return <div>Aucune alertes</div>;
  }
}

export default withMain(AllAlert);
