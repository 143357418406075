import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import StyledContainer from '../../../components/parameters/StyledContainers';
import StyledTitle from '../../../components/parameters/StyledTitle';
import InputRadio from '../../../components/parameters/InputRadio';
import StyledLabel from '../../../components/parameters/StyledLabel';
import Bot from '../../../_api/bot';
import { _sendFeedback } from '../../../_tools/ui';

const WrapperInline = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const SpanInfo = styled.span`
  font-weight: 300;
  font-size: 0.8em;
  text-decoration: ${(props) => (props.underline ? 'underline' : '')};
`;

const SpanStatus = styled.span`
  height: 25px;
  width: 25px;
  background-color: #7ed321;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 0.6rem;
`;

const Server = ({ m, setSaveFunction }) => {
  const [serverOnline, setServerOnline] = useState(server);
  const [stateServer, setStateServer] = useState(m.bot.status);
  const w = m.w.parameters;

  const server = m.bot.status === 'ok' ? true : false;

  useEffect(() => {
    if (setSaveFunction) {
      setSaveFunction(onSave);
    }
  }, [serverOnline]);
  const Api = new Bot(m.client, m.bot.id);

  const onSave = async () => {
    const bot = {
      id: m.bot.id,
      server: serverOnline,
    };
    if (Api && bot) {
      const res = await Api._updateBot(bot);
      if (res.success) {
        _sendFeedback('success', m.w.success.saved);
      } else {
        _sendFeedback('danger', m.w.error.general);
      }
    }
  };
  return (
    <StyledContainer width={'90'}>
      <StyledTitle>
        {w.serverInline}
        <SpanInfo underline>{w.serverInfo}</SpanInfo>
      </StyledTitle>
      <WrapperInline>
        <StyledLabel width={'50'} flex radio>
          <InputRadio
            type='radio'
            name='serverOnline'
            value={serverOnline}
            checked={serverOnline ? true : false}
            onChange={(e) => setServerOnline(!serverOnline)}
          />
          {w.yes}
        </StyledLabel>
        <StyledLabel width={'50'} flex radio>
          <InputRadio
            type='radio'
            name='serverOnline'
            value={serverOnline}
            checked={serverOnline ? false : true}
            onChange={(e) => setServerOnline(!serverOnline)}
          />
          {w.no}
        </StyledLabel>
      </WrapperInline>
      <StyledTitle>
        {w.stateServer}
        {stateServer === 'ok' && (
          <>
            <SpanStatus color={'#7ed321'}></SpanStatus>
            <SpanInfo>{w.online}</SpanInfo>
          </>
        )}
        {stateServer === 'down' && (
          <>
            <SpanStatus color={'#db7070'}></SpanStatus>
            <SpanInfo>{w.offline}</SpanInfo>
          </>
        )}
        {stateServer === 'pending' && (
          <>
            <SpanStatus color={'#F99B1C'}></SpanStatus>
            <SpanInfo>{w.inStaging}</SpanInfo>
          </>
        )}
      </StyledTitle>
    </StyledContainer>
  );
};

export default Server;
