import React, { memo, useContext, useState } from 'react';
import styled from 'styled-components';
import Susbar from './aside/Susbar';
import Topic from './topic/Topic';
import { Topics } from '../../types/qa/topics';
import { QAContext } from './context/QAContext';
import { Api } from '../../api/graphql/hooks/useTopics';

interface Props {
  topics: Topics;
  api: Api;
  botId: number;
  token: string;
  w: any;
}

const Content = memo((props: Props) => {
  const { state } = useContext(QAContext);

  return (
    <ContentContainer>
      <Susbar api={props.api} w={props.w} />
      <TopicsContainer>
        <Header>
          <Item>{props.w.theme}</Item>
          <Item>{props.w.answer}</Item>
          <Item>{props.w.options}</Item>
          <Item>{props.w.state}</Item>
        </Header>
        <>
          {state.topicStatus === 'all' &&
            state.topics.map((el) => (
              <Topic
                key={el.id}
                topic={el}
                api={props.api}
                botId={props.botId}
                token={props.token}
                w={props.w}
              />
            ))}
          {state.topicStatus === 'enabled' &&
            state.topics
              .filter((topic) => topic.enabled)
              .map((el) => (
                <Topic
                  key={el.id}
                  topic={el}
                  api={props.api}
                  botId={props.botId}
                  token={props.token}
                  w={props.w}
                />
              ))}
          {state.topicStatus === 'disabled' &&
            state.topics
              .filter((topic) => !topic.enabled)
              .map((el) => (
                <Topic
                  key={el.id}
                  topic={el}
                  api={props.api}
                  botId={props.botId}
                  token={props.token}
                  w={props.w}
                />
              ))}
        </>
      </TopicsContainer>
    </ContentContainer>
  );
});

const ContentContainer = styled.div`
  width: 100%;
  color: #645eb0;
  height: inherit;
  display: flex;
`;

const TopicsContainer = styled.div`
  width: 100%;
  min-height: 400px;
  border-left: 1px solid #ececec;
  height: fit-content;
`;

const Header = styled.div`
  background: #2d307b;
  color: #fff;
  height: auto;
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 4fr 2fr 1fr;
  justify-content: center;
  padding: 0.8rem 0.4rem;
`;

const Item = styled.div`
  place-self: center;
  padding: 0.25rem;
  color: #fff;
`;

export default Content;
