import React, { Component } from 'react';
import styled from 'styled-components';
//Base Components
import Foundations from '../components/base/Foundations';
import Sidebar, { Sub } from '../components/base/Sidebar';
import Header from '../components/base/Header';
import ErrorBoundary from '../components/ErrorBoundary';
import Portal from '../components/Portal';
import NavPrompt from '../components/NavPrompt';
import Loader from '../components/Loader';
import Nodata from '../components/Nodata';
//Context
import { withMain } from '../hoc/main';
import { withApi } from '../hoc/api';
//Tools
import { event as sections, _sendFeedback } from '../_tools/ui';
import { _getKeyValue, _delay } from '../_tools/other';
import { _getRange, _isUnique } from '../_tools/oph';
import { _updateTranslations } from '../_tools/translation';
//Section Components
import Information from './event/Information';
import Scheduler from './event/Scheduler';
import Translation from './event/Translation';
import PhoneCase from './event/PhoneCase';
//Api
import Bot from '../_api/bot';

import MUIButton from '@material-ui/core/Button';

class Event extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
      m: { bot },
    } = props;
    const places = _getKeyValue(bot, 'places');
    const types = _getKeyValue(bot, 'types');
    let place = places ? places[0] : null;
    this.mounted = true;
    this.state = {
      id: id,
      isUrlValid: true,
      isUrlTicketingValind: true,
      whileSubmit: false,
      bot: bot,
      subID: 0,
      hasChange: false,
      loading: false,
      event: {
        place: place ? { name: place.name, address: place.address } : null,
        place_id: place ? place.id : null,
        type: types ? types[0] : null,
      },
      error: null,
      errors: {},
      fct: {
        setTranslation: this.setTranslation,
        toggleFree: this.toggleFree,
        selectMark: this.selectMark,
        selectType: this.selectType,
        setPrice: this.setPrice,
        setPlace: this.setPlace,
        setSubPlace: this.setSubPlace,
        setImage: this.setImage,
        setOph: this.setOph,
        setDates: this.setDates,
        handleBadUrl: this.handleBadUrl,
        handleBadUrlTicketing: this.handleBadUrlTicketing,
      },
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async UNSAFE_componentWillMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id !== 'new') await this.getEvent(id);
    await this.getBot();
  }

  handleBadUrl = (isUrlValid) => {
    this.setState({
      isUrlValid,
    });
  };

  handleBadUrlTicketing = (isUrlTicketingValind) => {
    this.setState({
      isUrlTicketingValind,
    });
  };
  //Keep Bot up to date
  getBot = async () => {
    const {
      m: { bot, client },
    } = this.props;
    const cBot = await new Bot(client, bot.id)._getBot();
    if (cBot.success && this.mounted) this.setState({ bot: cBot.getBot });
  };

  getEvent = async (id) => {
    this.setState({ loading: true });
    const {
      api: { event },
    } = this.props;
    const res = await event._getEvent(
      { id: Number(id) },
      { fetchPolicy: 'no-cache' }
    );
    if (this.mounted) {
      this.setState({
        event: res.success ? res.getEvent : null,
        error: res.error || null,
        loading: false,
      });
    }
  };

  launchSubmit = async (published = true) => {
    const {
      api: { event },
      m: { w },
    } = this.props;
    const {
      bot,
      event: { translations, ...variables },
    } = this.state;
    const valid = event._checkRequirement(translations, variables);
    if (
      valid.success &&
      !this.state.whileSubmit &&
      this.state.isUrlValid &&
      this.state.isUrlTicketingValind
    ) {
      this.setState({ whileSubmit: true }, async () => {
        _sendFeedback('warning', 'loader');
        let args = event._formatVariables(variables);
        let res = null;
        let event_id = variables.id;
        res = !event_id
          ? await event._createEvent({
              bot_id: bot.id,
              ...args,
              published,
            })
          : await event._updateEvent({ ...args, published });
        if (res.success) {
          event_id = _getKeyValue(res, 'id');
          let published = _getKeyValue(res, 'published');
          let date_end = _getKeyValue(res, 'date_end');
          let date_start = _getKeyValue(res, 'date_start');
          this.setState((state) => ({
            event: {
              ...state.event,
              id: event_id,
              date_end: date_end,
              date_start: date_start,
              published,
            },
            hasChange: false,
            errors: {},
          }));
        } else _sendFeedback('danger', w.error.general);
        if (translations && translations.length) {
          translations.forEach(async (t) => {
            res = !t.id
              ? await event._setEventTranslation({ ...t, event_id })
              : await event._updateEventTranslation({ ...t, event_id });
          });
        }
        if (res.success) _sendFeedback('success', w.success.saved);
        else _sendFeedback('danger', w.error.general);
        this.setState({ whileSubmit: false });
      });
    } else if (!this.state.whileSubmit) {
      _sendFeedback('danger', w.error.general);
      this.setState({
        errors: valid.errors,
        subID: 0,
      });
    }
  };

  selectType = (item) => {
    this.setState((state) => ({
      event: { ...state.event, type: item.data },
      hasChange: true,
    }));
  };

  setDates = ({ startDate, endDate }) => {
    const date_start = startDate ? startDate.format('YYYY-MM-DD') : null;
    const date_end = endDate ? endDate.format('YYYY-MM-DD') : null;
    this.setState((state) => ({
      event: { ...state.event, date_start, date_end },
      hasChange: true,
    }));
  };

  setOph = (oph) => {
    this.setState((state) => ({
      event: { ...state.event, opening_hours: oph },
      hasChange: true,
    }));
  };

  setImage = (path) => {
    this.setState((state) => ({
      event: { ...state.event, main_image: path },
      hasChange: true,
    }));
  };

  setPlace = (place) => {
    this.setState((state) => ({
      event: {
        ...state.event,
        place: place,
        place_id: place.id,
        subplace: null,
        sub_place_id: null,
      },
      hasChange: true,
    }));
  };

  setSubPlace = (subplace) => {
    this.setState((state) => ({
      event: { ...state.event, subplace: subplace, sub_place_id: subplace.id },
      hasChange: true,
    }));
  };

  setTranslation = (e) => {
    const {
      event: { translations },
    } = this.state;
    const newTranslations = _updateTranslations(translations, e.target);
    this.setState((state) => ({
      event: { ...state.event, translations: newTranslations },
      hasChange: true,
    }));
  };

  toggleFree = () => {
    const {
      event: { free },
    } = this.state;
    this.setState((state) => ({
      event: { ...state.event, free: free ? false : true },
      hasChange: true,
    }));
  };

  setPrice = (e) => {
    const { name, value } = e.target;
    const {
      event: { price },
    } = this.state;
    let reg = new RegExp(/^\d+((\.\d+)?( - \d+(\.\d+)?)?)?$/gim);
    let newPrice = (price || '').match(reg) ? price : '';
    let parts = newPrice.split(' - ');
    switch (name) {
      case 'minPrice':
        parts[0] = value;
        break;
      case 'maxPrice':
        if (newPrice) parts[1] = value;
        else parts[0] = value;
        break;
      default:
        break;
    }
    newPrice = parts.join(' - ');
    this.setState((state) => ({
      event: { ...state.event, price: newPrice },
      hasChange: true,
    }));
  };

  selectMark = (item, name) => {
    const { event } = this.state;
    const newItems = event[name] || [];
    if (!newItems.find((i) => i.id === item.id))
      this.setState((state) => ({
        event: { ...state.event, [name]: newItems.concat(item.data) },
        hasChange: true,
      }));
    else
      this.setState((state) => ({
        event: {
          ...state.event,
          [name]: newItems.filter((i) => i.id !== item.id),
        },
        hasChange: true,
      }));
  };

  render() {
    const {
      m: { w },
    } = this.props;
    const { subID, id, error, loading } = this.state;
    return (
      <Foundations
        aside={
          <Sidebar
            c={this.state}
            addons={sections.map((s) => (
              <Sub
                noarrow={true}
                key={s.id}
                sub={{ ...s, title: w.subsections[s.w] }}
                active={subID === s.id}
                onClick={() => this.setState({ subID: s.id })}
              />
            ))}
          />
        }
        header={
          <Header
            title={`${w.generic.events}`}
            subtitle={`${
              id === 'new' ? w.event.eventCreation : w.event.eventEdition
            }`}
          />
        }
      >
        <Portal
          component={
            <Head
              {...this.props}
              launchSubmit={this.launchSubmit}
              onConfirm={() => {
                this.setState((state) => ({
                  ...state,
                  event: {
                    ...state.event,
                    date_start: this.state.preStart,
                    date_end: this.state.preEnd,
                  },
                }));
                this.launchSubmit(this.state.prepublished);
                this.setState({ prepublished: false, popup: false });
              }}
            />
          }
          selector="base-header"
        />
        {loading && <Loader className="has-text-primary-ter" />}
        {error && (
          <Nodata
            title={w.error.general}
            subtitle={process.env.REACT_APP_DEV ? error.message : ''}
          />
        )}
        {!error && !loading && subID === 0 && (
          <ErrorBoundary>
            <Information {...this.props} {...this.state} />
          </ErrorBoundary>
        )}
        {!error && !loading && subID === 1 && (
          <ErrorBoundary>
            <Scheduler {...this.props} {...this.state} />
          </ErrorBoundary>
        )}
        {!error && !loading && subID === 2 && (
          <ErrorBoundary>
            <Translation {...this.props} {...this.state} />
          </ErrorBoundary>
        )}
        {!error && !loading && subID !== 1 && (
          <ErrorBoundary>
            <PhoneCase {...this.props} {...this.state} />
          </ErrorBoundary>
        )}
        <NavPrompt when={this.state.hasChange} confirm={this.launchSubmit} />
      </Foundations>
    );
  }
}

const Head = ({ m: { w }, launchSubmit, whileSubmit, onConfirm }) => (
  <HeadContainer>
    <div className="m-l-md flex row grow-1 flex-end">
      <ButtonContainer>
        <MUIButton
          color="primary"
          variant="outlined"
          onClick={() => launchSubmit(false)}
          disabled={whileSubmit}
        >
          {w.event.draft}
        </MUIButton>
      </ButtonContainer>
      <MUIButton
        className="m-l-xs"
        color="primary"
        variant="contained"
        onClick={onConfirm}
        disabled={whileSubmit}
      >
        {w.event.publish}
      </MUIButton>
    </div>
  </HeadContainer>
);

export default withMain(withApi(Event));

const HeadContainer = styled.div`
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  margin-right: 10px;
`;
