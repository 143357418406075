import React from 'react';
import { Frame, Button } from '@ask-mona/ui';

export default ({ history }) => {
  return (
    <div className="has-content-centered">
      <div>
        <div
          className={`is-size-1 has-text-white has-text-weight-bold mw-4 m-b-md`}
        >
          <Frame background="transparent" style={{ width: '400px' }}>
            <img
              src={'http://askmonastudio.com/26/t78d24.png'}
              alt="Monat404"
            />
          </Frame>
        </div>
        <div className="flex center">
          <Button onClick={() => history.goBack()}>Retour</Button>
        </div>
      </div>
    </div>
  );
};

export const Nosection = ({ history }) => {
  return (
    <div className="has-content-centered">
      <div>
        <div
          className={`is-size-6 has-text-white has-text-weight-medium mw-4 m-b-md`}
        >
          Il n'existe aucune section valide pour ce bot.
        </div>
        <div className="flex center">
          <Button onClick={() => history.goBack()}>Retour</Button>
        </div>
      </div>
    </div>
  );
};
