import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { _sendFeedback } from '../../../_tools/ui';
import {
  _readImages,
  _addImages,
  getArtworkSignedUrl,
  uploadImage,
} from '../../../_api/upload';

const Visual = (props) => {
  const {
    m: { w },
    artwork: { images },
  } = props;
  return (
    <div className="p-md">
      <div className="p-b-xs has-text-weight-medium m-b-xs border-b-white-ter">
        {w.artwork.gallery} ({images.length})
      </div>
      <div className="m-b-md is-size-7 has-text-weight-normal">
        {w.artwork.gallerySub}
      </div>
      <Dropzone {...props} />
    </div>
  );
};

const Dropzone = (props) => {
  const {
    m: { w },
    artwork,
    dispatch,
    api,
  } = props;
  const [hovered, setHover] = useState(false);
  const [loadImage, setLoadImage] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState(0);
  const [progression, setProgression] = useState(0);
  const [totalImages, setTotalImages] = useState(0);
  const [toggleUpload, setToggleUpload] = useState(false);
  const apiArtwork = api.artwork;
  let artId = props.match.params.id;

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setToggleUpload(true);
      setTotalImages(acceptedFiles.length);
      setImagesToUpload(artwork.images.length + acceptedFiles.length);
      const acceptedFilesCloned = [...acceptedFiles];

      const arrFiles = new Array(Math.ceil(acceptedFiles.length / 100))
        .fill()
        .map((_) => acceptedFiles.splice(0, 100));

      let resUrl;
      let arrToUpload = [];
      let counter = 0;
      let currentImage = 0;

      const upload = async (counter2 = 0) => {
        if (counter2 === arrFiles.length) return;
        resUrl = await getArtworkSignedUrl(
          arrFiles[counter2],
          Number(artwork.id)
        );
        if (resUrl.success) {
          arrToUpload = resUrl.url;
          arrToUpload.forEach(async (el, i) => {
            const ext = acceptedFilesCloned[currentImage].type;
            let reader = new FileReader();
            reader.readAsArrayBuffer(acceptedFilesCloned[currentImage]);
            currentImage++;
            reader.onload = async () => {
              let res = await uploadImage(el.signedUrl, reader.result, ext);
              if (res.success) {
                counter++;
                const img = {
                  id: el.fileUrl,
                  filename: el.fileUrl,
                  path: el.fileUrl,
                };
                dispatch({ type: 'addImage', payload: img });
                if (counter === arrToUpload.length) {
                  counter = 0;
                  upload(counter2 + 1);
                }
              } else _sendFeedback('danger', w.error.general);
            };
          });
        }
      };
      await upload();
    },
    [artwork]
  );

  const syncArtwork = async () => {
    const res = await apiArtwork._syncArtworkFiles({ id: Number(artId) });
    if (res.success) {
      res.syncArtworkFiles &&
        _sendFeedback('success', w.feedback.sync_with_clarifai);
    }
  };

  useEffect(() => {
    if (imagesToUpload > artwork.images.length) {
      setLoadImage(true);
    } else {
      toggleUpload && syncArtwork();
      setToggleUpload(false);
      setLoadImage(false);
      setProgression(0);
      setImagesToUpload(0);
      toggleUpload && _sendFeedback('success', w.success.saved);
    }
  }, [artwork.images.length, imagesToUpload, loadImage]);

  useEffect(() => {
    if (imagesToUpload !== 0)
      setProgression((100 * artwork.images.length) / imagesToUpload);
  }, [progression, imagesToUpload, artwork.images.length]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*', onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject]
  );

  const { images } = artwork;

  return (
    <>
      {loadImage ? (
        <WrapperProgress>
          <div>{Math.round(progression)}%</div>
          <Progress progress={progression}>
            <div></div>
          </Progress>
        </WrapperProgress>
      ) : (
        <div
          {...getRootProps({ style })}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {!loadImage &&
            images.map((f) => (
              <img
                key={f.id}
                src={f.path}
                alt={f.filename}
                style={{ height: '105px', width: '159px' }}
                className="m-xs contain has-background-grey-lighter border-primary-lighter"
              />
            ))}
          <input {...getInputProps()} />
          {hovered && isDragActive && (
            <p className="flex pointer absolute top left overlay has-text-black-ter grow-1 w-100p h-100p center items-center">
              {w.generic.drop}
            </p>
          )}
          {hovered && !isDragActive && (
            <p className="flex pointer absolute top left overlay has-text-black-ter grow-1 w-100p h-100p center items-center">
              {w.generic.dropInfo}
            </p>
          )}
        </div>
      )}
    </>
  );
};

const WrapperProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Progress = styled.div`
  background: #ededf7;
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  display: flex;
  height: 15px;
  width: 500px;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));

  > div {
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: #7a7ab8;
    height: 100%;
    width: ${({ progress }) => progress || 0}%;
  }
`;

const baseStyle = {
  width: '100%',
  height: '25em',
  borderWidth: 1,
  borderColor: '#DBDBF0',
  borderStyle: 'dashed',
  overflow: 'scroll',
  position: 'relative',
};

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#FFD500',
  backgroundColor: '#eee',
};

const acceptStyle = {
  borderStyle: 'solid',
  borderColor: '#66CC33',
};

const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#CC3333',
};

export default Visual;
