import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Frame, Field, Input } from '@ask-mona/ui';
import { withGlobals } from '../hoc/globals';
import { _sendFeedback } from '../_tools/ui';
import logo from '../_assets/logoWhite.png';
import '../_styles/css/main.css';

class resetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: '',
      form: {
        password: '',
        token: props.match.params.token,
        passwordCheck: '',
      },
      error: {
        password: '',
        passwordCheck: '',
      },
    };
  }

  componentDidMount() {
    this.setLanguageCode();
  }

  setLanguageCode = () => {
    const {
      location: { search },
      g: { fct: { switchLang },
    }} = this.props;

    const languageCode = new URLSearchParams(search).get("language_code") || "en";
    switchLang(languageCode);
  }

  submit = async () => {
    const { w, user } = this.props.g;
    const { password, passwordCheck, token } = this.state.form;
    const errorPassword =
      'Password must contain at least 1 lowercase, 1 uppercase, 1 digit and be at least 6 characters long';
    if (password !== passwordCheck) {
      this.setState((state) => ({
        error: {
          password: w.error.passwordDoesNotMatch,
          passwordCheck: w.error.passwordDoesNotMatch,
        },
      }));
    } else {
      // NOTE: seems to be able to submit and succeed when password is an empty string
      const res = await user._updateAfterForget({ password, token });
      this.setState({ loading: true });
      if (!res.success || !res.updateAfterForget) {
        _sendFeedback(
          'danger',
          res.error.toString().includes(errorPassword)
            ? w.error.passwordBadlyFormatted
            : w.error.general
        );
      } else {
        _sendFeedback('success', w.form.passwordSuccess);
        this.props.history.push('/signin');
      }
    }
    this.setState({ loading: false });
  };

  onChange = ({ target: { name, value } }) => {
    this.setState((state) => ({
      form: { ...state.form, [name]: value },
      error: { ...state.error, [name]: '' },
    }));
  };

  render() {
    const { w } = this.props.g;
    const {
      form: { password, passwordCheck },
      error,
      loading,
      success,
    } = this.state;
    return (
      <div className="has-content-centered">
        <div className={`p-md w-4`}>
          <Frame background="transparent">
            <img
              src={logo}
              alt="AskMona-Logo"
              onLoad={() => this.setState({ loading: false })}
            />
          </Frame>
          <div className="has-background-white flex column p-md">
            <div>
              <Field
                title={w.form.password}
                subtitle={w.form.passwordSub}
                error={error.password}
                className="m-b-md"
              >
                <Input
                  name="password"
                  type="password"
                  value={password}
                  onChange={this.onChange}
                />
              </Field>
              <Field
                title={w.form.passwordCheck}
                subtitle={w.form.passwordCheckSub}
                error={error.passwordCheck}
                className="m-b-md"
              >
                <Input
                  name="passwordCheck"
                  type="password"
                  value={passwordCheck}
                  onChange={this.onChange}
                />
              </Field>
              {success && <div className="is-size-7">{success}</div>}
            </div>
            <div className="flex row space-between wrap">
              <Link to="/signin">
                <Button className="m-xs is-uppercase">
                  {w.generic.return}
                </Button>
              </Link>
              <Button
                loading={loading}
                className="m-xs is-uppercase"
                onClick={this.submit}
              >
                {w.generic.validate}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withGlobals(resetPassword);
