import React, { Component } from 'react';

class Animator extends Component {
  state = {
    mounted: this.props.isOpen,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      if (!this.props.delay) this.setState({ mounted: false });
      else
        setTimeout(() => this.setState({ mounted: false }), this.props.delay);
    } else if (!prevProps.isOpen && this.props.isOpen)
      this.setState({ mounted: true });
  }

  render() {
    const { children, enter, leave, isOpen } = this.props;
    const { mounted } = this.state;
    if (!mounted) return null;
    return <div className={`${isOpen ? enter : leave}`}>{children}</div>;
  }
}

export default Animator;
