import React from 'react';
import { Frame } from '@ask-mona/ui';
import Monatenance from '../../_assets/Monatenance.png';

const Maintenance = ({ g: { w } }) => (
  <div className="has-content-centered">
    <div>
      <Frame background="transparent">
        <img src={Monatenance} alt="Monatenance" />
      </Frame>
      <div className="is-size-1 has-text-white has-text-weight-bold">
        {w.generic.maintenance}
      </div>
    </div>
  </div>
);

export default Maintenance;
