import React from 'react';
import { withGlobals } from '../hoc/withGlobals';

const regLink = new RegExp(
  /(((http|https|ftp|ftps):\/\/)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*))/gi
);

const _hasLink = (str) => {
  if (str && typeof str === 'string') {
    return str.match(regLink) ? true : false;
  }
  return false;
};

const _toLinkedText = (str) => {
  return str.replace(
    regLink,
    '<a class="text-link" target="_blank" href="$1">$1</a>'
  );
};

const Text = ({ context: { skin }, text, to, ...props }) => {
  if (_hasLink(text))
    return (
      <div
        className={`
          iecrap
          p-md
          white-space-pre-line
          rad-5px
          word-break-break-word
          ${!to ? 'has-text-black' : 'has-text-white'}
          ${!to ? 'has-background-grey-lighter' : 'has-background-primary-ter'}
        `}
        dangerouslySetInnerHTML={{ __html: _toLinkedText(text) }}
      ></div>
    );
  else
    return (
      <div
        className={`
          iecrap
          p-md
          white-space-pre-line
          rad-5px
          word-break-break-word
          ${!to ? 'has-text-black' : 'has-text-white'}
          ${!to ? 'has-background-grey-lighter' : 'has-background-primary-ter'}
        `}
      >
        {text}
      </div>
    );
};

export default withGlobals(Text);
