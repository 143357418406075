export default {
  published: ["Nombre d'événements publiés", 'Number of events published'],
  unpublished: ['Événements non publiés', 'Unpublished events'],
  message: ['Nombre de messages en attente', 'Number of pending messages'],
  nbUser: ["Nombre d'utilisateurs", 'Number of users'],
  duration: ["Durée moyenne d'une session", 'Average length of a session'],
  nbSession: ['Nombre de session', 'Number of sessions'],
  perUser: ['par utilisateur', 'per user'],
  nbUsersFR: [
    "Pourcentage d'utilisateurs français",
    'Percentage of French users',
  ],
  nbClicEvts: ['Nombre clics Événements', 'Number of clicks on Events'],

  event: [
    'Boutons des événements les plus consultés ',
    'Buttons of the most viewed events',
  ],
  theme: ['Questions les plus posées ', 'Most frequently asked questions'],
  fct: ['Fonctionnalités les plus utilisées', 'Most used features'],
  thisMonth: ['Ce mois-ci', 'This month'],
  previous: ['Le mois dernier', 'Last month'],
  thisWeek: ['Cette semaine', 'This week'],
  default: ['Depuis le début', 'Since the beginning'],
  unavailable: ['Pas de données à afficher pour cette période', 'No data to display for this period'],

  now: ['actuellement', 'currently'],
  fromBeginning: ['depuis le lancement', 'since the launch'],
  searchBy: ["Chercher par", "Search by"],
  bot: ["Bot", "Bot"],
  searchBot: ['Chercher un chatbot', 'Search for a chatbot'],
  displayBot: [
    'Afficher seulement les chatbots en ligne',
    'Show only chatbots online',
  ],
  createBot: ['Créer un chatbot', 'Create a chatbot'],
  name: ['Nom du bot', 'Bot name'],
  customer: ["Client", "Customer"],
  nameCustomer: ['Nom du client', "Name of the client"],
  createdAt: ['Date de création', 'Creation date'],
  users: ['Utilisateurs', 'Users'],
  platform: ['Platforme', 'Platform'],
  number: ['N° du bot', 'Bot number'],
  status: ['Statut', 'Status'],
  newOrgaCreated: ['Nouvelle organisation créee', 'New organization created'],
  error: ['Une erreur est survenue.', 'An error has occurred.'],
  fillFields: [
    'Veuillez remplir les champs ci-dessus',
    'Please fill the fields above',
  ],
  orga: ["Nom de l'organisation", 'Name of the organization'],
  save: ['Enregistrer', 'Save'],
  cancel: ['Annuler', 'Cancel'],
  type: ['Type', 'Type'],
  chooseOrga: ['Choisir une organisation', 'Choose an organization'],
  createOrga: ['Créer une organisation', 'Create an organization'],
  chooseType: ['Choisir un type', 'Choose a type'],
  validate: ['Valider', 'Validate'],
  noDatas: ['Pas de données', 'No data'],
  notSpecified: ['Non renseigné', 'Not specified'],
  majBanner: ['Bannière mise à jour', 'Banner has been udpated'],
  messageActif: ["Aucun message d'alerte actif", 'No active alert message'],
  messagesActif: [" messages d'alerte actifs", ' active alert message'],
  multiSites: ["Multi-sites", "Multi-sites"],
  contentBanner: [
    "Retrouvez dans cet espace toutes les informations en lien avec le lancement de votre chatbot et l'ensemble de nos ressources",
    ,
    'Find in this space all the information related to the launch of your chatbot and all our resources',
  ],
  linkButton: ['Lien du bouton :', 'Link button :'],
  titleBanner: ['Mes ressources', 'My resources'],
  findOut: ['Découvrir', 'Discover'],
  seeAllStats: ["Voir l'ensemble des statistiques", 'See all statistics'],
  numberKey: ['Chiffres clés', 'Key figures'],
  noDatasAvailable: ['Aucune donnée disponible', 'No data available'],
};
