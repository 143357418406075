import React from 'react';
import { withGlobals } from '../hoc/withGlobals';
import Conversation from './Conversation';
import Header from './Header';

const Webchat = ({ context: { isMobile, skin, menu }, w }) => {
  return (
    <div
      className={`flex column overflow-hidden animation-fadeInFast w-100p h-100`}
    >
      <Header w={w} />
      <Conversation w={w} />
    </div>
  );
};

export default withGlobals(Webchat);
