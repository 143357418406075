export default {
  alertMessages: ["Messages d'alerte", 'Alert Messages'],
  create: ['Créer une alerte', 'Create an alert'],
  alert: ['Vos alertes', 'Your alerts'],
  schedule: ['Enregistrer votre alerte', 'Register your alert'],
  addDateAtLeast: ['Ajouter au moins une date', 'Add at least one date'],
  addDate: ['Ajouter une date', 'Add a date'],
  addPeriod: ['Ajouter une période', 'Add a period'],
  addHours: ['Ajouter des horaires', 'Add schedules'],
  content: ['Contenu de votre alerte', 'Content of your alert'],
  addContent: ["Ajouter du contenu à votre alerte", "Add content to your alert"],
  scheduleAlert: ['Programmer votre alerte', 'Schedule your alert'],
  noAlert: ["Aucun message d'alerte", 'No alert messages'],
  validate: ['Valider les modifications', 'Save'],
};
