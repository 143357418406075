import React from 'react';
import styled from 'styled-components';
import Video from '../../../components/Video';
import List from '../../../components/scenario/components/List';
import { Image } from '@ask-mona/ui';

const ContentHistory = ({ type, content, w, buttons, text }) => {
  switch (type) {
    case 'img':
    case 'gif':
      return (
        <Image
          src={content !== 'placeholder.png' ? content : ''}
          placeholder={w.generic.imgPlaceholder}
          fixed
        />
      );
    case 'vid':
    case 'youtube':
    case 'dailymotion':
      return (
        <Video
          src={content !== 'placeholder.webm' ? content : ''}
          placeholder={w.generic.vidPlaceholder}
          fixed
        />
      );
    case 'list':
      return <List element={JSON.parse(content)} />;
    case 'button':
      return (
        <Container>
          <WrapperText>{text}</WrapperText>
          {buttons.map((b, bIndex) => (
            <Buttons key={bIndex}>{b.title}</Buttons>
          ))}
        </Container>
      );
    default:
      return <p>{content}</p>;
  }
};

const Container = styled.div`
  border-radius: 15px;
`;

const WrapperText = styled.div`
  background-color: #7a7ab8;
  border-radius: 15px 15px 0px 0px;
  min-height: 50px;
  display: flex;
  color: #fff;
  padding: 0.6rem;
`;

const Buttons = styled.div`
  color: #2d307b;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  padding: 0.6rem;
  text-align: center;

  &:last-child {
    border-radius: 0px 0px 15px 15px;
  }
`;

export default ContentHistory;
