import slugify from 'slugify';
import moment from 'moment';

export const _rotateArray = (array, times) => {
  while (times--) {
    var temp = array.shift();
    array.push(temp);
  }
};

export const _delay = (delayInms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
};

export const _arrayMove = (arr, fromIndex, toIndex) => {
  let element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};

export const _checkPassword = (str) => {
  return /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/.test(str);
};

export const _random = (len = 20) => {
  let charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let ret = [];
  for (let i = 0, length = charset.length; len > i; i++) {
    ret[i] = charset[Math.floor(Math.random() * length)];
  }
  return ret.join('');
};

export const _mergeRecursive = (base, other) => {
  let newBase = Object.assign({}, base);
  for (var key in other) {
    try {
      if (typeof other[key] === 'object' && !Array.isArray(other[key]))
        newBase[key] = _mergeRecursive(newBase[key], other[key]);
      else newBase[key] = other[key];
    } catch (err) {
      newBase[key] = other[key];
    }
  }
  return newBase;
};

export const _mergePlaces = (base) => {
  const places = [];
  base.forEach((p) => {
    const { id, sub_places, ...other } = p;
    places.push({ id: _random(), pID: id, sub: false, ...other });
    if (sub_places)
      sub_places.forEach((s) => {
        const { id, ...other } = s;
        places.push({ id: _random(), pID: id, sub: true, ...other });
      });
  });
  return places;
};

export const _formatAddressComponent = (address_components) => {
  let format = {};
  address_components.forEach((obj) => {
    const newItem = {
      [obj.types[0]]: obj.long_name,
    };
    format = { ...format, ...newItem };
  });
  return format;
};

export const _getKeyValue = (obj, key) => {
  let found = null;
  if (typeof obj === 'object') {
    for (let k in obj) {
      if (k === key) found = obj[k];
      else if (typeof obj[k] === 'object' && !Array.isArray(obj[k]))
        found = _getKeyValue(obj[k], key);
      if (found) return found;
    }
  }
  return found;
};

export const _matchKey = (obj, regExp) => {
  let found = false;
  if (typeof obj === 'object') {
    for (let k in obj) {
      if (k.match(regExp)) found = true;
      else if (typeof obj[k] === 'object' && !Array.isArray(obj[k]))
        found = _matchKey(obj[k], regExp);
      if (found) return found;
    }
  }
  return found;
};

export const _sortTranslations = (translations = []) => {
  let ret = {};
  translations.forEach((t) => {
    let {
      language: { code },
      ...other
    } = t;
    ret = { ...ret, [code]: other };
  });
  return ret;
};

export const _splitReverseJoin = (str, delim, joint) => {
  return str ? str.split(delim).reverse().join(joint) : '';
};

export const _slugify = (str = '') => {
  return slugify(str, {
    replacement: '_',
    remove: /[^\w]+/g,
    lower: true,
  });
};

export const _isImg = (str) => {
  return /(?!\s)https?:\/\/.*(.png|.jpg|.gif)/.test(str);
};

export const _isVid = (str) => {
  return /(?!\s)https?:\/\/.*(.mov|.mp4|.ogg|.webm)/.test(str);
};

export const _isYoutube = (str) => {
  return /https:\/\/(www.)?youtu(\.be|be.com)?\/(embed\/)?[\w?=-]*/.test(str);
};

export const _isDailymotion = (str) => {
  return /https:\/\/(www.)?dai(\.ly|lymotion.com)\/(embed\/)?(video\/)?\w*/.test(
    str
  );
};

export const _findType = (str) => {
  if (_isImg(str)) return 'img';
  if (_isVid(str)) return 'vid';
  if (_isYoutube(str)) return 'youtube';
  if (_isDailymotion(str)) return 'dailymotion';
  return null;
};

export const _urlToEmbedVid = (url) => {
  const vidId = url.match(/[\w-]*$/);
  if (_isYoutube(url)) return `https://www.youtube.com/embed/${vidId[0]}`;
  if (_isDailymotion(url))
    return `https://www.dailymotion.com/embed/video/${vidId[0]}`;
  return url;
};

export const daysInMonth = (iMonth, iYear) => {
  return new Date(iYear, iMonth, 0).getDate();
};

export const timestampToDate = (scheduled) => {
  const date = new Date(scheduled * 1000);
  return date;
};

export const dateFormat = () => {
  const res = moment(new Date()).format('L').split('/');
  return [res[2], res[1], res[0]].join('-');
};

export const statut = (scheduled) => {
  const dateFinal = timestampToDate(scheduled);
  moment.locale('fr');
  let date = moment(dateFinal).format('L');
  let hour = moment(dateFinal).format('LT');

  let now = new Date();
  moment.locale('fr');
  const dateNow = moment(now).format('L');
  let hourNow = moment(now).format('LT');
  const dNow = dateNow.split('/');
  const d = date.split('/');

  const finalDN = new Date([dNow[2], dNow[1], dNow[0]].join('-'));
  const finalD = new Date([d[2], d[1], d[0]].join('-'));

  hour = hour.split(':');
  hourNow = hourNow.split(':');

  if (finalD > finalDN) {
    return true;
  } else if (finalD.getTime() === finalDN.getTime()) {
    if (hour[0] > hourNow[0]) {
      return true;
    } else if (hour[0] === hourNow[0]) {
      if (hour[1] > hourNow[1]) {
        return true;
      } else return false;
    } else return false;
  } else return false;
};

export const _checkRanges = (arrays = []) => {
  const ranges = Object.values(arrays).map((o) => [Number(o[0]), Number(o[1])]);

  let sortedArr = ranges.sort((a, b) => {
    return a[0] - b[0];
  });

  let tables = sortedArr.flat();

  let tmp = 0;
  for (let i = 0, len = tables.length; i < len; i++) {
    if (tables[i] < tmp) return false;
    tmp = tables[i];
  }
  return true;
};

/**
 * Convert Timestamp to date
 * @param {Int} timestamp timestamp
 * @return {Int} time in YYYY-MM-DD
 */
export const convertToFormattedDate = (time) => {
  let date = new Date(time);
  let formattedString = date.getFullYear() + '-';
  if (date.getMonth() < 9) {
    formattedString += '0';
  }
  formattedString += date.getMonth() + 1;
  formattedString += '-';
  if (date.getDate() < 10) {
    formattedString += '0';
  }
  return (formattedString += date.getDate());
};
