import React, { Component } from 'react';
import { withGlobals } from '../hoc/withGlobals';
import Loader from './Loader';

class QuickReply extends Component {
  state = {
    hasBeenClicked: false,
  };

  render() {
    const { title } = this.props;
    const { hasBeenClicked } = this.state;
    if (!this.props.isSsl && this.props.payload === 'location') return null;
    return (
      <div
        className={`m-xs animation-fadeIn webchat_button rad-15px quickreply-grey-light ${
          hasBeenClicked ? 'is-loading' : ''
        }`}
      >
        {hasBeenClicked ? <Loader /> : <span>{title}</span>}
      </div>
    );
  }
}

export default withGlobals(QuickReply);
