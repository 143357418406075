export default {
  link: ['Lier', 'Link'],
  menu: ['Menu', 'Menu'],
  action: ['Action', 'Action'],
  actions: ['Action groupée', 'Joint action'],
  active: ['activé', 'activated'],
  add: ['Ajouter', 'Add'],
  addBtn: ['Ajouter un bouton', "Add a button"],
  addImg: [
    "Vous pouvez ajouter une image en la téléchargeant depuis votre ordinateur ou en renseignant le lien terminant par .jpg ou .png d'une image déjà hébergée en ligne.",
    'You can add an image by uploading it from your computer or by filling in the link ending in .jpg or .png of an image already hosted online.',
  ],
  addLst: ['Ajouter un élément', 'Add an item'],
  addPeriod: ['Ajouter une période', 'Add a period'],
  addPlace: ['Ajouter un lieu', 'Add a location '],
  addVid: [
    'Vous pouvez ajouter une vidéo en copiant dans le champ ci-dessous son URL Youtube.',
    'You can add a video by copying its Youtube URL in the field below.',
  ],
  an: ['un', 'a'],
  and: ['et', 'and'],
  at: ["au", "at"],
  at_time: ["à", "at"],
  mediaImg: ['media image', 'media image'],
  mediaVid: ['media vidéo', 'video media'],
  answer: ['Réponse', 'Answer'],
  cancel: ['annuler', 'cancel'],
  closed: ["Fermé", "Closed"],
  content: ["Contenu", "Content"],
  copy: ['Copier', 'Copy'],
  create: ['Créer ', 'Create '],
  audio: ['Son', 'Sound'],
  date: ['Date', 'Date'],
  dateEnd: ['Date de fin', 'End Date'],
  dateStart: ['Date de début', 'Start Date'],
  scenarioDelete: ['Souhaitez-vous vraiment supprimer ce scénario ?', "Do you really want to remove this scenario?"],
  delete: ['Supprimer', "Delete"],
  delBtn: ['Supprimer le bouton', "Delete the button"],
  desactive: ['desactivé', 'deactivated'],
  description: ['Description', 'Description'],
  disconnect: ['Déconnexion', 'Disconnect'],
  drop: ['Déposez les fichiers ici...', 'Drop the files here...'],
  dropInfo: [
    'Vous pouvez déposer vos fichiers ici ou les séléctionner en cliquant dans le cadre',
    'You can upload your files here or select them by clicking in the',
  ],
  english: ['Anglais', 'English'],
  euro: ['€', '€'],
  event: ['Événement', 'Event'],
  events: ['Événements', 'Events'],
  fem: ['e', 'e'],
  for: ['Pour', 'For'],
  forgetPassword: ['Mot de passe oublié', 'Forgot your password?'],
  free: ['Gratuit', 'Free'],
  french: ['Français', 'French'],
  from: ['Du', 'from'],
  from_place: ['de', 'from'],
  generic: ['Carroussel', 'Carousel'],
  gestion: ['Gestion', 'Management'],
  gif: ['GIF', 'GIF'],
  hFrom: ['De', 'from'],
  hTo: ['à', 'to'],
  image: ['Image', 'image'],
  imgPlaceholder: ["Saisir ici l'URL d'une image ou la télécharger", "Enter the URL of an image here or download it"],
  language: ['Langues', 'Languages'],
  list: ['Liste', 'List'],
  maintenance: ['Le serveur est en maintenance', 'The server is under maintenance'],
  mediation: ['Parcours', 'Course'],
  monument: ["monument", "monument"],
  museum: ["musée", "museum"],
  newDate: ['Nouvelle date', 'New date'],
  no: ['Non', 'No'],
  open: ['Ouvert', 'Open'],
  or: ['Ou', 'Or'],
  permanent: ['Permanent', 'Permanent'],
  placeholder: ['Saisir votre texte ici', 'Enter your text here'],
  plural: ['s', 's'],
  pricing: ['Tarifs', 'Rates'],
  prompt: [
    'Souhaitez-vous enregistrer avant de quitter cette page ?',
    'Would you like to register before leaving this page?',
  ],
  random: ['contenu aléatoire', 'random content'],
  return: ['retour', 'return'],
  roles: ["rôles", "roles"],
  save: ['Enregistrer', 'save'],
  search: ['Rechercher', 'Search'],
  seconds: ['seconde(s)', "second(s)"],
  selectPlace: ['Sélectionnez un lieu', 'Select a location'],
  send: ['Envoyer', 'Send'],
  sendToCustom: [
    'Envoyer vers les modules personnalisés',
    'Send to custom modules',
  ],
  signin: ['Se connecter', 'log in'],
  signup: ["S'enregistrer", 'Register'],
  text: ['Texte', 'text'],
  the: ['Le', 'The'],
  title: ['Titre', 'Title'],
  to: ['au', 'to'],
  to_place: ['à', 'to'],
  user: ["utilisateur", "user"],
  validate: ['Valider', 'validate'],
  vidPlaceholder: ["Saisir le lien d'une vidéo", 'Enter the link of a video'],
  video: ['Vidéo', 'video'],
  pushs: ['push', 'push'],
  waiting: ['En attente', 'On hold'],
  waitingValidation: ['En attente de validation', 'Pending validation'],
  yes: ['Oui', 'Yes'],
  status: ['Statut', 'Status'],
  progress: ['En cours', 'In progress '],
  sent: ['Envoyé', 'Sent'],
  draft: ['Brouillon', 'Draft'],
  edit: ['Modifier', 'Edit'],
  schedule: ['Programmer', 'Program'],
  saveContent: ['Sauvergarder le contenu', 'Save Content'],
  back: ['Retour', 'Back'],
  selectDate: ['Choisissez une date', 'Choose a Date'],
  iframe: ['Iframe', 'Iframe'],
  scopeAnswer: ['Scope de la réponse:', 'Scope of the response:'],
};
