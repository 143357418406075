import React, { useState, useEffect } from 'react';
import Client from '../Client';

type User = {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  permissions: number;
};

type Users = Array<User>;

const useUsers = (botId: number, token: string, organization_id) => {
  const [users, setUsers] = useState<Users>([]);
  const [loading, setLoading] = useState<string>('');
  const [error, setError] = useState<any>();

  // This request is still using the V2 api, you will just have to replace the apiUrl during the migration to REACT_APP_HTTP_LINK_V3
  let client = new Client(`${process.env.REACT_APP_HTTP_LINK}`, token);

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        if (client) {
          let api = client.User;
          let res = await api.getUsers({
            organization_id,
          });
          if (res) {
            console.log(res);
            setUsers(res.data.getUsers.users);
          }
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading('load');
      }
    };
    getAllUsers();
  }, []);

  return {
    users,
    loading,
    error,
  };
};

export default useUsers;
