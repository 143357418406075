import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { iframeTemplate } from '../Reducer';
import { Check } from '@styled-icons/boxicons-regular/Check';

const Iframe = (props) => {
  const [valueIframe, setValueIframe] = useState('');
  const [toWebview, setToWebview] = useState(false);

  useEffect(() => {
    if (props.toWebview) setToWebview(true);
  }, []);

  const src = props.element.attachment.payload.src;
  const url = props.element.attachment.payload.url;

  return src || url ? (
    <>
      {src && (
        <WrapperContent>
          <div
            dangerouslySetInnerHTML={{
              __html: src.match(
                /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g
              )[0],
            }}
          ></div>
          <div>
            {toWebview ? 'Sous forme de webview' : 'Sous forme de message'}
          </div>
        </WrapperContent>
      )}
      {url && (
        <WrapperContent>
          <div
            dangerouslySetInnerHTML={{
              __html: url,
            }}
          ></div>
          <div>
            {toWebview ? 'Sous forme de webview' : 'Sous forme de message'}
          </div>
        </WrapperContent>
      )}
    </>
  ) : (
    <WrapperContent>
      <WrapperSubmit>
        <StyledInput
          value={valueIframe}
          type="text"
          placeholder={'Insérez la balise iframe'}
          onChange={(e) => {
            setValueIframe(e.target.value);
          }}
        />
        <StyledIcon
          size={'20'}
          onClick={() => {
            props.dispatch({
              type: 'onElemChange',
              payload: {
                index: props.index,
                value: iframeTemplate(valueIframe, toWebview),
              },
            });
          }}
        />
      </WrapperSubmit>
      <WrapperInput>
        <label for="webview">Afficher l'element sous la forme de webview</label>
        <input
          id="webview"
          name="webview"
          type="checkbox"
          checked={toWebview}
          onChange={() => setToWebview(!toWebview)}
        />
      </WrapperInput>
    </WrapperContent>
  );
};

const StyledInput = styled.input`
  color: #2d2f7b;
  padding: 0.6rem;
  margin-top: 0.6rem;
  border: 1px solid #2d2f7b;
  border-radius: 15px;
  height: 10px;
  margin: auto 0;

  &::placeholder {
    color: #2d2f7b;
  }
`;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
`;

const WrapperSubmit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperInput = styled.div`
  margin-top: 0.8rem;
  > label {
    margin-right: 0.4rem;
  }
`;

const StyledIcon = styled(Check)`
  color: #a3e085;
  cursor: pointer;
  transition: all 0.6s;
  border: 1px solid;
  border-radius: 25px;
  margin-left: 0.4rem;

  &:hover {
    transform: scale(1.2);
  }
`;

export default Iframe;
