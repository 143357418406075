import React from 'react';
import { withGlobals } from '../hoc/withGlobals';

const Icon = ({ context: { skin }, icon, size, color }) => (
  <div className={`webchat_icon has-text-${color} is-${size || 'normal'}`}>
    <i className={icon}></i>
  </div>
);

export default withGlobals(Icon);
