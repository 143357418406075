import React, { useState } from 'react';
import { Textarea } from '@ask-mona/ui';

const FuncText = ({ element, required, dispatch }) => {
  const [focus, setFocus] = useState(false);
  const { text, name, placeholder, arrayelement } = element;
  return (
    <div
      className={`p-xs w-50p has-text-white rad-15px has-background-${
        focus ? 'primary-bis' : 'primary-ter'
      } flex center ${required && !text ? 'border-danger' : ''}`}
    >
      <Textarea
        color="danger"
        resize="auto"
        name={name}
        placeholder={placeholder || ''}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        value={text || ''}
        onChange={(e) => {
          dispatch({
            type: 'onFuncChange',
            payload: e.target,
            arraypayload: arrayelement,
          });
        }}
        style={{ width: '100%' }}
        className="is-transparent"
      />
    </div>
  );
};

export default FuncText;
