import Schema from './Schema';
import gql from 'graphql-tag';

class Intents extends Schema {
  getIntents = async (variables?: object) =>
    await this.query(GET_INTENTS, variables);
}

const GET_INTENTS = gql`
  query getIntents($languageCode: String, $botId: Int!) {
    getIntents(bot_id: $botId, language_code: $languageCode) {
      intents {
        id
        title
        topic {
          id
          name
        }
        groupEntities {
          id
          title
          topic {
            id
            name
          }
        }
      }
    }
  }
`;

export default Intents;
