import gql from 'graphql-tag';
import { _mutate, _query } from './client';

class City {
  constructor(client, data) {
    this.client = client;
    this.data = data;
  }

  _getCity = async () => {
    let city = this.data.locality || this.data.postal_town;
    if (!city) return null;
    city = city.split('-')[0] === 'Paris' ? 'Paris' : city;
    let res = await _query(this.client, GET_CITY, { name: city });
    return res;
  };

  _createCity = async () => {
    let createCountry = false,
      createRegion = false,
      city_name = this.data.locality || this.data.postal_town,
      region_name =
        this.data.administrative_area_level_1 ||
        this.data.administrative_area_level_2 ||
        this.data.neighborhood,
      country_name = this.data.country,
      region = await _query(this.client, GET_REGION, { name: region_name }),
      country = await _query(this.client, GET_COUNTRY, { name: country_name });
    if (!region.success || !region.getRegion) {
      if (!country.success || !country.getCountry) {
        country = await _mutate(this.client, CREATE_COUNTRY, {
          name: country_name,
        });
        if (!country.success) return country;
        createCountry = true;
      }
      region = await _mutate(this.client, CREATE_REGION, {
        name: region_name,
        country_id: country[createCountry ? 'createCountry' : 'getCountry'].id,
      });
      if (!region.success) return region;
      createRegion = true;
    }
    let city = await _mutate(this.client, CREATE_CITY, {
      name: city_name,
      region_id: region[createRegion ? 'createRegion' : 'getRegion'].id,
    });
    return city;
  };
}

export default City;

const GET_CITY = gql`
  query($id: Int, $name: String) {
    getCity(id: $id, name: $name) {
      id
      name
    }
  }
`;

const CREATE_CITY = gql`
  mutation($name: String!, $region_id: Int) {
    createCity(name: $name, region_id: $region_id) {
      id
      name
    }
  }
`;

const GET_REGION = gql`
  query($id: Int, $name: String) {
    getRegion(id: $id, name: $name) {
      id
    }
  }
`;

const CREATE_REGION = gql`
  mutation($country_id: Int!, $name: String!) {
    createRegion(country_id: $country_id, name: $name) {
      id
    }
  }
`;

const GET_COUNTRY = gql`
  query($id: Int, $name: String) {
    getCountry(id: $id, name: $name) {
      id
    }
  }
`;

const CREATE_COUNTRY = gql`
  mutation($name: String!) {
    createCountry(name: $name) {
      id
    }
  }
`;
