import QR from './QR';
import form from './form';
import error from './errors';
import bot from './bot';
import sections from './sections';
import subsections from './subsections';
import place from './place';
import generic from './generic';
import feedback from './feedback';
import module from './module';
import events from './events';
import event from './event';
import success from './success';
import schedule from './schedule';
import inbox from './inbox';
import pricing from './pricing';
import direction from './direction';
import account from './account';
import med from './mediations';
import artwork from './artwork';
import params from './params';
import tutorial from './tutorials';
import pushs from './pushs';
import push from './push';
import parameters from './parameters';
import webchat from './webchat';

import scenario from './scenario';
import qa from './qa';
import checklist from './checklist';
import homepage from './homepage';
import alertMessages from './alertMessages';
import { days, fullDays, months, fullMonths } from './oph';

export { days, fullDays, months, fullMonths };

export const languages = Object.freeze({
  fr: 1,
  en: 2,
  de: 3,
  it: 5,
  es: 6,
  pt: 7,
});

export const wording = {
  qa,
  event,
  success,
  generic,
  error,
  QR,
  form,
  sections,
  subsections,
  bot,
  place,
  feedback,
  module,
  events,
  schedule,
  inbox,
  pricing,
  direction,
  account,
  med,
  artwork,
  params,
  tutorial,
  scenario,
  checklist,
  homepage,
  pushs,
  push,
  alertMessages,
  parameters,
  webchat
};

export const _extractByLang = (obj, lang) => {
  const index = Object.keys(languages).findIndex((l) => l === lang);
  let newObj = {};
  for (let key in obj) {
    if (Array.isArray(obj[key]))
      newObj = { ...newObj, [key]: obj[key][index] || 'undefined' };
    else if (typeof obj[key] === 'object')
      newObj = { ...newObj, [key]: _extractByLang(obj[key], lang) };
  }
  return newObj;
};
