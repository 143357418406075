export const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

export const days = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];

export const fullDays = [
  ['Dimanche', 'Sunday'],
  ['Lundi', 'Monday'],
  ['Mardi', 'Tuesday'],
  ['Mercredi', 'Wednesday'],
  ['Jeudi', 'Thursday'],
  ['Vendredi', 'Friday'],
  ['Samedi', 'Saturday'],
];

export const fullMonths = [
  ['Janvier', 'January'],
  ['Février', 'February'],
  ['Mars', 'March'],
  ['Avril', 'April'],
  ['Mai', 'May'],
  ['Juin', 'June'],
  ['Juillet', 'July'],
  ['Août', 'August'],
  ['Septembre', 'September'],
  ['Octobre', 'October'],
  ['Novembre', 'November'],
  ['Décembre', 'December'],
];
