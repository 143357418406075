import React from 'react';

export const Api = React.createContext();

export const withApi = (Component) => {
  return (props) => {
    return (
      <Api.Consumer>
        {(context) => <Component {...props} api={context} />}
      </Api.Consumer>
    );
  };
};
