import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import Modal from '../../components/Modal';
import Mediation, { GET_MEDIATIONS } from '../../_api/mediation';
import { GET_SUB_PLACES } from '../../_api/place';
import { _getKeyValue } from '../../_tools/other';
import Portal from '../../components/Portal';
import Loader from '../../components/Loader';
import Tutorial from '../../components/Tutorial';
import { _sendFeedback } from '../../_tools/ui';
import { Header, Heading, Delete } from './list/Ui';
import Mediations from './list/Mediations';
import NewMed from './NewMed';

class List extends Component {
  constructor(props) {
    super(props);
    this.Mediation = null;
    this.state = {
      getInfo: false,
      create: false,
      mediations: [],
      mediation: null,
      action: null,
      fct: {
        onAddMediation: this.onAddMediation,
        onGetInfo: this.onGetInfo,
        onListMediation: this.onListMediation,
        ppMediations: this.ppMediations,
        onSelect: this.onSelect,
        onConfirm: this.onConfirm,
      },
    };
  }

  componentDidMount() {
    const {
      m: { client },
    } = this.props;
    this.Mediation = new Mediation(client);
  }

  ppMediations = (id) => {
    let mediations = this.state.mediations || [];
    if (id < 0) {
      if (mediations.length) mediations = [];
      else {
        let list = _getKeyValue(this.props.data, 'getMediations') || [];
        mediations = list.map((o) => o.id);
      }
    } else {
      if (this.state.mediations.find((m) => m === id))
        mediations = this.state.mediations.filter((n) => n !== id);
      else mediations = this.state.mediations.concat(id);
    }
    this.setState({ mediations });
  };

  onGetInfo = () => this.setState((state) => ({ getInfo: !state.getInfo }));

  onSelect = (item) => {
    this.setState({ action: item.id });
  };

  onAddMediation = () => {
    this.setState({ create: true });
  };

  editMediation = (id) => {
    this.props.history.push(`/office/mediation/${id}`);
  };

  onConfirm = async () => {
    const {
      m: { w },
    } = this.props;
    const { mediations, action } = this.state;
    if (mediations.length && action !== null) {
      _sendFeedback('warning', 'loader');
      try {
        mediations.forEach(async (id) => {
          let res = null;
          if (action === 0)
            res = await this.Mediation._updateMediation(
              { id: id, published: true },
              { refetchQueries: ['getMediations'] }
            );
          else if (action === 1)
            res = await this.Mediation._deleteMediation(
              { id: id },
              { refetchQueries: ['getMediations'] }
            );
          if (res && !res.success) throw new Error(res.error);
        });
        _sendFeedback('success', w.success.saved);
        this.setState({ action: null, mediations: [] });
      } catch (err) {
        console.log(err);
        _sendFeedback('danger', w.error.general);
      }
    }
  };

  onListMediation = async (e, type) => {
    const {
      m: { w },
    } = this.props;
    switch (type) {
      case 'toggle':
        _sendFeedback('warning', 'loader');
        const res = await this.Mediation._updateMediation(
          { id: e.id, published: !e.published },
          { refetchQueries: ['getMediations'] }
        );
        if (res.success) _sendFeedback('success', w.success.saved);
        else _sendFeedback('danger', w.error.general);
        break;
      case 'delete':
        this.setState({ mediation: e });
        break;
      case 'pp':
        this.ppMediations(e.id);
        break;
      case 'edit':
        this.editMediation(e.id);
        break;
      default:
        break;
    }
  };

  deleteMediation = async () => {
    const {
      m: { w },
    } = this.props;
    const { mediation } = this.state;
    _sendFeedback('warning', 'loader');
    let res = await this.Mediation._deleteMediation(
      { id: mediation.id },
      { refetchQueries: ['getMediations'] }
    );
    if (res.success) _sendFeedback('success', w.success.deleted);
    else _sendFeedback('danger', w.error.general);
    this.setState({ mediation: null });
  };

  createMediation = async (form) => {
    const {
      m: { w },
    } = this.props;
    _sendFeedback('warning', 'loader');
    let res = await this.Mediation._createMediation(form, {
      refetchQueries: ['getMediations'],
    });
    if (res.success) {
      _sendFeedback('success', w.success.created);
      this.setState({ create: false });
    } else _sendFeedback('danger', w.error.general);
  };

  render() {
    const {
      data: { error, loading, getMediations },
      dispatch,
    } = this.props;
    if (error) throw new Error(this.props.data.error);
    let total = getMediations ? getMediations.length : 0;
    return (
      <div className="has-text-primary w-100p overflow-scroll relative">
        <Portal
          component={
            <Header
              {...this.props}
              {...this.state}
              counter={total}
              onChange={(e) =>
                dispatch({ type: 'setTitle', payload: e.target.value })
              }
            />
          }
          selector="base-header"
        />
        {loading && <Loader className="has-text-primary-ter overlay" />}
        {getMediations && (
          <Heading {...this.props} {...this.state} counter={total} />
        )}
        {getMediations && <Mediations {...this.props} {...this.state} />}
        <Modal
          isOpen={this.state.getInfo}
          close={() => this.onGetInfo()}
          selector="#root"
        >
          <Tutorial tuto={''} close={() => this.onGetInfo()} />
        </Modal>
        <Modal
          isOpen={this.state.create}
          close={() => this.setState({ create: false })}
          selector="#root"
        >
          <NewMed
            {...this.props}
            {...this.state}
            onCancel={() => this.setState({ create: false })}
            onConfirm={this.createMediation}
          />
        </Modal>
        <Modal
          isOpen={this.state.mediation}
          close={() => this.setState({ mediation: null })}
          selector="#root"
        >
          <Delete
            {...this.props}
            {...this.state}
            onCancel={() => this.setState({ mediation: null })}
            onConfirm={this.deleteMediation}
          />
        </Modal>
      </div>
    );
  }
}

// {getMediations && !loading && <Footer {...this.props} {...this.state}/>}

const withMediations = graphql(GET_MEDIATIONS, {
  options: (ownProps) => {
    return {
      variables: {
        bot_id: ownProps.m.bot.id,
      },
    };
  },
});

const withSubPlaces = graphql(GET_SUB_PLACES, {
  options: (ownProps) => {
    const {
      m: { bot },
    } = ownProps;
    let placeName = bot.places[0] ? bot.places[0].name : '';
    return {
      variables: {
        place: placeName,
      },
      skip: !placeName,
    };
  },
  name: 'subPlaceData',
});

export default compose(withMediations, withSubPlaces)(List);
