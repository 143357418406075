import React, { Component } from 'react';
import styled from 'styled-components';
import { Select, Icon } from '@ask-mona/ui';
import MUIButton from '@material-ui/core/Button';
import { _mergePlaces } from '../../../_tools/other';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#2D2F7B' },
    secondary: { main: '#E66E6E' },
  },
});

class Header extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    this.updateItems(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateItems(nextProps);
  }

  updateItems = (props) => {
    const {
      fct,
      _getBot: { getBot },
    } = props;
    let items = [];
    if (!getBot) return null;
    items = _mergePlaces(getBot.places);
    if (items.length !== this.state.items.length) {
      this.setState({ items: items });
      fct.onSelect(items[0]);
    }
  };

  render() {
    const {
      m: { w },
      fct,
    } = this.props;
    let items = this.state.items;
    items = items.map((i) => {
      if (i.main_site) {
        return {
          ...i,
          id: i.id,
          value: `${i.name} ✩`,
        };
      } else {
        return {
          ...i,
          id: i.id,
          value: i.name,
        };
      }
    });
    return (
      <MuiThemeProvider theme={theme}>
        <Container className="m-l-md flex row grow-1 space-between">
          <div className="flex row">
            {items.length > 0 && (
              <Select
                defaultValue={items[0].value}
                items={items}
                onSelect={fct.onSelect}
              />
            )}
            <MUIButton
              color="secondary"
              variant="contained"
              onClick={fct.onAddPlace}
            >
              {w.generic.addPlace}
            </MUIButton>
          </div>
          <div className="flex row">
            <MUIButton color="primary" onClick={fct.onSave} variant="contained">
              {w.generic.save}
            </MUIButton>
            <MUIButton
              className="shrink is-outlined m-l-xs"
              color="primary"
              onClick={fct.onGetInfo}
            >
              <Icon
                className="has-text-primary"
                icon="fas fa-exclamation-circle"
              />
            </MUIButton>
          </div>
        </Container>
      </MuiThemeProvider>
    );
  }
}

export default Header;

const Container = styled.div`
  padding-right: 0px;
  align-items: center;
`;
