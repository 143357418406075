export default (state, action) => {
  switch (action.type) {
    case 'SET_FUNCTIONALITIES_SUBSBAR':
      return {
        ...state,
        functionalitiesSubsbar: action.payload,
      };
    case 'SET_FUNCTIONALITIES_SUBSBAR_FOCUS':
      return {
        ...state,
        functionalitiesSubsbarFocus: action.payload,
      };
    case 'SET_FUNCTIONALITIES_SUBSBAR_FUNC':
      return {
        ...state,
        functionalitiesSubsbarFunc: action.payload,
      };
    case 'SET_FUNCTIONALITITES_SUBSBAR_LANG':
      return {
        ...state,
        functionalitiesSubsbarLang: action.payload,
      };
    default:
      return state;
  }
};
