import React, { useState } from 'react';
import styled from 'styled-components';
import StyledBigTitle from '../../../../components/parameters/StyledTitle';
import { ChromePicker } from 'react-color';
import { RightArrow } from '@styled-icons/boxicons-solid/RightArrow';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';

const Fullpage = ({ wWebchat, state, dispatch }) => {
  const [hideFirstContent, setHideFirstContent] = useState(true);
  return (
    <Wrapper>
      <StyledBigTitle>
        {wWebchat.templateConfig}
        <span onClick={() => setHideFirstContent(!hideFirstContent)}>
          {hideFirstContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideFirstContent && (
        <div>
          <WrapperColumn justifyContent={'space-around'} margin={'1rem 0'}>
            <WrapperLabelToggle>
              <StyledToggle>
                <ToggleInput
                  toggle={state.layoutCenter}
                  type="checkbox"
                  name="check"
                  value={state.layoutCenter}
                  onClick={(e) => {
                    dispatch({
                      type: 'setLayoutCenter',
                      payload: !state.layoutCenter,
                    });
                  }}
                />
                <Indicator toggle={state.layoutCenter}></Indicator>
              </StyledToggle>
              <ToggleText>
                {state.layoutCenter
                  ? wWebchat.centerMessage
                  : wWebchat.classicMessage}
              </ToggleText>
            </WrapperLabelToggle>

            <WrapperLabelToggle>
              <StyledToggle>
                <ToggleInput
                  toggle={state.noBackgroundMessage}
                  type="checkbox"
                  name="check"
                  value={state.noBackgroundMessage}
                  onClick={(e) => {
                    dispatch({
                      type: 'setNoBackgroundMessage',
                      payload: !state.noBackgroundMessage,
                    });
                  }}
                />
                <Indicator toggle={state.noBackgroundMessage}></Indicator>
              </StyledToggle>
              <ToggleText>
                {state.noBackgroundMessage
                  ? wWebchat.backgroundDesactivated
                  : wWebchat.backgroundActivated}
              </ToggleText>
            </WrapperLabelToggle>
          </WrapperColumn>

          <WrapperColumn justifyContent={'space-around'} margin={'1rem 0'}>
            <WrapperLabelToggle>
              <StyledToggle>
                <ToggleInput
                  toggle={state.hideAvatarFullpage}
                  type="checkbox"
                  name="check"
                  value={state.hideAvatarFullpage}
                  onClick={(e) => {
                    dispatch({
                      type: 'setHideAvatarFullpage',
                      payload: !state.hideAvatarFullpage,
                    });
                  }}
                />
                <Indicator toggle={state.hideAvatarFullpage}></Indicator>
              </StyledToggle>
              <ToggleText>
                {state.hideAvatarFullpage
                  ? wWebchat.avatarHidden
                  : wWebchat.avatarVisible}
              </ToggleText>
            </WrapperLabelToggle>
            <WrapperLabelToggle>
              <StyledToggle>
                <ToggleInput
                  toggle={state.hideHeaderFullpage}
                  type="checkbox"
                  name="check"
                  value={state.hideHeaderFullpage}
                  onClick={(e) => {
                    dispatch({
                      type: 'setHideHeaderFullpage',
                      payload: !state.hideHeaderFullpage,
                    });
                  }}
                />
                <Indicator toggle={state.hideHeaderFullpage}></Indicator>
              </StyledToggle>
              <ToggleText>
                {state.hideHeaderFullpage
                  ? wWebchat.headerHidden
                  : wWebchat.headerVisible}
              </ToggleText>
            </WrapperLabelToggle>
          </WrapperColumn>

          <WrapperColumn justifyContent={'space-around'} margin={'1rem 0'}>
            <div>
              <SubtitleLight margin={'1rem 0'}>
                {wWebchat.colorUserText}
              </SubtitleLight>
              <ChromePicker
                color={
                  state.colorMessageUserFullpage || state.colorBubbleFontUser
                }
                onChangeComplete={(val) => {
                  dispatch({
                    type: 'setColorMessageUserFullpage',
                    payload: val.hex,
                  });
                }}
              />
            </div>
            <div>
              <SubtitleLight margin={'1rem 0'}>
                {wWebchat.colorBotText}
              </SubtitleLight>
              <ChromePicker
                color={
                  state.colorMessageBotFullpage || state.colorBubbleFontBot
                }
                onChangeComplete={(val) => {
                  dispatch({
                    type: 'setColorMessageBotFullpage',
                    payload: val.hex,
                  });
                }}
              />
            </div>
          </WrapperColumn>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WrapperDiv = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ mr }) => (mr ? mr : '2rem 0')};
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent && 'space-around'};
  width: ${({ width }) => width};
`;

const WrapperColumn = styled.div`
  display: flex;
  margin: ${({ margin }) => margin || '1rem 2rem'};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const SubtitleLight = styled.h3`
  font-size: 1.1.rem;
  margin-right: 2rem;
  margin: ${({ margin }) => margin};
`;

const WrapperLabelToggle = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const ToggleText = styled.div`
  margin-left: 16px;
  min-width: 230px;
`;

const StyledToggle = styled.div`
  isolation: isolate;
  position: relative;
  height: 20px;
  width: 40px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6,
    4px 4px 4px 0px #d1d9e6 inset, -4px -4px 4px 0px #ffffff inset;
`;

const ToggleInput = styled.input`
  display: none;
  transform: ${({ toggle }) => toggle && 'translate3d(25%, 0, 0)'};
`;

const Indicator = styled.div`
  height: 100%;
  width: 200%;
  background: #ecf0f3;
  border-radius: 15px;
  transform: translate3d(-75%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
  box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6;
  transform: ${({ toggle }) => toggle && 'translate3d(25%, 0, 0)'};
`;

export default Fullpage;
