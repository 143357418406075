import React from 'react';
import { Field, Switch, Input, Button } from '@ask-mona/ui';
import { _getKeyValue } from '../../../_tools/other';

export default (props) => {
  const {
    m: { w },
    event,
    fct,
  } = props;
  const price = _getKeyValue(event, 'price') || '';
  let reg = new RegExp(/^\d+(\.\d+)?( - \d+(\.\d+)?)?$/gim);
  const parts = !(price || '').match(reg) ? [] : price.split(' - ');
  return (
    <Field title={w.generic.pricing} className="m-b-md">
      <div className="flex items-center">{w.generic.free}</div>
      <Switch
        color="secondary"
        className="m-l-md"
        defaultChecked={event.free || false}
        onChange={fct.toggleFree}
      />
      {!event.free && (
        <div className="flex row flex-end w-100p animation-bounceInRight">
          <Input
            name="minPrice"
            value={parts[0] && !isNaN(parts[0]) ? Number(parts[0]) : ''}
            type="number"
            className="m-l-md"
            style={{ width: '42px' }}
            fit="true"
            onChange={fct.setPrice}
          />
          <Button disabled>{w.generic.and}</Button>
          <Input
            name="maxPrice"
            value={parts[1] && !isNaN(parts[1]) ? Number(parts[1]) : ''}
            type="number"
            style={{ width: '42px' }}
            fit="true"
            onChange={fct.setPrice}
          />
        </div>
      )}
    </Field>
  );
};
