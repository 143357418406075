import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button as Btn, Icon } from '@ask-mona/ui';
import Text from './Text';
import Image from './Image';
import Video from './Video';
import Audio from './Audio';
import Generic from './Generic';
import Button from './Button';
import List from './List';
import Media from './Media';
import EventsElement from './EventsElement';

import { useWindowSize } from '../../../hooks/useWindowSize';

import {
  textTemplate,
  imageTemplate,
  mediaTemplate,
  videoTemplate,
  audioTemplate,
  genericTemplate,
  genTemplate,
  buttonTemplate,
  btnTemplate,
  listTemplate,
  lstTemplate,
} from '../Reducer';

import Popover, { ArrowContainer } from 'react-tiny-popover';

const Random = (props) => {
  const {
    m: { w, user },
    arrayOfElements,
    index,
    dispatch,
    btns,
  } = props;

  const windowsize = useWindowSize();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Container windowsize={windowsize}>
      {arrayOfElements.map((el, i) => {
        if (typeof el[0] === 'string') {
          if (el[0].includes('LOGIC_PROG')) {
            return (
              <ElementContainer>
                <EventsElement
                  {...props}
                  random={true}
                  logicString={el[0]}
                  newIndex={arrayOfElements.indexOf(el)}
                  arrayOfElements={arrayOfElements}
                />
              </ElementContainer>
            );
          } else {
            el[0] = { text: el[0] };
          }
        }

        if (el && el[0] && 'text' in el[0]) {
          return (
            <ElementContainer>
              <Text
                {...props}
                random={true}
                element={el[0]}
                newIndex={arrayOfElements.indexOf(el)}
                arrayOfElements={arrayOfElements}
              />
              {i !== arrayOfElements.length - 1 && (
                <OrSeparator>ou</OrSeparator>
              )}
            </ElementContainer>
          );
        } else if (el && el[0] && 'attachment' in el[0]) {
          let ret;
          const { type } = el[0]['attachment'];
          switch (type) {
            case 'image':
              return (
                <ElementContainer>
                  <Image
                    {...props}
                    random={true}
                    element={el[0]}
                    newIndex={arrayOfElements.indexOf(el)}
                    arrayOfElements={arrayOfElements}
                  />
                  {i !== arrayOfElements.length - 1 && (
                    <OrSeparator>ou</OrSeparator>
                  )}
                </ElementContainer>
              );
            case 'video':
              return (
                <ElementContainer>
                  <Video
                    {...props}
                    random={true}
                    element={el[0]}
                    newIndex={arrayOfElements.indexOf(el)}
                    arrayOfElements={arrayOfElements}
                  />
                  {i !== arrayOfElements.length - 1 && (
                    <OrSeparator>ou</OrSeparator>
                  )}
                </ElementContainer>
              );
            case 'audio':
              return (
                <ElementContainer>
                  <Audio
                    {...props}
                    random={true}
                    element={el[0]}
                    newIndex={arrayOfElements.indexOf(el)}
                    arrayOfElements={arrayOfElements}
                  />
                  {i !== arrayOfElements.length - 1 && (
                    <OrSeparator>ou</OrSeparator>
                  )}
                </ElementContainer>
              );
            case 'template':
              const {
                payload: { template_type },
              } = el[0]['attachment'];
              switch (template_type) {
                case 'generic':
                  return (
                    <ElementContainer>
                      <Generic
                        {...props}
                        random={true}
                        element={el[0]}
                        newIndex={arrayOfElements.indexOf(el)}
                        arrayOfElements={arrayOfElements}
                      />
                      {i !== arrayOfElements.length - 1 && (
                        <OrSeparator>ou</OrSeparator>
                      )}
                    </ElementContainer>
                  );
                case 'button':
                  return (
                    <ElementContainer>
                      <Button
                        {...props}
                        random={true}
                        element={el[0]}
                        newIndex={arrayOfElements.indexOf(el)}
                        arrayOfElements={arrayOfElements}
                      />
                      {i !== arrayOfElements.length - 1 && (
                        <OrSeparator>ou</OrSeparator>
                      )}
                    </ElementContainer>
                  );
                case 'list':
                  return (
                    <ElementContainer>
                      <List
                        {...props}
                        random={true}
                        element={el[0]}
                        newIndex={arrayOfElements.indexOf(el)}
                        arrayOfElements={arrayOfElements}
                      />
                      {i !== arrayOfElements.length - 1 && (
                        <OrSeparator>ou</OrSeparator>
                      )}
                    </ElementContainer>
                  );
                case 'media':
                  return (
                    <ElementContainer>
                      <Media
                        {...props}
                        random={true}
                        element={el[0]}
                        newIndex={arrayOfElements.indexOf(el)}
                        arrayOfElements={arrayOfElements}
                      />
                      {i !== arrayOfElements.length - 1 && (
                        <OrSeparator>ou</OrSeparator>
                      )}
                    </ElementContainer>
                  );
                default:
                  break;
              }
              break;
            default:
              break;
          }
          return (
            <>
              {ret}
              {i !== arrayOfElements.length - 1 && (
                <OrSeparator>ou</OrSeparator>
              )}
            </>
          );
        }
        return null;
      })}
      <Popover
        isOpen={isPopoverOpen}
        position={['bottom']}
        padding={5}
        onClickOutside={() => {
          setIsPopoverOpen(false);
        }}
        content={({ position, targetRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor={'#2D2F7B'}
            arrowSize={10}
            arrowStyle={{ zIndex: 10000, borderBottom: '15px solid #2D2F7B' }}
          >
            <PopupContainer>
              <BoardContainer>
                <BoardItems>
                  {btns.map(({ name, icon, type, ...b }) => {
                    if (user.organization.id !== 1 && name === 'list')
                      return null;
                    if (name === 'menu' || name === 'random' || name === 'copy')
                      return null;
                    else
                      return (
                        <BoardButton
                          {...b}
                          key={b.id}
                          onClick={() => {
                            let newValue = [];
                            switch (type) {
                              case 'text':
                                newValue = [
                                  ...arrayOfElements,
                                  [textTemplate('')],
                                ];
                                break;
                              case 'image':
                                newValue = [
                                  ...arrayOfElements,
                                  [imageTemplate('')],
                                ];
                                break;
                              case 'mediaImg':
                                newValue = [
                                  ...arrayOfElements,
                                  [mediaTemplate('image')],
                                ];
                                break;
                              case 'mediaVid':
                                newValue = [
                                  ...arrayOfElements,
                                  [mediaTemplate('video')],
                                ];
                                break;
                              case 'video':
                                newValue = [
                                  ...arrayOfElements,
                                  [videoTemplate('')],
                                ];
                                break;
                              case 'audio':
                                newValue = [
                                  ...arrayOfElements,
                                  [audioTemplate('')],
                                ];
                                break;
                              case 'generic':
                                newValue = [
                                  ...arrayOfElements,
                                  [
                                    genericTemplate(
                                      [genTemplate({}, {}, [btnTemplate()])],
                                      {}
                                    ),
                                  ],
                                ];
                                break;
                              case 'button':
                                newValue = [
                                  ...arrayOfElements,
                                  [buttonTemplate('')],
                                ];
                                break;
                              case 'list':
                                newValue = [
                                  ...arrayOfElements,
                                  [
                                    listTemplate(
                                      [
                                        lstTemplate({}, {}, []),
                                        lstTemplate({}, {}, []),
                                      ],
                                      [],
                                      {}
                                    ),
                                  ],
                                ];
                                break;
                              case 'events':
                                newValue = [
                                  ...arrayOfElements,
                                  ['LOGIC_PROG_EVENTS(event_id)'],
                                ];
                                break;
                              default:
                                newValue = [...arrayOfElements, [{ text: '' }]];
                                break;
                            }
                            dispatch({
                              type: 'onElemChange',
                              payload: {
                                index,
                                value: newValue,
                                func: null,
                              },
                            });
                          }}
                        >
                          {w.generic[name]}
                        </BoardButton>
                      );
                  })}
                </BoardItems>
              </BoardContainer>
            </PopupContainer>
          </ArrowContainer>
        )}
      >
        <ButtonContainer>
          <Btn
            className="shrink is-size-7 is-outlined is-borderless"
            onClick={() => {
              setIsPopoverOpen(!isPopoverOpen);
            }}
          >
            <Icon icon="fas fa-plus" />
          </Btn>
        </ButtonContainer>
      </Popover>
    </Container>
  );
};

export default Random;

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  overflow-x: auto;
`;

const ElementContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContainer = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  position: relative;
  z-index: 1000;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OrSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(p) => p.theme.colors.primary};
`;

const BoardContainer = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 10px 0px;
`;

const BoardItems = styled.div`
  margin-left: 1px;
  border-radius: 4px;
  padding: 0px 17px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid white;
  cursor: pointer;
`;

const BoardButton = styled.div`
  color: white;
  display: flex;
  height: 35px;
  max-width: 40px;
  font-size: 12px;
  flex-wrap: nowrap;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;
  padding-right: 22px;
  padding-left: 22px;
  border-right: 1px solid white;
  &:last-of-type {
    padding-right: 0;
    border-right: none;
  }
  &:first-of-type {
    padding-left: 0;
  }

  @media (max-width: 1500px) {
    height: 30px;
    font-size: 10px;
  }
  @media (max-width: 1300px) {
    height: 20px;
    font-size: 8px;
  }
`;
