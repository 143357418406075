import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Code from './templates/Code';
import Select from 'react-select';
import { CodeBlock, atomOneLight } from 'react-code-blocks';
import StyledTitle from '../../../../components/parameters/StyledTitle';
import StyledUpload from '../../../../components/parameters/StyledUpload';
import FontMime from '../webchat/templates/FontMime';
import Upload from '../../../../_assets/icon/Upload';
import InputFile from '../../../../components/parameters/InputFile';
import Loader from '../../../../components/Loader';
import Assets from '../../../../_api/assets';
import { _sendFeedback } from '../../../../_tools/ui';
import Bot from '../../../../_api/bot';

const Templates = ({ wat, w, wWebchat, state, dispatch, bot, client }) => {
  const api = new Assets();
  const [fontsArray, setFontsArray] = useState([]);
  const [loadFonts, setLoadFonts] = useState(false);
  const [referral, setReferral] = useState('');
  const [ids, setIds] = useState([]);

  useEffect(() => {
    fetchFonts();
    fetchIds();
  }, []);

  useEffect(() => {
    setFontsArray(
      state.fonts.map((font) => {
        return {
          label: font.fileName,
          value: font.fileUrl,
        };
      })
    );
  }, [state.fonts]);

  const fetchFonts = async () => {
    setLoadFonts(true);
    const res = await api._getFonts();
    if (res.success) {
      dispatch({ type: 'setFonts', payload: res.json });
      setLoadFonts(false);
    }
  };

  const uploadFont = async (e, signedUrl, contentType, extension, fileUrl) => {
    let file = e;
    _sendFeedback('warning', 'loader');
    if (file) {
      if (/\.(ttf|otf|woff|woff2)$/i.test(file.name)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        try {
          reader.onload = async () => {
            const res = await api.uploadFont(
              signedUrl,
              contentType,
              reader.result
            );
            if (res.success) {
              _sendFeedback('success', w.saved);
              dispatch({
                type: 'setFonts',
                payload: {
                  fileName: file.name,
                  fileUrl: fileUrl,
                },
              });
              dispatch({
                type: 'setFontSelected',
                payload: file.name.split('.')[0],
              });
              dispatch({ type: 'setUrlFont', payload: fileUrl });
              dispatch({ type: 'setFormatFont', payload: FontMime(extension) });
            }
          };
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const handleFont = async (e) => {
    setLoadFonts(true);
    const file = e.target.files[0];
    const extension = e.target.files[0].name.match(/\.[0-9a-z]+$/i)[0].slice(1);
    const fileName = e.target.files[0].name.split(' ').join('-');
    const contentType = `font/${extension}`;
    const res = await api.getSignedUrl({
      fileName,
      contentType,
    });
    if (res.success) {
      _sendFeedback('success', w.saved);
      setLoadFonts(false);
      const signedUrl = res.json.signedUrl;
      await uploadFont(
        file,
        signedUrl,
        contentType,
        extension,
        res.json.fileUrl
      );
    } else {
      setLoadFonts(false);
      _sendFeedback(
        'danger',
        w.download_error
      );
    }
  };

  const fetchIds = async () => {
    let api = new Bot(client, bot);
    let res = await api._getModules();
    if (res.success && res.json) {
      let keys = Object.keys(res.json.trigger);
      let modules = [];
      keys.forEach((k) => modules.push({ key: k, ...res.json.trigger[k] }));
      modules.unshift({
        key: "url.searchParams.get('referral')",
        name: 'default',
      });
      setIds(
        modules.map((el) => {
          return {
            label: el.id,
            value: el.name,
          };
        })
      );
    }
  };

  return (
    <div>
      <h3>{wWebchat.generalConfig}</h3>
      <WrapperDiv>
        <StyledDivWrapper>
          <SubtitleLight>{wWebchat.fontWebchat}</SubtitleLight>
          <SubWrapper>
            <StyledSubTitle>{w.downloadAFont}</StyledSubTitle>
            <StyledUpload width={'50%'}>
              <Upload />
              <div>
                <p>{w.download}</p>
              </div>
              <InputFile
                type="file"
                onChange={(e) => {
                  handleFont(e);
                }}
              />
            </StyledUpload>
            <StyledText>{w.acceptedFormat}</StyledText>
            <StyledWidth>
              <StyledSubTitle>{w.chooseAnExistingFont}</StyledSubTitle>
              {loadFonts ? (
                <Loader />
              ) : (
                <Select
                  options={fontsArray}
                  isSearchable="true"
                  placeholder={state.fontSelected}
                  onChange={(e) => {
                    const findMime = FontMime(
                      e.label.match(/\.[0-9a-z]+$/i)[0].slice(1)
                    );
                    dispatch({
                      type: 'setFontSelected',
                      payload: e.label.split('.')[0],
                    });
                    dispatch({ type: 'setUrlFont', payload: e.value });
                    dispatch({ type: 'setFormatFont', payload: findMime });
                  }}
                />
              )}
            </StyledWidth>
          </SubWrapper>
        </StyledDivWrapper>
      </WrapperDiv>
      <StyledTitle>{w.webchatCode}</StyledTitle>
      <SubWrapper>
        <StyledWidth>
          <SubtitleLight>{w.referral}</SubtitleLight>
          <Select
            options={ids}
            isSearchable="true"
            placeholder={'Choisir un referral'}
            onChange={(e) => {
              setReferral(e.label);
            }}
          />
        </StyledWidth>
      </SubWrapper>
      <CodeBlock
        theme={atomOneLight}
        customStyle={{
          backgroundColor: '#f6f6f6',
          border: '1px solid #a7a7a7',
          color: '#727272',
          marginTop: '1rem',
          marginBottom: '1rem',
          padding: '0!important',
        }}
        text={Code(wat, referral)}
        showLineNumbers={false}
      />
    </div>
  );
};

const WrapperDiv = styled.div`
  display: flex;
  margin: ${({ mr }) => (mr ? mr : '1rem 0')};
  align-items: ${({ relativeImg }) => (relativeImg ? 'stretch' : 'normal')};
`;

const StyledDivWrapper = styled.div`
  display: flex;
  flex-direction: ${({ noColumn }) => !noColumn && 'column'};
  align-items: ${({ alignItem }) => alignItem};
  margin-right: ${({ mr }) => mr};
  width: ${({ width }) => width};
  align-self: flex-end;
`;

const SubtitleLight = styled.h3`
  font-size: 1.1.rem;
  margin-bottom: ${({ mb }) => mb};
`;

const StyledWidth = styled.div`
  width: 80%;
`;

const SubWrapper = styled.div`
  margin-left: 1rem;
`;

const StyledSubTitle = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-weight: 500;
`;

const StyledText = styled.p`
  font-weight: 300;
  font-size: 0.8em;
  margin-top: ${({ mt }) => mt};
  width: ${({ width }) => width};
`;

export default Templates;
