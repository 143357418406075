import React, { memo, useContext, useState } from 'react';
import styled from 'styled-components';
import { QAContext } from '../context/QAContext';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import BaseModal from '../../../components/BaseModal';
import CreateQr from '../components/modals/CreateQr';
import { Api } from '../../../api/graphql/hooks/useTopics';

interface TitleProps {
  readonly isActive: boolean;
}

interface Props {
  api: Api;
  w: any;
}

const Susbar = memo((props: Props) => {
  const { state, dispatch } = useContext(QAContext);
  const [modalCreateQr, setModalCreateQr] = useState<boolean>(false);

  const enable = state.categories.find(
    (el) => el.id === state.currentCategoryId
  )?.enabled_topics_count;
  const total = state.categories.find(
    (el) => el.id === state.currentCategoryId
  )?.topics_count;

  return (
    <Container>
      {!!!state.searchValue.length && (
        <>
          <Section>
            <SectionText
              isActive={state.topicStatus === 'enabled'}
              onClick={() =>
                dispatch({ type: 'SET_TOPIC_STATUS', payload: 'enabled' })
              }
            >
              {props.w.activatedQa}({enable})
            </SectionText>
            <SectionIcon>
              <i className="fas fa-chevron-right" />
            </SectionIcon>
          </Section>
          <Section>
            <SectionText
              isActive={state.topicStatus === 'disabled'}
              onClick={() =>
                dispatch({ type: 'SET_TOPIC_STATUS', payload: 'disabled' })
              }
            >
              {props.w.disabledQa}(
              {enable === 0 ? total : total && enable && total - enable})
            </SectionText>
            <SectionIcon>
              <i className="fas fa-chevron-right" />
            </SectionIcon>
          </Section>
          <Section>
            <SectionText
              isActive={state.topicStatus === 'all'}
              onClick={() =>
                dispatch({ type: 'SET_TOPIC_STATUS', payload: 'all' })
              }
            >
              {props.w.allQa}({total})
            </SectionText>
            <SectionIcon>
              <i className="fas fa-chevron-right" />
            </SectionIcon>
          </Section>
          {state.permission === 1 && (
            <Section>
              <ButtonAddContainer
                onClick={() => setModalCreateQr(!modalCreateQr)}
              >
                <ButtonPlusSymbol>
                  <AddBoxRoundedIcon color="primary" />
                </ButtonPlusSymbol>
                <ButtonText>{props.w.addQr}</ButtonText>
              </ButtonAddContainer>
            </Section>
          )}

          {!!modalCreateQr && (
            <BaseModal isOpen={modalCreateQr} selector="#root">
              <CreateQr
                close={() => setModalCreateQr(!modalCreateQr)}
                api={props.api}
                w={props.w}
              />
            </BaseModal>
          )}
        </>
      )}
    </Container>
  );
});

export default Susbar;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 17%;
  min-width: 17%;
  padding: 30px 5px 0px 30px;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media (max-width: 1400px) {
    min-width: 170px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  overflow: wrap;
  text-align: center;
  width: 100%;
  color: #2d2f7b;
  margin-bottom: 30px;
  cursor: pointer;
`;

const SectionText = styled.div<TitleProps>`
  display: flex;
  flex-wrap: wrap;
  color: #2d2f7b;
`;

const SectionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ButtonAddContainer = styled.div`
  background-color: white;
  padding: 8px 20px 8px 13px;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  border: 1px solid #2d2f7b;
  margin: 10px auto 20px;
  cursor: pointer;
`;

const ButtonPlusSymbol = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled.div`
  color: #2d2f7b;
  font-size: 0.9rem;
`;
