import styled from 'styled-components';

const StyledTitle = styled.h1`
  font-size: 1.1em;
  width: 100%;
  margin-top: 1.4rem;
  margin-bottom: 1rem;
  text-align: ${({ textAlign }) => textAlign};
  >span {
    margin-left: .6rem;
  }
`;

export default StyledTitle;
