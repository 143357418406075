import React, { useState } from 'react';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import StyledLabel from '../../../../components/parameters/StyledLabel';
import StyledInput from '../../../../components/parameters/StyledInput';
import StyledBigTitle from '../../../../components/parameters/StyledTitle';
import Webchat from '../../../../components/parameters/Webchat';
import CircleImg from '../../../../components/parameters/CircleImg';
import StyledImage from '../../../../components/parameters/StyledImage';
import StyledUpload from '../../../../components/parameters/StyledUpload';
import InputFile from '../../../../components/parameters/InputFile';
import Upload from '../../../../_assets/icon/Upload';
import InputRadio from '../../../../components/parameters/InputRadio';
import Toggle from '../../../../components/parameters/Toggle';
import { RightArrow } from '@styled-icons/boxicons-solid/RightArrow';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';
import inputTemplate from './templates/Input';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${({ alignItem }) => alignItem && 'center'};
`;

const WrapperDiv = styled.div`
  display: flex;
  align-items: ${({ alignItem }) => alignItem || 'flex-start'};
  margin: ${({ mr }) => (mr ? mr : '2rem 0')};
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubititleLight = styled.h3`
  font-size: 1.1.rem;
  margin-right: 2rem;
  margin-bottom: ${({ mb }) => mb};
`;

const WrapperChat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: ${({ mr }) => mr};
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: 150px;
  text-align: center;
  margin-top: 1rem;
  transition: 0.3s;
  cursor: pointer;
  transition: opacity 0.3s;

  > span {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.white};
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 12px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const InputUpload = styled.input`
  display: inline-block;
  position: absolute;
  z-index: 1000000;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const StyledText = styled.p`
  font-weight: 300;
  font-size: 0.8em;
  margin-top: ${({ mt }) => mt};
  width: ${({ width }) => width};
`;

const Input = ({ w, wWebchat, state, dispatch, handleImage }) => {
  const [hideFirstContent, setHideFirstContent] = useState(true);
  const [hideSecondContent, setHideSecondContent] = useState(true);
  const { Handle } = Slider;

  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} %`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  return (
    <Wrapper>
      <StyledBigTitle>
        {wWebchat.templateChoice}
        <span onClick={() => setHideFirstContent(!hideFirstContent)}>
          {hideFirstContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideFirstContent && (
        <>
          <WrapperDiv justifyContent={'space-around'}>
            <WrapperChat>
              <Webchat input />
              <StyledLabel radio flex>
                <InputRadio
                  mt
                  type="radio"
                  value="template1"
                  checked={
                    JSON.stringify(state.templateInput) ===
                    JSON.stringify(inputTemplate('template1'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setTemplateInput',
                      payload: inputTemplate(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperChat>
            <WrapperChat>
              <Webchat
                input
                backgroundBoard={'#eee'}
                margin
                borderRadius={'5px'}
              />
              <StyledLabel radio flex>
                <InputRadio
                  mt
                  type="radio"
                  value="template2"
                  checked={
                    JSON.stringify(state.templateInput) ===
                    JSON.stringify(inputTemplate('template2'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setTemplateInput',
                      payload: inputTemplate(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperChat>
            <WrapperChat>
              <Webchat
                input
                backgroundBoard={'#eee'}
                margin
                borderRadius={'25px'}
              />
              <StyledLabel radio flex>
                <InputRadio
                  mt
                  type="radio"
                  value="template3"
                  checked={
                    JSON.stringify(state.templateInput) ===
                    JSON.stringify(inputTemplate('template3'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setTemplateInput',
                      payload: inputTemplate(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperChat>
            <WrapperChat>
              <Webchat
                input
                backgroundBoard={'#eee'}
                margin
                borderRadius={'25px'}
                shadow={'rgba(0, 0, 0, 0.18) 0px 2px 4px'}
              />
              <StyledLabel radio flex>
                <InputRadio
                  mt
                  type="radio"
                  value="template4"
                  checked={
                    JSON.stringify(state.templateInput) ===
                    JSON.stringify(inputTemplate('template4'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setTemplateInput',
                      payload: inputTemplate(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperChat>
            <WrapperChat>
              <Webchat
                input
                backgroundBoard={'#eee'}
                margin
                borderRadius={'5px'}
                shadow={'rgba(0, 0, 0, 0.18) 0px 2px 4px'}
              />
              <StyledLabel radio flex>
                <InputRadio
                  mt
                  type="radio"
                  value="template5"
                  checked={
                    JSON.stringify(state.templateInput) ===
                    JSON.stringify(inputTemplate('template5'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setTemplateInput',
                      payload: inputTemplate(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperChat>
          </WrapperDiv>
        </>
      )}
      <StyledBigTitle>
        {wWebchat.templateConfig}
        <span onClick={() => setHideSecondContent(!hideSecondContent)}>
          {hideSecondContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideSecondContent && (
        <>
          <WrapperDiv justifyContent={'space-around'}>
            <Wrapper alignItem={'center'}>
              <Toggle
                toggle={state.noBg}
                handleClick={() => {
                  dispatch({ type: 'setNoBg', payload: !state.noBg });
                }}
                content={
                  state.noBg ? wWebchat.input_background_enabled : wWebchat.input_background_disabled
                }
              />
            </Wrapper>
            <StyledDivWrapper margin={'1rem 0'}>
              <SubititleLight mb={'1rem'}>{wWebchat.bg}</SubititleLight>
              <ChromePicker
                color={state.backgroundInput}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setBackgroundInput', payload: val.hex });
                }}
              />
            </StyledDivWrapper>
            <StyledDivWrapper margin={'1rem 0'}>
              <SubititleLight mb={'1rem'}>{wWebchat.colorFont}</SubititleLight>
              <ChromePicker
                color={state.colorInputText}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setColorInputText', payload: val.hex });
                }}
              />
            </StyledDivWrapper>
            <StyledDivWrapper margin={'1rem 0'}>
              <SubititleLight mb={'1rem'}>{wWebchat.colorIcons}</SubititleLight>
              <ChromePicker
                color={state.colorInputIcons}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setColorInputIcons', payload: val.hex });
                }}
              />
            </StyledDivWrapper>
          </WrapperDiv>
          <WrapperDiv>
            <StyledLabel width={80}>
              {wWebchat.placeholder}
              <StyledInput
                type="text"
                maxLength="37"
                value={state.textInput}
                onChange={(e) => {
                  dispatch({ type: 'setTextInput', payload: e.target.value });
                }}
              />
            </StyledLabel>
          </WrapperDiv>
          <WrapperDiv justifyContent={'space-around'}>
            <StyledDivWrapper>
              <SubititleLight>{wWebchat.fontSize}</SubititleLight>
              <Slider
                trackStyle={{ backgroundColor: 'red', height: '10px' }}
                railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                handleStyle={{
                  width: '20px',
                  height: '20px',
                  border: '1px solid lightgray',
                  boxShadow:
                    'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                }}
                min={1}
                max={10}
                step={1}
                value={
                  (state.fontSizeBoard && state.fontSizeBoard.length >= 3)
                    ? state.fontSizeBoard?.slice(0, -2)
                    : state.fontSizeBoard
                }
                onChange={(value) => {
                  dispatch({ type: 'setFontSizeBoard', payload: `${value}px` });
                }}
                handle={handle}
              />
            </StyledDivWrapper>
          </WrapperDiv>
          <WrapperDiv mr={'1rem 2rem'}>
            <SubititleLight>{wWebchat.inputState}</SubititleLight>
            <StyledLabel radio flex widht={'20'} mr>
              <InputRadio
                type="radio"
                name="inputActive"
                value={state.inputTextActive}
                checked={state.inputTextActive}
                onChange={() => {
                  dispatch({
                    type: 'setInputTextActive',
                    payload: !state.inputTextActive,
                  });
                }}
              />
              {wWebchat.active}
            </StyledLabel>
            <StyledLabel radio flex widht={'20'}>
              <InputRadio
                type="radio"
                name="inputActive"
                value={state.inputTextActive}
                checked={!state.inputTextActive}
                onChange={() => {
                  dispatch({
                    type: 'setInputTextActive',
                    payload: !state.inputTextActive,
                  });
                }}
              />
              {wWebchat.close}
            </StyledLabel>
          </WrapperDiv>

          <WrapperDiv mr={'1rem 2rem'}>
            <SubititleLight>{wWebchat.sendImage}</SubititleLight>
            <StyledLabel radio flex widht={'20'} mr>
              <InputRadio
                type="radio"
                name="sendImage"
                value={state.sendImage}
                checked={state.sendImage}
                onChange={() => {
                  dispatch({ type: 'setSendImage', payload: !state.sendImage });
                }}
              />
              {wWebchat.active}
            </StyledLabel>
            <StyledLabel radio flex widht={'20'}>
              <InputRadio
                type="radio"
                name="sendImage"
                value={state.sendImage}
                checked={!state.sendImage}
                onChange={() => {
                  dispatch({ type: 'setSendImage', payload: !state.sendImage });
                }}
              />
              {wWebchat.desactivate}
            </StyledLabel>
          </WrapperDiv>
          <WrapperDiv mr={'.6rem 0'}>
            <SubititleLight>{wWebchat.changeSendIcon}</SubititleLight>
          </WrapperDiv>
          <WrapperDiv
            mr={'.6rem 0'}
            alignItem={'center'}
            justifyContent={state.sendButton && 'space-around'}
          >
            {state.sendButton ? (
              <>
                <StyledDivWrapper alignItem={'flex-start'}>
                  <CircleImg width={'80'} height={'80'}>
                    <StyledImage width={'80'} src={state.sendButton} alt="" />
                  </CircleImg>
                  <ButtonWrapper>
                    <span>{wWebchat.changeIcon}</span>
                    <InputUpload
                      type="file"
                      onChange={(e) => {
                        handleImage(e, 'setSendButton');
                      }}
                    />
                  </ButtonWrapper>
                </StyledDivWrapper>
                <StyledDivWrapper>
                  <SubititleLight>{wWebchat.input_icon_size}</SubititleLight>
                  <Slider
                    trackStyle={{ backgroundColor: 'red', height: '10px' }}
                    railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                    handleStyle={{
                      width: '20px',
                      height: '20px',
                      border: '1px solid lightgray',
                      boxShadow:
                        'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                    }}
                    min={16}
                    max={36}
                    step={1}
                    value={state.sizeIconSend}
                    onChange={(value) => {
                      dispatch({ type: 'setSizeIconSend', payload: value });
                    }}
                    handle={handle}
                  />
                </StyledDivWrapper>
              </>
            ) : (
              <>
                <WrapperColumn>
                  <StyledUpload noMargin width={'56'}>
                    <Upload />
                    <div>
                      <p>{w.download}</p>
                      <p>{w.downloadSize})</p>
                    </div>
                    <InputFile
                      type="file"
                      onChange={(e) => {
                        handleImage(e, 'setSendButton');
                      }}
                    />
                  </StyledUpload>
                  <StyledText width={'55%'}>{w.downloadFormat}</StyledText>
                </WrapperColumn>
              </>
            )}
          </WrapperDiv>

          <WrapperDiv mr={'.6rem 0'}>
            <SubititleLight>
              {wWebchat.input_change_pic_icon}
            </SubititleLight>
          </WrapperDiv>
          <WrapperDiv
            mr={'.6rem 0'}
            alignItem={'center'}
            justifyContent={state.iconSendImage && 'space-around'}
          >
            {state.iconSendImage ? (
              <>
                <StyledDivWrapper alignItem={'flex-start'}>
                  <CircleImg width={'80'} height={'80'}>
                    <StyledImage
                      width={'80'}
                      src={state.iconSendImage}
                      alt=""
                    />
                  </CircleImg>
                  <ButtonWrapper>
                    <span>{wWebchat.changeIcon}</span>
                    <InputUpload
                      type="file"
                      onChange={(e) => {
                        handleImage(e, 'setIconSendImage');
                      }}
                    />
                  </ButtonWrapper>
                </StyledDivWrapper>
                <StyledDivWrapper>
                  <SubititleLight>{wWebchat.input_icon_size}</SubititleLight>
                  <Slider
                    trackStyle={{ backgroundColor: 'red', height: '10px' }}
                    railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                    handleStyle={{
                      width: '20px',
                      height: '20px',
                      border: '1px solid lightgray',
                      boxShadow:
                        'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                    }}
                    min={16}
                    max={36}
                    step={1}
                    value={state.sizeIconSendImage}
                    onChange={(value) => {
                      dispatch({
                        type: 'setSizeIconSendImage',
                        payload: value,
                      });
                    }}
                    handle={handle}
                  />
                </StyledDivWrapper>
              </>
            ) : (
              <>
                <WrapperColumn>
                  <StyledUpload noMargin width={'56'}>
                    <Upload />
                    <div>
                      <p>{w.download}</p>
                      <p>{w.downloadSize})</p>
                    </div>
                    <InputFile
                      type="file"
                      onChange={(e) => {
                        handleImage(e, 'setIconSendImage');
                      }}
                    />
                  </StyledUpload>
                  <StyledText width={'55%'}>{w.downloadFormat}</StyledText>
                </WrapperColumn>
              </>
            )}
          </WrapperDiv>

          <WrapperDiv mr={'.6rem 0'}>
            <SubititleLight>{wWebchat.input_change_menu_icon}</SubititleLight>
          </WrapperDiv>
          <WrapperDiv
            mr={'.6rem 0'}
            alignItem={'center'}
            justifyContent={state.iconMenu && 'space-around'}
          >
            {state.iconMenu ? (
              <>
                <StyledDivWrapper alignItem={'flex-start'}>
                  <CircleImg width={'80'} height={'80'}>
                    <StyledImage width={'80'} src={state.iconMenu} alt="" />
                  </CircleImg>
                  <ButtonWrapper>
                    <span>{wWebchat.changeIcon}</span>
                    <InputUpload
                      type="file"
                      onChange={(e) => {
                        handleImage(e, 'setIconMenu');
                      }}
                    />
                  </ButtonWrapper>
                </StyledDivWrapper>
                <StyledDivWrapper>
                  <SubititleLight>{wWebchat.input_icon_size}</SubititleLight>
                  <Slider
                    trackStyle={{ backgroundColor: 'red', height: '10px' }}
                    railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                    handleStyle={{
                      width: '20px',
                      height: '20px',
                      border: '1px solid lightgray',
                      boxShadow:
                        'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                    }}
                    min={16}
                    max={36}
                    step={1}
                    value={state.sizeIconMenu}
                    onChange={(value) => {
                      dispatch({ type: 'setSizeIconMenu', payload: value });
                    }}
                    handle={handle}
                  />
                </StyledDivWrapper>
              </>
            ) : (
              <>
                <WrapperColumn>
                  <StyledUpload noMargin width={'56'}>
                    <Upload />
                    <div>
                      <p>{w.download}</p>
                      <p>{w.downloadSize})</p>
                    </div>
                    <InputFile
                      type="file"
                      onChange={(e) => {
                        handleImage(e, 'setIconMenu');
                      }}
                    />
                  </StyledUpload>
                  <StyledText width={'55%'}>{w.downloadFormat}</StyledText>
                </WrapperColumn>
              </>
            )}
          </WrapperDiv>
        </>
      )}
    </Wrapper>
  );
};

export default Input;
