import gql from "graphql-tag";

import { apiV3 } from "./apiV3";

import { User } from "../../types/user.type";

type UpdateUserArgs = {
  id: number;
  firstname?: string;
  lastname?: string;
  password?: string;
  organization_id?: number;
  language_code?: string;
}

type UpdateUserResponse = {
  updateUser: User;
};

type CreateUserArgs = {
  firstname: string;
  lastname: string;
  token: string;
  password: string;
};

type CreateUserResponse = {
  createUser: {
    user: Omit<User, "token">;
    token: string;
  };
};

// NOTE: api V3 can't update firstname, lastname, organization_id
// NOTE: can't query "permissions" field on type "User"
// const UPDATE_USER = gql`
//   mutation(
//     $id: Int!
//     $firstname: String
//     $lastname: String
//     $password: String
//     $organization_id: Int
//     $language_code: String
//   ) {
//     updateUser(
//       id: $id
//       firstname: $firstname
//       lastname: $lastname
//       password: $password
//       organization_id: $organization_id
//       language_code: $language_code
//     ) {
//       firstname
//       lastname
//       permissions
//       language_code
//     }
//   }
// `;

const CREATE_USER = gql`
  mutation (
    $firstname: String!
    $lastname: String!
    $password: String!
    $token: String!
  ) {
    createUser(
      first_name: $firstname
      last_name: $lastname
      password: $password
      token: $token
    ) {
      user {
        id
        email
        firstname
        lastname
        language_code
        role
        organization {
          id
          name
          invitations {
            email
            token
          }
        }
      }

      token
    }
  }
`;

export async function createUser(
  variables: CreateUserArgs
): Promise<CreateUserResponse["createUser"]> {
  const { data }: { data: CreateUserResponse } = await apiV3.mutate({
    mutation: CREATE_USER,
    variables,
  });

  return data.createUser;
}

const UPDATE_USER = gql`
  mutation ($id: Int!, $password: String, $language_code: String) {
    updateUser(id: $id, password: $password, language_code: $language_code) {
      id
      email
      firstname
      lastname
      role
      language_code
    }
  }
`;

export async function updateUser(variables: UpdateUserArgs): Promise<User> {
  const { data }: { data: UpdateUserResponse } = await apiV3.mutate({
    mutation: UPDATE_USER,
    variables,
  });

  return data.updateUser;
}

type GetUserArgs = {
  id: number;
  email: string;
  token: string;
};

const GET_USER = gql`
  query getUser($id: Int, $email: String, $token: String) {
    getUser(id: $id, email: $email, token: $token) {
      id
      email
      firstname
      lastname
      role
      language_code
      organization {
        id
        name
        invitations {
          email
          token
        }
      }
    }
  }
`;

export async function getUser(variables: GetUserArgs): Promise<User> {
  const { data: { getUser } }: { data: { getUser: User } } = await apiV3.query({
    query: GET_USER,
    variables
  });

  return getUser;
}
