export default {
  noMediation: ["Il n'y a pas de parcours", 'There is no course'],
  general: ['Une erreur est survenue.', 'An error has occurred.'],
  emailAndPasswordDoesNotMatch: [
    "Vérifiez votre nom d'utilisateur et votre mot de passe.",
    'Please check your user name and password.',
  ],
  email: ["L'email est invalide", 'The email is invalid'],
  passwordDoesNotMatch: [
    'Les mots de passes ne correspondent pas.',
    'The passwords do not match.',
  ],
  passwordRequirement: [
    'Le mot de passe doit contenir au moins une lettre capital, une lettre minuscule, un chiffre et faire 6 caractères',
    'The password must contain at least one capital letter, one lower case letter, one number and be 6 characters long',
  ],
  noPlaces: [
    "Vous n'avez pas de lieu référencé, commencez par en ajouter un.",
    "You don't have a location listed, start by creating one.",
  ],
  noPrices: [
    "Vous n'avez pas de tableau de prix enregistré",
    'You do not have a price list created yet',
  ],
  noScenario: ["Vous n'avez pas de scénario.", "You don't have a scenario."],
  getCity: ['La récupération du lieu a échoué.', 'The recovery of the place failed.'],
  createPlace: [
    'Une erreur est survenue durant la création du lieu.',
    'An error occurred during the creation of the place.',
  ],
  updatePlace: [
    'Une erreur est survenue durant la mis à jour du lieu.',
    'An error occurred during the update of the location.',
  ],
  createSubPlace: [
    'Une erreur est survenue durant la création du sous-lieu.',
    'An error occurred during the creation of the sub-location.',
  ],
  section: [
    'Une erreur est survenue pendant le chargement de la section',
    'An error occurred while loading this section',
  ],
  undefined: ['Une erreur indéfinie est survenue.', 'An undefined error has occurred.'],
  noTitle: ['Pas de titre', 'No title'],
  noEvent: ["Il n'y a aucun événement", 'There are no events'],
  missingTitle: ['Il manque un titre', 'Title is missing'],
  missingType: ['Il manque un type', 'Missing Type'],
  missingPlace: ['Il manque un lieu', 'Missing place'],
  missingFirstname: ['Il manque un prénom', 'Missing first name'],
  missingLastname: ['Il manque un nom', 'Missing last name'],
  noOph: ["Il n'y a pas d'horaire enregistré", 'There is no saved schedule'],
  noFocus: ["Vous n'avez pas séléctionné de module", "You have not selected a module"],
  unhandled: ["Cet élément n'est pas répertorié", "This item is not listed"],
  dateInOpen: [
    "Cette date est déjà présente dans les horaires d'ouverture",
    'This date is already present in the opening hours',
  ],
  dateInClose: [
    'Cette date est déjà présente dans les horaires de fermeture',
    'This date is already present in the closing hours',
  ],
  passwordBadlyFormatted: [
    "Le mot de passe doit contenir au moins 1 minuscule, 1 majuscule, 1 chiffre et être composé d'au moins 6 caractères.",
    'Password must contain at least 1 lowercase, 1 uppercase, 1 digit and be at least 6 characters long',
  ],
};
