import React from 'react';
import styled from 'styled-components';
import CircleImg from '../../components/parameters/CircleImg';
import StyledImage from '../../components/parameters/StyledImage';
import userDefault from '../../_assets/user-default.png';
import Generic from './Generic';
import Pagination from './Pagination';
import { Key } from "@styled-icons/boxicons-solid/Key";
import { Keyboard } from "@styled-icons/entypo/Keyboard";
import { SearchAlt } from "@styled-icons/boxicons-regular/SearchAlt";
import { PaperPlane } from "@styled-icons/boxicons-solid/PaperPlane";

const WebchatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 450px;
  width: 215px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.05);
  background: ${({ menu, input }) => (input && 'none') || menu ? 'linear-gradient(180deg, rgba(254,254,254,1) 0%, rgba(253,253,253,1) 20%, rgba(251,250,250,1) 50%, rgba(250,249,249,1) 75%, rgba(248,247,247,1) 100%)' : '#FFA300'};
`;

const WebchatHeader = styled.div`
  display: ${({ menu, generic }) => menu || generic ? 'none' : 'flex'};
  align-items: center;
  justify-content: center;
  background-color: #FFA300;
  height: ${({ height }) => height ? height : '60px'};
  width: 100%;
`;

const WebchatBody = styled.div`
  background: linear-gradient(180deg, rgba(254,254,254,1) 0%, rgba(253,253,253,1) 20%, rgba(251,250,250,1) 50%, rgba(250,249,249,1) 75%, rgba(248,247,247,1) 100%);
  border-radius: ${({ curved }) => curved && '25px 25px 0 0'};
  height: inherit;
  display: ${({ pagination }) => pagination ? 'flex' : 'block'};
  position: ${({ pagination }) => pagination && 'relative'};
  overflow: ${({ pagination }) => pagination && 'hidden'};
  align-items: ${({ pagination }) => pagination && 'center'}
`;

const WebchatInput = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: .8rem;
  padding: .6rem;
  color: #c9d3da;
  border-top: 1px solid #efefef;
  background: ${({ backgroundBoard }) => backgroundBoard ? backgroundBoard : '#FFF'};
  margin: ${({ margin }) => margin ? ".6rem .4rem" : ""};
  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: ${({ shadow }) => shadow};
`;

const WebchatMenu = styled.div`
  display: flex;
  flex-direction: ${({ icon }) => icon ? 'row' : 'column'};
  justify-content: ${({ noText }) => noText ? 'space-around' : 'center'};
  background: ${({ backgroundMenu }) => !backgroundMenu && 'linear-gradient(180deg, rgba(244,242,242,1) 0%, rgba(243,241,241,1) 30%, rgba(242,239,239,1) 79%, rgba(242,238,238,1) 100%)'};
  background-color: ${({ backgroundMenu }) => backgroundMenu || ''};
`;

const Button = styled.div`
  color: ${({ color }) => color ? color : '#9f9d9d'};
  font-size: ${({ icon }) => icon ? ".6em" : '.9em'};
  font-weight: 300;
  background-color: ${({ background }) => background ? background : '#FFF'};
  margin: ${({ opt2, icon, noText }) => (noText && '.6rem') || !opt2 || (!icon && '.4rem .8rem')};
  padding: ${({ opt2, noText }) => (noText && '.8rem') || !opt2 ? '.4rem' : '.2rem'};
  border-radius: ${({ icon, borderRadius }) => (!icon && '2px') || borderRadius};
  text-align: center;
  border-top: ${({ opt2 }) => !opt2 && '1px solid #f5f6f7'};
  display: ${({ icon }) => icon && 'flex'};
  flex-direction: ${({ icon }) => icon && 'column'};
  align-items: ${({ icon }) => icon && 'center'};
  &:first-child {
    margin-top: ${({ opt2, icon }) => !opt2 || (!icon && '1rem')};
    border-top-left-radius: ${({ radius }) => radius ? '15px' : ''};
  }
  &:last-child {
    margin-bottom: ${({ opt2, icon }) => !opt2 || (!icon && '1rem')};
    border-top-right-radius: ${({ radius }) => radius ? '15px' : ''};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  color: #FFF;
  font-size: .8em;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperGeneric = styled.div`
  display: flex;
  position: ${({ pagination }) => pagination && 'absolute'};
`;

const Webchat = (props) => (
  <WebchatContainer
    menu={props.menu}
    input={props.input}
  >
    <WebchatHeader
      menu={props.menu || props.input}
      height={props.height} 
      widget={props.iconWidget}
      title={props.title}
      subtitle={props.subtitle}
      generic={props.generic}
    >
      <StyledHeader>
        {props.iconWidget &&
          <CircleImg>
            <StyledImage
              src={props.widgetIcon ? props.widgetIcon : userDefault}
              alt="Logo du chatbot"
            />
          </CircleImg>
        }
        <StyledContent>
          {
            props.title &&
              <p>{props.title}</p>
          }
          {
            props.subtitle &&
              <p>{props.subtitle}</p>
          }
        </StyledContent>
      </StyledHeader>
    </WebchatHeader>
    <WebchatBody
      curved={props.curved}
      height={props.height}
      pagination={props.pagination}
    >
      {props.generic && 
        <WrapperGeneric pagination={props.pagination}>
          <Generic webchat pagination/>
          <Generic webchat pagination/>
        </WrapperGeneric>
      }
      {props.pagination &&
        <Pagination 
          pagNumber={props.pagNumber}
          pagSquare={props.pagSquare}
        />
      }
    </WebchatBody>
    <WebchatInput
      menu={props.menu}
      heightMenu={props.heightMenu}
      backgroundBoard={props.backgroundBoard}
      margin={props.margin}
      borderRadius={props.borderRadius}
      shadow={props.shadow}
    >
      Ecrire un message...
      {props.input && 
        <PaperPlane size={16} />
      }
    </WebchatInput>
    {
      props.menu && !props.icon &&
      <WebchatMenu heightMenu={props.heightMenu}>
        <Button opt2={props.opt2} >Titre du boutton</Button>
        <Button opt2={props.opt2} >Titre du boutton</Button>
        <Button opt2={props.opt2} >Titre du boutton</Button>
      </WebchatMenu>
    }
    {
      props.menu && props.icon &&
      <WebchatMenu
        heightMenu={props.heightMenu}
        icon={props.icon}
        backgroundMenu={props.backgroundMenu}
        noText={props.noText}
      >
        <Button
          icon={props.icon}
          background={props.background}
          color={props.color}
          radius={props.radius}
          noText={props.noText}
          borderRadius={props.borderRadius}
        >
          <Key size={16} />
          {!props.noText &&
            'Politique de confidentialité'
          }
        </Button>
        <Button
          icon={props.icon}
          background={props.background}
          color={props.color}
          radius={props.radius}
          noText={props.noText}
          borderRadius={props.borderRadius}
        >
          <SearchAlt size={16} />
          {!props.noText &&
            'Nouvelle recherche'
          }
        </Button>
        <Button
          icon={props.icon}
          background={props.background}
          color={props.color}
          radius={props.radius}
          noText={props.noText}
          borderRadius={props.borderRadius}
        >
          <Keyboard size={16} />
          {!props.noText &&
            'Oeuvre du mois'
          }
        </Button>
      </WebchatMenu>
    }
  </WebchatContainer>
);


export default Webchat;
