import React from 'react';
import styled from 'styled-components';
import { PlusSquareFill } from '@styled-icons/bootstrap/PlusSquareFill';
import { Search } from '@styled-icons/boxicons-regular/Search';

const Header = ({
  setAddBot,
  setSwitchOnline,
  switchOnline,
  valueInput,
  handleValue,
  user,
  w,
  searchBy,
  setSearchBy,
}) => {
  return (
    <Wrapper>
      <WrapperSearch>
        <WrapperSort>
          <WrapperInput>
            <input
              type="text"
              value={valueInput}

              placeholder={`${w.searchBy} ${ searchBy === 'bot' ? w.bot : w.customer}`}
              onChange={(e) => handleValue(e.target.value)}
            />
            <Search size={15} />
          </WrapperInput>
          <WrapperChoice>
            <div>{w.searchBy}:</div>
            <div>
              <StyledLabel>
                <input
                  type="radio"
                  name="sortSearch"
                  value={searchBy}
                  checked={searchBy === 'organization'}
                  onChange={() => setSearchBy('organization')}
                />
                {w.customer}
              </StyledLabel>
              <StyledLabel>
                <input
                  type="radio"
                  name="sortSearch"
                  value={searchBy}
                  checked={searchBy === 'bot'}
                  onChange={() => setSearchBy('bot')}
                />
                {w.bot}
              </StyledLabel>
            </div>
          </WrapperChoice>
        </WrapperSort>
        <WrapperToggle>
          <StyledText>{w.displayBot}</StyledText>
          <WrapperSwitch>
            <InputCheck type="checkbox" />
            <Switch
              toggle={switchOnline}
              onClick={() => setSwitchOnline(!switchOnline)}
            ></Switch>
          </WrapperSwitch>
        </WrapperToggle>
      </WrapperSearch>
      {user.permissions === 1 && (
        <WrapperButton onClick={() => setAddBot(true)}>
          {w.createBot}
          <PlusSquareFill size={15} />
        </WrapperButton>
      )}
    </Wrapper>
  );
};

const WrapperToggle = styled.div`
  display: flex;
  margin-left: 2rem;
`;

const WrapperSwitch = styled.label`
  display: block;
  position: relative;
  padding-left: 100px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  margin-left: 1rem;
  user-select: none;
`;

const InputCheck = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Switch = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 45px;
  border-radius: 50px;
  background-color: ${({ toggle }) => (toggle ? '#7ed322' : '#fff')};
  transition: background-color 0.15s ease-in;

  &:after {
    content: '';
    position: absolute;
    left: ${({ toggle }) => (toggle ? '21px' : '0px')};
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #2d2f7b;
    transition: left 0.15s ease-in;
    background-color: #fff;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  align-items: center;
`;

const WrapperButton = styled.div`
  background-color: #e06d6d;
  color: #fff;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  transition: opacity 0.5s;
  &:hover {
    opacity: 0.7;
  }

  > svg {
    margin-left: 0.6rem;
  }
`;

const WrapperSearch = styled.div`
  display: flex;
  width: 70%;
  justify-content: flex-start;
  align-items: center;
`;

const StyledText = styled.p`
  color: #fff;
`;

const WrapperInput = styled.div`
  display: flex;
  align-items: center;
  outline: none;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 25px;
  padding: 0.6rem 1rem;
  > input {
    background-color: #2d2f7b;
    color: #fff;
    border: none;

    ::placeholder {
      color: #fff;
    }
    :focus {
      outline: none;
    }
  }
`;

const WrapperSort = styled.div`
  display: flex;
  color: #fff;
  flex-direction: column;
`;

const WrapperChoice = styled.div`
  display: flex;
  font-size: 0.8em;
  margin: 0.4rem auto;
`;

const StyledLabel = styled.label`
  margin-left: 0.6rem;

  > input {
    margin-right: 0.4rem;
  }
`;
export default Header;
