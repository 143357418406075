import React, { useState } from 'react';
import { _sortOph } from '../../_tools/oph';
import { Button } from '@ask-mona/ui';
import Part from './Part';
import Suggestion from './Suggestion';

export default (props) => {
  const {
    g: { w },
    isActive,
    parts,
    typed,
    section,
    event,
  } = props;

  // Allows to control which content to show dynamically, Open or Close (Special is never loaded here, it comes from another component)
  if (!isActive || section === 'special') return null;

  // If in Open tab, general and exception categories are loaded. If in Close tab, only general appears
  if (typed) {
    const { general, exception } = _sortOph(
      parts.map((c) => c.oph).join(';'),
      'typed',
      true
    );
    return (
      <React.Fragment>
        <Section
          {...props}
          label={w.schedule.generalRules}
          event={event}
          parts={general}
          allParts={parts}
          comment="general"
        />
        <Section
          {...props}
          label={w.schedule.exceptions}
          allParts={parts}
          parts={exception}
          comment="exception"
        />
      </React.Fragment>
    );
  } else {
    return <Section {...props} label={w.schedule.generalRules} />;
  }
};

const Section = ({ label, parts, set, isActive, allParts, ...other }) => {
  const {
    g: { w },
    fct: { addOph },
    event,
  } = other;
  const [add, setAdd] = useState(false);
  const [dragOver] = useState(false);

  return (
    <div
      {...other}
      className={`p-md ${dragOver ? 'has-background-primary-light' : ''}`}
    >
      {label && (
        <div className="m-b-md is-size-6 has-text-weight-medium border-b-primary-lighter flex space-between items-center">
          {label}
          {other.name === 'off' && <Suggestion {...other} />}
        </div>
      )}
      <div>
        {parts
          .sort((a, b) => a.index - b.index)
          .map((o) => (
            <Part key={o.index} {...other} part={o} />
          ))}
        {!add && (
          <Button
            className="m-xs is-outlined is-size-7"
            onClick={() => setAdd(true)}
          >
            {w.generic.add}
          </Button>
        )}
        {add && (
          <Part
            {...other}
            part={{ oph: '' }}
            fct={{
              onChange: (nPart) => {
                addOph(nPart, event);
                setAdd(false);
              },
              popOph: () => setAdd(false),
            }}
            addOph={addOph}
          />
        )}
      </div>
    </div>
  );
};
