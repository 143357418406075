import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { withMain } from '../hoc/main';
import { Icon } from '@ask-mona/ui';
import Log from '../_api/log';
import Foundations from '../components/base/Foundations';
import Sidebar from '../components/base/Sidebar';
import Header from '../components/base/Header';
import ErrorBoundary from '../components/ErrorBoundary';
import Portal from '../components/Portal';
import Tutorial from '../components/Tutorial';
import Modal from '../components/Modal';

import Messagerie from './inbox/Messagerie';
import Webchat from './inbox/WCHistory.js';

import MUIButton from '@material-ui/core/Button';

//import Archive from "./inbox/Archive";
class Inbox extends Component {
  constructor(props) {
    super(props);
    const {
      m: { sectionID, menu },
    } = props;
    this.preURL = props.match.url;
    this.Log = null;
    this.state = {
      section: menu.find((s) => s.id === sectionID) || {},
      subID: 0,
      getInfo: false,
      fct: {
        changeSub: this.changeSub,
        getInfo: this.getInfo,
      },
    };
  }

  componentDidMount() {
    this.generateMenu(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.botID !== nextProps.m.bot.id) {
      this.setState({ botID: nextProps.m.bot.id });
      this.generateMenu(nextProps);
    }
  }

  getInfo = () => this.setState((state) => ({ getInfo: !state.getInfo }));

  generateMenu = (props) => {
    const {
      m: { menu, sectionID, client },
    } = props;
    this.Log = new Log(client);
    let curr = menu.find((m) => m.id === sectionID);
    let m = curr.menu[0];
    m && this.changeSub(m.id);
    m && props.history.push(m.path);
  };

  changeSub = (id) => {
    this.setState({ subID: id });
  };

  render() {
    const { subID, section } = this.state;
    const {
      m: { w },
    } = this.props;
    let sub = section.menu.find((c) => c.id === subID);
    return (
      <Foundations
        aside={<Sidebar menu={section.menu} c={this.state} noarrow={true} />}
        header={
          <Header title={w.inbox.title} subtitle={sub ? sub.title : ''} />
        }
      >
        <Portal
          component={<Head {...this.props} fct={this.state.fct} />}
          selector="base-header"
        />
        <Route
          exact
          path={`${this.preURL}/message`}
          render={() => (
            <ErrorBoundary>
              <Messagerie
                {...this.props}
                {...this.state}
                fct={this.state.fct}
                Log={this.Log}
                changeSub={this.changeSub}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          exact
          path={`${this.preURL}/archived`}
          render={() => (
            <ErrorBoundary>
              <Messagerie
                {...this.props}
                {...this.state}
                fct={this.state.fct}
                Log={this.Log}
                archived="true"
                changeSub={this.changeSub}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          exact
          path={`${this.preURL}/webchat`}
          render={() => (
            <ErrorBoundary>
              <Webchat
                {...this.props}
                {...this.state}
                fct={this.state.fct}
                changeSub={this.changeSub}
              />
            </ErrorBoundary>
          )}
        />
        <Route
          exact
          path={`${this.preURL}/mail`}
          render={() => (
            <ErrorBoundary>
              <Messagerie
                {...this.props}
                {...this.state}
                fct={this.state.fct}
                Log={this.Log}
                wc="true"
                changeSub={this.changeSub}
              />
            </ErrorBoundary>
          )}
        />
        <Modal
          isOpen={this.state.getInfo}
          close={() => this.getInfo()}
          selector="#root"
        >
          <Tutorial tuto={w.tutorial.inbox} close={() => this.getInfo()} />
        </Modal>
      </Foundations>
    );
  }
}

const Head = ({ fct }) => (
  <div className="m-l-md flex row grow-1 flex-end">
    <MUIButton
      color="primary"
      className="shrink is-outlined m-l-xs"
      onClick={fct.getInfo}
    >
      <Icon className="has-text-primary" icon="fas fa-exclamation-circle" />
    </MUIButton>
  </div>
);

export default withMain(Inbox);
