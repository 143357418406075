export default {
  noPush: ['Aucun push', 'No push'],
  titlePush: ['Titre du push', 'Title of the push'],
  published: ['Push envoyé', 'Push sent'],
  unpublished: ['Push en cours', 'Push in progress'],
  draft: ['Brouillon', 'Draft'],
  addNewPush: ['Ajouter un push', 'Add a push'],
  tuto: [
    'Vous trouverez ici tous les push que vous avez crées. Pour ajouter un nouveau push, cliquez sur “ajouter un push” et laissez-vous guider à travers les différentes catégories à renseigner.',
    'Here you will find all the pushes you have created. To add a new push, click on "add a push" and let yourself be guided through the different categories to be filled in.',
  ],
};
