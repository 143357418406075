import styled from 'styled-components';

const ContainerScroll = styled.div`
  display: ${({ preview }) => (preview ? 'flex' : 'inline-block')};
  flex-direction: ${({ preview }) => preview && 'column'};
  align-items: ${({ preview }) => preview && 'center'};
  width: ${(props) => props.width}%;
  border-left: ${(props) => (props.border ? '1px solid #979797' : '')};
  height: 100%;
  overflow: auto;
  color: ${(props) => props.theme.colors.primary};
  padding: ${(props) => (props.messaging ? '0' : '0 2rem')};
  opacity: ${({ opacity }) => opacity && 0.5};
`;

export default ContainerScroll;
