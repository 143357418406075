import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { _sendFeedback } from '../../../_tools/ui';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { _generatePath } from '../../../components/scenario/Reducer';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#fff' },
    secondary: { main: '#E66E6E' },
  },
});

export const EditCategoriePopover = (props) => {
  const {
    m: { w },
    focus,
    Bot,
    dispatch,
    modules,
    title,
    stack,
    subfocus,
    subModules,
  } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [name, setName] = useState('');

  useEffect(() => {
    if (title) {
      setName(title);
    }
  }, [title]);

  const handleSave = async () => {
    if (focus && name !== title) {
      if ('trigger' in focus) {
        let newFocus = { ...focus };
        let oldTag = newFocus['trigger'][stack[0].props.sub.key]['tag'];
        let newTag = oldTag.replace(/((.*\/)?.*)/, `$2${name}`);
        for (let i = 0, len = stack.length; i < len; i++) {
          newFocus['trigger'][stack[i].props.sub.key]['tag'] = newTag;
          await Bot._updateModule({
            path: _generatePath(focus, stack[i].props.sub).concat('tag'),
            module: { tag: newTag },
          });
        }
        dispatch({ type: 'setFocus', payload: newFocus });
        dispatch({
          type: 'setModules',
          payload: modules.map((m) => (m.key === focus.key ? newFocus : m)),
        });
        if (subfocus) {
          dispatch({ type: 'setSubFocus', payload: subfocus });
        }
      } else {
        let oldTag = stack[0].props.sub['tag'];
        let newTag = oldTag.replace(/((.*\/)?.*)/, `$2${name}`);
        for (let i = 0, len = stack.length; i < len; i++) {
          await Bot._updateModule({
            path: _generatePath(stack[i].props.sub).concat('tag'),
            module: { tag: newTag },
          });
        }
        let newSubModule = subModules.map((el) => {
          if (stack.find((s) => s.props.sub.id === el.id))
            return { ...el, tag: newTag };
          else return el;
        });
        dispatch({
          type: 'setSubModules',
          payload: newSubModule,
        });
      }
    }

    _sendFeedback('success', w.success.saved);
    setIsPopoverOpen(false);
  };

  return focus && focus.key !== 'first' && focus.key !== 'fallback' ? (
    <Popover
      isOpen={isPopoverOpen}
      position={['right', 'bottom']}
      padding={5}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={'#2D2F7B'}
          arrowSize={10}
          arrowStyle={{ zIndex: 10000, borderRight: '15px solid #2D2F7B' }}
        >
          {' '}
          <MuiThemeProvider theme={theme}>
            <Container>
              <Header>
                <HeaderText>{w.scenario.modifyFolder}</HeaderText>
                <ButtonContainer>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleSave}
                  >
                    {w.generic.save}
                  </Button>
                </ButtonContainer>
              </Header>
              <LineContainer>
                <LineTitle>{w.scenario.folderName}</LineTitle>
                <NameInput>
                  <CustomInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </NameInput>
              </LineContainer>
            </Container>
          </MuiThemeProvider>
        </ArrowContainer>
      )}
    >
      <IconContainer module={true}>
        <EditRoundedIcon
          color="primary"
          style={{ fontSize: '16px' }}
          onClick={() => {
            setIsPopoverOpen(!isPopoverOpen);
          }}
        />
      </IconContainer>
    </Popover>
  ) : null;
};

const CustomInput = styled.input`
  background-color: ${(p) => p.theme.colors.primary};
  color: white;
  border: 1px solid white;
  border-radius: 2px;
  height: 40px;
  width: 250px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  &:focus {
    border-color: white;
    outline-color: white;
  }
`;

const IconContainer = styled.div`
  margin-left: ${(p) => (p.module ? '3px' : '10px')};
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Container = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  position: relative;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1000 !important;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled.span`
  margin-right: 20px;
  font-size: 1.1rem;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;

const LineTitle = styled.div`
  margin-bottom: 10px;
`;
const NameInput = styled.div``;
