import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Nodata from '../../components/Nodata';
import Modal from '../../components/Modal';
import Element from './Element';
import { Board } from './Ui';
import _formatModule from './tools/';
import { Button, Icon } from '@ask-mona/ui';
import axios from 'axios';

import ScenariosContext from '../../containers/scenarios/context/scenariosContext';

export default (props) => {
  const [conv] = useState(React.createRef());
  const {
    focus,
    m: { w },
    advanced,
    force,
    lang,
  } = props;

  const scenariosContext = useContext(ScenariosContext);

  useEffect(() => {
    let el = conv.current;
    if (el) {
      const scrollHeight = el.scrollHeight;
      const height = el.clientHeight;
      const maxScrollTop = scrollHeight - height;
      el.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(focus || {}).key]);

  return (
    <ContentContainer
      className={`overflow-scroll relative inline-block has-text-primary-ter h-100p align-top space-initial ${
        advanced && focus && !force ? 'w-80p' : force ? 'w-80p' : 'w-100p'
        // advanced && focus && !force ? 'w-55p' : force ? 'w-80p' : 'w-75p'
      }`}
      style={{
        height: 'inherit',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {scenariosContext.functionalitiesSubsbar &&
        scenariosContext.functionalitiesSubsbarFocus &&
        scenariosContext.functionalitiesSubsbar[
          scenariosContext.functionalitiesSubsbarFocus
        ] &&
        scenariosContext.functionalitiesSubsbar[
          scenariosContext.functionalitiesSubsbarFocus
        ]['description'] && (
          <Descritpion>
            {
              scenariosContext.functionalitiesSubsbar[
                scenariosContext.functionalitiesSubsbarFocus
              ]['description']
            }
          </Descritpion>
        )}
      <div className="overflow-scroll p-b-xxl h-100p" ref={conv}>
        {!focus && <Nodata title={w.error.noFocus} />}
        {/* {focus && <Heading {...props} />} */}
        {focus && <Content {...props} />}
        <CpyModal {...props} />
      </div>
      {scenariosContext.functionalitiesSubsbarFocus &&
      scenariosContext.functionalitiesSubsbar &&
      scenariosContext.functionalitiesSubsbar[
        scenariosContext.functionalitiesSubsbarFocus
      ] &&
      scenariosContext.functionalitiesSubsbar[
        scenariosContext.functionalitiesSubsbarFocus
      ][lang] &&
      ((typeof scenariosContext.functionalitiesSubsbar[
        scenariosContext.functionalitiesSubsbarFocus
      ][lang] === 'string' &&
        scenariosContext.functionalitiesSubsbar[
          scenariosContext.functionalitiesSubsbarFocus
        ][lang] !== 'content') ||
        typeof scenariosContext.functionalitiesSubsbar[
          scenariosContext.functionalitiesSubsbarFocus
        ][lang] === 'boolean')
        ? null
        : focus && <Board {...props} />}
      {/* {focus && <Board {...props} />} */}
    </ContentContainer>
  );
};

const CpyModal = (props) => {
  const { cpyArray, cpyFolder, dispatch, advanced } = props;

  const scenariosContext = useContext(ScenariosContext);

  return (
    <Modal
      isOpen={cpyFolder}
      close={() => dispatch({ type: 'toggleCpyFolder', payload: false })}
      selector="#root"
    >
      <div className="w-4  has-background-white">
        <div className="has-background-primary-ter has-text-white p-md">
          <div className="flex row space-between">
            Dossier de copie
            <Button
              className="shrink is-outlined is-borderless"
              onClick={() =>
                dispatch({ type: 'toggleCpyFolder', payload: false })
              }
            >
              <Icon icon="fas fa-times" />
            </Button>
          </div>
        </div>
        <div className="h-4 overflow-scroll p-md">
          {cpyArray.map((el, i) => {
            return (
              <div
                key={i}
                className="flex items-center space-between border-b-grey-lighter"
              >
                <Element {...props} el={el} index={i} fix="true" />
                <div className="flex column center">
                  <Button
                    color="secondary"
                    className="shrink is-outlined is-borderless"
                    onClick={() => {
                      dispatch({ type: 'removeCpy', payload: el });
                    }}
                  >
                    <Icon icon="fas fa-trash" />
                  </Button>
                  <Button
                    className="shrink is-outlined is-borderless"
                    onClick={() => {
                      dispatch({
                        type: 'addElement',
                        payload: {
                          name: 'copy',
                          value: el,
                          func: advanced
                            ? null
                            : scenariosContext.functionalitiesSubsbarFocus ===
                              'content'
                            ? null
                            : scenariosContext.functionalitiesSubsbarFocus,
                        },
                      });
                    }}
                  >
                    <Icon icon="fas fa-file-import" />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

const ContentComponent = (props) => {
  const {
    advanced,
    // prefix,
    // cEl,
    // fEl,
    // addons,
  } = props;

  const { prefix, cEl, fEl, addons } = _formatModule(props);

  const scenariosContext = useContext(ScenariosContext);

  return (
    <ContentElements className="h-100p">
      {advanced
        ? // ? key === 'p:share' ||
          //   key === 'p:affluence' ||
          //   key === 'p:language' ||
          //   key === 'p:price' ||
          //   key === 'p:openings' ||
          //   key === 'p:intinerary' ||
          //   key === 'p:itinerary' ||
          //   key === 'p:end_lang'
          [prefix, ...fEl, ...cEl, ...addons]
        : // : [prefix, ...cEl, ...fEl, ...addons]
        scenariosContext.functionalitiesSubsbarFocus &&
          scenariosContext.functionalitiesSubsbarFocus === 'content'
        ? [prefix, ...cEl, ...addons]
        : scenariosContext.functionalitiesSubsbarFocus &&
          scenariosContext.functionalitiesSubsbar &&
          Object.keys(scenariosContext.functionalitiesSubsbar).length > 1 &&
          fEl
        ? [
            ...fEl.filter(
              (el) =>
                el && el.key === scenariosContext.functionalitiesSubsbarFocus
            ),
            ...fEl.filter(
              (el) =>
                el &&
                el.key ===
                  Object.keys(scenariosContext.functionalitiesSubsbar).filter(
                    (i) =>
                      scenariosContext.functionalitiesSubsbar[i] &&
                      scenariosContext.functionalitiesSubsbar[i]['parent'] &&
                      scenariosContext.functionalitiesSubsbar[i]['parent'] ===
                        scenariosContext.functionalitiesSubsbarFocus
                  )[0]
            ),
          ]
        : fEl}
    </ContentElements>
  );
};

const Content = (props) => {
  const {
    focus: { id },
    m: { bot },
  } = props;

  const [data, setData] = useState([]);
  const [wording, setWording] = useState([]);

  const scenariosContext = useContext(ScenariosContext);

  useEffect(() => {
    const getWording = async () => {
      if (bot && bot.familiar) {
        const res = await axios.get(`${bot.familiar.wording_url_v2}`);

        if (res.data) setData(res.data);
        else console.error('no wording data fetched');
      }
    };

    getWording();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bot.familiar]);

  useEffect(() => {
    setWording(data[id]);
    scenariosContext.setFunctionalitiesSubsbar(data[id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  return (
    <div className="h-100p">
      <ContentComponent wording={wording} {...props} />
    </div>
  );
};

const ContentContainer = styled.div`
  height: 100%;
  padding-bottom: 135px;
  margin-bottom: 0px;
  overflow-x: hidden;

  @media (max-width: 1400px) {
    padding-bottom: 125px;
  }

  @media (max-width: 800px) {
    padding-bottom: 80px;
  }
`;

const Descritpion = styled.div`
  display: flex;
  color: ${(p) => p.theme.colors.primary};
  width: 100%;
  height: 15%;
  /* border-bottom: 1px solid #ececec; */
  box-shadow: 2px 0px 5px #ececec;
  font-size: 1.1rem;
  /* letter-spacing: 1px; */
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const ContentElements = styled.div`
  padding-top: 20px;
`;
