import React from 'react';

export default ({ m: { w }, edit }) => (
  <div
    className="flex row items-center has-background-primary has-text-white is-size-6 sticky top border-b-grey-lighter"
    style={{ zIndex: 0 }}
  >
    <div className="p-sm flex grow-2 basis-0">{w.generic.title}</div>
    <div className="p-sm flex grow-2 basis-0">{w.pricing.grantee}</div>
    <div className="p-sm flex grow-2 basis-0">{w.pricing.information}</div>
    <div className="p-sm flex grow-1 basis-0 center">{w.pricing.price}</div>
    <div className="p-sm flex grow-2 basis-0 center">{w.generic.action}</div>
  </div>
);
