import React, { useState, useEffect } from 'react';
import Bot from '../../../../_api/bot';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import StyledBigTitle from '../../../../components/parameters/StyledTitle';
import StyledLabel from '../../../../components/parameters/StyledLabel';
import InputRadio from '../../../../components/parameters/InputRadio';
import CreateMenu from './CreateMenu';
import Webchat from '../../../../components/parameters/Webchat';
import { RightArrow } from '@styled-icons/boxicons-solid/RightArrow';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';
import templateMenu from './templates/Menu';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WrapperDiv = styled.div`
  display: flex;
  align-items: ${({ alignItem }) => alignItem || 'flex-start'};
  margin: ${({ mr }) => (mr ? mr : '2rem 0')};
  flex-wrap: wrap;
  justify-content: ${({ space }) => space};
  width: ${({ width }) => width};
`;

const SubititleLight = styled.h3`
  font-size: 1.1.rem;
  margin-right: 2rem;
  margin-bottom: ${({ mr }) => mr && '1rem'};
`;

const WrapperChat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Menu = ({ m, wWebchat, state, dispatch, bot, client }) => {
  const [hideFirstContent, setHideFirstContent] = useState(true);
  const [hideSecondContent, setHideSecondContent] = useState(true);

  useEffect(() => {
    const fetchScenario = async () => {
      let api = new Bot(client, bot);
      let res = await api._getModules();
      if (res.success && res.json) {
        if ('trigger' in res.json) {
          let triggers = res.json['trigger'];
          let payloads = Object.keys(triggers)
            .filter((k) => !triggers[k].tag)
            .map((k) => ({
              value: k.replace('p:', ''),
              label: triggers[k].name,
            }));
          dispatch({ type: 'setModulesScenario', payload: payloads });
        }
      }
    };
    fetchScenario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bot]);

  return (
    <Wrapper>
      <StyledBigTitle>
        {wWebchat.templateChoice}
        <span onClick={() => setHideFirstContent(!hideFirstContent)}>
          {hideFirstContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideFirstContent && (
        <>
          <WrapperDiv mr={'1rem 0 0 0'}>
            <SubititleLight>{wWebchat.defaultMenu}</SubititleLight>
            <StyledLabel radio flex widht={'20'} mr>
              <InputRadio
                type="radio"
                name="menuOpen"
                value={state.menuOpen}
                checked={!state.menuOpen}
                onChange={() => {
                  dispatch({ type: 'setMenuOpen', payload: !state.menuOpen });
                }}
              />
              {wWebchat.close}
            </StyledLabel>
            <StyledLabel radio flex widht={'20'}>
              <InputRadio
                type="radio"
                name="menuOpen"
                value={state.menuOpen}
                checked={state.menuOpen}
                onChange={() => {
                  dispatch({ type: 'setMenuOpen', payload: !state.menuOpen });
                }}
              />
              {wWebchat.open}
            </StyledLabel>
          </WrapperDiv>

          <WrapperDiv space={'space-around'} mr={'0 0 2rem 0'}>
            <WrapperChat>
              <Webchat menu opt2 />
              <StyledLabel radio flex mt={'1rem'}>
                <InputRadio
                  name="templateMenu"
                  type="radio"
                  value="template1"
                  checked={
                    JSON.stringify(state.menuTemplate) ===
                    JSON.stringify(templateMenu('template1'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setMenuTemplate',
                      payload: templateMenu(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperChat>
            <WrapperChat>
              <Webchat menu />
              <StyledLabel radio flex mt={'1rem'}>
                <InputRadio
                  name="templateMenu"
                  type="radio"
                  value="template2"
                  checked={
                    JSON.stringify(state.menuTemplate) ===
                    JSON.stringify(templateMenu('template2'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setMenuTemplate',
                      payload: templateMenu(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperChat>
            <WrapperChat>
              <Webchat menu icon background={'#a1a0a0'} color={'#FFF'} />
              <StyledLabel radio flex mt={'1rem'}>
                <InputRadio
                  name="templateMenu"
                  type="radio"
                  value="template3"
                  checked={
                    JSON.stringify(state.menuTemplate) ===
                    JSON.stringify(templateMenu('template3'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setMenuTemplate',
                      payload: templateMenu(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperChat>
            <WrapperChat>
              <Webchat menu icon radius background={'#a1a0a0'} color={'#FFF'} />
              <StyledLabel radio flex mt={'1rem'}>
                <InputRadio
                  name="templateMenu"
                  type="radio"
                  value="template4"
                  checked={
                    JSON.stringify(state.menuTemplate) ===
                    JSON.stringify(templateMenu('template4'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setMenuTemplate',
                      payload: templateMenu(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperChat>
            <WrapperChat>
              <Webchat
                menu
                icon
                background={'#FFF'}
                backgroundMenu={'#a1a0a0'}
                noText
                color={'#111'}
                borderRadius={'5px'}
              />
              <StyledLabel radio flex mt={'1rem'}>
                <InputRadio
                  type="radio"
                  value="template5"
                  checked={
                    JSON.stringify(state.menuTemplate) ===
                    JSON.stringify(templateMenu('template5'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setMenuTemplate',
                      payload: templateMenu(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperChat>
            <WrapperChat>
              <Webchat
                menu
                icon
                background={'#FFF'}
                backgroundMenu={'#f8f7f7'}
                noText
                color={'#111'}
                borderRadius={'25px'}
              />
              <StyledLabel radio flex mt={'1rem'}>
                <InputRadio
                  type="radio"
                  value="template6"
                  checked={
                    JSON.stringify(state.menuTemplate) ===
                    JSON.stringify(templateMenu('template6'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setMenuTemplate',
                      payload: templateMenu(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperChat>
          </WrapperDiv>
          <Wrapper>
            <SubititleLight>{wWebchat.itemsMenu}</SubititleLight>
            <CreateMenu
                m={m}
              state={state}
              dispatch={dispatch}
              w={wWebchat}
              botId={bot}
            />
          </Wrapper>
        </>
      )}
      <StyledBigTitle>
        {wWebchat.templateConfig}
        <span onClick={() => setHideSecondContent(!hideSecondContent)}>
          {hideSecondContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideSecondContent && (
        <>
          <WrapperDiv mr={'0 2rem 2rem 2rem'} space={'space-between'}>
            <div>
              <SubititleLight mr>{wWebchat.bgMenu}</SubititleLight>
              <SketchPicker
                color={state.backgroundMenu}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setBackgroundMenu', payload: val.hex });
                }}
              />
            </div>
            <div>
              <SubititleLight mr>{wWebchat.menu_font_colour}</SubititleLight>
              <SketchPicker
                color={state.colorMenu}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setColorMenu', payload: val.hex });
                }}
              />
            </div>
          </WrapperDiv>
        </>
      )}
    </Wrapper>
  );
};

export default Menu;
