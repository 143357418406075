import React, { useState } from 'react';
import { Checkbox } from '@ask-mona/ui';

function Protocol(props) {
  const [check, setCheck] = useState(false);

  const onChange = (bool) => {
    bool ? setCheck(false) : setCheck(true);
  };

  const Script = () => {
    return (
      <pre>
        {`<script type="text/javascript">(function(w,g,u){w.URLSearchParams=w.URLSearchParams||function(u){this.u=u;this.get=function(t){var r=new RegExp('[?&]'+t+'=([^&#]*)').exec(s.u);return(!r?null:decodeURI(r[1])||0)};return(s)};var urlParams=new URLSearchParams(u);var get=urlParams.get(g);if(get){try{const parts=get.split(":");fetch("http://api.askmonastudio.com/rest/analytics/tagManager",{method:"POST",mode:"cors",headers:{'Accept':'application/json','Content-Type':'application/json',},body:JSON.stringify({id:Number(parts[0]),event:Number(parts[1]),action:decodeURIComponent(parts[2])})})}catch(err){console.log(err)}}})(window,"aan_tag",window.location.search)</script>`}
      </pre>
    );
  };

  return (
    <div className="m-t-md m-b-md m-l-md">
      {check && (
        <Checkbox onClick={() => onChange(true)} label="https" defaultChecked />
      )}
      {!check && <Checkbox onClick={() => onChange(false)} label="https" />}
      {check && <Checkbox onClick={() => onChange(true)} label="http" />}
      {!check && (
        <Checkbox onClick={() => onChange(false)} label="http" defaultChecked />
      )}
      {!check && (
        <div className="w-90 p-md border has-text-primary-white is-size-7 break-word overflow-auto m-t-md has-background-primary-bis">
          {Script()}
        </div>
      )}
    </div>
  );
}

export default Protocol;
