import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, Frame, Field, Input } from '@ask-mona/ui';
import { withGlobals } from '../hoc/globals';
import { _sendFeedback } from '../_tools/ui';
import logo from '../_assets/logoWhite.png';

import { login } from "../api/graphql/auth.api";

async function submit ({ email, password }, { setLoading, notify, switchLanguage, history, w }) {
  setLoading(true);
  try {
    const { user, token } = await login({ email, password });

    localStorage.setItem('token', token);
    switchLanguage(user.language_code);
    history.push('/office');

  } catch(e) {
    if (e.message.includes("Unauthorized")) {
      notify('danger', w.error.emailAndPasswordDoesNotMatch);
    }
  } finally {
    setLoading(false);
  }
}

const Signin = ({ g: { w, user, fct }, history }) => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    user._logout();
    localStorage.removeItem("token");
    sessionStorage.clear();
  }, []);

  function handleSubmit() {
    return submit({ email, password }, {
      setLoading,
      notify: _sendFeedback,
      switchLanguage: fct. switchLang,
      history,
      w,
    });
  }

  return (
    <Container>
      <BoxContainer>
        <Frame background="transparent">
          <img src={logo} alt="AskMona-Logo" onLoad={() => setLoading(false)} />
        </Frame>
        <Box>
          <div>
            <Field
              title={w.form.email}
              subtitle={w.form.emailSub}
              className="m-b-md"
            >
              <Input
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Field>
            <Field
              title={w.form.password}
              subtitle={w.form.passwordSub}
              className="m-b-md"
            >
              <Input
                name="password"
                type="password"
                value={password}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Field>
          </div>
          <ButtonsContainer>
            <Link to="/password/forget">
              <Button className="is-uppercase" color="primary-bis">
                {w.generic.forgetPassword}
              </Button>
            </Link>
            <Button
              className={`m-l-md is-uppercase ${loading ? 'is-loading' : ''}`}
              onClick={() => handleSubmit()}
            >
              {w.generic.signin.toUpperCase()}
            </Button>
          </ButtonsContainer>
        </Box>
      </BoxContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoxContainer = styled.div`
  padding: 1rem;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

export default withGlobals(Signin);
