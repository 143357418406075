import React, { useContext } from 'react';
import styled from 'styled-components';
import FlagIcon from '../../../components/FlagIcon';
import { QAContext } from '../context/QAContext';
import languageTranslations from "../../../translations/languages";

interface Props {
  bot_lang: Array<string>;
  lang: string;
  setLang: (lang: string) => void;
}

interface ButtonsProps {
  readonly isSelected: boolean;
}

const Languages = (props: Props) => {
  const { bot_lang } = props;
  const { state, dispatch } = useContext(QAContext);

  return (
    <React.Fragment>
        {bot_lang.map((language) => (
            <Button
                isSelected={state.language === language}
                onClick={() => dispatch({ type: 'SET_LANGUAGE', payload: language })}
            >
                <FlagIcon code={languageTranslations.relatedCountry[language]} />
            </Button>
        ))}
    </React.Fragment>
  );
};

const Button = styled.button<ButtonsProps>`
  display: flex;
  padding: 0.25em;
  margin: 0.25rem;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  font-size: 1em;
  border-radius: 3px;
  background: transparent;
  border: none;
  border: ${({ isSelected }) => (isSelected ? '2px solid #5054be' : 'none')};
`;

export default Languages;
