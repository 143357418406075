import React, {
  useState,
  useReducer,
  useEffect,
  useContext,
  useCallback,
} from "react";
import Foundation from "../components/base/Foundations";
import Sidebar from "./questionsAnswers/aside/Sidebar";
import Header from "../components/base/Header";
import Content from "./questionsAnswers/Content";
import Languages from "./questionsAnswers/header/Languages";
import QAProvider from "./questionsAnswers/context/QAContext";
import useTopics from "../api/graphql/hooks/useTopics";
import { QAContext } from "./questionsAnswers/context/QAContext";
import { Places as PlacesType } from "../types/qa/topics";
import { _sendFeedback } from "../_tools/ui";
import MUIButton from "@material-ui/core/Button";
import styled from "styled-components";
import { GetAppOutlined, Publish, PublishOutlined } from "@material-ui/icons";

interface Props {
  bot_lang: Array<string>;
  bot_id: number;
  default_language: string;
  token: string;
  isMultisite: boolean;
  places: PlacesType;
  permissions: number;
  organizationId: number;
  w: any;
}

const QA = (props: Props) => {
  return (
    <QAProvider>
      <QuestionsAnswers {...props} />
    </QAProvider>
  );
};

const QuestionsAnswers = (props: Props) => {
  const { state, dispatch } = useContext(QAContext);
  const { bot_lang, bot_id, token } = props;
  const {
    state: { categories, topics, language, permission },
    topicsApi,
    loading,
  } = useTopics(bot_id, token);

  const [lang, setLang] = useState<string>("");

  useEffect(() => {
    if (state.currentCategoryId !== null) {
      if (state.searchValue.length) {
        dispatch({ type: "SET_TOPIC_STATUS", payload: "all" });
        topicsApi.getTopics(bot_id, null, state.searchValue);
      } else {
        dispatch({ type: "SET_TOPIC_STATUS", payload: "enabled" });
        topicsApi.getTopics(bot_id, state.currentCategoryId, null);
      }
    }
  }, [state.currentCategoryId, state.language, state.searchValue]);

  useEffect(() => {
    dispatch({ type: "SET_IS_MULTISITE", payload: props.isMultisite });
    dispatch({ type: "SET_PLACES", payload: props.places });
    dispatch({ type: "SET_BOT_ID", payload: props.bot_id });
    dispatch({ type: "SET_TOKEN", payload: props.token });
    dispatch({ type: "SET_PERMISSION", payload: props.permissions });
    dispatch({ type: "SET_PERMISSION", payload: props.permissions });
    dispatch({ type: "SET_LANGUAGE", payload: props.default_language });
    dispatch({ type: "SET_ORGANIZATION_ID", payload: props.organizationId });
    dispatch({ type: "SET_WORDING", payload: props.w });
  }, []);

  useEffect(() => {
    switch (loading) {
      case "loading":
        return _sendFeedback("warning", "loader");
      case "loaded":
        return _sendFeedback("success", props.w.saved);
      case "error":
        return _sendFeedback("danger", props.w.error);
      default:
        break;
    }
  }, [loading]);

  const downloadTopics = useCallback(() => {
    topicsApi.exportTopics(bot_id).then((url) => {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
    });
  }, [topicsApi]);

  const importTopics = useCallback(
    (file) => {
      console.log(file);
      topicsApi.importTopics(file, bot_id).then(() => {
        dispatch({ type: "SET_LANGUAGE", payload: state.language });
      });
    },
    [topicsApi]
  );

  return (
    <Foundation
      aside={<Sidebar api={topicsApi} w={props.w} />}
      header={
        <Header
          title={props.w.qa_title}
          addons={[
            ...[
              <Languages
                key={lang}
                bot_lang={bot_lang}
                lang={lang}
                setLang={setLang}
              />,
              <WrapperExportButton>
                <MUIButton
                  color="primary"
                  variant="contained"
                  onClick={() => downloadTopics()}
                  size="small"
                >
                  <GetAppOutlined fontSize="small" />
                </MUIButton>
              </WrapperExportButton>,
            ],
            ...(permission === 1
              ? [
                  <WrapperImportButton>
                    <MUIButton
                      color="primary"
                      variant="contained"
                      size="small"
                      component="label"
                    >
                      <PublishOutlined fontSize="small" />
                      <input
                        hidden
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        type="file"
                        onChange={(event) => {
                          if (event.target?.files?.length) {
                            importTopics(event.target.files[0]);
                          }
                        }}
                      />
                    </MUIButton>
                  </WrapperImportButton>,
                ]
              : []),
          ]}
        />
      }
    >
      <Content
        topics={state.topics}
        api={topicsApi}
        botId={bot_id}
        token={token}
        w={props.w}
      />
    </Foundation>
  );
};

const WrapperExportButton = styled.div`
  margin-left: 30px;
  padding: 0px;
`;

const WrapperImportButton = styled.div`
  margin-left: 10px;
  padding: 0px;
`;

export default QA;
