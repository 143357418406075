export const initialState = {
  currentPage: '',
  backgroundColorInputText: '',
  sendImage: '',
  fonts: [],
  fontSelected: '',
  urlFont: '',
  formatFont: '',
  wording: {},
  opts: {},
  skin: {},
  oldBackground: '',
  //Bubble
  template: '',
  title: '',
  titleEn: '',
  subtitleWidget: '',
  subtitleWidgetEn: '',
  subtitle: '',
  widgetIcon: '',
  widgetHover: '',
  colorBubble: '',
  colorFontBubble: '',
  fontBubble: '',
  rightMobile: '',
  bottomMobile: '',
  //Window
  templateWindow: '',
  borderRadius: '',
  backgroundChoice: '',
  backgroundColorBody: '',
  imageBody: '',
  backgroundSize: '',
  backgroundPosition: '',
  //Header
  v2Header: false,
  hasHeader: false,
  headerCurved: false,
  headerSize: '',
  positionHeader: '',
  colorHeader: '',
  headerWebchat: '',
  subtitleValue: '',
  colorText: '',
  distanceIconText: '',
  displayLogo: true,
  displayTitle: true,
  displaySubtitle: false,
  sizeIcon: '',
  sizeTitle: '',
  sizeSubtitle: '',
  sizeHeaderIcon: '',
  customIcon: '',
  avatarHeader: '',
  toggleMobile: false,
  //Menu
  menuOpen: false,
  menuTemplate: '',
  backgroundMenu: '',
  colorMenu: '',
  modulesScenario: [],
  menuItems: [],
  //QR
  qrTemplate: '',
  colorFontQr: '',
  colorQr: '',
  gradientQr: '',
  borderQr: '',
  fontSizeQr: '',
  heightQr: '',
  widthQr: '',
  borderColorQr: '',
  //Input
  inputTextActive: true,
  templateInput: '',
  backgroundInput: '',
  colorInputText: '',
  sendButton: '',
  textInput: '',
  fontSizeBoard: '',
  colorInputIcons: '',
  iconMenu: '',
  iconSendImage: '',
  sizeIconSend: '',
  sizeIconSendImage: '',
  sizeIconMenu: '',
  noBg: false,
  //Caroussels
  templateCaroussels: '',
  radiusCardCaroussel: '',
  colorBtnCard: '',
  backgroundCard: '',
  colorFontCaroussel: '',
  backgroundButtonCaroussel: '',
  borderColorButton: '',
  colorPagination: '',
  colorBgArrow: '',
  templateControls: '',
  fontSizeTitleCaroussel: '',
  fontSizeSubtitleCaroussel: '',
  fontSizeButtons: '',
  paddingButtons: '',
  colorArrow: '',
  //Webview
  templateWebview: '',
  positionBtn: '',
  //Message
  templateMessage: '',
  templateAnimation: '',
  iconMessageUser: false,
  iconMessageBot: false,
  iconPosition: '',
  gradientUser: '',
  colorBubbleUser: '',
  colorBubbleFontUser: '',
  gradientBot: '',
  colorBubbleBot: '',
  colorBubbleFontBot: '',
  colorAnimation: '',
  fullpage: '',
  boxShadowWebchat: '',

  userIcon: '',
  botIcon: '',
  fontSizeMessage: '',
  //Fullpage
  layoutCenter: false,
  noBackgroundMessage: false,
  hideAvatarFullpage: false,
  colorMessageUserFullpage: '',
  colorMessageBotFullpage: '',
  hideHeaderFullpage: false,
  //Homepage
  homeActive: false,
  homeTitle: {},
  homeImage: '',
  homeContent: {},
  homeButtons: [],
  homeTitleFontSize: '',
  homeTitleColor: '',
  homeContentBg: '',
  homeContentFontSize: '',
  homeContentColor: '',
  homeButtonsColor: '',
  homeButtonsWidth: '',
  homeButtonsHeight: '',
  homeButtonsBorder: '',
  homeButtonsBackground: '',
  // Webview Mail
  webviewMailColor: '',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setFonts':
      return {
        ...state,
        fonts:
          state.fonts.length !== 0
            ? [...state.fonts, action.payload]
            : action.payload,
      };
    case 'setFontSelected':
      return { ...state, fontSelected: action.payload };
    case 'setHeaderWebchat':
      return { ...state, headerWebchat: action.payload };
    case 'setSubtitleValue':
      return { ...state, subtitleValue: action.payload };
    case 'setTitle':
      return { ...state, title: action.payload };
    case 'setSubtitle':
      return { ...state, subtitle: action.payload };
    case 'setSubtitleWidget':
      return { ...state, subtitleWidget: action.payload };
    case 'setInputTextActive':
      return { ...state, inputTextActive: action.payload };
    case 'setBorderRadius':
      return { ...state, borderRadius: action.payload };
    case 'setWidgetIcon':
      return { ...state, widgetIcon: action.payload };
    case 'setV2Header':
      return { ...state, v2Header: action.payload };
    case 'setColorHeader':
      return { ...state, colorHeader: action.payload };
    case 'setHeaderSize':
      return { ...state, headerSize: action.payload };
    case 'setPositionHeader':
      return { ...state, positionHeader: action.payload };
    case 'setDistanceIconText':
      return { ...state, distanceIconText: action.payload };
    case 'setDisplayLogo':
      return { ...state, displayLogo: action.payload };
    case 'setDisplayTitle':
      return { ...state, displayTitle: action.payload };
    case 'setDisplaySubtitle':
      return { ...state, displaySubtitle: action.payload };
    case 'setSizeIcon':
      return { ...state, sizeIcon: action.payload };
    case 'setSizeTitle':
      return { ...state, sizeTitle: action.payload };
    case 'setSizeSubtitle':
      return { ...state, sizeSubtitle: action.payload };
    case 'setSizeHeaderIcon':
      return { ...state, sizeHeaderIcon: action.payload };
    case 'setCustomIcon':
      return { ...state, customIcon: action.payload };
    case 'setAvatarHeader':
      return { ...state, avatarHeader: action.payload };
    case 'setToggleMobile':
      return { ...state, toggleMobile: action.payload };
    case 'setHasHeader':
      return { ...state, hasHeader: action.payload };
    case 'setBackgroundColorBody':
      return { ...state, backgroundColorBody: action.payload };
    case 'setImageBody':
      return { ...state, imageBody: action.payload };
    case 'setHeaderCurved':
      return { ...state, headerCurved: action.payload };
    case 'setIconPosition':
      return { ...state, iconPosition: action.payload };
    case 'setTemplateMessage':
      return { ...state, templateMessage: action.payload };
    case 'setColorBubbleUser':
      return { ...state, colorBubbleUser: action.payload };
    case 'setColorBubbleBot':
      return { ...state, colorBubbleBot: action.payload };
    case 'setIconMessageUser':
      return { ...state, iconMessageUser: action.payload };
    case 'setIconMessageBot':
      return { ...state, iconMessageBot: action.payload };
    case 'setQrTemplate':
      return { ...state, qrTemplate: action.payload };
    case 'setColorQr':
      return { ...state, colorQr: action.payload };
    case 'setColorFontQr':
      return { ...state, colorFontQr: action.payload };
    case 'setBorderQr':
      return { ...state, borderQr: action.payload };
    case 'setTemplateCaroussels':
      return { ...state, templateCaroussels: action.payload };
    case 'setRadiusCardCaroussel':
      return { ...state, radiusCardCaroussel: action.payload };
    case 'setColorBtnCard':
      return { ...state, colorBtnCard: action.payload };
    case 'setBackgroundButtonCaroussel':
      return { ...state, backgroundButtonCaroussel: action.payload };
    case 'setBorderColorButton':
      return { ...state, borderColorButton: action.payload };
    case 'setColorFontCouroussel':
      return { ...state, colorFontCouroussel: action.payload };
    case 'setColorPagination':
      return { ...state, colorPagination: action.payload };
    case 'setColorBgArrow':
      return { ...state, colorBgArrow: action.payload };
    case 'setColorArrow':
      return { ...state, colorArrow: action.payload };
    case 'setTemplateControls':
      return { ...state, templateControls: action.payload };
    case 'setColorFontCaroussel':
      return { ...state, colorFontCaroussel: action.payload };
    case 'setBackgroundCard':
      return { ...state, backgroundCard: action.payload };
    case 'setTemplateWebview':
      return { ...state, templateWebview: action.payload };
    case 'setTemplateAnimation':
      return { ...state, templateAnimation: action.payload };
    case 'setColorAnimation':
      return { ...state, colorAnimation: action.payload };
    case 'setTemplateInput':
      return { ...state, templateInput: action.payload };
    case 'setBackgroundInput':
      return { ...state, backgroundInput: action.payload };
    case 'setColorInputText':
      return { ...state, colorInputText: action.payload };
    case 'setColorInputIcons':
      return { ...state, colorInputIcons: action.payload };

    case 'setIconMenu':
      return { ...state, iconMenu: action.payload };
    case 'setIconSendImage':
      return { ...state, iconSendImage: action.payload };
    case 'setSizeIconSend':
      return { ...state, sizeIconSend: action.payload };
    case 'setSizeIconSendImage':
      return { ...state, sizeIconSendImage: action.payload };
    case 'setSizeIconMenu':
      return { ...state, sizeIconMenu: action.payload };
    case 'setNoBg':
      return { ...state, noBg: action.payload };
    case 'setTextInput':
      return { ...state, textInput: action.payload };
    case 'setMenuTemplate':
      return { ...state, menuTemplate: action.payload };
    case 'setBackgroundMenu':
      return { ...state, backgroundMenu: action.payload };
    case 'setColorMenu':
      return { ...state, colorMenu: action.payload };
    case 'setMenuOpen':
      return { ...state, menuOpen: action.payload };
    case 'setColorBubble':
      return { ...state, colorBubble: action.payload };
    case 'setColorFontBubble':
      return { ...state, colorFontBubble: action.payload };
    case 'setRightMobile':
      return { ...state, rightMobile: action.payload };
    case 'setBottomMobile':
      return { ...state, bottomMobile: action.payload };
    case 'setUserIcon':
      return { ...state, userIcon: action.payload };
    case 'setBotIcon':
      return { ...state, botIcon: action.payload };
    case 'setTemplate':
      return { ...state, template: action.payload };
    case 'setTemplateWindow':
      return { ...state, templateWindow: action.payload };
    case 'setSendImage':
      return { ...state, sendImage: action.payload };
    case 'setColorText':
      return { ...state, colorText: action.payload };
    case 'setWidgetHover':
      return { ...state, widgetHover: action.payload };
    case 'setFullpage':
      return { ...state, fullpage: action.payload };
    case 'setBoxShadowWebchat':
      return { ...state, boxShadowWebchat: action.payload };
    case 'setBackgroundSize':
      return { ...state, backgroundSize: action.payload };
    case 'setBackgroundPosition':
      return { ...state, backgroundPosition: action.payload };
    case 'setFontSizeQr':
      return { ...state, fontSizeQr: action.payload };
    case 'setFontSizeBoard':
      return { ...state, fontSizeBoard: action.payload };
    case 'setColorBubbleFontUser':
      return { ...state, colorBubbleFontUser: action.payload };
    case 'setColorBubbleFontBot':
      return { ...state, colorBubbleFontBot: action.payload };
    case 'setModulesScenario':
      return { ...state, modulesScenario: action.payload };
    case 'setMenuItems':
      return { ...state, menuItems: action.payload };
    case 'setFontSizeMessage':
      return { ...state, fontSizeMessage: action.payload };
    case 'setSendButton':
      return { ...state, sendButton: action.payload };
    case 'setUrlFont':
      return { ...state, urlFont: action.payload };
    case 'setFormatFont':
      return { ...state, formatFont: action.payload };
    case 'setFontSizeTitleCaroussel':
      return { ...state, fontSizeTitleCaroussel: action.payload };
    case 'setFontSizeSubtitleCaroussel':
      return { ...state, fontSizeSubtitleCaroussel: action.payload };
    case 'setFontSizeButtons':
      return { ...state, fontSizeButtons: action.payload };
    case 'setPaddingButtons':
      return { ...state, paddingButtons: action.payload };
    case 'setHeightQr':
      return { ...state, heightQr: action.payload };
    case 'setWidthQr':
      return { ...state, widthQr: action.payload };
    case 'setQrBorderColor':
      return { ...state, borderColorQr: action.payload };
    case 'setWording':
      return { ...state, wording: action.payload };
    case 'setOpts':
      return { ...state, opts: action.payload };
    case 'setSkin':
      return { ...state, skin: action.payload };
    case 'setOldBackground':
      return { ...state, oldBackground: action.payload };
    case 'setLayoutCenter':
      return { ...state, layoutCenter: action.payload };
    case 'setNoBackgroundMessage':
      return { ...state, noBackgroundMessage: action.payload };
    case 'setHideAvatarFullpage':
      return { ...state, hideAvatarFullpage: action.payload };
    case 'setColorMessageUserFullpage':
      return { ...state, colorMessageUserFullpage: action.payload };
    case 'setColorMessageBotFullpage':
      return { ...state, colorMessageBotFullpage: action.payload };
    case 'setHideHeaderFullpage':
      return { ...state, hideHeaderFullpage: action.payload };
    case 'setHomeActive':
      return { ...state, homeActive: action.payload };
    case 'setHomeTitle':
      return { ...state, homeTitle: action.payload };
    case 'setHomeImage':
      return { ...state, homeImage: action.payload };
    case 'setHomeContent':
      return { ...state, homeContent: action.payload };
    case 'setHomeButtons':
      return { ...state, homeButtons: action.payload };
    case 'setHomeTitleFontSize':
      return { ...state, homeTitleFontSize: action.payload };
    case 'setHomeTitleColor':
      return { ...state, homeTitleColor: action.payload };
    case 'setHomeContentBg':
      return { ...state, homeContentBg: action.payload };
    case 'setHomeContentFontSize':
      return { ...state, homeContentFontSize: action.payload };
    case 'setHomeContentColor':
      return { ...state, homeContentColor: action.payload };
    case 'setHomeButtonsColor':
      return { ...state, homeButtonsColor: action.payload };
    case 'setHomeButtonsWidth':
      return { ...state, homeButtonsWidth: action.payload };
    case 'setHomeButtonsHeight':
      return { ...state, homeButtonsHeight: action.payload };
    case 'setHomeButtonsBorder':
      return { ...state, homeButtonsBorder: action.payload };
    case 'setHomeButtonsBackground':
      return { ...state, homeButtonsBackground: action.payload };
    case 'setWebviewMailColor':
      return { ...state, webviewMailColor: action.payload };
    default:
      return state;
  }
};
