import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Frame, Field, Input } from '@ask-mona/ui';
import { _sendFeedback } from '../_tools/ui';
import { _checkPassword } from '../_tools/other';
import { withGlobals } from '../hoc/globals';
import logo from '../_assets/logoWhite.png';
import '../_styles/css/main.css';

import { createUser } from "../api/graphql/user.api";

class ValidateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      form: {
        firstname: '',
        lastname: '',
        password: '',
        passwordCheck: '',
        token: this.props.match.params.token,
      },
      error: {
        firstname: '',
        lastname: '',
        password: '',
        passwordCheck: '',
      },
    };
  }

  componentDidMount() {
    this.setLanguageCode();
  }

// NOTE: should this function be placed at the Routes component and called on the didMount ?
  setLanguageCode = () => {
    const {
      location: { search },
      g: { fct: { switchLang },
    }} = this.props;

    const languageCode = new URLSearchParams(search).get("language_code") || "en";
    switchLang(languageCode);
  }

  submit = async () => {
    const { form } = this.state;
    const { w, user } = this.props.g;

    if (!form.password || !_checkPassword(form.password)) {
      this.setState((state) => ({
        error: {
          password: w.error.passwordRequirement,
        },
      }));
      return;
    }

    if (form.password !== form.passwordCheck) {
      this.setState((state) => ({
        error: {
          password: w.error.passwordDoesNotMatch,
          passwordCheck: w.error.passwordDoesNotMatch,
        },
      }));
      return;
    }

    if (!form.firstname) {
      this.setState((state) => ({ error: { firstname: w.form.missingInput } }));
      return;
    }

    if (!form.lastname) {
      this.setState((state) => ({ error: { firstname: w.form.missingInput } }));
      return;
    }

    this.setState({ loading: true });
    _sendFeedback('warning', 'loader');
    try {
      const { token } = await createUser(form);
      _sendFeedback('success', w.success.saved);
      localStorage.setItem('token', token);
      this.props.history.push('/signin');
    } catch (error) {
      this.setState((state) => ({ error: { lastname: w.form.validateError } }));
      _sendFeedback('danger', w.error.general);
      this.setState({ loading: false });
    }
  };

  onChange = ({ target: { name, value } }) => {
    this.setState((state) => ({
      form: { ...state.form, [name]: value },
      error: { ...state.error, [name]: '' },
    }));
  };

  render() {
    const { w } = this.props.g;
    const {
      form: { firstname, lastname, password, passwordCheck },
      loading,
      error,
    } = this.state;
    return (
      <div className="has-content-centered">
        <div className={`p-md w-4`}>
          <Frame background="transparent">
            <img
              src={logo}
              alt="AskMona-Logo"
              onLoad={() => this.setState({ loading: false })}
            />
          </Frame>
          <div className="has-background-white flex column p-md">
            <div>
              <Field
                title={w.form.firstname}
                subtitle={w.form.firstnameSub}
                error={error.firstname}
                className="m-b-md"
              >
                <Input
                  name="firstname"
                  value={firstname}
                  onChange={this.onChange}
                />
              </Field>
              <Field
                title={w.form.lastname}
                subtitle={w.form.lastnameSub}
                error={error.lastname}
                className="m-b-md"
              >
                <Input
                  name="lastname"
                  value={lastname}
                  onChange={this.onChange}
                />
              </Field>
              <Field
                title={w.form.password}
                subtitle={w.form.passwordSub}
                error={error.password}
                className="m-b-md"
              >
                <Input
                  name="password"
                  type="password"
                  value={password}
                  onChange={this.onChange}
                />
              </Field>
              <Field
                title={w.form.passwordCheck}
                subtitle={w.form.passwordCheckSub}
                error={error.passwordCheck}
                className="m-b-md"
              >
                <Input
                  name="passwordCheck"
                  type="password"
                  value={passwordCheck}
                  onChange={this.onChange}
                />
              </Field>
            </div>
            <div className="flex row space-between wrap">
              <Link to="/">
                <Button className="m-xs is-uppercase">
                  {w.generic.return}
                </Button>
              </Link>
              <Button
                className="m-xs is-uppercase"
                loading={loading}
                onClick={this.submit}
              >
                {w.generic.validate}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withGlobals(ValidateUser);
