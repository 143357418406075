import React, { FC, memo, useContext, useState } from "react";
import styled from "styled-components";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
import { QAContext } from "../context/QAContext";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Cross } from "@styled-icons/entypo/Cross";
import { Search } from "@styled-icons/bootstrap/Search";
import BaseModal from "../../../components/BaseModal";
import CreateTopicCategory from "../components/modals/CreateTopicCategory";
import { Api } from "../../../api/graphql/hooks/useTopics";

interface SidebarProps {
  w: any;
  api: Api;
}

interface TitleProps {
  readonly isActive: boolean;
}

interface CategorieProps {
}

const Sidebar = memo(({ w, api }: SidebarProps) => {
  const { state, dispatch } = useContext(QAContext);
  const [value, setValue] = useState<string>("");
  const [isOpenModalCreateCategory, setIsOpenModalCreateCategory] =
    useState<boolean>(false);
  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>(false);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    bottom ? setScrolledToBottom(true) : setScrolledToBottom(false);
  };

  const toggleIsOpenModalCreateCategory = () => {
    setIsOpenModalCreateCategory(!isOpenModalCreateCategory);
  };

  return (
    <>
      <SidebarContainer>
        <Tab>
          <TabTitle>{w.searchQa}</TabTitle>
          <WrapperInput>
            <WrapperInputSearch
              onClick={(e) =>
                dispatch({ type: "SET_SEARCH_VALUE", payload: value })
              }
            >
              <Search width="20" />
            </WrapperInputSearch>
            <StyledInput
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={w.search}
              onKeyPress={(e) =>
                e.key === "Enter" &&
                dispatch({ type: "SET_SEARCH_VALUE", payload: value })
              }
            />
            {!!state.searchValue.length && (
              <ButtonReset
                onClick={(e) => {
                  dispatch({ type: "SET_SEARCH_VALUE", payload: "" });
                  setValue("");
                }}
              >
                <Cross width="20" />
              </ButtonReset>
            )}
          </WrapperInput>
        </Tab>
      </SidebarContainer>
      <SidebarCategorieContainer>
        <Tab>
          <TabTitle>{w.categoryOf}</TabTitle>
        </Tab>
        <CategorieContainer
          onScroll={handleScroll}
        >
          {!state.searchValue.length &&
            state.categories.map((el) => (
              <CategorieTitle
                key={el.id}
                isActive={el.id === state.currentCategoryId}
                onClick={() =>
                  dispatch({ type: "SET_CURRENT_CATEGORY_ID", payload: el.id })
                }
              >
                {el.name} ({el.enabled_topics_count}/
                {el.topics_count - el.enabled_topics_count})
              </CategorieTitle>
            ))}
        </CategorieContainer>
        <WrapperIconBottom>
          {scrolledToBottom ? (
            <i className="fas fa-chevron-up"></i>
          ) : (
            <i className="fas fa-chevron-down"></i>
          )}
        </WrapperIconBottom>
        <ButtonAddContainer onClick={toggleIsOpenModalCreateCategory}>
          <ButtonPlusSymbol>
            <AddBoxRoundedIcon color="primary" />
          </ButtonPlusSymbol>
          <ButtonText>{w.addCategory}</ButtonText>
        </ButtonAddContainer>
      </SidebarCategorieContainer>
      {isOpenModalCreateCategory && (
        <BaseModal isOpen={isOpenModalCreateCategory} selector="#root">
          <CreateTopicCategory
            close={toggleIsOpenModalCreateCategory}
            w={w}
            api={api}
          ></CreateTopicCategory>
        </BaseModal>
      )}
    </>
  );
});

const SidebarContainer = styled.div`
  display: flex;
  padding: 0 15px;
  width: 245px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    color: #2d2f7b;
  }

  @media (max-width: 1400px) {
    padding: 0 10px;
    width: 240px;
  }
  @media (max-width: 1200px) {
    padding: 0 10px;
    width: 200px;
  }
  @media (max-width: 1000px) {
    padding: 0 10px;
    width: 170px;
  }
`;

const SidebarCategorieContainer = styled(SidebarContainer)`
  overflow: hidden;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px auto;
  color: #2d2f7b;
  font-weight: 510;
  width: inherit;
`;

const TabTitle = styled.div`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  margin-bottom: 1rem;
`;

const CategorieContainer = styled.div<CategorieProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  overflow: scroll;
`;

const CategorieTitle = styled.span<TitleProps>`
  padding: 1rem;
  color: ${({ isActive }) => (isActive ? "#e66e6e" : "#2d2f7b")};
  text-decoration: ${({ isActive }) => (isActive ? "underline" : "none")};
`;

const StyledInput = styled.input`
  font-size: 16px;
  font-weight: 500;
  color: rgb(45, 47, 123);
  background-color: white;
  padding: 0.7rem 2.25rem 0.7rem 2rem;
  border-radius: 16px;
  border: 1px solid #2d2f7b3d;
  box-shadow: rgb(236 236 236) 0px 2px 5px 0px;
  overflow: auto;
`;

const WrapperIconBottom = styled.div`
  width: 100%;
  text-align: center;
  > i {
    color: #2d2f7b;
    width: 20px;
    height: 20px;
  }
`;

const WrapperInput = styled.div`
  display: flex;
  position: relative;
  width: inherit;
`;

const WrapperInputSearch = styled.div`
  position: absolute;
  display: flex;
  background: #2d307b;
  color: #fff;
  outline: none;
  border: none;
  z-index: 1;
  right: 0%;
  cursor: pointer;
  height: 100%;
  display: flex;
  width: 50px;
  justify-content: center;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const ButtonReset = styled.div`
  position: absolute;
  outline: none;
  background: transparent;
  border: none;
  z-index: 1;
  right: 52px;
  cursor: pointer;
  height: 100%;
  display: flex;
`;

const ButtonAddContainer = styled.div`
  background-color: white;
  padding: 8px 20px 8px 13px;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  border: 1px solid #2d2f7b;
  margin: 10px auto 20px;
  cursor: pointer;
`;

const ButtonPlusSymbol = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled.div`
  color: #2d2f7b;
  font-size: 0.9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export default Sidebar;
