import React, { useState } from 'react';
import styled from 'styled-components';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import StyledBigTitle from '../../../../components/parameters/StyledTitle';
import Generic from '../../../../components/parameters/Generic';
import StyledLabel from '../../../../components/parameters/StyledLabel';
import InputRadio from '../../../../components/parameters/InputRadio';
import { ChromePicker } from 'react-color';
import { RightArrow } from '@styled-icons/boxicons-solid/RightArrow';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';
import Webchat from '../../../../components/parameters/Webchat';
import carousselTemplate from './templates/Caroussels';
import controlsTemplate from './templates/Controls';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WrapperDiv = styled.div`
  display: flex;
  align-items: ${({ alignItem }) => alignItem};
  margin: ${({ mr }) => (mr ? mr : '2rem 0')};
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
`;

const WrapperGeneric = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const StyledDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: ${({ mr }) => mr};
  width: ${({ width }) => width};
  justify-content: ${({ justifyContent }) => justifyContent};
  margin: ${({ margin }) => margin};
`;

const SubtitleLight = styled.h3`
  font-size: 1rem;
  margin-right: 2rem;
  font-weight: 400;
  margin-bottom: ${({ mb }) => mb};
`;

const Caroussels = ({ wWebchat, state, dispatch }) => {
  const [hideFirstContent, setHideFirstContent] = useState(true);
  const [hideSecondContent, setHideSecondContent] = useState(true);
  const { Handle } = Slider;

  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} %`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };
  return (
    <Wrapper>
      <StyledBigTitle>
        {wWebchat.templateChoice}
        <span onClick={() => setHideFirstContent(!hideFirstContent)}>
          {hideFirstContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideFirstContent && (
        <>
          <WrapperDiv mr={'2rem 0 0 0'}>
            <WrapperDiv width={'100%'} mr={'0 2rem'}>
              <SubtitleLight>{wWebchat.carousselPosition}</SubtitleLight>
            </WrapperDiv>
            <WrapperDiv justifyContent={'space-around'}>
              <WrapperGeneric>
                <Generic
                  widthBtn={'80%'}
                  card
                  bg={'#2F2F7B'}
                  height={'350px'}
                />
                <StyledLabel radio flex mt={'1rem'}>
                  <InputRadio
                    type="radio"
                    name="caroussel"
                    value="template1"
                    checked={
                      JSON.stringify(state.templateCaroussels) ===
                      JSON.stringify(carousselTemplate('template1'))
                    }
                    onChange={(e) => {
                      dispatch({
                        type: 'setTemplateCaroussels',
                        payload: carousselTemplate(e.target.value),
                      });
                    }}
                  />
                </StyledLabel>
              </WrapperGeneric>
              <WrapperGeneric>
                <Generic
                  widthBtn={'60%'}
                  card
                  bg={'#2F2F7B'}
                  height={'350px'}
                />
                <StyledLabel radio flex mt={'1rem'}>
                  <InputRadio
                    type="radio"
                    name="caroussel"
                    value="template2"
                    checked={
                      JSON.stringify(state.templateCaroussels) ===
                      JSON.stringify(carousselTemplate('template2'))
                    }
                    onChange={(e) => {
                      dispatch({
                        type: 'setTemplateCaroussels',
                        payload: carousselTemplate(e.target.value),
                      });
                    }}
                  />
                </StyledLabel>
              </WrapperGeneric>
              <WrapperGeneric>
                <Generic
                  widthBtn={'80%'}
                  card
                  imgCover
                  bg={'#2F2F7B'}
                  height={'350px'}
                />
                <StyledLabel radio flex mt={'1rem'}>
                  <InputRadio
                    type="radio"
                    name="caroussel"
                    value="template3"
                    checked={
                      JSON.stringify(state.templateCaroussels) ===
                      JSON.stringify(carousselTemplate('template3'))
                    }
                    onChange={(e) => {
                      dispatch({
                        type: 'setTemplateCaroussels',
                        payload: carousselTemplate(e.target.value),
                      });
                    }}
                  />
                </StyledLabel>
              </WrapperGeneric>
              <WrapperGeneric>
                <Generic
                  widthBtn={'100%'}
                  card
                  color={'#d3d3d3'}
                  borderFirstChild={'#d3d3d3'}
                  icon
                  height={'350px'}
                />
                <StyledLabel radio flex mt={'1rem'}>
                  <InputRadio
                    type="radio"
                    name="caroussel"
                    value="template6"
                    checked={
                      JSON.stringify(state.templateCaroussels) ===
                      JSON.stringify(carousselTemplate('template6'))
                    }
                    onChange={(e) => {
                      dispatch({
                        type: 'setTemplateCaroussels',
                        payload: carousselTemplate(e.target.value),
                      });
                    }}
                  />
                </StyledLabel>
              </WrapperGeneric>
              <WrapperGeneric>
                <Generic
                  widthBtn={'100%'}
                  card
                  color={'#d3d3d3'}
                  borderTop={'#d3d3d3'}
                  icon
                  height={'350px'}
                />
                <StyledLabel radio flex mt={'1rem'}>
                  <InputRadio
                    type="radio"
                    name="caroussel"
                    value="template7"
                    checked={
                      JSON.stringify(state.templateCaroussels) ===
                      JSON.stringify(carousselTemplate('template7'))
                    }
                    onChange={(e) => {
                      dispatch({
                        type: 'setTemplateCaroussels',
                        payload: carousselTemplate(e.target.value),
                      });
                    }}
                  />
                </StyledLabel>
              </WrapperGeneric>
              <WrapperGeneric>
                <Generic
                  widthBtn={'100%'}
                  card
                  imgCover
                  color={'#d3d3d3'}
                  borderTop={'#d3d3d3'}
                  icon
                  height={'350px'}
                />
                <StyledLabel radio flex mt={'1rem'}>
                  <InputRadio
                    type="radio"
                    name="caroussel"
                    value="template8"
                    checked={
                      JSON.stringify(state.templateCaroussels) ===
                      JSON.stringify(carousselTemplate('template8'))
                    }
                    onChange={(e) => {
                      dispatch({
                        type: 'setTemplateCaroussels',
                        payload: carousselTemplate(e.target.value),
                      });
                    }}
                  />
                </StyledLabel>
              </WrapperGeneric>
              <WrapperDiv width={'100%'} mr={'0 2rem'}>
                <SubtitleLight>{wWebchat.pagination}</SubtitleLight>
              </WrapperDiv>
              <WrapperDiv>
                <WrapperGeneric>
                  <Webchat generic pagination />
                  <StyledLabel radio flex mt={'1rem'}>
                    <InputRadio
                      type="radio"
                      name="controls"
                      value="template1"
                      checked={
                        JSON.stringify(state.templateControls) ===
                        JSON.stringify(controlsTemplate('template1'))
                      }
                      onChange={(e) => {
                        dispatch({
                          type: 'setTemplateControls',
                          payload: controlsTemplate(e.target.value),
                        });
                      }}
                    />
                  </StyledLabel>
                </WrapperGeneric>
              </WrapperDiv>
              <WrapperDiv>
                <WrapperGeneric>
                  <Webchat generic pagination pagSquare />
                  <StyledLabel radio flex mt={'1rem'}>
                    <InputRadio
                      type="radio"
                      name="controls"
                      value="template2"
                      checked={
                        JSON.stringify(state.templateControls) ===
                        JSON.stringify(controlsTemplate('template2'))
                      }
                      onChange={(e) => {
                        dispatch({
                          type: 'setTemplateControls',
                          payload: controlsTemplate(e.target.value),
                        });
                      }}
                    />
                  </StyledLabel>
                </WrapperGeneric>
              </WrapperDiv>
              <WrapperDiv>
                <WrapperGeneric>
                  <Webchat generic pagination pagNumber />
                  <StyledLabel radio flex mt={'1rem'} noMargin>
                    <InputRadio
                      type="radio"
                      name="controls"
                      value="template3"
                      checked={
                        JSON.stringify(state.templateControls) ===
                        JSON.stringify(controlsTemplate('template3'))
                      }
                      onChange={(e) => {
                        dispatch({
                          type: 'setTemplateControls',
                          payload: controlsTemplate(e.target.value),
                        });
                      }}
                    />
                  </StyledLabel>
                </WrapperGeneric>
              </WrapperDiv>
            </WrapperDiv>
          </WrapperDiv>
          <WrapperDiv justifyContent={'space-around'}>
            <StyledDivWrapper width={'45%'} justifyContent={'space-between'}>
              <SubtitleLight mb={'1rem'}>
                {wWebchat.paginationColor}
              </SubtitleLight>
              <ChromePicker
                color={state.colorPagination}
                onChangeComplete={(val) => {
                  dispatch({ type: 'setColorPagination', payload: val.hex });

                  JSON.stringify(state.templateControls) !==
                    JSON.stringify(controlsTemplate('template1')) &&
                    dispatch({ type: 'setColorBgArrow', payload: val.hex });

                  JSON.stringify(state.templateControls) ===
                    JSON.stringify(controlsTemplate('template1')) &&
                    dispatch({ type: 'setColorArrow', payload: val.hex });
                }}
              />
            </StyledDivWrapper>
            {JSON.stringify(state.templateControls) !==
              JSON.stringify(controlsTemplate('template1')) && (
              <StyledDivWrapper width={'45%'} justifyContent={'space-between'}>
                <SubtitleLight mb={'1rem'}>{wWebchat.carousel_arrow_colour}</SubtitleLight>
                <ChromePicker
                  color={state.colorArrow}
                  onChangeComplete={(val) => {
                    dispatch({ type: 'setColorArrow', payload: val.hex });
                  }}
                />
              </StyledDivWrapper>
            )}
          </WrapperDiv>
        </>
      )}
      <StyledBigTitle>
        {wWebchat.templateConfig}
        <span onClick={() => setHideSecondContent(!hideSecondContent)}>
          {hideSecondContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideSecondContent && (
        <>
          <WrapperDiv justifyContent={'center'}>
            <WrapperDiv justifyContent={'space-between'}>
              <SubtitleLight>{wWebchat.borderCaroussel}</SubtitleLight>
              <Slider
                trackStyle={{ backgroundColor: 'red', height: '10px' }}
                railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                handleStyle={{
                  width: '20px',
                  height: '20px',
                  border: '1px solid lightgray',
                  boxShadow:
                    'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                }}
                min={0}
                max={25}
                handle={handle}
                defaultValue={state.radiusCardCaroussel}
                value={state.radiusCardCaroussel}
                onChange={(value) => {
                  dispatch({ type: 'setRadiusCardCaroussel', payload: value });
                }}
              />
            </WrapperDiv>
            <WrapperDiv justifyContent={'space-between'}>
              <StyledDivWrapper
                width={'45%'}
                justifyContent={'space-between'}
                margin={'1rem 0'}
              >
                <SubtitleLight>{wWebchat.carousel_buttons_height}</SubtitleLight>
                <Slider
                  trackStyle={{ backgroundColor: 'red', height: '10px' }}
                  railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                  handleStyle={{
                    width: '20px',
                    height: '20px',
                    border: '1px solid lightgray',
                    boxShadow:
                      'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                  }}
                  min={0.2}
                  max={0.8}
                  step={0.1}
                  handle={handle}
                  defaultValue={state.paddingButtons}
                  value={state.paddingButtons}
                  onChange={(value) => {
                    dispatch({ type: 'setPaddingButtons', payload: value });
                  }}
                />
              </StyledDivWrapper>
              <StyledDivWrapper
                width={'45%'}
                justifyContent={'space-between'}
                margin={'1rem 0'}
              >
                <SubtitleLight>{wWebchat.carousel_buttons_font_size}</SubtitleLight>
                <Slider
                  trackStyle={{ backgroundColor: 'red', height: '10px' }}
                  railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                  handleStyle={{
                    width: '20px',
                    height: '20px',
                    border: '1px solid lightgray',
                    boxShadow:
                      'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                  }}
                  min={0.8}
                  max={1.2}
                  step={0.1}
                  handle={handle}
                  value={state.fontSizeButtons?.slice(0, -2)}
                  onChange={(value) => {
                    dispatch({
                      type: 'setFontSizeButtons',
                      payload: `${value}em`,
                    });
                  }}
                />
              </StyledDivWrapper>
            </WrapperDiv>
            <WrapperDiv justifyContent={'space-between'}>
              <StyledDivWrapper
                width={'45%'}
                justifyContent={'space-between'}
                margin={'1rem 0'}
              >
                <SubtitleLight>{wWebchat.carousel_titles_font_size}</SubtitleLight>
                <Slider
                  trackStyle={{ backgroundColor: 'red', height: '10px' }}
                  railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                  handleStyle={{
                    width: '20px',
                    height: '20px',
                    border: '1px solid lightgray',
                    boxShadow:
                      'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                  }}
                  min={0.8}
                  max={1.2}
                  step={0.1}
                  handle={handle}
                  defaultValue={state.fontSizeTitleCaroussel}
                  value={state.fontSizeTitleCaroussel}
                  onChange={(value) => {
                    dispatch({
                      type: 'setFontSizeTitleCaroussel',
                      payload: value,
                    });
                  }}
                />
              </StyledDivWrapper>
              <StyledDivWrapper
                width={'45%'}
                justifyContent={'space-between'}
                margin={'1rem 0'}
              >
                <SubtitleLight>
                  {wWebchat.carousel_subtitles_font_size}
                </SubtitleLight>
                <Slider
                  trackStyle={{ backgroundColor: 'red', height: '10px' }}
                  railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
                  handleStyle={{
                    width: '20px',
                    height: '20px',
                    border: '1px solid lightgray',
                    boxShadow:
                      'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2p',
                  }}
                  min={0.6}
                  max={1}
                  step={0.1}
                  handle={handle}
                  defaultValue={state.fontSizeSubtitleCaroussel}
                  value={state.fontSizeSubtitleCaroussel}
                  onChange={(value) => {
                    dispatch({
                      type: 'setFontSizeSubtitleCaroussel',
                      payload: value,
                    });
                  }}
                />
              </StyledDivWrapper>
            </WrapperDiv>
            <WrapperDiv justifyContent={'space-between'}>
              <StyledDivWrapper
                width={'45%'}
                justifyContent={'space-between'}
                margin={'1rem 0'}
              >
                <SubtitleLight mb={'1rem'}>{wWebchat.bgCard}</SubtitleLight>
                <ChromePicker
                  color={state.backgroundCard}
                  onChangeComplete={(val) => {
                    dispatch({ type: 'setBackgroundCard', payload: val.hex });
                  }}
                />
              </StyledDivWrapper>
              <StyledDivWrapper
                width={'45%'}
                justifyContent={'space-between'}
                margin={'1rem 0'}
              >
                <SubtitleLight mb={'1rem'}>{wWebchat.bgBtn}</SubtitleLight>
                <ChromePicker
                  color={state.backgroundButtonCaroussel}
                  onChangeComplete={(val) => {
                    dispatch({
                      type: 'setBackgroundButtonCaroussel',
                      payload: val.hex,
                    });
                  }}
                />
              </StyledDivWrapper>
              <StyledDivWrapper
                width={'45%'}
                justifyContent={'space-between'}
                margin={'1rem 0'}
              >
                <SubtitleLight mb={'1rem'}>
                  {wWebchat.borderColor}
                </SubtitleLight>
                <ChromePicker
                  color={state.borderColorButton}
                  onChangeComplete={(val) => {
                    dispatch({
                      type: 'setBorderColorButton',
                      payload: val.hex,
                    });
                  }}
                />
              </StyledDivWrapper>
              <StyledDivWrapper
                width={'45%'}
                justifyContent={'space-between'}
                margin={'1rem 0'}
              >
                <SubtitleLight mb={'1rem'}>
                  {wWebchat.colorTitleSub}
                </SubtitleLight>
                <ChromePicker
                  color={state.colorFontCaroussel}
                  onChangeComplete={(val) => {
                    dispatch({
                      type: 'setColorFontCaroussel',
                      payload: val.hex,
                    });
                  }}
                />
              </StyledDivWrapper>
              <StyledDivWrapper width={'45%'} justifyContent={'space-between'}>
                <SubtitleLight mb={'1rem'}>{wWebchat.btnColor}</SubtitleLight>
                <ChromePicker
                  color={state.colorBtnCard}
                  onChangeComplete={(val) => {
                    dispatch({ type: 'setColorBtnCard', payload: val.hex });
                  }}
                />
              </StyledDivWrapper>
            </WrapperDiv>
          </WrapperDiv>
        </>
      )}
    </Wrapper>
  );
};

export default Caroussels;
