import React, { useState } from 'react';
import { _slugify } from '../../_tools/other';
import { _sendFeedback } from '../../_tools/ui';
import Modal from '../../components/Modal';
import { moduleTemplate, _findDup, _generatePath } from './Reducer';
import { Switch, Button, Icon, Field, Select, Input } from '@ask-mona/ui';

export default (props) => {
  const {
    m: {
      w,
      user: { organization },
    },
    modules,
    allIds,
    Bot,
    focus,
    dispatch,
    advanced,
  } = props;
  const [add, setAdd] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState(0);
  const [daliArray, setDaliArray] = useState([]);
  return (
    <div
      className="inline-block overflow-auto border-r-primary-lighter w-25p align-top space-initial"
      style={{ height: 'inherit' }}
    >
      {advanced && (
        <div className="flex sticky top row center p-md b-t-xs has-background-primary-light">
          {w.scenario.colScenario}
        </div>
      )}
      {modules.map((m) => (
        <Module key={m.id} {...props} mod={m} />
      ))}
      {
        //ADVANCED PART
        advanced && !add && organization.id === 1 && (
          <div className="flex row center p-t-xs">
            <Button onClick={() => setAdd(true)}>
              {w.scenario.addScenario}
            </Button>
          </div>
        )
      }
      {
        //ADVANCED PART
        advanced && add && (
          <div className="flex column center p-xs">
            <Field subtitle={w.scenario.name}>
              <Input onChange={(e) => setName(e.target.value)} />
            </Field>
            <Field subtitle={w.scenario.type}>
              <Select
                className="w-100p m-b-xs"
                defaultValue={w.scenario.privateScenario}
                onSelect={(item) => {
                  setDaliArray([]);
                  setType(item.key);
                }}
                items={[
                  { id: 0, value: w.scenario.privateScenario, key: 0 },
                  { id: 1, value: w.scenario.publicScenario, key: 1 },
                  { id: 2, value: w.scenario.matchScenario, key: 2 },
                  { id: 3, value: w.scenario.quizzScenario, key: 3 },
                ]}
              />
            </Field>
            <Button
              onClick={async () => {
                let id = _slugify(name) || 'emojisolo';
                id = _findDup(id, allIds);
                let module = moduleTemplate(
                  { id, name, jump: 'FIRST' },
                  type,
                  allIds,
                  daliArray
                );
                _sendFeedback('warning', 'loader');
                const { key, ...other } = module;
                let res = await Bot._createModule({
                  path: _generatePath(module),
                  module: { [key]: { ...other } },
                });
                if (res.success) {
                  if (!focus) dispatch({ type: 'setFocus', payload: module });
                  dispatch({
                    type: 'setModules',
                    payload: modules.concat(module),
                  });
                  dispatch({ type: 'setIds', payload: allIds.concat(id) });
                  _sendFeedback('success', w.success.saved);
                  setAdd(false);
                  setType(0);
                } else _sendFeedback('danger', w.error.general);
              }}
            >
              {w.generic.validate}
            </Button>
          </div>
        )
      }
    </div>
  );
};

const Module = (props) => {
  const {
    m: {
      w,
      user: { organization },
    },
    subModules,
    focus,
    Bot,
    dispatch,
    mod,
    modules,
    advanced,
  } = props;
  const [changeName, setChangeName] = useState(false);
  const [name, setName] = useState(mod.name);
  const [disable, setDisable] = useState(mod.disable);
  const [delt, setDelt] = useState(false);

  return (
    <div
      className={`flex row space-between items-center ${
        advanced ? 'p-xs' : 'p-md'
      } pointer border-b-primary-lighter ${
        mod.id === focus.id ? 'has-background-primary-ter has-text-white' : ''
      } ${
        mod.id.toLowerCase() === 'start_lang' && 'border-programmatic-unique'
      }`}
      onClick={() => dispatch({ type: 'setFocus', payload: mod })}
    >
      <Modal isOpen={delt} close={() => setDelt(false)} selector="#root">
        <div className="has-background-white p-md w-4 rad-5">
          {w.generic.scenarioDelete}
          <div className="p-t-md flex row space-between">
            <Button onClick={() => setDelt(false)} color="secondary">
              {w.generic.no}
            </Button>
            <Button
              color="success"
              onClick={async () => {
                _sendFeedback('warning', 'loader');
                const { key, id } = mod;
                let res = await Bot._deleteModule({
                  path: _generatePath(mod),
                });
                if (res.success) {
                  dispatch({
                    type: 'setModules',
                    payload: modules.filter((m) => m.key !== key),
                  });
                  _sendFeedback('success', w.success.deleted);
                } else _sendFeedback('danger', w.error.general);
                if (advanced) {
                  let reg = new RegExp(`^${id}/`);
                  let subs = subModules.filter((m) => reg.test(m.tag));
                  subs.forEach(async (s) => {
                    await Bot._deleteModule({
                      path: _generatePath(subs),
                    });
                  });
                }
              }}
            >
              {w.generic.yes}
            </Button>
          </div>
        </div>
      </Modal>
      {advanced && !changeName && (
        <Button
          color="danger"
          className="is-outlined is-borderless shrink"
          onClick={() => setDelt(true)}
        >
          <Icon icon="fas fa-trash" className="is-size-7" />
        </Button>
      )}
      {!changeName && (
        <span className="mw-2 m-r-xs has-text-weight-medium space-pre-line break-word">
          {' '}
          {mod.key === 'fallback' ? 'Autres' : name}{' '}
        </span>
      )}
      {changeName && (
        <Field>
          <Input
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Button
            className="shrink"
            onClick={async () => {
              if (name !== mod.name) {
                _sendFeedback('warning', 'loader');
                let res = await Bot._updateModule({
                  path: _generatePath(mod).concat('name'),
                  module: { name: name },
                });
                if (res.success) _sendFeedback('success', w.success.saved);
                else {
                  setName(mod.name);
                  _sendFeedback('danger', w.error.general);
                }
              }
              changeName && setChangeName(false);
            }}
          >
            <Icon icon="fas fa-check" className="is-size-7" />
          </Button>
        </Field>
      )}
      {mod.key !== 'first' && mod.key !== 'fallback' && !changeName && (
        <div className="flex row">
          {organization.id === 1 && (
            <React.Fragment>
              <Button
                color="danger"
                className="is-outlined is-borderless shrink"
                onClick={() => setChangeName(true)}
              >
                <Icon icon="fas fa-pen" className="is-size-7" />
              </Button>
              <Button
                color="danger"
                className="is-outlined is-borderless shrink"
                onClick={async () => {
                  _sendFeedback('warning', 'loader');
                  const { key, custom } = mod;
                  let res = await Bot._updateModule({
                    path: _generatePath(mod).concat('custom'),
                    module: { custom: !custom },
                  });
                  if (res.success) {
                    dispatch({
                      type: 'setModules',
                      payload: modules.filter((m) => m.key !== key),
                    });
                    _sendFeedback('success', w.success.saved);
                  } else _sendFeedback('danger', w.error.general);
                }}
              >
                <Icon icon="fas fa-share-square" className="is-size-7" />
              </Button>
            </React.Fragment>
          )}
          <Switch
            small="true"
            color="secondary"
            defaultChecked={!mod.disable}
            onChange={async () => {
              _sendFeedback('warning', 'loader');
              let res = await Bot._updateModule({
                path: _generatePath(mod).concat('disable'),
                module: { disable: !disable },
              });
              if (res.success) {
                setDisable(!disable);
                _sendFeedback('success', w.success.saved);
              } else _sendFeedback('danger', w.error.general);
            }}
          />
        </div>
      )}
    </div>
  );
};
