import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import ContainerScroll from '../../../../components/parameters/ContainerScroll';
import StyledBigTitle from '../../../../components/parameters/StyledTitle';
import CircleImg from '../../../../components/parameters/CircleImg';
import StyledImage from '../../../../components/parameters/StyledImage';
import Generic from '../../../../components/parameters/Generic';
import Pagination from '../../../../components/parameters/Pagination';
import { IconMenu } from './templates/IconMenu';
import userDefault from '../../../../_assets/user-default.png';
import PreviewMail from './PreviewMail';
import {
  BounceLoader,
  Loader,
} from '../../../../components/parameters/LoadAnimation';
import { Cross } from '@styled-icons/entypo/Cross';
import { EllipsisV } from '@styled-icons/fa-solid/EllipsisV';
import { PaperPlane } from '@styled-icons/boxicons-solid/PaperPlane';
import { Camera } from '@styled-icons/boxicons-regular/Camera';

const Preview = ({ state, currentPage, w, m }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const widget = state.template.widget;
  const headerActive = !state.hasHeader && currentPage !== 'webview';

  useEffect(() => {
    setMenuOpen(state.menuOpen);
  }, [state.menuOpen]);

  return (
    <ContainerScroll width={'40'} preview border>
      <StyledBigTitle textAlign={'center'}>{w.preview}</StyledBigTitle>
      {currentPage === 'bulle' ? (
        <WrapperScreen>
          <StyledDivWrapper noColumn>
            <StyledWrapper
              afterContent={widget?.catchphrase.afterOption}
              borderCorner={widget?.catchphrase.borderCorner}
              entryReduced={widget?.reduce}
              background={state.colorBubble}
              color={state.colorFontBubble}
              widget={widget?.opt1}
            >
              {widget?.opt1 && (
                <StyledImg src={state.widgetIcon} relativeImg noColumn />
              )}
              <div>
                <StyledTitle>
                  {state.title.fr || 'Renseigner un titre'}
                </StyledTitle>
                <p>{state.subtitleWidget.fr || 'Renseigner un sous-titre'}</p>
              </div>
              {!widget?.reduce && <StyledCross size={16} />}
            </StyledWrapper>
          </StyledDivWrapper>
          {!widget?.opt1 && (
            <StyledImg src={state.widgetIcon} relativeImg noColumn />
          )}
        </WrapperScreen>
      ) : (
        <WebchatContainer
          curved={state.headerCurved}
          colorHeader={state.colorHeader}
          borderRadius={state.borderRadius}
          headerSize={state.headerSize}
          boxShadow={state.boxShadowWebchat}
        >
          <GlobalStyle
            fontFamilly={state.fontSelected}
            formatFont={state.formatFont}
            fontUrl={state.urlFont}
          />
          {headerActive && (
            <WebchatHeader
              colorHeader={state.colorHeader}
              headerSize={state.headerSize}
              borderRadius={state.borderRadius}
              position={state.positionHeader}
            >
              <StyledHeader colorText={state.colorText}>
                {state.widgetIcon && state.displayLogo && (
                  <CircleImg width={state.sizeIcon} height={state.sizeIcon}>
                    <StyledImage
                      src={
                        state.avatarHeader || state.widgetIcon || userDefault
                      }
                      width={state.sizeIcon}
                      alt="Logo du chatbot"
                    />
                  </CircleImg>
                )}
                <StyledContent>
                  {state.headerWebchat && state.displayTitle && (
                    <StyledText
                      marginLeft={state.distanceIconText}
                      fontFamilly={state.fontSelected}
                      fontSize={state.sizeTitle}
                    >
                      {state.headerWebchat}
                    </StyledText>
                  )}
                  {state.subtitle && state.displaySubtitle && (
                    <StyledText
                      fontSize={state.sizeSubtitle}
                      marginLeft={state.distanceIconText}
                      fontFamilly={state.fontSelected}
                    >
                      {state.subtitle}
                    </StyledText>
                  )}
                  <CrossContainer>
                    {state.customIcon ? (
                      <CustomIcon
                        src={state.customIcon}
                        alt="Logo du bouton pour envoyer un message"
                        size={state.sizeIconSend}
                      />
                    ) : (
                      <Cross size={16} />
                    )}
                  </CrossContainer>
                </StyledContent>
              </StyledHeader>
            </WebchatHeader>
          )}
          <WebchatBody
            background={state.backgroundColorBody}
            curved={state.headerCurved}
            imageBg={state.imageBody}
            webview={currentPage === 'webview'}
          >
            {currentPage === 'qr' && (
              <StyledQr
                background={state.colorQr}
                color={state.colorFontQr}
                template={state.qrTemplate}
                borderRadius={state.borderQr}
                fontSize={state.fontSizeQr}
                fontFamilly={state.fontSelected}
                height={state.heightQr}
                width={state.widthQr}
                borderColor={state.borderColorQr}
              >
                Exemple
              </StyledQr>
            )}
            {currentPage === 'bulles-messages' && (
              <WrapperMessages>
                <ContentMessages position={state.iconPosition}>
                  {!state.iconMessageBot && (
                    <CircleImg width={'30'} height={'30'}>
                      <StyledImage
                        src={state.botIcon || state.widgetIcon || userDefault}
                        width={'30px'}
                        alt="Logo du chatbot"
                      />
                    </CircleImg>
                  )}
                  <StyledMessage
                    borderCornerTop={state.templateMessage.borderRadiusTop}
                    borderCornerBottom={
                      state.templateMessage.borderRadiusBottom
                    }
                    borderRadius={state.templateMessage.borderRadius}
                    boxShadow={state.templateMessage.boxShadow}
                    oldVersion={!state.templateMessage.v2}
                    background={state.colorBubbleBot}
                    color={state.colorBubbleFontBot}
                    fontSize={state.fontSizeMessage}
                  >
                    <StyledTextMessage fontFamilly={state.fontSelected}>
                      Message du chatbot
                    </StyledTextMessage>
                  </StyledMessage>
                </ContentMessages>
                <ContentMessages user position={state.iconPosition}>
                  {!state.iconMessageUser && (
                    <CircleImg width={'30'} height={'30'}>
                      <StyledImage
                        src={state.userIcon || userDefault}
                        width={'30px'}
                        alt="Logo de l'utilisateur"
                      />
                    </CircleImg>
                  )}
                  <StyledMessage
                    isUser
                    borderCornerTop={state.templateMessage.borderRadiusTop}
                    borderCornerBottom={
                      state.templateMessage.borderRadiusBottom
                    }
                    borderRadius={state.templateMessage.borderRadius}
                    boxShadow={state.templateMessage.boxShadow}
                    background={state.colorBubbleUser}
                    color={state.colorBubbleFontUser}
                    oldVersion={!state.templateMessage.v2}
                    fontSize={state.fontSizeMessage}
                  >
                    <StyledTextMessage fontFamilly={state.fontSelected}>
                      Message de l'utilisateur
                    </StyledTextMessage>
                  </StyledMessage>
                </ContentMessages>
                {state.templateAnimation.v2 ? (
                  <ContentMessages margin={'.6rem 0 .8rem .4rem'}>
                    <BounceLoader color={state.colorAnimation} />
                  </ContentMessages>
                ) : (
                  <ContentMessages margin={'.6rem 0 .8rem .4rem'}>
                    <Loader />
                  </ContentMessages>
                )}
              </WrapperMessages>
            )}
            {currentPage === 'webview' && (
              <StyledWebview
                borderRadius={`${state.templateWebview?.borderRadius} ${state.templateWebview?.borderRadius} 10px 10px`}
                height={state.templateWebview?.height}
              >
                {state.templateWebview?.icon && (
                  <CircleImg webview>
                    <StyledImage
                      src={state.widgetIcon ? state.widgetIcon : userDefault}
                      alt="Logo du chatbot"
                    />
                  </CircleImg>
                )}
              </StyledWebview>
            )}
            {currentPage === 'caroussels' && (
              <>
                <WrapperGeneric>
                  <Generic
                    pagination
                    preview
                    noMr={state.templateCaroussels.buttons.first}
                    color={'#111'}
                    height={'280px'}
                    width={'210px'}
                    imgCover={state.templateCaroussels.generic.imgCover}
                    bg={
                      state.templateCaroussels.buttons.first
                        ? state.backgroundButtonCaroussel
                        : '#FFF'
                    }
                    border={state.borderColorButton}
                    color={state.colorBtnCard}
                    borderFirstChild={
                      state.templateCaroussels.borderFirstChild &&
                      (state.borderColorButton || '#eee')
                    }
                    borderTop={
                      !state.templateCaroussels.buttons.first &&
                      (state.borderColorButton || '#eee')
                    }
                    colorTitle={state.colorFontCaroussel}
                    widthBtn={state.templateCaroussels.buttons.width || '100%'}
                    bgCard={state.backgroundCard}
                    radius={state.radiusCardCaroussel}
                    fontSizeTitle={state.fontSizeTitleCaroussel}
                    fontSizeSubtitle={state.fontSizeSubtitleCaroussel}
                    fontSizeBtn={state.fontSizeButtons}
                    paddingBtn={state.paddingButtons}
                    icon={state.templateCaroussels.buttons.icon}
                  />
                  <Generic
                    pagination
                    preview
                    noMr={state.templateCaroussels.buttons.first}
                    color={'#111'}
                    height={'280px'}
                    width={'210px'}
                    imgCover={state.templateCaroussels.generic.imgCover}
                    bg={
                      state.templateCaroussels.buttons.first
                        ? state.backgroundButtonCaroussel
                        : '#FFF'
                    }
                    border={state.borderColorButton}
                    color={state.colorBtnCard}
                    borderFirstChild={
                      state.templateCaroussels.borderFirstChild &&
                      (state.borderColorButton || '#eee')
                    }
                    borderTop={
                      !state.templateCaroussels.buttons.first &&
                      (state.borderColorButton || '#eee')
                    }
                    colorTitle={state.colorFontCaroussel}
                    widthBtn={state.templateCaroussels.buttons.width || '100%'}
                    bgCard={state.backgroundCard}
                    radius={state.radiusCardCaroussel}
                    fontSizeTitle={state.fontSizeTitleCaroussel}
                    fontSizeSubtitle={state.fontSizeSubtitleCaroussel}
                    fontSizeBtn={state.fontSizeButtons}
                    paddingBtn={state.paddingButtons}
                    icon={state.templateCaroussels.buttons.icon}
                  />
                </WrapperGeneric>
                <Pagination
                  pagNumber={state.templateControls.first}
                  pagSquare={state.templateControls.borderArrow === '4px'}
                  bottom={'70px'}
                  left={'unset'}
                />
              </>
            )}
            {currentPage === 'contact' && (
              <PreviewMail
                color={state.webviewMailColor || state.colorInputText}
              />
            )}
          </WebchatBody>
          {state.inputTextActive ? (
            <Board>
              <WebchatInput
                inputTextActive={state.inputTextActive}
                templateInput={state.templateInput}
                backgroundInput={state.backgroundInput}
                colorInputText={state.colorInputText}
                sendButton={state.sendButton}
                textInput={state.textInput}
                fontSize={state.fontSizeBoard}
                fontFamilly={state.fontSelected}
              >
                {state.textInput || 'Ecrire un message...'}
                <WrapperIcon color={state.colorInputIcons}>
                  <WrapperIconBoard>
                    {state.sendButton ? (
                      <CustomIcon
                        src={state.sendButton}
                        alt="Logo du bouton pour envoyer un message"
                        size={state.sizeIconSend}
                      />
                    ) : (
                      <PaperPlane size={16} />
                    )}
                  </WrapperIconBoard>
                  {state.sendImage && (
                    <WrapperIconBoard>
                      {state.iconSendImage ? (
                        <CustomIcon
                          src={state.iconSendImage}
                          alt="Logo du bouton pour envoyer un message"
                          size={state.sizeIconSendImage}
                        />
                      ) : (
                        <Camera size={16} />
                      )}
                    </WrapperIconBoard>
                  )}
                  <WrapperIconBoard isMenu={currentPage === 'menu'}>
                    {state.iconMenu ? (
                      <CustomIcon
                        src={state.iconMenu}
                        alt="Logo du bouton du menu"
                        size={state.sizeIconMenu}
                      />
                    ) : (
                      <EllipsisV
                        size={16}
                        onClick={() => {
                          setMenuOpen(!menuOpen);
                        }}
                      />
                    )}
                  </WrapperIconBoard>
                </WrapperIcon>
              </WebchatInput>
            </Board>
          ) : (
            <WrapperIcon color={state.colorInputIcons} inputDisabled>
              {state.sendImage && (
                <WrapperIconBoard>
                  {state.iconSendImage ? (
                    <CustomIcon
                      src={state.iconSendImage}
                      alt="Logo du bouton pour envoyer un message"
                      size={state.sizeIconSendImage}
                    />
                  ) : (
                    <Camera size={16} />
                  )}
                </WrapperIconBoard>
              )}
              <WrapperIconBoard isMenu={currentPage === 'menu'}>
                {state.iconMenu ? (
                  <CustomIcon
                    src={state.iconMenu}
                    alt="Logo du bouton du menu"
                    size={state.sizeIconMenu}
                  />
                ) : (
                  <EllipsisV
                    size={16}
                    onClick={() => {
                      setMenuOpen(!menuOpen);
                    }}
                  />
                )}
              </WrapperIconBoard>
            </WrapperIcon>
          )}
          {currentPage === 'menu' && menuOpen && (
            <WebchatMenu
              template={state.menuTemplate}
              backgroundMenu={state.backgroundMenu}
            >
              {state.menuItems.map(
                (el, i) =>
                  Object.keys(el).length !== 0 && (
                    <Button
                      key={i}
                      color={state.colorMenu}
                      template={state.menuTemplate}
                      background={state.backgroundMenu}
                      fontFamilly={state.fontSelected}
                    >
                      {(state.menuTemplate.second ||
                        state.menuTemplate.third) && (
                        <IconMenu icon={el.icon} />
                      )}
                      {!state.menuTemplate.third && el.title && el.title[m.bot.default_language]}
                    </Button>
                  )
              )}
            </WebchatMenu>
          )}
        </WebchatContainer>
      )}
    </ContainerScroll>
  );
};

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: ${({ fontFamilly }) => fontFamilly};
    font-style: normal;
    font-weight: 400;
    src: ${({ fontUrl, formatFont }) =>
      `url('${fontUrl}') format('${formatFont}')`};
    font-display: swap;
  };
`;

const WebchatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  height: 530px;
  position: relative;
  border: 1px solid lightgray;
  box-shadow: ${({ boxShadow }) =>
    boxShadow ||
    '0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)'};
  background: ${({ curved, colorHeader }) =>
    curved
      ? colorHeader
      : 'linear-gradient(180deg, rgba(254,254,254,1) 0%, rgba(253,253,253,1) 20%, rgba(251,250,250,1) 50%, rgba(250,249,249,1) 75%, rgba(248,247,247,1) 100%)'};
  border-radius: ${({ borderRadius }) => borderRadius}px;
`;

const WebchatHeader = styled.div`
  display: flex;
  justify-content: ${({ position }) => position};
  background-color: ${({ colorHeader }) => colorHeader || '#FFA300'};
  height: ${({ headerSize }) => headerSize || '60px'};
  width: auto;
  border-top-left-radius: ${({ borderRadius }) => borderRadius}px;
  border-top-right-radius: ${({ borderRadius }) => borderRadius}px;
  padding: 0.7rem;
`;

const WebchatBody = styled.div`
  background: ${({ background, webview }) =>
    webview
      ? '#eee'
      : background ||
        'linear-gradient(180deg, rgba(254,254,254,1) 0%, rgba(253,253,253,1) 20%, rgba(251,250,250,1) 50%, rgba(250,249,249,1) 75%, rgba(248,247,247,1) 100%)'};
  border-radius: ${({ curved }) => curved && '25px 25px 0 0'};
  height: inherit;
  background-image: ${({ imageBg, background }) =>
    !background && imageBg && `url(${imageBg})`};
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.6rem;
  overflow-x: hidden;
`;

const WebchatInput = styled.div`
  font-family: ${({ fontFamilly }) => fontFamilly};
  display: flex;
  font-size: ${({ fontSize }) => fontSize}em;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem;
  color: ${({ colorInputText }) => colorInputText};
  border-top: 1px solid #efefef;
  background: ${({ backgroundInput }) => backgroundInput};
  margin: ${({ templateInput }) => templateInput.v2 && '.6rem .4rem'};
  border-radius: ${({ templateInput }) => templateInput.borderRadius};
  box-shadow: ${({ templateInput }) => templateInput.boxShadow};
`;
const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${({ colorText }) => colorText || '#FFF'};
  font-size: 0.8em;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperScreen = styled.div`
  display: flex;
  background: linear-gradient(
    180deg,
    rgba(254, 254, 254, 1) 0%,
    rgba(253, 253, 253, 1) 20%,
    rgba(251, 250, 250, 1) 50%,
    rgba(250, 249, 249, 1) 75%,
    rgba(248, 247, 247, 1) 100%
  );
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  border: 1px solid #eee;
  border-radius: 25px;
  width: 300px;
  height: 568px;
`;

const StyledDivWrapper = styled.div`
  display: flex;
  flex-direction: ${({ noColumn }) => !noColumn && 'column'};
  align-items: ${({ alignItem }) => !alignItem && 'center'};
  margin-right: ${({ mr }) => mr};
  width: ${({ width }) => width};
  align-self: flex-end;
`;

const StyledWrapper = styled.div`
  display: flex;
  width: 75%;
  position: absolute;
  right: ${({ entryReduced, borderCorner }) =>
    borderCorner ? '25px' : '20px'};
  bottom: ${({ widget, entryReduced }) =>
    entryReduced ? '0' : widget ? '22px' : '50px'};
  align-items: center;
  border: none;
  border-radius: ${({ entryReduced }) =>
    entryReduced ? '25px 25px 0 0' : '50px'};
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 8px 0px, rgb(0 0 0 / 14%) 0px 3px 4px 0px,
    rgb(0 0 0 / 12%) 0px 3px 3px -2px;
  background: ${({ background }) => background || '#FFF'};
  color: ${({ color }) => color || '#111'};
  align-items: center;
  justify-content: flex-start;
  padding: 0.4rem 1rem;
  height: 5opx;
  border-bottom-right-radius: ${({ borderCorner }) => borderCorner && '1px'};
  font-size: 0.8em;
  margin: ${({ widget }) =>
    widget ? '0 .2rem 0 .2rem' : '0 .2rem 1.2rem .2rem'};
  &::after {
    content: '';
    display: ${({ afterContent }) => (afterContent ? 'block' : 'none')};
    position: absolute;
    bottom: -20px;
    right: 30px;
    width: 0;
    height: 0;
    border-bottom: 20px solid transparent;
    border-right: 27px solid ${({ background }) => background || '#FFF'};
  }
`;

const StyledTitle = styled.h1`
  font-size: 1em;
  width: 100%;
  margin: 0.2rem 1rem 0.2rem 0;
`;

const StyledCross = styled(Cross)`
  position: absolute;
  top: 7px;
  right: 10px;
  z-index: 2147483648;
`;

const StyledImg = styled.img`
  width: ${({ noColumn }) => (noColumn ? '40px' : '30px')};
  object-fit: cover;
  border-radius: 50%;
  box-shadow: ${({ noColumn }) =>
    !noColumn
      ? '-1px 2px 15px #eee'
      : '0px 1px 8px 0px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.12)'};
  margin: 0.6rem;
`;

const StyledText = styled.p`
  font-family: ${({ fontFamilly }) => fontFamilly};
  margin-left: ${({ marginLeft }) => marginLeft}rem;
  display: block;
  font-size: ${({ fontSize }) => fontSize}px;
`;

const WebchatMenu = styled.div`
  display: flex;
  flex-direction: ${({ template }) =>
    template.second || template.third ? 'row' : 'column'};
  justify-content: ${({ noText, template }) =>
    template.third ? 'space-around' : noText ? 'space-around' : 'center'};
  background-color: ${({ backgroundMenu, template }) =>
    template.second ? '#FFF' : backgroundMenu};
  padding: ${({ template }) => template.third && '.6rem 0'};
`;

const Button = styled.div`
  font-family: ${({ fontFamilly }) => fontFamilly};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ template }) => template.second && '100%'};
  color: ${({ color }) => (color ? color : '#9f9d9d')};
  font-size: ${({ icon }) => (icon ? '.4em' : '.9em')};
  font-weight: 300;
  background-color: ${({ background, template }) =>
    template.second && background ? background : '#FFF'};
  margin: ${({ template }) => template.first && '.8rem 1.2rem 0 1.2rem'};
  padding: ${({ opt2, noText }) =>
    (noText && '.8rem') || !opt2 ? '.4rem' : '.2rem'};
  border-radius: ${({ template }) => template.borderIcon};
  text-align: center;
  border-top: ${({ opt2 }) => !opt2 && '1px solid #f5f6f7'};
  display: ${({ icon }) => icon && 'flex'};
  flex-direction: ${({ icon }) => icon && 'column'};
  align-items: ${({ icon }) => icon && 'center'};
  &:first-child {
    border-top-left-radius: ${({ template }) =>
      template.borderTopRadius ? '25px' : ''};
  }
  &:last-child {
    margin: ${({ template }) => template.first && '.8rem 1.2rem .8rem 1.2rem'};
    border-top-right-radius: ${({ template }) =>
      template.borderTopRadius ? '25px' : ''};
  }
`;

const StyledQr = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ fontFamilly }) => fontFamilly};
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  border: ${({ template, borderColor }) =>
    template.border || `1px solid ${borderColor}`};
  padding: 0.4rem 1rem;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  box-shadow: ${({ template }) =>
    template.border && 'rgba(120, 120, 120, 0.8) 0px 2px 2px 0px'};
  transition-duration: 0.3s;
  cursor: pointer;
  outline: none;
  margin: 0.6rem;
  text-align: center;
  width: ${({ width }) => `${width}px` || '135px'};
  min-height: 30px;
  height: ${({ height }) => `${height}px`};
  font-size: ${({ fontSize }) => `${fontSize}em` || '.9em'};
`;

const WrapperMessages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentMessages = styled.div`
  display: flex;
  align-self: ${({ user }) => (user ? 'flex-end' : 'flex-start')};
  flex-direction: ${({ user }) => user && 'row-reverse'};
  margin: ${({ margin }) => margin};
  align-items: ${({ position }) => position};
  max-width: 100%;
`;

const StyledMessage = styled.div`
  font-family: ${({ fontFamilly }) => fontFamilly};
  position: relative;
  width: max-content;
  font-weight: 300;
  line-height: 1.2;
  color: ${({ isUser, color }) => (color ? color : isUser ? '#111' : '#FFF')};
  background-color: ${({ background, isUser }) =>
    background ? background : isUser ? '#eee' : '#F44A4A'};
  padding: 0.8rem;
  margin: 0.4rem;
  border-radius: ${({ borderRadius }) => borderRadius};
  border-top-right-radius: ${({ isUser, borderCornerTop }) =>
    isUser && borderCornerTop ? '2px' : ''};
  border-top-left-radius: ${({ isUser, borderCornerTop }) =>
    !isUser && borderCornerTop ? '2px' : ''};
  border-bottom-right-radius: ${({ isUser, borderCornerBottom }) =>
    isUser && borderCornerBottom ? '2px' : ''};
  border-bottom-left-radius: ${({ isUser, borderCornerBottom }) =>
    !isUser && borderCornerBottom ? '2px' : ''};
  box-shadow: ${({ boxShadow }) => boxShadow};
  font-size: ${({ fontSize }) => `${fontSize}em` || 'inherit'};
  &::after {
    display: ${({ oldVersion }) => (oldVersion ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    right: ${({ isUser }) => (isUser ? '0' : 'unset')};
    left: ${({ isUser }) => (isUser ? 'unset' : '0')};
    margin-right: ${({ isUser }) => (isUser ? '8px' : 'unset')};
    margin-left: ${({ isUser }) => (!isUser ? '8px' : 'unset')};
    border-right-color: ${({ isUser, background }) =>
      isUser ? background || '#eee' : 'transparant'};
    border-left-color: ${({ isUser, background }) =>
      !isUser ? background || '#F44A4A' : 'transparent'};
  }
`;

const Board = styled.div`
  display: block;
  position: relative;
  background: #fff;
`;

const WrapperGeneric = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`;

const StyledWebview = styled.div`
  position: absolute;
  background-color: #fff;
  height: ${({ height }) => height || '95%'};
  border-radius: ${({ borderRadius }) => borderRadius};
  width: 100%;
  bottom: 0;
  z-index: 100;
`;

const CustomIcon = styled.img`
  width: ${({ size }) => size || 22}px;
  height: ${({ size }) => size || 22}px;
`;

const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  color: ${({ color }) => color || '#aaa'};
  justify-content: ${({ inputDisabled }) => inputDisabled && 'flex-end'};
  margin: ${({ inputDisabled }) => inputDisabled && '0 .6rem .6rem 0'};
`;

const StyledTextMessage = styled.p`
  font-family: ${({ fontFamilly }) => fontFamilly};
`;

const WrapperIconBoard = styled.span`
  cursor: ${({ isMenu }) => isMenu && 'pointer'};
  margin: 0 0.2rem;
`;

const CrossContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 6px;
`;

export default Preview;
