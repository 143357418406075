import React from 'react';
import styled from 'styled-components';
import { Icon, Button, Select } from '@ask-mona/ui';
import MUIButton from '@material-ui/core/Button';

export const actions = [
  { name: { fr: 'Action groupée', en: 'Group actions' }, id: 0 },
  { name: { fr: 'Supprimer', en: 'Delete' }, id: 1 },
  { name: { fr: 'Publier', en: 'Publish' }, id: 2 },
];

const array = [{ name: { fr: 'Supprimer', en: 'Delete' } }];

export const Header = ({ m: { w, lang }, counter, fct, check, addPush }) => {
  const items = array.map((b) => {
    return { value: b.name[lang] };
  });
  return (
    <div className="m-l-xs flex row grow-1 space-between">
      <div className="flex row wrap flex-start grow-1">
        <div className="flex items-center has-text-primary-ter">
          <Counter>{`(${counter}) `}</Counter>
        </div>

        {check.checkListID && check.checkListID.length > 0 && (
          <React.Fragment>
            <Select
              placeholder="Action groupées"
              items={items}
              onSelect={(e) => fct.onSelectPush(e.value)}
            />
            <Button
              style={{ backgroundColor: 'rgb(130, 105, 207)', color: 'white' }}
              onClick={fct.actionSelectPush}
            >
              {w.generic.validate}
            </Button>
          </React.Fragment>
        )}
      </div>
      <div className="flex row center items-center">
        <Spacing>
          <Icon
            className="has-text-primary m-r-xs turn-360 pointer"
            icon="fas fa-sync-alt fa-lg"
            onClick={fct.reloadPush}
          />
        </Spacing>
        <MUIButton variant="contained" color="secondary" onClick={addPush}>
          {`${w.generic.add} ${w.generic.an} ${w.generic.pushs.toLowerCase()}`}
        </MUIButton>
        <MUIButton
          className="shrink is-outlined m-l-xs"
          onClick={fct.onGetInfo}
          color="primary"
        >
          <Icon
            className="has-text-primary has-size-6"
            icon="fas fa-exclamation-circle"
          />
        </MUIButton>
      </div>
    </div>
  );
};

export const Delete = ({ onConfirm, onCancel, m: { w } }) => (
  <div className="w-4 has-background-white rad-5">
    <div className="has-background-primary p-md has-text-white">
      {w.events.deleteConfirmation}
    </div>
    <div className="p-md">
      <div className="flex row space-between wrap">
        <Button onClick={onCancel} color="danger">
          {w.generic.cancel}
        </Button>
        <Button onClick={onConfirm}>{w.generic.validate}</Button>
      </div>
    </div>
  </div>
);

const Counter = styled.div`
  margin-left: -20px;
  margin-right: 40px;
  width: 20px;
`;

const Spacing = styled.div`
  margin-right: 20px;
`;
