import React, { useState } from 'react';
import { _breakOph, closingDates } from '../../../_tools/oph';
import moment from 'moment';
import Part from '../Part';
import uuid from 'react-uuid';
import { Button } from '@ask-mona/ui';

// Component that handles the Special tab content
const Special = (props) => {
  const { event, partsopen, partsclose, g: { w } } = props;

  return (
    <div>
      <div>
        <Section
          sectionName="todo"
          parts={partsopen.concat(partsclose)}
          {...props}
          event={event}
          label={w.schedule.unclassifiedHolidays}
        />
      </div>
      <div>
        <Section
          sectionName="open"
          parts={partsopen}
          {...props}
          event={event}
          label={w.generic.open}
        />
      </div>
      <div>
        <Section
          sectionName="close"
          parts={partsclose}
          {...props}
          event={event}
          label={w.generic.closed}
        />
      </div>
    </div>
  );
};

export default Special;

const Section = ({
  label,
  sectionName,
  parts,
  set,
  oph,
  event,
  isActive,
  allParts,
  ...other
}) => {
  const {
    g: { w },
    fct: { addOph },
  } = other;

  const [add, setAdd] = useState(false);
  const [dragOver] = useState(false);

  const sort = (a, b) => {
    let $a = _breakOph(a.oph).dates[0].startDate;
    let $b = _breakOph(b.oph).dates[0].startDate;
    if ($a && $b) {
      let diff = moment($a).diff($b);
      if (diff < 0) return -1;
      if (diff > 0) return 1;
    }
    return 0;
  };

  return (
    <div
      {...other}
      className={`p-md ${dragOver ? 'has-background-primary-light' : ''}`}
    >
      {label && (
        <div className="m-b-md is-size-5 has-text-weight-medium border-b-primary-lighter flex space-between items-center">
          {label}
        </div>
      )}
      <div>
        {/* Section component maps through the open and close parts, to see if they contain special closing dates and lists them here.
        The remaining closing dates are listed in Todo section */}
        {sectionName === 'todo'
          ? closingDates
              .filter(
                (date) =>
                  !parts
                    .map((part) => part.oph.split(' ')[0].toLowerCase())
                    .includes(date.oph.split(' ')[0].toLowerCase()) &&
                  !parts
                    .map((part) =>
                      part.oph.split(' ')[0].slice(4).toLowerCase()
                    )
                    .includes(date.oph.split(' ')[0].toLowerCase())
              )
              .sort(sort)
              .map((o) => (
                <Part
                  sectionName={sectionName}
                  key={uuid()}
                  {...other}
                  part={o}
                  addOph={addOph}
                />
              ))
          : parts
              .filter(
                (part) =>
                  closingDates
                    .map((date) => date.oph.split(' ')[0].toLowerCase())
                    .includes(part.oph.split(' ')[0].toLowerCase()) ||
                  closingDates
                    .map((date) => date.oph.split(' ')[0].toLowerCase())
                    .includes(part.oph.split(' ')[0].slice(4).toLowerCase()) ||
                  part.oph.includes('special')
              )
              .sort(sort)
              .map((o) => {
                const { comment } = _breakOph(o.oph);
                console.log('map in progress');
                return (
                  <Part
                    sectionName={sectionName}
                    key={uuid()}
                    {...other}
                    part={o}
                    addOph={addOph}
                    comment={comment}
                  />
                );
              })}

        {/* Allows to add Special days that are not in the Closing dates */}
        {sectionName !== 'todo' && !add && (
          <Button
            className="m-xs is-outlined is-size-7"
            onClick={() => setAdd(true)}
          >
            {w.generic.add}
          </Button>
        )}
        {sectionName !== 'todo' && add && (
          <Part
            {...other}
            part={{ oph: '' }}
            fct={{
              onChange: async (nPart) => {
                await addOph(
                  {
                    ...nPart,
                    oph:
                      sectionName === 'close'
                        ? `${nPart.oph} "special: " off`
                        : `${nPart.oph} "special: "`,
                  },
                  event
                );
                setAdd(false);
              },
              popOph: () => setAdd(false),
            }}
          />
        )}
      </div>
    </div>
  );
};
