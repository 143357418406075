import React, { Component, useCallback, useEffect, useState } from 'react';
import { ApolloProvider, graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import { Switch, useLocation } from 'react-router-dom';
import { _mergeRecursive } from '../_tools/other';
import * as s from '../_tools/security';
import _generatedMenu from '../_tools/ui';
import createClient from '../_api/client';
import User from '../_api/user';
import Place from '../_api/place';
import TTT from '../_api/ttt';
import Event from '../_api/event';
import Artwork from '../_api/artwork';
import Intent from '../_api/intent';
import { GET_BOTS } from '../_api/bot';
import { Main } from '../hoc/main';
import { Api } from '../hoc/api';
import Bot from '../_api/bot';
import { withGlobals } from '../hoc/globals';
import Loader from '../components/Loader';
import PrivateRoute from '../components/PrivateRoute';
import Boards from '../app/Boards';
import createWebchat from '../_api/createWebchat';
import { GET_LOGS } from '../_api/log';

import { getUser } from "../api/graphql/user.api";

import { roleToPermissions } from "../_tools/role";

const Onboarding = () => <div></div>;

// OFFICE
function Office(props) {
  const preURL = props.match.url;
  const location = useLocation();

  const [bots, setBots] = useState(null);
  const [bot, setBot] = useState(null);
  const [menu, setMenu] = useState(null);
  const [sectionID, changeSection] = useState(0);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [bot_lang, updateBotLang] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (!!bots) return;

    const {
      g: {
        user: { user: {...user } },
      },
    } = props;

    if (user.bots && user.bots.length) {
      setBots(user.bots);

      if (user.bots.length === 1) setBot(user.bots[0]);

    } else {
      const url = user.permissions === 1
        ? '/office/homepageAdmin/'
        : '/nosection';

      props.history.push(url);
    }
  }, []);

  useEffect(() => {
    const isExactlyOffice = location.pathname
      .split("/")
      .filter((keyword) => !!keyword.length)
      .length === 1;
    
    if (!isExactlyOffice) return;

    const {
      g: {
        user: { user: {...user } },
      },
    } = props;
    
    const url = user.permissions === 1
      ? "/office/homepageAdmin"
      : `/office/bot/${user.bots[0].id}`;
    
    props.history.push(url);
  }, [location.pathname])

  const { client } = props.g;
  const fetchMessages = useCallback(async () => {
    try {
      const res = await client.query({
        query: GET_LOGS,
        variables: {
          bot_id: bot?.id,
          archived: false,
        }
      });

      setUnreadMessages(res.data.getLogs.length);
    } catch {
      return;
    }

  }, [client, bot, setUnreadMessages]);

  const switchBot = useCallback((bot) => {
    if (!bot) {
      setBot(null);
      setMenu(null);
      setLoading(null);
    } else {
      setBot(bot);
      setLoading(true);
    }
  }, [setBot, setMenu, setLoading]);

  const { w, user: { organization, user: { permissions }} } = props.g;
  const { push } = props.history;
  useEffect(() => {
    if (!bot) return;

    const { menu: newMenu, bot_lang: botLanguage } = _generatedMenu(
      bot.features,
      w,
      organization.id,
      permissions,
      bot.default_language,
      bot.id,
    );

    fetchMessages();

    setMenu(newMenu);
    updateBotLang(botLanguage);
    changeSection(newMenu[0] ? newMenu[0].id : null);
    setLoading(false);

    if (!newMenu[0]) push("/nosection");

  }, [
    bot,
    w,
    organization,
    permissions,
    fetchMessages,
    setMenu,
    updateBotLang,
    changeSection,
    setLoading,
    push,
  ]);

  const { g: { user } } = props;

  return !bots || loading 
    ? (<Loader className="has-text-white" />)
    : (
      <Main.Provider value={_mergeRecursive(props.g, {
        bots,
        bot,
        menu,
        sectionID,
        unreadMessages,
        bot_lang,
        loading,
        fct: {
          switchBot,
          changeSection,
          fetchMessages,
          updateBotLang,
        },
      })}>
        <Switch>
          <PrivateRoute
            exact
            path={`${preURL}/onboarding`}
            component={Onboarding}
            check={s._userIsAuthenticated}
            redirectTo="/signin"
          />

          <PrivateRoute
            path={preURL}
            component={Boards}
            check={() => s._userHasOrganization(user) && bots.length}
            redirectTo={`${preURL}/onboarding`}
            switchBot={switchBot}
          />
        </Switch>
      </Main.Provider>
    );
}

const withBots = graphql(GET_BOTS, {
  options: (ownProps) => {
    return {
      variables: {
        organization_id: ownProps.g.user.organization?.id,
      },
      fetchPolicy: 'cache-first',
      skip: !ownProps.g.user.organization,
    };
  },
  name: 'dataBots',
});

const OfficeWithBots = compose(withBots)(Office);

// ENTRY WITH ALL DATA
class Entry extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem('token');
    this.client = createClient(this.token);
    this.state = {
      loading: true,
      place: new Place(this.client),
      ttt: new TTT(this.client),
      event: new Event(this.client),
      user: new User(this.client),
      artwork: new Artwork(this.client),
      intent: new Intent(this.client),
      createWebchat: new createWebchat(this.client),
    };
  }

  async componentDidMount() {
    const { g } = this.props;
    g.client = this.client;
    g.user = new User(this.client);
    try {
      const { organization, ...user } = await getUser();
      const botApi = new Bot(g.client);
      const res = await botApi._getBots();
       g.user.user = { ...user, permissions: roleToPermissions(user.role), bots: res.getBots.bots};
      g.user.organization = organization;
    } catch (err) {
      localStorage.removeItem("token");
      this.props.history.push('/signin');
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <ApolloProvider client={this.client}>
        <Api.Provider value={this.state}>
          <OfficeWithBots {...this.props} />
        </Api.Provider>
      </ApolloProvider>
    );
  }
}

export default withGlobals(Entry);
