import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Frame, Field, Input } from '@ask-mona/ui';
import styled from "styled-components";

import { _isEmail } from '../_tools/is';
import { withGlobals } from '../hoc/globals';
import { _sendFeedback } from '../_tools/ui';
import logo from '../_assets/logoWhite.png';
import '../_styles/css/main.css';

import { forgetPassword } from "../api/graphql/auth.api";

const FieldHeaderWithImage = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;

  img {
    max-width: 31px;
  }

  span {
    flex: 1 0 auto;
  }
`;

const FieldHeaderContent =styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;
`

class resetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: '',
      form: {
        email: '',
      },
      error: {
        email: '',
      },
      isDisabled: false,
      hasSubmitted: false,
    };
  }

  submit = async () => {
    const { form } = this.state;
    const { w } = this.props.g;
    try {
      this.setState({loading: true, isDisabled: true});
      await forgetPassword(form);
      _sendFeedback('success', w.form.registerSuccess);
      if (!this.state.hasSubmitted) {
        this.setState({ hasSubmitted: true });
      }
      setTimeout(() => {
        this.setState({ isDisabled: false });
      }, 15e3); // 15secondes
    } catch (error) {
      if (!_isEmail(form.email))
        _sendFeedback('danger', w.error.email);
      else _sendFeedback('danger', w.error.general);
      this.setState({isDisabled: false});
    }
    this.setState({ loading: false });
  };

  onChange = ({ target: { name, value } }) => {
    this.setState((state) => ({
      form: { ...state.form, [name]: value },
      error: { ...state.error, [name]: '', success: '' },
    }));
  };

  render() {
    const { w } = this.props.g;
    const {
      form: { email },
      error,
      loading,
      success,
      isDisabled,
      hasSubmitted,
    } = this.state;

    let title, subtitle;

    if (hasSubmitted) {
      title = (
        <FieldHeaderWithImage>
          <img
            src="https://s3.eu-central-1.amazonaws.com/askmonastudio.com/design_system/icons/warning_important.svg"
            alt="warning_important"
          />
          <span>{w.form.checkYourSpamsTitle}</span>
        </FieldHeaderWithImage>
      );

      subtitle = (
        <FieldHeaderContent>
          <span>{w.form.checkYourSpamsContentFirstLine}</span>
          <span>{w.form.checkYourSpamsContentSecondLine}</span>
        </FieldHeaderContent>
      );
    } else {
      title = w.form.email;
      subtitle = w.form.emailSub;
    }
    return (
      <div className="has-content-centered">
        <div className={`p-md w-4`}>
          <Frame background="transparent">
            <img
              src={logo}
              alt="AskMona-Logo"
              onLoad={() => this.setState({ loading: false })}
            />
          </Frame>
          <div className="has-background-white flex column p-md">
            <div>
              <Field
                title={title}
                subtitle={subtitle}
                error={error.email}
                className="m-b-md"
              >
                <Input
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  style={{ width: '100%' }}
                />
                <Button
                  loading={loading}
                  className="is-uppercase"
                  onClick={this.submit}
                  disabled={isDisabled}
                >
                  {w.generic.validate}
                </Button>
              </Field>
              {success && <div className="is-size-7">{success}</div>}
            </div>
            <div className="flex row space-between wrap">
              <Link to="/signin">
                <Button className="m-xs is-uppercase">
                  {w.generic.return}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withGlobals(resetPassword);
