import React, { useState, useEffect } from 'react';
import Loader from '../../components/Loader';
import Webchat from '../../_api/webchat';
import WChat from '../../components/webchat/';

const WCHistory = (props) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [logs, setLogsState] = useState([]);
  const [limit] = useState(15);
  const [focus, setFocusState] = useState({});
  const [firstFetch, setFirstFetch] = useState(false);
  const [lastKey, setLastKey] = useState('');
  const [scrollLoad, setScrollLoad] = useState(false);
  const [stopScroll, setStopScroll] = useState(false);
  const [scrollHeight, setScrollHeight] = useState('');

  useEffect(() => {
    async function fetchMyAPI() {
      if (firstFetch === false) {
        const { changeSub } = props;
        changeSub(2);
        setFirstFetch(true);
      }
      await loadData(lastKey);
    }

    fetchMyAPI();

    return () => {
      setFirstFetch(false);
      window.Webchat._cancelFetch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e) => {
    const target = e.target;
    const bottom =
      target.scrollHeight - target.scrollTop < (target.clientHeight * 1.1);
    if (bottom && !stopScroll && !scrollLoad) {
      if (scrollHeight < target.scrollHeight) {
        target.scrollTop = scrollHeight;
        setScrollHeight(target.scrollHeight);
        setScrollLoad(true);
        loadData(lastKey);
      }
    }
  };

  const loadData = async (page) => {
    setLoading(true);
    setScrollLoad(true);
    const {
      m: { bot, user },
    } = props;
    window.Webchat = new Webchat();
    const res = await window.Webchat._getHistory({
      bot_id: bot.id,
      startKey: page,
      limit,
    });
    if (!res.success && !res.canceled) setError(res.error);
    else {
      const { data } = res.json;
      res.json.lastKey
        ? setLastKey(JSON.stringify(res.json.lastKey))
        : setStopScroll(true);
      setLogsState([...logs, ...data]);
      logs.length === 0 &&
        setFocusState(data.filter((el) => el.history.length)[0]);
      setLoading(false);
      setScrollLoad(false);
      if (
        !sessionStorage.getItem(`user${user.user.id}_bot${bot.id}_page${page}`)
      ) {
        sessionStorage.setItem(
          `user${user.user.id}_bot${bot.id}_page${page}`,
          JSON.stringify(data)
        );
      }
    }
  };

  const setFocus = (log, num) => {
    setFocusState({ ...log, num: num });
  };

  const [fct] = useState({
    setFocus: setFocus,
  });

  if (error) throw new Error(error);

  return (
    <div className="has-text-primary w-100p flex row">
      <Sidebar
        {...props}
        error={error}
        loading={loading}
        logs={logs}
        limit={limit}
        focus={focus}
        fct={fct}
        handleScroll={handleScroll}
        scrollLoad={scrollLoad}
      />
      {!loading && (
        <div className="inline-block w-70p h-100">
          <WChat
            {...props}
            error={error}
            loading={loading}
            logs={logs}
            limit={limit}
            focus={focus}
            fct={fct}
          />
        </div>
      )}
    </div>
  );
};

const Sidebar = ({
  m: { w },
  logs,
  focus,
  fct,
  handleScroll,
  scrollLoad,
  ...other
}) => {
  const withoutEmptyHistory = logs.filter((el) => el.history.length);
  return (
    <div
      onScroll={handleScroll}
      className="inline-block w-30p h-100 border-r-primary-lighter is-size-7 has-text-weight-medium overflow-scroll"
    >
      <div className="sticky top border-b-primary-lighter has-background-white">
        <div className="p-md flex row space-between items-center">
          {`${w.inbox.conv} (${
            withoutEmptyHistory ? withoutEmptyHistory.length : '0'
          })`}
        </div>
      </div>
      {scrollLoad && <Loader />}
      {!scrollLoad && (
        <div>
          {withoutEmptyHistory &&
            withoutEmptyHistory.map((l, i) => (
              <Logs
                key={l.id}
                {...l}
                w={w}
                index={i}
                page={other.page}
                numUser={i + 1}
                focus={(num) => fct.setFocus(l, num)}
                focused={l.id === focus.id}
                id={l.id}
              />
            ))}
        </div>
      )}
    </div>
  );
};

const Logs = ({ history, focus, focused, numUser, page, index, id, w }) => {
  // Allows to fix the date and time of last received message
  let date = new Date(history[history.length - 1].time)
    .toLocaleDateString()
    .substr(0, 10)
    .replace(/-/g, '/');
  let time = new Date(history[history.length - 1].time)
    .toTimeString()
    .substr(0, 5);
  let num = Number(`${page ? page : ''}${index % 10}`) + 1;
  const user = id;
  return (
    <div
      className={`flex space-between break-word p-md border-b-primary-lighter cursor pointer
        ${focused ? `has-background-primary-ter has-text-white` : ''}
      `}
      onClick={() => focus(numUser)}
    >
      <div>{`${w.generic.user} ${user.split('_')[1].slice(0, 4)}`}</div>
      <div>{`${date} ${w.generic.at_time} ${time}`}</div>
    </div>
  );
};

export default WCHistory;
