import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import Loader from '../../components/Loader';
import { GET_EVENTS } from '../../_api/event';

class Published extends Component {
  renderPublished(getEvents) {
    if (!getEvents)
      return (
        <div className="text-align-center">
          <Loader />
        </div>
      );
    else {
      return (
        <React.Fragment>
          <span className="m-md is-size-5 text-align-center">
            {getEvents.pagination.total}
          </span>
        </React.Fragment>
      );
    }
  }

  render() {
    const {
      wording,
      opt: { getEvents },
      time,
    } = this.props;
    return (
      <React.Fragment>
        <h6 className="p-t-xs text-align-center has-text-weight-light">
          {time}
        </h6>
        <h3 className="p-t-xs is-size-7 text-align-center is-uppercase">
          {wording}
        </h3>
        {this.renderPublished(getEvents)}
      </React.Fragment>
    );
  }
}

const withData = graphql(GET_EVENTS, {
  options: (props) => {
    return {
      variables: {
        bot_id: props.bot_id,
        published: props.published,
      },
      fetchPolicy: 'network-only',
    };
  },
  name: 'opt',
});

export default compose(withData)(Published);
