import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export function BotSelectorContainer({ children, setBotById, bot }) {
  const { botId } = useParams();
  useEffect(() => {
    const id = Number(botId);

    if (id === bot.id) return;
  
    setBotById(Number(botId));
  }, [setBotById, botId]);

  const hasBot = useMemo(() => !!bot.id, [bot]);

  return hasBot ? children : <span>loading bot</span>
}