export const _isImg = (str) => {
  return /(?!\s)(https?:\/\/)?\w(\.png|\.jpg|\.jpeg|\.gif)/.test(str);
};

export const _isVid = (str) => {
  return /(?!\s)(https?:\/\/)?\w(\.mov|\.mp4|\.ogg|\.webm)/.test(str);
};

export const _isAudio = (str) => {
  return /(?!\s)(https?:\/\/)?\w(\.mp3)/.test(str);
};

export const _isYoutube = (str) => {
  return (
    /https:\/\/(www.)?youtu(\.be|be.com)?\/(embed\/)?[\w?=-]*/.test(str) &&
    /^\S*$/.test(str)
  );
};

export const _isDailymotion = (str) => {
  return /https:\/\/(www.)?dai(\.ly|lymotion.com)\/(embed\/)?(video\/)?\w*/.test(
    str
  );
};

export const _isGif = (str) => {
  return /(?!\s)https?:\/\/\w(\.gif)/.test(str);
};

export const _isEmail = (email) => {
  // eslint-disable-next-line
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const _isList = (str) => {
  if (!str || typeof str !== 'string') return false;
  return str.match(/"template_type":"list"/g);
};

export const _isButton = (str) => {
  if (!str || typeof str !== 'string') return false;
  return str.match(/"template_type":"button"/g);
};

export const _isGeneric = (str) => {
  if (!str || typeof str !== 'string') return false;
  return str.match(/"template_type":"generic"/g);
};
