import React, { useReducer } from 'react';
import EventContext from './EventContext';
import EventReducer from './EventReducer';

// This state is created in order to mantain the page number while modifying one element in the list of events
const EventState = (props) => {
  const initialState = {
    offset: 0,
  };

  const [state, dispatch] = useReducer(EventReducer, initialState);

  const setOffset = (value) => {
    dispatch({ type: 'SET_OFFSET', payload: value });
  };

  return (
    <EventContext.Provider
      value={{
        offset: state.offset,
        setOffset,
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
};

export default EventState;
