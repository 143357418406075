import React, { useContext } from 'react';
import styled from 'styled-components';
import HistoryContent from './HistoryContent';
import { Answers } from '../../../../types/qa/topics';
import { Api } from '../../../../api/graphql/hooks/useTopics';
import { QAContext } from '../../context/QAContext';

interface Props {
  close: () => any;
  answers: Answers;
  api: Api;
  w: any;
}

const History = (props: Props) => {
  const { state } = useContext(QAContext);

  return (
    <div className="w-4 h-5 has-background-white overflow-auto">
      <div className="p-md has-text-white has-background-primary">
        {state.wording.history}
      </div>
      <div className="p-md">
        {props.answers.map((el, i) => (
          <HistoryContent answer={el} key={el.id} api={props.api} w={props.w} />
        ))}
        <Button onClick={props.close}>{state.wording.back}</Button>
      </div>
    </div>
  );
};

const Button = styled.button`
  display: flex;
  padding: 0.25em 1em;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  border-radius: 3px;
  border: none;
  background-color: #e08585;
  color: white;
`;

export default History;
