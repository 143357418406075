import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Topic } from '../../../types/qa/topics';
import { QAContext } from '../context/QAContext';

interface Props {
  topic: Topic;
  handleDescription: (value: string) => void;
}

const Description = (props: Props) => {
  const { state } = useContext(QAContext);

  const [edit, setEdit] = useState<boolean>(false);
  const [valueDescription, setValueDescription] = useState<string>(
    props.topic.description
  );

  return (
    <div>
      {edit ? (
        <StyledField>
          <input
            type="text"
            value={valueDescription}
            onChange={(e) => setValueDescription(e.target.value)}
          />
          <button
            onClick={() => {
              props.handleDescription(valueDescription);
              setEdit(false);
            }}
          >
            <i className="fas fa-check" />
          </button>
        </StyledField>
      ) : (
        <StyledName>
          {valueDescription}
          {state.permission === 1 && (
            <div onClick={() => setEdit(!edit)}>
              <i className="fas fa-pen"></i>
            </div>
          )}
        </StyledName>
      )}
    </div>
  );
};

const StyledField = styled.div`
  display: flex;
  justyfi-content: space-between;
  align-items: center;
  padding: 0.25rem;
  color: #2d307b;

  > input {
    border: 1px solid #c7c8ea;
    background-color: #dadbf1;
    color: #2d307b;
    padding: 7px;
    outline: none;
    font-size: 1rem;
  }

  > button {
    border: 2px solid #4246b3;
    background-color: #5054be;
    color: white;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    font-size: 1em;
    padding: 0.25rem;

    > i {
      color: #2d307b;
      cursor: pointer;
    }
  }
`;

const StyledName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Description;
