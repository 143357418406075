import React, { useState, useEffect } from 'react';
import Client from '../Client';

type ScenarioContent = {
  id: string;
  name: string;
};

type ScenarioContents = Array<ScenarioContent>;

const useTopicLinks = (botId: number, token: string) => {
  const [scenarioContents, setScenarioContents] = useState<ScenarioContents>(
    []
  );
  const [loading, setLoading] = useState<string>('');
  const [error, setError] = useState<any>();

  let client = new Client(`${process.env.REACT_APP_HTTP_LINK_V3}`, token);

  useEffect(() => {
    const getLinkableScenarioContents = async () => {
      try {
        if (client) {
          let api = client.Topics;
          let res = await api.getLinkableScenarioContents({
            botId,
            languageCode: 'fr',
          });
          if (res) {
            setScenarioContents(
              res.data.getLinkableScenarioContents.scenarioContents
            );
          }
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading('load');
      }
    };
    getLinkableScenarioContents();
  }, []);

  return {
    scenarioContents,
    loading,
    error,
  };
};

export default useTopicLinks;
