import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import useTopicLinks from '../../../../api/graphql/hooks/useTopicLinks';
import { QAContext } from '../../context/QAContext';

interface Props {
  button: Button;
  handleButton: ({}: Button) => void;
  deleteButton: () => void;
}

type Button = {
  title: string;
  type: string;
  url?: string;
  payload?: string;
};

interface IconProps {
  readonly color: string;
}

interface WrapperProps {
  readonly isFilled: boolean;
}

type ScenarioContent = {
  id: string;
  name: string;
};

const Buttons = (props: Props) => {
  const { state } = useContext(QAContext);

  const [title, setTitle] = useState<string>(props.button.title);
  const [type, setType] = useState<string>(props.button.type);
  const [url, setUrl] = useState<string>(
    props.button.url || props.button.payload || ''
  );
  const [updateButton, setUpdateButton] = useState<boolean>(false);
  const [localUpdate, setLocalUpdate] = useState<boolean>(false);
  const { scenarioContents } = useTopicLinks(state.botId, state.token);

  useEffect(() => {
    if (!localUpdate) {
      setTitle(props.button.title);
      setType(props.button.type);
      setUrl(props.button.url || props.button.payload || '');
    }
  }, [props.button]);

  return (
    <>
      <Wrapper
        isFilled={title.length !== 0 && type.length !== 0 && url.length !== 0}
      >
        <StyledIcon color={'#e08585'} onClick={() => props.deleteButton()}>
          <i className="fas fa-trash"></i>
        </StyledIcon>
        <StyledTitle
          disabled={!updateButton}
          value={title}
          onChange={(e) => {
            setLocalUpdate(true);
            setTitle(e.target.value);
          }}
        />
        <StyledIcon color={'#a3e085'}>
          {!!!updateButton && (
            <i
              className="fas fa-pen"
              onClick={() => setUpdateButton(!updateButton)}
            ></i>
          )}
          {!!updateButton && (
            <i
              className="fas fa-check"
              onClick={() => {
                const valueUrl = type === 'postback' ? 'payload' : 'url';
                props.handleButton({ title, type, [valueUrl]: url });
                setUpdateButton(false);
              }}
            />
          )}
        </StyledIcon>
      </Wrapper>
      <div>
        {!!updateButton && (
          <WrapperOptions>
            <div className="askmona-ui-field is-primary animation-slideDown">
              <label>Contenu envoyé</label>
              <Select
                options={[
                  { label: 'Lien vers un site web', value: 'web_url' },
                  { label: 'Diriger vers un autre module', value: 'postback' },
                ]}
                className="w-100p"
                placeholder={
                  (type === 'web_url' && 'Lien vers un site web') ||
                  (type === 'postback' && 'Diriger vers un autre module') ||
                  ''
                }
                onChange={(option) => {
                  setLocalUpdate(true);
                  setType(option.value);
                  setUrl('');
                }}
              />
            </div>
            {type === 'web_url' && (
              <>
                <label>URL de redirection</label>
                <StyledField
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
              </>
            )}
            {type === 'postback' && (
              <div className="askmona-ui-field is-primary">
                <label>Lien vers un module</label>
                <Select
                  options={scenarioContents}
                  placeholder={url}
                  getOptionLabel={(scenarioContent: ScenarioContent) =>
                    scenarioContent.name
                  }
                  getOptionValue={(scenarioContent: ScenarioContent) =>
                    scenarioContent.id
                  }
                  onChange={(option) => setUrl(option.id)}
                />
              </div>
            )}
          </WrapperOptions>
        )}
      </div>
    </>
  );
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background
  color: #209fee;
  background: ${({ isFilled }) => (isFilled ? '#fff' : '#f6dada')};
  align-items:center;
  height: 35px;
  border: 1px solid #e6e6e6;

`;

const StyledTitle = styled.input`
  border: none;
  background-color: transparent;
  color: #209fee;
  width: 90%;
  padding: 7px;
  outline: none;
  font-size: 1rem;
  text-align: center;
  &::placeholder {
    color: #209fee;
  }
`;

const StyledIcon = styled.div<IconProps>`
  color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  padding: 0 0.4rem;
  > i {
    cursor: pointer;
    margin: 0.4rem 0;
  }
`;

const WrapperOptions = styled.div`
  background-color: #ededf7;
  padding: 0.25rem;
`;

const StyledField = styled.input`
  display: flex;
  justyfi-content: space-between;
  align-items: center;
  padding: 0.25rem;
  color: #2d307b;
  border: 1px solid #c7c8ea;
  background-color: #dadbf1;
  color: #2d307b;
  padding: 7px;
  outline: none;
  font-size: 1rem;
  width: 92%;
  margin-top: 0.4rem;
`;

export default Buttons;
