export default {
  relatedCountry: {
    fr: "fr",
    en: "gb",
    it: "it",
    es: "es",
    de: "de",
    pt: "pt"
  }
};
