import React, { useState } from 'react';
import styled from 'styled-components';
import Loader from '../../../components/Loader.js';

import SidebarSection from '../../../components/base/SidebarSection';
import Nodata from '../../../components/Nodata';

import { Icon } from '@ask-mona/ui';

export default (props) => {
  const {
    m: { w, bot, user },
    sidebarheight,
    state,
    dispatch,
  } = props;

  if (state.loading) return <Loader className="has-text-primary-ter" />;

  if ('activated' in state && !state.activated)
    return <Nodata title={w.scenario.botDesactivated} />;

  if (state.error) throw new Error(state.error);

  return (
    <Spacing>
      <SidebarSection
        label={w.subsections.customFunctions}
        padding="-"
        overflow="-"
        click={true}
        hashistory={true}
        subpath=""
        maxheight={sidebarheight / 2 - 100}
        {...props}
      >
        <Container
          onClick={() => {
            props.history.location.pathname !== `${props.match.path}` &&
              props.history.push(`${props.match.path}`);
          }}
        >
          {state.modules
            .filter((m) => {
              return bot.features.some((el) => m.key.includes(el.slug))
                ? bot.features.filter((el) => m.key.includes(el.slug))[0]
                    .required_permission === null
                  ? true
                  : user.user.permission <=
                    bot.features.filter((el) => m.key.includes(el.slug))[0]
                      .required_permission
                  ? true
                  : false
                : true;
            })
            .map((m) => (
              <Module
                key={m.id}
                {...props}
                mod={m}
                {...state}
                dispatch={dispatch}
              />
            ))}
        </Container>
      </SidebarSection>
    </Spacing>
  );
};

const Module = (props) => {
  const {
    location: { pathname },
    focus,
    dispatch,
    mod,
  } = props;
  const [changeName] = useState(false);
  const [name] = useState(mod.name);

  return (
    <ModuleContainer>
      {!changeName && (
        <ModuleElement
          onClick={() => dispatch({ type: 'setFocus', payload: mod })}
        >
          <ModuleBullet>
            {!mod.disable ? <ActivatedBullet /> : <DesactivatedBullet />}
          </ModuleBullet>
          <ModuleTitle focus={focus} mod={mod} pathname={pathname}>
            {' '}
            {mod.key === 'fallback' ? 'Autres' : name}{' '}
          </ModuleTitle>
          {pathname === '/office/scenarios' && focus.id === mod.id && (
            <Icon icon="fas fa-chevron-right" className="has-text-secondary" />
          )}
        </ModuleElement>
      )}
    </ModuleContainer>
  );
};

const Spacing = styled.div`
  margin-bottom: 10px;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.colors.primary};
  height: 100%;
`;

const ModuleContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const ModuleElement = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
`;

const ModuleTitle = styled.span`
  display: flex;
  width: 150px;
  color: ${(p) =>
    p.pathname === '/office/scenarios' && p.focus.id === p.mod.id
      ? p.theme.colors.secondary
      : p.theme.colors.primary};
  text-decoration: ${(p) =>
    p.pathname === '/office/scenarios' && p.focus.id === p.mod.id
      ? `underline ${p.theme.colors.secondary}`
      : 'none'};
`;

const ModuleBullet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin-right: 10px;
`;

const ActivatedBullet = styled.span`
  width: 13px;
  height: 13px;
  background-color: rgba(126, 211, 33, 1);
  border-radius: 50%;
`;

const DesactivatedBullet = styled.span`
  width: 13px;
  height: 13px;
  background-color: rgba(226, 70, 41, 1);
  border-radius: 50%;
`;
