import React, { useState } from 'react';
import { Button as Btn, Input, Icon, Button, Field } from '@ask-mona/ui';

export default (props) => {
  const [hover, setMouseHover] = useState(false);
  const {
    m: { w },
    el,
    index,
    dispatch,
  } = props;
  if (typeof el !== 'object') return null;
  if (!('delay' in el))
    return (
      <div className="w-100p p-xs flex center">
        <Btn
          className="is-size-7 is-outlined is-borderless"
          onClick={() => {
            dispatch({
              type: 'onElemChange',
              payload: { index, value: { ...el, delay: 1000 } },
            });
          }}
        >
          {w.scenario.addDelay}
        </Btn>
      </div>
    );
  return (
    <div
      className={`border-m-primary-ter is-size-7 w-100p p-xs flex row flex-end ${
        hover ? 'has-background-danger-light' : ''
      }`}
    >
      <Field>
        <Input
          type="number"
          value={el.delay/1000}
          onChange={(e) => {
            dispatch({
              type: 'onElemChange',
              payload: { index, value: { ...el, delay: e.target.value*1000 } },
            });
          }}
        />
        <Button disabled className="is-outlined is-borderless">
          {w.generic.seconds}
        </Button>
      </Field>
      <Button
        color="danger"
        className="m-l-md is-outlined is-borderless shrink"
        onMouseEnter={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
        onClick={() => {
          let newEl = { ...el };
          delete newEl['delay'];
          dispatch({ type: 'onElemChange', payload: { index, value: newEl } });
          setMouseHover(false);
        }}
      >
        <Icon icon="fas fa-trash" />
      </Button>
    </div>
  );
};
