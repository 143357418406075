import React, { Component } from 'react';
import { withMain } from '../hoc/main';
import Foundations from '../components/base/Foundations';
import Sidebar, { Sub } from '../components/base/Sidebar';
import Portal from '../components/Portal';
import ErrorBoundary from '../components/ErrorBoundary';
import moment from 'moment';
import isUrl from 'is-url';
import FormPushMain from './push/FormPushMain';
import ModalTime from './push/formPushMain/ModalTime';
import ModalProg from './push/formPushMain/ModalProg';
import { _sendFeedback } from '../_tools/ui';
import { dateFormat, statut } from '../_tools/other';
import { sections, TitlePush, Head, deleteEltArray } from './push/Ui';
import {
  genericTemplate,
  genTemplate,
  textTemplate,
  imageTemplate,
  videoTemplate,
} from '../components/scenario/Reducer';
//Api
import Bot from '../_api/bot';
import {
  _showPush,
  _broadcastPush,
  _createPush,
  _updatePush,
  _getTag,
} from '../_api/push';

class FormPush extends Component {
  mounted = false;
  constructor(props) {
    super(props);
    const {
      match,
      match: {
        params: { id },
      },
      m: { bot, lang },
    } = this.props;

    this.state = {
      focus: {
        key: 'FIRST',
      },
      hasChange: true,
      subID: 0,
      id: id,
      Error: '',
      Hold: false,
      modalProg: false,
      loaderProg: false,
      payloads: null,
      carousel: null,
      element: genericTemplate([genTemplate()]),
      array: [],
      errorTitle: null,
      arrTags: [],
      status: true,
      data: {
        lang: lang,
        id: match.params.id || null,
        bot_id: bot.id,
        sections: sections,
        places: bot.places,
        place: {
          name: bot.places[0] ? bot.places[0].name : '',
          address: bot.places[0] ? bot.places[0].address : '',
          short_address: bot.places[0] ? bot.places[0].short_address : '',
        },
      },
      variables: {
        modalButton: {
          modal: false,
          visibility: 'hidden',
          opacity: 0,
        },
        contentPush: {
          title: null,
          event: null,
          buttonName: '',
          buttonLink: '',
          allButton: [],
          save: false,
          idPush: null,
        },
        sendPush: {
          finalDate: null,
          modalTime: false,
          date: '',
          hour: '',
          min: '',
          switch: false,
          number: '',
          interval: '',
          dateEnd: '',
        },
        errorContent: {
          errorEvent: null,
          txt: '',
          empty: false,
        },
        errorButton: {
          errorName: '',
          errorLink: '',
        },
        errorSave: {
          errorDate: '',
          errorHour: '',
        },
      },
      fct: {
        //SAVE CONTENT PUSH
        saveContentPush: this.saveContentPush.bind(this),
        updateContentPush: this.updateContentPush.bind(this),
        scheduleContentPush: this.scheduleContentPush.bind(this),
        deleteContentPush: this.deleteContentPush.bind(this),

        onChangeContent: this.onChangeContent.bind(this),
        addContent: this.addContent.bind(this),

        // CAROUSEL
        attachmentCarousel: this.attachmentCarousel.bind(this),

        // TIMEPUSH
        changeDate: this.changeDate.bind(this),
        changeHour: this.changeHour.bind(this),
        saveDate: this.saveDate.bind(this),
        deleteDate: this.deleteDate.bind(this),

        getPush: this.getPush.bind(this),
        changeSection: this.changeSection,
        toggleModalTime: this.toggleModalTime.bind(this),
        toggleModalProg: this.toggleModalProg.bind(this),
        saveDraft: this.saveDraft.bind(this),
        moveElt: this.moveElt.bind(this),

        selectAllTags: this.selectAllTags.bind(this),
        deleteTagsArray: this.deleteTagsArray.bind(this),
      },
      promptStatus: false,
    };
  }

  async componentDidMount() {
    this.mounted = true;
    const {
      m: { bot, client },
      match: {
        params: { id },
      },
    } = this.props;
    if (this.mounted) {
      let api = new Bot(client, bot.id);
      let res = await api._getModules();
      if (res.success && res.json) {
        let keys = Object.keys(res.json.trigger);
        let modules = [];
        keys.forEach((k) => modules.push({ key: k, ...res.json.trigger[k] }));
        modules = modules.filter((m) => m.custom && !m.tag);
        let payloads = modules.map((m, i) => ({
          id: i,
          value: m.name,
          key: m.id,
        }));
        this.setState({ ...this.state, payloads: payloads });
      }
      if (id !== 'new') await this.getPush(id);
      await this.getBot();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  toggleModalTime() {
    const { modalTime } = this.state.variables.sendPush;
    if (!modalTime)
      this.setState({
        ...this.state,
        variables: {
          ...this.state.variables,
          sendPush: { ...this.state.variables.sendPush, modalTime: true },
        },
      });
    else
      this.setState({
        ...this.state,
        variables: {
          ...this.state.variables,
          sendPush: { ...this.state.variables.sendPush, modalTime: false },
        },
      });
  }

  toggleModalProg() {
    const { modalProg } = this.state;
    if (!modalProg) this.setState({ modalProg: true });
    else this.setState({ modalProg: false });
  }

  moveElt(index, name) {
    let { array } = this.state,
      elt = array[index],
      newArray = [];
    if (array.length > 1 || index > 0) {
      array.splice(index, 1);
      if (name === 'up') {
        array.forEach((arr, i) => {
          if (index - 1 === i) newArray.push(elt);
          newArray.push(arr);
        });
      } else if (name === 'down') {
        array.splice(index + 1, 0, elt);
        newArray = array;
      }
    } else newArray = array;
    this.setState({ ...this.state, array: newArray });
  }

  async getPush(id) {
    const {
      m: { bot },
    } = this.props;
    const idPush = this.props.match.params.id;
    const target = [];
    let status = true;
    if (this.mounted) {
      const d = await _showPush(bot.id, idPush);
      await fetch(d.url, d.req)
        .then((res) => res.json())
        .then((data) => {
          let finalDate = null,
            hour = null,
            min = null,
            date = null,
            array = [];
          if (data.scheduled) {
            status = statut(data.scheduled);
            const res = new Date(data.scheduled * 1000);
            moment.locale('fr');
            const h = moment(res).format('LT').split(':');
            const d = moment(res).format('L').split('/');
            const month = d[1] - 1;
            const event = new Date(Date.UTC(d[2], month, d[0], 3, 0, 0));
            const options = {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            };
            const f = event.toLocaleDateString('fr-FR', options);
            finalDate = f + ' à ' + h[0] + 'h' + h[1];
            hour = h[0];
            min = h[1];
            date = [d[2], d[1], d[0]].join('-');
          }
          if (data.targeting) {
            data.targeting.forEach(async (tagID) => {
              const d = await _getTag(bot.id, tagID);
              await fetch(d.url, d.req)
                .then((res) => res.json())
                .then((data) => target.push(data));
            });
          }
          data.messages.forEach((messages) => {
            if (messages.text)
              array.push({ name: 'Texte', content: { text: messages.text } });
            else if (messages.attachment) {
              if (messages.attachment.type === 'image') {
                array.push({ name: 'Image', content: messages });
              } else if (messages.attachment.type === 'video') {
                array.push({ name: 'Vidéo', content: messages });
              } else if (messages.attachment.type === 'template') {
                const elts = messages.attachment.payload.elements;
                elts.forEach((elt, k) => {
                  if (!elt.buttons) {
                    elt.buttons = [];
                  }
                });
                delete messages['id'];
                delete messages['index'];
                array.push({ name: 'Carousel', content: messages });
              }
            }
          });
          this.setState({
            array: array,
            arrTags: target,
            status: status,
            variables: {
              ...this.state.variables,
              sendPush: { date, hour, min, finalDate },
              contentPush: {
                ...this.state.variables.contentPush,
                title: data.title,
                idPush: data.id,
              },
            },
          });
        });
    }
  }

  //Keep Bot up to date
  getBot = async () => {
    const {
      m: { bot, client },
    } = this.props;
    const cBot = await new Bot(client, bot.id)._getBot();
    if (cBot.success && this.mounted) this.setState({ bot: cBot.getBot });
  };

  //SCHEDULE CONTENT PUSH
  async scheduleContentPush() {
    const {
      arrTags,
      variables: {
        sendPush: { date, hour, min, finalDate },
        contentPush: { idPush },
      },
    } = this.state;
    const {
      m: { bot, w },
    } = this.props;
    this.setState({ loaderProg: true });
    let body = {
      schedule_time: null,
      notification_type: 'REGULAR',
      messaging_type: 'MESSAGE_TAG',
      tag: 'NON_PROMOTIONAL_SUBSCRIPTION',
    };

    if (!finalDate) {
      const now = Date.now();
      const result = Math.floor(now / 1000);
      body.schedule_time = result + 50;
    } else {
      const newDate = date.split('/').join('-');
      const newHour = hour.concat(':', min);
      const concatDate = newDate.concat(' ', newHour);
      const datum = new Date(concatDate);
      const result = datum.getTime() / 1000;
      body.schedule_time = result;
    }
    if (arrTags.length > 0) {
      if (arrTags.length === 1) {
        body.custom_label_id = parseInt(arrTags[0].id);
      } else {
        body.targeting = { labels: { operator: 'AND', values: [] } };
        const idTags = [];
        arrTags.forEach((tag) => {
          idTags.push(parseInt(tag.id));
        });
        arrTags.forEach((tag) => {
          body.targeting.labels.values.push({
            operator: 'AND',
            values: idTags,
          });
        });
      }
    }
    if (body.schedule_time) {
      const json = JSON.stringify(body);
      const d = await _broadcastPush(bot.id, idPush, json);
      await fetch(d.url, d.req);
      this.setState({ loaderProg: false });
      _sendFeedback('success', w.push.save);
      this.props.history.push('/office/pushs');
    }
  }

  //SAVE CONTENT PUSH
  async saveContentPush() {
    let {
      array,
      variables: {
        contentPush: { title },
      },
    } = this.state;
    const {
      m: { w },
    } = this.props;
    const result = { title: title, messages: [] };
    if (!title || title === '') {
      _sendFeedback('warning', w.push.addTitle);
      this.setState({ errorTitle: 'ajouter un titre à votre push' });
    } else if (array.length === 0) _sendFeedback('warning', w.push.write);
    array = deleteEltArray(array);
    if (array.length > 0) {
      array.forEach((i) => {
        if (i.name === 'Texte' || i.name === 'Image' || i.name === 'Vidéo')
          result.messages.push(i.content);
        if (i.name === 'Carousel') {
          const elt = i.content.attachment.payload.elements;
          elt.forEach((e) => {
            if (!e.title || e.title === '')
              _sendFeedback('warning', w.push.carouselTitle);
            else if (!e.subtitle || e.subtitle === '')
              _sendFeedback('warning', w.push.carouselSubtitle);
            else {
              if (e.default_action) delete e['default_action'];
              if (e.buttons && e.buttons.length === 0) delete e['buttons'];
              if (e.buttons && e.buttons.length > 0) {
                e.buttons.forEach((btn) => {
                  if (btn.title === '')
                    _sendFeedback('warning', w.push.btnTitle);
                  if (btn.url === '' || !isUrl(btn.url))
                    _sendFeedback('warning', w.push.btnUrl);
                });
              }
            }
          });
          result.messages.push(i.content);
        }
      });

      if (result && title) {
        this.createOrUpdatePush(result);
      }
    } else
      this.setState({
        ...this.state,
        variables: { ...this.state.variables, errorContent: { empty: true } },
      });
  }

  async updateContentPush() {
    let {
      array,
      variables: {
        contentPush: { title },
      },
    } = this.state;
    const {
      m: { w },
    } = this.props;
    const result = { title: title, messages: [] };
    if (!title || title === '') {
      _sendFeedback('warning', w.push.addTitle);
      this.setState({
        ...this.state,
        errorTitle: 'ajouter un titre à votre push',
      });
    } else if (array.length === 0) _sendFeedback('warning', w.push.write);
    array = deleteEltArray(array);
    if (array.length > 0) {
      array.forEach((i) => {
        if (i.name === 'Image') {
          result.messages.push({ attachment: i.content.attachment });
        } else if (i.name === 'Texte') {
          result.messages.push(i.content);
        } else if (i.name === 'Vidéo') {
          result.messages.push({ attachment: i.content.attachment });
        } else if (i.name === 'Carousel') {
          if (i.content.broadcast_id) delete i.content['broadcast_id'];
          if (i.content.schedule_time) delete i.content['schedule_time'];
          const elt = i.content.attachment.payload.elements;
          elt.forEach((e) => {
            if (!e.title || e.title === '')
              _sendFeedback('warning', w.push.carouselTitle);
            else if (!e.subtitle || e.subtitle === '')
              _sendFeedback('warning', w.push.carouselSubtitle);
            else {
              if (e.default_action) {
                if (e.default_action && e.default_action.url === '')
                  delete e['default_action'];
              }
              if (e.buttons && e.buttons.length === 0) delete e['buttons'];
              if (e.buttons && e.buttons.length > 0) {
                e.buttons.forEach((btn) => {
                  if (btn.title === '')
                    _sendFeedback('warning', w.push.btnTitle);
                  if (btn.url === '' || !isUrl(btn.url))
                    _sendFeedback('warning', w.push.btnUrl);
                });
              }
            }
          });
          i.content.attachment.payload.elements = elt;
          result.messages.push(i.content);
        }
      });
      if (array.length > 0 && title) this.createOrUpdatePush(result);
    } else
      this.setState({
        ...this.state,
        variables: { ...this.state.variables, errorContent: { empty: true } },
      });
  }

  async createOrUpdatePush(result) {
    const {
      m: { bot, w },
    } = this.props;
    const {
      variables: {
        contentPush: { idPush },
      },
    } = this.state;
    _sendFeedback('warning', 'loader');
    const json = JSON.stringify(result);
    const d = !idPush
      ? await _createPush(bot.id, json)
      : await _updatePush(bot.id, idPush, json);
    await fetch(d.url, d.req)
      .then((res) => res.json())
      .then((data) => {
        if (data.id) {
          _sendFeedback('success', w.push.save);
          this.setState({
            ...this.state,
            hasChange: true,
            variables: {
              ...this.state.variables,
              contentPush: {
                ...this.state.variables.contentPush,
                save: true,
                idPush: data.id,
              },
              errorContent: { empty: false },
            },
          });
        }
        if (!data.error) {
          _sendFeedback('success', w.push.save);
          this.setState({
            ...this.state,
            hasChange: true,
            variables: {
              ...this.state.variables,
              contentPush: { ...this.state.variables.contentPush, save: true },
              errorContent: {
                ...this.state.variables.errorContent,
                empty: false,
              },
            },
          });
        }
      });
  }

  saveDraft = () => {
    this.props.history.push('/office/pushs');
  };

  async deleteContentPush() {
    //const d = await _deletePush(bot.id, idPush)
    //const res = await fetch(d.url, d.req)
    //if (res) {
    //}
    this.setState({
      ...this.state,
      hasChange: false,
      variables: {
        ...this.state.variables,
        contentPush: { ...this.state.variables.contentPush, save: false },
      },
    });
  }

  onChangeContent(value, name, index) {
    const { array } = this.state;
    const { carousel } = this.state.variables.contentPush;
    var contentPush = Object.assign(this.state.variables.contentPush);
    var state = Object.assign(this.state);
    if (name === 'title') {
      contentPush.title = value;
      state.errorTitle = null;
    }
    array.forEach((tab, k) => {
      if (k === index) {
        if (tab.name === 'Texte') tab.content.text = value;
        else if (tab.name === 'Image')
          tab.content.attachment.payload.url = value;
        else if (tab.name === 'Vidéo')
          tab.content.attachment.payload.url = value;
        else if (name === 'carousel') {
          carousel.push(value);
          tab.content = carousel;
        }
      }
    });
    this.setState({
      ...this.state,
      array: array,
      variables: { ...this.state.variables, errorContent: { empty: false } },
    });
  }

  addContent(name) {
    const { array } = this.state;
    const res = array;

    if (name === 'Texte') {
      res.push({ name: name, content: textTemplate() });
      this.setState({ ...this.state, hasChange: false, array: res });
    } else if (name === 'Image') {
      res.push({ name: name, content: imageTemplate() });
      this.setState({ ...this.state, hasChange: false, array: res });
    } else if (name === 'Vidéo') {
      res.push({ name: name, content: videoTemplate() });
      this.setState({ ...this.state, hasChange: false, array: res });
    } else if (name === 'Carousel') {
      res.push({ name: name, content: genericTemplate([genTemplate()]) });
      this.setState({ ...this.state, hasChange: false, array: res });
    } else {
      this.setState({
        variables: { ...this.state.variables, errorContent: { txt: true } },
      });
    }
  }

  attachmentCarousel(array) {
    this.setState({ ...this.state, hasChange: false, array: array });
  }

  //TIME PUSH
  changeDate(value) {
    const res = dateFormat();
    const result = value >= res ? value : res;
    this.setState({
      variables: {
        ...this.state.variables,
        sendPush: { ...this.state.variables.sendPush, date: result },
      },
    });
  }

  changeHour(e, name) {
    let value = e.target.value;
    if (name === 'hour') {
      if ((value[0] > 1 && value[1] > 3) || value[0] > 2 || value.length > 2) {
        this.setState({
          variables: {
            ...this.state.variables,
            sendPush: { ...this.state.variables.sendPush },
            errorSave: { errorHour: 'Invalide' },
          },
        });
      } else if (value.length <= 2) {
        this.setState({
          variables: {
            ...this.state.variables,
            sendPush: {
              ...this.state.variables.sendPush,
              hour: e.target.value,
            },
            errorSave: { errorHour: '' },
          },
        });
      }
    } else if (name === 'min') {
      if (value[0] > 5 || value[1] > 9 || value.length > 2) {
        this.setState({
          variables: {
            ...this.state.variables,
            sendPush: { ...this.state.variables.sendPush },
            errorSave: { errorHour: 'Invalide' },
          },
        });
      } else if (value.length <= 2) {
        this.setState({
          variables: {
            ...this.state.variables,
            sendPush: { ...this.state.variables.sendPush, min: e.target.value },
            errorSave: { errorHour: '' },
          },
        });
      }
    }
  }

  saveDate() {
    const { date, hour, min } = this.state.variables.sendPush;
    let finalDate = null;
    const hNow = moment(new Date()).format('LT').split(':');
    if (hNow[0] > parseInt(hour))
      this.setState({
        ...this.state,
        variables: {
          ...this.state.variables,
          errorSave: { errorHour: 'Invalide' },
        },
      });
    else if (hNow[0] === hour && hNow[1] > min)
      this.setState({
        ...this.state,
        variables: {
          ...this.state.variables,
          errorSave: { errorHour: 'Invalide' },
        },
      });
    else if (date && hour && min) {
      const res = date.split('-');
      const month = res[1] - 1;
      const event = new Date(Date.UTC(res[0], month, res[2], 3, 0, 0));
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const f = event.toLocaleDateString('fr-FR', options);
      finalDate = f + ' à ' + hour + 'h' + min;
      this.setState({
        ...this.state,
        variables: {
          ...this.state.variables,
          sendPush: {
            ...this.state.variables.sendPush,
            finalDate: finalDate,
            modalTime: false,
          },
        },
      });
    }
  }

  deleteDate() {
    this.setState({
      ...this.state,
      variables: {
        ...this.state.variables,
        sendPush: { ...this.state.variables.sendPush, finalDate: null },
      },
    });
  }

  selectAllTags = (data) => {
    const { arrTags } = this.state;
    if (data.error) return;
    const name = data.name.split('-')[0].trim();
    arrTags.forEach((tag, t) => {
      const res = tag.name.split('-')[0].trim();
      if (res === name) arrTags.splice(t, 1);
    });
    arrTags.push(data);
  };

  deleteTagsArray = (data, name) => {
    const { arrTags } = this.state;
    if (name === 'features')
      arrTags.forEach((tag, t) => {
        if (data === tag.name) {
          arrTags.splice(t, 1);
        }
      });
    else
      arrTags.forEach((tag, t) => {
        if (tag.id === data.id) {
          arrTags.splice(t, 1);
        }
      });
  };

  changeSection = (section_id) =>
    this.setState((state) => ({
      data: { ...state.data, view: sections[section_id] },
    }));

  checkFeatures() {
    const {
      m: { bot },
    } = this.props;
    let res = null;
    bot.features.forEach((f) => {
      if (f.id === 28 || f.id === 32) {
        res = true;
      }
    });
    return res;
  }

  render() {
    const {
      m: { w, lang, bot },
    } = this.props;
    const {
      loadingSave,
      modalProg,
      subID,
      id,
      errorTitle,
      array,
      fct,
      loaderProg,
      status,
      variables: {
        sendPush: { modalTime, finalDate },
        contentPush: { save },
      },
    } = this.state;

    const feat = this.checkFeatures() === true ? true : false;
    array.forEach((arr) => {
      if (arr.name === 'Carousel') {
        const elts = arr.content.attachment.payload.elements;
        elts.forEach((elt, k) => {
          if (!elt.buttons) {
            elt.buttons = [];
          }
        });
      }
    });
    return (
      <React.Fragment>
        <Foundations
          aside={
            <Sidebar
              c={this.state}
              addons={sections.map((s) => {
                if (s.id === 1 && feat === false) return null;
                else
                  return (
                    <Sub
                      key={s.id}
                      sub={{ ...s, title: s.name[lang] }}
                      active={subID === s.id}
                      onClick={() => this.setState({ subID: s.id })}
                    />
                  );
              })}
            />
          }
          header={
            <TitlePush
              {...this.props}
              fct={this.state.fct}
              state={this.state}
              lang={lang}
              save={save}
              errorTitle={errorTitle}
              w={w}
              status={status}
            />
          }
        >
          <Portal
            component={
              <Head
                {...this.props}
                fct={this.state.fct}
                save={this.state.variables.contentPush.save}
                array={array}
                id={id}
                finalDate={finalDate}
                loadingSave={loadingSave}
                w={w}
                status={status}
              />
            }
            selector="current-header"
          />
          {this.state.Error ? (
            <div
              className="has-background-white has-all-centered"
              style={{ gridArea: 'view' }}
            >
              {this.state.Error}
            </div>
          ) : this.state.Hold ? (
            <div
              className="has-background-white has-all-centered"
              style={{ gridArea: 'view' }}
            >
              {w.push.hold}
            </div>
          ) : (
            <ErrorBoundary>
              <FormPushMain context={this.state} />
            </ErrorBoundary>
          )}
        </Foundations>
        {modalTime && (
          <ModalTime
            show={modalTime}
            close={fct.toggleModalTime}
            bot_id={bot.id}
            wording={w}
            fct={this.state.fct}
            date={this.state.variables.sendPush}
            error={this.state.variables.errorSave}
            lang={lang}
          />
        )}
        {modalProg && (
          <ModalProg
            show={modalProg}
            close={fct.toggleModalProg}
            bot_id={bot.id}
            wording={w}
            fct={this.state.fct}
            lang={lang}
            finalDate={finalDate}
            loaderProg={loaderProg}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withMain(FormPush);
