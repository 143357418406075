import React from 'react';
import styled from 'styled-components';

const StyledBounceLoader = styled.div`
  padding: .6rem;
  >div {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: ${({ color }) => color || 'hsl(243, 80%, 62%)'};
  margin-bottom: -5px;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
  margin: 0 5px;
  animation-delay: 0.2s;
  }
  &::before {
    display: inline-block;
    content: "";
    width: 8px;
    height: 8px;
    background: ${({ color }) => color || 'hsl(243, 80%, 62%)'};
    margin-bottom: -5px;
    border-radius: 50%;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  &::after {
  display: inline-block;
  content: "";
  width: 8px;
  height: 8px;
  background: ${({ color }) => color || 'hsl(243, 80%, 62%)'};
  margin-bottom: -5px;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
  animation-delay: 0.4s;
  }

  @keyframes bouncing-loader {
  to {
      opacity: 0.1;
      transform: translate3d(0, -10px, 0);
  }
}
`;

const Load = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
`;

const LoadDot = styled.div`
  margin: 0.125rem;
  width: 8px;
  height: 8px;
  background: hsl(0, 0%, 71%);
  border-radius: 50%;

  @keyframes askmona_dot_pulse {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }

  animation-name: askmona_dot_pulse;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-delay: ${({ delay }) => delay};
`;

export const BounceLoader = (props) => (
  <StyledBounceLoader color={props.color}>
    <div></div>
  </StyledBounceLoader>
);

export const Loader = () => (
  <Load>
    <LoadDot delay="0.16s" />
    <LoadDot delay="0.32s" />
    <LoadDot delay="0.64s" />
  </Load>
);
