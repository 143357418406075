import React from 'react';

export const baseStyle = {
  position: 'fixed',
  bottom: '0px',
  right: '0px',
  border: 'none',
  zIndex: '9001',
};

export const baseState = {
  title: 'Ask Mona',
  active: false,
  menu: false,
  isMobile: false,
  state: '',
  locale: 'fr',
  timeIDs: [],
  conversation: {
    history: [],
    quickReplies: [],
  },
  wording: {
    fr: {
      msgPlaceholder: '',
      geolocation: {
        permissionDenied: "Le service de localisation n'est pas activé.",
        positionUnavailable:
          "Le service de localisation n'a pas réussi à vous localiser.",
        timeout: 'Le service de localisation a pris trop de temps !',
      },
      status: {
        base: '',
        send: '',
        received: '',
        disconnected: '',
        connect: '',
        connect_error: '',
      },
    },
    en: {
      msgPlaceholder: '',
      geolocation: {
        permissionDenied: '',
        positionUnavailable: '',
        timeout: '',
      },
      status: {
        base: '',
        send: '',
        received: '',
        disconnected: '',
        connect: '',
        connect_error: '',
      },
    },
  },
  opts: {
    entry: {},
    reply: {
      bot: {},
      user: {},
    },
  },
  skin: {
    ifrm: {
      webchat: {},
      button: {},
      mobile: {},
    },
    header: {},
    conversation: {},
    board: {},
    menu: {},
    card: {},
    textarea: {},
    button: {},
    quickreply: {},
    text: {},
    icon: {},
  },
};

export const GlobalsContext = React.createContext(baseState);

export const withGlobals = (Component) => {
  const withGlobals = (props) => {
    return (
      <GlobalsContext.Consumer>
        {(globals) => <Component {...props} context={globals} />}
      </GlobalsContext.Consumer>
    );
  };
  return withGlobals;
};
