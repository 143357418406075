import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import {Icon} from "@ask-mona/ui";
import StyledContainer from "../../../components/parameters/StyledContainers";
import FlagIcon from "../../../components/FlagIcon";
import languageTranslations from "../../../translations/languages";

const LanguagesSelection = ({ m, languageFeatures, languageBotFeatures, onBotBotFeaturesUpdated, close }) => {
    const w = m.w.parameters;

    const [languages, setLanguages] = useState(languageFeatures.map((languageFeature) => ({
        ...languageFeature,
        is_bot_active: languageBotFeatures.find((languageBotFeature) => languageBotFeature.feature_id === languageFeature.id)?.is_bot_active || false,
        is_panel_active: languageBotFeatures.findIndex((languageBotFeature) => languageBotFeature.feature_id === languageFeature.id) >= 0
    })));

    const onPanelActiveClick = useCallback((languageFeatureId, value) => {
        setLanguages((languages)=> (languages.map((language)=>({
            ...language,
            is_panel_active: language.id === languageFeatureId ? value : language.is_panel_active,
            is_bot_active: (language.id === languageFeatureId && !value) ? false : language.is_bot_active
        }))))
    }, []);

    const onBotActiveClick = useCallback((languageFeatureId, value) => {
        setLanguages((languages)=> (languages.map((language)=>({
            ...language,
            is_bot_active: language.id === languageFeatureId ? value : language.is_bot_active
        }))))
    }, []);

    return (
      <div className="w-5 h-auto has-background-white">
        <div className="p-md has-text-white has-background-primary relative">
            {w.editLanguages}
          <span className="has-text-white absolute top right p-md pointer">
              <Icon icon="fas fa-times" onClick={() => close()} />
            </span>
        </div>
        <WrapperContent>
            <StyledContainer>
                <WrapperGrid>
                    <StyledItem>{w.language}</StyledItem>
                    <StyledItem>{w.backofficeActive}</StyledItem>
                    <StyledItem>{w.chatbotActive}</StyledItem>
                </WrapperGrid>
                {languages.map((language) => (
                    <WrapperGrid>
                        <StyledLanguageItem>
                            <FlagIcon code={languageTranslations.relatedCountry[language.slug]} />
                            {language.name}
                        </StyledLanguageItem>
                        <StyledCheckbox
                            type="checkbox"
                            checked={language.is_panel_active}
                            onChange={(e) => {
                                onPanelActiveClick(language.id, e.target.checked);
                            }}
                        />
                        <StyledCheckbox
                            type="checkbox"
                            disabled={!language.is_panel_active}
                            checked={language.is_panel_active && language.is_bot_active}
                            onChange={(e) => {
                                onBotActiveClick(language.id, e.target.checked);
                            }}
                        />
                    </WrapperGrid>
                ))}
            </StyledContainer>
            <WrapperButtons>
            <Button
                onClick={close}
            >
                {w.cancel}
            </Button>
            <Button
                onClick={() => {
                    onBotBotFeaturesUpdated(languages.filter(({is_panel_active})=> is_panel_active).map((language) => ({
                        feature_id: language.id,
                        is_bot_active: language.is_bot_active
                    })));
                    close();
                }}
                color="#5054BE"
            >
                {w.validate}
            </Button>
          </WrapperButtons>
        </WrapperContent>
      </div>
  );
};

const WrapperContent = styled.div`
  padding: 1rem;
  overflow: scroll;
`;

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`;

const Button = styled.button`
  display: flex;
  padding: 0.25em 1em;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  border-radius: 3px;
  border: none;
  color: white;
  background: ${({ color }) => (color ? color : '#e08585')};
`;

const StyledLanguageItem = styled.div`
  place-self: flex-start;
  padding-left: 10px;
  span {
    padding-right: 10px;
  }
`;

const StyledItem = styled.div`
  place-self: center;
`;

const StyledCheckbox = styled.input`
  place-self: center;
  cursor: pointer;
`;

const WrapperGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  border-bottom: 1px solid #2d2f7b;
  text-align: center;
  align-items: center;
  padding: 1rem;
`;

export default LanguagesSelection;
