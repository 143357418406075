export default (state, action) => {
  switch (action.type) {
    case 'COPY_ITEMS':
      return {
        ...state,
        items: action.payload,
      };
    case 'SET_LEO_INTENTS':
      return {
        ...state,
        leoIntents: action.payload,
      };
    case 'SET_LEO_ENTITIES':
      return {
        ...state,
        leoEntities: action.payload,
      };
    case 'SET_SUSBAR_FOCUS':
      return {
        ...state,
        susbarFocus: action.payload,
      };
    case 'SET_ACTIVATED_NUMBER':
      return {
        ...state,
        activatedNumber: action.payload,
      };
    case 'SET_DESACTIVATED_NUMBER':
      return {
        ...state,
        desactivatedNumber: action.payload,
      };
    default:
      return state;
  }
};
