import React from 'react';
import Image from './Image';
import Audio from './Audio';
import Video from './Video';
import Template from './Template';
import Location from './Location';
import { withGlobals } from '../hoc/withGlobals';

const Attachement = ({ context: { skin }, type, payload, ...props }) => {
  let attachment = null;

  switch (type) {
    case 'image':
      attachment = <Image {...payload} />;
      break;
    case 'audio':
      attachment = <Audio {...payload} />;
      break;
    case 'video':
      attachment = <Video {...payload} />;
      break;
    case 'template':
      attachment = <Template {...payload} />;
      break;
    case 'location':
      attachment = <Location {...payload} />;
      break;
    default:
      break;
  }

  return <div className={`flex`}>{attachment}</div>;
};

export default withGlobals(Attachement);
