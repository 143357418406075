import React from 'react';
import Button from './Button';
import { withGlobals } from '../hoc/withGlobals';

const Buttons = ({
  buttons,
  context: {
    fct,
    opts: { hiddenBtns },
  },
  render,
  ...props
}) => {
  let btns = buttons?.filter((btns) => _filterBtns(btns, hiddenBtns))
    .map(({ type, ...props }, i) => {
      let enhancedProps = props;
      switch (type) {
        case 'postback':
          enhancedProps = {
            ...enhancedProps,
            onClick: () => fct.sendPostback(props),
          };
          break;
        case 'web_url':
          enhancedProps = {
            ...enhancedProps,
            onClick: () => window.open(props.url, '_blank'),
          };
          break;
        case 'nested':
          break;
        case 'element_share':
          return null;
        default:
          break;
      }
      return (
        <div
          key={i}
          className={`border-t-primary-lighter border-r-primary-lighter flex ${
            render === 'list'
              ? 'border-b-primary-lighter border-l-primary=lighter shrink m-xs'
              : ''
          }`}
        >
          <Button {...enhancedProps} />
        </div>
      );
    });

  return <div {...props}>{btns}</div>;
};

const _filterBtns = ({ type, payload }, hiddenBtns = []) => {
  if (type === 'postback')
    return hiddenBtns.filter((btn) => btn === payload).length === 0;
  return true;
};

export default withGlobals(Buttons);
