import React from 'react';
import styled from 'styled-components';
import CircleImg from '../../../../components/parameters/CircleImg';
import StyledImage from '../../../../components/parameters/StyledImage';
import userDefault from '../../../../_assets/user-default.png';

const PreviewFullpage = ({ state, wWebchat }) => {
  return (
    <Container curved={state.headerCurved} colorHeader={state.colorHeader}>
      {!state.hideHeaderFullpage && (
        <WebchatHeader
          colorHeader={state.colorHeader}
          headerSize={state.headerSize}
          borderRadius={state.borderRadius}
          position={state.positionHeader}
        >
          <StyledHeader colorText={state.colorText}>
            {state.widgetIcon && state.displayLogo && (
              <CircleImg width={state.sizeIcon} height={state.sizeIcon}>
                <StyledImage
                  src={state.widgetIcon ? state.widgetIcon : userDefault}
                  width={state.sizeIcon}
                  alt="Logo du chatbot"
                />
              </CircleImg>
            )}
            <StyledContent>
              {state.headerWebchat && state.displayTitle && (
                <StyledText
                  marginLeft={state.distanceIconText}
                  fontFamilly={state.fontSelected}
                >
                  {state.headerWebchat}
                </StyledText>
              )}
              {state.subtitle && state.displaySubtitle && (
                <StyledText
                  fontSize={'.8em'}
                  marginLeft={state.distanceIconText}
                  fontFamilly={state.fontSelected}
                >
                  {state.subtitle}
                </StyledText>
              )}
            </StyledContent>
          </StyledHeader>
        </WebchatHeader>
      )}
      <WebchatBody
        background={state.backgroundColorBody}
        curved={state.headerCurved}
        imageBg={state.imageBody}
        centerMessage={state.layoutCenter}
        headerHidden={state.hideHeaderFullpage}
      >
        <WrapperMessages>
          <ContentMessages position={state.iconPosition}>
            {!state.hideAvatarFullpage && (
              <CircleImg width={'30'} height={'30'}>
                <StyledImage
                  src={state.widgetIcon ? state.widgetIcon : userDefault}
                  width={'30px'}
                  alt="Logo du chatbot"
                />
              </CircleImg>
            )}
            <StyledMessage
              borderCornerTop={state.templateMessage.borderRadiusTop}
              borderCornerBottom={state.templateMessage.borderRadiusBottom}
              borderRadius={state.templateMessage.borderRadius}
              boxShadow={state.templateMessage.boxShadow}
              oldVersion={!state.templateMessage.v2}
              background={
                state.noBackgroundMessage ? 'transparant' : state.colorBubbleBot
              }
              color={state.colorMessageBotFullpage || state.colorBubbleFontBot}
              fontSize={state.fontSizeMessage}
              noBg={state.noBackgroundMessage}
            >
              <StyledTextMessage fontFamilly={state.fontSelected}>
                {wWebchat.messageBot}
              </StyledTextMessage>
            </StyledMessage>
          </ContentMessages>
          <ContentMessages user position={state.iconPosition}>
            {!state.hideAvatarFullpage && (
              <CircleImg width={'30'} height={'30'}>
                <StyledImage
                  src={state.userIcon || userDefault}
                  width={'30px'}
                  alt="Logo de l'utilisateur"
                />
              </CircleImg>
            )}
            <StyledMessage
              isUser
              borderCornerTop={state.templateMessage.borderRadiusTop}
              borderCornerBottom={state.templateMessage.borderRadiusBottom}
              borderRadius={state.templateMessage.borderRadius}
              boxShadow={state.templateMessage.boxShadow}
              background={
                state.noBackgroundMessage
                  ? 'transparant'
                  : state.colorBubbleUser
              }
              color={
                state.colorMessageUserFullpage || state.colorBubbleFontUser
              }
              oldVersion={!state.templateMessage.v2}
              fontSize={state.fontSizeMessage}
              noBg={state.noBackgroundMessage}
            >
              <StyledTextMessage fontFamilly={state.fontSelected}>
                {wWebchat.messageUser}
              </StyledTextMessage>
            </StyledMessage>
          </ContentMessages>
        </WrapperMessages>
      </WebchatBody>
      <WebchatInput>{state.textInput || 'Ecrire un message...'}</WebchatInput>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  width: 80%;
  height: 80%;
  background: #eee;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
  background: ${({ curved, colorHeader }) =>
    curved
      ? colorHeader
      : 'linear-gradient(180deg, rgba(254,254,254,1) 0%, rgba(253,253,253,1) 20%, rgba(251,250,250,1) 50%, rgba(250,249,249,1) 75%, rgba(248,247,247,1) 100%)'};
`;

const WebchatHeader = styled.div`
  display: flex;
  justify-content: ${({ position }) => position};
  background-color: ${({ colorHeader }) => colorHeader || '#FFA300'};
  height: ${({ headerSize }) => headerSize || '60px'};
  width: auto;
  border-top-left-radius: ${({ borderRadius }) => borderRadius}px;
  border-top-right-radius: ${({ borderRadius }) => borderRadius}px;
  padding: 0.7rem;
`;

const WebchatBody = styled.div`
  background: ${({ background }) =>
    background ||
    'linear-gradient(180deg, rgba(254,254,254,1) 0%, rgba(253,253,253,1) 20%, rgba(251,250,250,1) 50%, rgba(250,249,249,1) 75%, rgba(248,247,247,1) 100%)'};
  border-radius: ${({ curved, headerHidden }) =>
    headerHidden ? 'none' : curved && '25px 25px 0 0'};
  height: inherit;
  background-image: ${({ imageBg, background }) =>
    !background && imageBg && `url(${imageBg})`};
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: ${({ centerMessage }) => (centerMessage ? '0 15rem' : '0 0.6rem')};
  height: 100%;
`;

const WebchatInput = styled.div`
  display: flex;
  font-size: 1em;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem;
  color: #111;
  border-top: 1px solid #efefef;
  background-color: #fff;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${({ colorText }) => colorText || '#FFF'};
  font-size: 0.8em;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledText = styled.p`
  font-family: ${({ fontFamilly }) => fontFamilly};
  margin-left: ${({ marginLeft }) => marginLeft}rem;
  display: block;
  font-size: ${({ fontSize }) => fontSize};
`;

const WrapperMessages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentMessages = styled.div`
  display: flex;
  align-self: ${({ user }) => (user ? 'flex-end' : 'flex-start')};
  flex-direction: ${({ user }) => user && 'row-reverse'};
  margin: ${({ margin }) => margin};
  align-items: ${({ position }) => position};
  max-width: 100%;
`;

const StyledMessage = styled.div`
  font-family: ${({ fontFamilly }) => fontFamilly};
  position: relative;
  width: max-content;
  font-weight: 300;
  line-height: 1.2;
  color: ${({ isUser, color }) => (color ? color : isUser ? '#111' : '#FFF')};
  background-color: ${({ background, isUser, noBg }) =>
    noBg
      ? 'transparant'
      : background
      ? background
      : isUser
      ? '#eee'
      : '#F44A4A'};
  padding: 0.8rem;
  margin: 0.4rem;
  border-radius: ${({ borderRadius }) => borderRadius};
  border-top-right-radius: ${({ isUser, borderCornerTop }) =>
    isUser && borderCornerTop ? '2px' : ''};
  border-top-left-radius: ${({ isUser, borderCornerTop }) =>
    !isUser && borderCornerTop ? '2px' : ''};
  border-bottom-right-radius: ${({ isUser, borderCornerBottom }) =>
    isUser && borderCornerBottom ? '2px' : ''};
  border-bottom-left-radius: ${({ isUser, borderCornerBottom }) =>
    !isUser && borderCornerBottom ? '2px' : ''};
  box-shadow: ${({ boxShadow }) => boxShadow};
  font-size: ${({ fontSize }) => `${fontSize}em` || 'inherit'};
  &::after {
    display: ${({ oldVersion }) => (oldVersion ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    right: ${({ isUser }) => (isUser ? '0' : 'unset')};
    left: ${({ isUser }) => (isUser ? 'unset' : '0')};
    margin-right: ${({ isUser }) => (isUser ? '8px' : 'unset')};
    margin-left: ${({ isUser }) => (!isUser ? '8px' : 'unset')};
    border-right-color: ${({ isUser, background }) =>
      isUser ? background || '#eee' : 'transparant'};
    border-left-color: ${({ isUser, background }) =>
      !isUser ? background || '#F44A4A' : 'transparent'};
  }
`;

const StyledTextMessage = styled.p`
  font-family: ${({ fontFamilly }) => fontFamilly};
`;

export default PreviewFullpage;
