import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const BaseModal = ({ isOpen, selector, children }) => {
  const element = document.querySelector(selector);
  const dp = React.createRef<HTMLInputElement>();

  if (!isOpen || !element) return null;

  return ReactDOM.createPortal(
    <div className="modal absolute top left flex center items-center">
      <div ref={dp}>{children}</div>
    </div>,
    element
  );
};

export default BaseModal;
