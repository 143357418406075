import React from 'react';
import ReplyIcon from './ReplyIcon';

export default ({ to, children }) => (
  <div
    className={`
      m-md
      flex
      flex-1
      items-flex-end
      relative
      row${!to ? '-reverse' : ''}
    `}
  >
    {
      <div className="m-xs">
        <ReplyIcon to={to ? true : false} />
      </div>
    }
    <div>
      {React.Children.map(children, (child) => (
        <div className="m-xs flex">{child}</div>
      ))}
    </div>
  </div>
);
