import React, { Component } from 'react';
import { withGlobals } from '../hoc/withGlobals';
import Input from './Input';
import Button from './Button';
import Icon from './Icon';
import Loader from './Loader';

class Mailto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      close: false,
      error: {},
    };
  }

  componentWillUnmount() {
    if (this.state.timeOutID) clearTimeout(this.state.timeOutID);
  }

  render() {
    const { wording, locale } = this.props.context;
    const { error, loading, close, success } = this.state;
    const w = wording[locale].mailTo || {
      msg: 'Message',
      mail: 'mail',
      msgError: 'erreur',
      mailError: 'erreur',
      send: 'Envoyer',
      sendSuccess: 'Envoyé !',
      sendError: 'Erreur',
    };
    if (close) return null;
    return (
      <div className="webchat_mailto">
        {success && (
          <div className="webchat_mailTo_mask">
            <Icon icon="fas fa-check" />
            <div className="mailTo_message">{w.sendSuccess}</div>
          </div>
        )}
        {loading && (
          <div className="mailTo_mask">
            <Loader />
          </div>
        )}
        <div className="mailTo_container">
          <span className="mailTo_label">{w.msg}</span>
          <textarea
            className={`mailTo_textarea ${error.msg ? 'error' : ''}`}
            name="message"
            disabled
          ></textarea>
          {error.msg && <span className="mailTo_error">{w.msgError}</span>}
          <span className="mailTo_label">{w.mail}</span>
          <Input
            className={`mailTo_input ${error.mail ? 'error' : ''}`}
            name="mail"
            disabled
          />
          {error.mail && <span className="mailTo_error">{w.mailError}</span>}
          <Button title={w.send} className="mailTo_button" disabled />
          {error.unknown && <span className="mailTo_error">{w.sendError}</span>}
        </div>
      </div>
    );
  }
}

export default withGlobals(Mailto);
