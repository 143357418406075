import React from 'react';
import styled from 'styled-components';
import { Button } from '@ask-mona/ui';
import warningIcon from '../../../../../_assets/icon/warningIconWhite.svg';

const DeleteEmail = ({ onConfirm, close, wWebchat }) => {
  return (
    <Wrapper>
      <Header>
        <HeaderTitle>{wWebchat.confirmDeletion}</HeaderTitle>
        <HeaderTitle>
          <img src={warningIcon} width="24" alt="warning icon" />
          {wWebchat.beware}
          <span>{wWebchat.irreversible}</span>.
        </HeaderTitle>
      </Header>
      <WrapperContent>
        <WrapperButtons>
          <Button className="is-uppercase" color="secondary" onClick={close}>
            {wWebchat.cancel}
          </Button>
          <Button className="is-uppercase" onClick={() => onConfirm()}>
            {wWebchat.confirm}
          </Button>
        </WrapperButtons>
      </WrapperContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 400px;
  border-radius: 5px;
  background: #fff;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #2d2f7b;
  padding: 1rem;
`;

const HeaderTitle = styled.div`
  color: #fff;
  margin-top: 1rem;
  display: flex;

  > img {
    margin-right: 1rem;
  }
  > span {
    text-decoration: underline;
    font-weight: bold;
    margin-left: 0.4rem;
  }
`;

const WrapperContent = styled.div`
  padding: 1rem;
`;

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export default DeleteEmail;
