export default {
  mediations: ['Parcours de médiation', 'Mediation course'],
  finished: ['Médiation terminée', 'Mediation completed'],
  published: ['Parcours de médiation publié', 'Published mediation course'],
  unpublished: [
    'Parcours de médiation non-publié',
    'Unpublished mediation course',
  ],
  create: ['Ajouter un parcours de Médiation', 'Add a Mediation path'],
  affPeriod: [
    'Ajouter une période à ce parcours',
    'Add a period to this course',
  ],
  name: ['Nom du parcours *', 'Name of the course *'],
  description: ['Informations complémentaires', 'Additional information'],
  place: ['Lieu', 'Location'],
  deleteConfirmation: [
    'Souhaitez-vous vraiment supprimer ce parcours ?',
    'Do you really want to delete this course?',
  ],
  mediation: ['Parcours', 'Course'],
  createArtwork: ['Ajouter une oeuvre', 'Add an artwork'],
  ScenarioTitle: [
    'Modifier le début et la fin du parcours',
    'Change the start and end of the course',
  ],
  ScenarioSubtitle: [
    'Vous pouver modifier ici les messages envoyés à vos utilisateurs quand ils commencent et terminent ce parcours de médiation.\nLe message de retour permet de demander des feedbacks.',
    'Here you can change the messages sent to your users when they start and end this mediation path.\nThe message allows you to ask for feedback.',
  ],
  intro: ["Message d'intro", 'Introduction message'],
  outro: ['Message de retour', 'Back message'],
  publish: ['Publier le parcours', 'Publish the course'],
};
