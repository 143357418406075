import React, { useState, useReducer } from 'react';
import { Image as Img, Button, Icon } from '@ask-mona/ui';
import ArtworkApi from '../../_api/artwork';
import { _sendFeedback } from '../../_tools/ui';
import { _getKeyValue } from '../../_tools/other';
import { _formatTranslation } from '../../_tools/translation';
import { _reducer, initialState } from '../artwork/Reducer';

export default (props) => {
  const {
    data: { getMediation },
    m: { client },
    search,
  } = props;
  const [state, dispatch] = useReducer(_reducer, initialState);
  let items = _getKeyValue(getMediation, 'artworks') || [];
  let api = new ArtworkApi(client);
  if (search) {
    items = items.filter((m) => {
      let tr = _formatTranslation(m.translations);
      let name = _getKeyValue(tr, 'value') || '';
      let reg = new RegExp(`.*${search}.*`, 'ig');
      return reg.test(name);
    });
  }
  return (
    <div className="inline-block w-100p">
      <Artwork {...props} o={{ id: 'createArtwork' }} api={api} />
      {items.map((o) => (
        <Artwork
          key={o.id}
          {...props}
          o={o}
          api={api}
          state={state}
          dispatch={dispatch}
        />
      ))}
    </div>
  );
};

const Artwork = ({
  o: { id, cover_image, translations },
  m: { w },
  api,
  medId,
  other,
  history,
}) => {
  const [hovered, setHover] = useState(false);

  let tr = _formatTranslation(translations);
  let name = _getKeyValue(tr, 'value') || '';
  let createCard = id === 'createArtwork';
  // Card division
  let val = 1.5;

  return (
    <div
      className={`pointer inline-block border-primary-ter has-text-primary-ter m-xs relative ${
        hovered && createCard ? 'has-background-primary-lighter' : ''
      }`}
      style={{
        height: `${220 / val}px`,
        width: `${210 / val}px`,
        verticalAlign: 'top',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {createCard && (
        <div
          className="flex column center h-100p w-100p items-center"
          onClick={() => {
            history.push(`/office/artwork/${medId}`);
          }}
        >
          <span>
            <Icon icon="far fa-plus-square" />
          </span>
          <span className="is-size-7">{w.med.createArtwork}</span>
        </div>
      )}
      {!createCard && (
        <div>
          <Img src={cover_image || ''} alt="artwork_img" fixed="true" />
          <div className="p-xs is-size-7">{name}</div>
        </div>
      )}
      {hovered && !createCard && (
        <div className="absolute top left w-100p h-100p overlay">
          <div className="flex flex-end p-xxs">
            {/*<Button
              className="shrink is-outlined is-borderless"
              color="danger"
              onClick={async () => {
                _sendFeedback('warning', 'loader');
                let res = await api._deleteArtwork({ id });
                if (res.success) {
                  _sendFeedback('success', w.success.deleted);
                  other.refetch();
                } else _sendFeedback('danger', w.error.general);
              }}
            >
              {' '}
              <Icon icon="fas fa-trash" />{' '}
            </Button>*/}
            <Button
              className="shrink is-outlined is-borderless"
              onClick={() => history.push(`/office/artwork/${medId}/${id}`)}
            >
              {' '}
              <Icon icon="fas fa-pen" />{' '}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
