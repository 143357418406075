import React from 'react';
import URLImage from './URLImage';
import Buttons from './Buttons';
import Informations from './Information';
import { withGlobals } from '../hoc/withGlobals';
import { _random } from '../tools/other';

const Elements = ({ elements = [] }) => {
  return elements.map((e) => {
    const { default_action, image_url, buttons, ...other } = e;
    return (
      <div
        key={_random()}
        className="flex row space-between border-b-grey-lighter p-md"
      >
        <div>
          <Informations {...other} />
          {buttons && <Buttons buttons={buttons} render="list" />}
        </div>
        {image_url && (
          <URLImage src={image_url} {...default_action} className="square" />
        )}
      </div>
    );
  });
};

const Items = ({ buttons, elements, ...other }) => {
  console.log(elements, other);
  return (
    <div className="flex column space-between items">
      <Elements elements={elements} />
      <Buttons buttons={buttons} />
    </div>
  );
};

const TempList = ({
  context: { skin },
  image_url,
  default_action,
  ...props
}) => {
  return (
    <div className="webchat_card overflow-hidden rad-5px flex column m-xs">
      {image_url && <URLImage src={image_url} {...default_action} />}
      <Items {...props} />
    </div>
  );
};

export default withGlobals(TempList);
