import React from 'react';
import { Field, Image as Img } from '@ask-mona/ui';
import { _getKeyValue } from '../../../_tools/other';
import { getArtworkSignedUrl, uploadImage } from '../../../_api/upload';

const Image = ({ dispatch, artwork, w }) => {
  const onUpload = async (e) => {
    let file = Object.values(e.target.files)[0];
    const resUrl = await getArtworkSignedUrl([file], Number(artwork.id));
    if (resUrl.success) {
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      let res = null;
      reader.onload = async () => {
        const signedUrl = resUrl.url[0].signedUrl;
        const fileUrl = resUrl.url[0].fileUrl;
        res = await uploadImage(signedUrl, reader.result, file.type);
        if (res.success) {
          dispatch({ type: 'setImage', payload: fileUrl });
        }
      };
    }
  };

  const onChange = (e) => {
    dispatch({ type: 'setImage', payload: e.target.value });
  };

  const main_image = _getKeyValue(artwork, 'cover_image') || '';

  return (
    <Field title={w.artwork.image} className="m-b-md">
      <Img
        src={main_image}
        onUpload={onUpload}
        onChange={onChange}
        className="border-grey-lighter"
      />
    </Field>
  );
};

export default Image;
