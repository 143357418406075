// REDUCER AND STATE
export const _reducer = (state, action) => {
  switch(action.type) {
    case "setAddPlace":
      return ({...state, addPlace: action.payload});
    case "toggleInfo":
      return ({...state, getInfo: !state.getInfo});
    case "setEdit":
      return ({...state, edit: action.payload})
    default:
      return (state);
  }
}

export const initialState = {
  addPlace: false,
  getInfo: false,
  edit: null,
}
