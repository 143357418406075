import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { QAContext } from '../../context/QAContext';

import Buttons from './Buttons';

interface Props {
  value: string;
  buttons?: Array<Button>;
  type: string;
  id: number;
  answerId: number;
  isEditable: boolean;
}

type Button = {
  title: string;
  type: string;
  url: string;
};

const Text = (props: Props) => {
  const { state, dispatch } = useContext(QAContext);
  const [buttons, setButtons] = useState<Button[]>(props.buttons || []);

  useEffect(() => {
    setButtons(props.buttons || []);
  }, [props.buttons]);

  const handleChange = (value: string) => {
    dispatch({
      type: 'UPDATE_TOPIC_ANSWER',
      payload: {
        value,
        type: props.type,
        remove: false,
        buttons: null,
        id: props.id,
        answerId: props.answerId,
      },
    });
  };

  const handleButtons = (val, i) => {
    buttons[i] = val;
    dispatch({
      type: 'UPDATE_TOPIC_ANSWER',
      payload: {
        value: '',
        type: 'attachment',
        remove: false,
        buttons: buttons,
        id: props.id,
        answerId: props.answerId,
      },
    });
  };

  const deleteButton = (i) => {
    dispatch({
      type: 'DELETE_ANSWER_CONTENT_BUTTON',
      payload: {
        answerId: props.answerId,
        buttonId: i,
        contentId: props.id,
      },
    });
  };

  return (
    <WrapperText>
      <StyledContent>
        <StyledTextArea
          disabled={!props.isEditable}
          value={props.value}
          onChange={(e) => handleChange(e.target.value)}
        ></StyledTextArea>
        {!!buttons?.length &&
          buttons.map((el: Button, i) => (
            <Buttons
              button={el}
              key={i}
              handleButton={(val) => handleButtons(val, i)}
              deleteButton={() => deleteButton(i)}
            />
          ))}
        {buttons.length < 3 &&
          props.isEditable &&
          (buttons.length ? (
            <StyledBtn
              onClick={() =>
                dispatch({
                  type: 'ADD_TOPIC_ANSWER',
                  payload: {
                    contentType: 'attachment',
                    answerId: props.answerId,
                    contentId: props.id,
                  },
                })
              }
            >
              Ajouter un bouton
            </StyledBtn>
          ) : (
            <StyledButton
              onClick={() =>
                dispatch({
                  type: 'ADD_TOPIC_ANSWER',
                  payload: {
                    contentType: 'attachment',
                    answerId: props.answerId,
                    contentId: props.id,
                  },
                })
              }
            >
              + Ajouter un bouton
            </StyledButton>
          ))}
      </StyledContent>
    </WrapperText>
  );
};

const WrapperText = styled.div`
  background-color: #645eb0;
  color: #fff;
  display: flex;
  justify-content: center;
  paddin: 0.6rem;
  margin: 0.4rem 0;
  border-radius: 15px;
  width: 100%;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTextArea = styled.textarea`
  border: none;
  background-color: transparent;
  color: inherit;
  width: fill-available;
  justify-self: start;
  resize: none;
  align-self: flex-start;
  padding: 0.6rem;
  outline: none;
  font-size: 1rem;
  height: 115px;
`;

const StyledButton = styled.div`
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledBtn = styled.div`
  border: 2px solid #94db70;
  background-color: #a3e085;
  color: white;
  display: flex;
  padding: 0.25em 1em;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  font-size: 1em;
  border-radius: 3px;
`;

export default Text;
