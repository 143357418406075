import React from 'react';
import { Field, Input } from '@ask-mona/ui';
import FlagIcon from '../../../components/FlagIcon';
import {
  _defaultTranslation,
  _formatTranslation,
} from '../../../_tools/translation';

export default (props) => {
  const {
    m: { w },
    code,
    errors,
    artwork,
    dispatch,
  } = props;
  let tr = _formatTranslation(
    artwork?.translations || [_defaultTranslation(code)]
  )[code];
  let title = tr.name;
  return (
    <Field
      title={`${w.artwork.title} *`}
      className="m-b-md"
      error={errors.title ? w.error.missingTitle : ''}
    >
      <Input
        {...title}
        maxLength={
          title.value.length > title.maxLength ? 'default' : title.maxLength
        }
        onChange={(e) => dispatch({ type: 'onChange', payload: e.target })}
      />
      <div className="flex column start m-t-sm m-l-sm">
        <FlagIcon code={code === 'en' ? 'gb' : code} />
      </div>
    </Field>
  );
};
