import React, { Component } from 'react';
import { withGlobals } from '../hoc/globals';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    const {
      g: { w },
    } = this.props;
    if (this.state.errorInfo) {
      return (
        <div className="has-text-black has-content-centered">
          <div className="flex column flex-start">
            <div>{w.error.section}</div>
            {process.env.REACT_APP_DEV === 'true' && (
              <details open>
                <summary>
                  {this.state.error && this.state.error.toString()}
                </summary>
                <div>{this.state.errorInfo.componentStack}</div>
              </details>
            )}
          </div>
        </div>
      );
    } else if (this.props.children) {
      return this.props.children;
    } else {
      return null;
    }
  }
}

export default withGlobals(ErrorBoundary);
