import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { GET_PLACES } from '../../_api/place';
import { Select } from '@ask-mona/ui';

class PlaceSelector extends Component {
  state = {
    input: '',
  };

  onChange = (e) => {
    this.setState({ input: e.target.value });
  };

  onSelect = (item) => {
    const { onSelect } = this.props;
    onSelect && onSelect(item);
  };

  onCreate = (item) => {
    const { onCreate } = this.props;
    onCreate && onCreate(item);
  };

  render() {
    const { input } = this.state;
    const { create, ...other } = this.props;
    return (
      <Query query={GET_PLACES} variables={input ? { name: input } : {}}>
        {({ loading, data }) => {
          let items = [];
          if (data && data.getPlaces)
            items = data.getPlaces.places.map((p) => {
              return { ...p, value: p.name, data: p };
            });
          return (
            <div className="flex row w-100p">
              <Select
                {...other}
                loading={loading || !data.getPlaces}
                items={items}
                create={create}
                onChange={this.onChange}
                onSelect={this.onSelect}
                onCreate={this.onCreate}
                className="w-100p"
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default PlaceSelector;
