import React from 'react';
import CheckboxBool from './CheckboxBool';

function infoPart(props) {
  const { open, price, itinerary, fct } = props.context;
  return (
    <div className="m-t-md m-b-md m-l-md">
      <CheckboxBool
        label="Horaires"
        bool={open.bool}
        onCreate={fct.createFeatures}
        onDelete={fct.deleteFeatures}
        data={[2]}
      />
      <CheckboxBool
        label="Tarifs"
        bool={price.bool}
        onCreate={fct.createFeatures}
        onDelete={fct.deleteFeatures}
        data={[3]}
      />
      <CheckboxBool
        label="Itinéraires"
        bool={itinerary.bool}
        onCreate={fct.createFeatures}
        onDelete={fct.deleteFeatures}
        data={[5]}
      />
    </div>
  );
}

export default infoPart;
