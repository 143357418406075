import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes/';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './_styles/css/main.css';
import { unregister } from './registerServiceWorker';
import { ThemeProvider } from '@material-ui/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import mui_theme from './styles/mui/theme';
import styled_theme from './styles/styled/theme';

ReactDOM.render(
  <StyledThemeProvider theme={styled_theme}>
    <ThemeProvider theme={mui_theme}>
      <Routes />
    </ThemeProvider>
  </StyledThemeProvider>,
  document.getElementById('root')
);

unregister();
