import styled from 'styled-components';

const StyledUpload = styled.div`
  padding: 1rem;
  margin-top: ${({ noMargin }) => !noMargin && '1rem'};
  border: 2px dashed #4b4b92;
  width: ${(props) => props.width}%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export default StyledUpload;
