import React from 'react';
import { withGlobals } from '../../../hoc/globals';
import Filter from '../../../components/base/Filter';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Options = ({ g: { w }, finished, onChange }) => (
  <Filter icon="fas fa-cogs" label={w.events.state}>
    <FormControlLabel
      control={
        <Checkbox
          label={w.events.finished}
          color="primary"
          onChange={onChange}
          defaultChecked={finished}
        />
      }
      label={w.events.finished}
      fontSize="small"
    />
  </Filter>
);

export default withGlobals(Options);
