import React, { Component } from 'react';
import styled from 'styled-components';
import { Field, Input, Icon, Button, Select } from '@ask-mona/ui';
import City from '../../_api/city';
import Place from '../../_api/place';
import { withMain } from '../../hoc/main';
import { withApi } from '../../hoc/api';
import { _sendFeedback } from '../../_tools/ui';
import { _getKeyValue } from '../../_tools/other';
import Autocomplete from './GoogleAutocomplete';
import Animator from '../Animator';
import { Advanced } from './PlaceCreator';
import pronoun from './data/pronoun';

class PlaceEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.place,
      advanced: false,
      pronoun: '',
    };
  }

  componentDidMount() {
    this.getPlacePronoun();
  }

  getPlacePronoun = async () => {
    const api = new Place(this.props.m.client);
    const placeId = this.props.place.pID;
    const res = await api._getPlace({ id: placeId });
    if (res) this.setState({ pronoun: res.getPlace.pronoun });
  };

  onConfirm = async () => {
    _sendFeedback('warning', 'loader');
    const {
      api: { place },
      m: { w },
      onSuccess,
      onError,
    } = this.props;
    const { advanced, pID, id, pronoun, ...form } = this.state;
    let res = null;
    if (form.sub)
      res = await place._updateSubPlace({
        ...form,
        id: pID,
        itinerary: false,
        pronoun,
      });
    else
      res = await place._updatePlace({
        ...form,
        id: pID,
        pronoun,
      });
    if (res.success) {
      _sendFeedback('success', w.feedback.updatePlace);
      onSuccess && onSuccess(res[form.sub ? 'createSubPlace' : 'createPlace']);
    } else {
      console.log(res);
      _sendFeedback('danger', w.error.updatePlace);
      onError && onError(res);
    }
  };

  onSelect = async (place) => {
    const {
      m: { w },
    } = this.props;
    if (place) {
      const { other, ...form } = place;
      _sendFeedback('warning', 'loader');
      const city = await this.setCity(other);
      if (city.success) _sendFeedback('success', w.feedback.getCity);
      else _sendFeedback('danger', w.error.getCity);
      this.setState({ ...form, city_id: _getKeyValue(city, 'id') });
    }
  };

  setCity = async (data) => {
    const {
      m: { client },
    } = this.props;
    let city = new City(client, data);
    let ret = await city._getCity();
    if (!ret || !ret.getCity) ret = await city._createCity();
    return ret;
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  reset = (e) => {
    this.setState({
      lat: null,
      long: null,
      city_id: null,
      address: e.target.value,
      short_address: '',
      google_place_id: '',
      postcode: '',
    });
  };

  toggleAdvanced = () => {
    this.setState((state) => ({ advanced: !state.advanced }));
  };

  render() {
    const {
      m: { w },
      onCancel,
    } = this.props;
    const { advanced, ...place } = this.state;
    return (
      <div className="w-4 has-background-white rad-5">
        <div className="flex row space-between items-center has-background-primary p-md">
          <div className="has-text-white">{w.place.editPlace}</div>
          <Button
            onClick={onCancel}
            className="is-outlined is-borderless shrink"
          >
            <Icon icon="fas fa-times" />
          </Button>
        </div>
        <div className="p-md">
          <Field title={w.place.name} className="m-b-md">
            <Input
              name="name"
              value={place.name}
              placeolder={w.place.namePlaceholder}
              onChange={this.onChange}
              className="w-100p"
            />
          </Field>
          <StyledTitle>{w.place.pronoun}</StyledTitle>
          <StyledSelect
            items={pronoun}
            placeholder={this.state.pronoun}
            onSelect={(e) => this.setState({ pronoun: e.value })}
            className="w-100p m-b-md"
          />
          <Field title={w.place.address} className="m-b-md">
            <Autocomplete
              onSelect={this.onSelect}
              onChange={this.reset}
              default={place.address}
              style={{ width: '100%' }}
            />
          </Field>
          {!place.sub && (
            <React.Fragment>
              <div className="flex center">
                <Button
                  className="is outlined is-borderless is-size-7"
                  onClick={this.toggleAdvanced}
                >
                  {advanced ? (
                    <Icon icon="fas fa-minus" />
                  ) : (
                    <Icon icon="fas fa-plus" />
                  )}
                </Button>
              </div>
              <Animator
                isOpen={advanced}
                enter="animation-slideDown overflow-hidden"
              >
                <Advanced
                  {...this.props}
                  data={place}
                  onChange={this.onChange}
                />
              </Animator>
            </React.Fragment>
          )}
          <div className="flex row space-between wrap">
            <Button
              className="is-uppercase"
              onClick={onCancel}
              color="secondary"
            >
              {w.generic.cancel}
            </Button>
            <Button
              className="is-uppercase"
              onClick={this.onConfirm}
              disabled={
                place.sub
                  ? !place.name || !place.address
                  : !place.lat ||
                    !place.long ||
                    !place.name ||
                    !place.address ||
                    !place.short_address
              }
            >
              {w.generic.validate}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const StyledTitle = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 2px;
  color: #2d307b;
`;

const StyledSelect = styled(Select)`
  .askmona-ui-input.is-primary {
    &::placeholder {
      color: #2d307b;
    }
  }
`;

export default withMain(withApi(PlaceEdit));
