import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon } from '@ask-mona/ui';
import { _sendFeedback } from '../../../_tools/ui';
import Modal from '../../../components/Modal';
import ContentHistory from './ContentHistory';
import {
  _isImg,
  _isVid,
  _isGif,
  _isList,
  _isYoutube,
  _isDailymotion,
  _isButton,
} from '../../../_tools/is';
import { _getKeyValue } from '../../../_tools/other';

const _contentToItems = (content) => {
  let items = (content || []).map((c) => {
    if (_isList(c)) return { type: 'list', content: c };
    if (_isButton(c))
      return {
        type: 'button',
        content: c,
        buttons: _getKeyValue(JSON.parse(c), 'buttons'),
        text: _getKeyValue(JSON.parse(c), 'text'),
      };
    if (_isImg(c)) return { type: 'img', content: c };
    if (_isGif(c)) return { type: 'gif', content: c };
    if (_isVid(c)) return { type: 'vid', content: c };
    if (_isYoutube(c)) return { type: 'youtube', content: c };
    if (_isDailymotion(c)) return { type: 'dailymotion', content: c };
    return { type: 'text', content: c };
  });
  return items;
};

const History = (props) => {
  const {
    m: { w },
    isOpen,
    close,
    content,
  } = props;

  return (
    <Modal isOpen={isOpen} close={close} selector="#root">
      <div className="w-4 h-5 relative overflow-scroll has-background-white has-text-primary-ter">
        <HeaderHistory>
          {w.qa.history}
          <Button color="is-outlined is-borderless shrink" onClick={close}>
            <Icon icon="fas fa-times" />
          </Button>
        </HeaderHistory>
        <div className="p-md">
          {content
            ? content.map((c) =>
                c.content ? (
                  <Content key={c.id} {...props} c={c} />
                ) : (
                  content.splice(content.indexOf(c), 1)
                )
              )
            : w.QR.noHistory}
        </div>
        <div className="p-md">
          <Button color="danger" onClick={close}>
            {w.generic.return}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const Content = (props) => {
  const [delhover, setHover] = useState(false);
  const [reshover, setResHover] = useState(false);
  const [explore, setExplore] = useState(false);
  const [content, setContent] = useState({});
  const {
    m: { w, bot },
    api: { intent },
    el,
    lang,
    c,
  } = props;

  useEffect(() => {
    setContent(_contentToItems(c.content));
  }, []);

  return (
    <div
      className={`flex column m-b-sm p-sm ${
        delhover ? 'has-background-danger-light' : ''
      } ${reshover ? 'has-background-success-light' : ''}`}
    >
      {explore && c.content.length > 1 ? (
        <Explore {...props} setExplore={setExplore} />
      ) : (
        <>
          <div className="is-size-7 is-italic flex row space-between items-center">
            <div>{`${w.qa.archivedBy} ${c.archived_date}`}</div>
            <div className="flex row">
              <Button
                color="danger"
                className="is-outlined shrink is-borderless"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={async () => {
                  _sendFeedback('warning', 'loader');
                  const res = await intent._deleteContent(
                    {
                      bot_id: bot.id,
                      id: el.id,
                      language: lang,
                      content_id: c.id,
                    },
                    { refetchQueries: ['getCategoriesAndIntents'] }
                  );
                  if (res.success) _sendFeedback('success', w.success.deleted);
                  else _sendFeedback('danger', w.error.general);
                }}
              >
                <Icon icon="fas fa-trash" />
              </Button>
              <Button
                className="is-outlined shrink is-borderless"
                onMouseEnter={() => setResHover(true)}
                onMouseLeave={() => setResHover(false)}
                onClick={async () => {
                  _sendFeedback('warning', 'loader');
                  const res = await intent._updateContent(
                    {
                      bot_id: bot.id,
                      id: el.id,
                      language: lang,
                      content_id: c.id,
                      content: c.content,
                      activated: true,
                      place_id: c.place_id,
                    },
                    { refetchQueries: ['getCategoriesAndIntents'] }
                  );
                  if (res.success) _sendFeedback('success', w.success.saved);
                  else _sendFeedback('danger', w.error.general);
                }}
              >
                <Icon icon="fas fa-share-square" />
              </Button>
              <Button
                className="is-outlined shrink is-borderless"
                onClick={() => setExplore(true)}
              >
                {c.content.length > 1 && <Icon icon="fas fa-eye" />}
              </Button>
            </div>
          </div>
          <span
            className="p-sm rad-5px has-background-primary-light space-pre-line"
            style={{ wordBreak: 'break-word' }}
            onClick={() => setExplore(true)}
          >
            {content && (
              <ContentHistory
                type={content[0]?.type}
                content={content[0]?.content}
                w={w}
                buttons={content[0]?.buttons}
                text={content[0]?.text}
              />
            )}
          </span>
        </>
      )}
    </div>
  );
};

const Explore = (props) => {
  const {
    m: { w },
    c,
    setExplore,
  } = props;
  const [content, setContent] = useState([]);

  useEffect(() => {
    setContent(_contentToItems(c.content));
  }, []);

  return (
    <div className="is-size-7 is-italic flex column space-between flex-start">
      <div>{`${w.qa.archivedBy} ${c.archived_date}`}</div>
      <div className="flex column m-t-sm">
        {content?.map((c) => (
          <span
            className="p-sm rad-5px has-background-primary-light space-pre-line m-b-xs"
            style={{ wordBreak: 'break-word' }}
          >
            <ContentHistory
              type={c.type}
              content={c.content}
              w={w}
              buttons={c.buttons}
              text={c.text}
            />
          </span>
        ))}
        <Button
          className="is-outlined shrink is-borderless m-t-xs"
          onClick={() => setExplore(false)}
        >
          <Icon icon="fas fa-arrow-left" /> Revenir en arrière
        </Button>
      </div>
    </div>
  );
};

const HeaderHistory = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color: #fff;
  background-color: #7a7ab8;
  padding: 1rem;
  position: sticky;
  top: 0px;
`;

export default History;
