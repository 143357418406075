export default {
  alternate: ['alternative', 'alternative'],
  title: ['Questions / Réponses', 'Questions and answers'],
  noAnswer: [
    'Cette thématique est activée mais vous n’y avez pas apporté de réponse.',
    'This theme is activated but you have not provided an answer.',
  ],
  addElement: ['+ Ajouter un élément', '+ Add an item'],
  addContent: ['+ Ajouter une réponse', '+ Add an answer'],
  addSettings: ['+ Modifier les rappels et les périodes', '+ Modify reminders and periods'],
  setPeriod: [
    'Programmer cette réponse pour une période',
    'Program this response for a period of time',
  ],
  setDate: [
    'Programmer cette réponse pour une date',
    'Schedule this response for a date',
  ],
  setReminder: ['Programmer un rappel par mail', 'Schedule an email reminder'],
  scheduled: ['Programmé', 'Programmed'],
  remind: [
    'Un rappel sera envoyé par mail le',
    'A reminder will be sent by email on',
  ],
  history: ["Consulter l'historique des réponses", 'View response history'],
  historyTitle: ['Historique des réponses', 'History of responses'],
  archiveHelper: [
    "Cliquez ici à chaque fois que vous souhaitez sauvegarder dans l'historique une réponse de votre bot. Par défaut, vos modifications ne sont pas archivées automatiquement.",
    'Click here each time you want to archive a response from your bot. By default your changes are not automatically archived.',
  ],
  noHistory: [
    "Vous n'avez aucune réponse sauvegardée dans l'historique pour l'instant.",
    'You do not have any responses archived in the history at this time.',
  ],
};
