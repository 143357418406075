import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { withMain } from '../hoc/main';
import { getAnalytic } from '../_api/analytics';
import { uploadImage, uploadBotFile } from '../_api/upload';
import Bot from '../_api/bot';
import Alert from '../_api/alert';
import AlertMessages from '../components/AlertMessages';
import StyledImage from '../components/parameters/StyledImage';
import CircleImg from '../components/parameters/CircleImg';
import Upload from '../_assets/icon/Upload';
import InputFile from '../components/parameters/InputFile';
import { DocumentText } from '@styled-icons/ionicons-solid/DocumentText';
import { SearchPlus } from '@styled-icons/fa-solid/SearchPlus';
import { User } from '@styled-icons/entypo/User';
import { BackInTime } from '@styled-icons/entypo/BackInTime';
import { MessageDots } from '@styled-icons/boxicons-regular/MessageDots';
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { EditOutline } from '@styled-icons/evaicons-outline/EditOutline';
import { Check } from '@styled-icons/boxicons-regular/Check';
import Loader from '../components/Loader';
import { _sendFeedback } from '../_tools/ui';
import { Bot as BotIcon } from '@styled-icons/boxicons-regular/Bot';

const verifyUrl = (url) => {
  const regex = new RegExp(
    /https?:\/\/(?:[-\w]+\.)?([-\w]+)\.\w+(?:\.\w+)?\/?.*/i
  );

  if (url.match(regex)) {
    return url;
  } else {
    return 'https://'.concat(url);
  }
};

const duration = (time) => {
  const res = [];
  const d = time.split(':');
  if (d[0] !== 0) {
    res.push(d[0] + 'h');
  }
  res.push(d[1]);
  res.push('m'.toLowerCase());
  res.push(d[2]);
  res.push('s'.toLowerCase());
  const join = res.join('');
  return join;
};

const numberWithSpaces = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const Homepage = (props) => {
  const [nbUser, setNbUser] = useState(0);
  const [time, setTime] = useState(0);
  const [alertMessage, setAlertMessage] = useState(0);
  const [nbEvent, setEvent] = useState(0);
  const [loadDatas, setLoadDatas] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [homepageBanner, setHomepageBanner] = useState({});
  const [loadBanner, setLoadBanner] = useState(false);

  const w = props.m.w.homepage;

  useEffect(() => {
    getAnalytics();
    fetchAlertMessage();
  }, []);

  useEffect(() => {
    fetchHomepageBanner();
  }, []);

  const fetchHomepageBanner = async () => {
    const Api = new Bot(props.m.client, props.m.bot.id);
    const res = await Api._getBot({
      id: props.m.bot.id,
    });
    if (res.success) {
      setHomepageBanner(res.getBot.homepage_banner);
      setLoadBanner(true);
    }
  };

  const fetchAlertMessage = async () => {
    const Api = new Alert(props.m.client, props.m.bot.id);
    const res = await Api._getAlertMessages({
      bot_id: props.m.bot.id,
    });
    if (res.success) {
      setAlertMessage(res.getAlertMessages.length);
    }
  };

  const sendBannerInfo = async () => {
    const link = verifyUrl(
      homepageBanner?.link || 'https://studio.askmona.fr/'
    );
    setHomepageBanner({
      ...homepageBanner,
      link,
    });
    const Api = new Bot(props.m.client, props.m.bot.id);
    const res = await Api._updateHomepageBanner({
      ...homepageBanner,
      id: Number(props.m.bot.id),
    });
    if (res.success) {
      _sendFeedback('success', w.majBanner);
      setIsEditable(false);
    } else {
      _sendFeedback('danger', w.error);
    }
  };

  const getAnalytics = async () => {
    const res = await getAnalytic(props.m.bot.id);
    if (res.success) {
      setNbUser(res.json.users?.count?.total);
      setTime(res.json.users?.sessions.duration.average);
      setLoadDatas(false);
      setEvent(
        res.json.ga_analytics && res.json.ga_analytics['Event Action']?.total
      );
    }
  };

  const handleUpload = async (e) => {
    let file = Object.values(e.target.files)[0];
    const resUrl = await uploadBotFile(file.name, Number(props.m.bot.id));
    if (resUrl.success) {
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      let res = null;
      reader.onload = async () => {
        const signedUrl = resUrl.url.signedUrl;
        const fileUrl = resUrl.url.fileUrl;
        res = await uploadImage(signedUrl, reader.result, file.type);
        if (res.success) {
          _sendFeedback('success', props.m.w.success.saved);
          setHomepageBanner({
            ...homepageBanner,
            image: fileUrl,
          });
        }
      };
    }
  };

  return (
    <Wrapper>
      <WrapperHeader>
        <WrapperBot>
          {Object.entries(props.m.bot.panel_logo).length !== 0 ? (
            <CircleImg width={'60'} height={'60'} mr={1} noBorder>
              <StyledImage
                width={'60'}
                src={props.m.bot.panel_logo}
                alt={`Logo de ${props.m.bot.name}`}
              />
            </CircleImg>
          ) : (
            <StyledUserCircle size={60} />
          )}
          <div>
            <NameBot>{props.m.bot.name}</NameBot>
            <NameOrga>{props.m.bot.organization.name}</NameOrga>
          </div>
        </WrapperBot>
        <AlertContainer>
          <AlertMessages
            bot_id={props.m.bot.id}
            alertMessage={alertMessage}
            homepage
          />
        </AlertContainer>
      </WrapperHeader>
      <WrapperResources>
        <WrapperDocumentText>
          {loadBanner ? (
            isEditable && props.m.user.user.permissions === 1 ? (
              <WrapperEditUpload>
                {homepageBanner?.image ? (
                  <img src={homepageBanner.image} width={40} height={40} />
                ) : (
                  <DocumentText size={40} color={'#2d2f7b'} />
                )}
                <WrapperUpload>
                  <Upload />
                  <InputFile type="file" onChange={(e) => handleUpload(e)} />
                </WrapperUpload>
              </WrapperEditUpload>
            ) : homepageBanner?.image ? (
              <StyledImg src={homepageBanner.image} width={40} height={40} />
            ) : (
              <DocumentText size={40} color={'#2d2f7b'} />
            )
          ) : (
            <Loader />
          )}
          <WrapperEditable>
            {loadBanner ? (
              isEditable && props.m.user.user.permissions === 1 ? (
                <WrapperInput>
                  <StyledInput
                    type="text"
                    value={homepageBanner?.title}
                    placeholder={homepageBanner?.title || w.titleBanner}
                    onChange={(e) =>
                      setHomepageBanner({
                        ...homepageBanner,
                        title: e.target.value,
                      })
                    }
                    fontSize={'1.2em'}
                  />
                  <StyledInput
                    type="text"
                    value={homepageBanner?.description}
                    placeholder={homepageBanner?.description || w.contentBanner}
                    onChange={(e) =>
                      setHomepageBanner({
                        ...homepageBanner,
                        description: e.target.value,
                      })
                    }
                    fontSize={'1em'}
                  />
                </WrapperInput>
              ) : (
                <>
                  <h3>{homepageBanner?.title || w.titleBanner}</h3>
                  <p>{homepageBanner?.description || w.contentBanner}</p>
                </>
              )
            ) : (
              <Loader />
            )}
          </WrapperEditable>
        </WrapperDocumentText>
        {loadBanner ? (
          isEditable && props.m.user.user.permissions === 1 ? (
            <WrapperEditableButton>
              <WrapperButtonLink>
                <ButtonInput
                  value={homepageBanner?.button_title}
                  placeholder={homepageBanner?.button_title || w.findOut}
                  onChange={(e) =>
                    setHomepageBanner({
                      ...homepageBanner,
                      button_title: e.target.value,
                    })
                  }
                />
                <p>{w.linkButton}</p>
                <ButtonInput
                  type="text"
                  value={homepageBanner?.link || 'https://studio.askmona.fr/'}
                  onChange={(e) =>
                    setHomepageBanner({
                      ...homepageBanner,
                      link: e.target.value,
                    })
                  }
                />
              </WrapperButtonLink>
            </WrapperEditableButton>
          ) : (
            <WrapperFindOut>
              <a
                href={homepageBanner?.link || 'https://studio.askmona.fr/'}
                target="_blank"
              >
                {homepageBanner?.button_title || w.findOut}
              </a>
            </WrapperFindOut>
          )
        ) : (
          <Loader />
        )}
        {props.m.user.user.permissions === 1 && (
          <WrapperEdit>
            <StyledEdit size={20} onClick={() => setIsEditable(!isEditable)} />
            {isEditable && (
              <StyledCheck size={20} onClick={() => sendBannerInfo()} />
            )}
          </WrapperEdit>
        )}
      </WrapperResources>
      <KeyDatas>
        <TittleKey>{w.numberKey}</TittleKey>
        <WrapperBox>
          <Box
            gradient={
              'linear-gradient(90deg, rgba(157,119,246,1) 0%, rgba(145,111,237,1) 20%, rgba(125,98,224,1) 40%, rgba(113,90,216,1) 60%, rgba(91,78,201,1) 80%, rgba(78,68,192,1) 100%)'
            }
          >
            <WrapperTitleKey>
              <WrapperIcon>
                <User size={22} />
              </WrapperIcon>
              <h3>{w.nbUser}</h3>
            </WrapperTitleKey>
            <WrapperNumber>
              {nbUser ? (
                <>
                  <StyledNumber>{numberWithSpaces(nbUser)}</StyledNumber>
                  <p>{w.fromBeginning}</p>
                </>
              ) : (
                <p>{w.noDatasAvailable}</p>
              )}
            </WrapperNumber>
          </Box>
          <Box
            gradient={
              'linear-gradient(90deg, rgba(254,142,136,1) 0%, rgba(254,148,138,1) 20%, rgba(252,159,140,1) 40%, rgba(250,169,143,1) 60%, rgba(250,181,146,1) 80%, rgba(248,189,150,1) 100%);'
            }
          >
            <WrapperTitleKey>
              <WrapperIcon>
                <BackInTime size={22} />
              </WrapperIcon>
              <h3>{w.duration}</h3>
            </WrapperTitleKey>
            <WrapperNumber>
              {time ? (
                loadDatas ? (
                  <Loader />
                ) : (
                  <>
                    <StyledNumber>{duration(time)}</StyledNumber>
                    <p>{w.fromBeginning}</p>
                  </>
                )
              ) : (
                <p>{w.noDatasAvailable}</p>
              )}
            </WrapperNumber>
          </Box>
          <Box
            gradient={
              'linear-gradient(90deg, rgba(234,172,192,1) 0%, rgba(226,165,195,1) 20%, rgba(206,152,202,1) 40%, rgba(193,145,205,1) 60%, rgba(182,136,208,1) 80%, rgba(169,127,213,1) 100%);'
            }
          >
            <WrapperTitleKey>
              <WrapperIcon>
                <MessageDots size={22} />
              </WrapperIcon>
              <h3>{w.message}</h3>
            </WrapperTitleKey>
            <WrapperNumber>
              {props.m.unreadMessages ? (
                <>
                  <StyledNumber>
                    {numberWithSpaces(props.m.unreadMessages)}
                  </StyledNumber>
                  <p>{w.now}</p>
                </>
              ) : (
                <p>{w.noDatasAvailable}</p>
              )}
            </WrapperNumber>
          </Box>
          <Box
            gradient={
              'linear-gradient(90deg, rgba(67,220,198,1) 0%, rgba(69,216,204,1) 20%, rgba(71,208,216,1) 40%, rgba(71,206,221,1) 60%, rgba(75,200,229,1) 80%, rgba(75,200,229,1) 100%);'
            }
          >
            <WrapperTitleKey>
              <WrapperIcon>
                <CheckCircle size={22} />
              </WrapperIcon>
              <h3>{w.published}</h3>
            </WrapperTitleKey>
            <WrapperNumber>
              {nbEvent ? (
                <>
                  <StyledNumber>{numberWithSpaces(nbEvent)}</StyledNumber>
                  <p>{w.now}</p>
                </>
              ) : (
                <p>{w.noDatasAvailable}</p>
              )}
            </WrapperNumber>
          </Box>
        </WrapperBox>
        <StyledLink to="/office/analytics">
          <SearchPlus size={16} />
          {w.seeAllStats}
        </StyledLink>
      </KeyDatas>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 95%;
  margin: 0 auto;
  padding-top: 2rem;
`;

const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  align-items: center;
  width: 100%;
`;

const WrapperBot = styled.div`
  display: flex;
  align-items: center;
`;

const NameBot = styled.p`
  color: #fff;
  font-size: 1.6em;
`;

const NameOrga = styled.p`
  color: #fff;
  font-size: 1.2em;
`;

const StyledLink = styled(NavLink)`
  background-color: #e06d6d;
  color: #fff;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  margin: 0 auto;
  transition: opacity 0.5s;
  > svg {
    margin-right: 0.2rem;
  }
  &:hover {
    opacity: 0.7;
  }
`;
const WrapperResources = styled.div`
  display: flex;
  border-radius: 7px;
  background: #fff;
  padding: 1.2rem;
  align-items: center;
  width: 100%;
  margin: 0 auto 4rem auto;
  justify-content: space-between;
  color: #2d2f7b;
`;

const WrapperDocumentText = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-right: 1rem;
  > svg {
    margin-right: 1rem;
  }
`;

const TittleKey = styled.h3`
  font-size: 1.5em;
  color: #fff;
`;

const WrapperTitleKey = styled.div`
  display: flex;
  padding: 1.4rem;
  border-bottom: 1px solid #fff;
  height: 50px;
  justify-content: center;
  align-items: center;
`;

const WrapperNumber = styled.div`
  padding: 1.4rem;
`;

const StyledNumber = styled.p`
  font-size: 1.6em;
  font-weight: 700;
`;

const KeyDatas = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WrapperFindOut = styled.div`
  border: 1px solid #2d2f7b;
  padding: 0.6rem 2.2rem;
  border-radius: 10px;
  color: #2d2f7b;

  > a:link,
  > a:hover,
  > a:visited,
  > a:active {
    color: #2d2f7b;
  }
`;

const WrapperBox = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem 0;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eee;
  border-radius: 7px;
  margin-right: 1rem;
  background: ${({ gradient }) => gradient};
  color: #fff;
  width: 290px;
  height: 190px;
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  font-size: ${({ fontSize }) => fontSize};
  cursor: auto;
  color: #2d2f7b;
  margin: 0.4rem;

  &::placeholder {
    color: #2d2f7b;
  }
`;

const WrapperEditable = styled.div`
  width: 100%;
`;

const ButtonInput = styled.input`
  outline: none;
  width: 50%;
  font-size: 1em;
  color: #2d2f7b;
  border: 1px solid #2d2f7b;
  padding: 0.6rem 2.2rem;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 0.4rem;

  &::placeholder {
    color: #2d2f7b;
  }
`;

const WrapperEdit = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.4rem;
`;

const WrapperEditableButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WrapperIcon = styled.div`
  margin-right: 1rem;
`;

const StyledEdit = styled(EditOutline)`
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.3);
    color: #e06d6d;
  }
`;

const StyledCheck = styled(Check)`
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.3);
    color: green;
  }
`;

const WrapperButtonLink = styled.div`
  margin-top: 0.6rem;
`;

const StyledUserCircle = styled(BotIcon)`
  margin-right: 1rem;
  color: #fff;
`;

const AlertContainer = styled.div`
  margin-right: 14px;
  margin-bottom: 5px;
  text-align: center;
`;

const WrapperUpload = styled.div`
  position: relative;
  cursor: pointer;
  transition: transform 0.5s;

  > svg {
    margin-top: 0.6rem;
  }
  &:hover {
    transform: scale(1.2);
  }
`;

const WrapperEditUpload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.6rem;
`;

const StyledImg = styled.img`
  margin-right: 0.6rem;
`;

export default withMain(Homepage);
