import React, { useReducer, useState, useEffect, useContext } from 'react';
import { _getKeyValue, _slugify } from '../../../_tools/other';
import {
  _findDup,
  listTemplate,
  lstTemplate,
  btnTemplate,
  moduleTemplate,
  _generatePath,
} from '../Reducer';
import { _uploadImg } from '../../../_api/upload';
import { _sendFeedback } from '../../../_tools/ui';
import {
  Button as Btn,
  Icon,
  Switch,
  Select,
  Field,
  Input,
  Textarea,
  Image,
} from '@ask-mona/ui';

import ScenariosContext from '../../../containers/scenarios/context/scenariosContext';

// Lst Element Reducer
const _reducerLst = (state, action) => {
  switch (action.type) {
    case 'onChange':
    case 'onChangeBtn':
    case 'onPopBtn':
      const { name, value, index } = action.payload;
      if (action.type === 'onChange') return { ...state, [name]: value };
      else if (action.type === 'onChangeBtn') {
        let newBtn = { ...value };
        if (newBtn.type === 'web_url') delete newBtn['payload'];
        else delete newBtn['url'];
        return {
          ...state,
          buttons: state.buttons.map((b, i) => (i === index ? newBtn : b)),
        };
      } else if (action.type === 'onPopBtn') {
        const { buttons, ...other } = state;
        return other;
      }
      return { ...state };
    case 'onAddBtn':
      return { ...state, buttons: [btnTemplate()] };
    case 'setEl':
      return { ...action.payload };
    default:
      return state;
  }
};

export default (props) => {
  const [hover, setMouseHover] = useState(false);
  const [edit, setEdit] = useState(false);
  const {
    m: { w },
    element,
    index,
    disabled,
    fix,
    advanced,
  } = props;
  const els = _getKeyValue(element, 'elements') || [];
  const btns = element.attachment.payload.buttons || [];
  let qr = element.quick_replies || null;

  const scenariosContext = useContext(ScenariosContext);

  return (
    <div
      className={`has-background-white border-grey-lighter inline-block rad-15px overflow-auto m-xs space-initial w-100p text-align-left`}
      style={{ width: '325px' }}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
    >
      {(hover || edit) && !disabled && !fix && (
        <div className="flex row space-between overflow-hidden animation-slideDown">
          <span></span>
          <Btn
            color="success is-size-7 is-outlined is-borderless"
            onClick={() => {
              if (edit) setEdit(false);
              else setEdit(true);
            }}
          >
            {edit ? <Icon icon="fas fa-check" /> : <Icon icon="fas fa-pen" />}
          </Btn>
        </div>
      )}
      {els.map((el, qIndex) => (
        <Element
          key={qIndex}
          {...props}
          els={els}
          el={el}
          qIndex={qIndex}
          edit={edit}
          onDelete={() => {
            let obj = listTemplate(
              els.filter((el, i) => i !== qIndex),
              btns,
              qr ? { quick_replies: qr } : {}
            );
            props.dispatch &&
              props.dispatch({
                type: 'onElemChange',
                payload: { index, value: obj },
                func: advanced
                  ? null
                  : scenariosContext.functionalitiesSubsbarFocus === 'content'
                  ? null
                  : scenariosContext.functionalitiesSubsbarFocus,
              });
            props.onChange && props.onChange(obj);
          }}
        />
      ))}
      {btns && btns.length === 1 && (
        <Button
          key={'ListBtn'}
          {...props}
          bIndex={0}
          el={btns[0]}
          hover={edit}
          pDispatch={props.dispatch}
          dispatch={(action) => {
            const { value } = action.payload;
            let newBtn = null;
            switch (action.type) {
              case 'onChangeBtn':
                newBtn = [value];
                if (newBtn[0].type === 'web_url') delete newBtn[0]['payload'];
                else delete newBtn[0]['url'];
                break;
              case 'onPopBtn':
                newBtn = [];
                break;
              default:
                break;
            }
            let obj = listTemplate(
              els,
              newBtn,
              qr ? { quick_replies: qr } : {}
            );
            props.dispatch &&
              props.dispatch({
                type: 'onElemChange',
                payload: { index, value: obj },
                func: advanced
                  ? null
                  : scenariosContext.functionalitiesSubsbarFocus === 'content'
                  ? null
                  : scenariosContext.functionalitiesSubsbarFocus,
              });
            props.onChange && props.onChange(obj);
          }}
        />
      )}
      {!btns.length && edit && !disabled && !fix && (
        <Btn
          color="warning expand is-size-7"
          onClick={() => {
            let obj = listTemplate(
              els,
              [btnTemplate()],
              qr ? { quick_replies: qr } : {}
            );
            props.dispatch &&
              props.dispatch({
                type: 'onElemChange',
                payload: { index, value: obj },
                func: advanced
                  ? null
                  : scenariosContext.functionalitiesSubsbarFocus === 'content'
                  ? null
                  : scenariosContext.functionalitiesSubsbarFocus,
              });
            props.onChange && props.onChange(obj);
          }}
        >
          {w.generic.addBtn}
        </Btn>
      )}
      {edit && els.length < 4 && !disabled && !fix && (
        <Btn
          color="success expand is-size-7"
          onClick={() => {
            let obj = listTemplate(
              els.concat(lstTemplate({}, {}, [])),
              btns,
              qr ? { quick_replies: qr } : {}
            );
            props.dispatch &&
              props.dispatch({
                type: 'onElemChange',
                payload: { index, value: obj },
                func: advanced
                  ? null
                  : scenariosContext.functionalitiesSubsbarFocus === 'content'
                  ? null
                  : scenariosContext.functionalitiesSubsbarFocus,
              });
            props.onChange && props.onChange(obj);
          }}
        >
          {w.generic.addLst}
        </Btn>
      )}
    </div>
  );
};

const Element = (props) => {
  const {
    m: { bot, w },
    focus,
    subfocus,
    element,
    el,
    lang,
    disabled,
    fix,
    qIndex,
    onDelete,
    edit,
    advanced,
  } = props;
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(_reducerLst, el);
  const { buttons, default_action, ...opt } = state;
  const { image_url, title, subtitle } = opt;

  const scenariosContext = useContext(ScenariosContext);

  useEffect(() => {
    dispatch({ type: 'setEl', payload: el });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus.key, subfocus ? subfocus.key : '-', lang]);

  useEffect(() => {
    if (!fix) {
      const btns = element.attachment.payload.buttons || [];
      const { els, index, qIndex } = props;
      const { buttons, default_action, ...opt } = state;
      let qr = element.quick_replies || null;
      let obj = listTemplate(
        els.map((el, i) =>
          i === qIndex ? lstTemplate(opt, default_action, buttons) : el
        ),
        btns,
        qr ? { quick_replies: qr } : {}
      );
      props.dispatch &&
        props.dispatch({
          type: 'onElemChange',
          payload: { index, value: obj },
          func: advanced
            ? null
            : scenariosContext.functionalitiesSubsbarFocus === 'content'
            ? null
            : scenariosContext.functionalitiesSubsbarFocus,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="border-grey-lighter flex row space-between p-sm items-center">
      {edit && qIndex !== 0 && qIndex !== 1 && (
        <div>
          <Btn
            color={qIndex === 0 || qIndex === 1 ? 'grey' : 'danger'}
            className="shrink is-outlined is-borderless is-size-7"
            onClick={onDelete}
            disabled={qIndex === 0 || qIndex === 1}
          >
            <Icon
              icon="fas fa-trash"
              className={qIndex === 0 || qIndex === 1 ? 'has-text-white' : ''}
            />
          </Btn>
        </div>
      )}
      <div className="flex column flex-start">
        <Input
          autoComplete="off"
          name="title"
          placeholder={w.scenario.title}
          className="has-text-weight-semibold has-text-grey is-size-7 is-transparent"
          value={title || ''}
          maxLength={edit ? 80 : ''}
          onChange={(e) => {
            dispatch({ type: 'onChange', payload: e.target });
          }}
        />
        {(subtitle || edit) && (
          <Textarea
            name="subtitle"
            className="animation-slideDown has-text-grey is-size-7 is-transparent"
            placeholder={w.scenario.subtitle}
            value={subtitle || ''}
            resize="auto"
            maxLength={edit ? 80 : ''}
            onChange={(e) => {
              dispatch({ type: 'onChange', payload: e.target });
            }}
          />
        )}
        {buttons &&
          buttons.map((b, bIndex) => (
            <Button
              key={bIndex}
              {...props}
              bordered="true"
              bIndex={bIndex}
              el={b}
              hover={edit}
              dispatch={dispatch}
              pDispatch={props.dispatch}
            />
          ))}
        {!buttons && edit && (
          <div className="flex center items-center">
            <Btn
              className="is-outlined is-borderless is-size-7 expand"
              onClick={() => dispatch({ type: 'onAddBtn' })}
            >
              {w.generic.addBtn}
            </Btn>
          </div>
        )}
      </div>
      <div style={{ width: '100px' }} className="lstImage">
        <Image
          squared="true"
          src={
            image_url ||
            (typeof bot.default_image === 'string' ? bot.default_image : '')
          }
          placeholder={w.generic.imgPlaceholder}
          name="image_url"
          className="contain"
          onChange={(e) => dispatch({ type: 'onChange', payload: e.target })}
          loading={loading ? 'true' : ''}
          fixed={disabled || fix ? 'true' : ''}
          onUpload={async (e) => {
            setLoading(true);
            _sendFeedback('warning', 'loader');
            await _uploadImg(
              bot.id,
              e,
              (res) => {
                setLoading(false);
                _sendFeedback('success', w.success.saved);
                dispatch({
                  type: 'onChange',
                  payload: { name: 'image_url', value: res.path },
                });
              },
              (err) => {
                setLoading(false);
                _sendFeedback('danger', w.error.general);
              }
            );
          }}
        />
      </div>
    </div>
  );
};

export const Button = (props) => {
  const [edit, setEdit] = useState(false);
  const {
    m: { w },
    el,
    bIndex,
    hover,
    dispatch,
    payloads,
    bordered,
    advanced,
    pDispatch,
    subfocus,
    focus,
    allIds,
    Bot,
    subModules,
  } = props;
  const { type, url, payload, title } = el;

  const [increments, setIncrements] = useState(() => {
    let increments = (payload || '').match(/\({.*(\[.*\]).*}\)/);
    if (increments) return JSON.parse(increments[1]);
    else return [];
  });

  useEffect(() => {
    let increments = (payload || '').match(/\({.*(\[.*\]).*}\)/);
    if (increments) setIncrements(JSON.parse(increments[1]));
    else setIncrements([]);
  }, [payload]);

  return (
    <div
      className={`has-text-info ${
        bordered ? 'border-info' : ''
      } pointer rad-5px ${
        !url && !payload ? 'has-background-danger-light' : ''
      }`}
    >
      <div className="flex column">
        {hover && (
          <div className="flex row space-between border-b-grey-lighter">
            <Btn
              color="danger"
              className="is-outlined is-borderless shrink is-size-7"
              onClick={() =>
                dispatch({ type: 'onPopBtn', payload: { index: bIndex } })
              }
            >
              <Icon icon="fas fa-trash" />
            </Btn>
            <Btn
              color="success is-outlined is-borderless shrink is-size-7"
              onClick={() => setEdit(!edit)}
            >
              {edit ? <Icon icon="fas fa-check" /> : <Icon icon="fas fa-pen" />}
            </Btn>
          </div>
        )}
        <div className="flex row has-text-info">
          <Input
            autoComplete="off"
            name="title"
            className="is-transparent text-align-center"
            placeholder={w.scenario.btnTitle}
            value={title || ''}
            onChange={(e) => {
              const { name, value } = e.target;
              dispatch({
                type: 'onChangeBtn',
                payload: { index: bIndex, value: { ...el, [name]: value } },
              });
            }}
          />
        </div>
      </div>
      {hover && edit && (
        <div className="has-background-primary-lighter p-xs">
          <Field
            subtitle={w.scenario.contentType}
            className="animation-slideDown"
          >
            <Select
              defaultValue={
                type === 'postback'
                  ? w.scenario.linkToBlock
                  : w.scenario.linkToWebURL
              }
              className="w-100p"
              items={[
                { id: 0, value: w.scenario.linkToWebURL, key: 'web_url' },
                { id: 1, value: w.scenario.linkToBlock, key: 'postback' },
              ]}
              onSelect={(item) => {
                dispatch({
                  type: 'onChangeBtn',
                  payload: { index: bIndex, value: { ...el, type: item.key } },
                });
              }}
            />
          </Field>
          {type === 'postback' ? (
            <React.Fragment>
              <Field
                subtitle={w.scenario.linkToModule}
                className="animation-slideDown"
              >
                <Select
                  className="w-100p"
                  defaultValue={
                    (
                      payloads.find(
                        (p) => (payload || '').replace(/\(.*\)/, '') === p.key
                      ) || {}
                    ).value
                  }
                  items={payloads}
                  onSelect={(item) => {
                    let adds = increments.length
                      ? `({"increment": [${increments.map(
                          (el) => `"${el}"`
                        )}]})`
                      : '';
                    dispatch({
                      type: 'onChangeBtn',
                      payload: {
                        index: bIndex,
                        value: { ...el, payload: `${item.key}${adds}` },
                      },
                    });
                  }}
                  create={advanced ? w.generic.create : ''}
                  onCreate={async (item) => {
                    const name = item.value;
                    if (!name) return;
                    let id = _slugify(name) || 'emojisolo';
                    if ('trigger' in focus) {
                      let folder =
                        subfocus && typeof subfocus === 'string'
                          ? subfocus.tag
                          : '';
                      id = _findDup(id, allIds);
                      let module = moduleTemplate({
                        id,
                        name,
                        tag: folder,
                        jump: focus.id,
                        disable: false,
                      });
                      const { key, ...other } = module;
                      let newFocus = {
                        ...focus,
                        trigger: { ...focus.trigger, [key]: other },
                      };
                      let res = await Bot._createModule({
                        path: _generatePath(focus).concat(['trigger', key]),
                        module: { [key]: other },
                      });
                      if (res.success) {
                        dispatch({
                          type: 'onChangeBtn',
                          payload: {
                            index: bIndex,
                            value: { ...el, payload: id },
                          },
                        });
                        pDispatch({ type: 'setFocus', payload: newFocus });
                        pDispatch({
                          type: 'setIds',
                          payload: allIds.concat(id),
                        });
                        _sendFeedback('success', w.success.saved);
                      } else _sendFeedback('danger', w.error.general);
                    } else {
                      let folder = subfocus
                        ? subfocus.tag.replace(/^.*\//, '')
                        : '';
                      id = _findDup(id, allIds);
                      let module = moduleTemplate({
                        id,
                        name,
                        tag: `${focus.id}/${folder || w.scenario.noFolder}`,
                        jump: 'FIRST',
                        disable: false,
                      });
                      _sendFeedback('warning', 'loader');
                      const { key, ...other } = module;
                      let res = await Bot._createModule({
                        path: _generatePath(),
                        module: { [key]: { ...other } },
                      });
                      if (res.success) {
                        dispatch({
                          type: 'onChangeBtn',
                          payload: {
                            index: bIndex,
                            value: { ...el, payload: id },
                          },
                        });
                        pDispatch({
                          type: 'setSubModules',
                          payload: subModules.concat(module),
                        });
                        pDispatch({
                          type: 'setIds',
                          payload: allIds.concat(id),
                        });
                        _sendFeedback('success', w.success.saved);
                      } else _sendFeedback('danger', w.error.general);
                    }
                  }}
                />
              </Field>
              {focus.dali &&
                payload &&
                focus &&
                focus.content &&
                focus.content.func.default.daliType === 'match' && (
                  <Field subtitle={w.scenario.increment}>
                    <Select
                      items={Object.keys(
                        focus.content.func['match'] || []
                      ).map((el, i) => ({ id: i, value: el, key: el }))}
                      onSelect={(item) => {
                        let newIncrements = increments
                          .filter((str) => el !== str)
                          .concat(item.value);
                        setIncrements(newIncrements);
                        let adds = newIncrements.length
                          ? `({"increment": [${newIncrements
                              .filter((str) => el !== str)
                              .map((el) => `"${el}"`)
                              .join(',')}]})`
                          : '';
                        dispatch({
                          type: 'onChangeBtn',
                          payload: {
                            index: bIndex,
                            value: {
                              ...el,
                              payload: `${payload.replace(
                                /\(.*\)/,
                                ''
                              )}${adds}`,
                            },
                          },
                        });
                      }}
                    />
                  </Field>
                )}
              {focus.dali &&
                payload &&
                focus &&
                focus.content &&
                focus.content.func.default.daliType === 'quiz' && (
                  <Field subtitle={w.scenario.answer} className="m-b-xs">
                    <Switch
                      checked={increments.length}
                      onChange={() => {
                        if (payload.match(/quiz/) && payload.match(/increment/))
                          dispatch({
                            type: 'onChangeBtn',
                            payload: {
                              index: bIndex,
                              value: {
                                ...el,
                                payload: `${payload.replace(/\(.*\)/, '')}`,
                              },
                            },
                          });
                        else
                          dispatch({
                            type: 'onChangeBtn',
                            payload: {
                              index: bIndex,
                              value: {
                                ...el,
                                payload: `${payload}({"increment": ["quiz"]})`,
                              },
                            },
                          });
                      }}
                    />
                  </Field>
                )}
              {increments &&
                payload &&
                focus &&
                focus.content &&
                focus.content.func.default.daliType === 'match' && (
                  <div>
                    {increments.map((inc, i) => {
                      return (
                        <div
                          className="flex row m-b-xs space-between"
                          key={inc}
                        >
                          <span className="has-text-primary-ter p-xs space-pre-line word-break-break-word rad-5px">
                            {inc}
                          </span>
                          <Btn
                            className="is-outlined is-borderless is-size-7"
                            color="danger"
                            onClick={() => {
                              let newIncrements = increments.filter(
                                (str) => inc !== str
                              );
                              setIncrements(newIncrements);
                              let adds = newIncrements.length
                                ? `({"increment": [${newIncrements
                                    .filter((str) => inc !== str)
                                    .map((inc) => `"${inc}"`)
                                    .join(',')}]})`
                                : '';
                              dispatch({
                                type: 'onChangeBtn',
                                payload: {
                                  index: bIndex,
                                  value: {
                                    ...el,
                                    payload: `${payload.replace(
                                      /\(.*\)/,
                                      ''
                                    )}${adds}`,
                                  },
                                },
                              });
                            }}
                          >
                            <Icon icon="fas fa-minus" className="is-size-7" />
                          </Btn>
                        </div>
                      );
                    })}
                  </div>
                )}
            </React.Fragment>
          ) : (
            <Field
              subtitle={w.scenario.redirectionURL}
              className="animation-slideDown"
            >
              <Input
                autoComplete="off"
                name="url"
                value={url}
                onChange={(e) => {
                  const { name, value } = e.target;
                  dispatch({
                    type: 'onChangeBtn',
                    payload: { index: bIndex, value: { ...el, [name]: value } },
                  });
                }}
              />
            </Field>
          )}
        </div>
      )}
    </div>
  );
};
