const Code = (wat, ref) =>
  `<script src="https://cdn.askmonastudio.com/webchat/askmona.js"></script>
    <script defer>
      const url = new URL(window.location.href);
      Askmona.launch.webchat({
        wat: "${wat}",
        referral: ${ref ? `"${ref}"` : "url.searchParams.get('referral')"}
      });
    </script>`;

export default Code;
