class Schema {
  client: any;

  constructor(client: any) {
    this.client = client;
  }

  mutate = async (mutation: any, variables?: object, opts?: object) =>
    await this.client.mutate({ mutation, variables, ...opts });

  query = async (query: any, variables?: object, opts?: object) =>
    await this.client.query({ query, variables, ...opts });
}

export default Schema;
