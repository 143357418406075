import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { buttonTemplate, btnTemplate } from '../Reducer';
import { Textarea, Button, Icon } from '@ask-mona/ui';

import ScenariosContext from '../../../containers/scenarios/context/scenariosContext';

export default ({
  m: { w },
  focus,
  subfocus,
  lang,
  element,
  dispatch,
  index,
  fix,
  advanced,
  random,
  arrayOfElements,
  newIndex,
  ...props
}) => {
  const [focused, setFocus] = useState(false);
  const [text, setText] = useState(element['text']);

  const scenariosContext = useContext(ScenariosContext);

  useEffect(() => {
    if (!focused) {
      setText('reloadTextarea');
      setTimeout(() => setText(element['text']), 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus.key, subfocus ? subfocus.key : '-', lang, element['text']]);

  if (text === 'reloadTextarea') return null;

  return (
    <Container random={random} className="m-xs border-primary-lighter rad-15px">
      <div
        className={`w-auto p-sm has-text-white rad-15px has-background-${
          focused ? 'primary-bis' : 'primary-ter'
        } flex center`}
      >
        <Textarea
          disabled={fix ? true : false}
          resize="auto"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={text || ''}
          onChange={(e) => {
            setText(e.target.value);
            let newArray = [];
            if (random) {
              newArray = [...arrayOfElements];
              newArray[newIndex] = [{ ...element, text: e.target.value }];
            }

            dispatch({
              type: 'onElemChange',
              payload: {
                index,
                value: random ? newArray : { ...element, text: e.target.value },
                func: advanced
                  ? null
                  : scenariosContext.functionalitiesSubsbarFocus === 'content'
                  ? null
                  : scenariosContext.functionalitiesSubsbarFocus,
              },
            });
          }}
          style={{ width: '90%' }}
          className="is-transparent"
        />
      </div>
      {!fix && (
        <div className="flex center items-center">
          <Button
            className="is-outlined is-borderless is-size-7 expand"
            onClick={() => {
              let newArray = [];
              if (random) {
                newArray = [...arrayOfElements];
                let obj = buttonTemplate(
                  text,
                  [btnTemplate()],
                  element.quick_replies
                    ? { quick_replies: element.quick_replies }
                    : {}
                );
                newArray[newIndex] = [obj];
              }

              dispatch({
                type: 'onElemChange',
                payload: {
                  index,
                  value: random
                    ? newArray
                    : buttonTemplate(
                        text,
                        [btnTemplate()],
                        element.quick_replies
                          ? { quick_replies: element.quick_replies }
                          : {}
                      ),
                  func: advanced
                    ? null
                    : scenariosContext.functionalitiesSubsbarFocus === 'content'
                    ? null
                    : scenariosContext.functionalitiesSubsbarFocus,
                },
              });
            }}
          >
            {w.generic.addBtn}
          </Button>
        </div>
      )}
      {random && (
        <RemoveElementButton
          onClick={() => {
            let newArray = [...arrayOfElements];
            newArray = newArray.filter((el) => el[0] !== element);
            dispatch({
              type: 'onElemChange',
              payload: {
                index,
                value: newArray,
                func: null,
              },
            });
          }}
        >
          <Icon icon="fas fa-times" />
        </RemoveElementButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: ${(p) => (p.random ? '200px' : '50%')};
  position: relative;
`;

const RemoveElementButton = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  color: gray;
  background-color: #ececec;
  border-radius: 20px;
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
`;
