import React, { useState, useEffect } from 'react';
import { Switch } from '@ask-mona/ui';
import { _getKeyValue } from '../../_tools/other';
import Affiliator from './scheduler/Affiliator';
import Schedule from '../../components/scheduler/';
import DateRange from '../../components/DateRange';

export default (props) => {
  const {
    bot,
    event,
    fct,
    m: { w },
  } = props;
  const oph = _getKeyValue(event, 'opening_hours');
  const date_start = _getKeyValue(event, 'date_start');
  const date_end = _getKeyValue(event, 'date_end');
  const [hasPeriod, setPeriod] = useState(
    date_start || date_end ? true : false
  );
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setPeriod(false);
    setTimeout(() => {
      setPeriod(date_start || date_end ? true : false);
    }, 200);
  }, [date_start, date_end]);

  return (
    <div className="w-100p has-text-primary-ter relative overflow-scroll p-md">
      {bot.places && bot.places.filter((p) => p.opening_hours).length > 0 && (
        <Affiliator
          {...props}
          isOpen={isOpen}
          toggleOpen={() => setOpen(!isOpen)}
        />
      )}
      <div className="flex items-center m-b-md">
        <span className="m-r-md">{w.event.affPeriod}</span>
        {!hasPeriod && (
          <Switch
            color="seconday"
            className="m-l-md"
            defaultChecked={hasPeriod}
            onChange={() => setPeriod(!hasPeriod)}
          />
        )}
        {hasPeriod && (
          <DateRange
            anchorDirection="right"
            showClearDates
            small
            onChange={fct.setDates}
            start={date_start ? date_start : ''}
            end={date_end ? date_end : ''}
          />
        )}
      </div>
      <div className="p-t-none">
        <Schedule
          event="true"
          oph={oph}
          date_start={date_start}
          date_end={date_end}
          set={fct.setOph}
        />
      </div>
    </div>
  );
};
