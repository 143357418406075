import opening_hours from 'opening_hours';
import moment from 'moment';
import { fullMonths, languages } from '../translations/';

//const RegPers = new RegExp(/\d{4}\s*[a-z]{3}\s*\d{2}/);
//const RegDay = new RegExp(/Mo|Tu|We|Th|Fr|Sa|Su/ig);
//const RegDays = new RegExp(/(Mo|Tu|We|Th|Fr|Sa|Su)(-?(Mo|Tu|We|Th|Fr|Sa|Su))?/igm);
//const RegYea = new RegExp(/\d{4}/i);
//const RegDats = new RegExp(/(\d{4}\s*)?[a-z]{3}(\s*\d{2})?(\s*-)?/ig);
const RegDat = new RegExp(/[a-z]{3}\s*\d{2}?$/gi);
const RegOff = new RegExp(/off/i);
const RegMon = new RegExp(/[a-z]{3}/gi);
const RegNum = new RegExp(/\d{2}/gi);
const RegHou = new RegExp(/\d{2}:\d{2}-\d{2}:\d{2}/gi);
const RegCom = new RegExp(/".*"/gi);
const RegPer = new RegExp(/[a-z]{3}\s*(\d{2})?\s*-\s*[a-z]{3}(\d{2})?/gi);
const RegGen = new RegExp(/general/gi);

//======================================================================
// Constants BREAK OPH
//======================================================================
const months = {
  jan: 0,
  feb: 1,
  mar: 2,
  apr: 3,
  may: 4,
  jun: 5,
  jul: 6,
  aug: 7,
  sep: 8,
  oct: 9,
  nov: 10,
  dec: 11,
};
const weekdays = { su: 0, mo: 1, tu: 2, we: 3, th: 4, fr: 5, sa: 6 };
const wm = Object.keys(months);
const wd = Object.keys(weekdays);

export const _isUnique = (oph) => {
  let reg = new RegExp(/(?=(Mo|Tu|We|Th|Fr|Sa|Su|-(?!\d{2}:)))/, 'ig');
  return !reg.test(oph);
};

export const _hasDates = (oph) => {
  let reg = new RegExp(/\d{4}([a-z]{3}\d{2})/);
  return !reg.test(oph);
};

export const _isWithinDates = (oph = '', from = '', to = '') => {
  if (from && to && oph) {
    try {
      let parts = oph.split(';');
      for (let i = 0, len = parts.length; i < len; i++) {
        let oh = new opening_hours(parts[i]);
        let intervals = oh.getOpenIntervals(from, to);
        if (!intervals.length) return false;
      }
    } catch (err) {
      return false;
    }
  }
  return true;
};

export const _getRange = (oph) => {
  let date_start = null;
  let date_end = null;
  let isPerm = oph
    .split(';')
    .filter((o) => {
      if (!o) return false;
      else return !o.match(/off/gi);
    })
    .some((el) => {
      let hasNoYear = el.match(/^[a-z]{3}\d{2}/gi);
      let hasNoDate = el.match(/^\s*(Mo|Tu|We|Th|Fr|Sa|Su)/gi);
      return hasNoYear || hasNoDate;
    });
  if (!isPerm) {
    let stacks = oph.split(/[; -]/).filter((str) => {
      return str.match(/^(?!off)\d{4}[a-z]{3}\d{2}$/i);
    });
    stacks = stacks.map((date) => {
      let str = date.toLowerCase();
      let year = str.match(/^\d{4}/)[0];
      let month = str.match(/[a-z]{3}/i)[0];
      let day = str.match(/\d{2}$/i)[0];
      return moment(
        `${year}-${(months[month] + 1).toString().padStart(2, '0')}-${day}`
      );
    });
    stacks.forEach((date) => {
      if (!date_start && !date_end) {
        date_start = date;
        date_end = date;
      } else if (date_start > date) date_start = date;
      else if (date_end < date) date_end = date;
    });
    if (date_start && date_end) {
      date_start = date_start.format('YYYY-MM-DD');
      date_end = date_end.format('YYYY-MM-DD');
    }
  }
  return [date_start, date_end];
};

export const closingDates = [
  { id: 0, value: '1er Janvier', oph: 'Jan01 off' },
  { id: 1, value: '1er Mai', oph: 'May01 off' },
  { id: 2, value: '8 Mai', oph: 'May08 off' },
  { id: 3, value: '14 Juillet', oph: 'Jul14 off' },
  { id: 4, value: '15 Aout', oph: 'Aug15 off' },
  { id: 5, value: '1er Novembre', oph: 'Nov01 off' },
  { id: 6, value: '11 Novembre', oph: 'Nov11 off' },
  { id: 7, value: '25 Décembre', oph: 'Dec25 off' },
];

export const _mergeOph2 = (
  { dates, blocks, comment, off },
  addComment,
  addOff
) => {
  let str = '';
  dates.forEach((item, i) => {
    str = `${str}${' '}`;
    if (item.startDate) str = `${str}${_momentToOph(item.startDate)}`;
    if (item.startDate && item.endDate) {
      str = `${str}${item.startDate ? '-' : ''}${_momentToOph(item.endDate)}`;
    }
  });

  if (blocks[0].hours[0].start !== false) {
    blocks.forEach(({ days, hours }, i) => {
      let filteredDays = [...new Set(days)];
      filteredDays.forEach((d, i) => {
        str = `${str}${i === 0 ? ' ' : ','}${wd[d]}`;
      });
      if (hours.length > 0) {
        hours.forEach(({ start, end }, i) => {
          str = `${str}${i === 0 ? ' ' : ','}${start}-${end}`;
        });
      } else {
        str = `${str}${i === 0 ? ' ' : ','}${hours.start}-${hours.end}`;
      }
    });
  }

  if (!str) return '';
  if (comment) str = `${str} ${comment}`;
  if (addComment) {
    const reg = new RegExp(`".*${addComment}.*"`, 'ig');
    if (!str.match(reg)) {
      if (str.match(RegCom)) str = str.replace(/"(.*)"/, `"$1/${addComment}"`);
      else str = `${str} "${addComment}"`;
    }
  }
  if (off || addOff) str = `${str} off`;
  if (_checkOph(str)) {
    return str;
  } else {
    return '';
  }
};

// Allows to transform part elements in a single oph element
export const _mergeOph = (
  { dates, blocks, comment, off },
  addComment,
  addOff,
  perm = false
) => {
  let str = '';
  dates.forEach((date) => {
    const { startDate, endDate } = date;
    let hasYear = perm;
    if (startDate && endDate)
      hasYear =
        startDate[2] !== endDate[2] || startDate[3] !== endDate[3] || hasYear;
    if (startDate)
      str = `${str ? `${str} ` : ''}${_momentToOph(startDate, hasYear)}`;
    if (endDate)
      str = `${str}${startDate ? '-' : ''}${_momentToOph(endDate, hasYear)}`;
  });
  blocks.forEach(({ days, hours }, i) => {
    if (i > 0) str = `${str},`;
    let filteredDays = [...new Set(days)];
    filteredDays.forEach((d, i) => {
      str = `${str}${i === 0 ? ' ' : ','}${wd[d]}`;
    });
    hours.forEach(({ start, end }, i) => {
      str = `${str}${i === 0 ? ' ' : ','}${start}-${end}`;
    });
  });
  if (!str) return '';
  if (!comment === null) str = `${str} ${comment}`;
  if (addComment) {
    const reg = new RegExp(`".*${addComment}.*"`, 'ig');
    if (!str.match(reg)) {
      if (str.match(RegCom)) str = str.replace(/"(.*)"/, `"$1/${addComment}"`);
      else str = `${str} "${addComment}"`;
    }
  }
  if (off || addOff) str = `${str} off`;
  if (_checkOph(str)) return str;
  else {
    return '';
  }
};

export const _breakOph2 = (oph) => {
  try {
    let oh = new opening_hours(oph);
    let pretty = oh.prettifyValue();
    let tokens = _tokenize(pretty.toLowerCase());
    tokens = _tokenizeDates(tokens);
    let result = _parse2(tokens, 0);
    return result;
  } catch (err) {
    return {
      dates: [],
      blocks: [],
      comment: '',
      off: false,
    };
  }
};

const _parse2 = (tokens, at) => {
  let dates = [];
  let days = [];
  let hours = [];
  let blocks = [];
  let bIndex = 0;
  let comment = '';
  let off = false;
  while (at < tokens.length) {
    if (_matchTokens(tokens, at, 'date')) at = _parseDate(tokens, at, dates);
    else if (_matchTokens(tokens, at, 'weekday'))
      at = _parseDays(tokens, at, days);
    else if (_matchTokens(tokens, at, 'hours')) {
      at = _parseHours(tokens, at, hours);
      if (
        !_matchTokens(tokens, at, ',', 'hours') &&
        !_matchTokens(tokens, at, 'hours')
      ) {
        blocks.push({ index: bIndex, days: [...days], hours: [...hours] });
        bIndex++;
        days = [];
        hours = [];
      }
    } else if (_matchTokens(tokens, at, 'comment')) {
      comment = tokens[at][0];
      at++;
    } else if (_matchTokens(tokens, at, 'off')) {
      off = true;
      at++;
    } else at++;
  }

  if (days.length || hours.length)
    blocks.push({ index: bIndex, days: [...days], hours: [...hours] });
  // if (dates.length < 2)
  //   dates = dates[0] || {startDate: null, endDate: null, hasYear: false};
  return {
    dates,
    blocks,
    comment,
    off,
  };
};

export const _breakOph = (oph) => {
  try {
    let oh = new opening_hours(oph);
    let pretty = oh.prettifyValue();
    let tokens = _tokenize(pretty.toLowerCase());
    tokens = _tokenizeDates(tokens);
    let result = _parse(tokens, 0);
    return result;
  } catch (err) {
    return {
      dates: [{ startDate: null, endDate: null, hasYear: false }],
      blocks: [],
      comment: '',
      off: false,
    };
  }
};

const _parse = (tokens, at) => {
  let dates = [];
  let days = [];
  let hours = [];
  let blocks = [];
  let bIndex = 0;
  let comment = '';
  let off = false;
  while (at < tokens.length) {
    if (_matchTokens(tokens, at, 'date')) at = _parseDate(tokens, at, dates);
    else if (_matchTokens(tokens, at, 'weekday'))
      at = _parseDays(tokens, at, days);
    else if (_matchTokens(tokens, at, 'hours')) {
      at = _parseHours(tokens, at, hours);
      if (
        !_matchTokens(tokens, at, ',', 'hours') &&
        !_matchTokens(tokens, at, 'hours')
      ) {
        blocks.push({ index: bIndex, days: [...days], hours: [...hours] });
        bIndex++;
        days = [];
        hours = [];
      }
    } else if (_matchTokens(tokens, at, 'comment')) {
      comment = tokens[at][0];
      at++;
    } else if (_matchTokens(tokens, at, 'off')) {
      off = true;
      at++;
    } else at++;
  }

  if (days.length || hours.length)
    blocks.push({ index: bIndex, days: [...days], hours: [...hours] });
  dates =
    dates.length > 0
      ? dates
      : [{ startDate: null, endDate: null, hasYear: false }];
  return {
    dates,
    blocks,
    comment,
    off,
  };
};

const _parseDate = (tokens, at, dates) => {
  let start = tokens[at][0];
  let hasYear = tokens[at][2] === 'hasYear' ? true : false;
  if (_matchTokens(tokens, at, 'date', '-', 'date')) {
    let end = tokens[at + 2][0];
    if (moment(start).diff(end, 'minute') >= 0)
      end = moment(end).add(1, 'year').format('YYYY-MM-DD');
    dates.push({ startDate: start, endDate: end, hasYear });
    return at + 3;
  }
  dates.push({ startDate: start, hasYear });
  return at + 1;
};

const _parseDays = (tokens, at, days) => {
  let dStart = tokens[at][0];
  if (_matchTokens(tokens, at, 'weekday', '-', 'weekday')) {
    let dEnd = tokens[at + 2][0];
    for (let i = 0; i < 7 && (dStart + i) % 7 !== dEnd; i++)
      days.push((dStart + i) % 7);
    days.push(dEnd);
    return at + 3;
  }
  days.push(tokens[at][0]);
  return at + 1;
};

const _parseHours = (tokens, at, hours) => {
  let hStart = tokens[at][0];
  if (_matchTokens(tokens, at, 'hours', '-', 'hours')) {
    let hEnd = tokens[at + 2][0];
    hours.push({ start: hStart, end: hEnd });
    return at + 3;
  }
  hours.push({ start: hStart });
  return at + 1;
};

const _matchTokens = (tokens, at, ...args) => {
  if (at + args.length > tokens.length) return false;
  for (var i = 0; i < args.length; i++) {
    if (tokens[at + i][1] !== args[i]) return false;
  }
  return true;
};

const _tokenizeDates = (tokens) => {
  let year = moment().format('YYYY');
  let newTokens = [];
  let at = 0;
  while (at < tokens.length) {
    if (_matchTokens(tokens, at, 'years', 'month', 'number')) {
      let date = tokens.slice(at, at + 3).map((p) => p[0]);
      newTokens.push([date.join('-'), 'date', 'hasYear']);
      at += 3;
    } else if (_matchTokens(tokens, at, 'month', 'number')) {
      let date = [year, ...tokens.slice(at, at + 2).map((p) => p[0])];
      newTokens.push([date.join('-'), 'date']);
      at += 2;
    } else if (_matchTokens(tokens, at, 'month')) {
      let date = [year, ...tokens.slice(at, at + 1).map((p) => p[0]), '01'];
      newTokens.push([date.join('-'), 'date']);
      at += 1;
    } else {
      newTokens.push(tokens[at]);
      at++;
    }
  }
  return newTokens;
};

/*eslint-disable */
const _tokenize = (value) => {
  let tokens = [];
  while (value !== '') {
    let tmp;
    if ((tmp = value.match(/^(?:week|24\/7|off)/))) {
      tokens.push([tmp[0], tmp[0]]);
      value = value.substr(tmp[0].length);
    } else if (
      (tmp = value.match(
        /^(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/
      ))
    ) {
      tmp = ('0' + (months[tmp[0]] + 1)).slice(-2);
      tokens.push([tmp, 'month']);
      value = value.substr(3);
    } else if ((tmp = value.match(/^(?:mo|tu|we|th|fr|sa|su)/))) {
      tokens.push([weekdays[tmp[0]], 'weekday']);
      value = value.substr(2);
    } else if ((tmp = value.match(/^\d{2}:\d{2}/))) {
      tokens.push([tmp[0], 'hours']);
      value = value.substr(tmp[0].length);
    } else if ((tmp = value.match(/^\d{4}/))) {
      tokens.push([tmp[0], 'years']);
      value = value.substr(tmp[0].length);
    } else if ((tmp = value.match(/^".*"/))) {
      tokens.push([tmp[0], 'comment']);
      value = value.substr(tmp[0].length);
    } else if ((tmp = value.match(/^\d+/))) {
      // number
      tokens.push([tmp[0], 'number']);
      value = value.substr(tmp[0].length);
    } else if (value.match(/^\s/)) {
      // whitespace is ignored
      value = value.substr(1);
    } else if (value.match(/^[:.]/)) {
      // other single-character tokens
      tokens.push([value[0], 'timesep']);
      value = value.substr(1);
    } else {
      // other single-character tokens
      tokens.push([value[0], value[0]]);
      value = value.substr(1);
    }
  }
  return tokens;
};
/*eslint-enable */

// UTILS_OPH_FUNCTION
export const _checkOph = (oph) => {
  try {
    new opening_hours(oph);
  } catch (err) {
    console.log(err);
    return false;
  }
  return true;
};

export const _addComment = (oph, comment) => {
  let str = oph;
  const reg = new RegExp(`".*${comment}.*"`, 'ig');
  if (!str.match(reg)) {
    if (str.match(RegCom)) str = str.replace(/"(.*)"/, `"$1/${comment}"`);
    else str = `${str} "${comment}"`;
  }
  return str;
};

export const _removeComment = (oph, comment) => {
  let str = oph;
  const reg = new RegExp(`/?${comment}`, 'ig');
  if (str.match(reg)) str = str.replace(reg, '');
  str.replace(/"\s*"/, '');
  return str;
};

// CONVERT_OPH_FUNCTION
export const _periodToStr = (part = '', lang = 'fr') => {
  let [ds, de] = part.split('-');
  ds = _dateToStr(ds);
  de = _dateToStr(de);
  return `${de ? 'du ' : 'le '}${ds}${de ? ' au ' : ''}${de}`;
};

export const _dateToStr = (part = '', lang = 'fr') => {
  let str = part;
  try {
    str = str.replace(RegHou, '');
    str = str.replace(RegCom, '');
    const m = str.match(RegMon) || [];
    const d = str.match(RegNum) || [];
    str = `${d[2]} ${fullMonths[months[m[0]]][languages[lang] - 1]} ${d[0]}${
      d[1]
    }`;
  } catch (err) {
    console.log(err.message);
    str = '';
  }
  return str;
};

export const _momentToOph = (date, hasYear = false) => {
  try {
    let tokens = date.split('-');
    let m = wm[Number(tokens[1]) - 1];
    return `${hasYear ? tokens[0] : ''}${m}${tokens[2]}`;
  } catch (err) {
    return '';
  }
};

// SORT_OPH_FUNCTION
export const _sortOph = (oph = '', type = 'basique', chronos = false) => {
  switch (type) {
    case 'basique':
      return _sortBasique(oph, chronos);
    case 'typed':
      return _sortTyped(oph, chronos);
    default:
      break;
  }
  return {};
};

const _sortChronos = (arr = []) => {
  let stack = [...arr];
  // First sort chronologically
  stack.sort((a, b) => {
    let $a = _breakOph(a.oph).dates[0].startDate;
    let $b = _breakOph(b.oph).dates[0].startDate;
    if ($a && $b) {
      let diff = moment($a).diff($b);
      if (diff < 0) return -1;
      if (diff > 0) return 1;
    }
    return 0;
  });
  //Split in two arrays
  let after = [];
  let before = [];
  let curr = moment().subtract(7, 'days');
  for (let i = 0, len = stack.length; i < len; i++) {
    const { startDate, endDate } = _breakOph(stack[i].oph).dates[0];
    if (curr.diff(endDate || startDate) < 0) after.push(stack[i]);
    else before.push(stack[i]);
  }
  return [...after, ...before];
};

const _sortBasique = (oph = '') => {
  const global = [];
  const period = [];
  const unique = [];
  const open = [];
  const off = [];
  try {
    const parts = oph
      .split(';')
      .filter((o) => o)
      .filter(_checkOph);
    parts.forEach((p, i) => {
      if (!p.match(RegOff)) {
        open.push({ index: i, oph: p });
        if (p.match(RegPer)) period.push({ index: i, oph: p });
        else if (p.match(RegDat)) unique.push({ index: i, oph: p });
        else global.push({ index: i, oph: p });
      } else off.push({ index: i, oph: p });
    });
  } catch (err) {
    console.log(err);
  }
  return {
    global,
    period,
    unique,
    off,
    open,
  };
};

// Sort OPH in general, exception and special parts
const _sortTyped = (oph = '', chronos) => {
  const general = [];
  const exception = [];
  const special = [];
  try {
    const parts = oph
      .split(';')
      .filter((o) => o)
      .filter(_checkOph);
    parts.forEach((p, i) => {
      if (p.match(RegCom) && p.match(/general/))
        general.push({ index: i, oph: p });
      else if (p.match(RegCom) && p.match(/special/)) {
        special.push({ index: i, oph: p });
        exception.push({ index: i, oph: p });
      } else exception.push({ index: i, oph: p });
    });
  } catch (err) {
    console.log(err);
  }
  return {
    general: chronos ? _sortChronos(general) : general,
    exception: chronos ? _sortChronos(exception) : exception,
    special: chronos ? _sortChronos(special) : special,
  };
};

export const _reOrder = (a, b) => {
  if (a.off && !b.off) return 1;
  else if (!a.off && b.off) return -1;
  else {
    if (a.oph.match(RegGen) && !b.oph.match(RegGen)) return -1;
    if (!a.oph.match(RegGen) && b.oph.match(RegGen)) return 1;
    else return 0;
  }
};
