import React, { Component } from 'react';
import { withMain } from '../hoc/main';
import { withApi } from '../hoc/api';
import { Query } from 'react-apollo';
import { GET_ALL_USERS } from '../_api/user';
import Foundations from '../components/base/Foundations';
import Sidebar, { Sub } from '../components/base/Sidebar';
import Header from '../components/base/Header';
import ErrorBoundary from '../components/ErrorBoundary';
import Loader from '../components/Loader';
import User from './account/User';
import Team from './account/Team';
import { account as sections } from '../_tools/ui';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subID: 0,
      loading: false,
    };
  }

  render() {
    const {
      m: { w },
    } = this.props;
    const { subID } = this.state;
    return (
      <Foundations
        aside={
          <Sidebar
            c={this.state}
            addons={sections.map((s) => (
              <Sub
                noarrow={true}
                key={s.id}
                sub={{ ...s, title: w.subsections[s.w] }}
                active={subID === s.id}
                onClick={() => this.setState({ subID: s.id })}
              />
            ))}
          />
        }
        header={
          <Header
            title={`${w.generic.gestion}`}
            subtitle={`${subID === 0 ? w.account.user : w.account.team}`}
          />
        }
      >
        <Gate {...this.props} {...this.state} />
      </Foundations>
    );
  }
}

const Gate = (props) => {
  const {
    subID,
    m: {
      bot: { organization_id },
    },
  } = props;
  return (
    <Query
      query={GET_ALL_USERS}
      variables={{ organization_id: organization_id }}
      skip={!organization_id}
    >
      {({ loading, error, data, ...other }) => {
        if (loading) return <Loader className="has-text-primary-ter" />;
        if (error) return null;
        if (subID === 0)
          return (
            <ErrorBoundary>
              <User {...props} data={data} other={other} />
            </ErrorBoundary>
          );
        else if (subID === 1)
          return (
            <ErrorBoundary>
              <Team {...props} data={data} other={other} />
            </ErrorBoundary>
          );
      }}
    </Query>
  );
};

export default withMain(withApi(Account));
