import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Field, Image, Icon, Input } from '@ask-mona/ui';
import Video from '../../../components/Video';
import { _uploadImg, _uploadVid } from '../../../_api/upload';
import { _sendFeedback } from '../../../_tools/ui';
import { _getKeyValue } from '../../../_tools/other';
import { mediaTemplate, btnTemplate } from '../Reducer';
import { Button } from '@ask-mona/ui';
import { Button as GenericBtn } from './Generic';

import ScenariosContext from '../../../containers/scenarios/context/scenariosContext';

export default (props) => {
  const { element } = props;
  const type = element['attachment']['payload']['elements'][0]['media_type'];

  if (type === 'video') return <MediaVideo {...props} />;
  else if (type === 'image') return <MediaImage {...props} />;
  else return null;
};

const MediaVideo = (props) => {
  const {
    m: { bot, w },
    element,
    dispatch,
    index,
    fix,
    advanced,
    random,
    newIndex,
    arrayOfElements,
  } = props;
  const el = element['attachment']['payload']['elements'][0];
  const [loading, setLoading] = useState(false);

  const [addBtn, setAddBtn] = useState(!!_getKeyValue(el, 'buttons'));
  let url = _getKeyValue(el, 'url') || '';
  let attachment_id = _getKeyValue(el, 'attachment_id') || '';
  let attachmentURL = `https://cdn.${process.env.REACT_APP_ASKMONA}/${bot.id}/${attachment_id}.mp4`;
  if (!url && attachment_id) url = attachmentURL;

  const qr = element.quick_replies || null;

  const scenariosContext = useContext(ScenariosContext);

  const updateMedia = (attachmentID, url, btn) => {
    let newArray = [];
    if (random) {
      newArray = [...arrayOfElements];
      newArray[newIndex] = [
        mediaTemplate(
          'video',
          attachmentID,
          !attachmentID ? url : null,
          qr ? { quick_replies: qr } : {},
          btn ? btnTemplate(btn) : null
        ),
      ];
    }
    dispatch({
      type: 'onElemChange',
      payload: {
        index,
        value: random
          ? newArray
          : mediaTemplate(
              'video',
              attachmentID,
              !attachmentID ? url : null,
              qr ? { quick_replies: qr } : {},
              btn ? btnTemplate(btn) : null
            ),
        func: advanced
          ? null
          : scenariosContext.functionalitiesSubsbarFocus === 'content'
          ? null
          : scenariosContext.functionalitiesSubsbarFocus,
      },
    });
    if (btn) setAddBtn(true);
    else setAddBtn(false);
  };

  return (
    <Container random={random} className="border-primary-lighter m-xs rad-15px">
      <CustomField>
        <Video
          src={url}
          placeholder={w.generic.vidPlaceholder}
          onUpload={async (e) => {
            setLoading(true);
            _sendFeedback('warning', 'loader');
            await _uploadVid(
              bot.id,
              e.target.value,
              (res) => {
                setLoading(false);
                _sendFeedback('success', w.success.saved);
                updateMedia(res.id, null, addBtn ? el['buttons'][0] : null);
              },
              (err) => {
                setLoading(false);
                _sendFeedback('danger', w.error.general);
              }
            );
          }}
          onChange={(e) => {
            if (e.target.type === 'id')
              updateMedia(
                e.target.value,
                null,
                addBtn ? el['buttons'][0] : null
              );
            else
              updateMedia(
                null,
                e.target.value,
                addBtn ? el['buttons'][0] : null
              );
          }}
          fixed={loading || fix ? 'true' : ''}
          loading={loading ? 'true' : ''}
        />
      </CustomField>
      {!addBtn && (
        <Button
          className="is-outlined is-borderless is-size-7 expand"
          onClick={(e) => {
            updateMedia(attachment_id, url, {});
          }}
        >
          {w.generic.addBtn}
        </Button>
      )}
      {addBtn && el['buttons'] && (
        <React.Fragment>
          <GenericBtn
            {...props}
            bIndex={0}
            el={el['buttons'][0]}
            hover={true}
            dispatch={(el) => {
              updateMedia(attachment_id, url, el.payload.value);
            }}
          />
          <Button
            className="is-outlined is-borderless is-size-7 expand"
            onClick={(e) => {
              updateMedia(attachment_id, url);
            }}
          >
            {w.generic.delBtn}
          </Button>
        </React.Fragment>
      )}
      {random && (
        <RemoveElementButton
          onClick={() => {
            let newArray = [...arrayOfElements];
            newArray = newArray.filter((el) => el[0] !== element);
            dispatch({
              type: 'onElemChange',
              payload: {
                index,
                value: newArray,
                func: null,
              },
            });
          }}
        >
          <Icon icon="fas fa-times" />
        </RemoveElementButton>
      )}
    </Container>
  );
};

const MediaImage = (props) => {
  const {
    m: { bot, w },
    element,
    dispatch,
    index,
    fix,
    advanced,
    random,
    newIndex,
    arrayOfElements,
  } = props;
  const el = element['attachment']['payload']['elements'][0];
  const [loading, setLoading] = useState(false);
  const [alt, setAlt] = useState('');

  useEffect(() => {
    const altImage = _getKeyValue(el, 'alt');
    setAlt(altImage);
  }, []);

  const [addBtn, setAddBtn] = useState(!!_getKeyValue(el, 'buttons'));
  let url = _getKeyValue(el, 'url') || '';
  let attachment_id = _getKeyValue(el, 'attachment_id') || '';
  let attachmentURL = `https://cdn.${process.env.REACT_APP_ASKMONA}/${bot.id}/${attachment_id}.png`;
  if (!url && attachment_id) url = attachmentURL;

  const qr = element.quick_replies || null;

  const scenariosContext = useContext(ScenariosContext);

  const updateMedia = (attachmentID, url, btn, alt) => {
    let newArray = [];
    if (random) {
      newArray = [...arrayOfElements];
      newArray[newIndex] = [
        mediaTemplate(
          'image',
          attachmentID,
          !attachmentID ? url : null,
          qr ? { quick_replies: qr } : {},
          btn ? btnTemplate(btn) : null,
          alt
        ),
      ];
    }
    dispatch({
      type: 'onElemChange',
      payload: {
        index,
        value: random
          ? newArray
          : mediaTemplate(
              'image',
              attachmentID,
              !attachmentID ? url : null,
              qr ? { quick_replies: qr } : {},
              btn ? btnTemplate(btn) : null,
              alt
            ),
        func: advanced
          ? null
          : scenariosContext.functionalitiesSubsbarFocus === 'content'
          ? null
          : scenariosContext.functionalitiesSubsbarFocus,
      },
    });
    if (btn) setAddBtn(true);
    else setAddBtn(false);
  };

  return (
    <Container random={random} className="border-primary-lighter m-xs rad-15px">
      <CustomField>
        <Image
          src={url}
          alt={alt}
          placeholder={w.generic.imgPlaceholder}
          onUpload={async (e) => {
            setLoading(true);
            _sendFeedback('warning', 'loader');
            await _uploadImg(
              bot.id,
              e,
              (res) => {
                setLoading(false);
                _sendFeedback('success', w.success.saved);
                updateMedia(
                  res.id,
                  null,
                  addBtn ? el['buttons'][0] : null,
                  alt
                );
              },
              (err) => {
                setLoading(false);
                _sendFeedback('danger', w.error.general);
              }
            );
          }}
          onChange={(e) =>
            updateMedia(
              null,
              e.target.value,
              addBtn ? el['buttons'][0] : null,
              alt
            )
          }
          fixed={loading || fix ? 'true' : ''}
          loading={loading ? 'true' : ''}
        />
      </CustomField>
      <Input
        autoComplete="off"
        name="title"
        className="is-transparent text-align-center"
        placeholder={"Description de l'image"}
        style={{ width: '92%' }}
        value={alt}
        onChange={(e) => {
          setAlt(e.target.value);
          updateMedia(
            attachment_id,
            url,
            addBtn ? el['buttons'][0] : null,
            e.target.value
          );
        }}
      />
      {!addBtn && (
        <Button
          className="is-outlined is-borderless is-size-7 expand"
          onClick={(e) => {
            updateMedia(attachment_id, url, {});
          }}
        >
          {w.generic.addBtn}
        </Button>
      )}
      {addBtn && el['buttons'] && (
        <React.Fragment>
          <GenericBtn
            {...props}
            bIndex={0}
            el={el['buttons'][0]}
            hover={true}
            dispatch={(el) => {
              updateMedia(attachment_id, url, el.payload.value, alt);
            }}
          />
          <Button
            className="is-outlined is-borderless is-size-7 expand"
            onClick={(e) => {
              updateMedia(attachment_id, url);
            }}
          >
            {w.generic.delBtn}
          </Button>
        </React.Fragment>
      )}
      {random && (
        <RemoveElementButton
          onClick={() => {
            let newArray = [...arrayOfElements];
            newArray = newArray.filter((el) => el[0] !== element);
            dispatch({
              type: 'onElemChange',
              payload: {
                index,
                value: newArray,
                func: null,
              },
            });
          }}
        >
          <Icon icon="fas fa-times" />
        </RemoveElementButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: ${(p) => (p.random ? '300px' : '50%')};
  position: relative;
`;

const CustomField = styled(Field)`
  border-radius: 15px;
  overflow: hidden;
`;

const RemoveElementButton = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  color: gray;
  background-color: #ececec;
  border-radius: 20px;
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
`;
