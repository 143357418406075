import { _momentToOph } from '../../../_tools/oph';
import { _arrayMove } from '../../../_tools/other';
export default (state, action) => {
  switch (action.type) {
    case 'setDates':
    case 'setReminderInterval':
      let { name, date } = action.payload;
      if (action.type === 'setDates') {
        const [startDate, endDate] = (state.period || '').split('-');
        if (name === 'startDate')
          return {
            ...state,
            period: `${date ? _momentToOph(date.format('YYYY-MM-DD')) : ''}${
              date && endDate ? '-' : ''
            }${endDate ? `${endDate}` : ''}`,
          };
        else if (name === 'period' || name === 'endDate')
          return {
            ...state,
            period: `${startDate}${
              date
                ? date.length
                  ? `-${_momentToOph(date[1].date.format('YYYY-MM-DD'))}`
                  : `-${_momentToOph(date.format('YYYY-MM-DD'))}`
                : ''
            }`,
          };
        else return { ...state };
      } else if (action.type === 'setReminderInterval') {
        let newRI = state.mail.remind_interval || '';
        if (date) {
          newRI = `${newRI}${newRI ? ' ' : ''}${_momentToOph(
            date.format('YYYY-MM-DD')
          )}`;
          newRI = [...new Set(newRI.split(' '))].join(' ');
        }
        return { ...state, mail: { ...state.mail, remind_interval: newRI } };
      }
      break;
    case 'removeReminderInterval':
      let newR = state.mail.remind_interval.replace(action.payload, '');
      newR = [...new Set(newR.split(' '))].filter((o) => o).join(' ');
      return { ...state, mail: { ...state.mail, remind_interval: newR || '' } };
    case 'setPeriod':
      return { ...state, period: action.payload };
    case 'setActivated':
      return { ...state, mail: { ...state.mail, activated: action.payload } };
    case 'setMailMail':
      return { ...state, mail: { ...state.mail, mail: action.payload } };
    case 'mveElement':
    case 'addElement':
    case 'popElement':
    case 'chgElement':
    case 'onElemChange':
    case 'addButtonToElement':
      const { value, index, type } = action.payload;
      if (action.type === 'addElement')
        return {
          ...state,
          content: state.content.filter((o) => o).concat(value),
        };
      if (action.type === 'chgElement')
        return {
          ...state,
          content: state.content.map((el, i) => (i === index ? value : el)),
        };
      if (action.type === 'popElement')
        return {
          ...state,
          content: state.content.filter((el, i) => i !== index),
        };
      if (action.type === 'mveElement') {
        let newContent = [...state.content];
        _arrayMove(newContent, index, type === 'up' ? index - 1 : index + 1);
        return { ...state, content: newContent };
      }
      if (action.type === 'onElemChange')
        return {
          ...state,
          content: state.content.map((el, i) =>
            i === index ? JSON.stringify(value) : el
          ),
        };
      if (action.type === 'addButtonToElement')
        return {
          ...state,
          content: state.content.map((el, i) =>
            i === index
              ? JSON.stringify({
                  attachment: {
                    type: 'template',
                    payload: {
                      template_type: 'button',
                      text: el,
                      buttons: [
                        {
                          type: 'web_url',
                          url: action.payload.url,
                          title: action.payload.title,
                        },
                      ],
                    },
                  },
                })
              : el
          ),
        };
      return state;
    case 'addPlaceId':
      return {
        ...state,
        place_id: action.payload,
      };
    default:
      return state;
  }
};
