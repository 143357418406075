import React from 'react';
import styled from 'styled-components';

const Message = ({ unreadMessages, time, wording }) => {
  return (
    <>
      <Subtitle>{time}</Subtitle>
      <WrapperTitle
        className="p-t-xs text-align-center is-uppercase"
        style={{ fontSize: '10px' }}
      >
        <Title>{wording.homepage.message}</Title>
      </WrapperTitle>
      <WrapperNumber>{unreadMessages ? unreadMessages : 0}</WrapperNumber>
    </>
  );
};

const Subtitle = styled.h6`
  text-align: center;
  padding-top: 0.25rem;
  font-weight: 300;
`;

const WrapperTitle = styled.div`
  font-size: 10px;
  text-align: center;
  padding-top: 0.25rem;
`;

const Title = styled.h3`
  text-align: center;
  text-transform: uppercase;
`;

const WrapperNumber = styled.span`
  text-align: center;
  margin: 1rem;
  font-size: 1.25rem;
`;

export default Message;
