import React, { useMemo, useState } from 'react';
import { _isEmail } from '../../_tools/is';
import { Query } from 'react-apollo';
import { GET_USERS } from '../../_api/user';
import Loader from '../../components/Loader';
import { _sendFeedback } from '../../_tools/ui';
import { Field, Input, Button, Select, Icon } from '@ask-mona/ui';

import { createInvitation } from "../../api/graphql/invitation.api";

export default (props) => {
  const {
    m: {
      w,
      bot,
      user: { user, organization, _createInvitation },
    },
  } = props;
  const [mail, setMail] = useState('');
  const [error, setError] = useState('');
  const [invitationLanguageCode, setInvitationLanguageCode] = useState("en");
  const [invitationPerm, setInvitationPerm] = useState(3);

  const availableRoles = useMemo(() => {
    const roles = [
      { id: 2, value: w.account.owner, perm: 2, status: 'owner' },
      { id: 3, value: w.account.member, perm: 4, status: 'member' },
    ];

    if (user.role === "Admin") {
      roles.unshift({ id: 1, value: w.account.admin, perm: 1, status: "admin" });
    }

    return roles;
  }, [user, w]);
  return (
    <div className="has-text-primary-ter p-md overflow-scroll w-100p">
      {organization.id !== bot.organization_id && organization.id === 1 && (
        <div>
          <span className="has-weight-semibold m-b-xs">
            {w.account.monaTeam}
          </span>
          <UserTable {...props} />
        </div>
      )}
      {organization.id !== bot.organization_id && organization.id === 1 && (
        <span className="has-weight-semibold m-b-xs">
          {`${w.account.otherTeam} ${bot.name}:`}
        </span>
      )}
      <Field
        className="m-b-md"
        title={w.account.yourTeam}
        subtitle={w.account.infoTeam}
      ></Field>
      {user.permissions && 1 | 2 && (
        <Field title={w.account.invitation} className="m-b-md" error={error}>
          <Input
            value={mail}
            placeholder={w.account.userMail}
            onChange={(e) => {
              setMail(e.target.value);
              if (error) setError('');
            }}
          />
          <Select
            placeholder={w.account.language}
            items={[
              { id: 1, value: `🇬🇧 ${w.generic.english}`, code: "en" },
              { id: 2, value: `🇫🇷 ${w.generic.french}`, code: "fr" },
            ]}
            onSelect={(item) => {
              setInvitationLanguageCode(item.code);
            }}
          />
          <Select
            placeholder={w.generic.roles}
            onSelect={async (items) => {
              setInvitationPerm(items.perm);
            }}
            items={availableRoles}
          />
          <Button
            onClick={async () => {
              if (!_isEmail(mail)) {
                setError(w.error.email);
                return;
              } else {
                _sendFeedback('warning', 'loader');

                try {
                  await createInvitation(
                    {
                      email: mail,
                      organizationId: bot.organization_id,
                      permission: invitationPerm,
                      languageCode: invitationLanguageCode,
                    },
                    { refetchQueries: ['getAllUsers'] }
                  );

                  _sendFeedback('success', w.success.saved);
                  setInvitationPerm(4);
                } catch {
                  _sendFeedback('danger', w.error.general);
                }

              }
            }}
          >
            {w.generic.add}
          </Button>
        </Field>
      )}
      <Table {...props} />
    </div>
  );
};

//USER TABLE
const _toStatus = (perm, w) => {
  switch (perm) {
    case 1:
      return w.account.admin;
    case 2:
      return w.account.owner;
    case 4:
      return w.account.member;
    case 8:
      return w.account.guest;
    default:
      return w.error.unknown;
  }
};

const UserTable = (props) => {
  const {
    subID,
    m: {
      w,
      user: { organization },
    },
  } = props;
  return (
    <Query
      query={GET_USERS}
      variables={{ organization_id: organization.id }}
      skip={!organization.id}
    >
      {({ loading, error, data, ...other }) => {
        if (loading) return <Loader className="has-text-primary-ter" />;
        if (error) return null;
        else if (subID === 1) {
          let users = data.getUsers.users || [];
          return (
            <div className="border-l-primary-lighter border-r-primary-lighter m-b-md">
              <div className="flex row has-background-primary-lighter is-size-6bis">
                <div className="p-xs flex grow-2 basis-0">
                  {w.account.userName}
                </div>
                <div className="p-xs flex grow-3 basis-0">
                  {w.account.userMail}
                </div>
                <div className="p-xs flex grow-2 basis-0 center">
                  {w.account.userPerm}
                </div>
              </div>
              {users.map((u) => (
                <User key={u.id} {...u} {...props} />
              ))}
            </div>
          );
        }
      }}
    </Query>
  );
};

const Table = (props) => {
  const {
    m: { w },
    data: { getUsers, getOrganization },
  } = props;
  const users = getUsers.users || [];
  const invits = getOrganization.invitations || [];
  return (
    <div className="border-l-primary-lighter border-r-primary-lighter m-b-md">
      <div className="flex row has-background-primary-lighter is-size-6bis">
        <div className="p-xs flex grow-2 basis-0">{w.account.userName}</div>
        <div className="p-xs flex grow-3 basis-0">{w.account.userMail}</div>
        <div className="p-xs flex grow-2 basis-0 center">
          {w.account.userPerm}
        </div>
      </div>
      {users.map((u) => (
        <User key={u.id} {...u} {...props} />
      ))}
      {invits
        .filter((m) => !m.is_removed)
        .map((u) => (
          <Invitation key={u.id} {...u} {...props} />
        ))}
    </div>
  );
};

const User = (props) => {
  const {
    m: { w, user, bot },
  } = props;
  const { firstname, lastname, permissions, email, id } = props;
  return (
    <div className="flex row border-b-primary-lighter">
      <div className="p-xs flex grow-2 basis-0 items-center">{`${firstname} ${lastname}`}</div>
      <div className="p-xs flex grow-3 basis-0 items-center">{email}</div>
      {user.user.permissions & (1 | 2) ? (
        <div className="p-xs flex grow-2 basis-0 center">
          <Select
            defaultValue={_toStatus(permissions, w)}
            onSelect={async (items) => {
              const { status } = items;
              if (status === 'owner' || status === 'member') {
                _sendFeedback('warning', 'loader');
                let res = await user._updateStatus(
                  { user_id: id, status: status },
                  { refetchQueries: ['getAllUsers'] }
                );
                if (res.success) _sendFeedback('success', w.success.saved);
                else _sendFeedback('danger', w.error.general);
              }
            }}
            items={[
              { id: 0, value: _toStatus(permissions, w), status: 'base' },
              { id: 1, value: w.account.owner, status: 'owner' },
              { id: 2, value: w.account.member, status: 'member' },
            ]}
          />
          <Button
            color="danger"
            className="is-outlined is-borderless shrink"
            onClick={async () => {
              _sendFeedback('warning', 'loader');
              let res = await user._revokeFromOrg(
                { user_id: id, bot_id: bot.id },
                { refetchQueries: ['getAllUsers'] }
              );
              if (res.success) _sendFeedback('success', w.success.saved);
              else _sendFeedback('danger', w.error.general);
            }}
          >
            <Icon icon="fas fa-trash" />
          </Button>
        </div>
      ) : (
        <div className="p-xs flex grow-1 basis-0">
          {_toStatus(permissions, w)}
        </div>
      )}
    </div>
  );
};

const Invitation = (props) => {
  const {
    m: { w, user },
    email,
    token,
  } = props;
  return (
    <div className="flex row border-b-primary-lighter">
      <div
        className="p-xs flex grow-2 basis-0"
        style={{ whiteSpace: 'unset', wordBreak: 'break-all' }}
      >
        {email}
      </div>
      <div
        className="p-xs flex grow-4 basis-0 is-size-7"
        style={{ wordBreak: 'break-all' }}
      >
        <Field className="w-100p">
          <Input
            id={token}
            value={`${window.location.protocol}//${window.location.host}/validate/${token}`}
          />
          <Button
            onClick={() => {
              let copyText = document.getElementById(token);
              copyText.select();
              document.execCommand('copy');
            }}
          >
            <Icon className="has-text-primary" icon="fas fa-copy" />
          </Button>
        </Field>
      </div>
      <div className="p-xs flex grow-2 basis-0 items-center">{`${w.generic.waitingValidation}...`}</div>
      <Button
        color="danger"
        className="is-outlined is-borderless shrink"
        onClick={async () => {
          _sendFeedback('warning', 'loader');
          let res = await user._deleteInvitation(
            { email: email },
            { refetchQueries: ['getAllUsers'] }
          );
          if (res.success) _sendFeedback('success', w.success.saved);
          else _sendFeedback('danger', w.error.general);
        }}
      >
        <Icon icon="fas fa-trash" />
      </Button>
    </div>
  );
};
