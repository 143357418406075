import Schema from './Schema';
import gql from 'graphql-tag';

class EmailRecipient extends Schema {
  getEmails = async (variables?: object) =>
    await this.query(GET_EMAIL_RECIPIENTS, variables);

  createEmail = async (variables?: object) =>
    await this.mutate(CREATE_EMAIL_RECIPIENT, variables);

  updateEmail = async (variables?: object) =>
    await this.mutate(UPDATE_EMAIL_RECIPIENT, variables);

  deleteEmail = async (variables?: object) =>
    await this.mutate(DELETE_EMAIL, variables);
}

const GET_EMAIL_RECIPIENTS = gql`
  query getEmailRecipients($bot_id: Int) {
    getEmailRecipients(bot_id: $bot_id) {
      emailRecipients {
        id
        email
        chat_notification_disabled
      }
    }
  }
`;

const CREATE_EMAIL_RECIPIENT = gql`
  mutation createEmailRecipient(
    $bot_id: Int!
    $email: String!
    $chat_notification_disabled: Boolean
  ) {
    createEmailRecipient(
      bot_id: $bot_id
      email: $email
      chat_notification_disabled: $chat_notification_disabled
    ) {
      id
      email
      chat_notification_disabled
    }
  }
`;

const UPDATE_EMAIL_RECIPIENT = gql`
  mutation updateEmailRecipient(
    $id: Int!
    $email: String
    $chat_notification_disabled: Boolean
  ) {
    updateEmailRecipient(
      id: $id
      email: $email
      chat_notification_disabled: $chat_notification_disabled
    ) {
      id
      email
      chat_notification_disabled
    }
  }
`;

const DELETE_EMAIL = gql`
  mutation deleteEmailRecipient($id: Int!) {
    deleteEmailRecipient(id: $id)
  }
`;

export default EmailRecipient;
