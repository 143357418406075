import React from 'react';
import { withGlobals } from '../hoc/globals';
import TimeInput from './TimeInput';

const TimeRange = ({ start, end, g: { w }, ...other }) => (
  <div className="is-lowercase space-pre">
    {w.generic.hFrom}
    <TimeInput
      name="start"
      placeholder={'-- : --'}
      initTime={start}
      {...other}
    />
    <span className="m-l-xs m-r-xs">{w.generic.hTo}</span>
    <TimeInput name="end" placeholder={'-- : --'} initTime={end} {...other} />
  </div>
);

export default withGlobals(TimeRange);
