import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Frame } from '@ask-mona/ui';
import { withGlobals } from '../hoc/globals';
import logo from '../_assets/logoWhite.png';

class Door extends Component {
  state = {
    loading: true,
  };

  render() {
    const { w } = this.props.g;
    return (
      <div className="has-content-centered">
        <div
          className={`p-md w-4 ${
            this.state.loading ? 'display-none' : 'animation-fadeIn'
          }`}
        >
          <Frame background="transparent">
            <img
              src={logo}
              alt="AskMona-Logo"
              onLoad={() => this.setState({ loading: false })}
            />
          </Frame>
          <div className="flex row space-between wrap">
            <Link to="/signin" className="w-100p">
              <Button className="is-uppercase m-xs expand">
                {w.generic.signin}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withGlobals(Door);
