import React from 'react';
import { Field, Input } from '@ask-mona/ui';

export default (props) => {
  const {
    m: { w },
    artwork: { slug },
    dispatch,
  } = props;
  return (
    <div className="p-md">
      <div className="p-b-xs has-text-weight-medium m-b-xs border-b-white-ter">
        {w.artwork.text}
      </div>
      <div className="m-b-md is-size-7 has-text-weight-normal">
        {w.artwork.textInfo}
      </div>
      <Field title={w.artwork.textTitle}>
        <Input
          value={slug || ''}
          onChange={(e) =>
            dispatch({ type: 'setSlug', payload: e.target.value })
          }
        />
      </Field>
    </div>
  );
};
