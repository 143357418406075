import gql from 'graphql-tag';

export const UPDATE_TRACKER = gql`
  mutation($bot_id: Int!, $embeded: Boolean) {
    updateTracker(bot_id: $bot_id, embeded: $embeded)
  }
`;

export const UPDATE_CARROUSEL = gql`
  mutation($bot_id: Int!, $carrousel: Boolean) {
    updateCarrousel(bot_id: $bot_id, carrousel: $carrousel)
  }
`;

export const UPDATE_CARDLIST = gql`
  mutation($bot_id: Int!, $card_list: Boolean) {
    updateCardList(bot_id: $bot_id, card_list: $card_list)
  }
`;

export const UPDATE_LANGUAGE_BOT = gql`
  mutation($bot_id: Int!, $fr: Boolean, $en: Boolean) {
    updateLanguageBot(bot_id: $bot_id, fr: $fr, en: $en)
  }
`;

export const CREATE_FEATURE = gql`
  mutation($bot_id: Int!, $feature_id: [Int]!) {
    createFeature(bot_id: $bot_id, feature_id: $feature_id) {
      id
      name
    }
  }
`;

export const DELETE_FEATURE = gql`
  mutation($bot_id: Int!, $feature_id: [Int]!) {
    deleteFeature(bot_id: $bot_id, feature_id: $feature_id) {
      id
      name
    }
  }
`;
