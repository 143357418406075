import React from 'react';

export default ({ title, subtitle }) => (
  <div
    className="has-content-centered has-text-primary-ter"
    style={{ height: 'inherit' }}
  >
    <div className="is-size-5">{title}</div>
    <br />
    <div>{subtitle}</div>
  </div>
);
