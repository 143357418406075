import React, { Component } from 'react';
import { Field, Image as Img } from '@ask-mona/ui';
import { _getKeyValue } from '../../../_tools/other';
import { _sendFeedback } from "../../../_tools/ui";
import { _uploadImg } from '../../../_api/upload';

class Image extends Component {
  onSuccess = (res) => {
    const { fct } = this.props;
    fct.setImage(res.path);
    _sendFeedback("success", this.props.m.w.success.saved);
  };

  onError = (err) => {
    console.log(err);
  };

  onUpload = async (e) => {
    const {
      m: { bot },
    } = this.props;
    await _uploadImg(bot.id, e, this.onSuccess, this.onError);
  };

  onChange = (e) => {
    const { fct } = this.props;
    fct.setImage(e.target.value);
  };

  render() {
    const {
      m: { w, bot },
      event,
    } = this.props;
    const main_image = _getKeyValue(event, 'main_image') || '';
    return (
      <Field title={w.event.image} className="m-b-md">
        <Img
          src={main_image || bot.default_image}
          onUpload={this.onUpload}
          onChange={this.onChange}
          className="border-grey-lighter"
        />
      </Field>
    );
  }
}

export default Image;
