const Window = (template) => {
  switch (template) {
    case 'standard':
      return {
        side: false,
        fullpage: false,
        height: '',
        width: '',
      }
    case 'aside':
      return {
        side: true,
        fullpage: false,
        height: '',
        width: '400px',
      }
    default:
      break;
  }
}

export default Window;
