import React from 'react';
import styled from 'styled-components';
import { Field, Input, Icon, Button } from '@ask-mona/ui';

const CreateEmail = ({
  value,
  setValueEmail,
  handleCreateEmail,
  close,
  wWebchat,
}) => {
  return (
    <Wrapper>
      <Header>
        <HeaderTitle>{wWebchat.configEmail}</HeaderTitle>
        <Button className="is-outlined is-borderless shrink">
          <Icon icon="fas fa-times" onClick={close} />
        </Button>
      </Header>
      <WrapperContent>
        <Field title={wWebchat.email} className="m-b-md">
          <Input
            name="name"
            value={value}
            onChange={(e) => setValueEmail(e.target.value)}
            className="w-100p"
          />
        </Field>
        <WrapperButtons>
          <Button className="is-uppercase" color="secondary" onClick={close}>
            {wWebchat.cancel}
          </Button>
          <Button className="is-uppercase" onClick={() => handleCreateEmail()}>
            {wWebchat.confirm}
          </Button>
        </WrapperButtons>
      </WrapperContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 400px;
  border-radius: 5px;
  background: #fff;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: #2d2f7b;
  padding: 1rem;
`;

const HeaderTitle = styled.div`
  color: #fff;
`;

const WrapperContent = styled.div`
  padding: 1rem;
`;

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export default CreateEmail;
