import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { File, Field, Input, Icon } from '@ask-mona/ui';
import { _uploadAudio } from '../../../_api/upload';
import Loader from '../../../components/Loader';
import { _getKeyValue } from '../../../_tools/other';
import { audioTemplate } from '../Reducer';
import { _sendFeedback } from '../../../_tools/ui';

import ScenariosContext from '../../../containers/scenarios/context/scenariosContext';

const Audio = ({
  m: { bot, w },
  element,
  dispatch,
  index,
  fix,
  advanced,
  random,
  newIndex,
  arrayOfElements,
}) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(_getKeyValue(element, 'url') || '');
  const qr = element.quick_replies || null;

  const scenariosContext = useContext(ScenariosContext);

  const onChange = (e) => {
    let newArray = [];
    if (random) {
      newArray = [...arrayOfElements];
      newArray[newIndex] = [
        audioTemplate(e.target.value, qr ? { quick_replies: qr } : {}),
      ];
    }
    setUrl(e.target.value);
    dispatch({
      type: 'onElemChange',
      payload: {
        index,
        value: random
          ? newArray
          : audioTemplate(e.target.value, qr ? { quick_replies: qr } : {}),
        func: advanced
          ? null
          : scenariosContext.functionalitiesSubsbarFocus === 'content'
          ? null
          : scenariosContext.functionalitiesSubsbarFocus,
      },
    });
  };

  const onSuccess = (res) => {
    let newArray = [];
    if (random) {
      newArray = [...arrayOfElements];
      newArray[newIndex] = [
        audioTemplate(res.path, qr ? { quick_replies: qr } : {}),
      ];
    }
    setUrl(res.path);
    dispatch({
      type: 'onElemChange',
      payload: {
        index,
        value: random
          ? newArray
          : audioTemplate(res.path, qr ? { quick_replies: qr } : {}),
        func: advanced
          ? null
          : scenariosContext.functionalitiesSubsbarFocus === 'content'
          ? null
          : scenariosContext.functionalitiesSubsbarFocus,
      },
    });
  };

  const onUpload = async (e) => {
    setLoading(true);
    await _uploadAudio(bot.id, e, (res) => {
      _sendFeedback("success", w.success.saved);
      onSuccess(res);
    }, (err) => console.log(err));
    setTimeout(() => setLoading(false), 500);
  };

  if (loading) return <Loader />;

  return (
    <Container className="p-xs">
      <audio
        className="audio has-text-danger"
        controls
        key={element.attachment.payload.url}
      >
        <source
          src={element.attachment.payload.url}
          type={`audio/${url.slice(-3)}`}
        />
        Browser does not support audio tag
        <code>audio</code>
      </audio>
      {!fix && (
        <CustomField subtitle={w.scenario.audioTitle}>
          <Input
            disabled={loading}
            value={element.attachment.payload.url}
            onChange={onChange}
          />
          <File disabled={loading} onChange={onUpload} />
        </CustomField>
      )}
      {random && (
        <RemoveElementButton
          onClick={() => {
            let newArray = [...arrayOfElements];
            newArray = newArray.filter((el) => el[0] !== element);
            dispatch({
              type: 'onElemChange',
              payload: {
                index,
                value: newArray,
                func: null,
              },
            });
          }}
        >
          <Icon icon="fas fa-times" />
        </RemoveElementButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  overflow: visible;
`;

const CustomField = styled(Field)`
  overflow: hidden;
`;

const RemoveElementButton = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  color: gray;
  background-color: #ececec;
  border-radius: 20px;
  position: absolute;
  top: -2px;
  right: -2px;
  cursor: pointer;
`;

export default Audio;
