import React, { createContext, useReducer } from "react";
import { _arrayMove } from "../../../_tools/other";
import { State, Action } from "../../../types/qa/context";

export const initialState: State = {
  language: "fr",
  botId: 0,
  token: "",
  permission: 0,
  organizationId: 0,
  wording: {},
  searchValue: "",
  categories: [],
  topics: [],
  currentTopicId: null,
  currentAnswerId: null,
  currentAnswerContentIndex: 0,
  currentCategoryId: null,
  topicAnswerCopied: "",
  topicStatus: "enabled",
  isMultisite: false,
  places: [],
};

export const QAReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return { ...state, language: action.payload };
    case "SET_BOT_ID":
      return { ...state, botId: action.payload };
    case "SET_TOKEN":
      return { ...state, token: action.payload };
    case "SET_PERMISSION":
      return { ...state, permission: action.payload };
    case "SET_ORGANIZATION_ID":
      return { ...state, organizationId: action.payload };
    case "SET_WORDING":
      return { ...state, wording: action.payload };
    case "SET_IS_MULTISITE":
      return { ...state, isMultisite: action.payload };
    case "SET_PLACES":
      return { ...state, places: action.payload };
    case "SET_NUMBER_QA":
      const { from, to, enabled } = action.payload;
      let newCats = [...state.categories];
      let lastCat = newCats.find((el) => el.id === from);
      if (to !== null) {
        if (lastCat) {
          lastCat.topics_count = lastCat?.topics_count - 1;
          if (enabled) {
            lastCat.enabled_topics_count = lastCat.enabled_topics_count - 1;
          }
        }
        let newCat = newCats.find((el) => el.id === to);
        if (newCat) {
          newCat.topics_count = newCat?.topics_count + 1;
          if (enabled) {
            newCat.enabled_topics_count = newCat.enabled_topics_count + 1;
          }
        }
      } else {
        if (lastCat) {
          if (enabled) {
            lastCat.enabled_topics_count = lastCat.enabled_topics_count - 1;
          } else {
            lastCat.enabled_topics_count = lastCat.enabled_topics_count + 1;
          }
        }
      }
      return { ...state, categories: newCats };
    case "SET_SEARCH_VALUE":
      return { ...state, searchValue: action.payload };
    case "SET_TOPICS":
      return { ...state, topics: action.payload };
    case "SET_CATEGORIES":
      return { ...state, categories: action.payload };
    case "SET_CURRENT_CATEGORY_ID":
      return { ...state, currentCategoryId: action.payload };
    case "SET_CURRENT_TOPIC":
      return { ...state, currentTopicId: action.payload };
    case "SET_CURRENT_ANSWER":
      return { ...state, currentAnswerId: action.payload };
    case "SET_CURRENT_ANSWER_CONTENT":
      return { ...state, currentAnswerContentIndex: action.payload };
    case "UPDATE_TOPIC_ANSWER":
    case "MOVE_ANSWER_CONTENT":
    case "DELETE_ANSWER_CONTENT_BUTTON":
      let newTopics = [...state.topics];
      let topic = newTopics.find((el) => el.id === state.currentTopicId);
      if (action.type === "UPDATE_TOPIC_ANSWER") {
        const { value, type, remove, buttons, id, answerId } = action.payload;

        let answer = topic?.answers.find((el) => el.id === answerId);
        if (answer) {
          let contentAnswer = JSON.parse(answer.content);
          if (remove) {
            contentAnswer.splice(id, 1);
            const newContentAnswer = contentAnswer.filter((a: any) => a);
            answer.content = JSON.stringify(newContentAnswer);
          } else {
            if (type === "button") {
              contentAnswer[id].value.attachment.payload.text = value;
              answer.content = JSON.stringify(contentAnswer);
            } else if (type === "attachment") {
              contentAnswer[id].value.attachment.payload.buttons = buttons;
              answer.content = JSON.stringify(contentAnswer);
            } else {
              contentAnswer[id].value = value;
              answer.content = JSON.stringify(contentAnswer);
            }
          }
        }
      } else if (action.type === "DELETE_ANSWER_CONTENT_BUTTON") {
        const { answerId, buttonId, contentId } = action.payload;
        let answer = topic?.answers.find((el) => el.id === answerId);
        if (answer) {
          let contentAnswer = JSON.parse(answer.content);
          let buttons =
            contentAnswer[contentId].value.attachment.payload.buttons;
          buttons.splice(buttonId, 1);
          if (!buttons.length) {
            let newContent = {
              type: "text",
              value: contentAnswer[contentId].value.attachment.payload.text,
            };
            contentAnswer[contentId] = newContent;
          }
          answer.content = JSON.stringify(contentAnswer);
        }
      } else if (action.type === "MOVE_ANSWER_CONTENT") {
        const { answerId, contentId, direction } = action.payload;
        let answer = topic?.answers.find((el) => el.id === answerId);
        if (answer) {
          let contentAnswer = JSON.parse(answer.content);
          _arrayMove(
            contentAnswer,
            contentId,
            direction === "up" ? contentId - 1 : contentId + 1
          );
          answer.content = JSON.stringify(contentAnswer);
        }
      }
      return { ...state, topics: newTopics };
    case "COPY_TOPIC_ANSWER":
      return { ...state, topicAnswerCopied: action.payload };
    case "PASTE_TOPIC_ANSWER":
      const contentToAdd = JSON.parse(state.topicAnswerCopied);
      const newArray = [...state.topics];
      const currentTopic = newArray.find(
        (el) => el.id === state.currentTopicId
      );

      let currentAnswer = currentTopic?.answers.find(
        (el) => el.id === action.payload
      );

      if (currentAnswer && currentAnswer.content) {
        let content = JSON.parse(currentAnswer?.content);
        const concatArray = [...content, ...contentToAdd];
        currentAnswer.content = JSON.stringify(concatArray);
      }
      return { ...state, topics: newArray, topicAnswerCopied: "" };
    case "ADD_TOPIC_ANSWER":
      const { contentType, answerId, contentId } = action.payload;
      let arrayTopics = [...state.topics];
      let topicTarget = arrayTopics.find(
        (el) => el.id === state.currentTopicId
      );
      let answerTarget = topicTarget?.answers.find((el) => el.id === answerId);
      if (answerTarget) {
        let answerParsed = JSON.parse(answerTarget.content);
        let newElement;
        switch (contentType) {
          case "text":
            newElement = {
              type: "text",
              value: "",
            };
            let res = answerParsed.concat(newElement);
            answerTarget.content = JSON.stringify(res);
            return { ...state, topics: arrayTopics };
          case "attachment":
            if (
              answerParsed[contentId]?.value?.attachment?.payload
                .template_type === "button"
            ) {
              answerParsed[contentId].value?.attachment.payload.buttons.push({
                type: "web_url",
                url: "",
                title: "",
              });
            } else {
              newElement = {
                type: "attachment",
                value: {
                  attachment: {
                    type: "template",
                    payload: {
                      template_type: "button",
                      text: answerParsed[contentId].value,
                      buttons: [
                        {
                          type: "web_url",
                          url: "",
                          title: "",
                        },
                      ],
                    },
                  },
                },
              };
              answerParsed[contentId] = newElement;
            }
            answerTarget.content = JSON.stringify(answerParsed);
            return { ...state, topics: arrayTopics };
          case "image":
          case "gif":
          case "video":
            if (contentType === "video") {
              newElement = {
                type: "video",
                value: "placeholder.webm",
              };
            } else {
              newElement = {
                type: contentType,
                value: "placeholder.png",
              };
            }
            let newContent = answerParsed.concat(newElement);
            answerTarget.content = JSON.stringify(newContent);
            return { ...state, topics: arrayTopics };
          default:
            throw new Error(`Type doesn't exist: ${contentType}`);
        }
      }
      return { ...state };
    case "SET_TOPIC_STATUS":
      return { ...state, topicStatus: action.payload };
    case "ADD_ANSWER":
      let clonedTopics = [...state.topics];
      let answers = clonedTopics.find(
        (el) => el.id === state.currentTopicId
      )?.answers;
      answers?.push(action.payload);
      return { ...state, topics: clonedTopics };
    case "DELETE_ANSWER":
      let topics = [...state.topics];
      let topicanswers = topics.find(
        (el) => el.id === state.currentTopicId
      )?.answers;
      if (topicanswers) {
        let answerToDelete = topicanswers?.findIndex(
          (el) => el.id === action.payload
        );
        topicanswers.splice(answerToDelete, 1);
      }
      return { ...state, topics };
    case "UPDATE_TOPIC":
      const { topicId, fieldToUpdate, valueField } = action.payload;
      let topicsList = [...state.topics];
      let topicToUpdate = topicsList.find((el) => el.id === topicId);
      switch (fieldToUpdate) {
        case "isFallback":
          if (topicToUpdate) topicToUpdate.is_fallback = Boolean(valueField);
          return { ...state, topcis: topicsList };
        case "enabled":
          if (topicToUpdate) topicToUpdate.enabled = Boolean(valueField);
          return { ...state, topcis: topicsList };
        case "name":
          if (topicToUpdate) topicToUpdate.name = String(valueField);
          return { ...state, topcis: topicsList };
        case "description":
          if (topicToUpdate) topicToUpdate.description = String(valueField);
          return { ...state, topcis: topicsList };
        default:
          throw new Error(`Type doesn't exist: ${fieldToUpdate}`);
      }
    case "CHANGE_CATEGORY":
      let topicsLists = [...state.topics];
      let topicToDelete = topicsLists.findIndex(
        (el) => el.id === action.payload.topicId
      );
      topicsLists.splice(topicToDelete, 1);
      return { ...state, topics: topicsLists };
    case "CREATE_LINK_TARGET":
    case "DELETE_LINK_TARGET":
      let newsTopics = [...state.topics];
      if (action.type === "CREATE_LINK_TARGET") {
        let currentTopicToUpdate = newsTopics.find(
          (el) => el.id === action.payload.topicId
        );
        currentTopicToUpdate?.targets.push(action.payload.target);
      } else {
        let currentTopicToUpdate = newsTopics.find(
          (el) => el.id === state.currentTopicId
        );
        let targets = currentTopicToUpdate?.targets;
        if (targets) {
          let targetToDelete = targets.findIndex(
            (el) => el.id === action.payload
          );
          targets.splice(targetToDelete, 1);
        }
      }
      return { ...state, topics: newsTopics };
    case "UPDATE_ANSWER":
      let tops = [...state.topics];
      let top = tops.find((el) => el.id === state.currentTopicId);
      let ans = top?.answers.find((el) => el.id === action.payload.id);
      if (ans) {
        ans.enabled = action.payload.enabled;
        ans.start_date = action.payload.startDate;
        ans.end_date = action.payload.endDate;
        ans.reminder_date = action.payload.reminderDate;
        ans.reminder_emails = action.payload.reminderEmails;
        ans.place = action.payload.place;
      }
      return { ...state, topics: tops };
    case "SET_TOPIC_LINK":
    case "DELETE_TOPIC_LINK":
      let topicsNews = [...state.topics];
      let topicNew = topicsNews.find((el) => el.id === state.currentTopicId);

      if (action.type === "SET_TOPIC_LINK") {
        if (topicNew) {
          topicNew.answers = [];
          topicNew.link = action.payload;
        }
      } else {
        if (topicNew) topicNew.link = null;
      }
      return { ...state, topics: topicsNews };
    case "ADD_TOPIC":
      let topicsCloned = [...state.topics];
      topicsCloned.push(action.payload);
      return { ...state, topics: topicsCloned };
    case "ADD_TOPIC_CATEGORY":
      let categoriesCloned = [...state.categories];
      categoriesCloned.unshift(action.payload);
      return { ...state, categories: categoriesCloned };
    default:
      return state;
  }
};

type Context = {
  state: State;
  dispatch: (action: Action) => void;
};

type Props = {
  children: React.ReactNode;
};

export const QAContext = createContext({} as Context);

const QAProvider = (props: Props) => {
  const [state, dispatch] = useReducer(QAReducer, initialState);
  return (
    <QAContext.Provider value={{ state, dispatch }}>
      {props.children}
    </QAContext.Provider>
  );
};

export default QAProvider;
