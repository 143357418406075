import React, { Component } from 'react';
import styled from 'styled-components';
import ArrowRightSharpIcon from '@material-ui/icons/ArrowRightSharp';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';

class SidebarSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      showArrow: true,
      noArrow: false,
      scrollHeight: 0,
    };
  }
  paneDidMount = (node) => {
    if (node) {
      node.addEventListener('scroll', this.handleScroll.bind(this));
      if (this.state.scrollHeight !== node.scrollHeight) {
        this.setState({ scrollHeight: node.scrollHeight });
      }
      if (
        node.scrollHeight < this.props.maxheight &&
        this.state.noArrow === false &&
        !this.props.forceScrollArrow
      ) {
        this.setState({ noArrow: true });
      } else if (
        node.scrollHeight >= this.props.maxheight &&
        this.state.noArrow === true
      ) {
        this.setState({ noArrow: false });
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.state.scrollHeight < this.props.maxheight &&
      this.state.noArrow === false &&
      !this.props.forceScrollArrow
    ) {
      this.setState({ noArrow: true });
    } else if (
      this.state.scrollHeight >= this.props.maxheight &&
      this.state.noArrow === true
    ) {
      this.setState({ noArrow: false });
    }
  }

  handleScroll = (event) => {
    var node = event.target;
    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
    if (bottom) {
      this.setState({ showArrow: false });
    } else if (!this.state.showArrow) {
      this.setState({ showArrow: true });
    }
  };

  handleClick = () => {
    this.props.toggleActive();
    if (!this.state.showArrow && !this.state.noArrow) {
      this.setState({ showArrow: true });
    }
  };

  render() {
    return (
      <Container
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        maxheight={this.props.maxheight}
      >
        <Tab onClick={this.handleClick}>
          <TabTitle>{this.props.label}</TabTitle>
          <ArrowIcon>
            {this.props.click && this.props.active ? (
              <ArrowDropDownSharpIcon style={{ fontSize: 30 }} />
            ) : (
              <ArrowRightSharpIcon style={{ fontSize: 30 }} />
            )}
          </ArrowIcon>
        </Tab>
        <AddonContainer
          maxheight={this.props.maxheight}
          ref={this.paneDidMount}
          overflowHidden={this.props.overflowHidden}
        >
          {this.props.click && this.props.active && this.props.children}
        </AddonContainer>
        {!this.state.noArrow && this.state.showArrow && this.props.active ? (
          <ScrollArrow force={this.props.forceScrollArrow}>
            <ExpandMoreRoundedIcon style={{ fontSize: 15 }} />
          </ScrollArrow>
        ) : (
          !this.state.noArrow &&
          this.props.active && (
            <ScrollArrow force={this.props.forceScrollArrow}>
              <ExpandLessRoundedIcon style={{ fontSize: 15 }} />
            </ScrollArrow>
          )
        )}
      </Container>
    );
  }
}

export default SidebarSection;

const ScrollArrow = styled.div`
  position: absolute;
  width: 15px;
  bottom: 0 - 50px;
  left: ${(p) => (p.force ? '110px' : '80px')};
  display: flex;
  color: ${(p) => p.theme.colors.primary};
  justify-content: center;
  align-items: center;
  align-content: center;

  @media (max-width: 1400px) {
    ${(p) => p.force && `left: 110px`}
  }
  @media (max-width: 1200px) {
    ${(p) => p.force && `left: 95px`}
  }
  @media (max-width: 1000px) {
    ${(p) => p.force && `left: 77px`}
  }
`;

const Container = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  max-height: ${(p) => `${p.maxheight}px`};
  position: relative;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 20px auto;
  color: ${(p) => p.theme.colors.primary};
  font-weight: 510;
`;

const TabTitle = styled.div`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
`;

const ArrowIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
`;

const AddonContainer = styled.div`
  color: ${(p) => p.theme.colors.primary};
  margin-top: -8px;
  overflow-y: ${({ overflowHidden }) => overflowHidden ? 'hidden' : 'scroll'};
  overflow-x: visible;
  max-height: ${(p) => `${p.maxheight}px`};
  width: 100%;
`;
