import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import User from './User';
import NoContent from './NoContent';
import useEmails from '../../../../../api/graphql/hooks/useEmails';
import Loader from '../../../../../components/Loader';
import Modal from '../../../../../components/Modal';
import CreateEmail from './CreateEmail';
import { _sendFeedback } from '../../../../../_tools/ui';
import { _isEmail } from '../../../../../_tools/is';

const Messaging = ({ user, botId, wWebchat, w }) => {
  const [modalCreate, setModalCreate] = useState(false);
  const [valueEmail, setValueEmail] = useState('');
  const { emails, loading, createEmail, deleteEmail, updateEmail } = useEmails(
    botId,
    user.token
  );

  useEffect(() => {
    switch (loading) {
      case 'loading':
        return _sendFeedback('warning', 'loader');
      case 'loaded':
        return _sendFeedback('success', w.success.saved);
      case 'error':
        return _sendFeedback('danger', w.errors.general);
      default:
        break;
    }
  }, [loading]);

  const handleCreateEmail = () => {
    if (_isEmail(valueEmail)) {
      createEmail(valueEmail);
      setModalCreate(false);
      setValueEmail('');
    } else {
      _sendFeedback('danger', w.errors.email);
    }
  };

  const handleUpdate = (id, isActive, email) => {
    let emailToUpdate = emails.find((email) => email.id === id);
    emailToUpdate.chat_notification_disabled = !isActive;
    emailToUpdate.email = email;
    updateEmail(emailToUpdate);
  };

  const handleDelete = (id) => {
    deleteEmail(id);
  };

  return (
    <Wrapper>
      <WrapperHeader noContent={!emails.length}>
        <StyledBigTitle>{wWebchat.configEmail}</StyledBigTitle>
        {emails.length !== 0 && (
          <StyledButton onClick={() => setModalCreate(true)}>
            {wWebchat.addEmail}
          </StyledButton>
        )}
      </WrapperHeader>
      {modalCreate && (
        <Modal
          isOpen={modalCreate}
          close={() => setModalCreate(false)}
          selector="#root"
        >
          <CreateEmail
            value={valueEmail}
            setValueEmail={setValueEmail}
            handleCreateEmail={handleCreateEmail}
            close={() => setModalCreate(false)}
            wWebchat={wWebchat}
          />
        </Modal>
      )}
      <WrapperGrid header>
        <div>{wWebchat.mail}</div>
        {emails && !!emails.length && (
          <>
            <div>{wWebchat.enablingNotifications}</div>
            <div>{wWebchat.action}</div>
          </>
        )}
      </WrapperGrid>
      {loading !== '' ? (
        emails.length ? (
          emails.map((el) => (
            <User
              {...el}
              key={el.id}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
              wWebchat={wWebchat}
              w={w}
            />
          ))
        ) : (
          <NoContent setModalCreate={setModalCreate} wWebchat={wWebchat} />
        )
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WrapperGrid = styled.div`
  width: 100%;
  color: ${({ header }) => (header ? '#FFF' : '#2D307B')};
  background: ${({ header }) => (header ? '#2D307B' : '#FFF')};
  height: 40px;
  align-items: center;
  text-align: center;
  display: grid;
  grid-template-columns: 3fr 3fr 2fr;
`;

const StyledBigTitle = styled.h1`
  font-size: 1.1em;
  margin: 1.4rem 0 1rem 0;
`;

const StyledButton = styled.button`
  background: #fff;
  padding: 0.4rem 0.5rem;
  font-weight: 600;
  border: 2px solid #d27270;
  border-radius: 5px;
  color: #d27270;
  cursor: pointer;
  height: 40px;
`;

const WrapperHeader = styled.div`
  display: flex;
  justify-content: ${({ noContent }) =>
    noContent ? 'flex-start' : 'space-between'};
  align-items: center;
  margin: 0 2rem 1rem;
`;

export default Messaging;
