import React, { useState } from 'react';
import styled from 'styled-components';
import StyledBigTitle from '../../../../components/parameters/StyledTitle';
import StyledLabel from '../../../../components/parameters/StyledLabel';
import InputRadio from '../../../../components/parameters/InputRadio';
import CircleImg from '../../../../components/parameters/CircleImg';
import StyledImage from '../../../../components/parameters/StyledImage';
import userDefault from '../../../../_assets/user-default.png';
import { RightArrow } from '@styled-icons/boxicons-solid/RightArrow';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';
import webviewTemplate from './templates/Webview';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WrapperDiv = styled.div`
  display: flex;
  align-items: ${({ alignItem }) => alignItem};
  margin: ${({ mr }) => (mr ? mr : '2rem 0')};
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WebviewContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 450px;
  width: 215px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
  background: #eee;
`;

const StyledWebview = styled.div`
  position: absolute;
  background-color: #fff;
  height: ${({ height }) => height || '95%'};
  border-radius: ${({ borderRadius }) => borderRadius};
  width: 100%;
  bottom: 0;
`;

const Webview = ({ wWebchat, state, dispatch }) => {
  const [hideFirstContent, setHideFirstContent] = useState(true);
  return (
    <Wrapper>
      <StyledBigTitle>
        {wWebchat.templateChoice}
        <span onClick={() => setHideFirstContent(!hideFirstContent)}>
          {hideFirstContent ? (
            <DownArrow size={16} />
          ) : (
            <RightArrow size={16} />
          )}
        </span>
      </StyledBigTitle>
      {hideFirstContent && (
        <>
          <WrapperDiv justifyContent={'space-around'}>
            <WrapperContent>
              <WebviewContent>
                <StyledWebview borderRadius={'25px 25px 10px 10px'}>
                  <CircleImg webview>
                    <StyledImage
                      src={state.widgetIcon ? state.widgetIcon : userDefault}
                      alt="Logo du chatbot"
                    />
                  </CircleImg>
                </StyledWebview>
              </WebviewContent>
              <StyledLabel radio flex mt={'1rem'}>
                <InputRadio
                  type="radio"
                  value="template1"
                  checked={
                    JSON.stringify(state.templateWebview) ===
                    JSON.stringify(webviewTemplate('template1'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setTemplateWebview',
                      payload: webviewTemplate(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperContent>
            <WrapperContent>
              <WebviewContent>
                <StyledWebview
                  height={'80%'}
                  borderRadius={'25px 25px 10px 10px'}
                >
                  <CircleImg webview>
                    <StyledImage
                      src={state.widgetIcon ? state.widgetIcon : userDefault}
                      alt="Logo du chatbot"
                    />
                  </CircleImg>
                </StyledWebview>
              </WebviewContent>
              <StyledLabel radio flex mt={'1rem'}>
                <InputRadio
                  type="radio"
                  value="template2"
                  checked={
                    JSON.stringify(state.templateWebview) ===
                    JSON.stringify(webviewTemplate('template2'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setTemplateWebview',
                      payload: webviewTemplate(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperContent>
            <WrapperContent>
              <WebviewContent>
                <StyledWebview borderRadius={'25px 25px 10px 10px'} />
              </WebviewContent>
              <StyledLabel radio flex mt={'1rem'}>
                <InputRadio
                  type="radio"
                  value="template3"
                  checked={
                    JSON.stringify(state.templateWebview) ===
                    JSON.stringify(webviewTemplate('template3'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setTemplateWebview',
                      payload: webviewTemplate(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperContent>
            <WrapperContent>
              <WebviewContent>
                <StyledWebview borderRadius={'0 0 10px 10px'} />
              </WebviewContent>
              <StyledLabel radio flex mt={'1rem'}>
                <InputRadio
                  type="radio"
                  value="template4"
                  checked={
                    JSON.stringify(state.templateWebview) ===
                    JSON.stringify(webviewTemplate('template4'))
                  }
                  onChange={(e) => {
                    dispatch({
                      type: 'setTemplateWebview',
                      payload: webviewTemplate(e.target.value),
                    });
                  }}
                />
              </StyledLabel>
            </WrapperContent>
          </WrapperDiv>
        </>
      )}
    </Wrapper>
  );
};

export default Webview;
