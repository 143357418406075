import React, { useState } from 'react';
import styled from 'styled-components';

type Props = {
  toggle: boolean;
  handleClick: () => void;
};

interface Toggle {
  readonly toggle: boolean;
}

const Switch = (props: Props) => {
  return (
    <WrapperSwitch>
      <InputCheck type="checkbox" />
      <StyledSwitch
        toggle={props.toggle}
        onClick={props.handleClick}
      ></StyledSwitch>
    </WrapperSwitch>
  );
};

const WrapperSwitch = styled.label`
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  padding-right: 30px;
`;

const InputCheck = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const StyledSwitch = styled.div<Toggle>`
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 30px;
  border-radius: 50px;
  background-color: ${({ toggle }) => (toggle ? '#5054be' : '#808080')};
  transition: background-color 0.15s ease-in;

  &:after {
    content: '';
    position: absolute;
    left: ${({ toggle }) => (toggle ? '14px' : '0px')};
    top: -3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #2d2f7b;
    transition: left 0.15s ease-in;
    background-color: #fff;
  }
`;

export default Switch;
