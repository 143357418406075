const Menu = (template) => {
  switch (template) {
    case 'template1':
      return {
        first: true,
        second: false,
        third: false,
        borderColor: '#e6e5e5',
        borderIcon: '',
        backgroundIcon: '',
        colorIcon: '',
        backgroundBtn: '#fff',
        shadowIcon: null,
      };
    case 'template2':
      return {
        first: false,
        second: false,
        third: false,
        borderColor: '',
        borderIcon: '',
        backgroundIcon: '',
        colorIcon: '',
        backgroundBtn: '',
        shadowIcon: null,
      };
    case 'template3':
      return {
        first: false,
        second: true,
        third: false,
        borderColor: '',
        borderIcon: '',
        backgroundIcon: '',
        colorIcon: '',
        backgroundBtn: '',
        shadowIcon: null,
      };
    case 'template4':
      return {
        first: false,
        second: true,
        third: false,
        borderColor: '',
        borderIcon: '',
        backgroundIcon: '',
        colorIcon: '',
        backgroundBtn: '',
        shadowIcon: null,
        borderTopRadius: true,
      };
    case 'template5':
      return {
        first: false,
        second: false,
        third: true,
        borderColor: '',
        borderIcon: '5px',
        backgroundIcon: '#FFF',
        colorIcon: '',
        backgroundBtn: '',
        shadowIcon:
          '0px 1px 6px -2px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 1px 0px rgb(0 0 0 / 12%)',
      };
    case 'template6':
      return {
        first: false,
        second: false,
        third: true,
        borderColor: '',
        borderIcon: '25px',
        backgroundIcon: '#FFF',
        colorIcon: '',
        backgroundBtn: '',
        shadowIcon:
          '0px 1px 6px -2px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 1px 0px rgb(0 0 0 / 12%)',
      };
    default:
      break;
  }
};

export default Menu;
