import gql from 'graphql-tag';
import { _mutate, _query } from './client';

class Bot {
  constructor(client, id) {
    this.client = client;
    this.id = id;
    this.hasCanceled = false;
  }

  _getBot = async (opt) => {
    return await _query(this.client, GET_BOT, { id: this.id }, opt);
  };

  _getBots = async () => {
    return await _query(this.client, GET_BOTS);
  };

  _updateBot = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_BOT, variables, opt);
  };

  _getModules = async () => {
    return await this.fetch();
  };

  _updateModule = async (data) => {
    return await this.fetch('/update', 'PUT', data);
  };

  _createModule = async (data) => {
    return await this.fetch('/create', 'POST', data);
  };

  _deleteModule = async (body) => {
    return await this.fetch(`/delete`, 'DELETE', body);
  };

  _getModule = async (module) => {
    return await this.fetch(`/${module}`);
  };

  _createScenarioFromTemplate = async (data) => {
    return await this.fetch('/create/from_template', 'POST', data);
  };

  _desactivateBot = async (msg) => {
    return await this.fetch('/desactivate-bot', 'PUT', { message: msg });
  };

  _activateBot = async () => {
    return await this.fetch('/activate-bot', 'PUT');
  };

  _cancelFetch = () => {
    this.hasCanceled = true;
  };

  _updateOrganization = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_ORGANIZATION, variables, opt);
  };

  _addBotFeature = async (variables, opt) => {
    return await _mutate(this.client, ADD_BOT_FEATURE, variables, opt);
  };

  _getFacebook = async (opt) => {
    return await _query(this.client, GET_FACEBOOK, { id: this.id }, opt);
  };

  _getPersona = async (opt) => {
    return await _query(this.client, GET_PERSONA, { bot_id: this.id }, opt);
  };

  _createPersona = async (variables, opt) => {
    return await _mutate(this.client, CREATE_PERSONA, variables, opt);
  };

  _updatePersona = async (variables, opt) => {
    return await _mutate(this.client, UPDATE_PERSONA, variables, opt);
  };

  _getFeatures = async (opt) => {
    return await _query(this.client, GET_FEATURES, opt);
  };

  _removeFeatures = async (variables, opt) => {
    return await _mutate(this.client, REMOVE_BOT_FEATURE, variables, opt);
  };

  _createBot = async (opt) => {
    return await _mutate(this.client, CREATE_BOT, opt);
  };

  _updateHomepageBanner = async (opt) => {
    return await _mutate(this.client, UPDATE_HOMEPAGE_BANNER, opt);
  };

  _getBotFeatures = async (opt) => {
    return await _query(this.client, GET_BOT_FEATURES, { id: this.id }, opt);
  };

  _updatePlatform = async (opt) => {
    return await _mutate(this.client, UPDATE_PLATFORM, opt);
  };

  fetch = async (path = '', method = 'GET', body = null) => {
    const url = `${process.env.REACT_APP_HTTP_LINK}/rest/scenario/${this.id}${path}`;
    let req = {
      method: method,
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if (body) req = { ...req, body: JSON.stringify(body) };

    try {
      const res = await fetch(url, req).then((res) => res.json());
      if (res.error) throw new Error(res.error);
      if (this.hasCanceled) throw new Error('fetch canceled');
      return { success: true, json: res };
    } catch (err) {
      return { success: false, error: err, canceled: this.hasCanceled };
    }
  };
}

export default Bot;

export const GET_BOT = gql`
  query getBot($id: Int) {
    getBot(id: $id) {
      id
      scope
      organization_id
      default_image
      default_language
      name
      prices
      wat
      panel_logo
      page_id
      platforms {
        messenger
        webchat
      }
      familiar {
        activated
        wording_url_v2
      }
      types {
        id
        slug
        translations {
          name
          short_name
        }
      }
      places {
        id
        name
        lat
        long
        address
        opening_hours
        postcode
        google_place_id
        short_address
        image_url
        main_site
        sub_places {
          id
          name
          address
          itinerary
        }
      }
      config {
        alertMessages {
          id
          content {
            fr
            en
          }
          opening_hours
        }
        tracker {
          embeded
        }
        languages {
          fr
          en
        }
        analyticsFeatures {
          name
          activated
        }
      }
      features {
        id
        slug
        name
        type
        is_bot_active
        required_permission
      }
      homepage_banner {
        description
        enable
        image
        title
        link
      }
    }
  }
`;

// BOT QUERY
export const GET_BOTS = gql`
  query getBots($status: String, $languages: [String]) {
    getBots(
      status: $status
      languages: $languages
    ) {
      bots {
        id
        scope
        activated
        organization_id
        default_language
        types {
          id
          slug
          translations {
            name
            short_name
          }
        }
        familiar {
          activated
          wording_url_v2
        }
        name
        prices
        permissions
        default_image
        status
        messenger_url
        places {
          id
          name
          opening_hours
          main_site
        }
        features {
          id
          slug
          name
          type
          is_bot_active
          required_permission
        }
        organization {
          name
        }
        platforms {
          webchat
          messenger
        }
        created_at
        panel_logo
        total_users
      }
    }
  }
`;

export const UPDATE_BOT = gql`
  mutation (
    $id: Int!
    $name: String
    $organization_id: Int
    $permissions: Int
    $prices: String
    $default_image: String
    $facebook_access_key: String
    $page_id: String
    $familiar: Boolean
    $server: Boolean
    $panel_logo: String
  ) {
    updateBot(
      id: $id
      name: $name
      organization_id: $organization_id
      permissions: $permissions
      prices: $prices
      default_image: $default_image
      facebook_access_key: $facebook_access_key
      page_id: $page_id
      familiar: $familiar
      server: $server
      panel_logo: $panel_logo
    ) {
      id
      organization_id
      name
      familiar {
        activated
        wording_url_v2
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation ($id: Int!, $name: String) {
    updateOrganization(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const ADD_BOT_FEATURE = gql`
  mutation (
    $bot_id: Int!
    $feature_id: [Int]!
    $is_bot_active: Boolean
    $required_permission: Int
  ) {
    addBotFeature(
      bot_id: $bot_id
      feature_id: $feature_id
      is_bot_active: $is_bot_active
      required_permission: $required_permission
    ) {
      id
      name
    }
  }
`;

export const GET_FACEBOOK = gql`
  query getBot($id: Int) {
    getBot(id: $id) {
      id
      facebook_access_key
    }
  }
`;

export const GET_PERSONA = gql`
  query getPersona($bot_id: Int, $type: String) {
    getPersona(bot_id: $bot_id, type: $type) {
      id
      name
      profile_picture
      type
    }
  }
`;

export const CREATE_PERSONA = gql`
  mutation (
    $bot_id: Int!
    $name: String!
    $profile_picture: String!
    $user_id: Int
    $type: String
  ) {
    createPersona(
      bot_id: $bot_id
      name: $name
      profile_picture: $profile_picture
      user_id: $user_id
      type: $type
    ) {
      id
      name
      profile_picture
      type
    }
  }
`;

export const UPDATE_PERSONA = gql`
  mutation ($id: Int!, $name: String!, $profile_picture: String!) {
    updatePersona(id: $id, name: $name, profile_picture: $profile_picture) {
      id
      name
      profile_picture
      type
    }
  }
`;

export const GET_FEATURES = gql`
  query getFeatures {
    getFeatures {
      features {
        id
        slug
        name
        type
        description
        is_editable
      }
    }
  }
`;

export const REMOVE_BOT_FEATURE = gql`
  mutation ($bot_id: Int!, $feature_id: [Int]!) {
    removeBotFeature(bot_id: $bot_id, feature_id: $feature_id) {
      id
      name
    }
  }
`;

export const CREATE_BOT = gql`
  mutation ($name: String!, $organization_id: Int!, $types: [Int]) {
    createBot(name: $name, organization_id: $organization_id, types: $types) {
      id
      name
      status
      platforms {
        webchat
        messenger
      }
      organization {
        id
        name
      }
      created_at
    }
  }
`;

const UPDATE_HOMEPAGE_BANNER = gql`
  mutation (
    $id: Int!
    $description: String
    $enable: Boolean
    $image: String
    $link: String
    $title: String
    $button_title: String
  ) {
    updateHomepageBanner(
      id: $id
      description: $description
      enable: $enable
      image: $image
      link: $link
      title: $title
      button_title: $button_title
    ) {
      homepage_banner {
        description
        enable
        image
        link
        title
        button_title
      }
    }
  }
`;

export const GET_BOT_FEATURES = gql`
  query getBot($id: Int) {
    getBot(id: $id) {
      id
      features {
        id
        slug
        name
        is_bot_active
        required_permission
      }
    }
  }
`;

export const UPDATE_PLATFORM = gql`
  mutation ($id: Int!, $messenger: Boolean, $webchat: Boolean) {
    updatePlatform(id: $id, messenger: $messenger, webchat: $webchat) {
      id
      platforms {
        messenger
        webchat
      }
    }
  }
`;
