import gql from 'graphql-tag';

import { apiV3 } from "./apiV3";

import { User } from "../../types/user.type";

type LoginArgs = {
  email: string;
  password: string;
}

type LoginResponse = {
  login: {
    user: Omit<User, "token">;
    token: string;
  };
};

type ForgetPasswordArgs = {
  email: string;
};

// NOTE: user.permissions, organization.verified, organization.permissions, invitation.id missing on api v3
const LOGIN = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        email
        firstname
        lastname
        language_code
        role
        organization {
          id
          name
          invitations {
            email
            token
          }
        }
      }

      token
    }
  }
`;

export async function login(
  variables: LoginArgs
): Promise<LoginResponse["login"]> {
  const { data }: { data: LoginResponse } = await apiV3.mutate({
    mutation: LOGIN,
    variables,
  });

  return data.login;
}

const FORGET_PASSWORD = gql`
  mutation($email: String!) {
    forgetPassword(email: $email)
  }
`

export async function forgetPassword(variables: ForgetPasswordArgs): Promise<boolean> {
  const { data } = await apiV3.mutate({
    mutation: FORGET_PASSWORD,
    variables,
  });
  const { forgetPassword }: { forgetPassword: boolean } = data;
  return forgetPassword
}

