import React from 'react';
import Buttons from './Buttons';
import Text from './Text';

export default ({ buttons, text }) => (
  <div className="webchat_card rad-5px flex column space-between m-xs">
    <Text text={text} />
    <Buttons buttons={buttons} />
  </div>
);
