import React, { memo, useContext } from 'react';
import styled from 'styled-components';
import { QAContext } from '../../context/QAContext';
import {Topic as TopicType} from '../../../../types/qa/topics';
import { Icon } from '@ask-mona/ui';
import {Api} from "../../../../api/graphql/hooks/useTopics";

interface Props {
  close: () => any;
  api: Api;
  topic: TopicType;
}

interface ButtonsProps {
  readonly background: string;
}

const EditFallback = memo((props: Props) => {
  const { state } = useContext(QAContext);

  return (
    <div className="w-4 has-background-white">
      <div className="p-md has-text-white has-background-primary relative">
        {state.wording.talkToHumain}
        <span className="has-text-white absolute top right p-md pointer">
          <Icon icon="fas fa-times" onClick={() => props.close()} />
        </span>
      </div>
      <div className="p-md">
        {props.topic.is_fallback ? state.wording.disableTalkToHuman : state.wording.enableTalkToHuman}
        <WrapperButtons>
          <Button background={'#e08585'} onClick={props.close}>
            {state.wording.cancel}
          </Button>
          <Button
            background={'#5054be'}
            onClick={() => {
              props.api.updateTopic(
                  props.topic.id,
                  state.language,
                  'isFallback',
                  !props.topic.is_fallback
              );
              props.close();
            }}
          >
            {props.topic.is_fallback ? state.wording.disable : state.wording.enable}
          </Button>
        </WrapperButtons>
      </div>
    </div>
  );
});

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
`;

const Button = styled.button<ButtonsProps>`
  display: flex;
  padding: 0.25em 1em;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  border-radius: 3px;
  border: none;
  background-color: ${({ background }) => background};
  color: white;
`;

export default EditFallback;
