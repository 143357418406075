export default {
  title: ['Gestion des messages', 'Message management'],
  conv: ['Conversation', 'Conversation'],
  treat: ['A traiter', 'To be processed'],
  filter: ['Filtrer', 'Filter'],
  msgUnread: ['Messages non lus', 'Unread message'],
  msgWaiting: [
    "Messages en attente d'une réponse",
    'Messages waiting for a response',
  ],
  msgDesactivated: [
    'Conversations où le chatbot est désactivé',
    'Conversations where the chatbot is disabled',
  ],
  waiting: ['En attente', 'On hold'],
  disabled: ['Chatbot désactivé', 'Chatbot disabled'],
  send: ['Envoyé le ', 'Sent on'],
  archive: ['Archiver', 'Archive'],
  unarchive: ['Déplacer vers la messagerie', 'Move to email'],
  desactivate: [
    'Désactiver le chatbot pour cet utilisateur',
    'Disable the chatbot for this user',
  ],
  reactivate: [
    'Le chatbot est désactivé pour cet utilisateur',
    'The chatbot is disabled for this user',
  ],
  activationWarning: [
    "Pensez à le réactiver une fois l'échange terminé ",
    'Remember to reactivate it once the exchange is over',
  ],
  noneSelected: ['Aucun message sélectionné', 'No message selected'],
  sendBy: ['Envoyé par', 'Sent by'],
  directAnswer: ['Répondre directement', 'Answer directly'],
  selectAnswer: ['Sélectionner une réponse', 'Select an answer'],
  selectQAnswer: [
    'Sélectionner la question/réponse',
    'Select the question/answer',
  ],
  noLogs: [
    'Aucun message dans cette boite de réception',
    'No messages in this inbox',
  ],
  reload: ['Recharger', 'Reload'],
};
