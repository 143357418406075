import React, { useState, useEffect } from 'react';
import Client from '../Client';

type Intent = {
  id: number;
  title: string;
  groupEntities: GroupEntities;
};

type GroupEntitie = {
  id: number;
  title: string;
};

type GroupEntities = Array<GroupEntitie>;
type Intents = Array<Intent>;

const useIntents = (botId: number, token: string, languageCode: string) => {
  const [intents, setIntents] = useState<Intents>([]);
  const [loading, setLoading] = React.useState<string>('');
  const [error, setError] = useState<any>();

  let client = new Client(`${process.env.REACT_APP_HTTP_LINK_V3}`, token);

  useEffect(() => {
    const getIntents = async () => {
      try {
        if (client) {
          let api = client.Intents;
          let res = await api.getIntents({ botId, languageCode });
          if (res) setIntents(res.data.getIntents.intents);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading('load');
      }
    };
    getIntents();
  }, []);

  return {
    intents,
    loading,
    error,
  };
};

export default useIntents;
