const Qr = (template) => {
  switch (template) {
    case 'template1':
      return {
        v2: true,
        boxShadow: 'none',
        hover: false,
      };
    case 'template2':
      return {
        v2: true,
        boxShadow: '',
        hover: false,
      };
    default:
      break;
  }
};

export default Qr;
