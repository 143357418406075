import React, { useState, useEffect } from 'react';
import { Field, Button, Icon } from '@ask-mona/ui';
import {
  _defaultTranslation,
  _formatTranslation,
} from '../../_tools/translation';
import FlagIcon from '../../components/FlagIcon';

import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import languageTranslations from "../../translations/languages";

const styles = {
  input: {
    color: '#2D2F7B',
  },
};

const Description = (props) => {
  const {
    m: { w, lang, bot_lang, bot },
    event: { translations },
  } = props;

  let tr = _formatTranslation(translations || [_defaultTranslation(bot.default_language)]);
  const [languages, setLang] = useState(Object.keys(tr));

  return (
    <div className="p-md has-text-primary-ter w-60p overflow-auto">
      <div className="p-b-xs m-b-xs border-b-white-ter flex row space-between items-center relative">
        {w.subsections.eventTranslation}
          <div className="flex">
            {bot_lang.map((l) => {
              if (languages.find((lang) => l === lang)) return null;
              return (
                <Button
                  key={l}
                  className="is-outlined is-borderless shrink"
                  onClick={() => {
                    setLang(languages.concat(l));
                  }}
                >
                  <FlagIcon code={languageTranslations.relatedCountry[l]} />
                </Button>
              );
            })}
          </div>
      </div>
      <div className="flex row overflow-scroll">
          {languages.map((language) => (
              <Translation {...props} tr={tr[language]} code={language} />
          ))}
      </div>
    </div>
  );
};

const Translation = ({ m: { w, lang }, code, tr, fct, classes}) => {
  let translation = tr;
  if (!tr) translation = _formatTranslation([_defaultTranslation(code)])[code];
  let title = translation.short_title.value
    ? translation.short_title
    : translation.long_title;
  let {
    price,
    date,
    description,
    url,
    url_ticketing,
    more_infos,
    practical_infos,
    anecdote,
  } = translation;

  const [isUrlValid, setIsUrlValid] = useState(true);
  const [isUrlTicketing, setIsUrlTicketing] = useState(true);

  useEffect(() => {
    if ((url && url.value && url.value.match(regexUrl)) || url.value === '') {
      setIsUrlValid(true);
    } else {
      setIsUrlValid(false);
    }
  }, [url]);

  useEffect(() => {
    if (
      (url_ticketing &&
        url_ticketing.value &&
        url_ticketing.value.match(regexUrl)) ||
      url_ticketing.value === ''
    ) {
      setIsUrlTicketing(true);
    } else {
      setIsUrlTicketing(false);
    }
  }, [url_ticketing]);

  useEffect(() => {
    fct.handleBadUrl(isUrlValid);
  }, [isUrlValid]);

  useEffect(() => {
    fct.handleBadUrlTicketing(isUrlTicketing);
  }, [isUrlTicketing]);

  const regexUrl = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  const helperTextContent = (translationType) => {
    if (translationType === url && !isUrlValid) {
      return (
        <>
          <span className="is-size-7" style={{ color: 'orangered' }}>
            {w.event.urlValid}
          </span>
        </>
      );
    }
    if (translationType === url_ticketing && !isUrlTicketing) {
      return (
        <>
          <span className="is-size-7" style={{ color: 'orangered' }}>
            {w.event.urlValid}
          </span>
        </>
      );
    }
    if (
      translationType === title &&
      translationType.value.length < translationType.maxLength
    ) {
      return (
        <>
          <span>
            {translationType.value.length}/{translationType.maxLength} &nbsp;
          </span>
        </>
      );
    } else if (
      translationType === title &&
      translationType.value.length >= translationType.maxLength
    ) {
      return (
        <>
          <span>
            {translationType.value.length}/{translationType.maxLength} &nbsp;
          </span>
          <span className="is-size-7" style={{ color: 'darkorange' }}>
            {' '}
            {w.event.titleLimitWarningMessage}
          </span>
        </>
      );
    }

    if (translationType.value.length < translationType.maxLength - 50) {
      return (
        <>
          <span>
            {translationType.value.length}/{translationType.maxLength} &nbsp;
          </span>
        </>
      );
    } else if (translationType.value.length < translationType.maxLength) {
      return (
        <>
          <span>
            {translationType.value.length}/{translationType.maxLength} &nbsp;
          </span>
          <span className="is-size-7" style={{ color: 'darkorange' }}>
            {' '}
            {w.event.fieldLimitWarningMessage}
          </span>
        </>
      );
    } else {
      return (
        <>
          <span>
            {translationType.value.length}/{translationType.maxLength} &nbsp;
          </span>
          <span className="is-size-7" style={{ color: 'orangered' }}>
            {' '}
            {w.event.fieldLimitPassedWarningMessage}
          </span>
        </>
      );
    }
  };

  return (
    <div className="m-xs flex grow-1 column minw-40p">
      <div className="flex center p-md">
        <FlagIcon code={languageTranslations.relatedCountry[code]} />
      </div>
      <Field title={`${w.generic.title} *`} className="m-b-md">
        <TextField
          onChange={fct.setTranslation}
          inputProps={{
            name: title.name,
            value: title.value,
            'data-lang': title['data-lang'],
            className: classes.input,
          }}
          helperText={helperTextContent(title)}
          fullWidth
        />
      </Field>
      <h3 className="p-b-xs m-b-xs border-b-white-ter">{w.event.tellMeMore}</h3>
      <Field className="m-b-md m-t-sm" title={w.event.description}>
        <TextField
          multiline={true}
          onChange={fct.setTranslation}
          inputProps={{
            name: description.name,
            value: description.value,
            'data-lang': description['data-lang'],
            className: classes.input,
          }}
          helperText={helperTextContent(description)}
          fullWidth
        />
      </Field>
      <Field className="m-b-md" title={w.event.anecdote}>
        <TextField
          multiline={true}
          onChange={fct.setTranslation}
          inputProps={{
            name: anecdote.name,
            value: anecdote.value,
            'data-lang': anecdote['data-lang'],
            className: classes.input,
          }}
          helperText={helperTextContent(anecdote)}
          fullWidth
        />
      </Field>
      <h3 className="p-b-xs m-b-xs border-b-white-ter">
        {w.event.practicalInfo}
      </h3>
      <Field className="m-b-md m-t-sm" title={w.event.moreInfoSchedule}>
        <TextField
          multiline={true}
          onChange={fct.setTranslation}
          inputProps={{
            name: date.name,
            value: date.value,
            'data-lang': date['data-lang'],
            className: classes.input,
          }}
          helperText={helperTextContent(date)}
          fullWidth
        />
      </Field>
      <Field className="m-b-md" title={w.event.moreInfoPricing}>
        <TextField
          multiline={true}
          onChange={fct.setTranslation}
          inputProps={{
            name: price.name,
            value: price.value,
            'data-lang': price['data-lang'],
            className: classes.input,
          }}
          helperText={helperTextContent(price)}
          fullWidth
        />
      </Field>
      <Field className="m-b-md" title={w.event.moreInfo}>
        <TextField
          multiline={true}
          onChange={fct.setTranslation}
          inputProps={{
            name: practical_infos.name,
            value: practical_infos.value,
            'data-lang': practical_infos['data-lang'],
            className: classes.input,
          }}
          helperText={helperTextContent(practical_infos)}
          fullWidth
        />
      </Field>
      {more_infos && (
        <Field className="m-b-md" title={w.event.other}>
          <TextField
            multiline={true}
            onChange={fct.setTranslation}
            inputProps={{
              name: more_infos.name,
              value: more_infos.value,
              'data-lang': more_infos['data-lang'],
              className: classes.input,
            }}
            helperText={helperTextContent(more_infos)}
            fullWidth
          />
        </Field>
      )}
      <Field className="m-b-md" title={w.event.linkToWebsite}>
        <TextField
          multiline={true}
          onChange={fct.setTranslation}
          inputProps={{
            name: url.name,
            value: url.value,
            'data-lang': url['data-lang'],
            className: classes.input,
          }}
          helperText={helperTextContent(url)}
          fullWidth
        />
      </Field>
      <Field className="m-b-md" title={w.event.linkToResa}>
        <TextField
          multiline={true}
          onChange={fct.setTranslation}
          inputProps={{
            name: url_ticketing.name,
            value: url_ticketing.value,
            'data-lang': url_ticketing['data-lang'],
            className: classes.input,
          }}
          helperText={helperTextContent(url_ticketing)}
          fullWidth
        />
      </Field>
    </div>
  );
};

export default withStyles(styles)(Description);
