import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  FirstTriggerLang,
  LangSwitcher,
  DaliLogic,
  AdvanceLogic,
  SaveButton,
} from './header';

import ScenariosContext from '../../containers/scenarios/context/scenariosContext';

export const Heading = (props) => {
  const { subfocus, focus } = props;

  return (
    <div
      className="sticky top has-background-primary-light p-md "
      style={{ zIndex: 2 }}
      id="current_heading"
    >
      <div className="flex items-center space-between">
        {focus.key === 'fallback' ? (
          <span>Autres</span>
        ) : (
          <span>{`${focus.name}${subfocus ? ` > ${subfocus.name}` : ''}`}</span>
        )}
        <div className="flex items-center space-evenly row">
          <FirstTriggerLang {...props} />
          <LangSwitcher {...props} />
          <DaliLogic {...props} />
          <AdvanceLogic {...props} />
          <SaveButton {...props} />
        </div>
      </div>
    </div>
  );
};

export const Board = (props) => {
  const {
    m: { w, user },
    btns,
    dispatch,
    focus,
    lang,
    subfocus,
    cpyArray,
    cpyFolder,
    advanced,
  } = props;

  const scenariosContext = useContext(ScenariosContext);

  return (
    <BoardPosition>
      <BoardContainer>
        <BoardTitle>{w.generic.add}</BoardTitle>
        <BoardItems>
          {btns.map(({ name, icon, type, ...b }) => {
            if (user.organization.id !== 1 && name === 'list') return null;
            if (
              name === 'menu' &&
              !subfocus &&
              focus.content[lang]?.includes('LOGIC_MENU')
            )
              return null;
            if (
              name === 'menu' &&
              subfocus?.content[lang]?.includes('LOGIC_MENU')
            )
              return null;
            if (!advanced && (name === 'random' || name === 'events'))
              return null;
            else
              return (
                <BoardButton
                  {...b}
                  key={b.id}
                  onClick={() =>
                    dispatch({
                      type: 'addElement',
                      payload: {
                        name: type,
                        value: null,
                        func: advanced
                          ? null
                          : scenariosContext.functionalitiesSubsbarFocus ===
                            'content'
                          ? null
                          : scenariosContext.functionalitiesSubsbarFocus,
                      },
                    })
                  }
                >
                  {w.generic[name]}
                </BoardButton>
              );
          })}
          {cpyArray && cpyArray.length > 0 && (
            <BoardButton
              color="secondary"
              onClick={() =>
                dispatch({ type: 'toggleCpyFolder', payload: !cpyFolder })
              }
            >
              {w.scenario.copied}
            </BoardButton>
          )}
        </BoardItems>
      </BoardContainer>
    </BoardPosition>
  );
};

export { default as Sidebar } from './Sidebar';
export { default as Subsbar } from './PersonalScenariosSubsbar';

const BoardPosition = styled.div`
  /* height: 100%; */
  width: 99%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoardContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  background-color: white;
  border-radius: 8px;
  padding: 25px 38px 24px 35px;
  box-shadow: 0px 4px 16px 0px #aaaaaa;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  position: absolute;
  bottom: 0;
  margin: 0 auto 10px;
  @media (max-width: 1400px) {
    padding: 10px 20px 10px 20px;
    margin: 0 10px 10px;
  }
`;

const BoardTitle = styled.p`
  width: 90px;
  font-size: 14px;
  font-weight: 800;
  line-height: 15px;
  color: ${(p) => p.theme.colors.primary};
  text-transform: uppercase;
  margin-bottom: 19px;
  letter-spacing: 2px;
  @media (max-width: 1500px) {
    font-size: 12px;
  }
  @media (max-width: 1300px) {
    font-size: 10px;
  }
`;
const BoardItems = styled.div`
  margin-left: 1px;
  border-radius: 4px;
  padding: 0px 17px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${(p) => p.theme.colors.primary};
  cursor: pointer;
`;

const BoardButton = styled.div`
  color: ${(p) => p.theme.colors.primary};
  display: flex;
  height: 35px;
  max-width: 40px;
  font-size: 12px;
  flex-wrap: nowrap;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;
  padding-right: 22px;
  padding-left: 22px;
  border-right: 1px solid ${(p) => p.theme.colors.primary};
  &:last-of-type {
    padding-right: 0;
    border-right: none;
  }
  &:first-of-type {
    padding-left: 0;
  }

  @media (max-width: 1500px) {
    height: 30px;
    font-size: 10px;
  }
  @media (max-width: 1300px) {
    height: 20px;
    font-size: 8px;
  }
`;
