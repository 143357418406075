import React from 'react';
import Button from '@material-ui/core/Button';
import { _sendFeedback } from '../../../_tools/ui';
import { _checkRanges } from '../../../_tools/other';
import { _generatePath } from '../Reducer';

const SaveButton = (props) => {
  const {
    m: { w, bot_lang },
    subfocus,
    addToSave,
    lang,
    focus,
    Bot,
    subModules,
    modules,
    dispatch,
  } = props;
  return (
    <Button
      color="secondary"
      variant="contained"
      onClick={async () => {
        _sendFeedback('warning', 'loader');
        const { key, ...other } = subfocus || focus;
        let res = null;
        addToSave && addToSave();
        other.content[lang] = other.content[lang].map((e, i) => {
          let next = other.content[lang][i + 1];
          if (!e.quick_replies || !e.quick_replies.length)
            delete e['quick_replies'];
          else if (next && typeof next !== 'string') delete e['quick_replies'];
          return e;
        });
        const contentDefault = other.content[lang];
        bot_lang.forEach((el) => {
          if (other.content[el] && !other.content[el].length) {
            delete other.content[el];
          }
        });
        if (contentDefault.includes('LOGIC_MENU')) {
          contentDefault.push(
            contentDefault.splice(contentDefault.indexOf('LOGIC_MENU'), 1)[0]
          );
        }
        if (key === 'p:talk_to_human') {
          const lastEl = other.content[lang][other.content[lang].length - 1];
          other.content[lang].map((el) => (el.mail = false));
          lastEl.mail = true;
        }
        if (key === 'first') {
          delete other['trigger'];
          res = await Bot._updateModule({
            path: ['module', 'content'],
            module: { content: other['content'] },
          });
        } else if (key === 'fallback') {
          res = await Bot._updateModule({
            path: ['module', 'trigger', `p:${focus.id}`, 'fallback', 'content'],
            module: { content: other['content'] },
          });
        } else {
          if ('trigger' in focus) {
            if (!subfocus) {
              res = await Bot._updateModule({
                path: ['module', 'trigger', `p:${focus.id}`, 'content'],
                module: { content: other['content'] },
              });
            } else {
              res = await Bot._updateModule({
                path: _generatePath(focus, subfocus),
                module: { [key]: other },
              });
            }
          } else if (focus && !subfocus) {
            res = await Bot._updateModule({
              path: _generatePath(focus),
              module: { [key]: other },
            });
          } else {
            res = await Bot._updateModule({
              path: _generatePath(subfocus),
              module: { [key]: other },
            });
          }
        }
        if (res.success) {
          if (subfocus && !('trigger' in focus)) {
            let newSubModules = subModules.map((m) =>
              m.key === key ? subfocus : m
            );
            dispatch({ type: 'setSubModules', payload: newSubModules });
          } else {
            if (subfocus) {
              const { key, ...other } = subfocus;
              let newModules = modules.map((m) =>
                m.key === focus.key
                  ? { ...focus, trigger: { ...focus.trigger, [key]: other } }
                  : m
              );
              dispatch({ type: 'setModules', payload: newModules });
            } else {
              let newModules = modules.map((m) => (m.key === key ? focus : m));
              dispatch({ type: 'setModules', payload: newModules });
            }
          }
          _sendFeedback('success', w.success.saved);
        } else _sendFeedback('danger', w.error.general);

        if (
          focus.dali &&
          focus.content.func.default.daliType === 'quiz' &&
          !_checkRanges(focus.content.func.quiz)
        )
          _sendFeedback('danger', w.scenario.wtf);
      }}
    >
      {w.generic.save}
    </Button>
  );
};

export { SaveButton };
