const Message = (template) => {
  switch (template) {
    case 'template2':
      return {
        boxShadow: '',
        v2: true,
        borderRadius: '25px',
        borderRadiusBottom: false,
        borderRadiusTop: true,
      }
    case 'template3':
      return {
        borderRadius: '20px',
        boxShadow: '',
        v2: true,
        borderRadiusBottom: false,
        borderRadiusTop: false,
      }
    case 'template4':
      return {
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 3px 15px',
        v2: true,
        borderRadius: '25px',
        borderRadiusBottom: false,
        borderRadiusTop: false,
      }
    case 'template5':
      return {
        boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.5)',
        v2: true,
        borderRadius: '15px',
        borderRadiusBottom: false,
        borderRadiusTop: false,
      }
    case 'template6':
      return {
        boxShadow: '',
        v2: true,
        borderRadius: '25px',
        borderRadiusBottom: true,
        borderRadiusTop: false,
      }
    case 'template7':
      return {
        boxShadow: '',
        v2: false,
        borderRadius: '5px',
        borderRadiusBottom: false,
        borderRadiusTop: false,
      }
    default:
      break;
  }
};

export default Message;
