const Catchphrase = (template) => {
  switch (template) {
    case 'template1':
      return {
        catchphrase: {
          bottom: '100px',
          right: '0px',
          borderRadius: '',
          border: 'none',
        },
        widget: {
          paddingEntryReduced: '1.8rem .6rem',
          catchphrase: {
            display: '',
            isClosable: false,
            borderCorner: false,
          },
          v2: true,
          opt1: true,
          reduce: false,
          borderRadius: '45px',
          crossTop: '13px',
          crossRight: '13px',
          height: '',
          template: 1
        }
      };
    case 'template2':
      return {
        catchphrase: {
          bottom: '100px',
          right: '0px',
          borderRadius: '',
          border: 'none',
        },
        widget: {
          paddingEntryReduced: '1.8rem .6rem',
          catchphrase: {
            display: '',
            isClosable: false,
            borderCorner: false,
          },
          v2: true,
          opt1: true,
          reduce: true,
          borderRadius: '',
          height: '',
          template: 2,
        },
      };
    case 'template3':
      return {
        catchphrase: {
          bottom: '86px',
          right: '66px',
          borderRadius: '45px',
          border: 'none',
        },
        widget: {
          catchphrase: {
            color: '',
            display: '',
            isClosable: true,
            borderCorner: false,
            crossTop: '11px',
            crossRight: '13px',
            afterOption: true,
          },
          v2: true,
          opt1: false,
          reduce: false,
          borderRadius: '45px',
          crossTop: '13px',
          crossRight: '13px',
          height: '',
          template: 3,
        },
      };
    case 'template4':
      return {
        catchphrase: {
          bottom: '51px',
          right: '98px',
          borderRadius: '45px',
          border: 'none',
        },
        widget: {
          catchphrase: {
            color: '',
            display: '',
            isClosable: true,
            borderCorner: false,
            crossTop: '11px',
            crossRight: '13px',
          },
          v2: true,
          opt1: false,
          reduce: false,
          borderRadius: '45px',
          crossTop: '13px',
          crossRight: '13px',
          height: '',
          template: 4,
        },
      };
    case 'template5':
      return {
        catchphrase: {
          bottom: '51px',
          right: '98px',
          borderRadius: '45px',
          border: 'none',
        },
        widget: {
          catchphrase: {
            color: '',
            display: '',
            isClosable: true,
            borderCorner: true,
            crossTop: '11px',
            crossRight: '13px',
          },
          v2: true,
          opt1: false,
          reduce: false,
          borderRadius: '45px',
          crossTop: '13px',
          crossRight: '13px',
          height: '',
          template: 5,
        },
      };
    default:
      break;
  }
};

export default Catchphrase;
