import React, { useReducer, useState, useEffect } from 'react';
import Bot from '../_api/bot';
import {
  moduleTemplate,
  _findDup,
  _generatePath,
  _extractIds,
} from '../components/scenario/Reducer';
import { _sendFeedback } from '../_tools/ui';

import { Route } from 'react-router-dom';
import { withMain } from '../hoc/main';
import { withApi } from '../hoc/api';
import Foundations from '../components/base/Foundations';
import Sidebar from '../components/base/Sidebar';
import Header from '../components/base/Header';
import ErrorBoundary from '../components/ErrorBoundary';
import Functionalities from './scenarios/Functionalities';
import PersonalScenarios from './scenarios/PersonalScenarios';
import FunctionalitiesSidebar from './scenarios/sidebar/FunctionalitiesSidebar';
import PersonalScenariosSidebar from './scenarios/sidebar/PersonalScenariosSidebar';

import { LangSwitcher } from '../components/scenario/header/LangSwitcher';
import { SaveButton } from '../components/scenario/header/SaveButton';
import { DaliLogic } from '../components/scenario/header/DaliType';
import { EditPopover } from './scenarios/header/EditPopover';

import { _reducer, initialState } from '../components/scenario/Reducer';

const Scenarios = (props) => {
  const {
    m: { w, bot, client, menu, sectionID },
  } = props;

  const [functionalitiesstate, functionalitiesdispatch] = useReducer(
    _reducer,
    initialState
  );

  const [personalscenariosstate, personalscenariosdispatch] = useReducer(
    _reducer,
    initialState
  );

  const [update, setUpdate] = useState(false);

  const [subID, setSubID] = useState(0);
  const [botID, setBotID] = useState(bot.id);

  useEffect(() => {
    generateMenu(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (botID !== bot.id) {
      setBotID(bot.id);
      generateMenu(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bot.id]);

  // Fetching Scenarios for Functionnalities section
  useEffect(() => {
    functionalitiesdispatch({ type: 'setDefaultLang', payload: bot.default_language });
    const fetchScenario = async () => {
      let api = new Bot(client, bot.id);
      functionalitiesdispatch({ type: 'setLoading', payload: true });
      let res = await api._getModules();
      if (res.success && res.json) {
        if ('activated' in res.json && !res.json.activated) {
          functionalitiesdispatch({
            type: 'setActivated',
            payload: res.json.activated,
          });
        } else {
          let keys = Object.keys(res.json.trigger);
          let modules = [{ key: 'first', ...res.json }];
          modules.push({ key: 'fallback', ...res.json.fallback });
          keys.forEach((k) => modules.push({ key: k, ...res.json.trigger[k] }));
          let ids = _extractIds(modules);
          modules = modules.filter((m) => !m.custom && m.key !== 'location');
          let feats = bot.features;

          if (!feats.some((el) => el.slug === 'price'))
            modules = modules.filter((el) => el.key !== 'p:price');
          if (!feats.some((el) => el.slug === 'intinerary'))
            modules = modules.filter((el) => el.key !== 'p:intinerary');
          if (!feats.some((el) => el.slug === 'prog'))
            modules = modules.filter((el) => el.key !== 'p:prog');
          modules = modules.filter((el) => {
            if (
              el.id.toLowerCase() === 'start_lang' &&
              props.m.user.user.permissions !== 1
            )
              return false;
            else return true;
          });

          let hasTriggerLang = modules[0].content.default.some(
            (c) => c === 'LOGIC_LANG'
          );
          if (hasTriggerLang) {
            let start_index = modules.findIndex(
              (m) => m.key === 'p:start_lang'
            );
            let end_index = modules.findIndex((m) => m.key === 'p:end_lang');
            let start = modules.splice(start_index, 1);
            let end = modules.splice(end_index - 1, 1);
            modules.splice(1, 0, start[0]);
            modules.splice(2, 0, end[0]);
          }

          let payloads = modules.map((m, i) => ({
            id: i,
            value: m.name,
            key: m.id,
          }));
          payloads.find((el) => {
            if (el.value === undefined) el.value = '';
          });
          if (!functionalitiesstate.focus && modules.length)
            functionalitiesdispatch({ type: 'setFocus', payload: modules[0] });
          functionalitiesdispatch({ type: 'setModules', payload: modules });
          functionalitiesdispatch({ type: 'setIds', payload: ids });
          functionalitiesdispatch({ type: 'setApi', payload: api });
          functionalitiesdispatch({ type: 'setPayloads', payload: payloads });
        }
      } else functionalitiesdispatch({ type: 'setError', payload: res.error });
      functionalitiesdispatch({ type: 'setLoading', payload: false });
    };
    fetchScenario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.m.bot, update]);

  // Fetching Scenarios for Personal Scenarios section
  useEffect(() => {
    personalscenariosdispatch({ type: 'setDefaultLang', payload: bot.default_language });
    const fetchScenario = async () => {
      let api = new Bot(client, bot.id);
      personalscenariosdispatch({ type: 'setLoading', payload: true });
      let res = await api._getModules();
      if (res.success && res.json) {
        if ('activated' in res.json && !res.json.activated) {
          personalscenariosdispatch({
            type: 'setActivated',
            payload: res.json.activated,
          });
        } else {
          let keys = Object.keys(res.json.trigger);
          let modules = [];
          let subs = [];
          keys.forEach((k) => modules.push({ key: k, ...res.json.trigger[k] }));
          let ids = _extractIds(modules);
          subs = modules.filter((m) => m.custom && m.tag);
          modules = modules.filter((m) => m.custom && !m.tag);
          modules = modules.filter((el) => {
            if (
              el.id.toLowerCase() === 'start_lang' &&
              props.m.user.user.permissions !== 1
            )
              return false;
            else return true;
          });
          if (!personalscenariosstate.focus && modules.length)
            personalscenariosdispatch({
              type: 'setFocus',
              payload: modules[0],
            });
          personalscenariosdispatch({ type: 'setIds', payload: ids });
          personalscenariosdispatch({ type: 'setModules', payload: modules });
          personalscenariosdispatch({ type: 'setSubModules', payload: subs });
          personalscenariosdispatch({ type: 'setApi', payload: api });

          // HANDLE FORCE
          if (props.force || props.subforce) {
            let module = modules.find((m) => m.id === props.force);
            if (module)
              personalscenariosdispatch({ type: 'setFocus', payload: module });
            else {
              let id = _findDup(props.force, ids);
              module = moduleTemplate(
                { id, name: id, disable: false, trigger: {} },
                false,
                ids
              );
              _sendFeedback('warning', 'loader');
              const { key, ...other } = module;
              let res = await api._createModule({
                path: _generatePath(module),
                module: { [key]: { ...other } },
              });
              if (res.success) {
                personalscenariosdispatch({
                  type: 'setFocus',
                  payload: module,
                });
                personalscenariosdispatch({
                  type: 'setIds',
                  payload: ids.concat(id),
                });
                _sendFeedback('success', w.success.saved);
              } else _sendFeedback('danger', w.error.general);
            }
            if (props.subforce) {
              let reg = new RegExp(props.subforce);
              let subModule = Object.keys(module.trigger || {}).find((m) =>
                module.trigger[m].id.match(reg)
              );
              if (subModule)
                personalscenariosdispatch({
                  type: 'setSubFocus',
                  payload: { key: subModule, ...module.trigger[subModule] },
                });
              else {
                let id = _findDup(props.subforce, ids);
                subModule = moduleTemplate({
                  id,
                  name: id,
                  tag: props.folder || '',
                  jump: module.id,
                  disable: false,
                  undeletable: true,
                });
                const { key, ...other } = subModule;
                module = {
                  ...module,
                  trigger: { ...module.trigger, [key]: other },
                };
                {
                  const { key, ...other } = subModule;
                  let res = await api._createModule({
                    path: _generatePath(module).concat(['trigger', key]),
                    module: { [key]: other },
                  });
                  if (res.success) {
                    personalscenariosdispatch({
                      type: 'setFocus',
                      payload: module,
                    });
                    personalscenariosdispatch({
                      type: 'setSubFocus',
                      payload: subModule,
                    });
                    personalscenariosdispatch({
                      type: 'setIds',
                      payload: ids.concat(id),
                    });
                    _sendFeedback('success', w.success.saved);
                  } else _sendFeedback('danger', w.error.general);
                }
              }
            }
          }
        }
      } else
        personalscenariosdispatch({ type: 'setError', payload: res.error });
      personalscenariosdispatch({ type: 'setLoading', payload: false });
    };

    fetchScenario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.m.bot, update]);

  //Refresh payloads for Personal Scenarios Section
  useEffect(() => {
    let payloads = [];
    if (personalscenariosstate.focus) {
      if ('trigger' in personalscenariosstate.focus) {
        let triggers = personalscenariosstate.focus.trigger;
        payloads = Object.keys(triggers).map((k) => ({
          id: triggers[k].id,
          value: triggers[k].name,
          key: triggers[k].id,
          tag: triggers[k].tag,
        }));
        if (props.subforce)
          payloads = payloads.filter((e) => e.tag === props.subforce);
      } else if (
        personalscenariosstate.focus &&
        personalscenariosstate.subModules
      ) {
        let reg = new RegExp(`^${personalscenariosstate.focus.id}/`);
        payloads = personalscenariosstate.subModules
          .filter((m) => m.tag.match(reg))
          .map((m) => ({ id: m.id, value: m.name, key: m.id }));
      }
    }
    personalscenariosdispatch({ type: 'setPayloads', payload: payloads });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalscenariosstate.focus, personalscenariosstate.subModules, update]);

  const changeSub = (id) => {
    setSubID(id);
  };

  const generateMenu = (props) => {
    let curr = menu.find((m) => m.id === sectionID);
    let m = curr.menu[0];
    m && changeSub(m.id);
    m && props.history.push(m.path);
  };

  return (
    <ScenariosContainer
      {...props}
      functionalitiesstate={functionalitiesstate}
      functionalitiesdispatch={functionalitiesdispatch}
      personalscenariosstate={personalscenariosstate}
      personalscenariosdispatch={personalscenariosdispatch}
      update={update}
      setUpdate={setUpdate}
      subID={subID}
      botID={botID}
      changeSub={changeSub}
    />
  );
};

const ScenariosContainer = (props) => {
  const {
    m: { sectionID, menu },
    changeSub,
    update,
    setUpdate,
    subID,
  } = props;
  const preURL = props.match.url;

  const [section] = useState(menu.find((s) => s.id === sectionID) || {});
  const [sidebarheight, setSidebarheight] = useState(0);
  const [functionalitieSidebarActive, setFunctionalitieSidebarActive] =
    useState(true);
  const [personalScenarioSidebarActive, setPersonalScenarioSidebarActive] =
    useState(true);

  const setHeight = (height) => {
    setSidebarheight(height);
  };

  const toggleUpdate = () => {
    setUpdate(!update);
  };

  const toggleFunctionalitieSidebarActive = () => {
    setFunctionalitieSidebarActive(!functionalitieSidebarActive);
  };

  const togglePersonalScenarioSidebarActive = () => {
    setPersonalScenarioSidebarActive(!personalScenarioSidebarActive);
  };

  // let isScenario = subID === 0 || subID === 4;
  let sub = section.menu.find((c) => c.id === subID);
  let state =
    props.history.location.pathname === '/office/scenarios/personal-scenarios'
      ? props.personalscenariosstate
      : props.functionalitiesstate;
  let dispatch =
    props.history.location.pathname === '/office/scenarios/personal-scenarios'
      ? props.personalscenariosdispatch
      : props.functionalitiesdispatch;

  let c = { section, subID, fct: { changeSub: changeSub } };
  return (
    <Foundations
      aside={
        <Sidebar
          menu={section.menu}
          c={c}
          setHeight={setHeight}
          addons={[
            <FunctionalitiesSidebar
              key={0}
              {...props}
              // {...state}
              // dispatch={dispatch}
              state={props.functionalitiesstate}
              dispatch={props.functionalitiesdispatch}
              sidebarheight={
                personalScenarioSidebarActive
                  ? sidebarheight
                  : sidebarheight * 2 - 120
              }
              update={update}
              active={functionalitieSidebarActive}
              toggleActive={toggleFunctionalitieSidebarActive}
            />,
            <PersonalScenariosSidebar
              key={1}
              {...props}
              // {...state}
              // dispatch={dispatch}
              state={props.personalscenariosstate}
              dispatch={props.personalscenariosdispatch}
              sidebarheight={
                functionalitieSidebarActive
                  ? sidebarheight
                  : sidebarheight * 2 - 200
              }
              update={update}
              active={personalScenarioSidebarActive}
              toggleActive={togglePersonalScenarioSidebarActive}
            />,
          ]}
        />
      }
      header={
        <Header
          // title={section.title}
          title={
            props.history.location.pathname ===
            '/office/scenarios/personal-scenarios'
              ? props.personalscenariosstate.focus &&
                props.personalscenariosstate.lang !== 'default' &&
                props.personalscenariosstate.focus.name_translations &&
                props.personalscenariosstate.focus.name_translations[
                  props.personalscenariosstate.lang
                ]
                ? props.personalscenariosstate.focus.name_translations[
                    props.personalscenariosstate.lang
                  ]
                : props.personalscenariosstate.focus &&
                  props.personalscenariosstate.focus.name &&
                  props.personalscenariosstate.focus.name
              : props.functionalitiesstate.focus &&
                props.functionalitiesstate.focus.key === 'fallback'
              ? 'Autres'
              : props.functionalitiesstate.focus &&
                props.functionalitiesstate.focus.name
          }
          subtitle={sub && sub.title}
          {...state}
          {...props}
          dispatch={dispatch}
          addons={[
            <DaliLogic {...state} {...props} dispatch={dispatch} />,
            <LangSwitcher {...state} {...props} dispatch={dispatch} />,
            <SaveButton {...state} {...props} dispatch={dispatch} />,
          ]}
          titleaddon={[
            <EditPopover
              {...state}
              {...props}
              dispatch={dispatch}
              toggleUpdate={toggleUpdate}
              advanced={
                props.history.location.pathname ===
                '/office/scenarios/personal-scenarios'
                  ? true
                  : false
              }
            />,
          ]}
          // title={isScenario ? '' : section.title}
          // subtitle={sub && !isScenario ? sub.title : ''}
        />
      }
    >
      <Route
        exact
        path={`${preURL}`}
        render={() => (
          <ErrorBoundary>
            <Functionalities
              {...props}
              state={props.functionalitiesstate}
              dispatch={props.functionalitiesdispatch}
              changeSub={changeSub}
              subID={props.subID}
            />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path={`${preURL}/personal-scenarios`}
        render={() => (
          <ErrorBoundary>
            <PersonalScenarios
              {...props}
              state={props.personalscenariosstate}
              dispatch={props.personalscenariosdispatch}
              changeSub={changeSub}
              subID={props.subID}
              toggleUpdate={toggleUpdate}
            />
          </ErrorBoundary>
        )}
      />
    </Foundations>
  );
};

export default withMain(withApi(Scenarios));
