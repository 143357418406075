import React, { useEffect, useState } from 'react';
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';
import Nodata from '../../components/Nodata';
import moment from 'moment';
import {
  Switch,
  Button,
  Icon,
  Field,
  Textarea,
  Image,
  Select,
} from '@ask-mona/ui';
import { _isImg } from '../../_tools/is';
import { withMain } from '../../hoc/main';

const Main = (props) => {
  const [conv] = useState(React.createRef());

  useEffect(() => {
    let el = conv.current;
    if (el) {
      const scrollHeight = el.scrollHeight;
      const height = el.clientHeight;
      const maxScrollTop = scrollHeight - height;
      el.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  });

  const { setFocus, archived, logs, ...other } = props;
  const { w, bot } = other.m;
  const { focus } = other;
  if (!focus) return <Nodata title={w.inbox.noneSelected} />;

  let curr = logs.find((l) => l.id === focus.id);

  return (
    <div
      className="w-70p inline-block has-text-primary-ter h-100 relative overflow-scroll"
      ref={conv}
    >
      <div
        className="sticky top border-b-primary-light has-background-primary-lighter"
        style={{ zIndex: 1 }}
      >
        <div className="p-md flex row space-between items-center">
          <Button
            className="is-outlined is-borderless is-size-7"
            onClick={async () => {
              const { Log, focus } = other;
              if (focus.bot_disabled)
                await Log._toggleBot({
                  bot_id: bot.id,
                  user_id: focus.user_id,
                });
              await Log._toggleArchived(
                { bot_id: bot.id, id: focus.id },
                { refetchQueries: ['getLogs'] }
              );
              setFocus(null);
              props.m.fct.fetchMessage();
            }}
          >
            <Icon icon="fas fa-file-archive" />
            {!archived ? w.inbox.archive : w.inbox.unarchive}
          </Button>
          {focus.name}
          {!props.wc && (
            <div className="flex column center items-center">
              <Switch
                color="secondary"
                checked={focus.bot_disabled}
                onChange={async () => {
                  const { Log, focus } = other;
                  await Log._toggleBot(
                    { bot_id: bot.id, user_id: focus.user_id },
                    { refetchQueries: ['getLogs'] }
                  );
                  setFocus({ ...focus, bot_disabled: !focus.bot_disabled });
                }}
              />
              <span className="is-size-7 p-t-xs">
                {focus.bot_disabled ? (
                  <div className="flex column center content-center">
                    <div className="flex center content-center">
                      <span className="flex self-center center content-center">
                        <ErrorSharpIcon
                          fontSize="small"
                          style={{ color: 'orangered' }}
                        />
                      </span>
                      <span className="flex self-center center content-center">
                        <strong>&nbsp;{w.inbox.reactivate}</strong>
                      </span>
                    </div>
                    <span className="flex center content-center">
                      {w.inbox.activationWarning}
                    </span>
                  </div>
                ) : (
                  <span>{w.inbox.desactivate}</span>
                )}
              </span>
            </div>
          )}
        </div>
      </div>
      <Content {...props} curr={curr || focus} />
      {!archived && <Board {...props} curr={curr || focus} />}
    </div>
  );
};

const Board = ({ m: { w, bot, user }, quickAnswers, ...other }) => {
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [quicky, setQuick] = useState(false);
  const [select, setSelect] = useState(null);

  useEffect(() => {
    setInput('');
  }, [other.curr.id]);

  return (
    <div className="p-md sticky bottom">
      <div className="p-md has-background-primary-lighter border-primary-light rad-10px shadow">
        <div className="flex row space-between is-size-7 m-b-xs">
          <Button className="is-outlined is-borderless" disabled>
            <div className="flex column items-center">
              <Icon icon="fas fa-comment-alt" />
              {w.inbox.directAnswer}
            </div>
          </Button>
          {quickAnswers.length > 0 && (
            <Button
              className="is-outlined is-borderless"
              onClick={() => setQuick(!quicky)}
            >
              <div className="flex column items-center">
                <Icon icon="fas fa-list-ul" />
                {w.inbox.selectAnswer}
              </div>
            </Button>
          )}
        </div>
        {quicky && (
          <div className="animation-fadeInFast m-b-xs">
            <Select
              items={quickAnswers.map((q, i) => ({
                id: i,
                value: q.name,
                ...q,
              }))}
              placeholder={w.inbox.selectQAnswer}
              onSelect={(item) => {
                if (item.content.length === 1) {
                  setInput(item.content[0]);
                  setQuick(false);
                  setSelect(null);
                } else setSelect(item);
              }}
            />
            {select && (
              <Select
                items={select.content.map((c, i) => ({ id: i, value: c }))}
                onSelect={(item) => {
                  setInput(item.value);
                  setQuick(false);
                  setSelect(null);
                }}
              />
            )}
          </div>
        )}
        <div>
          <Field>
            <Textarea
              resize="none"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <Button
              className="p-b-xs"
              loading={loading}
              onClick={async () => {
                const { Log, curr, setFocus } = other;
                if (input) {
                  setLoading(true);
                  let res = await Log._answerLog({
                    bot_id: bot.id,
                    id: curr.id,
                    answer: input,
                    answered_name: `${user.user.firstname} ${user.user.lastname}`,
                    is_user: false,
                  });
                  if (res.success) {
                    setFocus(res.answerLog);
                    setInput('');
                  }
                  setLoading(false);
                }
              }}
            >
              {w.generic.send}
            </Button>
          </Field>
        </div>
      </div>
    </div>
  );
};

const Content = ({ curr, m: { w } }) => {
  const { message } = curr;
  return (
    <div>
      {message.map((m, i) => {
        let next = message[i + 1];
        return (
          <Message
            key={m.date}
            {...m}
            name={curr.name}
            w={w}
            last={next ? next.is_user !== m.is_user : true}
          />
        );
      })}
    </div>
  );
};

const Message = ({ content, is_user, answered_name, name, date, last, w }) => {
  let dat = parseInt(date, 10);
  moment.locale(localStorage.getItem("languageCode"));

  const momentDate = moment(dat);

  dat = `${momentDate.format("Do MMMM YYYY")} ${w.generic.at_time} ${momentDate.format("HH:mm")}`;

  let isImg = _isImg(content);

  return (
    <div className="m-xs">
      <div className={`flex row${!is_user ? '-reverse' : ''} items-flex-end`}>
        <Icon icon={`fas fa-${is_user ? 'user' : 'robot'}`} className="p-xs" />
        <div
          className={`rad-10px break-word mw-70p p-sm ${
            is_user
              ? 'has-background-grey-lighter'
              : 'has-background-primary-lighter'
          }`}
        >
          {isImg && (
            <div style={{ width: '250px' }}>
              <Image src={content} alt="rdImg" fixed="true" />
            </div>
          )}
          {!isImg && content}
        </div>
      </div>
      {last && (
        <div
          className={`flex m-r-lg m-l-lg row${
            !is_user ? '-reverse' : ''
          } items-flex-end is-size-7`}
        >
          {`${w.inbox.sendBy} ${
            is_user ? name || '' : answered_name || '...'
          } ${w.generic.the.toLowerCase()} ${dat}`}
        </div>
      )}
    </div>
  );
};

export default withMain(Main);
