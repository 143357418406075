export default {
  openSection: ["Horaires d'ouverture", 'Opening hours'],
  closeSection: ['Jours de fermeture (exceptions)', 'Closing days (exceptions)'],
  specialSection: ['Jours feriés', 'Holidays'],
  generalRules: ['Règles générales', 'General rules'],
  exceptions: ['Exceptions', 'Exceptions'],
  addDate: ['Ajouter une date', 'Add a date'],
  addDays: ['Ajouter des jours', 'Add days'],
  addHours: ['Ajouter des heures', 'Add hours'],

  renewableLabel: ["Reconductible tous les ans", "Renewable every year"],
  unclassifiedHolidays: ["Jours fériés non classés", "Unclassified holidays"],
  toBeFiled: ["À classer", "To be filed"],
  placeInOpen: ["Placer dans ouvert", "Place in open"],
  placeInClose: ["Placer dans fermer", "Place in close"],

  specificMessage: ["Message spécifique", "Specific message"],
  specificMessageLabel: ["Texte à afficher pour cette date", "Text to display for this date"],
  updateTheText: ["Mettre à jour le texte", "Update the text"],
};
