import React from 'react';
import {
  _getKeyValue,
  _sortTranslations,
  _splitReverseJoin,
} from '../../../_tools/other';
import { _dateToStr, _sortOph } from '../../../_tools/oph';
import { Checkbox, Switch, Icon, Button } from '@ask-mona/ui';
import FlagIcon from '../../../components/FlagIcon';
import Nodata from '../../../components/Nodata';

export default ({ data, fct, events, m: { w, lang, bot_lang } }) => {
  let items = _getKeyValue(data, 'events') || [];
  let target = lang || bot_lang[0];
  items = items.map((e) => {
    let title = '',
      date = '',
      place = '',
      placeId = e.place_id,
      categorie = '',
      language = [],
      published = e.published,
      id = e.id,
      themes = e.themes || [],
      oph = e.opening_hours || '',
      date_start = e.date_start
        ? _splitReverseJoin(e.date_start, '-', '/')
        : '',
      date_end = e.date_end ? _splitReverseJoin(e.date_end, '-', '/') : '',
      type = e.types || {},
      seen = e.seen,
      tmp = {};


    tmp = _sortTranslations(e.translations);

    const titleTranslation = tmp[target] || tmp[bot_lang[0]] || Object.values(tmp)[0];
    // Set Title
    title = titleTranslation
      ? titleTranslation.short_title || titleTranslation.long_title || w.events.noTitle
      : w.events.noTitle;

    // Set Place
    place = e.place ? e.place.name : w.events.noPlace;

    // Set Language
    for (let key in tmp) {
      if (bot_lang.some((lang) => lang === key))
        language.push(<FlagIcon key={key} code={key === 'en' ? 'gb' : key} />);
    }
    // Set themes
    themes = e.themes.map((t) => _sortTranslations(t.translations));
    themes.forEach((t) => {
      if (t[lang]) categorie = `${t[lang].name} ${categorie}`;
    });
    // Set Type
    if (!categorie) {
      type = _sortTranslations(type.translations);
      categorie = type[lang]
        ? type[lang].short_name || type[lang].name || w.events.noCategorie
        : w.events.noCategorie;
    }
    // Set Dates
    if (date_start && date_end) {
      if (date_start === date_end) date = `${w.generic.the} ${date_start}`;
      else
        date = `${w.generic.from} ${date_start}\n${w.generic.to} ${date_end}`;
    } else {
      const parts = _sortOph(oph);
      if (!parts.global.length && !parts.period.length && parts.unique.length)
        parts.unique.forEach((p, i) => {
          if (i < 2)
            date = `${date}${date ? '\n' : ''}${w.generic.the} ${_dateToStr(
              p.oph
            )}`;
        });
      date = `${date}${parts.unique.length > 2 ? '\n...' : ''}`;
      date = date || w.generic.permanent;
    }

    return {
      id,
      title,
      date,
      place,
      placeId,
      categorie,
      language,
      published,
      seen,
    };
  });

  if (!items.length) return <Nodata title={w.error.noEvent} />;

  return items.map((e) => (
    <div
      className={`flex row items-center is-size-6bis border-b-primary-lighter ${!e.seen ? 'has-background-primary-light' : ''
        }`}
      key={e.id}
    >
      <div className="m-b-md p-xs flex items-start">
        {' '}
        <Checkbox
          checked={events.find((id) => id === e.id) || false}
          onChange={() => fct.onListEvent(e, 'pp')}
          className="small"
        />{' '}
      </div>
      <div
        className="p-xs flex grow-2 basis-0 pointer"
        onClick={() => fct.onListEvent(e, 'edit')}
      >
        {e.title}
      </div>
      <div className="p-xs flex grow-2 basis-0 space-pre">{e.date}</div>
      <div className="p-xs flex grow-2 basis-0">{e.place}</div>
      <div className="p-xs flex grow-2 basis-0">{e.categorie}</div>
      <div className="p-xs flex grow-1 basis-0 center">{e.language}</div>
      <div className="p-xs flex grow-1 basis-0 center">
        {' '}
        <Switch
          color="secondary"
          checked={e.published}
          onChange={() => fct.onListEvent(e, 'toggle')}
        />{' '}
      </div>
      <div className="p-xs flex grow-1 basis-0 center">
        <Button
          className="is-borderless is-outlined shrink has-text-primary"
          onClick={() => fct.onListEvent(e, 'edit')}
        >
          {' '}
          <Icon icon="fas fa-pencil-alt has-text-primary " />{' '}
        </Button>
        <Button
          className="is-borderless is-outlined shrink"
          onClick={() => fct.onListEvent(e, 'delete')}
        >
          {' '}
          <Icon icon="fas fa-trash has-text-primary" />{' '}
        </Button>
      </div>
    </div>
  ));
};
