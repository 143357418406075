import React, { useState, useEffect } from 'react';
import { withMain } from '../../../hoc/main';
import Loader from '../../../components/Loader';
import { Checkbox } from '@ask-mona/ui';
import { GET_BOT } from '../../../_api/bot';
import { UPDATE_TRACKER } from '../../../_api/checklist';
import Protocol from './tracker/Protocol';

function Tracker(props) {
  const [tracker, setTracker] = useState(null);
  const [init, setInit] = useState(true);

  useEffect(() => {
    const {
      m: { client, bot },
    } = props;
    if (init) {
      client
        .query({
          query: GET_BOT,
          variables: { id: Number(bot.id) },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          if (res.data.getBot.tracker) {
            setTracker(res.data.getBot.config.tracker.embeded);
          } else {
            changeTracker(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setInit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTracker = async (bool) => {
    console.log(bool);
    const {
      m: { client, bot },
    } = props;
    client
      .mutate({
        mutation: UPDATE_TRACKER,
        variables: {
          bot_id: bot.id,
          embeded: bool,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.updateTracker) {
          const result = bool ? false : true;
          setTracker(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (tracker === null) return <Loader className="has-text-primary" />;
  return (
    <React.Fragment>
      <div className="m-md">
        {!tracker && (
          <Checkbox
            onClick={() => changeTracker(true)}
            label="Depuis Ask Mona"
            defaultChecked
          />
        )}
        {tracker && (
          <Checkbox
            onClick={() => changeTracker(false)}
            label="Depuis Ask Mona"
          />
        )}
      </div>
      <div className="m-md">
        {!tracker && (
          <Checkbox
            onClick={() => changeTracker(true)}
            label="Depuis le site du client"
          />
        )}
        {tracker && (
          <Checkbox
            onClick={() => changeTracker(false)}
            label="Depuis le site du client"
            defaultChecked
          />
        )}
        {tracker && <Protocol />}
      </div>
    </React.Fragment>
  );
}

export default withMain(Tracker);
