import React, { Component } from 'react';
import { withMain } from '../hoc/main';
import PushMain from './pushs/PushMain';
import Foundations from '../components/base/Foundations';
import Sidebar from '../components/base/Sidebar';
import Header from '../components/base/Header';
import ErrorBoundary from '../components/ErrorBoundary';
import moment from 'moment';
import { timestampToDate, statut } from '../_tools/other';
import { State } from './pushs/Sidebar';
import Modal from '../components/Modal';
import Tutorial from '../components/Tutorial';

//API
import { _showAllPushs, _deletePush } from '../_api/push';

class Push extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getInfo: false,
      allPush: null,
      filterPush: [],
      checkFilter: null,
      statusFilter: false,
      check: {
        checkListID: null,
        action: null,
      },
      data: {
        lang: this.props.m.lang,
        selected: [],
        deleted: [],
        actID: null,
        preSort: '',
      },
      variables: {
        bot_id: this.props.m.bot.id,
        title: '',
        date_interval: [],
        types: [],
        themes: [],
        offset: 0,
        limit: 30,
        published: 3,
        lat: null,
        long: null,
        sortBy: 'date_start',
        language: 0,
        finished: false,
      },
      fct: {
        ppAllPush: this.ppAllPush.bind(this),
        changeName: this.changeName.bind(this),
        setStatut: this.setStatut.bind(this),
        sortByDate: this.sortByDate.bind(this),
        onAddPush: this.onAddPush.bind(this),

        onGetInfo: this.onGetInfo,
        showAllPush: this.showAllPush.bind(this),
        reloadPush: this.reloadPush.bind(this),
        deleteContentPush: this.deleteContentPush.bind(this),
        viewPush: this.viewPush.bind(this),
        editPush: this.editPush.bind(this),
        onSelectPush: this.onSelectPush.bind(this),
        actionSelectPush: this.actionSelectPush.bind(this),
        checkAllPush: this.checkAllPush.bind(this),
        checkOnePush: this.checkOnePush.bind(this),
      },
    };
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.showAllPush();
      }.bind(this),
      2000
    );
  }

  onGetInfo = () => this.setState((state) => ({ getInfo: !state.getInfo }));

  async showAllPush() {
    const {
      m: { bot },
    } = this.props;
    const { checkFilter } = this.state;
    const d = await _showAllPushs(bot.id);
    let arrFinal = [];
    await fetch(d.url, d.req)
      .then((res) => res.json())
      .then((data) => {
        arrFinal = (data.data || []).filter((d) => d.reminder === false);
        let res = [];
        if (checkFilter) {
          if (checkFilter === 1) {
            res = filter(arrFinal, false);
          } else if (checkFilter === 2) {
            res = filter(arrFinal, true);
          } else if (checkFilter === 3) {
            res = filter(arrFinal, null);
          }
        }
        this.setState({
          ...this.state,
          filterPush: res,
          allPush: arrFinal,
          check: { ...this.state.check, checkListID: [] },
        });
      });
  }

  reloadPush() {
    this.showAllPush();
  }

  async deleteContentPush(idPush) {
    const {
      m: { bot },
    } = this.props;
    const d = await _deletePush(bot.id, idPush);
    const res = await fetch(d.url, d.req);
    if (res) {
      this.showAllPush();
    }
  }

  onSelectPush(value) {
    this.setState({
      ...this.state,
      check: { ...this.state.check, action: value },
    });
  }

  async actionSelectPush() {
    const {
      check: { checkListID, action },
    } = this.state;

    if (action === 'Supprimer' || action === 'delete') {
      for (var k = 0; checkListID.length > k; k++) {
        this.deleteContentPush(checkListID[k]);
      }
    }
  }

  checkAllPush() {
    const {
      allPush,
      check: { checkListID },
    } = this.state;
    let pushID = [];
    if (checkListID.length !== allPush.length) {
      allPush.forEach((item) => {
        pushID.push(item.id);
      });
      this.setState({
        ...this.state,
        check: { ...this.state.check, checkListID: pushID },
      });
    } else {
      this.setState({
        ...this.state,
        check: { ...this.state.check, checkListID: [] },
      });
    }
  }

  checkOnePush(pushID) {
    const {
      check: { checkListID },
    } = this.state;
    if (checkListID.includes(pushID)) {
      for (var k = 0; checkListID.length > k; k++) {
        if (checkListID[k] === pushID) {
          checkListID.splice(k, 1);
        }
      }
      this.setState({
        ...this.state,
        check: {
          ...this.state.check,
          allPush: false,
          checkListID: checkListID,
        },
      });
    } else {
      checkListID.push(pushID);
      this.setState({
        ...this.state,
        check: {
          ...this.state.check,
          allPush: false,
          checkListID: checkListID,
        },
      });
    }
  }

  setDates = ({ startDate, endDate }) => {
    let date_interval = [];
    if (startDate && endDate) {
      date_interval.push(startDate.format('YYYY-MM-DD'));
      date_interval.push(endDate.format('YYYY-MM-DD'));
    }
    this.setState({ date_interval, offset: 0 });
  };

  setOptions = () =>
    this.setState((state) => ({ finished: !state.finished, offset: 0 }));

  ppAllPush = (array) => {
    const newData = Object.assign({}, this.state.data);
    if (newData.selected.length) newData.selected = [];
    else {
      newData.selected = array;
    }
    this.setState({ data: newData });
  };

  changeName(value) {
    this.setState({ variables: { title: value } });
  }

  setStatut = (value) => {
    const { allPush, checkFilter } = this.state;
    let check = null;
    if (checkFilter === value) {
      check = null;
    } else {
      check = value;
    }
    if (value === 1) {
      const res = filter(allPush, false);
      this.setState({ filterPush: res, checkFilter: check });
    } else if (value === 2) {
      const res = filter(allPush, true);
      this.setState({ filterPush: res, checkFilter: check });
    } else if (value === 3) {
      const res = filter(allPush, null);
      this.setState({ filterPush: res, checkFilter: check });
    }
  };

  sortByDate = () => {
    const { allPush } = this.state;
    const sortArray = allPush.sort(function (a, b) {
      const c1 = b.scheduled;
      const c2 = a.scheduled;
      return new Date(c1) - new Date(c2);
    });
    this.setState({ allPush: sortArray });
  };

  onAddPush = () => {
    this.editPush(-42);
  };

  viewPush = (id) => {
    this.editPush(id);
  };

  editPush = (id) => {
    if (id === -42) this.props.history.push('/office/push/new');
    else this.props.history.push(`/office/push/${id}`);
  };

  render() {
    const {
      m: { w },
    } = this.props;
    const { allPush, filterPush, checkFilter } = this.state;
    const all = checkFilter !== null ? filterPush : allPush;
    return (
      <Foundations
        aside={
          <Sidebar
            c={this.state}
            addons={[
              <State
                key={2}
                onChange={this.setStatut}
                published={this.state.checkFilter}
              />,
            ]}
            fixed
          />
        }
        header={<Header title={`${w.generic.pushs}`} />}
      >
        <ErrorBoundary>
          <PushMain
            context={this.state}
            editPush={this.editPush}
            allPush={all}
          />
        </ErrorBoundary>
        <Modal
          isOpen={this.state.getInfo}
          close={() => this.onGetInfo()}
          selector="#root"
        >
          <Tutorial tuto={w.tutorial.push} close={() => this.onGetInfo()} />
        </Modal>
      </Foundations>
    );
  }
}

const filter = (allPush, bool) => {
  const filterPush = [];
  allPush &&
    allPush.forEach((p) => {
      let finalStatut = null;
      if (p.scheduled) {
        const date = timestampToDate(p.scheduled);
        moment.locale('fr');
        const d = moment(date).format('L');
        const h = moment(date).format('LT');
        finalStatut = statut(d, h);
        if (finalStatut === bool) filterPush.push(p);
      } else if (p.scheduled === undefined && bool === null) {
        filterPush.push(p);
      }
    });
  return filterPush;
};

export default withMain(Push);
