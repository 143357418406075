import React from 'react';
import styled from 'styled-components';

export default ({ aside, header, children }) => (
  <GridFoundations className="flex row has-text-white">
    <GridSidebar id="grid-sidebar" className=" has-background-white">
      {aside}
    </GridSidebar>
    <GridHeader id="grid-header" className="has-background-white">
      {header}
    </GridHeader>
    <GridMain id="grid-main" className="has-background-white flex grow-1">
      {children}
    </GridMain>
  </GridFoundations>
);

const GridFoundations = styled.div`
  display: -ms-grid;
  position: relative;
  display: grid;
  grid-template-areas:
    '. header'
    'sidebar main'
    '. main';
  width: 100%;
  height: 95%;
  overflow: hidden;
  -ms-grid-columns: fit-content(100%) 1fr;
  grid-template-columns: fit-content(100%) 1fr;
  -ms-grid-rows: fit-content(100%) 1fr;
  grid-template-rows: fit-content(100%) 1fr;
`;

const GridHeader = styled.div`
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: header;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 2px solid #ececec;
  -webkit-box-shadow: 2px 2px 1px #ececec, -2px 0px 1px #ececec;
  box-shadow: 2px 2px 1px #ececec, -2px 0px 1px #ececec;
`;

const GridSidebar = styled.div`
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: sidebar;
  height: 95%;
  border-radius: 5px 0px 0px 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const GridMain = styled.main`
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  -ms-grid-column: 2;
  grid-area: main;
  border-radius: 0px 0px 5px 5px;
  -webkit-box-shadow: 2px 0px 1px #ececec, -2px 0px 1px #ececec;
  box-shadow: 2px 0px 1px #ececec, -2px 0px 1px #ececec;
  max-height: -webkit-fill-available;
  max-height: -moz-available;
  max-height: stretch;
  overflow: auto;
`;
