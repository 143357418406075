import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ArrowBottom } from '@styled-icons/open-iconic/ArrowBottom';
import { ArrowTop } from '@styled-icons/open-iconic/ArrowTop';
import { withMain } from '../hoc/main';
import AddBot from './homepageAdmin/AddBot';
import Bot from './homepageAdmin/Bot';
import Header from './homepageAdmin/Header';

const HomepageAdmin = (props) => {
  const [bots, setBots] = useState(props.m.bots);
  const [addBot, setAddBot] = useState(false);
  const [filterBy, setFilterBy] = useState('');
  const [type, setType] = useState('');
  const [orderAscending, setOrderAscending] = useState(false);
  const [switchOnline, setSwitchOnline] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchBy, setSearchBy] = useState('organization');

  const admin = props.m.user.user.permissions === 1;
  const w = props.m.w.homepage;

  useEffect(() => {
    if (filterBy !== '') {
      if (type === 'number') {
        const sorted = [...bots].sort((a, b) =>
          orderAscending ? a[filterBy] - b[filterBy] : b[filterBy] - a[filterBy]
        );
        setBots(sorted);
      } else if (type === 'string') {
        if (filterBy === 'organization') {
          const sorted = [...bots].sort((a, b) =>
            orderAscending
              ? a['organization']['name'].localeCompare(
                  b['organization']['name']
                )
              : b['organization']['name'].localeCompare(
                  a['organization']['name']
                )
          );
          setBots(sorted);
        } else {
          const sorted = [...bots].sort((a, b) =>
            orderAscending
              ? a[filterBy].localeCompare(b[filterBy])
              : b[filterBy].localeCompare(a[filterBy])
          );
          setBots(sorted);
        }
      } else if (type === 'status') {
        const newArr = [...bots];
        const arrOnline = newArr.filter((el) => el.status === 'ok');
        const arrDown = newArr.filter((el) => el.status === 'down');
        const arrPending = newArr.filter((el) => el.status === 'pending');
        if (orderAscending) {
          setBots([...arrOnline, ...arrPending, ...arrDown]);
        } else {
          setBots([...arrDown, ...arrPending, ...arrOnline]);
        }
      }
    }
  }, [filterBy, orderAscending]);

  useEffect(() => {
    if (switchOnline) {
      setBots(bots.filter((el) => el.status === 'ok'));
    } else {
      setBots(props.m.bots);
    }
  }, [switchOnline]);

  useEffect(() => {
    if (searchValue !== '') {
      if (searchBy === 'bot') {
        setBots(
          props.m.bots.filter((el) =>
            el.name.toLowerCase().includes(searchValue.toLocaleLowerCase())
          )
        );
      } else if (searchBy === 'organization') {
        setBots(
          props.m.bots.filter((el) =>
            el.organization.name
              .toLowerCase()
              .includes(searchValue.toLocaleLowerCase())
          )
        );
      }
    } else {
      setBots(props.m.bots);
    }
  }, [searchValue]);

  const findBot = (id) => {
    props.m.fct.switchBot(bots.find((el) => el.id === id));
    props.history.push(`/office/bot/${id}`);
  };

  return (
    <>
      <Wrapper addBot={addBot}>
        <Header
          setAddBot={setAddBot}
          setSwitchOnline={setSwitchOnline}
          switchOnline={switchOnline}
          valueInput={searchValue}
          handleValue={setSearchValue}
          user={props.m.user.user}
          w={w}
          searchBy={searchBy}
          setSearchBy={setSearchBy}
        />
        <WrapperGrid admin={admin}>
          <StyledItem />
          <StyledItem>
            {w.name}
            <span
              onClick={() => {
                setFilterBy('name');
                setType('string');
                setOrderAscending(!orderAscending);
              }}
            >
              {orderAscending && filterBy === 'name' ? (
                <StyledArrowTop size={14} />
              ) : (
                <StyledArrow size={14} />
              )}
            </span>
          </StyledItem>
          <StyledItem>
            {w.nameCustomer}
            <span
              onClick={() => {
                setFilterBy('organization');
                setType('string');
                setOrderAscending(!orderAscending);
              }}
            >
              {orderAscending && filterBy === 'organization' ? (
                <StyledArrowTop size={14} />
              ) : (
                <StyledArrow size={14} />
              )}
            </span>
          </StyledItem>
          <StyledItem>
            {w.createdAt}
            <span
              onClick={() => {
                setFilterBy('created_at');
                setType('number');
                setOrderAscending(!orderAscending);
              }}
            >
              {orderAscending && filterBy === 'created_at' ? (
                <StyledArrowTop size={14} />
              ) : (
                <StyledArrow size={14} />
              )}
            </span>
          </StyledItem>
          <StyledItem>
            {w.users}
            <span
              onClick={() => {
                setFilterBy('total_users');
                setType('number');
                setOrderAscending(!orderAscending);
              }}
            >
              {orderAscending && filterBy === 'total_users' ? (
                <StyledArrowTop size={14} />
              ) : (
                <StyledArrow size={14} />
              )}
            </span>
          </StyledItem>
          <StyledItem>{w.platform} </StyledItem>
          {admin && (
            <StyledItem>
              {w.number}
              <span
                onClick={() => {
                  setFilterBy('id');
                  setType('number');
                  setOrderAscending(!orderAscending);
                }}
              >
                {orderAscending && filterBy === 'id' ? (
                  <StyledArrowTop size={14} />
                ) : (
                  <StyledArrow size={14} />
                )}
              </span>
            </StyledItem>
          )}
          <StyledItem>{props.m.w.generic.language}</StyledItem>
          <StyledItem>
            {w.status}
            <span
              onClick={() => {
                setFilterBy('status');
                setType('status');
                setOrderAscending(!orderAscending);
              }}
            >
              {orderAscending && filterBy === 'status' ? (
                <StyledArrowTop size={14} />
              ) : (
                <StyledArrow size={14} />
              )}
            </span>
          </StyledItem>
        </WrapperGrid>
        {bots.map((bot) => (
          <Bot key={bot.id} {...bot} findBot={findBot} admin={admin} w={w} />
        ))}
      </Wrapper>
      {addBot && (
        <AddBot
          setAddBot={setAddBot}
          m={props.m}
          w={w}
          updateList={(b, orga) => {
            const newBot = {
              ...b,
              panel_logo: {},
              organization: {
                name: orga,
              },
              features: [
                {
                  id: 12,
                  slug: 'fr',
                  name: 'Français',
                  is_bot_active: true,
                },
              ],
            };
            setBots([...bots, newBot]);
            setAddBot(false);
          }}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  opacity: ${({ addBot }) => addBot && '.5'};
  width: 95%;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 auto;
`;

const WrapperGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ admin }) =>
    admin
      ? '1fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr'
      : '1fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr'};
  justify-content: center;
  border-bottom: 1px solid #2d2f7b;
  text-align: center;
  align-items: center;
  padding: 0 0.8rem;
  background: transparent;
  color: #fff;
`;

const StyledItem = styled.div`
  place-self: center;
  > span {
    margin-left: 0.4rem;
  }
`;

const StyledArrow = styled(ArrowBottom)`
  cursor: pointer;
`;

const StyledArrowTop = styled(ArrowTop)`
  cursor: pointer;
`;

export default withMain(HomepageAdmin);
