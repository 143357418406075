import React, { Component } from 'react';
import { withMain } from '../hoc/main';
import Foundations from '../components/base/Foundations';
import Sidebar from '../components/base/Sidebar';
import Header from '../components/base/Header';
import ErrorBoundary from '../components/ErrorBoundary';
import {
  Dates,
  Place,
  State,
  Types,
  Themes,
  Languages,
} from './events/Sidebar';
import List from './events/List'
import eventContext from './events/list/context/EventContext';

class Events extends Component {
  // the context allows to recover the offset variable and the setOffset function that are used to set the current pagination number in the list of events
  static contextType = eventContext;

  constructor(props) {
    super(props);
    this.state = {
      title: '',
      types: [],
      themes: [],
      date_interval: [],
      selectedPlaceId: null,
      sortBy: '',
      language: 3,
      published: 3,
      finished: false,
      limit: 30,
    };
  }

  setTitle = (e) => {
    this.setState({ title: e.target.value });
    this.context.setOffset(0);
  };

  setOffset = (value) => this.context.setOffset(value);

  setDates = ({ startDate, endDate }) => {
    let date_interval = [];
    if (startDate && endDate) {
      date_interval.push(startDate.format('YYYY-MM-DD'));
      date_interval.push(endDate.format('YYYY-MM-DD'));
    }
    this.setState({ date_interval });
    this.context.setOffset(0);
  };

  setPublished = (value) => {
    this.setState({ published: value });
    this.context.setOffset(0);
  };

  ppArray = (id, name) => {
    if (this.state[name].find((m) => m === id)) {
      this.setState((state) => ({
        [name]: state[name].filter((n) => n !== id),
      }));
      this.context.setOffset(0);
    } else {
      this.setState((state) => ({ [name]: state[name].concat(id), offset: 0 }));
      this.context.setOffset(0);
    }
  };

  setLanguage = (value) => {
    this.setState({ language: value });
    this.context.setOffset(0);
  };

  setOptions = () => {
    this.setState((state) => ({ finished: !state.finished }));
    this.context.setOffset(0);
  };

  editEvent = (id) => {
    if (id === -42) this.props.history.push('/office/event/new');
    else this.props.history.push(`/office/event/${id}`);
  };

  setSelectedPlaceId = (selectedPlace) => {
    this.setState({ selectedPlaceId: selectedPlace.id })
  }

  render() {
    const {
      m: { w, bot },
    } = this.props;

    return (
      <Foundations
        aside={
          <Sidebar
            c={this.state}
            addons={[
              <Dates key={0} onChange={this.setDates} />,
              <Place
                {...this.props}
                key={1}
                onChange={this.setSelectedPlaceId}
              />,
              <State
                key={2}
                onChange={this.setPublished}
                published={this.state.published}
                onFinished={this.setOptions}
                finished={this.state.finished}
              />,
              !bot.types.length ? (
                <Types
                  key={3}
                  onChange={(id) => this.ppArray(id, 'types')}
                  types={this.state.types}
                />
              ) : (
                  <Themes
                    key={4}
                    onChange={(id) => this.ppArray(id, 'themes')}
                    themes={this.state.themes}
                  />
                ),
              <Languages
                key={5}
                onChange={this.setLanguage}
                language={this.state.language}
              />,
            ]}
            fixed
          />
        }
        header={<Header title={`${w.generic.events}`} />}
      >
        <ErrorBoundary>
          <List
            {...this.state}
            offset={this.context.offset}
            bot_id={bot.id}
            setTitle={this.setTitle}
            setOffset={this.setOffset}
            editEvent={this.editEvent}
          />
        </ErrorBoundary>
      </Foundations>
    );
  }
}

export default withMain(Events);
