export default {
  defaultTitle: ['Œuvre', 'Artwork'],
  image: ["Photo de l'œuvre", 'Photo of the artwork'],
  title: ["Nom de l'oeuvre", 'Name of the artwork'],
  tag: [
    'Ajouter des tags pour mieux retrouver cette oeuvre',
    'Add tags to better find this artwork',
  ],
  author: ["Nom de l'auteur", "Author's name"],
  visual: ['Reconnaissance visuelle', 'Visual recognition'],
  textual: ['Reconnaissance Textuelle', 'Text Recognition'],
  gallery: ['Ma gallerie', 'My gallery'],
  gallerySub: [
    "Ici sont rassemblées toutes les photographies que vous avez téléchargées de l'oeuvre. Elle nous permettent d'entraîner sa reconnaissance visuelle.\nVeuillez suivre notre guide sur la prise de vue avant de télécharger ici ces images.",
    'Here are collected all the photographs that you have downloaded of the artwork. They allow us to train its visual recognition.\nPlease follow our guide on how to correctly shoot pictures before uploading images here.',
  ],
  text: ['Mots clefs', 'Key words'],
  textInfo: [
    'Vous pouvez définir les mots clefs ici.',
    'You can define the keywords here.',
  ],
  textTitle: ['Mots clefs', 'Key words'],
};
