import React, { Component } from 'react';
import { withMain } from '../../hoc/main';
import Loader from '../../components/Loader';
import { RenderRange, RenderSort, RenderEvents } from './rank/Render';

class Rank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      time: false,
      input: 'Depuis le début',
      interval: false,
      loader: false,
      fctInterval: this.fctInterval.bind(this),
      fctInput: this.fctInput.bind(this),
      fctLoader: this.fctLoader.bind(this),
      fctTimer: this.fctTimer.bind(this),
    };
  }

  fctInterval = (interval) => {
    this.setState({ interval, input: 'Depuis le début' });
  };

  fctInput = (input) => {
    this.setState({ input });
  };

  fctLoader = (loader) => {
    this.setState({ time: loader });
  };

  fctTimer = (time) => {
    setTimeout(() => this.setState({ time: time }), 3000);
  };

  render() {
    const {
      input,
      fctInterval,
      interval,
      fctInput,
      fctLoader,
      loader,
      fctTimer,
      time,
    } = this.state;
    const {
      m: { w },
      analytics,
      action,
      wording,
      fct,
    } = this.props;

    if (!analytics) {
      return (
        <React.Fragment>
          <h3 className="p-md is-size-7 text-align-center is-uppercase">
            {wording}
          </h3>
          <div className="text-align-center">
            <Loader />
          </div>
        </React.Fragment>
      );
    }
    if (!analytics.ga_analytics) {
      return (
        <React.Fragment>
          <h3 className="p-md is-size-7 text-align-center is-uppercase">
            {wording}
          </h3>
          <div className="text-align-center is-size-7">
            {w.homepage.unavailable}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h3 className="p-md is-size-7 text-align-center is-uppercase">
            {wording}
          </h3>
          <RenderRange
            action={action}
            w={w}
            fct={fct}
            fctInterval={fctInterval}
            interval={interval}
            fctLoader={fctLoader}
            fctTimer={fctTimer}
          />
          <div className="m-t-lg">
            <RenderSort
              action={action}
              input={input}
              w={w}
              fct={fct}
              fctInterval={fctInterval}
              interval={interval}
              fctInput={fctInput}
              fctLoader={fctLoader}
              fctTimer={fctTimer}
            />
          </div>
          <div className="m-t-xs">
            <RenderEvents
              analytics={analytics}
              action={action}
              w={w}
              loader={loader}
              time={time}
            />
          </div>
        </React.Fragment>
      );
    }
  }
}

export default withMain(Rank);
