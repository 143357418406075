import React from 'react';
import { Subsbar } from '../../components/scenario/Ui';
import Content from '../../components/scenario/Content';

export default (props) => {
  const { state, dispatch } = props;

  return (
    <div
      className="has-text-primary-ter w-100p relative space-nowrap overflow-hidden"
      style={{ display: 'flex' }}
    >
      {/* {!props.force && (
        <Sidebar {...props} {...state} dispatch={dispatch} advanced="true" />
      )} */}
      {state.focus && (
        <Subsbar {...props} {...state} dispatch={dispatch} advanced="true" />
      )}
      <Content {...props} {...state} dispatch={dispatch} advanced="true" />
    </div>
  );
};
