export default {
  email: ['Adresse email', 'Email address'],
  emailSub: ['Veuillez entrer votre adresse email', 'Please enter your email address'],
  firstname: ['Votre prénom', 'Your first name'],
  firstnameSub: ['Renseignez votre prénom', 'Enter your first name'],
  lastname: ['Votre nom', 'Your name'],
  lastnameSub: ['Renseignez votre nom', 'Fill in your last name'],
  password: ['Votre mot de passe', 'Your password'],
  passwordCheck: ['Réécrivez votre mot de passe.', 'Rewrite your password.'],
  passwordCheckSub: ['Veuillez resaisir votre mot de passe', 'Please re-enter your password'],
  passwordSub: ['Veuillez entrer votre mot de passe', 'Please enter your password'],
  passwordSuccess: [
    'Votre mot de passe est mis à jour. Vous pouvez désormais vous connecter avec votre nouveau mot de passe.',
    'Your password has been updated. You can now login with your new password.',
  ],
  registerSuccess: [
    "Un email de vérification vous a été envoyé à l'adresse indiquée.",
    "A verification email has been sent to the address indicated.",
  ],

  checkYourSpamsTitle: ["Vérifiez vos spams ou l’adresse indiquée", "Please check your spam messages"],
  checkYourSpamsContentFirstLine: ["Vous n’avez pas reçu de mail ?", "Didn't receive an email?"],
  checkYourSpamsContentSecondLine: ["Cliquez ci-dessous pour en recevoir un nouveau.", "Please click below to receive a new one."],
};
