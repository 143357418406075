import React, { useState, useEffect } from 'react';

function Round(props) {
  const { item, select } = props;

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (select !== null && select.length > 0) {
      for (var i = 0; select.length > i; i++) {
        if (item.opening_hours === select[i].opening_hours) {
          setValid(true);
          break;
        } else if (item.opening_hours !== select[i].opening_hours) {
          setValid(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (valid) {
    return (
      <div>
        <i className="fas fa-circle" style={{ color: 'green' }}></i>
      </div>
    );
  } else {
    return (
      <div>
        <i className="far fa-circle" style={{ color: 'green' }}></i>
      </div>
    );
  }
}

export default Round;
