export default {
  title: ['Titre', 'Title'],
  scheduleDate: ["Programmer la date d'envoi", 'Schedule the date of sending'],
  noContent: ['Prévisualiser votre contenu ici', 'Preview your content here'],
  contentNew: ['Rédiger le contenu du push', 'Write the content of the push'],
  subtitleNew: [
    'Vous pouvez rédiger ici le contenu de vos messages push qui seront envoyés à tous vous utilisateurs et le prévisualiser à droite',
    'Here you can write the content of your push messages that will be sent to all your users and preview it on the right',
  ],
  placeholderNew: ['Seulement visible par vous', 'Only visible to you'],
  contentTime: [
    "Choisir le moment d'envoi du push",
    'Choose the time to send the push message',
  ],
  subtitleTime: [
    "Vous pouvez définir la date et l'heure à laquelle le push sera envoyé à vos utilisateurs",
    'You can set the date and time when the push will be sent to your users',
  ],
  dateTime: ["Définir une date d'envoi", 'Set a sending date'],
  hourTime: ["Définir une heure d'envoi", 'Set a sending time'],
  Hold: ["Recherche d'un push", 'Search for a push'],

  //TARGET
  available: ['Indisponible', 'Unavailable'],
  content: ['Définir les cibles du push', 'Define the push targets'],
  subtitle: [
    "Vous pouvez décider de cibler certains groupes d'utilisateurs. A cette fin il est possible de retenir différent critères pour vos messages push",
    'You can decide to target certain user groups. For this purpose it is possible to use different criteria for your push messages',
  ],
  criteria: ['Critères de ciblage personnalisés', 'Customized targeting criteria'],
  target: [
    'Cibler les utilisateurs qui ont les caractéristiques suivantes',
    'Target users who have the following characteristics',
  ],
  progDate: [
    'Si vous confirmez votre push sera envoyé le : ',
    'If you confirm your push will be sent on :',
  ],
  progNoDate: [
    "Aucune date n'a été programmé, si vous cliquez sur confirmer votre push sera envoyé automatiquement. Si vous souhaitez programmer une date d'envoi, annuler et cliquez sur 'Programmer une date.'",
    'No date has been scheduled, if you click confirm your push will be sent automatically. If you want to schedule a date to send, cancel and click on "Schedule a date".',
  ],

  //MESSAGES
  save: ['Enregistré !', 'Saved !'],
  addTitle: ['Ajouter un titre à votre push', 'Add a title to your push'],
  write: ['Rédiger le contenu de votre push', 'Write the content of your push'],
  carouselTitle: [
    'Ajouter un titre à votre carousel',
    'Add a title to your carousel',
  ],
  carouselSubtitle: [
    'Ajouter un sous-titre à votre carousel',
    'Add a subtitle to your carousel',
  ],
  btnTitle: ['Ajouter un titre à vos boutons', 'Add a title to your buttons'],
  btnUrl: ['Ajouter une url à vos boutons', 'Add an url to your buttons'],
};
