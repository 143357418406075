import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import StyledContainer from '../../../components/parameters/StyledContainers';
import WrapperGrid from '../../../components/parameters/WrapperGrid';
import StyledTitle from '../../../components/parameters/StyledTitle';
import Feature from './Feature';
import Bot from '../../../_api/bot';
import Loader from '../../../components/Loader';
import { _sendFeedback } from '../../../_tools/ui';

const StyledItem = styled.div`
  place-self: center;
`;

const compare = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const Administration = ({ m, setSaveFunction }) => {
  const [featuresList, setFeaturesList] = useState([]);
  const [valuePermission, setValuePermission] = useState('');
  const [idFeature, setIdFeature] = useState('');
  const [load, setLoad] = useState(false);
  const [updatePermission, setUpdatePersmission] = useState([]);
  const [activeBackoffice, setActiveBackoffice] = useState('');
  const [idFeatureBackoffice, setIdFeatureBackoffice] = useState('');
  const [updateBackoffice, setUpdateBackoffice] = useState([]);
  const [botActive, setBotActive] = useState('');
  const [idFeatureBotActive, setIdFeatureBotActive] = useState('');
  const [updateBotActive, setUpdateBotActive] = useState([]);
  const [counterPermission, setCounterPermission] = useState(0);
  const [counterPermissionError, setCounterPermissionError] = useState(0);
  const [fetchFinished, setFetchFinished] = useState(false);
  const [counterBackoffice, setCounterBackoffice] = useState(0);
  const [counterBackofficeError, setCounterBackofficeError] = useState(0);
  const [fetchBackofficeFinished, setFetchBackofficeFinished] = useState(false);
  const [counterChatbot, setCounterChatbot] = useState(0);
  const [counterChatbotError, setCounterChatbotError] = useState(0);
  const [fetchedChatbot, setFetchedChatbot] = useState(false);
  const [featuresSorted, setFeaturesSorted] = useState([]);
  const [features, setFeaturesBots] = useState([]);
  const Api = new Bot(m.client, m.bot.id);
  const w = m.w.parameters;

  useEffect(() => {
    getBotFeatures();
    getFeatures();
  }, []);

  const getBotFeatures = async () => {
    const res = await Api._getBotFeatures();
    if (res.success) {
      setFeaturesBots(res.getBot.features);
    }
  };

  useEffect(() => {
    if (features) {
      const filterIsActive = featuresList.map((el) => {
        if (features.some((i) => i.slug === el.slug)) {
          return {
            ...el,
            activeBackoffice: true,
          };
        } else {
          return {
            ...el,
            activeBackoffice: false,
          };
        }
      });

      const filterPermission = filterIsActive.map((el) => {
        return {
          ...el,
          required_permission:
            features.filter((i) => i.slug === el.slug).length > 0
              ? features.filter((i) => i.slug === el.slug)[0]
                  .required_permission
              : null,
          is_bot_active:
            features.filter((i) => i.slug === el.slug).length > 0
              ? features.filter((i) => i.slug === el.slug)[0].is_bot_active
              : null,
        };
      });
      setFeaturesSorted(filterPermission.sort(compare));
    }
  }, [features]);

  useEffect(() => {
    if (idFeature !== '' && valuePermission !== '') {
      setUpdatePersmission([
        ...updatePermission.filter(({id})=> id != idFeature ),
        {
          id: idFeature,
          value: valuePermission,
        },
      ]);
    }
  }, [idFeature, valuePermission]);

  useEffect(() => {
    if (activeBackoffice !== '' && idFeatureBackoffice !== '') {
      setUpdateBackoffice([
        ...updateBackoffice.filter(({id})=> id != idFeatureBackoffice ),
        {
          id: idFeatureBackoffice,
          value: activeBackoffice,
        },
      ]);
    }
  }, [idFeatureBackoffice, activeBackoffice]);

  useEffect(() => {
    if (botActive !== '' && idFeatureBotActive !== '') {
      setUpdateBotActive([
        ...updateBotActive.filter(({id})=> id != idFeatureBotActive ),
        {
          id: idFeatureBotActive,
          value: botActive,
        },
      ]);
    }
  }, [botActive, idFeatureBotActive]);

  useEffect(() => {
    if (setSaveFunction) {
      setSaveFunction(fetchUpdatePermission);
    }
  }, [updatePermission]);

  useEffect(() => {
    if (setSaveFunction) {
      setSaveFunction(fetchActiveBackoffice);
    }
  }, [updateBackoffice]);

  useEffect(() => {
    if (setSaveFunction) {
      setSaveFunction(fetchBotActive);
    }
  }, [updateBotActive]);

  useEffect(() => {
    let counterTotal = counterBackoffice + counterChatbot + counterPermission;
    let counterTotalError =
      counterBackofficeError + counterChatbotError + counterPermissionError;
    if (
      (counterTotal > 0 && fetchBackofficeFinished) ||
      fetchFinished ||
      fetchedChatbot
    ) {
      _sendFeedback('success', m.w.success.saved);
      setFetchedChatbot(false);
      setFetchFinished(false);
      setFetchBackofficeFinished(false);
    } else if (
      (counterTotalError > 0 && fetchBackofficeFinished) ||
      fetchFinished ||
      fetchedChatbot
    ) {
      _sendFeedback('danger', m.w.error.general);
      setFetchedChatbot(false);
      setFetchFinished(false);
      setFetchBackofficeFinished(false);
    }
  }, [fetchFinished, fetchBackofficeFinished, fetchedChatbot]);

  const getFeatures = async () => {
    setLoad(true);
    const res = await Api._getFeatures();
    if (res.getFeatures.features) {
      const featuresFiltered = res.getFeatures.features.filter(
        (feature) => feature.is_editable
      );
      setFeaturesList(featuresFiltered);
      setLoad(false);
    }
  };

  const fetchUpdatePermission = async () => {
    let counter = 0;
    let counterError = 0;
    return Promise.all(
      updatePermission.map(async (el) => {
        let res = await Api._addBotFeature({
          bot_id: m.bot.id,
          feature_id: el.id,
          required_permission: Number(el.value),
          is_bot_active: updateBotActive.length
            ? updateBotActive.find((i) => i.id === el.id).value
            : featuresSorted.find((x) => x.id === el.id).is_bot_active,
        });
        if (res.success) {
          counter++;
          setCounterPermission(counter);
        } else {
          counterError++;
          setCounterPermissionError(counterError);
        }
      })
    ).finally(() => {
      setUpdatePersmission([]);
      setFetchFinished(true);
    });
  };

  const handleValuePermission = (value, id) => {
    setValuePermission(value);
    setIdFeature(id);
  };

  const handleActiveBackoffice = (value, id) => {
    setIdFeatureBackoffice(id);
    setActiveBackoffice(value);
  };
  const fetchActiveBackoffice = async () => {
    let counter = 0;
    let counterError = 0;
    Promise.all(
      updateBackoffice.map(async (el) => {
        if (el.value === 'true') {
          let res = await Api._removeFeatures({
            bot_id: m.bot.id,
            feature_id: el.id,
          });
          if (res.success) {
            counter++;
            setCounterBackoffice(counter);
          } else {
            counterError++;
            setCounterBackofficeError(counterError);
          }
        } else {
          let res = await Api._addBotFeature({
            bot_id: m.bot.id,
            feature_id: el.id,
            is_bot_active: false,
          });
          if (res.success) {
            counter++;
            setCounterBackoffice(counter);
          } else {
            counterError++;
            setCounterBackofficeError(counterError);
          }
        }
      })
    ).finally(() => {
      setUpdateBackoffice([]);
      setFetchBackofficeFinished(true);
    });
  };

  const handleBotActive = (value, id) => {
    setIdFeatureBotActive(id);
    setBotActive(Boolean(value));
  };

  const fetchBotActive = async () => {
    let counter = 0;
    let counterError = 0;
    return Promise.all(
      updateBotActive.map(async (el) => {
        let res = await Api._addBotFeature({
          bot_id: m.bot.id,
          feature_id: el.id,
          is_bot_active: el.value,
          required_permission: updatePermission.length
            ? Number(updatePermission.find((i) => i.id === el.id).value)
            : featuresSorted.find((x) => x.id === el.id).required_permission,
        });
        if (res.success) {
          counter++;
          setCounterChatbot(counter);
        } else {
          counterError++;
          setCounterChatbotError(counterError);
        }
      })
    ).finally(() => {
      setFetchedChatbot(true);
      setUpdateBotActive([]);
    });
  };

  return (
    <StyledContainer width={'95'}>
      <StyledTitle>{w.featuresSelect}</StyledTitle>
      <WrapperGrid>
        <StyledItem>{w.feature}</StyledItem>
        <StyledItem>{w.description}</StyledItem>
        <StyledItem>{w.backofficeActive}</StyledItem>
        <StyledItem>{w.visibleFor}</StyledItem>
        <StyledItem>{w.chatbotActive}</StyledItem>
      </WrapperGrid>
      {load && <Loader />}
      {featuresSorted.map((feature) => (
        <Feature
          {...feature}
          key={feature.id}
          handleValuePermission={handleValuePermission}
          handleActiveBackoffice={handleActiveBackoffice}
          handleBotActive={handleBotActive}
          w={w}
        />
      ))}
    </StyledContainer>
  );
};

export default Administration;
