import React, { useEffect } from 'react';
import Bot from '../../_api/bot';
import Loader from '../../components/Loader.js';
import { Sidebar, Subsbar } from '../../components/scenario/Ui';
import Content from '../../components/scenario/Content';
import { _sendFeedback } from '../../_tools/ui';
import { _generatePath } from '../../components/scenario/Reducer';
import Nodata from '../../components/Nodata';
import {
  moduleTemplate,
  _findDup,
  _extractIds,
} from '../../components/scenario/Reducer';

const Advanced = (props) => {
  const {
    m: { w, bot, client },
    subID,
    changeSub,
    state,
    dispatch,
  } = props;

  if (subID !== 1) changeSub(1);

  useEffect(() => {
    dispatch({ type: 'setDefaultLang', payload: bot.default_language });
    const fetchScenario = async () => {
      let api = new Bot(client, bot.id);
      dispatch({ type: 'setLoading', payload: true });
      let res = await api._getModules();
      if (res.success && res.json) {
        if ('activated' in res.json && !res.json.activated) {
          dispatch({ type: 'setActivated', payload: res.json.activated });
        } else {
          let keys = Object.keys(res.json.trigger);
          let modules = [];
          let subs = [];
          keys.forEach((k) => modules.push({ key: k, ...res.json.trigger[k] }));
          let ids = _extractIds(modules);
          subs = modules.filter((m) => m.custom && m.tag);
          modules = modules.filter((m) => m.custom && !m.tag);
          modules = modules.filter((el) => {
            if (
              el.id.toLowerCase() === 'start_lang' &&
              props.m.user.user.permissions !== 1
            )
              return false;
            else return true;
          });
          if (!state.focus && modules.length)
            dispatch({ type: 'setFocus', payload: modules[0] });
          dispatch({ type: 'setIds', payload: ids });
          dispatch({ type: 'setModules', payload: modules });
          dispatch({ type: 'setSubModules', payload: subs });
          dispatch({ type: 'setApi', payload: api });

          // HANDLE FORCE
          if (props.force || props.subforce) {
            let module = modules.find((m) => m.id === props.force);
            if (module) dispatch({ type: 'setFocus', payload: module });
            else {
              let id = _findDup(props.force, ids);
              _sendFeedback('warning', 'loader');

              let res = await api._createScenarioFromTemplate({
                name: id,
                type: 'mediation',
              });
              if (res.success) {
                dispatch({ type: 'setFocus', payload: res.json });
                dispatch({ type: 'setIds', payload: ids.concat(id) });
                _sendFeedback('success', w.success.saved);
              } else _sendFeedback('danger', w.error.general);
            }
            if (props.subforce) {
              let reg = new RegExp(props.subforce);
              let subModule = Object.keys(module.trigger || {}).find((m) =>
                module.trigger[m].id.match(reg)
              );
              if (subModule)
                dispatch({
                  type: 'setSubFocus',
                  payload: { key: subModule, ...module.trigger[subModule] },
                });
              else {
                let id = _findDup(props.subforce, ids);
                subModule = moduleTemplate({
                  id,
                  name: id,
                  tag: props.folder || '',
                  jump: module.id,
                  disable: false,
                  undeletable: true,
                });
                const { key, ...other } = subModule;
                module = {
                  ...module,
                  trigger: { ...module.trigger, [key]: other },
                };
                {
                  const { key, ...other } = subModule;
                  let res = await api._createModule({
                    path: _generatePath(module).concat(['trigger', key]),
                    module: { [key]: other },
                  });
                  if (res.success) {
                    dispatch({ type: 'setFocus', payload: module });
                    dispatch({ type: 'setSubFocus', payload: subModule });
                    dispatch({ type: 'setIds', payload: ids.concat(id) });
                    _sendFeedback('success', w.success.saved);
                  } else _sendFeedback('danger', w.error.general);
                }
              }
            }
          }
        }
      } else dispatch({ type: 'setError', payload: res.error });
      dispatch({ type: 'setLoading', payload: false });
    };

    fetchScenario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.m.bot]);

  //Refresh payloads
  useEffect(() => {
    dispatch({ type: 'setDefaultLang', payload: bot.default_language });
    let payloads = [];
    if (state.focus) {
      if ('trigger' in state.focus) {
        let triggers = state.focus.trigger;
        payloads = Object.keys(triggers).map((k) => ({
          id: triggers[k].id,
          value: triggers[k].name,
          key: triggers[k].id,
          tag: triggers[k].tag,
        }));
        if (props.subforce)
          payloads = payloads.filter((e) => e.tag === props.subforce);
      } else if (state.focus && state.subModules) {
        let reg = new RegExp(`^${state.focus.id}/`);
        payloads = state.subModules
          .filter((m) => m.tag.match(reg))
          .map((m) => ({ id: m.id, value: m.name, key: m.id }));
      }
    }
    dispatch({ type: 'setPayloads', payload: payloads });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.focus, state.subModules]);

  if (state.loading) return <Loader className="has-text-primary-ter" />;

  if ('activated' in state && !state.activated)
    return <Nodata title={w.scenario.botDesactivated} />;

  if (state.error) throw new Error(state.error);

  return (
    <div
      className="has-text-primary-ter w-100p relative space-nowrap overflow-hidden"
      style={{ display: 'flex' }}
    >
      {!props.force && (
        <Sidebar {...props} {...state} dispatch={dispatch} advanced="true" />
      )}
      {state.focus && (
        <Subsbar {...props} {...state} dispatch={dispatch} advanced="true" />
      )}
      <Content {...props} {...state} dispatch={dispatch} advanced="true" />
    </div>
  );
};

export default Advanced;
