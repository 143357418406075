import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import EditTopicContent from './EditTopicContent';
import { Topic as TopicTypes } from '../../../types/qa/topics';
import { _isButton, _isGeneric } from '../../../_tools/is';
import { _getKeyValue } from '../../../_tools/other';
import { QAContext } from '../context/QAContext';
import Switch from '../../../components/base/Switch';
import { Api } from '../../../api/graphql/hooks/useTopics';
import BaseModal from '../../../components/BaseModal';
import ChangeCategory from '../components/modals/ChangeCategory';
import EditLeoLink from '../components/modals/EditLeoLink';
import LinkTo from '../components/modals/LinkTo';
import linkEmpty from '../../../_assets/icon/linkEmpty.svg';
import linkLeo from '../../../_assets/icon/linkLeo.svg';
import reattach from '../../../_assets/icon/reattach.svg';
import classify from '../../../_assets/icon/classify.svg';
import talkToHuman from '../../../_assets/icon/talkToHuman.svg';
import talkToHumanDisabled from '../../../_assets/icon/talkToHumanDisabled.svg';
import EditFallback from "../components/modals/EditFallback";
interface Props {
  topic: TopicTypes;
  api: Api;
  botId: number;
  token: string;
  w: any;
}

interface TopicItemProps {
  readonly justify?: boolean;
}

const Topic = (props: Props) => {
  const { state, dispatch } = useContext(QAContext);
  const [edit, setEdit] = useState<boolean>(false);
  const [editName, setEditName] = useState<boolean>(false);
  const [nameValue, setNameValue] = useState<string>(props.topic.name);
  const [editFolder, setEditFolder] = useState<boolean>(false);
  const [editLeoLink, setEditLeoLink] = useState<boolean>(false);
  const [editLinkToScenario, setEditLinkToScenario] = useState<boolean>(false);
    const [editFallback, setEditFallback] = useState<boolean>(false);
  const { name, answers } = props.topic;

  let firstAnswer = answers
    ? answers.filter((answer) => answer.enabled)[0]?.content
    : '';

  return (
    <>
      <TopicContainer
        onClick={() =>
          dispatch({ type: 'SET_CURRENT_TOPIC', payload: props.topic.id })
        }
      >
        <TopicItem>
          {editName ? (
            <StyledField>
              <input
                type="text"
                value={nameValue}
                onChange={(e) => setNameValue(e.target.value)}
              />
              <button
                onClick={() => {
                  props.api.updateTopic(
                    props.topic.id,
                    'fr',
                    'name',
                    nameValue
                  );
                  setEditName(false);
                }}
              >
                <i className="fas fa-check" />
              </button>
            </StyledField>
          ) : (
            <StyledName>
              <div>{name}</div>
              {state.permission === 1 && (
                <div onClick={() => setEditName(!editName)}>
                  <StyledIcon className="fas fa-pen" />
                </div>
              )}
            </StyledName>
          )}
        </TopicItem>
        {firstAnswer && !_isGeneric(firstAnswer) ? (
          _isButton(firstAnswer) ? (
            <StyledButton>
              {_getKeyValue(JSON.parse(firstAnswer), 'buttons')[0]?.title}
            </StyledButton>
          ) : (
            <TopicItem justify>
              <div> {JSON.parse(firstAnswer)[0]?.value}</div>
            </TopicItem>
          )
        ) : (
          <TopicItem />
        )}
        <TopicItem>
          <WrapperOptions>
            <div onClick={() => setEdit(!edit)}>
              <i className="fas fa-pen"></i>
            </div>
            <div onClick={() => setEditFolder(!editFolder)}>
              <img src={classify} width="16px" />
            </div>
            {state.permission === 1 && (
              <>
                <div>
                  <img
                    src={reattach}
                    width="16px"
                    onClick={() => setEditLinkToScenario(!editLinkToScenario)}
                  />
                </div>
                <div onClick={() => setEditLeoLink(!editLeoLink)}>
                  {props.topic.targets?.length ? (
                    <img src={linkLeo} width="16px" />
                  ) : (
                    <img src={linkEmpty} width="16px" />
                  )}
                </div>
                  <div>
                      <img
                          src={props.topic.is_fallback ? talkToHuman : talkToHumanDisabled}
                          width="16px"
                          onClick={() => setEditFallback(!editFallback)}
                      />
                  </div>
              </>
            )}
          </WrapperOptions>
        </TopicItem>
        <TopicItem>
          {' '}
          <Switch
            toggle={props.topic.enabled}
            handleClick={() => {
              props.api.updateTopic(
                props.topic.id,
                state.language,
                'enabled',
                !props.topic.enabled
              );
              dispatch({
                type: 'SET_NUMBER_QA',
                payload: {
                  from: state.currentCategoryId,
                  to: null,
                  enabled: props.topic.enabled,
                },
              });
            }}
          />{' '}
        </TopicItem>
      </TopicContainer>
      {edit && <EditTopicContent topic={props.topic} api={props.api} w={props.w}/>}
      <BaseModal isOpen={editFolder} selector="#root">
        <ChangeCategory
          close={() => setEditFolder(!editFolder)}
          handleChangeCategory={(catId) => {
            dispatch({
              type: 'SET_NUMBER_QA',
              payload: {
                from: state.currentCategoryId,
                to: catId,
                enabled: props.topic.enabled,
              },
            });
            props.api.updateTopic(props.topic.id, 'fr', 'categoryId', catId);
            setEditFolder(false);
          }}
        />
      </BaseModal>
      <BaseModal isOpen={editFallback} selector="#root">
          <EditFallback
              api={props.api}
              topic={props.topic}
              close={() => setEditFallback(!editFallback)}
          />
      </BaseModal>
      <BaseModal isOpen={editLinkToScenario} selector="#root">
        <LinkTo
          close={() => setEditLinkToScenario(!editLinkToScenario)}
          name={props.topic.name}
          botId={props.botId}
          token={props.token}
          api={props.api}
          topic={props.topic}
          w={props.w}
        />
      </BaseModal>
      <BaseModal isOpen={editLeoLink} selector="#root">
        <EditLeoLink
          botId={props.botId}
          token={props.token}
          api={props.api}
          close={() => setEditLeoLink(!editLeoLink)}
          w={props.w}
        />
      </BaseModal>
    </>
  );
};

const TopicContainer = styled.div`
  height: auto;

  align-items: center;
  display: grid;
  grid-template-columns: 2fr 4fr 2fr 1fr;
  justify-content: center;
  border-bottom: 1px solid #ededf7;
`;

const TopicItem = styled.div<TopicItemProps>`
  border-right: 1px solid #ededf7;
  text-align: center;
  padding: 0.25rem;
  min-height: -webkit-fill-available;
  display: flex;
  justify-content: ${({ justify }) => (justify ? 'flex-start' : 'center')};
  align-items: center;
  word-break: break-word;
  &::last-child {
    border-right: none;
  }
`;

const StyledButton = styled.div`
  border: 1px solid #ededf7;
  text-align: center;
  padding: 7px;
  font-size: 1em;
  border-radius: 8px;
`;

const WrapperOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    padding: 0.25rem;
    cursor: pointer;
    margin-left: 0.6rem;
    > i {
      color: #2d307b;
    }
  }
`;

const StyledField = styled.div`
  display: flex;
  justyfi-content: space-between;
  align-items: center;
  padding: 0.25rem;
  color: #2d307b;

  > input {
    border: 1px solid #c7c8ea;
    background-color: #dadbf1;
    color: #2d307b;
    padding: 7px;
    outline: none;
    font-size: 1rem;
  }

  > button {
    border: 2px solid #4246b3;
    background-color: #5054be;
    color: white;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    font-size: 1em;
    padding: 0.25rem;
  }
`;

const StyledIcon = styled.i`
  color: #2d307b;
`;

const StyledName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0.6rem;
  text-align: left;
`;

export default Topic;
