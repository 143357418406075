import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      thumb: {
        color: 'white',
      },
    },
  },
  palette: {
    primary: {
      light: '#7a7ab8',
      main: '#2D2F7B',
    },
    secondary: {
      main: '#E66E6E',
    },
    background: {
      default: '#2D2F7B',
    },
  },
  typography: {
    fontFamily: [
      'Source Sans pro',
      'Roboto',
      'BlinkMacSystemFont',
      'Arial',
      'sans-serif',
      '"Segoe UI"',
      '"Helvetica Neue"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default theme;
