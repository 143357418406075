import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Field, Input, Icon, Button, Select } from '@ask-mona/ui';
import { _periodToStr, _dateToStr } from '../../../_tools/oph';
import { _sendFeedback } from '../../../_tools/ui';
import FlagIcon from '../../../components/FlagIcon';
import Settings from './Settings';
import QaContext from '../context/qaContext';
import Bot from '../../../_api/bot';

export const Head = (props) => {
  const {
    m: { bot_lang },
    lang,
    setLang,
    focus,
  } = props;
  return (
    <div
      className="has-background-primary-light p-md flex items-center space-between sticky top"
      style={{ zIndex: 1 }}
    >
      {focus ? focus[lang] || focus.title || focus.id : ''}
      <div className="flex row">
        <Button
          className={`m-r-sm is-outlined ${
            lang === 'en' ? 'is-borderless' : ''
          } shrink`}
          onClick={() => setLang('fr')}
        >
          <FlagIcon code="fr" />
        </Button>
        {bot_lang.some((l) => l === 'en') && (
          <Button
            className={`m-r-sm is-outlined ${
              lang === 'en' ? '' : 'is-borderless'
            } shrink`}
            onClick={() => setLang('en')}
          >
            <FlagIcon code="gb" />
          </Button>
        )}
      </div>
    </div>
  );
};

export const Sidebar = (props) => {
  const {
    m: {
      w,
      user: { organization, user },
      bot,
    },
    data,
    lang,
    search,
    cats,
    focus,
    setFocus,
    setAddCat,
    setSearch,
  } = props;

  const focused = focus ? focus.id : '';

  const leoBot = bot.features.some((el) => el.slug === 'leo');

  const { getCategoriesV2 } = data;

  return (
    <div
      className="w-25p inline-block h-100p border-r-primary-lighter align-top overflow-scroll"
      style={{ height: 'inherit' }}
    >
      <div
        className={`flex center has-text-primary-ter ${
          search ? 'has-background-primary-ter' : ''
        } items-center p-sm border-b-primary-lighter`}
      >
        <Input
          placeholder={w.generic.search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {!search && (
        <React.Fragment>
          <Tab
            {...props}
            label={w.qa.allActivated}
            setFocus={() =>
              setFocus({ id: 'allActivated', title: w.qa.allActivated })
            }
            focused={focused === 'allActivated'}
          />
          {cats
            .filter((c) => {
              if (organization.id === 1) return true;
              else return c.id !== '1545240456' || c.id !== '12331565';
            })
            .filter((c) => {
              if (
                c.fr &&
                c.fr.toLowerCase() === 'programmatique' &&
                user.permissions !== 1
              )
                return false;
              else return true;
            })
            .sort((a, b) => {
              if (a.intents.length < b.intents.length) return 1;
              else if (a.intents.length > b.intents.length) return -1;
              else return 0;
            })
            .map((i) => {
              let isFocused = focus ? focus.id === i.id : false;
              let active = i.intents.filter((e) => {
                if (leoBot) {
                  return !e.deleted;
                } else {
                  if (!e[lang]) return false;
                  return !e[lang].deleted;
                }
              });
              return (
                <Tab
                  {...props}
                  key={i.id}
                  label={i.fr}
                  deletable={i.bot_id}
                  id={i.id}
                  active={active.length}
                  desactive={i.intents.length - active.length}
                  setFocus={() => setFocus(i)}
                  focused={isFocused}
                  programmatique={
                    i.fr && i.fr.toLowerCase() === 'programmatique'
                      ? true
                      : false
                  }
                />
              );
            })}
          {bot.features.some((el) => el.slug === 'leo') &&
            getCategoriesV2 &&
            getCategoriesV2
              .filter((cat) => {
                if (
                  cat.bot_id === bot.id &&
                  cat.intents === null &&
                  !cats.some((el) => el.id === cat.id)
                )
                  return true;
                else return false;
              })
              .map((i) => {
                let isFocused = focus ? focus.id === i.id : false;
                return (
                  <Tab
                    {...props}
                    key={i.id}
                    label={i.fr}
                    deletable={i.bot_id}
                    id={i.id}
                    active={0}
                    desactive={0}
                    setFocus={() => setFocus(i)}
                    focused={isFocused}
                    programmatique={
                      i.fr && i.fr.toLowerCase() === 'programmatique'
                        ? true
                        : false
                    }
                  />
                );
              })}

          <Tab
            {...props}
            label={w.qa.allDesactivated}
            setFocus={() =>
              setFocus({ id: 'allDesactivated', title: w.qa.allDesactivated })
            }
            focused={focused === 'allDesactivated'}
          />
        </React.Fragment>
      )}
      {organization.id === 1 && (
        <div className="flex center p-md">
          <Button
            className="is-outlined is-borderless shrink"
            onClick={() => setAddCat(true)}
          >
            {`+ ${w.pricing.addCategorie}`}
          </Button>
        </div>
      )}
    </div>
  );
};

export const Board = (props) => {
  const {
    m: {
      w,
      client,
      bot: { types, id },
    },
    c: { mail, period, place_id },
    dispatch,
    items,
  } = props;

  const [addEl, setAddEl] = useState(false);
  const [param, setParam] = useState(false);
  const [optionsPlaces, setOptionsPlaces] = useState([]);
  const [placeSelected, setPlacesSelected] = useState({});
  const isMultisite = types.some((el) => el.slug === 'multisite');

  const getBot = async () => {
    const Api = new Bot(client, id);
    const res = await Api._getBot();
    setOptionsPlaces(
      res.getBot.places.map((i) => {
        if (i.main_site) {
          return {
            id: i.id,
            value: `${i.name} ✩`,
          };
        } else {
          return {
            id: i.id,
            value: i.name,
          };
        }
      })
    );
    if (isMultisite) {
      const currentPlace = res.getBot.places.find((el) => el.id === place_id);
      if (currentPlace) {
        setPlacesSelected({
          id: currentPlace.id,
          value: currentPlace.name,
        });
      } else {
        const mainSite = res.getBot.places.find((el) => el.main_site);
        setPlacesSelected({
          id: mainSite.id,
          value: mainSite.name,
        });
        dispatch({
          type: 'addPlaceId',
          payload: mainSite.id,
        });
      }
    }
  };

  useEffect(() => {
    getBot();
  }, []);

  const qaContext = useContext(QaContext);

  let date = '';
  if (period && period !== 'permanent') {
    date = _periodToStr(period);
  }

  let reminderDates = [];
  if (mail && mail.activated) {
    let arr = mail.remind_interval.split(' ').filter((o) => o);
    arr.forEach((el) => {
      if (el) reminderDates.push({ str: _dateToStr(el), oph: el });
    });
  }

  return (
    <div>
      {addEl && <Menu {...props} />}
      <Button
        className="is-underlined is-size-7 is-outlined is-borderless shrink"
        onClick={() => setAddEl(!addEl)}
      >
        {w.qa.addElement}
      </Button>
      <Button
        className="is-underlined is-size-7 is-outlined is-borderless shrink"
        onClick={() => qaContext.copyItems(items)}
      >
        {w.qa.copyElements}{' '}
      </Button>
      {qaContext.items && qaContext.items.length > 0 && (
        <div className="flex content-center">
          <Button
            className="is-underlined is-size-8 is-outlined is-borderless shrink"
            onClick={() =>
              qaContext.items.map((item) => {
                dispatch({
                  type: 'addElement',
                  payload: {
                    type: item.type,
                    value: item.content,
                  },
                });
                return null;
              })
            }
          >
            {w.qa.pasteElements}{' '}
          </Button>
          <Button
            className="is-size-8 shrink is-outlined is-borderless"
            color="danger"
            onClick={() => qaContext.copyItems([])}
          >
            <Icon icon="fas fa-times"></Icon>
          </Button>
        </div>
      )}
      <div className="is-size-7 is-italic space-pre-line">
        {date && <div> {`${w.qa.prog} ${date}`} </div>}
        {mail && mail.activated && (
          <React.Fragment>
            <div className="is-lowercase is-capitalized">
              {`${w.qa.reminder} ${w.generic.the} `}
              {reminderDates.map((el) => {
                return (
                  <Date
                    key={el.str}
                    onClick={() =>
                      param &&
                      dispatch({
                        type: 'removeReminderInterval',
                        payload: el.oph,
                      })
                    }
                    str={el.str}
                  />
                );
              })}
            </div>
            <div>{`${w.generic.for}: ${(mail.mail || []).join(', ')}`}</div>
          </React.Fragment>
        )}
      </div>
      <Button
        className="is-underlined is-size-7 is-outlined is-borderless shrink"
        onClick={() => setParam(!param)}
      >
        {w.qa.addParam}
      </Button>
      {isMultisite && (
        <div>
          <WrapperScope>
            <p>{w.generic.scopeAnswer}</p>
            <StyledPlace>{placeSelected.value}</StyledPlace>
          </WrapperScope>
          <WrapperSelect>
            <Select
              placeholder={'Remplacer le lieu'}
              items={optionsPlaces}
              onSelect={(e) => {
                setPlacesSelected({
                  id: e.id,
                  value: e.value.includes('✩')
                    ? e.value.replace('✩', '')
                    : e.value,
                });
                dispatch({
                  type: 'addPlaceId',
                  payload: e.id,
                });
              }}
            />
          </WrapperSelect>
        </div>
      )}
      {param && <Settings {...props} />}
    </div>
  );
};

const Date = (props) => {
  const [hover, setHover] = useState(false);
  return (
    <span
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`${hover ? 'has-background-danger-light' : ''} crosshair`}
      onClick={props.onClick}
    >
      {`${props.str}, `}
    </span>
  );
};

const btns = [
  { id: 0, type: 'text', name: 'text', icon: 'fas fa-font' },
  { id: 1, type: 'image', name: 'image', icon: 'fas fa-image' },
  { id: 2, type: 'video', name: 'video', icon: 'fab fa-youtube' },
  { id: 3, type: 'image', name: 'gif', icon: 'fas fa-file' },
  { id: 5, type: 'list', name: 'list', icon: 'fas fa-list-alt' },
];

const Menu = (props) => {
  const {
    m: { w },
    dispatch,
  } = props;
  return (
    <div className="flex row wrap center">
      {btns.map(({ type, icon, name, ...b }) => {
        return (
          <Button
            {...b}
            key={b.id}
            className="is-size-8 is-borderless is-outlined m-xxs rad-25px is-underlined"
            onClick={() =>
              dispatch({
                type: 'addElement',
                payload: {
                  type,
                  value:
                    type === 'image'
                      ? 'placeholder.png'
                      : type === 'video'
                      ? 'placeholder.webm'
                      : '',
                },
              })
            }
          >
            <Icon icon={icon} />
            {w.generic[name]}
          </Button>
        );
      })}
    </div>
  );
};

const Tab = (props) => {
  const {
    label,
    focused,
    setFocus,
    deletable,
    id,
    m: {
      w,
      bot,
      user: { organization },
    },
    api: { intent },
    lang,
    active,
    desactive,
  } = props;
  const [changeName] = useState(id && organization.id === 1);
  const [name, setName] = useState(label);
  const [edit, setEdit] = useState(false);

  let title = label;
  if (typeof active === 'number') title = `(${active}/${desactive}) ${label}`;
  return (
    <div
      className={`p-md flex row items-center space-between border-b-primary-lighter pointer ${
        props.programmatique
          ? 'has-background-secondary has-text-white'
          : focused
          ? 'has-background-primary-ter has-text-white'
          : ''
      }`}
      onClick={setFocus}
      style={{ backroundColor: props.programmatique && 'orangered' }}
    >
      {edit && (
        <Field>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
          <Button
            className="is-size-7"
            onClick={async () => {
              if (name !== label) {
                _sendFeedback('warning', 'loader');
                let res = await intent._updateCategory(
                  {
                    id: id,
                    [lang]: name,
                  },
                  {
                    refetchQueries: ['getCategoriesAndIntents'],
                  }
                );
                if (res.success) _sendFeedback('success', w.success.saved);
                else _sendFeedback('danger', w.error.general);
              }
              edit && setEdit(false);
            }}
          >
            {w.generic.validate}
          </Button>
        </Field>
      )}
      {!edit && (
        <React.Fragment>
          <span className="space-pre-line word-break-break-word">{title}</span>
          {focused && <Icon icon="fas fa-chevron-right" />}
          {changeName && deletable && (
            <div className="flex row">
              <Button
                color="secondary"
                className="is-outlined is-borderless shrink"
                onClick={async () => {
                  _sendFeedback('warning', 'loader');
                  let res = await intent._deleteCategory(
                    {
                      bot_id: bot.id,
                      id: id,
                    },
                    {
                      refetchQueries: ['getCategoriesAndIntents'],
                    }
                  );
                  if (res) _sendFeedback('success', w.success.saved);
                  else _sendFeedback('danger', w.error.general);
                }}
              >
                <Icon icon="fas fa-trash" />
              </Button>
              <Button
                className="is-outlined is-borderless shrink"
                onClick={() => setEdit(true)}
              >
                <Icon icon="fas fa-pen" />
              </Button>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const WrapperScope = styled.div`
  display: flex;
  align-items: center;
  margin: 0.6rem 0;
  font-size: 14px;
`;

const WrapperSelect = styled.div`
  width: 50%;
  font-size: 14px;
`;

const StyledPlace = styled.div`
  background: #2d2e7c;
  color: #fff;
  padding: 0.4rem;
  margin-left: 0.4rem;
`;
