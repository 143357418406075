import React from 'react';
import { _getKeyValue, _splitReverseJoin } from '../../../_tools/other';
import { Checkbox, Switch, Icon, Button } from '@ask-mona/ui';
import Nodata from '../../../components/Nodata';

export default ({ data, fct, mediations, m: { w, lang }, filters }) => {
  let items = _getKeyValue(data, 'getMediations') || [];

  // Filters
  if (filters.published) {
    if (filters.published === 1) items = items.filter((m) => m.published);
    else if (filters.published === 2) items = items.filter((m) => !m.published);
  }
  if (filters.finished) {
    items = items.filter((m) => {
      if (!m.date_end) return false;
      else {
        const today = new Date();
        const endDate = new Date(m.date_end);
        return endDate < today;
      }
    });
  }
  if (filters.title) {
    items = items.filter((m) => {
      const reg = new RegExp(`.*${filters.title}.*`, 'ig');
      return reg.test(m.name);
    });
  }

  items = items.map((e) => {
    let title = '',
      date = '',
      place = '',
      published = e.published,
      id = e.id,
      date_start = e.date_start
        ? _splitReverseJoin(e.date_start, '-', '/')
        : '',
      date_end = e.date_end ? _splitReverseJoin(e.date_end, '-', '/') : '';

    // Set Title
    title = e.name || w.events.noTitle;
    // Set Place
    place = e.sub_place ? e.sub_place.name : w.events.noPlace;
    // Set Dates
    if (date_start && date_end) {
      if (date_start === date_end) date = `${w.generic.the} ${date_start}`;
      else
        date = `${w.generic.from} ${date_start}\n${w.generic.to} ${date_end}`;
    } else {
      date = date || w.generic.permanent;
    }

    return {
      id,
      title,
      date,
      place,
      published,
    };
  });

  if (!items.length) return <Nodata title={w.error.noMediation} />;

  return items.map((e) => (
    <div
      className="flex row items-center is-size-6bis border-b-primary-lighter"
      key={e.id}
    >
      <div className="m-b-md p-xs flex items-start">
        {' '}
        <Checkbox
          checked={mediations.find((id) => id === e.id) || false}
          onChange={() => fct.onListMediation(e, 'pp')}
          className="small"
        />{' '}
      </div>
      <div
        className="p-xs flex grow-2 basis-0 pointer"
        onClick={() => fct.onListMediation(e, 'edit')}
      >
        {e.title}
      </div>
      <div className="p-xs flex grow-2 basis-0 space-pre">{e.date}</div>
      <div className="p-xs flex grow-2 basis-0">{e.place}</div>
      <div className="p-xs flex grow-1 basis-0 center">
        {' '}
        <Switch
          color="secondary"
          checked={e.published}
          onChange={() => fct.onListMediation(e, 'toggle')}
        />{' '}
      </div>
      <div className="p-xs flex grow-1 basis-0 center">
        <Button
          className="is-borderless is-outlined shrink"
          onClick={() => fct.onListMediation(e, 'edit')}
        >
          {' '}
          <Icon className="has-text-primary" icon="fas fa-pencil-alt" />{' '}
        </Button>
        <Button
          className="is-borderless is-outlined shrink"
          onClick={() => fct.onListMediation(e, 'delete')}
        >
          {' '}
          <Icon className="has-text-primary" icon="fas fa-trash" />{' '}
        </Button>
      </div>
    </div>
  ));
};
