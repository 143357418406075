import React, { Component } from 'react';
import { Field, Button, Input } from '@ask-mona/ui';
import { _uploadVid } from '../../../../_api/upload';
import { _isVid } from '../../../../_tools/is';

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modify: true,
      loading: false,
      error: '',
    };
  }

  onUpload = async (e) => {
    const { botID, src } = this.props;
    this.setState({ loading: true });
    await _uploadVid(
      botID,
      src,
      (res) => {
        this.props.onChange({ target: { value: res.path } });
      },
      (err) => {
        this.setState({ error: err });
      }
    );
    this.setState({ loading: false });
  };

  render() {
    const { src } = this.props;
    return (
      <div
        className="relative"
        style={{ minHeight: '150px', background: '#555' }}
      >
        {src && _isVid(src) && (
          <div>
            <video className="maxWidth-100" controls src={src}>
              <source src={src} type={`video/webm`} />
            </video>
          </div>
        )}
        {this.state.modify && (
          <div
            className="absolute has-content-centered"
            style={{ top: '0', left: '0', height: '100%', width: '100%' }}
          >
            <VidInput
              loading={this.state.loading}
              value={src || ''}
              onChange={this.props.onChange}
              onUpload={this.onUpload}
              error={this.state.error}
            />
          </div>
        )}
      </div>
    );
  }
}

const VidInput = ({ error, loading, value, onChange, onUpload }) => (
  <Field error={error}>
    <Input value={value} onChange={onChange} />
    <Button onClick={onUpload} loading={loading}>
      Ajouter
    </Button>
  </Field>
);

export default Video;
