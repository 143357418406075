import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { withGlobals } from '../hoc/globals';
import { DateRangePicker } from 'react-dates';

class DateRange extends Component {
  constructor(props) {
    super(props);
    const { start, end } = this.props;
    this.state = {
      startDate: start ? moment(start) : null,
      endDate: end ? moment(end) : null,
      focusedInput: null,
    };
  }

  datesChange = ({ startDate, endDate }) => {
    const { onChange } = this.props;
    this.setState({ startDate, endDate }, () => {
      onChange && onChange({ startDate, endDate });
    });
  };

  focusChange = (focusedInput) => this.setState({ focusedInput });

  render() {
    const {
      g: { w, lang },
      onChange,
      start,
      end,
      ...other
    } = this.props;
    moment.locale(lang);
    return (
      <DateRangePicker
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        minimumNights={0}
        startDatePlaceholderText={w.generic.dateStart}
        endDatePlaceholderText={w.generic.dateEnd}
        startDateId="your_unique_start_date_id"
        endDateId="your_unique_end_date_id"
        onDatesChange={this.datesChange}
        focusedInput={this.state.focusedInput}
        onFocusChange={this.focusChange}
        numberOfMonths={1}
        isOutsideRange={() => false}
        hideKeyboardShortcutsPanel
        {...other}
      />
    );
  }
}

export default withGlobals(DateRange);
