import React from 'react';
import styled from 'styled-components';
import vanGogh from '../../containers/parameter/functionality/webchat/assets/images/la-nuit-etoilee.png';
import { KeyboardArrowRight } from '@styled-icons/material-sharp/KeyboardArrowRight';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px;
  border-radius: 5px;
  width: ${({ pagination, width }) => width ? width : pagination ? '155px' : '210px !important'};
  overflow: hidden;
  height: ${({ height, pagination, preview }) => height || preview ? 'auto' : pagination ? '255px' : 'auto'};
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
  background: ${({ bgCard }) => bgCard || '#FFF'};
  border-radius: ${({ radius }) => radius}px;
`;

const WrapperContent = styled.div`
  margin: ${({ imgCover }) => imgCover ? '' : '.6rem'};
  margin-bottom: ${({ noMr }) => noMr && '0'}; 
`;

const Img = styled.a`
  display: block;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
  height: ${({ imgCover, preview }) => (preview || imgCover) ? '' : '160px'};
  > img {
    display: block;
    width: 100%;
    height: ${({ imgCover, preview }) => (preview || imgCover) ? '' : '160px'};
    max-width: 100%;
    object-fit: contain;
  }
`;

const Information = styled.a`
  display: block;
  padding: 0.5rem;
  text-decoration: none;
  word-break: break-word;
`;

const Title = styled.div`
  color: ${({ colorTitle }) => colorTitle || '#333'};
  font-weight: 700;
  font-size: ${({ fontSizeTitle }) => fontSizeTitle}em;
`;

const Subtitle = styled.div`
  font-size: ${({ fontSizeSubtitle }) => fontSizeSubtitle}em;
  color: ${({ colorTitle }) => colorTitle || '#333'};
  white-space: pre-line;
`;

const Buttons = styled.div`
  border: ${({ border }) => border ? `1px solid ${border}` : 'none'};
  border-top: ${({ borderTop, borderFirstChild }) => borderTop && !borderFirstChild && `1px solid ${borderTop}`};
  border-radius: ${({ borderTop, borderFirstChild }) => borderTop || borderFirstChild ? 'none' : '25px'};
  margin: ${({ borderTop, borderFirstChild, preview }) => (borderFirstChild && 'none') || borderTop || preview ? '0 auto' : '.4rem auto'};
  margin-top: ${({ noMr }) => noMr && '.2rem'};
  margin-bottom: ${({ noMr }) => noMr && '.2rem'};
  background-color:  ${({ bg }) => bg};
  box-shadow: '';
  text-align: center;
  font-size: ${({ fontSizeBtn }) => fontSizeBtn ? `${fontSizeBtn}em` : '10px'};
  color: ${({ color }) => color ? color : '#FFF'};
  padding: ${({ paddingBtn }) => paddingBtn || .5}rem;
  width: ${({ widthBtn }) => widthBtn };

  &:first-child {
    border-top: ${({ borderFirstChild }) => borderFirstChild && `1px solid ${borderFirstChild}`};
  }
`;

const WrapperButtons= styled.div`
  display: flex;
  flex-direction: column;
`;

const buttons = [
  {
    title: 'La peinture'
  },
  {
    title: 'Histoire du tableau'
  },
  {
    title: 'Anecdote'
  }
];

const Generic = (props) => (
  <Card
    webchat={props.webchat}
    card={props.card}
    pagination={props.pagination}
    preview={props.preview}
    width={props.width}
    bgCard={props.bgCard}
    radius={props.radius}
  >
    <WrapperContent imgCover={props.imgCover} noMr={props.noMr}>
      <Img imgCover={props.imgCover} preview={props.preview}>
        <img src={vanGogh} alt="img"/>
      </Img>
      <Information>
        <Title 
          colorTitle={props.colorTitle} 
          fontSizeTitle={props.fontSizeTitle}
        >
          La nuit étoilée
        </Title>
        <Subtitle 
          colorTitle={props.colorTitle}
          fontSizeSubtitle={props.fontSizeSubtitle}
        >
          Vincent Van Gogh, 1888
        </Subtitle>
      </Information>
    </WrapperContent>
    <WrapperButtons>
      {buttons.map(button => (
        <Buttons 
          bg={props.bg} 
          border={props.border}
          color={props.color}
          borderTop={props.borderTop}
          borderFirstChild={props.borderFirstChild}
          preview={props.preview}
          widthBtn={props.widthBtn}
          key={button.title}
          noMr={props.noMr}
          fontSizeBtn={props.fontSizeBtn}
          paddingBtn={props.paddingBtn}
        >
          {button.title}
          {props.icon && <KeyboardArrowRight size={'16'}/>}
        </Buttons>
      ))}
    </WrapperButtons>
  </Card>
);

export default Generic;
