export default {
  otherTeam: ['Équipe du chatbot', 'Chatbot team'],
  monaTeam: ['Équipe Ask Mona', 'Ask Mona Team'],
  askMonaOnly: ['Ask Mona Uniquement', 'Ask Mona Only'],
  user: ['Mon compte', 'My account'],
  team: ['Mon équipe', 'My team'],
  yourAccount: ['Votre compte', 'Your account'],
  info: [
    'Vous pouvez modifier ici les informations liées à votre compte',
    'You can change your account information here',
  ],
  firstname: ['Votre prénom', 'Your first name'],
  lastname: ['Votre nom', 'Your last name'],
  newPass: ['Votre nouveau mot de passe', 'Your new password'],
  newPassTwo: ['Confirmer votre mot de passe', 'Confirm your password'],
  yourTeam: ['Votre équipe', 'Your team'],
  infoTeam: [
    'Vous pouvez retrouver ici tous les membres de votre équipe, en ajouter ou en supprimer et modifier leurs autorisations. Les administrateurs peuvent ajouter et supprimer des membres ou bien supprimer le chatbot, à la différence des membres.',
    'You can find all the members of your team here.',
  ],
  invitation: ['Invitation', 'Invitation'],
  userName: ["Nom d'utilisateur", 'User name'],
  userMail: ['Adresse email', 'User mail'],
  language: ['langue', 'language'],
  userPerm: ['Permissions', 'User rights'],
  admin: ['Askmona', 'Askmona'],
  owner: ['Administrateur', 'Admin'],
  member: ['Membre', 'Member'],
  guest: ['Invité', 'Guest'],
  tokenSub: [
    'Vous pouvez envoyer ce lien à son destinataire',
    'You can send this link to its recipient',
  ],
  validationLink: ['Lien de validation', 'Validation link'],
  language: ["Votre langue", "Your language"],
};
