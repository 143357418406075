import React, { Component } from 'react';

class Hour extends Component {
  render() {
    const { onChangeHour, w, date, error } = this.props;
    return (
      <React.Fragment>
        <h3 className="m-b-xs is-size-7 p-b-xs has-text-primary">
          {w.push.hourTime} :*
        </h3>
        <div className="flex row w-50p h-1">
          <input
            className={
              error.errorHour
                ? 'has-background-primary-light outline-none border-none has-text-primary-ter p-md is-size-7 m-r-xs danger-time'
                : 'has-background-primary-lighter outline-none border-none has-text-primary-ter p-md is-size-7 m-r-xs'
            }
            onChange={(e) => onChangeHour(e, 'hour')}
            value={date.hour ? date.hour : ''}
            size={2}
            min={0}
            max={23}
            maxLength={2}
            type="number"
          />
          <span className="modalTime__time-input--span"> : </span>
          <input
            className={
              error.errorHour
                ? 'has-background-primary-light outline-none border-none has-text-primary-ter p-md is-size-7 m-l-xs danger-time'
                : 'has-background-primary-lighter outline-none border-none has-text-primary-ter p-md is-size-7 m-l-xs'
            }
            onChange={(e) => onChangeHour(e, 'min')}
            value={date.min ? date.min : ''}
            maxLength={2}
            size={2}
            min={0}
            max={59}
            type="number"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Hour;
