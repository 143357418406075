import React, { Component } from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import Modal from '../../components/Modal';
import { _breakOph2, _mergeOph2 } from '../../_tools/oph';
import AllAlert from './showModalAlert/AllAlert';
import { Button } from '@ask-mona/ui';
import CreateAlert from './showModalAlert/CreateAlert';
import moment from 'moment';
import { withMain } from '../../hoc/main';

//API
import { GET_BOT } from '../../_api/bot';
import {
  GET_ALERT_MESSAGES,
  DELETE_ALERT_MESSAGES,
  CREATE_ALERT_MESSAGES,
  UPDATE_ALERT_MESSAGES,
} from '../../_api/alert';

function dateFormat() {
  const res = moment(new Date()).format('L').split('/');
  return [res[2], res[1], res[0]].join('-');
}

class ShowModalAlert extends Component {
  constructor(props) {
    super(props);

    this.utc = moment().utcOffset() / 60;

    this.state = {
      arrayAlert: [],
      allAlert: null,
      selectAlert: null,
      toggle: false,
      displayHour: false,
      loader: {
        bool: false,
        index: null,
        action: null,
      },
      oph: '',
      contentFR: [],
      contentEN: [],
      bool: {
        date: false,
        period: false,
      },
      allDates: [],
      hour: {
        hourStart: null,
        hourEnd: null,
        minStart: null,
        minEnd: null,
      },
      error: {
        content: null,
        hourStart: null,
        hourEnd: null,
        minStart: null,
        minEnd: null,
        dates: null,
      },
      periods: {
        dates: [],
        blocks: [
          {
            hours: [
              {
                start: false,
                end: false,
              },
            ],
          },
        ],
        comment: false,
        name: '',
        off: false,
      },
      fct: {
        deleteAlert: this.deleteAlert.bind(this),
        updateAlert: this.updateAlert.bind(this),
        changeContent: this.changeContent.bind(this),
        updateContent: this.updateContent.bind(this),
        changeDate: this.changeDate.bind(this),
        updateDate: this.updateDate.bind(this),
        changeHour: this.changeHour.bind(this),
        updateHour: this.updateHour.bind(this),
        merge: this.merge.bind(this),

        addDate: this.addDate.bind(this),
        addNewDate: this.addNewDate.bind(this),
        deleteDate: this.deleteDate.bind(this),
        addHour: this.addHour.bind(this),
        deleteHour: this.deleteHour.bind(this),

        putDataArray: this.putDataArray.bind(this),
        toggleAlert: this.toggleAlert.bind(this),
        displayHour: this.displayHour.bind(this),
      },
    };
  }

  toggleAlert() {
    const { toggle } = this.state;
    toggle
      ? this.setState({
          error: {
            content: null,
            dates: null,
            hourStart: null,
            hourEnd: null,
            minStart: null,
            minEnd: null,
          },
          toggle: false,
        })
      : this.setState({ toggle: true });
  }

  displayHour() {
    const { displayHour } = this.state;
    displayHour
      ? this.setState({ displayHour: false })
      : this.setState({ displayHour: true });
  }

  addDate(name) {
    const { allDates } = this.state;
    const res = dateFormat();
    if (name === 'date') {
      allDates.push({ startDate: res });
      this.setState({ ...this.state, allDates: allDates });
    }
    if (name === 'period') {
      allDates.push({ startDate: res, endDate: res });
      this.setState({ ...this.state, allDates: allDates });
    }
    this.setState({
      ...this.state,
      allDates: allDates,
      error: { dates: null },
    });
  }

  addNewDate(name, i) {
    const { arrayAlert } = this.state;
    const res = dateFormat();
    arrayAlert.forEach((item, k) => {
      if (i === k) {
        if (name === 'date') {
          item.date.push({ startDate: res });
        }
        if (name === 'period') {
          item.date.push({ startDate: res, endDate: res });
        }
      }
    });
    this.setState({
      ...this.state,
      arrayAlert: arrayAlert,
      error: { dates: null },
    });
  }

  addHour(index) {
    const { arrayAlert } = this.state;
    arrayAlert.forEach((item, k) => {
      if (index === k) {
        item.hours.HS = '... ';
        item.hours.MS = ' ...';
        item.hours.HE = '... ';
        item.hours.ME = ' ...';
      }
    });
    this.setState({ ...this.state, arrayAlert: arrayAlert });
  }

  deleteHour(index) {
    const { arrayAlert } = this.state;
    arrayAlert.forEach((item, k) => {
      if (index === k) {
        item.hours.HS = false;
        item.hours.MS = false;
        item.hours.HE = false;
        item.hours.ME = false;
      }
    });
    this.setState({ ...this.state, arrayAlert: arrayAlert });
  }

  deleteDate(k, bool, indexAlert) {
    if (!bool) {
      const { allDates } = this.state;
      allDates.splice(k, 1);
      this.setState({ ...this.state, allDates: allDates });
    } else {
      const { arrayAlert } = this.state;
      arrayAlert.forEach((item, i) => {
        if (item.date.length === 1 && indexAlert === i) {
          item.hours.HS = false;
          item.hours.MS = false;
          item.hours.HE = false;
          item.hours.ME = false;
          item.date.splice(k, 1);
        } else if (indexAlert === i) {
          item.date.splice(k, 1);
        }
      });

      this.setState({ ...this.state, arrayAlert: arrayAlert });
    }
  }

  getBotAlert = () => {
    const { client, bot_id } = this.props;

    client
      .query({
        query: GET_BOT,
        variables: {
          id: bot_id,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        const alertMessages = [];
        if (res.data.getBot.config !== null) {
          this.setState({ selectAlert: res.data.getBot.config.alertMessages });
        } else {
          this.setState({ selectAlert: alertMessages });
        }

        if (res) {
          client
            .query({
              query: GET_ALERT_MESSAGES,
              variables: {
                bot_id: bot_id,
              },
              fetchPolicy: 'network-only',
            })
            .then((res) => {
              this.putDataArray(res.data.getAlertMessages);
              this.setState({ allAlert: res.data.getAlertMessages });
              return;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };

  async componentDidMount() {
    await this.getBotAlert();
    this.putDataArray.bind(this);
  }

  putDataArray(alerts) {
    let array = [];
    if (alerts.length > 0) {
      alerts.forEach((item) => {
        const { dates, blocks } = _breakOph2(item.opening_hours);
        array.push({
          id: item.id,
          opening_hours: item.opening_hours,
          content: { fr: [item.content.fr[0]], en: [] },
          date: dates,
          hours: {
            HS:
              blocks.length > 0
                ? Number(blocks[0].hours[0].start.split(':')[0]) + this.utc
                : false,
            MS:
              blocks.length > 0
                ? blocks[0].hours[0].start.split(':')[1]
                : false,
            HE:
              blocks.length > 0
                ? Number(blocks[0].hours[0].end.split(':')[0]) + this.utc
                : false,
            ME:
              blocks.length > 0 ? blocks[0].hours[0].end.split(':')[1] : false,
          },
        });
      });
      this.setState({ arrayAlert: array });
    } else {
      this.setState({ arrayAlert: null });
    }
  }

  async createAlert() {
    const { hourStart, minStart, hourEnd, minEnd } = this.state.hour;
    const { contentFR, allDates, displayHour } = this.state;

    if (
      displayHour &&
      contentFR[0] &&
      hourStart &&
      minStart &&
      hourEnd &&
      minEnd &&
      allDates.length > 0
    ) {
      const HStartUtc = hourStart - this.utc;
      const HEndUtc = hourEnd - this.utc;
      let hours = Object.assign(this.state.periods.blocks[0].hours[0]);
      let blocks = Object.assign(this.state.periods.blocks[0]);
      const start = String(HStartUtc).concat(':', minStart);
      const end = String(HEndUtc).concat(':', minEnd);
      hours.start = start;
      hours.end = end;
      blocks = [{ hours }];
      this.setState({
        loader: { bool: true, action: 'create' },
        periods: { ...this.state.periods, dates: allDates, blocks },
      });
      await this.merge();
      this.scheduleAlert();
    } else if (!displayHour && contentFR[0] && allDates.length > 0) {
      let hours = Object.assign(this.state.periods.blocks[0].hours[0]);
      let blocks = Object.assign(this.state.periods.blocks[0]);
      hours.start = false;
      hours.end = false;
      blocks = [{ hours }];
      this.setState({
        loader: { bool: true, action: 'create' },
        periods: { ...this.state.periods, dates: allDates, blocks },
      });
      await this.merge();
      this.scheduleAlert();
    } else if (!contentFR[0]) {
      this.setState({ error: { content: 'error' } });
    } else if (allDates.length === 0) {
      this.setState({ error: { dates: 'error' } });
    } else if (displayHour && !hourStart) {
      this.setState({ error: { hourStart: 'error' } });
    } else if (displayHour && !minStart) {
      this.setState({ error: { minStart: 'error' } });
    } else if (displayHour && !hourEnd) {
      this.setState({ error: { hourEnd: 'error' } });
    } else if (displayHour && !minEnd) {
      this.setState({ error: { minEnd: 'error' } });
    }
  }

  scheduleAlert(i, name) {
    const { client, bot_id } = this.props;
    const { oph, contentFR, contentEN } = this.state;
    client
      .mutate({
        mutation: name ? UPDATE_ALERT_MESSAGES : CREATE_ALERT_MESSAGES,
        variables: {
          bot_id: bot_id,
          id: name ? i : '',
          fr: contentFR,
          en: contentEN,
          opening_hours: oph,
        },
      })
      .then((res) => {
        if (res) {
          this.getBotAlert();
          if (!name) {
            this.toggleAlert();
            setTimeout(() => {
              this.setState({
                ...this.state,
                display: false,
                loader: { bool: false, action: null },
                contentFR: [],
                allDates: [],
                hour: [
                  {
                    hourStart: null,
                    hourEnd: null,
                    minStart: null,
                    minEnd: null,
                  },
                ],
                periods: {
                  dates: [],
                  blocks: [{ hours: [{ start: false, end: false }] }],
                  comment: false,
                  name: '',
                  off: false,
                },
              });
            }, 2000);
          } else
            this.setState({
              ...this.state,
              display: false,
              loader: { bool: false, action: null },
              contentFR: [],
              allDates: [],
              hour: [
                {
                  hourStart: null,
                  hourEnd: null,
                  minStart: null,
                  minEnd: null,
                },
              ],
              periods: {
                dates: [],
                blocks: [{ hours: [{ start: false, end: false }] }],
                comment: false,
                name: '',
                off: false,
              },
            });
        }
      });
  }

  async updateAlert(i, name) {
    const { periods, arrayAlert } = this.state;
    let contentFR = [];
    for (var k = 0; arrayAlert.length > k; k++) {
      if (i === k) {
        if (!arrayAlert[k].content.fr[0]) {
          this.setState({ error: { content: 'error' } });
        } else if (arrayAlert[k].date.length < 1) {
          this.setState({ error: { dates: 'error' } });
        }
        // else if (!arrayAlert[k].hours.HS) {
        // 	this.setState({ error: { hourStart: 'error' } })
        // }
        // else if (!arrayAlert[k].hours.MS) {
        // 	this.setState({ error: { minStart: 'error' } })
        // }
        // else if (!arrayAlert[k].hours.HE) {
        // 	this.setState({ error: { hourEnd: 'error' } })
        // }
        // else if (!arrayAlert[k].hours.ME) {
        // 	this.setState({ error: { minEnd: 'error' } })
        // }
        else if (
          arrayAlert[k].hours.HS === false &&
          arrayAlert[k].content.fr[0] &&
          arrayAlert[k].date
        ) {
          contentFR = arrayAlert[k].content.fr[0];
          periods.dates = arrayAlert[k].date;
          periods.blocks[0].hours[0].start = false;
          periods.blocks[0].hours[0].end = false;
          this.setState({
            ...this.state,
            loader: { bool: true, index: i },
            contentFR,
            periods: periods,
          });
          await this.merge();
          this.scheduleAlert(arrayAlert[k].id, name);
        } else {
          contentFR = arrayAlert[k].content.fr[0];
          periods.dates = arrayAlert[k].date;
          const HStartLess = arrayAlert[k].hours.HS - this.utc;
          const HEndLess = arrayAlert[k].hours.HE - this.utc;
          periods.blocks[0].hours[0].start = String(HStartLess).concat(
            ':',
            arrayAlert[k].hours.MS
          );
          periods.blocks[0].hours[0].end = String(HEndLess).concat(
            ':',
            arrayAlert[k].hours.ME
          );

          this.setState({
            ...this.state,
            loader: { bool: true, index: i },
            contentFR,
            periods: periods,
          });
          await this.merge();
          this.scheduleAlert(arrayAlert[k].id, name);
        }
      }
    }
  }

  deleteAlert(id) {
    const { client, bot_id } = this.props;

    client
      .mutate({
        mutation: DELETE_ALERT_MESSAGES,
        variables: {
          bot_id: bot_id,
          id: id,
        },
      })
      .then((res) => {
        if (res) {
          client
            .query({
              query: GET_BOT,
              variables: {
                id: bot_id,
              },
              fetchPolicy: 'network-only',
            })
            .then((res) => {
              this.setState({
                selectAlert: res.data.getBot.config.alertMessages,
              });
              if (res) {
                client
                  .query({
                    query: GET_ALERT_MESSAGES,
                    variables: {
                      bot_id: bot_id,
                    },
                    fetchPolicy: 'network-only',
                  })
                  .then((res) => {
                    if (res.data.getAlertMessages.length > 0) {
                      this.putDataArray(res.data.getAlertMessages);
                      this.setState({ allAlert: res.data.getAlertMessages });
                    } else {
                      this.setState({ allAlert: [] });
                    }
                  });
              }
            });
        }
      });
  }

  changeContent(e) {
    let value = e.target.value;
    this.setState({ contentFR: [value], error: { content: null } });
  }

  updateContent(value, i) {
    const { arrayAlert } = this.state;
    arrayAlert.forEach((item, index) => {
      if (i === index) {
        item.content.fr[0] = value;
      }
    });
    this.setState({ ...this.state, arrayAlert: arrayAlert });
  }

  async changeDate(name, dateIndex, date) {
    const { allDates } = this.state;
    for (var j = 0; allDates.length > j; j++) {
      if (j === dateIndex) {
        if (allDates[j].startDate && allDates[j].startDate) {
          name === 'date_start'
            ? (allDates[j].startDate = date)
            : (allDates[j].endDate = date);
        } else {
          allDates[j].startDate = date;
        }
      }
    }
    this.setState({
      ...this.state,
      allDates: allDates,
      periods: { ...this.state.periods, dates: allDates },
    });
    this.merge();
  }

  async updateDate(value, dateIndex, i, name) {
    const { arrayAlert } = this.state;
    const current = moment(new Date()).add(10, 'days').calendar();
    const res = current.split('/').join('-');
    arrayAlert.forEach((item, index) => {
      if (i === index) {
        item.date.forEach((e, j) => {
          if (dateIndex === j) {
            if (name === 'date_start') e.startDate = value !== '' ? value : res;
            if (name === 'date_end') e.endDate = value !== '' ? value : res;
          }
        });
      }
    });
    this.setState({ ...this.state, arrayAlert: arrayAlert });
  }

  changeHour(e, name) {
    let value = e.target.value;
    if (name === 'hourStart') {
      if ((parseInt(value) > 23)) {
        this.setState({ error: { hourStart: 'error' } });
      } else if (value.length <= 2) {
        this.setState({
          hour: { ...this.state.hour, hourStart: value },
          error: { hourStart: null },
        });
      }
    } else if (name === 'minStart') {
      if (parseInt(value) > 59) {
        this.setState({ error: { minStart: 'error' } });
      } else if (value.length <= 2) {
        this.setState({
          hour: { ...this.state.hour, minStart: value },
          error: { minStart: null },
        });
      }
    } else if (name === 'hourEnd') {
      if ((parseInt(value) > 23)) {
        this.setState({ error: { hourEnd: 'error' } });
      } else if (value.length <= 2) {
        this.setState({
          hour: { ...this.state.hour, hourEnd: value },
          error: { hourEnd: null },
        });
      }
    } else if (name === 'minEnd') {
      if (parseInt(value) > 59) {
        this.setState({ error: { minEnd: 'error' } });
      } else if (value.length <= 2) {
        this.setState({
          hour: { ...this.state.hour, minEnd: value },
          error: { minEnd: null },
        });
      }
    }
  }

  updateHour(value, i, name) {
    const { arrayAlert } = this.state;
    if (name === 'hourStart') {
      if ((value[0] > 1 && value[1] > 3) || value.length > 2) {
        this.setState({ error: { hourStart: 'error' } });
      } else if (value.length <= 2) {
        arrayAlert.forEach((item, index) => {
          if (i === index) {
            item.hours.HS = value;
          }
        });
        this.setState({
          ...this.state,
          arrayAlert: arrayAlert,
          error: { hourStart: null },
        });
      }
    } else if (name === 'minStart') {
      if (value[1] > 9 || value.length > 2) {
        this.setState({ error: { minStart: 'error' } });
      } else if (value.length <= 2) {
        arrayAlert.forEach((item, index) => {
          if (i === index) {
            item.hours.MS = value;
          }
        });
        this.setState({
          ...this.state,
          arrayAlert: arrayAlert,
          error: { minStart: null },
        });
      }
    } else if (name === 'hourEnd') {
      if ((value[0] > 1 && value[1] > 3) || value.length > 2) {
        this.setState({ error: { hourEnd: 'error' } });
      } else if (value.length <= 2) {
        arrayAlert.forEach((item, index) => {
          if (i === index) {
            item.hours.HE = value;
          }
        });
        this.setState({
          ...this.state,
          arrayAlert: arrayAlert,
          error: { hourEnd: null },
        });
      }
    } else if (name === 'minEnd') {
      if (value[1] > 9 || value.length > 2) {
        this.setState({ error: { minEnd: 'error' } });
      } else if (value.length <= 2) {
        arrayAlert.forEach((item, index) => {
          if (i === index) {
            item.hours.ME = value;
          }
        });
        this.setState({
          ...this.state,
          arrayAlert: arrayAlert,
          error: { minEnd: null },
        });
      }
    }
  }

  merge() {
    const { periods } = this.state;
    const res = dateFormat();
    if (periods.dates.length === 0) {
      periods.dates = [{ startDate: res }];
    }
    const name = '';
    const comment = null;
    let oph = _mergeOph2(periods, comment, name === 'off' ? true : false);
    this.setState({ oph: oph });
  }

  render() {
    const {
      show,
      close,
      m: { w },
    } = this.props;
    const {
      allAlert,
      selectAlert,
      fct,
      toggle,
      contentFR,
      hour,
      loader,
      displayHour,
      arrayAlert,
    } = this.state;

    return (
      <Modal isOpen={show} close={close} selector="#root">
        <StyledModal>
          <div className="flex row space-between m-xs">
            <div className="flex row space-between w-100">
              {!toggle && (
                <Button onClick={fct.toggleAlert}>
                  {w.alertMessages.create}
                </Button>
              )}
              {toggle && (
                <React.Fragment>
                  <div>
                    <Button color="primary-bis" onClick={fct.toggleAlert}>
                      {w.alertMessages.alert}
                    </Button>
                  </div>
                  <div>
                    <Button onClick={this.createAlert.bind(this)}>
                      {w.alertMessages.schedule}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          {!toggle && (
            <StyledAlert>
              <AllAlert
                array={arrayAlert}
                all={allAlert}
                select={selectAlert}
                fct={fct}
                periods={this.state.periods}
                error={this.state.error}
                bool={this.state.bool}
                state={this.state}
                loader={loader}
              />
            </StyledAlert>
          )}
          {toggle && (
            <div className="m-xs overflow-auto">
              <CreateAlert
                fct={fct}
                periods={this.state.periods}
                error={this.state.error}
                bool={this.state.bool}
                allDates={this.state.allDates}
                contentFR={contentFR}
                hour={hour}
                display={displayHour}
              />
            </div>
          )}
        </StyledModal>
      </Modal>
    );
  }
}

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  height: 530px;
  background: #FFF;
  padding: 1rem;
  border-radius: 25px;
  width: 850px;
`;

const StyledAlert = styled.div`
  height: 530px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0.25rem;
`;

export default withMain(withApollo(ShowModalAlert));
