import React, { Component } from 'react';
import Modal from '../../../components/Modal';
import { Button } from '@ask-mona/ui';
import { withMain } from '../../../hoc/main';

class ModalProg extends Component {
  render() {
    const {
      m: { w },
      show,
      close,
      wording,
      fct,
      finalDate,
      loaderProg,
    } = this.props;
    return (
      <Modal isOpen={show} close={close} selector="#root">
        <div className="flex column w-40p h-2 has-background-white box-shadow">
          {finalDate && (
            <div className="flex column items-center m-md is-size-7 has-text-primary-ter">
              <p>{wording.push.progDate}</p>
              <p>{finalDate}</p>
            </div>
          )}
          {!finalDate && (
            <div className="flex column items-center m-md is-size-7 has-text-primary-ter">
              <p>{wording.push.progNoDate}</p>
            </div>
          )}
          <div className="flex row space-between m-md">
            <Button
              className={loaderProg ? 'disabled' : ''}
              style={loaderProg ? { opacity: '.5' } : { opacity: '1' }}
              color="danger"
              onClick={fct.toggleModalProg}
            >
              {' '}
              {w.generic.cancel}{' '}
            </Button>
            {loaderProg ? (
              <Button onClick={fct.scheduleContentPush} loading>
                {' '}
                {w.generic.validate}{' '}
              </Button>
            ) : (
              <Button onClick={fct.scheduleContentPush}>
                {' '}
                {w.generic.validate}{' '}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default withMain(ModalProg);
