class Assets {
  constructor() {
    this.hasCanceled = false;
  }

  _getSignedUrl = async (template) => {
    return await this.getSignedUrl(template);
  };

  _getFonts = async () => {
    return await this.getFonts();
  };

  _uploadFont = async (signedUrl, contentType, file) => {
    return await this.getFont(signedUrl, contentType, file);
  };

  getSignedUrl = async (template) => {
    let url = `${process.env.REACT_APP_HTTP_LINK}/rest/assets/fonts/upload`;
    let req = {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(template),
    };

    try {
      const res = await fetch(url, req).then((res) => res.json());
      if (res && res.error) throw new Error(res.error);
      if (this.hasCanceled) throw new Error('fetch canceled');
      return { success: true, json: res };
    } catch (err) {
      console.log(err);
      return { success: false, error: err, canceled: this.hasCanceled };
    }
  };

  uploadFont = async (signedUrl, contentType, file) => {
    let url = signedUrl;
    let req = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: contentType,
        'Content-Type': contentType,
      },
      body: file,
    };

    try {
      const res = await fetch(url, req).then((res) => res);
      if (res && res.error) throw new Error(res.error);
      if (this.hasCanceled) throw new Error('fetch canceled');
      return { success: true, json: res.url };
    } catch (err) {
      console.log(err);
      return { success: false, error: err, canceled: this.hasCanceled };
    }
  };

  getFonts = async () => {
    let url = `${process.env.REACT_APP_HTTP_LINK}/rest/assets/fonts`;
    let req = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await fetch(url, req).then((res) => res.json());
      if (res && res.error) throw new Error(res.error);
      if (this.hasCanceled) throw new Error('fetch canceled');
      return { success: true, json: res };
    } catch (err) {
      console.log(err);
      return { success: false, error: err, canceled: this.hasCanceled };
    }
  };
}

export default Assets;
