import React, { Component } from 'react';
import ListHeader from './pushMain/ListHeader';
import ListPushs from './pushMain/ListPushs';
import { withMain } from '../../hoc/main';
import Portal from '../../components/Portal';
import { Header } from './pushMain/Ui';

class PushMain extends Component {
  render() {
    const {
      check,
      fct,
      data: { lang },
    } = this.props.context;
    const { allPush } = this.props;
    const total = allPush ? allPush.length : 0;
    return (
      <div className="has-text-primary w-100p relative">
        <Portal
          component={
            <Header
              {...this.props}
              {...this.state}
              counter={total}
              check={check}
              fct={fct}
              addPush={fct.onAddPush}
            />
          }
          selector="base-header"
        />
        <ListHeader
          allPush={allPush}
          check={check}
          lang={lang}
          fct={fct}
          funct={fct}
        />
        <div className="overflow-scroll">
          <ListPushs allPush={allPush} funct={fct} check={check} />
        </div>
      </div>
    );
  }
}

export default withMain(PushMain);
