import React, { Component } from 'react';
import { withGlobals } from '../../hoc/globals';
import Scheduler from './Scheduler';
import { _sortOph, _reOrder } from '../../_tools/oph';
import Special from './special';
import { _sendFeedback } from '../../_tools/ui';

// Access to Open, Close, and Special tabs thanks to the onChange inherited function
const Header = ({ g: { w, user }, onChange, section }) => (
  <div className="flex row">
    <div
      className={`flex grow-1 pointer center p-md has-background-${
        section === 'open'
          ? 'primary-ter has-text-white rad-t-l-5px'
          : 'primary-lighter border-b-primary-ter'
      }`}
      onClick={() => onChange(true, 'open')}
    >
      {w.schedule.openSection}
    </div>
    <div
      className={`flex grow-1 pointer center p-md has-background-${
        section === 'close'
          ? user.user.permissions === 1
            ? 'primary-ter has-text-white'
            : 'primary-ter has-text-white rad-t-r-5px'
          : 'primary-lighter border-b-primary-ter'
      } ${section === 'special' && 'border-l-primary'}`}
      onClick={() => onChange(false, 'close')}
    >
      {w.schedule.closeSection}
    </div>

    {/* Special tab for adding special wording to special days, only accessible ny AskMona members */}
    {user.user.permissions === 1 && (
      <div
        className={`flex grow-1 pointer center p-md has-background-${
          section === 'special'
            ? 'secondary has-text-white rad-t-r-5px'
            : 'danger-light border-b-primary-ter'
        }`}
        onClick={() => onChange(false, 'special')}
      >
        {w.schedule.specialSection}
      </div>
    )}
  </div>
);

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: true,
      section: 'open',
      fct: {
        addOph: this.addOph,
        popOph: this.popOph,
        onChange: this.onChange,
        onUpdate: this.onUpdate,
      },
    };
  }

  addOph = (nPart, event = false) => {
    const {
      oph,
      set,
      g: { w },
    } = this.props;
    const { off, open } = _sortOph(oph || '');

    /* Only for non events schedules, allows to detect if an OPH already exists in open or closed sections before adding a new OPH*/

    if (!event) {
      if (
        open
          .map((date) => date.oph.trim().split(' ')[0].toLowerCase())
          .includes(nPart.oph.trim().split(' ')[0].toLowerCase()) ||
        open
          .map((date) => date.oph.trim().split(' ')[0].slice(4).toLowerCase())
          .includes(nPart.oph.trim().split(' ')[0].toLowerCase())
      ) {
        _sendFeedback('danger', w.error.dateInOpen);
      } else if (
        off
          .map((date) => date.oph.trim().split(' ')[0].toLowerCase())
          .includes(nPart.oph.trim().split(' ')[0].toLowerCase()) ||
        off
          .map((date) => date.oph.trim().split(' ')[0].slice(4).toLowerCase())
          .includes(nPart.oph.trim().split(' ')[0].toLowerCase())
      ) {
        _sendFeedback('danger', w.error.dateInClose);
      } else {
        let array = open.concat(nPart).concat(off).sort(_reOrder);
        array = array.filter((o) => o).map((o) => o.oph);
        const newOph = array.filter((o) => o).join(';');
        set && set(newOph);
      }
    } else {
      let array = open.concat(nPart).concat(off).sort(_reOrder);
      array = array.filter((o) => o).map((o) => o.oph);
      const newOph = array.filter((o) => o).join(';');
      set && set(newOph);
    }
  };

  popOph = (part) => {
    const { oph, set } = this.props;
    const newOph = oph
      .split(';')
      .filter((o, i) => o && i !== part.index)
      .join(';');
    set && set(newOph);
  };

  onChange = (newPart) => {
    const { oph, set } = this.props;
    const { off, open } = _sortOph(oph || '');
    const array = open.concat(off).map((o) => {
      return o.index === newPart.index ? newPart.oph : o.oph;
    });
    const newOph = array.filter((o) => o).join(';');
    set && set(newOph);
  };

  render() {
    const { off, open } = _sortOph(this.props.oph || '');
    const { special } = _sortOph(this.props.oph, 'typed', true);
    const { isActive, section } = this.state;
    return (
      <div className="flex column grow-1">
        <Header
          {...this.props}
          {...this.state}
          event={this.props.event ? this.props.event : false}
          isActive={isActive}
          section={section}
          onChange={(bool, section) =>
            this.setState({ isActive: bool, section: section })
          }
        />
        <div className="border-b-primary-ter border-r-primary-ter border-l-primary-ter rad-b-l-5px rad-b-r-5px">
          {/* Open and Close tabs content  */}
          <Scheduler
            {...this.props}
            {...this.state}
            event={this.props.event ? this.props.event : undefined}
            name="open"
            parts={open}
            section={section}
            typed="true"
          />
          <Scheduler
            {...this.props}
            {...this.state}
            event={this.props.event ? this.props.event : undefined}
            isActive={!isActive}
            name="off"
            section={section}
            parts={off}
          />

          {/* Special tab content */}
          {section === 'special' && (
            <Special
              name="special"
              event={this.props.event ? this.props.event : undefined}
              {...this.props}
              {...this.state}
              partsopen={open}
              partsclose={off}
              partsspecial={special}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withGlobals(Schedule);
