import React from 'react';

export default ({ title, subtitle, url }) => {
  if (url)
    return (
      <div>
        <div className="p-xs">
          <a
            className="has-text-grey-light"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {title && <div className="title">{title}</div>}
            {subtitle && <div className="subtitle">{subtitle}</div>}
          </a>
        </div>
      </div>
    );
  else
    return (
      <div className="p-xs">
        {title && <div className="title">{title}</div>}
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </div>
    );
};
