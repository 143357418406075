import React, { useContext } from 'react';
import styled from 'styled-components';
import { Icon } from '@ask-mona/ui';

import QaContext from '../context/qaContext';

export const Susbar = (props) => {
  const qaContext = useContext(QaContext);

  return (
    <Container>
      {!props.search && (
        <>
          {' '}
          <Section
            onClick={() => qaContext.setSusbarFocus('activated')}
            focus={qaContext.susbarFocus === 'activated'}
          >
            <SectionText>{`QR activées (${qaContext.activatedNumber})`}</SectionText>
            {qaContext.susbarFocus === 'activated' && (
              <SectionIcon>
                <Icon
                  icon="fas fa-chevron-right"
                  className="has-text-secondary"
                />
              </SectionIcon>
            )}
          </Section>
          <Section
            onClick={() => qaContext.setSusbarFocus('desactivated')}
            focus={qaContext.susbarFocus === 'desactivated'}
          >
            <SectionText>{`QR désactivées (${qaContext.desactivatedNumber})`}</SectionText>
            {qaContext.susbarFocus === 'desactivated' && (
              <SectionIcon>
                <Icon
                  icon="fas fa-chevron-right"
                  className="has-text-secondary"
                />
              </SectionIcon>
            )}
          </Section>
          <Section
            onClick={() => qaContext.setSusbarFocus('all')}
            focus={qaContext.susbarFocus === 'all'}
          >
            <SectionText>{`Toutes les QR (${
              qaContext.activatedNumber + qaContext.desactivatedNumber
            })`}</SectionText>
            {qaContext.susbarFocus === 'all' && (
              <SectionIcon>
                <Icon
                  icon="fas fa-chevron-right"
                  className="has-text-secondary"
                />
              </SectionIcon>
            )}
          </Section>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 17%;
  min-width: 17%;
  padding: 30px 5px 0px 30px;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  border-right: 1px solid #ececec;

  @media (max-width: 1400px) {
    min-width: 170px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  overflow: wrap;
  text-align: center;
  width: 100%;
  /* color: ${(p) => p.theme.colors.primary}; */
  color: ${(p) =>
    p.focus ? p.theme.colors.secondary : p.theme.colors.primary};

  margin-bottom: 30px;
  cursor: pointer;
`;

const SectionText = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-decoration: ${(p) =>
    p.focus ? `underline ${p.theme.colors.secondary}` : 'none'};
`;

const SectionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
