import React, { useState, useReducer, useEffect, useContext } from 'react';
import styled from 'styled-components';
import {
  Button as Btn,
  Field,
  Image,
  Input,
  Textarea,
  Switch,
  Select,
  Icon,
} from '@ask-mona/ui';
import { _uploadImg } from '../../../_api/upload';
import { _getKeyValue, _arrayMove, _slugify } from '../../../_tools/other';
import { _sendFeedback } from '../../../_tools/ui';
import {
  _findDup,
  btnTemplate,
  moduleTemplate,
  genericTemplate,
  genTemplate,
  _generatePath,
} from '../Reducer';

import ScenariosContext from '../../../containers/scenarios/context/scenariosContext';

export default (props) => {
  const {
    hasMoved,
    element,
    index,
    disabled,
    cIndex,
    fix,
    advanced,
    random,
    newIndex,
    arrayOfElements,
  } = props;
  const [scrollBar] = useState(React.createRef());
  const [hasMove, setHasMove] = useState(true);
  const els = _getKeyValue(element, 'elements') || [];
  let qr = element.quick_replies || null;

  const scenariosContext = useContext(ScenariosContext);

  useEffect(() => {
    setHasMove(!hasMove);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMoved]);

  return (
    <Container
      random={random}
      multiple={els.length > 1}
      className="relative hideScroll"
    >
      {els.length > 1 && !random && (
        <React.Fragment>
          <Btn
            color="secondary"
            className="absolute left shrink"
            onClick={() => scrollBar.current.scrollBy(els.length * 50 * -1, 0)}
            style={{ zIndex: 1, top: '50px', left: '-40px' }}
          >
            <Icon icon="fas fa-chevron-left" />
          </Btn>
          <Btn
            color="secondary"
            className="absolute right shrink"
            onClick={() => scrollBar.current.scrollBy(els.length * 50, 0)}
            style={{ zIndex: 1, top: '50px', right: '-40px' }}
          >
            <Icon icon="fas fa-chevron-right" />
          </Btn>
        </React.Fragment>
      )}
      <div
        className="w-100p p-xs space-nowrap overflow-auto hideScroll text-align-right"
        ref={scrollBar}
      >
        {els.map((el, gIndex) => (
          <Generic
            key={gIndex}
            {...props}
            els={els}
            gIndex={gIndex}
            el={el}
            index={index}
            hasMove={hasMove}
            onMove={(type) => {
              let newEls = [...els];
              _arrayMove(
                newEls,
                gIndex,
                type === 'up' ? gIndex - 1 : gIndex + 1
              );
              let obj = genericTemplate(newEls);
              let newArray = [];
              if (random) {
                newArray = [...arrayOfElements];
                newArray[newIndex] = [obj];
              }
              props.dispatch &&
                props.dispatch({
                  type: 'onElemChange',
                  payload: { index, value: random ? newArray : obj },
                  func: advanced
                    ? null
                    : scenariosContext.functionalitiesSubsbarFocus === 'content'
                    ? null
                    : scenariosContext.functionalitiesSubsbarFocus,
                });
              props.onChange && props.onChange(obj);
              setHasMove(!hasMove);
            }}
            onDelete={() => {
              let obj = genericTemplate(
                els.filter((el, i) => i !== gIndex),
                qr ? { quick_replies: qr } : {}
              );
              let newArray = [];
              if (random) {
                newArray = [...arrayOfElements];
                newArray[newIndex] = [obj];
              }
              props.dispatch &&
                props.dispatch({
                  type: 'onElemChange',
                  payload: { index, value: random ? newArray : obj },
                  func: advanced
                    ? null
                    : scenariosContext.functionalitiesSubsbarFocus === 'content'
                    ? null
                    : scenariosContext.functionalitiesSubsbarFocus,
                });
              props.onChange && props.onChange(obj, null, null, 'obj', cIndex);
              setHasMove(!hasMove);
            }}
          />
        ))}
        {!disabled && !fix && els.length < 14 && (
          <div className="border-grey-lighter inline-block rad-15px overflow-auto m-xs space-initial">
            <Btn
              className="align-top"
              onClick={() => {
                let obj = genericTemplate(
                  els.concat(genTemplate({}, {}, [btnTemplate()])),
                  qr ? { quick_replies: qr } : {}
                );
                let newArray = [];
                if (random) {
                  newArray = [...arrayOfElements];
                  newArray[newIndex] = [obj];
                }
                props.dispatch &&
                  props.dispatch({
                    type: 'onElemChange',
                    payload: { index, value: random ? newArray : obj },
                    func: advanced
                      ? null
                      : scenariosContext.functionalitiesSubsbarFocus ===
                        'content'
                      ? null
                      : scenariosContext.functionalitiesSubsbarFocus,
                  });
                props.onChange &&
                  props.onChange(obj, null, null, 'obj', cIndex);
              }}
            >
              <Icon icon="fas fa-plus" />
            </Btn>
          </div>
        )}
      </div>
      {random && (
        <RemoveElementButton
          onClick={() => {
            let newArray = [...arrayOfElements];
            newArray = newArray.filter((el) => el[0] !== element);
            props.dispatch &&
              props.dispatch({
                type: 'onElemChange',
                payload: {
                  index,
                  value: newArray,
                  func: null,
                },
              });
          }}
        >
          <Icon icon="fas fa-times" />
        </RemoveElementButton>
      )}
    </Container>
  );
};

const _reducer = (state, action) => {
  switch (action.type) {
    case 'onChange':
    case 'onChangeBtn':
    case 'onPopBtn':
      const { name, value, index } = action.payload;
      if (action.type === 'onChange') return { ...state, [name]: value };
      else if (action.type === 'onChangeBtn') {
        let newBtn = { ...value };
        if (newBtn.type === 'web_url') delete newBtn['payload'];
        else delete newBtn['url'];
        return {
          ...state,
          buttons: state.buttons.map((b, i) => (i === index ? newBtn : b)),
        };
      } else if (action.type === 'onPopBtn')
        return {
          ...state,
          buttons: state.buttons.filter((b, i) => i !== index),
        };
      return { ...state };
    case 'onAddBtn':
      return { ...state, buttons: state.buttons.concat(btnTemplate()) };
    case 'setEl':
      return { ...action.payload };
    default:
      return state;
  }
};

const Generic = (props) => {
  const {
    m: { bot, w },
    lang,
    focus,
    onMove,
    hasMove,
    hasChange,
    subfocus,
    element,
    el,
    els,
    disabled,
    fix,
    gIndex,
    onDelete,
    fill,
    cIndex,
    advanced,
    random,
    arrayOfElements,
    newIndex,
  } = props;
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mouseHover, setMouseHover] = useState(false);
  const [state, dispatch] = useReducer(_reducer, el);
  const { buttons, default_action, ...opt } = state;
  const { image_url, title, subtitle, alt } = opt;
  const url = _getKeyValue(default_action, 'url') || '';

  const scenariosContext = useContext(ScenariosContext);

  // Refresh component on
  useEffect(() => {
    dispatch({ type: 'setEl', payload: el });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus.key, subfocus ? subfocus.key : '-', lang, hasMove, hasChange]);

  //Save element
  useEffect(() => {
    if (!fix) {
      const { els, index, gIndex } = props;
      const { buttons, default_action, ...opt } = state;
      let qr = element.quick_replies || null;
      let obj = genericTemplate(
        els.map((el, i) =>
          i === gIndex ? genTemplate(opt, default_action, buttons, alt) : el
        ),
        qr ? { quick_replies: qr } : {}
      );
      let newArray = [];
      if (random) {
        newArray = [...arrayOfElements];
        newArray[newIndex] = [obj];
      }
      props.dispatch &&
        props.dispatch({
          type: 'onElemChange',
          payload: { index, value: random ? newArray : obj },
          func: advanced
            ? null
            : scenariosContext.functionalitiesSubsbarFocus === 'content'
            ? null
            : scenariosContext.functionalitiesSubsbarFocus,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div
      className={`has-background-white border-grey-lighter inline-block rad-15px overflow-auto m-xs space-initial w-100p text-align-left`}
      style={{ width: fill ? '' : '225px' }}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
    >
      {!disabled && !fix && (mouseHover || hover) && (
        <div className="flex row space-between overflow-hidden animation-slideDown">
          <Btn
            color={els.length === 1 ? 'grey' : 'danger'}
            className="is-outlined is-borderless is-size-7"
            onClick={onDelete}
            disabled={els.length === 1}
          >
            <Icon
              icon="fas fa-trash"
              className={els.length === 1 ? 'has-text-white' : ''}
            />
          </Btn>
          <Btn
            onClick={() => onMove('up')}
            className="is-borderless is-outlined shrink"
          >
            <Icon icon="fas fa-caret-left" />
          </Btn>
          <Btn
            onClick={() => onMove('down')}
            className="is-borderless is-outlined shrink"
          >
            <Icon icon="fas fa-caret-right" />
          </Btn>
          <Btn
            color="success is-size-7 is-outlined is-borderless"
            onClick={() => {
              if (hover) setHover(false);
              else setHover(true);
            }}
          >
            {hover ? <Icon icon="fas fa-check" /> : <Icon icon="fas fa-pen" />}
          </Btn>
        </div>
      )}
      <Field>
        <Image
          src={
            image_url ||
            (typeof bot.default_image === 'string' ? bot.default_image : '')
          }
          alt={alt}
          placeholder={w.generic.imgPlaceholder}
          name="image_url"
          className="contain"
          onChange={(e) => dispatch({ type: 'onChange', payload: e.target })}
          loading={loading ? 'true' : ''}
          fixed={disabled || fix ? 'true' : ''}
          onUpload={async (e) => {
            setLoading(true);
            _sendFeedback('warning', 'loader');
            await _uploadImg(
              bot.id,
              e,
              (res) => {
                setLoading(false);
                _sendFeedback('success', w.success.saved);
                props.dispatch &&
                  dispatch({
                    type: 'onChange',
                    payload: { name: 'image_url', value: res.path },
                  });
                props.onChange &&
                  props.onChange(res.path, gIndex, null, 'img', cIndex);
              },
              (err) => {
                setLoading(false);
                _sendFeedback('danger', w.error.general);
              }
            );
          }}
        />
      </Field>
      {hover && (
        <div className="has-background-primary-lighter p-xs">
          <Field
            subtitle={w.scenario.redirectionURL}
            className="animation-slideDown overflow-hidden"
          >
            <Input
              autoComplete="off"
              name="default_action"
              className="is-size-7"
              value={url || ''}
              onChange={(e) => {
                const { name, value } = e.target;
                props.dispatch &&
                  dispatch({
                    type: 'onChange',
                    payload: { name, value: { ...default_action, url: value } },
                  });
                props.onChange &&
                  props.onChange(value, gIndex, null, 'url', cIndex);
              }}
            />
          </Field>
        </div>
      )}
      <div className="p-xs has-text-primary-bis">
        {(disabled || fix) && (
          <span className="has-text-weight-semibold has-text-primary-ter is-size-7 space-pre-line word-break-break-word ">
            {title}
          </span>
        )}
        <Input
          autoComplete="off"
          name="alt"
          className
          ="is-transparent text-align-center"
          placeholder={w.scenario.imageDescription}
          style={{ width: '92%' }}
          value={alt}
          onChange={(e) => {
            dispatch({ type: 'onChange', payload: e.target });
          }}
        />
        {!disabled && !fix && (
          <Input
            autoComplete="off"
            name="title"
            placeholder={w.scenario.title}
            className="has-text-weight-semibold has-text-grey is-size-7 is-transparent"
            value={title || ''}
            maxLength={hover ? 80 : ''}
            onChange={(e) => {
              props.dispatch &&
                dispatch({ type: 'onChange', payload: e.target });
              props.onChange &&
                props.onChange(e.target.value, gIndex, null, 'title', cIndex);
            }}
          />
        )}
        {(subtitle || hover) && (
          <Textarea
            name="subtitle"
            className="animation-slideDown has-text-grey is-size-7 is-transparent"
            style={{ color: '#2d2f7b' }}
            placeholder={w.scenario.subtitle}
            value={subtitle || ''}
            resize="auto"
            maxLength={hover ? 80 : ''}
            onChange={(e) => {
              props.dispatch &&
                dispatch({ type: 'onChange', payload: e.target });
              props.onChange &&
                props.onChange(
                  e.target.value,
                  gIndex,
                  null,
                  'subtitle',
                  cIndex
                );
            }}
          />
        )}
      </div>
      <div className="border-t-grey-lighter">
        {buttons.map((b, bIndex) => (
          <Button
            key={bIndex}
            {...props}
            bIndex={bIndex}
            el={b}
            hover={hover}
            dispatch={dispatch}
            pDispatch={props.dispatch}
            gIndex={gIndex}
          />
        ))}
        {hover && buttons.length < 3 && !fix && (
          <Btn
            color="success expand is-size-7"
            onClick={() => {
              props.dispatch && dispatch({ type: 'onAddBtn' });
              props.onChange &&
                props.onChange(
                  { type: 'web_url', url: '', title: '' },
                  gIndex,
                  null,
                  'btn',
                  cIndex
                );
            }}
          >
            {w.generic.addBtn}
          </Btn>
        )}
      </div>
    </div>
  );
};

export const Button = (props) => {
  const [edit, setEdit] = useState(false);
  const {
    m: { w, bot },
    el,
    bIndex,
    hover,
    dispatch,
    payloads,
    advanced,
    subfocus,
    focus,
    subModules,
    allIds,
    Bot,
    pDispatch,
    gIndex,
    cIndex,
    fix,
    disabled,
    qa,
  } = props;
  const { type, url, payload, title } = el;

  const [increments, setIncrements] = useState(() => {
    let increments = (payload || '').match(/\({.*(\[.*\]).*}\)/);
    if (increments) return JSON.parse(increments[1]);
    else return [];
  });
  const [webchatPayloads, setWebchatPayloads] = useState([]);

  useEffect(() => {
    if (Bot) {
      const fetchModules = async () => {
        let res = await Bot._getModules();
        if (res.success && res.json) {
          if ('trigger' in res.json) {
            let triggers = res.json['trigger'];
            let payloads = Object.keys(triggers)
              .filter((k) => !triggers[k].tag)
              .map((k) => ({
                id: k.replace('p:', ''),
                value: k.replace('p:', ''),
                key: k.replace('p:', ''),
              }));
            setWebchatPayloads(payloads);
          }
        }
      };
      fetchModules();
    }
  }, []);

  useEffect(() => {
    let increments = (payload || '').match(/\({.*(\[.*\]).*}\)/);
    if (increments) setIncrements(JSON.parse(increments[1]));
    else setIncrements([]);
  }, [payload]);

  return (
    <div
      className={`has-text-info border-b-grey-lighter pointer ${
        !payload && !url && !fix && !disabled
          ? 'has-background-danger-light'
          : ''
      }`}
    >
      <div className="flex row has-text-info">
        {hover && (
          <Btn
            color={bIndex === 0 ? 'grey' : 'danger'}
            className="is-outlined is-borderless shrink is-size-7"
            onClick={() => {
              props.dispatch &&
                dispatch({ type: 'onPopBtn', payload: { index: bIndex } });
              props.onChange &&
                props.onChange(null, gIndex, bIndex, 'btnDelete', cIndex);
            }}
            disabled={bIndex === 0}
          >
            <Icon
              icon="fas fa-trash"
              className={bIndex === 0 ? 'has-text-white' : ''}
            />
          </Btn>
        )}
        <Input
          disabled={fix ? true : false}
          autoComplete="off"
          name="title"
          className="is-transparent text-align-center"
          placeholder={w.scenario.btnTitle}
          style={{ width: '92%' }}
          value={title}
          onChange={(e) => {
            const { name, value } = e.target;
            props.dispatch &&
              dispatch({
                type: 'onChangeBtn',
                payload: { index: bIndex, value: { ...el, [name]: value } },
              });
            props.onChange &&
              props.onChange(value, gIndex, bIndex, 'btnTitle', cIndex);
          }}
        />
        {hover && (
          <Btn
            color="success is-outlined is-borderless shrink is-size-7"
            onClick={() => setEdit(!edit)}
          >
            {edit ? <Icon icon="fas fa-check" /> : <Icon icon="fas fa-pen" />}
          </Btn>
        )}
      </div>
      {hover && edit && (
        <div className="has-background-primary-lighter p-xs">
          <Field
            subtitle={w.scenario.contentType}
            className="animation-slideDown"
          >
            <Select
              defaultValue={
                type === 'postback'
                  ? w.scenario.linkToBlock
                  : w.scenario.linkToWebURL
              }
              className="w-100p"
              items={[
                { id: 0, value: w.scenario.linkToWebURL, key: 'web_url' },
                { id: 1, value: w.scenario.linkToBlock, key: 'postback' },
              ]}
              onSelect={(item) => {
                props.dispatch &&
                  dispatch({
                    type: 'onChangeBtn',
                    payload: {
                      index: bIndex,
                      value: { ...el, type: item.key },
                    },
                  });
                props.onChange &&
                  props.onChange(item.key, gIndex, bIndex, 'btnType', cIndex);
              }}
            />
          </Field>
          {type === 'postback' ? (
            <>
              <Field
                subtitle={w.scenario.linkToModule}
                className="animation-slideDown"
              >
                <Select
                  className="w-100p"
                  defaultValue={
                    bot.platforms.webchat && focus.jump === 'FIRST'
                      ? webchatPayloads
                          .concat(payloads)
                          .find((i) => el.payload === i.id)?.value
                      : (
                          payloads.find(
                            (p) =>
                              (payload || '').replace(/\(.*\)/, '') ===
                              (qa ? p.value : p.key)
                          ) || {}
                        ).value
                  }
                  items={
                    bot.platforms.webchat && focus.jump === 'FIRST'
                      ? webchatPayloads.concat(payloads)
                      : payloads
                  }
                  onSelect={(item) => {
                    let adds = increments.length
                      ? `({"increment": [${increments.map(
                          (el) => `"${el}"`
                        )}]})`
                      : '';
                    props.dispatch &&
                      dispatch({
                        type: 'onChangeBtn',
                        payload: {
                          index: bIndex,
                          value: {
                            ...el,
                            payload: `${qa ? item.value : item.key}${adds}`,
                          },
                        },
                      });
                    props.onChange &&
                      props.onChange(
                        item.key,
                        gIndex,
                        bIndex,
                        'btnPay',
                        cIndex
                      );
                  }}
                  create={advanced ? w.generic.create : ''}
                  onCreate={async (item) => {
                    const name = item.value;
                    if (!name) return;
                    let id = _slugify(name) || 'emojisolo';
                    if ('trigger' in focus) {
                      let folder =
                        subfocus && typeof subfocus === 'string'
                          ? subfocus.tag
                          : '';
                      id = _findDup(id, allIds);
                      let module = moduleTemplate({
                        id,
                        name,
                        tag: folder,
                        jump: focus.id,
                        disable: false,
                      });
                      const { key, ...other } = module;
                      let newFocus = {
                        ...focus,
                        trigger: { ...focus.trigger, [key]: other },
                      };
                      let res = await Bot._createModule({
                        path: _generatePath(newFocus).concat('trigger', key),
                        module: { [key]: other },
                      });
                      if (res.success) {
                        dispatch({
                          type: 'onChangeBtn',
                          payload: {
                            index: bIndex,
                            value: { ...el, payload: id },
                          },
                        });
                        pDispatch({ type: 'setFocus', payload: newFocus });
                        pDispatch({
                          type: 'setIds',
                          payload: allIds.concat(id),
                        });
                        _sendFeedback('success', w.success.saved);
                      } else _sendFeedback('danger', w.error.general);
                    } else {
                      let folder = subfocus
                        ? subfocus.tag.replace(/^.*\//, '')
                        : '';
                      id = _findDup(id, allIds);
                      let module = moduleTemplate({
                        id,
                        name,
                        tag: `${focus.id}/${folder || w.scenario.noFolder}`,
                        jump: 'FIRST',
                        disable: false,
                      });
                      _sendFeedback('warning', 'loader');
                      const { key, ...other } = module;
                      let res = await Bot._createModule({
                        path: _generatePath(),
                        module: { [key]: { ...other } },
                      });
                      if (res.success) {
                        dispatch({
                          type: 'onChangeBtn',
                          payload: {
                            index: bIndex,
                            value: { ...el, payload: id },
                          },
                        });
                        pDispatch({
                          type: 'setSubModules',
                          payload: subModules.concat(module),
                        });
                        pDispatch({
                          type: 'setIds',
                          payload: allIds.concat(id),
                        });
                        _sendFeedback('success', w.success.saved);
                      } else _sendFeedback('danger', w.error.general);
                    }
                  }}
                />
              </Field>
              {focus.dali &&
                payload &&
                focus.content &&
                focus.content.func.default.daliType === 'match' && (
                  <Field subtitle={w.scenario.increment}>
                    <Select
                      items={Object.keys(focus.content.func['match'] || []).map(
                        (el, i) => ({ id: i, value: el, key: el })
                      )}
                      onSelect={(item) => {
                        let newIncrements = increments
                          .filter((str) => el !== str)
                          .concat(item.value);
                        setIncrements(newIncrements);
                        let adds = newIncrements.length
                          ? `({"increment": [${newIncrements
                              .filter((str) => el !== str)
                              .map((el) => `"${el}"`)
                              .join(',')}]})`
                          : '';
                        dispatch({
                          type: 'onChangeBtn',
                          payload: {
                            index: bIndex,
                            value: {
                              ...el,
                              payload: `${payload.replace(
                                /\(.*\)/,
                                ''
                              )}${adds}`,
                            },
                          },
                        });
                      }}
                    />
                  </Field>
                )}
              {focus.dali &&
                payload &&
                focus.content &&
                focus.content.func.default.daliType === 'quiz' && (
                  <Field subtitle={w.scenario.answer} className="m-b-xs">
                    <Switch
                      checked={increments.length}
                      onChange={() => {
                        if (payload.match(/quiz/) && payload.match(/increment/))
                          dispatch({
                            type: 'onChangeBtn',
                            payload: {
                              index: bIndex,
                              value: {
                                ...el,
                                payload: `${payload.replace(/\(.*\)/, '')}`,
                              },
                            },
                          });
                        else
                          dispatch({
                            type: 'onChangeBtn',
                            payload: {
                              index: bIndex,
                              value: {
                                ...el,
                                payload: `${payload}({"increment": ["quiz"]})`,
                              },
                            },
                          });
                      }}
                    />
                  </Field>
                )}
              {increments &&
                payload &&
                focus.content &&
                focus.content.func.default.daliType === 'match' && (
                  <div>
                    {increments.map((inc, i) => {
                      return (
                        <div
                          className="flex row m-b-xs space-between"
                          key={inc}
                        >
                          <span className="has-text-primary-ter p-xs space-pre-line word-break-break-word rad-5px">
                            {inc}
                          </span>
                          <Btn
                            className="is-outlined is-borderless is-size-7"
                            color="danger"
                            onClick={() => {
                              let newIncrements = increments.filter(
                                (str) => inc !== str
                              );
                              setIncrements(newIncrements);
                              let adds = newIncrements.length
                                ? `({"increment": [${newIncrements
                                    .filter((str) => inc !== str)
                                    .map((inc) => `"${inc}"`)
                                    .join(',')}]})`
                                : '';
                              dispatch({
                                type: 'onChangeBtn',
                                payload: {
                                  index: bIndex,
                                  value: {
                                    ...el,
                                    payload: `${payload.replace(
                                      /\(.*\)/,
                                      ''
                                    )}${adds}`,
                                  },
                                },
                              });
                            }}
                          >
                            <Icon icon="fas fa-minus" className="is-size-7" />
                          </Btn>
                        </div>
                      );
                    })}
                  </div>
                )}
            </>
          ) : (
            <Field
              subtitle={w.scenario.redirectionURL}
              className="animation-slideDown"
            >
              <Input
                autoComplete="off"
                name="url"
                value={url}
                onChange={(e) => {
                  const { name, value } = e.target;
                  props.dispatch &&
                    dispatch({
                      type: 'onChangeBtn',
                      payload: {
                        index: bIndex,
                        value: { ...el, [name]: value },
                      },
                    });
                  props.onChange &&
                    props.onChange(value, gIndex, bIndex, 'btnUrl', cIndex);
                }}
              />
            </Field>
          )}
        </div>
      )}
    </div>
  );
};

const Container = styled.div`
  width: ${(p) => (p.random ? (p.multiple ? '570px' : '310px') : '80%')};
`;

const RemoveElementButton = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  color: gray;
  background-color: #ececec;
  border-radius: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;
