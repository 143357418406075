import React, { Component } from 'react';
import styled from 'styled-components';
import { withGlobals } from '../hoc/withGlobals';
import { _formatToComponents } from '../tools/format';
import QuickReplies from '../components/QuickReplies';
import Mailto from '../components/Mailto';

const ConversationWrapper= styled.div`
  overflow-x: hidden;
`;

class Conversation extends Component {
  scrollToBottom = () => {
    const scrollHeight = this.conversation.scrollHeight;
    const height = this.conversation.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.conversation.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  render() {
    const {
      context: {
        conversation: { history, mailTo },
      },
    } = this.props;
    return (
      <ConversationWrapper
        className="hideScroll flex flex-1 overflow-scroll has-background-white iecrapbis"
        ref={(div) => {
          this.conversation = div;
        }}
      >
        <div className="fill-available flex space-between column p-xs w-100p">
          <div>{_formatToComponents(history, this.props.w)}</div>
          <div>{mailTo && <Mailto />}</div>
          <div>
            <QuickReplies />
          </div>
        </div>
      </ConversationWrapper>
    );
  }
}

export default withGlobals(Conversation);
