import React, { Component } from 'react';

class Date extends Component {
  render() {
    const { onChange, w, date, error } = this.props;

    return (
      <React.Fragment>
        <h3 className="m-b-xs is-size-7 p-b-xs has-text-primary">{w} :*</h3>
        <div className="flex row" style={{ width: '200%' }}>
          <input
            className={error ? 'dateInput danger' : 'dateInput'}
            onChange={(e) => onChange(e.target.value)}
            type="date"
            value={date ? date : ''}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Date;
