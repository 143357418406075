import React, { Component } from 'react';
import { withMain } from '../../hoc/main';
import PlaceSelector from './PlaceSelector';
import PlaceCreator from './PlaceCreator';
import Modal from '../Modal';
import { Field, Button, Icon } from '@ask-mona/ui';

class AddPlaceModal extends Component {
  state = {
    isOpen: false,
    place: null,
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };
  onCreate = (item) => {
    this.setState({ isOpen: true, default: item.value });
  };
  onSelect = (place) => {
    this.setState({ place: place });
  };

  onAdd = async (place) => {
    const { onAdd } = this.props;
    onAdd && onAdd(place);
  };

  onConfirm = async () => {
    await this.onAdd(this.state.place);
  };

  render() {
    const {
      m: { w },
      onCancel,
      subOpt,
    } = this.props;
    return (
      <div className="w-4 has-background-white rad-5">
        <div className="flex row space-between items-center has-background-primary p-md">
          <div className="has-text-white">{w.place.addPlace}</div>
          <Button
            onClick={onCancel}
            className="is-outlined is-borderless shrink"
          >
            <Icon icon="fas fa-times" />
          </Button>
        </div>
        <div className="p-md">
          <Field title={w.place.searchPlace} className="m-b-md">
            <PlaceSelector
              create={`${w.place.createPlace}: `}
              onSelect={this.onSelect}
              onCreate={this.onCreate}
            />
          </Field>
          <Modal
            isOpen={this.state.isOpen}
            close={this.onClose}
            selector="#root"
          >
            <PlaceCreator
              onSuccess={this.onAdd}
              onCancel={this.onClose}
              default={this.state.default}
              subOpt={subOpt}
            />
          </Modal>
          <div className="flex row space-between wrap">
            <Button
              className="is-uppercase"
              onClick={onCancel}
              color="secondary"
            >
              {w.generic.cancel}
            </Button>
            <Button
              className="is-uppercase"
              onClick={this.onConfirm}
              disabled={!this.state.place}
            >
              {w.generic.validate}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withMain(AddPlaceModal);
