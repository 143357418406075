import styled from 'styled-components';

const StyledInput = styled.input`
  color: #2d2f7b;
  padding: 0.6rem;
  margin-top: 0.6rem;
  border: 1px solid #2d2f7b;
  &::placeholder {
    color: #2d2f7b;
    font-weight: 500;
  }
`;

export default StyledInput;
