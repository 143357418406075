import React, { useState, useEffect } from 'react';
import { Button as Btn, Input, Field, Icon, Switch } from '@ask-mona/ui';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { QRTemplate } from '../Reducer';
import { _arrayMove } from '../../../_tools/other';

export default (props) => {
  const {
    m: { w },
    lang,
    index,
    el: { quick_replies },
    dispatch,
  } = props;

  useEffect(() => {
    dispatch({ type: 'setQR', payload: quick_replies });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quick_replies, lang]);

  if (!quick_replies || !quick_replies.length) {
    return (
      <div className="w-100p p-xs flex center">
        <Btn
          className="is-size-7 is-outlined is-borderless"
          onClick={() => {
            dispatch({
              type: 'onQRChange',
              payload: {
                index,
                value: (quick_replies || []).concat(QRTemplate()),
              },
            });
          }}
        >
          {w.scenario.addQR}
        </Btn>
      </div>
    );
  } else
    return (
      <div className="w-100p p-xs overflow-auto space-nowrap hideScroll text-align-center">
        {quick_replies.map((qr, i) => (
          <QR key={i} {...props} qr={qr} qIndex={i} />
        ))}
        {quick_replies.length < 13 && (
          <div className="inline-block has-text-info border-grey-lighter pointer rad-15px m-xs">
            <Btn
              className="shrink is-size-7 is-outlined is-borderless"
              onClick={() => {
                dispatch({
                  type: 'onQRChange',
                  payload: {
                    index,
                    value: (quick_replies || []).concat(QRTemplate()),
                  },
                });
              }}
            >
              <Icon icon="fas fa-plus" />
            </Btn>
          </div>
        )}
      </div>
    );
};

const QR = (props) => {
  const [edit, setEdit] = useState(false);
  const [hover, setHover] = useState(false);
  const [valueSelect, setValueSelect] = useState('');
  const {
    m: { w },
    el,
    qr,
    index,
    qIndex,
    dispatch,
    payloads,
    focus,
  } = props;
  const { quick_replies } = el;
  const { payload, title } = qr;
  const [increments, setIncrements] = useState(() => {
    let increments = (payload || '').match(/\({.*(\[.*\]).*}\)/);
    if (increments) return JSON.parse(increments[1]);
    else return [];
  });

  useEffect(() => {
    let increments = (payload || '').match(/\({.*(\[.*\]).*}\)/);
    if (increments) setIncrements(JSON.parse(increments[1]));
    else setIncrements([]);
  }, [payload]);

  return (
    <div
      className="inline-block has-text-info border-grey-lighter pointer rad-15px m-xs"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {(hover || edit) && (
        <div className="flex row space-between animation-slideDown border-b-grey-lighter">
          <Btn
            color="danger"
            className="is-outlined is-borderless shrink is-size-7"
            onClick={() =>
              dispatch({
                type: 'onQRChange',
                payload: {
                  index,
                  value: quick_replies.filter((qr, i) => i !== qIndex),
                },
              })
            }
          >
            <Icon icon="fas fa-trash" />
          </Btn>
          <Btn
            onClick={() => {
              let newQR = [...quick_replies];
              _arrayMove(newQR, qIndex, qIndex - 1);
              dispatch({
                type: 'onQRChange',
                payload: {
                  index,
                  value: newQR,
                },
              });
            }}
            className="is-borderless is-outlined shrink"
          >
            <Icon icon="fas fa-caret-left" />
          </Btn>
          <Btn
            onClick={() => {
              let newQR = [...quick_replies];
              _arrayMove(newQR, qIndex, qIndex + 1);
              dispatch({
                type: 'onQRChange',
                payload: {
                  index,
                  value: newQR,
                },
              });
            }}
            className="is-borderless is-outlined shrink"
          >
            <Icon icon="fas fa-caret-right" />
          </Btn>
          <Btn
            color="success is-outlined is-borderless shrink is-size-7"
            onClick={() => setEdit(!edit)}
          >
            {edit && valueSelect ? (
              <Icon icon="fas fa-check" />
            ) : (
              <Icon icon="fas fa-pen" />
            )}
          </Btn>
        </div>
      )}
      <div
        className={`flex row has-text-info ${
          !payload ? 'has-background-danger-light rad-15px' : ''
        }`}
      >
        <Input
          autoComplete="off"
          name="title"
          maxLength={edit ? 20 : ''}
          className="is-transparent text-align-center"
          placeholder={w.scenario.btnTitle}
          value={title || ''}
          onChange={(e) => {
            const { name, value } = e.target;
            dispatch({
              type: 'onQRChange',
              payload: {
                index,
                value: quick_replies.map((qr, i) =>
                  i === qIndex ? { ...qr, [name]: value } : qr
                ),
              },
            });
          }}
        />
      </div>
      {edit && (
        <div className="has-background-primary-lighter p-xs">
          <Field
            subtitle={w.scenario.linkToModule}
            className="animation-slideDown"
          >
            <FormControl>
              <Select
                autoWidth
                native
                value={
                  payload.match(/quiz/) && payload.match(/increment/)
                    ? quick_replies
                        .find((qr, i) => i === qIndex)
                        .payload.match(/(.*?)\(/)[1] || valueSelect
                    : quick_replies.find((qr, i) => i === qIndex).payload ||
                      valueSelect
                }
                onChange={(e) => {
                  setValueSelect(e.target.value);
                  let adds = increments.length
                    ? `({"increment": [${increments.map((el) => `"${el}"`)}]})`
                    : '';
                  dispatch({
                    type: 'onQRChange',
                    payload: {
                      index,
                      value: quick_replies.map((qr, i) =>
                        i === qIndex
                          ? { ...qr, payload: `${e.target.value}${adds}` }
                          : qr
                      ),
                    },
                  });
                }}
              >
                <option selected>{''}</option>
                {payloads.map((p) => (
                  <option key={p.id} value={p.key}>
                    {p.value}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Field>
          {focus.dali &&
            payload &&
            focus.content.func.default.daliType === 'match' && (
              <Field subtitle={w.scenario.increment}>
                <FormControl>
                  <Select
                    native
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        let newIncrements = increments
                          .filter((el) => el !== e.target.value)
                          .concat(e.target.value);
                        let adds = newIncrements.length
                          ? `({"increment": [${newIncrements
                              .filter((el) => el !== e.target.value)
                              .concat(e.target.value)
                              .map((el) => `"${el}"`)
                              .join(',')}]})`
                          : '';
                        setIncrements(newIncrements);
                        dispatch({
                          type: 'onQRChange',
                          payload: {
                            index,
                            value: quick_replies.map((qr, i) =>
                              i === qIndex
                                ? {
                                    ...qr,
                                    payload: `${payload.replace(
                                      /\(.*\)/,
                                      ''
                                    )}${adds}`,
                                  }
                                : qr
                            ),
                          },
                        });
                      }
                    }}
                  >
                    <option>{''}</option>
                    {Object.keys(focus.content.func['match'] || []).map(
                      (el, i) => (
                        <option key={i} value={el}>
                          {el}
                        </option>
                      )
                    )}
                  </Select>
                  <FormHelperText>{w.scenario.addResults}</FormHelperText>
                </FormControl>
              </Field>
            )}
          {focus.dali &&
            payload &&
            focus.content.func.default.daliType === 'quiz' && (
              <Field subtitle={w.scenario.answer} className="m-b-xs">
                <Switch
                  checked={increments.length}
                  onChange={() => {
                    if (payload.match(/quiz/) && payload.match(/increment/))
                      dispatch({
                        type: 'onQRChange',
                        payload: {
                          index,
                          value: quick_replies.map((qr, i) =>
                            i === qIndex
                              ? {
                                  ...qr,
                                  payload: `${payload.replace(/\(.*\)/, '')}`,
                                }
                              : qr
                          ),
                        },
                      });
                    else
                      dispatch({
                        type: 'onQRChange',
                        payload: {
                          index,
                          value: quick_replies.map((qr, i) =>
                            i === qIndex
                              ? {
                                  ...qr,
                                  payload: `${payload}({"increment": ["quiz"]})`,
                                }
                              : qr
                          ),
                        },
                      });
                  }}
                />
              </Field>
            )}
          {increments &&
            payload &&
            focus.content.func.default.daliType === 'match' && (
              <div>
                {increments.map((el, i) => {
                  return (
                    <div className="flex row m-b-xs space-between" key={el}>
                      <span className="has-text-primary-ter p-xs space-pre-line word-break-break-word rad-5px">
                        {el}
                      </span>
                      <Btn
                        className="is-outlined is-borderless is-size-7"
                        color="danger"
                        onClick={() => {
                          let newIncrements = increments.filter(
                            (str) => el !== str
                          );
                          setIncrements(newIncrements);
                          let adds = newIncrements.length
                            ? `({"increment": [${newIncrements
                                .filter((str) => el !== str)
                                .map((el) => `"${el}"`)
                                .join(',')}]})`
                            : '';
                          dispatch({
                            type: 'onQRChange',
                            payload: {
                              index,
                              value: quick_replies.map((qr, i) =>
                                i === qIndex
                                  ? {
                                      ...qr,
                                      payload: `${payload.replace(
                                        /\(.*\)/,
                                        ''
                                      )}${adds}`,
                                    }
                                  : qr
                              ),
                            },
                          });
                        }}
                      >
                        <Icon icon="fas fa-minus" className="is-size-7" />
                      </Btn>
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      )}
    </div>
  );
};
