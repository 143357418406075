import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { QAContext } from '../context/QAContext';
import { Answer as AnswerType } from '../../../types/qa/topics';
import { convertToFormattedDate } from '../../../_tools/other';
import styled from 'styled-components';
import useUsers from '../../../api/graphql/hooks/useUsers';

interface Props {
  answer: AnswerType;
  w: any;
}
type User = {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  permissions: number;
};

const DateManagement = (props: Props) => {
  const { state, dispatch } = useContext(QAContext);
  const [displayPeriod, setDisplayPeriod] = useState<boolean>(false);
  const [displayDate, setDisplayDate] = useState<boolean>(false);
  const [displayEndDate, setDisplayEndDate] = useState<boolean>(false);
  const { users } = useUsers(state.botId, state.token, state.organizationId);
  console.log(users);
  useEffect(() => {
    if (typeof props.answer.start_date === 'string') {
      setDisplayPeriod(true);
    }
    if (typeof props.answer.reminder_date === 'string') {
      setDisplayDate(true);
    }
    if (typeof props.answer.end_date === 'string') {
      setDisplayEndDate(true);
    }
  }, []);

  return (
    <div>
      <div>
        <input
          type="checkbox"
          name="reminder"
          id="reminder"
          checked={displayPeriod}
          onChange={() => {
            setDisplayPeriod(!displayPeriod);
            if (displayPeriod) {
              dispatch({
                type: 'UPDATE_ANSWER',
                payload: {
                  enabled: props.answer.enabled,
                  id: props.answer.id,
                  startDate: null,
                  endDate: null,
                  reminderDate: props.answer.reminder_date,
                  reminderEmails: props.answer.reminder_emails,
                  place: props.answer.place,
                },
              });
            }
          }}
        />
        <label htmlFor="reminder">
          {props.w.setPeriod}
        </label>
      </div>

      {!!displayPeriod && (
        <Wrapper>
          <div>
            <label htmlFor="start">{props.w.startingDate}</label>
            <StyledInput
              type="date"
              id="start"
              name="start"
              value={convertToFormattedDate(
                props.answer.start_date || new Date()
              )}
              onChange={(e) => {
                dispatch({
                  type: 'UPDATE_ANSWER',
                  payload: {
                    enabled: props.answer.enabled,
                    id: props.answer.id,
                    startDate: e.target.value,
                    endDate: props.answer.end_date,
                    reminderDate: props.answer.reminder_date,
                    reminderEmails: props.answer.reminder_emails,
                    place: props.answer.place,
                  },
                });
              }}
            />
          </div>
          {!!displayEndDate && (
            <div>
              <label htmlFor="end">{props.w.endingDate}</label>
              <StyledInput
                type="date"
                id="end"
                name="end"
                value={convertToFormattedDate(
                  props.answer.end_date || new Date()
                )}
                onChange={(e) => {
                  dispatch({
                    type: 'UPDATE_ANSWER',
                    payload: {
                      enabled: props.answer.enabled,
                      id: props.answer.id,
                      startDate: props.answer.start_date,
                      endDate: e.target.value,
                      reminderDate: props.answer.reminder_date,
                      reminderEmails: props.answer.reminder_emails,
                      place: props.answer.place,
                    },
                  });
                }}
              />
            </div>
          )}
          {displayEndDate ? (
            <i
              className="fas fa-minus"
              onClick={() => {
                setDisplayEndDate(false);
                dispatch({
                  type: 'UPDATE_ANSWER',
                  payload: {
                    enabled: props.answer.enabled,
                    id: props.answer.id,
                    startDate: props.answer.start_date,
                    endDate: null,
                    reminderDate: props.answer.reminder_date,
                    reminderEmails: props.answer.reminder_emails,
                    place: props.answer.place,
                  },
                });
              }}
            ></i>
          ) : (
            <i
              className="fas fa-plus"
              onClick={() => setDisplayEndDate(true)}
            ></i>
          )}
        </Wrapper>
      )}

      <div>
        <input
          type="checkbox"
          name="reminderMail"
          checked={displayDate}
          onChange={() => setDisplayDate(!displayDate)}
        />
        <label htmlFor="reminderMail">{props.w.setMail}</label>
        {!!displayDate && (
          <Wrapper>
            <WrapperSelect>
              <label htmlFor="reminder">{props.w.the}</label>
              <StyledInput
                type="date"
                id="reminder"
                name="reminder"
                value={convertToFormattedDate(
                  props.answer.reminder_date || new Date()
                )}
                onChange={(e) => {
                  dispatch({
                    type: 'UPDATE_ANSWER',
                    payload: {
                      enabled: props.answer.enabled,
                      id: props.answer.id,
                      startDate: props.answer.start_date,
                      endDate: props.answer.end_date,
                      reminderDate: e.target.value,
                      reminderEmails: props.answer.reminder_emails,
                      place: props.answer.place,
                    },
                  });
                }}
              />
            </WrapperSelect>
            {typeof props.answer.reminder_date === 'string' && (
              <WrapperSelect className="w-100p">
                <label>{props.w.for}</label>
                <Select<User>
                  options={users}
                  getOptionLabel={(user: User) => user.email}
                  getOptionValue={(user: User) => user.id}
                  className="w-100p"
                  onChange={(intent) => {
                    dispatch({
                      type: 'UPDATE_ANSWER',
                      payload: {
                        enabled: props.answer.enabled,
                        id: props.answer.id,
                        startDate: props.answer.start_date,
                        reminderDate: props.answer.reminder_date,
                        endDate: props.answer.end_date,
                        reminderEmails:
                          typeof props.answer.reminder_emails === 'string'
                            ? props.answer.reminder_emails.concat(
                                `;${intent.email}`
                              )
                            : intent.email,
                        place: props.answer.place,
                      },
                    });
                  }}
                />
              </WrapperSelect>
            )}
          </Wrapper>
        )}
        {typeof props.answer.reminder_emails === 'string' && (
          <WrapperEmails>
            {props.answer.reminder_emails?.split(';').map((el, i) => (
              <StyledEmail
                className="p-xs is-size-7 has-background-primary-light rad-5px flex row space-between items-center"
                key={i}
              >
                {el}
                <StyledCross
                  onClick={() => {
                    let arr = props.answer.reminder_emails?.split(';');
                    if (arr)
                      dispatch({
                        type: 'UPDATE_ANSWER',
                        payload: {
                          enabled: props.answer.enabled,
                          id: props.answer.id,
                          startDate: props.answer.start_date,
                          reminderDate: props.answer.reminder_date,
                          endDate: props.answer.end_date,
                          reminderEmails:
                            props.answer.reminder_emails?.replace(
                              arr.length > 1 ? `,${el}` : el,
                              ''
                            ) || null,
                          place: props.answer.place,
                        },
                      });
                  }}
                >
                  <i className="fas fa-times" />
                </StyledCross>
              </StyledEmail>
            ))}
          </WrapperEmails>
        )}
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const WrapperSelect = styled.div`
  display: flex;
  align-items: center;

  > label {
    margin-right: 0.4rem;
  }
`;

const StyledInput = styled.input`
  border: 1px solid #dbdbdb;
  padding: 0.5rem;
  border-radius: 4px;
  margin-right: 0.4rem;
`;
const WrapperEmails = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCross = styled.div`
  color: #e08585;
  cursor: pointer;
`;

const StyledEmail = styled.div`
  height: 30px;
  width: fill-available;
  margin: 0.4rem 0;
`;

export default DateManagement;
