import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Field, Icon } from '@ask-mona/ui';
import Video from '../../../components/Video';
import { _uploadVid } from '../../../_api/upload';
import { _sendFeedback } from '../../../_tools/ui';
import { _getKeyValue } from '../../../_tools/other';
import { videoTemplate, videoIDTemplate } from '../Reducer';

import ScenariosContext from '../../../containers/scenarios/context/scenariosContext';

export default (props) => {
  const {
    m: { bot, w },
    element,
    dispatch,
    index,
    fix,
    advanced,
    random,
    newIndex,
    arrayOfElements,
  } = props;
  const [loading, setLoading] = useState(false);

  let url = _getKeyValue(element, 'url') || '';
  let attachment_id = _getKeyValue(element, 'attachment_id') || '';
  let attachmentURL = `https://cdn.${process.env.REACT_APP_ASKMONA}/${bot.id}/${attachment_id}.mp4`;
  if (!url && attachment_id) url = attachmentURL;

  const qr = element.quick_replies || null;

  const scenariosContext = useContext(ScenariosContext);

  const updateVid = (attachmentID, url) => {
    let newArray = [];

    if (!url && attachmentID) {
      if (random) {
        newArray = [...arrayOfElements];
        newArray[newIndex] = [
          videoIDTemplate(attachmentID, qr ? { quick_replies: qr } : {}),
        ];
      }
      dispatch({
        type: 'onElemChange',
        payload: {
          index,
          value: random
            ? newArray
            : videoIDTemplate(attachmentID, qr ? { quick_replies: qr } : {}),
          func: advanced
            ? null
            : scenariosContext.functionalitiesSubsbarFocus === 'content'
            ? null
            : scenariosContext.functionalitiesSubsbarFocus,
        },
      });
    } else {
      if (random) {
        newArray = [...arrayOfElements];
        newArray[newIndex] = [
          videoTemplate(url, qr ? { quick_replies: qr } : {}),
        ];
      }
      dispatch({
        type: 'onElemChange',
        payload: {
          index,
          value: random
            ? newArray
            : videoTemplate(url, qr ? { quick_replies: qr } : {}),
          func: advanced
            ? null
            : scenariosContext.functionalitiesSubsbarFocus === 'content'
            ? null
            : scenariosContext.functionalitiesSubsbarFocus,
        },
      });
    }
  };

  return (
    <Container random={random} className="border-primary-lighter m-xs rad-15px">
      <CustomField>
        <Video
          src={url}
          placeholder={w.generic.vidPlaceholder}
          onUpload={async (e) => {
            setLoading(true);
            _sendFeedback('warning', 'loader');
            await _uploadVid(
              bot.id,
              e.target.value,
              (res) => {
                setLoading(false);
                _sendFeedback('success', w.success.saved);
                updateVid(res.id, null);
              },
              (err) => {
                setLoading(false);
                _sendFeedback('danger', w.error.general);
              }
            );
          }}
          onChange={(e) => {
            if (e.target.type === 'id') updateVid(e.target.value, null);
            else updateVid(null, e.target.value);
          }}
          fixed={loading || fix ? 'true' : ''}
          loading={loading ? 'true' : ''}
        />
      </CustomField>
      {random && (
        <RemoveElementButton
          onClick={() => {
            let newArray = [...arrayOfElements];
            newArray = newArray.filter((el) => el[0] !== element);
            dispatch({
              type: 'onElemChange',
              payload: {
                index,
                value: newArray,
                func: null,
              },
            });
          }}
        >
          <Icon icon="fas fa-times" />
        </RemoveElementButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: ${(p) => (p.random ? '300px' : '50%')};
  position: relative;
`;

const CustomField = styled(Field)`
  border-radius: 15px;
  overflow: hidden;
`;

const RemoveElementButton = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  color: gray;
  background-color: #ececec;
  border-radius: 20px;
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
`;
