import React, { Component } from 'react';
import { Switch, Select } from '@ask-mona/ui';
import { _getKeyValue } from '../../../_tools/other';
import { _sendFeedback } from '../../../_tools/ui';

class Affiliator extends Component {
  state = {
    defaultValue: '',
  };

  onSelect = async (item) => {
    const {
      m: { w },
      api: { place },
      fct,
    } = this.props;
    _sendFeedback('warning', 'loader');
    const res = await place._getPlace({ id: item.id });
    const oph = _getKeyValue(res, 'opening_hours');
    if (oph) {
      fct.setOph(oph);
      _sendFeedback('success', w.success.loaded);
    } else _sendFeedback('danger', w.error.noOph);
  };

  render() {
    const {
      m: { w },
      bot: { places },
      isOpen,
      toggleOpen,
    } = this.props;
    return (
      <div>
        <div className="w-100p flex items-center m-b-md p-r-md">
          {w.event.affPlace}
          <Switch
            color="secondary"
            defaultValue={isOpen}
            onChange={toggleOpen}
            className="m-l-md"
          />
        </div>
        <div className={`${isOpen ? 'animation-fadeIn' : 'none'}`}>
          <div className="w-100p flex items-center m-b-md">
            <span className="m-r-md">{w.generic.selectPlace}</span>
            <Select
              items={places.map((p) => ({ id: p.id, value: p.name }))}
              onSelect={this.onSelect}
              defaultValue={this.state.defaultValue}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Affiliator;
