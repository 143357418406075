import React from 'react';
import { withMain } from '../hoc/main';
import { Button, Icon } from '@ask-mona/ui';

const noop = () => {};

const Tutorial = ({ m: { w }, tuto, close }) => {
  const str = tuto || w.tutorial.default;
  return (
    <div className="w-8 p-md space-pre-line has-text-black has-background-white">
      <div className="flex flex-end">
        <Button
          className="is-outlined is-borderless shrink"
          onClick={() => {
            (close || noop)();
          }}
        >
          <Icon icon="fas fa-times" />
        </Button>
      </div>
      {str}
    </div>
  );
};

export default withMain(Tutorial);
