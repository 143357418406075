import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { withMain } from '../hoc/main';
import Header from '../containers/Header';
import Settings from '../containers/Settings';
import Parameters from '../containers/Parameters';
import Scenarios from '../containers/Scenarios';
import QAContainer from '../containers/QA';
import Events from '../containers/Events';
import Event from '../containers/Event';
import Inbox from '../containers/Inbox';
import Account from '../containers/Account';
import Mediations from '../containers/Mediations';
import Checklist from '../containers/Checklist';
import Mediation from '../containers/Mediation';
import Artwork from '../containers/Artwork';
import Analytics from '../containers/Analytics';
import HomepageAdmin from '../containers/HomepageAdmin';
import Homepage from '../containers/Homepage';
import Pushs from '../containers/Pushs';
import Push from '../containers/Push';
import QA from '../containers/QuestionsAnswers';
import EventState from '../containers/events/list/context/EventState';
import ScenariosState from '../containers/scenarios/context/ScenariosState';
import { BotSelectorContainer } from '../containers/BotSelector';

function NotFound() {
  return <Redirect to="/office" />;
}

function CheckIfBotPresent({ children, bot }) {
  return !!bot.id
    ? children
    : <NotFound />;
}

function Boards(props) {
  const preURL = props.match.url;
  const location = useLocation();

  const { bots, fct: { switchBot} } = props.m;
  const setBotById = useCallback((id) => {
    switchBot(bots.find((bot) => bot.id === id));
  }, [bots, switchBot]);

  const {
    m: {
      isIframe,
      bot_lang,
      fct,
      user: {
        organization,
        user: { permissions, token },
      },
      bot: { features, id, types, places, default_language, organization_id },
      w,
    },
  } = props;

  const leo = features?.some((feature) => feature.id === 40);
  const isMultisite = types?.some((el) => el.slug === 'multisite');

  useEffect(() => {
    if (!isIframe) return;

    const root = document.querySelector("#root");
    const panelV2BackgroundLight = "hsl(227, 69%, 97%)";

    // NOTE: scss doesn't seem to rebuild locally. Apply directly inline style just in case.
    root.classList.add("panelV2-iframe");
    root.style["background-color"] = panelV2BackgroundLight;

  }, [isIframe]);

  return (
    <>
      {!isIframe && (<Header {...props} />)}
      <BoardContainer>
        {/* The EventState allows the application to recover the variable and function necessary for setting the event list pagination number from the EventContext*/}
        <EventState>
          <ScenariosState>
            <Switch>
              <Route path={`${preURL}/bot/:botId`}>
                <BotSelectorContainer setBotById={setBotById} bot={props.m.bot}>
                  <Switch>
                    {leo || process.env.REACT_APP_FORCE_LEO_QR ? (
                      <Route path={`${preURL}/bot/:botId/qa`}>
                        <QA
                          bot_lang={bot_lang}
                          bot_id={id}
                          default_language={default_language}
                          token={token}
                          isMultisite={isMultisite}
                          places={places}
                          permissions={permissions}
                          organizationId={organization_id}
                          w={w.qa}
                        />
                      </Route>
                    ) : (
                      <Route path={`${preURL}/bot/:botId/qa`} component={QAContainer} />
                    )}

                    <Route path={`${preURL}/bot/:botId/general`} component={Settings} />

                    <Route
                      exact
                      index
                      component={Homepage}
                      {...props}
                    />
                  </Switch>
                </BotSelectorContainer>
              </Route>
              
              {/* <Route path={`${this.preURL}/customize`} component={Parameters} /> */}

              <Route
                exact
                path={`${preURL}/homepageAdmin`}
                component={HomepageAdmin}
                {...props}
              />

              <Route path="*">
                <CheckIfBotPresent bot={props.m.bot}>
                  <Route path={`${preURL}/general`} component={Settings} />

                  <Route
                    path={`${preURL}/scenarios`}
                    component={Scenarios}
                  />

                  <Route
                    exact
                    path={`${preURL}/mediations`}
                    component={Mediations}
                  />

                  <Route
                    exact
                    path={`${preURL}/mediation/:id`}
                    component={Mediation}
                  />

                  <Route
                    exact
                    path={`${preURL}/artwork/:medId/:id/`}
                    component={Artwork}
                  />

                  <Route
                    exact
                    path={`${preURL}/artwork/:medId`}
                    component={Artwork}
                  />

                  <Route
                    exact
                    path={`${preURL}/events`}
                    component={Events}
                  />

                  <Route path={`${preURL}/event/:id`} component={Event} />

                  <Route path={`${preURL}/inbox`} component={Inbox} />

                  <Route
                    exact
                    path={`${preURL}/account`}
                    component={Account}
                  />

                  <Route
                    exact
                    path={`${preURL}/checklist`}
                    component={Checklist}
                  />

                  <Route
                    exact
                    path={`${preURL}/analytics`}
                    component={Analytics}
                  />

                  <Route exact path={`${preURL}/pushs`} component={Pushs} />

                  <Route
                    exact
                    path={`${preURL}/push/:id`}
                    component={Push}
                  />

                  {permissions === 1 && (
                    <Route
                      path={`${preURL}/parameters`}
                      component={Parameters}
                    />
                  )}
                </CheckIfBotPresent>
              </Route>

              <Route path="*" component={NotFound} />
            </Switch>
          </ScenariosState>
        </EventState>
      </BoardContainer>
    </>
  );
}


export default withMain(Boards);

const BoardContainer = styled.div`
  width: 1024px;
  height: 86%;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 1124px) {
    width: 100%;
  }
  @media only screen and (min-width: 1124px) {
    width: 90%;
  }
`;
