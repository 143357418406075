import React, { Component } from 'react';
import { withGlobals } from '../../../hoc/globals';
import Filter from '../../../components/base/Filter';
import SubPlaceCreator from '../../../components/place/SubPlaceCreator';

class Place extends Component {
  state = {
    fixed: false,
  };

  render() {
    const {
      m: { w },
    } = this.props;
    return (
      <Filter
        icon="fas fa-map-marker-alt"
        label={w.events.place}
        fixed={this.state.fixed}
      >
        <SubPlaceCreator
          {...this.props}
          isSub
          onSelect={(e) => this.props.onChange(e)}
          onClick={() => this.setState({ fixed: true })}
        />
      </Filter>
    );
  }
}

export default withGlobals(Place);
