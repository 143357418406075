import React, { useEffect, useState, useContext } from 'react';
import Element from '../Element';
import QuickReplies from '../components/QuickReplies';
import Delay from '../components/Delay';
//import QuickReplies from "../components/QuickReplies";
import FuncText from '../components/FuncText';
import Bubble from '../components/Bubble';
import Image from '../components/Image';
import Video from '../components/Video';
import Audio from '../components/Audio';
import SwitchElement from '../components/SwitchElement';
import LogicMenu from './LogicMenu';
import LogicLang from './LogicLang';
import TWording from '../../../translations/TWording';
import { imageTemplate, videoTemplate, audioTemplate } from '../Reducer';
import { _getKeyValue } from '../../../_tools/other';
import { _isImg, _isVid, _isAudio } from '../../../_tools/is';
import ScenariosContext from '../../../containers/scenarios/context/scenariosContext';

const Cel = (props) => {
  const { advanced, lang, subfocus, focus, dispatch, el, i } = props;
  const { content } = subfocus || focus;
  let noDelete =
    focus.key === 'p:ask_language' &&
    _getKeyValue(el, 'template_type') === 'button';

  const scenariosContext = useContext(ScenariosContext);

  return (
    <div>
      {advanced && <Delay {...props} el={el} dispatch={dispatch} index={i} />}
      <Bubble
        removable="true"
        movable="true"
        onMove={(type) => {
          dispatch({
            type: 'onMoveElement',
            payload: {
              index: i,
              type,
              func: advanced
                ? null
                : scenariosContext.functionalitiesSubsbarFocus === 'content'
                ? null
                : scenariosContext.functionalitiesSubsbarFocus,
            },
          });
        }}
        onDelete={
          !noDelete
            ? () => {
                dispatch({
                  type: 'popElement',
                  payload: i,
                  func: advanced
                    ? null
                    : scenariosContext.functionalitiesSubsbarFocus === 'content'
                    ? null
                    : scenariosContext.functionalitiesSubsbarFocus,
                });
              }
            : null
        }
        onCopy={() => {
          dispatch({ type: 'copyElement', payload: el });
        }}
      >
        <Element {...props} el={el} dispatch={dispatch} index={i} />
      </Bubble>
      {advanced && i === content[lang].length - 1 && (
        <QuickReplies {...props} el={el} dispatch={dispatch} index={i} />
      )}
    </div>
  );
};

export default (props) => {
  const {
    m: { w },
    lang,
    focus,
    subfocus,
    advanced,
  } = props;
  const { content } = subfocus || focus;
  const { func } = content;

  const scenariosContext = useContext(ScenariosContext);

  useEffect(() => {
    scenariosContext.setFunctionalitiesSubsbarFunc(func);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [func]);

  useEffect(() => {
    scenariosContext.setFunctionalitiesSubsbarLang(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    scenariosContext.setFunctionalitiesSubsbarFunc(func);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [func]);

  const [wording, setWording] = useState(
    scenariosContext.functionalitiesSubsbar
  );

  useEffect(() => {
    setWording(scenariosContext.functionalitiesSubsbar);
  }, [scenariosContext.functionalitiesSubsbar]);

  let fkeys = [];
  let wkeys = [];

  if (func && func[lang]) fkeys = Object.keys(func[lang]);

  if (wording) wkeys = Object.keys(wording);

  fkeys = fkeys.filter(
    (k) =>
      k !== 'mediationLevel' &&
      k !== 'daliType' &&
      k !== 'answers' &&
      k !== 'error' &&
      k !== 'waiting_time' &&
      k !== 'ti' &&
      k !== 'ask_tarif' &&
      k !== 'select_ticket' &&
      k !== 'free_w' &&
      k !== 'tarif' &&
      k !== 'also' &&
      k !== 'valuable' &&
      k !== 'price' &&
      k !== 'free' &&
      k !== 'result' &&
      k !== 'of' &&
      k !== 'full_price' &&
      k !== 'ask_tarif' &&
      k !== 'select_ticket' &&
      k !== 'free_w' &&
      k !== 'tarif' &&
      k !== 'also' &&
      k !== 'valuable' &&
      k !== 'price' &&
      k !== 'free' &&
      k !== 'of'
  );

  let allkeys = [...wkeys];

  for (let i = 0; i < fkeys.length; i++) {
    if (!wkeys.includes(fkeys[i])) {
      allkeys.push(fkeys[i]);
    }
  }

  let cEl = [];
  let fEl = [];
  let addons = [];
  let prefix = [];

  let hasMenu = false;
  let hasMenuLang = false;
  if (content[lang])
    cEl = content[lang].map((el, i) => {
      if (
        typeof el === 'string' &&
        el.match(/logic/gi) &&
        !el.includes('LOGIC_PROG')
      ) {
        if (el === 'LOGIC_MENU') hasMenu = true;
        else if (el === 'LOGIC_LANG') hasMenuLang = true;
        return null;
      } else if (typeof el === 'string' && el === 'trigger_lang({{ $1 }})')
        return null;
      return <Cel {...props} key={i} el={el} i={i} />;
    });

  if (focus.key === 'fallback')
    prefix = [
      <div key="fallbackstr" className="flex center">
        Lorsque le bot ne comprend pas:{' '}
      </div>,
    ];
  else if (focus.key === 'p:language')
    prefix = [
      <div key="strLang" className="flex center">
        Lorsque l'utilisateur change de langue:{' '}
      </div>,
    ];
  else if (focus.key === 'p:pdc')
    prefix = [
      <div key="pdcstr" className="flex center">
        Lorsque l'utilisateur veut se renseigner sur la politique de
        confidentialité:{' '}
      </div>,
    ];

  if (hasMenu) addons = [<LogicMenu key="LogicMenu" {...props} />];
  else if (hasMenuLang) addons = [<LogicLang key="LogicMenu" {...props} />];

  if (!advanced) {
    fEl = allkeys.map((k) => {
      let el = '';
      if (
        func &&
        func[lang] &&
        !func[lang][k] &&
        typeof func[lang][k] !== 'boolean'
      ) {
        if (
          wording &&
          wording[k] &&
          (wording[k][lang] || typeof wording[k][lang] === 'boolean')
        ) {
          el = wording[k][lang];
        } else {
          el = '';
        }
      } else if (
        func &&
        func[lang] &&
        (func[lang][k] || typeof func[lang][k] === 'boolean')
      ) {
        el = func[lang][k];
      }

      let placeholder = (TWording[focus.key] && TWording[focus.key][lang]) ? TWording[focus.key][lang][k] : '';

      return (
        <div key={k}>
          <div className="flex center p-xxs is-size-7">
            {w.scenario[k] || ''}
          </div>

          {el && typeof el === 'string' ? (
            <Bubble>
              <FuncText
                {...props}
                element={{
                  name: k,
                  text: el,
                  placeholder,
                  arrayelement: false,
                }}
              />
            </Bubble>
          ) : Array.isArray(el) ? (
            el.map((i, index) => {
              if (_isImg(i))
                return (
                  <Bubble>
                    {' '}
                    <Image {...props} fix="true" element={imageTemplate(i)} />
                  </Bubble>
                );
              else if (_isVid(i))
                return (
                  <Bubble>
                    {' '}
                    <Video {...props} fix="true" element={videoTemplate(i)} />
                  </Bubble>
                );
              else if (_isAudio(i))
                return (
                  <Bubble>
                    <Audio {...props} fix="true" element={audioTemplate(i)} />
                  </Bubble>
                );
              else if (typeof i === 'string')
                return (
                  <Bubble>
                    <FuncText
                      {...props}
                      element={{
                        name: k,
                        text: i,
                        placeholder,
                        arrayelement: true,
                      }}
                    />
                  </Bubble>
                );
              else if (typeof i === 'boolean')
                return (
                  <Bubble>
                    <SwitchElement
                      {...props}
                      element={{
                        name: k,
                        value: i,
                        placeholder,
                        arrayelement: false,
                      }}
                    />
                  </Bubble>
                );
              else return <Cel {...props} key={index} el={i} i={index} />;
            })
          ) : _isImg(el) ? (
            <Bubble>
              {' '}
              <Image {...props} fix="true" element={imageTemplate(el)} />
            </Bubble>
          ) : _isVid(el) ? (
            <Bubble>
              {' '}
              <Video {...props} fix="true" element={videoTemplate(el)} />
            </Bubble>
          ) : _isAudio(el) ? (
            <Bubble>
              <Audio {...props} fix="true" element={audioTemplate(el)} />
            </Bubble>
          ) : typeof el === 'boolean' ? (
            <Bubble>
              <SwitchElement
                {...props}
                element={{
                  name: k,
                  value: el,
                  placeholder,
                  arrayelement: false,
                }}
              />
            </Bubble>
          ) : (
            <Bubble>
              <FuncText
                {...props}
                element={{
                  name: k,
                  text: el,
                  placeholder,
                  arrayelement: false,
                }}
              />
            </Bubble>
          )}
        </div>
      );
    });
  } else {
    fEl = fkeys.map((k) => {
      if (typeof func[lang][k] !== 'string' || !func[lang][k]) return null;
      let placeholder = TWording[focus.key] ? TWording[focus.key][lang][k] : '';
      let el = func[lang][k];
      if (_isImg(el))
        el = <Image {...props} fix="true" element={imageTemplate(el)} />;
      else if (_isVid(el))
        el = <Video {...props} fix="true" element={videoTemplate(el)} />;
      else if (_isAudio(el))
        el = <Audio {...props} fix="true" element={audioTemplate(el)} />;
      else
        el = (
          <FuncText {...props} element={{ name: k, text: el, placeholder }} />
        );

      return (
        <div key={k}>
          <div className="flex center p-xxs is-size-7">
            {w.scenario[k] || ''}
          </div>
          <Bubble>{el}</Bubble>
        </div>
      );
    });
  }

  return { prefix, fEl, cEl, addons };
};
