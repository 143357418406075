import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Icon, Textarea } from '@ask-mona/ui';
import { _sendFeedback } from '../../../_tools/ui';
import History from './History';
import Content from './Content';
import warningIcon from '../../../_assets/icon/warningIcon.png';

const Editor = (props) => {
  const {
    m: {
      w,
      user: { organization, permissions },
      bot,
    },
    api: { intent, place },
    payloads,
    lang,
    el,
    isOpen,
  } = props;

  const leoBot = bot.features.some((el) => el.slug === 'leo');
  const [history, setHistory] = useState(false);
  const [openQu, setOpenQu] = useState(false);
  const [places, setPlaces] = useState([]);
  const isMultisite = bot.types.some((el) => el.slug === 'multisite');

  const fetchPlaces = async () => {
    const res = await place._getBotHasPlace({ bot_id: bot.id });
    if (res.success) setPlaces(res.getBotHasPlaces);
  };

  useEffect(() => {
    if (isMultisite) fetchPlaces();
  }, []);

  if (!isOpen) return null;

  const desactivated = [];
  const activated = [];

  if (leoBot) {
    (el.answers || []).forEach((answer) => {
      if (answer.activated === true) activated.push(answer);
      else if (answer.activated === false) desactivated.push(answer);
    });
  } else {
    (el[lang]['content'] || []).forEach((content) => {
      if (content.activated) activated.push(content);
      else if (!content.activated && content.activated !== null)
        desactivated.push(content);
    });
  }

  return (
    <div className="flex row has-background-white has-text-primary">
      {!leoBot && (
        <div className="p-xs flex column grow-1 basis-0 border-r-primary-lighter">
          <div className="flex row space-between">
            <span className="m-b-md is-size-6 is-underlined">
              {w.qa.exQuestion}
            </span>
            <WrapperIcon icon="fas fa-eye" onClick={() => setOpenQu(!openQu)} />
          </div>
          {openQu && <EditQuestion {...props} setOpenQu={setOpenQu} />}
        </div>
      )}
      {isMultisite && !places.length ? (
        <WrapperError>
          <WrapperContent>
            <StyledIcon>
              <img src={warningIcon} width="30px" />
            </StyledIcon>
            <div>
              <StyledTitle>Lieu manquant</StyledTitle>
              <StyledContent>
                Créez un nouveau lieu dans l'onglet “Infos pratiques” avant
                d'éditer une réponse à une question
              </StyledContent>
            </div>
          </WrapperContent>
        </WrapperError>
      ) : (
        <div>
          {!el.jump && (
            <div className="p-xs flex column grow-1 basis-0 p-b-lg">
              {desactivated.length > 0 && (
                <div className="flex flex-end">
                  <Button
                    className="is-outlined is-borderless"
                    onClick={() => setHistory(true)}
                  >
                    <Icon icon="fas fa-folder" />
                    {w.qa.getHistory}
                  </Button>
                </div>
              )}
              {activated.map((c, i) => (
                <React.Fragment key={i}>
                  {i !== 0 && (
                    <div className="is-uppercase text-align-center has-text-weight-medium m-sm">
                      {w.generic.or}
                    </div>
                  )}
                  <Content key={c.id} {...props} c={c} cIndex={i} />
                </React.Fragment>
              ))}
              <Creator {...props} />
            </div>
          )}
          {el.jump && (
            <div className="p-xs flex column grow-2 basis-0">
              {`Lien direct vers le module ${
                (payloads.find((it) => it.value === el.jump) || {}).value
              }`}
              {organization.id === 1 && (
                <Button
                  onClick={async () => {
                    _sendFeedback('warning', 'loader');
                    let res = await intent._updateIntent(
                      {
                        bot_id: bot.id,
                        id: el.id,
                        language: lang,
                        jump: null,
                      },
                      {
                        refetchQueries: ['getCategoriesAndIntents'],
                        fetchPolicy: 'no-cache',
                      }
                    );
                    if (res.success) _sendFeedback('success', w.success.saved);
                    else _sendFeedback('danger', w.error.general);
                  }}
                >
                  {w.qa.remove}
                </Button>
              )}
            </div>
          )}
        </div>
      )}
      <History
        {...props}
        isOpen={history}
        close={() => setHistory(false)}
        content={desactivated}
      />
    </div>
  );
};

const EditQuestion = (props) => {
  const {
    m: { w, bot, user },
    api: { intent },
    lang,
    el,
    isOpen,
    close,
    setOpenQu,
  } = props;
  const [qs, setQs] = useState(el[lang]['questions'] || []);

  return (
    <div isOpen={isOpen} close={close}>
      <div className="has-background-white">
        <WrapperButton>
          {qs.length < 25 && (
            <Button
              className="is-size-7 m-r-xs"
              onClick={() => setQs(qs.concat(''))}
            >
              {w.qa.addQuest}
            </Button>
          )}
          <Button
            color="danger"
            className="m-r-xs is-size-7"
            onClick={() => setOpenQu(false)}
          >
            {w.generic.cancel}
          </Button>
          <Button
            color="success"
            className="is-size-7"
            onClick={async () => {
              _sendFeedback('warning', 'loader');
              const res = await intent._updateIntent(
                {
                  bot_id: bot.id,
                  id: el.id,
                  language: lang,
                  questions: qs,
                },
                { refetchQueries: ['getCategoriesAndIntents'] }
              );
              if (res.success) {
                _sendFeedback('success', w.success.saved);
                setOpenQu(false);
              } else _sendFeedback('danger', w.error.general);
            }}
          >
            {w.generic.validate}
          </Button>
        </WrapperButton>
        <div className="mh-4 overflow-scroll p-md ">
          {qs.map((q, i) => (
            <QuestionEditor
              key={i}
              index={i}
              value={q}
              qs={qs}
              setQs={setQs}
              prog={el.programmatic || el.fromMonolith}
              permissions={user.user.permissions}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const QuestionEditor = (props) => {
  const [focus, setFocus] = useState(false);
  const { value, qs, setQs, index } = props;

  return (
    <div className="m-sm flex row">
      <div
        className={`w-100p has-text-white rad-5px has-background-${
          focus ? 'primary-light' : 'primary-ter'
        } flex center`}
      >
        <Textarea
          disabled={(props.prog || props.permissions !== 1) && true}
          resize="auto"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={value || ''}
          onChange={(e) =>
            setQs(qs.map((q, i) => (i === index ? e.target.value : q)))
          }
          style={{ width: '100%' }}
          className="is-transparent"
        />
      </div>
      {props.permissions === 1 && (
        <Button
          className="is-outlined is-borderless shrink"
          color="danger"
          onClick={() => setQs(qs.filter((el, i) => i !== index))}
        >
          <Icon icon="fas fa-times" />
        </Button>
      )}
    </div>
  );
};

const Creator = ({ m: { w, bot }, el, lang, api: { intent }, ...other }) => {
  return (
    <div className="flex p-sm center">
      <Button
        className="shrink"
        onClick={async () => {
          let res = await intent._createContent(
            {
              bot_id: bot.id,
              id: el.id,
              language: lang,
              activated: true,
              content: [],
            },
            { refetchQueries: ['getCategoriesAndIntents'] }
          );
          if (res.success) _sendFeedback('success', w.success.saved);
          else _sendFeedback('danger', w.error.general);
        }}
      >
        {w.qa.addContent}
      </Button>
    </div>
  );
};

const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
`;

const WrapperIcon = styled(Icon)`
  cursor: pointer;
`;

const WrapperError = styled.div`
  display: flex;
  align-items: center;
  background: #ececec;
  width: 50%;
  padding: 2rem 1rem;
  overflow: hidden;
  white-space: initial;
`;

const StyledTitle = styled.h3`
  color: #d27270;
  margin-bottom: 0.8rem;
  font-weight: 600;
`;

const StyledContent = styled.p`
  color: #2d307b;
  line-height: 20px;
`;

const WrapperContent = styled.div`
  display: flex;
  width: 100%;
`;

const StyledIcon = styled.div`
  margin-right: 1.2rem;
`;

export default Editor;
