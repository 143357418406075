import {
  HttpLink,
  InMemoryCache,
  ApolloClient as CustomClient,
} from 'apollo-boost';
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import EmailRecipient from './EmailRecipient';
import Topics from './Topics';
import Intents from './Intents';
import User from './User';

interface IdGetterObjExtended extends Object {
  __typename?: string;
  id?: string;
  key?: string;
}

class Client {
  apiUrl: string;
  token?: string;
  client;

  constructor(apiUrl: string, token?: string) {
    this.apiUrl = `${apiUrl}/graphql`;
    this.token = token;
    this.client = this.createClient();
  }

  getToken() {
    return localStorage.getItem("token");
  }

  links = () => {
    let retryOptions = {
      delay: {
        initial: 500,
        max: 2000,
        jitter: true,
      },
    };
    let retryLink = new RetryLink(retryOptions);
    let httpLink = createUploadLink({ uri: this.apiUrl });
    let middleLink = new ApolloLink((req, next) => {
      req.setContext(({ headers }) => {
        const token = this.getToken();
        const authorization = token
          ? `Bearer ${token}`
          : "";
        return {
          headers: {
            ...headers,
            authorization,
          },
        };
      });
      return next(req);
    });
    let links = ApolloLink.from([retryLink, middleLink.concat(httpLink)]);
    return links;
  };

  cache = () => {
    let cache = new InMemoryCache({
      dataIdFromObject: (object: IdGetterObjExtended) => object.key,
    });
    return cache;
  };

  options = () => {
    const options = {};
    return options;
  };

  createClient () {
    return new CustomClient({
      link: this.links(),
      cache: this.cache(),
      defaultOptions: this.options(),
    });
  }

  get EmailRecipient() {
    return new EmailRecipient(this.client);
  }

  get Topics() {
    return new Topics(this.client);
  }

  get Intents() {
    return new Intents(this.client);
  }

  get User() {
    return new User(this.client);
  }
}

export default Client;
